import { reverse } from "@reactivated";
import { ajax, CSRF_HEADER, getCSRFToken } from "../utils/ajax";
import { FinancingApplicationSubmitData } from "../models/financing";
import {
    PreQualCustomerResponse,
    FinancingPlan,
    FinancingPlans,
    EstimatedPaymentResponse,
    FinancingPreQualRequest,
    FinancingPreQualRequestResponse,
    FinancingPreQualResponse,
    FinancingCreditAppSubmitResult,
    MaybeFinancingAccountInquiry,
} from "../models/financing";
import { check } from "../models/utils";
import { IProductID } from "../models/nominals";
import { PromiseMutex } from "../utils/mutex";
import config from "../config";

export const listFinancingPlans = async (): Promise<FinancingPlan[]> => {
    const mutex = new PromiseMutex<FinancingPlan[]>("list-financing-plans");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(reverse("financingapi:plan-list"))
            .set("Accept", "application/json")
            .then((resp) => {
                return check(FinancingPlans.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const listFinancingPlansForProduct = async (
    productID: IProductID,
): Promise<FinancingPlan[]> => {
    const mutex = new PromiseMutex<FinancingPlan[]>(
        `list-financing-plans-for-product-${productID}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/products/${productID}/financing-plans/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(FinancingPlans.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const getEstimatedMonthlyPrice = async (
    principal: string,
): Promise<EstimatedPaymentResponse> => {
    const resp = await ajax
        .get(reverse("financingapi:estimated-payment"))
        .set("Accept", "application/json")
        .query({ price: principal });
    return check(EstimatedPaymentResponse.decode(resp.body));
};

export const submitFinancingCreditApp = async (
    data: FinancingApplicationSubmitData,
): Promise<FinancingCreditAppSubmitResult> => {
    const resp = await ajax
        .post(reverse("financingapi:apply"))
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data);
    return check(FinancingCreditAppSubmitResult.decode(resp.body));
};

export const updatePendingCreditAppStatus =
    async (): Promise<MaybeFinancingAccountInquiry> => {
        const resp = await ajax
            .post(reverse("financingapi:update-inquiry"))
            .set("Accept", "application/json")
            .set(CSRF_HEADER, await getCSRFToken());
        return check(MaybeFinancingAccountInquiry.decode(resp.body || null));
    };

export const checkFinancingPreQualStatus = async (
    data: FinancingPreQualRequest,
): Promise<FinancingPreQualRequestResponse> => {
    const resp = await ajax
        .post(reverse("financingapi:prequal"))
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data);
    return check(FinancingPreQualRequestResponse.decode(resp.body));
};

export const setPreQualCustomerResponse = async (
    customerResp: PreQualCustomerResponse,
): Promise<FinancingPreQualResponse> => {
    const data = {
        customer_response: customerResp,
    };
    const resp = await ajax
        .post(reverse("financingapi:prequal-customer-response"))
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data);
    return check(FinancingPreQualResponse.decode(resp.body));
};

export const sendPreScreenOfferDisplayConfirmation = async (
    uniqueId: string,
): Promise<void> => {
    // obtain the API endpoint from config
    const apiURL = config.get("WFRS_PRESCREEN_API");
    // API endpoint expects the CORS request parameters to be encoded with x-www-form-urlencoded
    // According to the docs (as of 2020-01-29), it really is `offerDisplyed`, not `offerDisplayed`
    const data = `uniqueId=${uniqueId}&offerDisplyed=Y`;
    return ajax
        .post(apiURL)
        .set("Content-Type", "application/x-www-form-urlencoded")
        .send(data)
        .then(() => {
            return;
        });
};

export const updatePreScreenChoiceValue = async (
    choice: PreQualCustomerResponse,
    uniqueId: string,
): Promise<void> => {
    // obtain the API endpoint from config
    const apiURL = config.get("WFRS_PRESCREEN_API");

    // use remind_me as default response
    let buttonId = "remind_me";
    if (choice === PreQualCustomerResponse.ACCEPT) {
        buttonId = "apply_now";
    }

    // API endpoint expects the CORS request parameters to be encoded with x-www-form-urlencoded
    return ajax
        .post(apiURL)
        .set("Content-Type", "application/x-www-form-urlencoded")
        .send(`uniqueId=${uniqueId}&buttonId=${buttonId}`)
        .then(() => {
            return;
        });
};
