import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { t, ngettext, msgid } from "ttag";
import { range } from "../utils/functional";
import { formatNumber } from "../utils/format";
import { modulo } from "../utils/math";
import styles from "./RatingGraphic.module.scss";
import iconStar from "../../img/icons/star.svg";
import iconStarOutline from "../../img/icons/star-outline.svg";
import iconStarHalfOutline from "../../img/icons/star-half-outline.svg";
import iconStarWithStroke from "../../img/icons/star-with-stroke.svg";

interface IProps {
    cardClass: string;
    cardSize: string;
    rating: number | null;
    numReviews?: number;
    onlyReviewsNum?: boolean;
    onlyStars?: boolean;
    starHasStroke?: boolean;
    starRatingClass?: string;
    reviewNumberClass?: string;
}

interface IState {}

export class RatingGraphic extends React.Component<IProps, IState> {
    private readonly buildLinearGradient = () => {
        const style = {
            width: "0",
            height: "0",
        };

        return (
            <svg aria-hidden="true" style={style}>
                <linearGradient id="halfGradient">
                    <stop
                        className="star-rating__star--half-star-color"
                        stopOpacity="1"
                        offset="50%"
                    ></stop>
                    <stop stopOpacity="0" offset="50%"></stop>
                </linearGradient>
            </svg>
        );
    };

    render() {
        const cardClass = this.props.cardClass;
        const cardSize = this.props.cardSize;
        const rating = this.props.rating;
        const numReviews = this.props.numReviews;
        const onlyReviewsNum = this.props.onlyReviewsNum;
        const propStarRatingClass = this.props.starRatingClass
            ? this.props.starRatingClass
            : "false";
        const propReviewNumberClass = this.props.reviewNumberClass
            ? this.props.reviewNumberClass
            : "false";

        // Build star rating class
        const starRatingClass = classNames({
            "star-rating": true,
            [cardClass + "__star-rating"]: true,
            [cardClass + "--" + cardSize + "__star-rating"]: true,
            [propStarRatingClass]: this.props.starRatingClass ? true : false,
            [styles.starRating]: cardClass === "star-rating",
        });

        // Build star rating number class
        const starRatingNumberClass = classNames({
            "star-rating__number": true,
            [cardClass + "__star-rating__number"]: true,
        });

        // Build review number class
        const reviewNumberClass = classNames({
            "star-rating__review-number": true,
            [cardClass + "__star-rating__review-number"]: true,
            [propReviewNumberClass]: this.props.reviewNumberClass
                ? true
                : false,
        });

        // Create array of svg star elements for the feel scale
        const options = range(5);
        let halfOutline: boolean | undefined = false;
        let outline: boolean | undefined = false;

        const halfStarMin = 0.25;
        const halfStarMax = 0.75;
        const stars = options.map((r) => {
            const fractionalPart = rating ? modulo(rating, 1) : 0;
            const showHalfStar =
                fractionalPart >= halfStarMin && fractionalPart < halfStarMax;
            // Can't use <use> tag in React, so injecting it as html is the workaround
            // Check if current star is below the rating...
            let svgPath = iconStar;

            if (
                rating === null ||
                rating === undefined ||
                Number.isNaN(rating)
            ) {
                // If rating is null, undefined or NaN, always show hollow stars
                svgPath = iconStarOutline;
                outline = this.props.starHasStroke;
            } else if (rating && rating < r) {
                if (rating > r - 1) {
                    // Check if current star is a half star...
                    if (showHalfStar) {
                        svgPath = iconStarHalfOutline;
                        halfOutline = this.props.starHasStroke;
                    } else if (fractionalPart < halfStarMin) {
                        svgPath = iconStarOutline;
                        outline = this.props.starHasStroke;
                    }
                } else {
                    svgPath = iconStarOutline;
                    outline = this.props.starHasStroke;
                }
            }

            // Do not change halfStar svgPath
            if (this.props.starHasStroke && !showHalfStar) {
                svgPath = iconStarWithStroke;
            }

            // Build star class
            const starClass = classNames({
                "star-rating__star": true,
                "star-rating__star--half-outline": halfOutline && !showHalfStar,
                "star-rating__star--outline": outline,
                [cardClass + "__star"]: true,
            });

            return (
                <SVG
                    key={r}
                    className={starClass}
                    src={svgPath}
                    aria-hidden="true"
                />
            );
        });

        const reviewScreenReaderText = (
            <span className="ada-screenreader-only">
                {t`Rated ${rating} out of 5 stars`}
            </span>
        );

        let starRatingElem: JSX.Element | null = null;
        if (this.props.onlyStars) {
            starRatingElem = (
                <div className="star-rating__stars">
                    {reviewScreenReaderText}
                    {stars}
                </div>
            );
        } else if (typeof numReviews !== "undefined") {
            if (!onlyReviewsNum) {
                const reviewCountLabel = ngettext(
                    msgid`Review`,
                    `Reviews`,
                    numReviews,
                );
                starRatingElem = (
                    <>
                        <div className="star-rating__stars">
                            {reviewScreenReaderText}
                            {stars}
                        </div>
                        <div className={reviewNumberClass}>
                            {formatNumber(numReviews)} {reviewCountLabel}
                        </div>
                    </>
                );
            } else {
                starRatingElem = (
                    <>
                        <div className="star-rating__stars">
                            {reviewScreenReaderText}
                            {stars}
                        </div>
                        <div className={reviewNumberClass}>
                            {formatNumber(numReviews)}
                        </div>
                    </>
                );
            }
        } else {
            starRatingElem = (
                <>
                    <div className="star-rating__stars">
                        {reviewScreenReaderText}
                        {stars}
                    </div>
                    <div className={starRatingNumberClass}>{rating}</div>
                    {this.props.starHasStroke
                        ? this.buildLinearGradient()
                        : null}
                </>
            );
        }

        return <div className={starRatingClass}>{starRatingElem}</div>;
    }
}

export default RatingGraphic;
