import React from "react";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { baseVariantSelector, upgradeBundlesSelector } from "../selectors";
import format from "../../../utils/format";
import { InternalProduct } from "../../../models/catalogue";
import { IProductID, IImageURL } from "../../../models/nominals";
import {
    IProduct,
    IConcreteBundle,
    IAddon,
    IBundleGroup,
} from "../../../models/catalogue.interfaces";
import { PriceType, getProductPrice } from "../../../utils/catalogue";
import { ZERO } from "../../../utils/money";
import { getUpgradeID } from "../selectors";
import { OptionPriceType } from "./ConfiguratorUpsells";
import { ConfiguratorUpsell } from "./ConfiguratorUpsell";

interface IOwnProps {
    optionPriceType: OptionPriceType;
    bundleType: string;
    selectedUpgrades: IAddon[];
    onSelectionChange: (
        updates: Array<{ productID: IProductID; quantity: number }>,
    ) => void;
}

interface IReduxProps {
    baseVariant: IProduct | null;
    upgradeBundles: IConcreteBundle[];
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class ConfiguratorUpsellContainerComponent extends React.PureComponent<
    IProps,
    IState
> {
    private buildProduct(suggested_products: InternalProduct[]) {
        let currentProduct = suggested_products[0];
        suggested_products.forEach((product) => {
            const upgradeID = getUpgradeID(product);
            const selectedUpgrade = this.props.selectedUpgrades.find(
                (addon) => addon.productID === upgradeID,
            );
            if (selectedUpgrade) {
                currentProduct = product;
            }
        });
        return currentProduct;
    }

    private isSelected(upgradeID: IProductID) {
        const selectedUpgrade = this.props.selectedUpgrades.find(
            (addon) => addon.productID === upgradeID,
        );
        if (selectedUpgrade) {
            return true;
        }
        return false;
    }

    private buildUpsellProductName(product: InternalProduct) {
        if (product.parent) {
            if (product.parent.attributes.upgrade_option_title) {
                return product.parent.attributes.upgrade_option_title.value;
            }
            return product.parent.title;
        }

        if (product.attributes.upgrade_option_title) {
            return product.attributes.upgrade_option_title.value;
        }

        return product.title;
    }

    private buildPrice(product: InternalProduct) {
        if (this.props.optionPriceType === OptionPriceType.FREE_GIFT) {
            const price = getProductPrice(product.price, {
                priceType: PriceType.RETAIL,
                includePostDiscountAddons: true,
                quantity: 1,
            });
            return `(${format.money(price)} Value)`;
        }

        let basePrice = ZERO;
        if (
            this.props.baseVariant &&
            this.props.optionPriceType === OptionPriceType.RELATIVE
        ) {
            basePrice = getProductPrice(this.props.baseVariant.price, {
                priceType: PriceType.COSMETIC_EXCL_TAX,
                includePostDiscountAddons: true,
                quantity: 1,
            });
        }
        const upgradePrice = getProductPrice(product.price, {
            priceType: PriceType.COSMETIC_EXCL_TAX,
            includePostDiscountAddons: true,
            quantity: 1,
        });
        const priceDiff = upgradePrice.subtract(basePrice);
        return `+ ${format.money(priceDiff)}`;
    }

    private buildImage(
        product: InternalProduct,
        bundle_group: IBundleGroup,
    ): IImageURL | null {
        const bundleImage = bundle_group.image;
        if (bundleImage) {
            return bundleImage;
        }
        const productImage = product.parent
            ? product.parent.images[0]
            : product.images[0];
        if (productImage) {
            return productImage.original;
        }
        return null;
    }

    render() {
        if (!this.props.baseVariant) {
            return null;
        }
        if (this.props.upgradeBundles.length <= 0) {
            return null;
        }
        const upgradeOptionElems = this.props.upgradeBundles.map((bundle) => {
            const cardProduct = this.buildProduct(bundle.suggested_products);
            const upgradeID = getUpgradeID(cardProduct);
            return (
                <ConfiguratorUpsell
                    key={bundle.id}
                    bundleType={this.props.bundleType}
                    bundleID={bundle.bundle_group.id}
                    sectionTitle={bundle.bundle_group.description}
                    lineOne={bundle.bundle_group.headline}
                    lineTwo={this.buildUpsellProductName(cardProduct)}
                    lineThree={this.buildPrice(cardProduct)}
                    productUpgradeID={upgradeID}
                    isSelected={this.isSelected(upgradeID)}
                    // selectedUpgrades={this.props.selectedUpgrades}
                    cardImage={this.buildImage(
                        cardProduct,
                        bundle.bundle_group,
                    )}
                />
            );
        });
        return <>{upgradeOptionElems}</>;
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    const baseVariant = baseVariantSelector(state);
    return {
        concreteBundles: state.entities.concreteBundles,
        baseVariant: baseVariant,
        upgradeBundles: upgradeBundlesSelector(
            state.entities.concreteBundles,
            baseVariant,
            ownProps.bundleType,
        ),
        ...ownProps,
    };
};

export const ConfiguratorUpsellContainer = connect(mapStateToProps)(
    ConfiguratorUpsellContainerComponent,
);
