import * as t from "io-ts";
import { codecFromEnum } from "./utils";

export interface SynchronyModalData {
    // REQUIRED FIELDS
    tokenId: string;
    syfPartnerId: string;
    merchantID: string;
    processInd: string;
    clientTransId: string;
    custAddress1: string;
    custAddress2: string;
    custCity: string;
    custState: string;
    custZipCode: string;
    transAmount1: string;
    transPromo1: string;

    // OPTIONAL FIELDS
    custFirstName?: string;
    custLastName?: string;
    cardNumber?: string;
    expMonth?: string;
    expYear?: string;
    iniPurAmt?: string;
    phoneNumber?: string;
    emailAddress?: string;
}

export interface SyfWidgetConfig {
    syfPartnerId: string;
    flowType: string;
}

export interface SyfMPP {
    calldBuyProcess(formID: null, data: SynchronyModalData): void;
    mppFromAnyWhere(initialAmount?: string): void;
}

export const DPOSTokenResponse = t.interface({
    token: t.string,
});
export type DPOSTokenResponse = t.TypeOf<typeof DPOSTokenResponse>;

export const UnifyModalCloseEvent = t.interface({
    data: t.literal("Unifi Modal Close"),
});

export enum SYFTransactionCode {
    SUCCESS = "033", // Customer Approval Success
    CUSTOMER_TERMINATED = "100", // Customer Terminated
    TOKEN_NOT_FOUND = "400", // Required input not provided/Input validation error (Token not found in DB)
    TXN_FAILED = "401", // Transaction Failed (Token Expired Scenario)
    AVS_FAILED = "403", // Address Verification Check Fail
    SYSTEM_FAILED = "500", // System Exception
}

const MerchantInfo = t.partial({
    merchantNumber: t.string,
    verificationId: t.string,
    correlationId: t.string,
});

const CustomerInfo = t.partial({
    "cipher.firstName": t.string,
    "cipher.lastName": t.string,
    "address": t.partial({
        "cipher.addressLine1": t.string,
        "cipher.addressLine2": t.string,
        "cipher.city": t.string,
        "cipher.state": t.string,
        "cipher.zipCode": t.string,
    }),
});

const AccountInfo = t.interface({
    "cipher.accountNumber": t.string,
});

const TransactionInfo = t.interface({
    amount: t.string,
    dateTime: t.string,
    description: t.string,
    authorizationCode: t.string,
    promoCode: t.string,
    promoType: t.string,
});

const CreditAuthInfo = t.intersection([
    t.interface({
        transactionCode: codecFromEnum(
            "SYFTransactionCode",
            SYFTransactionCode,
        ),
        transactionMessage: t.string,
    }),
    t.partial({
        merchantInfo: MerchantInfo,
        customerInfo: CustomerInfo,
        accountInfo: AccountInfo,
        transactionInfo: TransactionInfo,
    }),
]);

export const TransactionLookupResponse = t.interface({
    inquiryType: t.literal("BUY"),
    creditAuthorizationInfo: CreditAuthInfo,
});
export type TransactionLookupResponse = t.TypeOf<
    typeof TransactionLookupResponse
>;
