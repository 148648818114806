import React from "react";

interface IProps {
    className: string;
    heading: string;
    isCurrentStep?: boolean;
    children?: React.ReactNode;
}

interface IState {}

export class CheckoutStepContainer extends React.Component<IProps, IState> {
    render() {
        return (
            <div
                role="group"
                className={`checkout-step ${this.props.className}`}
                aria-label={this.props.heading}
            >
                <h2
                    className="checkout-step__heading"
                    aria-current={this.props.isCurrentStep ? "step" : false}
                >
                    {this.props.heading}
                </h2>
                {this.props.children}
            </div>
        );
    }
}
