import { ajax, CSRF_HEADER, getCSRFToken } from "../utils/ajax";
import { IAddress } from "../models/address.interfaces";
import {
    IPayment,
    IPaymentStates,
    IOrderResponse,
} from "../models/checkout.interfaces";
import { PromiseMutex } from "../utils/mutex";

export const listEnabledPaymentMethods = async () => {
    const mutex = new PromiseMutex<string[]>("list-enabled-payment-methods");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/checkout/payment-methods/")
            .set("Accept", "application/json")
            .then((resp) => {
                return Object.keys(resp.body);
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const saveEmailAddress = async (
    email: string,
): Promise<{ owner_email: string }> => {
    return ajax
        .post("/api/basket/email/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send({
            owner_email: email,
        })
        .then((resp): { owner_email: string } => {
            return resp.body;
        });
};

export const loadEmailAddress = async () => {
    const mutex = new PromiseMutex<{ owner_email: string }>(
        "load-basket-email-address",
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/basket/email/")
            .set("Accept", "application/json")
            .then((resp): { owner_email: string } => {
                return resp.body;
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const saveShippingAddress = async (
    shippingAddress: IAddress,
    bypassUSPSValidation = false,
): Promise<IAddress> => {
    const data = {
        ...shippingAddress,
        bypass_validation: bypassUSPSValidation ? "t" : "f",
    };
    return ajax
        .post("/api/checkout/shipping-address/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data)
        .then((resp): IAddress => {
            return resp.body;
        });
};

export const loadShippingAddress = async () => {
    const mutex = new PromiseMutex<IAddress>("load-shipping-address");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/checkout/shipping-address/")
            .set("Accept", "application/json")
            .then((resp): IAddress => {
                return resp.body;
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const clearShippingAddress = async (): Promise<void> => {
    return ajax
        .delete("/api/checkout/shipping-address/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .then((resp): void => {
            return resp.body;
        });
};

export const saveBillingAddress = async (
    billingAddress: IAddress,
    bypassUSPSValidation = false,
): Promise<IAddress> => {
    const data = {
        ...billingAddress,
        bypass_validation: bypassUSPSValidation ? "t" : "f",
    };
    return ajax
        .post("/api/checkout/billing-address/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data)
        .then((resp): IAddress => {
            return resp.body;
        });
};

export const loadBillingAddress = async () => {
    const mutex = new PromiseMutex<IAddress>("load-billing-address");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/checkout/billing-address/")
            .set("Accept", "application/json")
            .then((resp): IAddress => {
                return resp.body;
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const clearBillingAddress = async (): Promise<void> => {
    return ajax
        .delete("/api/checkout/billing-address/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .then((resp): void => {
            return resp.body;
        });
};

export const placeOrder = async (
    basketURL: string,
    shippingAddress: IAddress,
    shippingMethodCode: string,
    billingAddress: IAddress,
    payment: IPayment,
    guestEmail?: string,
    recaptcha?: string,
): Promise<IOrderResponse> => {
    const body = {
        guest_email: guestEmail,
        basket: basketURL,
        shipping_address: shippingAddress,
        shipping_method_code: shippingMethodCode,
        billing_address: billingAddress,
        payment: payment,
        recaptcha: recaptcha,
    };

    return ajax
        .post("/api/checkout/")
        .set(CSRF_HEADER, await getCSRFToken())
        .set("Accept", "application/json")
        .send(body)
        .then((resp): IOrderResponse => {
            return resp.body;
        });
};

export const completeDeferredPayment = async (
    basketToken: string,
    orderToken: string,
    payment: IPayment,
): Promise<IOrderResponse> => {
    const body = {
        order: orderToken,
        payment: payment,
    };
    return ajax
        .post("/api/checkout/complete-deferred-payment/")
        .set(CSRF_HEADER, await getCSRFToken())
        .set("Accept", "application/json")
        .query({
            "basket-token": basketToken,
        })
        .send(body)
        .then((resp): IOrderResponse => {
            return resp.body;
        });
};

export const checkPaymentStates = async (): Promise<IPaymentStates> => {
    return ajax
        .get("/api/checkout/payment-states/")
        .set("Accept", "application/json")
        .then((resp): IPaymentStates => {
            return resp.body;
        });
};
