import { IUser } from "../../models/user.interfaces";

export type IChatInitReturn = {
    isOnline: boolean;
};

export type IChatCampaignData = {
    campaignKey: string;
};

export type IChatCampaignContext = {
    eventKey: string;
};

export abstract class ChatConnector {
    /**
     * Variable used to store bits of data we need to send to the agent upon start
     * of a chat session.
     */
    protected readonly agentMessages: { [key: string]: string } = {};

    /**
     * Ensure that the chat connector code is loaded on the page. This is a no-op if it was already loaded / loading.
     * Returns a boolean with the agent-online status.
     */
    public abstract init(): Promise<IChatInitReturn>;

    /**
     * Make the user's email and name available to the chat agent.
     */
    public abstract setUserData(user: IUser): Promise<void>;

    /**
     * Open a chat window
     */
    public abstract openChat(): Promise<void>;

    /**
     * Open a proactive chat window
     */
    public abstract openProactiveChat(message: string): Promise<void>;

    /**
     * Trigger a campaign
     */
    public abstract triggerCampaign(campaignKey: string): Promise<void>;

    /**
     * Register a message to be sent to the chat agent upon starting a new chat session.
     */
    public registerChatAgentMessage(key: string, message: string | null) {
        if (message) {
            this.agentMessages[key] = message;
        } else {
            delete this.agentMessages[key];
        }
    }
}
