import { createSelector } from "@reduxjs/toolkit";
import { getProductID } from "../../api/products";
import { union, intersection } from "../../utils/sets";
import { notEmpty, objectKeys, range } from "../../utils/functional";
import { IProductID, IProductUUID } from "../../models/nominals";
import {
    IProduct,
    IAddon,
    IConcreteBundle,
    IOptionCode,
} from "../../models/catalogue.interfaces";
import {
    IAPIPrice,
    IPrice,
    IPriceQuantity,
} from "../../models/prices.interfaces";
import { BundleGroupTypes } from "../../constants";
import { getProductVariant } from "../selectors";
import { getDefaultRootProduct } from "./utils";
import {
    IModularConfiguratorUpsellInfoModal,
    IPLCOptionPanel,
    IPLCProductCategorySelector,
} from "./models.interfaces";
import {
    IHistogramEntry,
    IReviewsProduct,
} from "../../models/reviews.interfaces";
import {
    RootSelectionMode,
    ISelectedCategories,
    IReduxState,
} from "./reducers.interfaces";
import { sumPriceQuantities } from "../../utils/money";

/**
 * Given a product being displayed as an upgrade option, get the ID that should be used
 * in the Redux store. If the product is a child product, this is the parent's ID. Otherwise,
 * it's the products ID.
 */
export const getUpgradeID = (product: IProduct) => {
    return product.parent ? product.parent.id : product.id;
};

/**
 * Return a list of currently selected root products
 */
export const rootProductsSelector = (state: IReduxState): IProduct[] => {
    let rootProducts: IProduct[] = [];
    if (state.ui.selectedRoot?.type === RootSelectionMode.DIRECT) {
        const rootProductID = state.ui.selectedRoot.productID;
        const directRootProduct = state.entities.rootProducts.find(
            (p) => p.id === rootProductID,
        );
        if (directRootProduct) {
            rootProducts = [directRootProduct];
        }
    }
    if (state.ui.selectedRoot?.type === RootSelectionMode.CATEGORY) {
        const selectedCategories = new Set(
            Object.values(state.ui.selectedRoot.categories),
        );
        rootProducts = state.entities.rootProducts.filter((p) => {
            const overlap = intersection(
                new Set(p.category_ids),
                selectedCategories,
            );
            return overlap.size >= selectedCategories.size;
        });
    }
    if (rootProducts.length <= 0) {
        rootProducts = [getDefaultRootProduct(state.entities.rootProducts)];
    }
    return rootProducts.filter(notEmpty);
};

export const variantPrefilterSelector = (state: IReduxState) => {
    return state.entities.variantPrefilter;
};

export const categoriesSelector = (
    state: IReduxState,
): IPLCProductCategorySelector[] => {
    if (!state.entities.categorySelectors) {
        return [];
    }
    return state.entities.categorySelectors;
};

export const selectedCategoriesSelector = (
    state: IReduxState,
): ISelectedCategories => {
    if (state.ui.selectedRoot?.type !== RootSelectionMode.CATEGORY) {
        return {};
    }
    return state.ui.selectedRoot.categories;
};

export const previouslySelectedCategoriesSelector = (
    state: IReduxState,
): ISelectedCategories => {
    if (
        state.ui.previousSelectionState?.selectedRoot?.type !==
        RootSelectionMode.CATEGORY
    ) {
        return {};
    }
    return state.ui.previousSelectionState?.selectedRoot.categories;
};

export const optionPanelsSelector = (state: IReduxState): IPLCOptionPanel[] => {
    if (!state.entities.optionPanels) {
        return [];
    }
    return state.entities.optionPanels;
};

/**
 * Return the currently selected option values.
 */
export const optionValueSelector = (state: IReduxState) => {
    const vals: typeof state.ui.optionValues = {};
    for (const key of objectKeys(state.ui.optionValues)) {
        if (
            state.ui.optionValues[key] !== undefined &&
            state.ui.optionValues[key] !== null
        ) {
            vals[key] = state.ui.optionValues[key];
        }
    }
    if (state.entities.variantPrefilter) {
        vals[state.entities.variantPrefilter.attr] =
            state.entities.variantPrefilter.value;
    }
    return vals;
};

/**
 * Return the currently loaded product bundles
 */
export const concreteBundlesSelector = (state: IReduxState) => {
    return state.entities.concreteBundles;
};

/**
 * Return the currently selected upgrade ID
 */
export const upgradeIDSelector = (state: IReduxState) => {
    return state.ui.selectedUpgrade;
};

/**
 * Return the currently selected Add-On Product IDs
 */
export const addonsSelector = (state: IReduxState) => {
    return state.ui.selectedAddons;
};

/**
 * Return the currently loaded price object
 */
export const loadedPriceSelector = (state: IReduxState) => {
    return state.entities.price;
};

/**
 * Return the currently selected quantity
 */
export const quantitySelector = (state: IReduxState) => {
    return state.ui.quantity;
};

/**
 * Given a root product and the currently selected option values, determine which variant of the root
 * product (if there are any variants of the root product) is actually selected.
 */
export const baseVariantSelector = createSelector(
    rootProductsSelector,
    optionValueSelector,
    getProductVariant,
);

const getRootForVariant = (
    rootProducts: IProduct[],
    variant: IProduct | null,
): IProduct | null => {
    if (!variant && rootProducts.length === 1) {
        return rootProducts[0];
    }
    if (!variant || rootProducts.length <= 0) {
        return null;
    }
    for (const rootProduct of rootProducts) {
        const vids = new Set(rootProduct.children.map((c) => c.id));
        if (variant.id === rootProduct.id || vids.has(variant.id)) {
            return rootProduct;
        }
    }
    return variant;
};

/**
 * Return the root product for the currently selected base variant
 */
export const rootProductSelector = createSelector(
    rootProductsSelector,
    baseVariantSelector,
    getRootForVariant,
);

/**
 * Return the list of option codes used to narrow variants in the configurator
 */
export const optionCodesSelector = createSelector(
    rootProductSelector,
    variantPrefilterSelector,
    (rootProduct, variantPrefilter) => {
        if (!rootProduct) {
            return [];
        }
        const optionCodes = [
            ...(rootProduct.attributes.product_options?.value || []),
        ];
        // If feel option exists, move it to the end (to sort correctly for the UI)
        // TODO: Find more elegant solution to coincide with different order in the grid card
        const hasFeel = optionCodes.indexOf("option_feel");
        if (hasFeel !== -1) {
            optionCodes.push(optionCodes.splice(hasFeel, 1)[0]);
        }
        // If level option exists, move THAT to the end
        // TODO: Seriously, we need a better sorting solution here
        const hasLevel = optionCodes.indexOf("option_level");
        if (hasLevel !== -1) {
            optionCodes.push(optionCodes.splice(hasLevel, 1)[0]);
        }
        // Remove the prefiltered attr
        if (variantPrefilter) {
            const pfIndex = optionCodes.indexOf(variantPrefilter.attr);
            optionCodes.splice(pfIndex, 1);
        }
        return optionCodes;
    },
);

/**
 * Given the base selected product variant, a list of product bundles, and the currently selected
 * product upgrade ID, return the product variant (either base or upgraded) that is actually
 * selected.
 */
export const upgradedVariantSelector = createSelector(
    baseVariantSelector,
    concreteBundlesSelector,
    upgradeIDSelector,
    (baseVariant, bundles, selectedUpgradeID) => {
        if (!selectedUpgradeID) {
            return baseVariant;
        }

        const upgradeBundles = bundles.filter((bundle) => {
            return (
                bundle.bundle_group.bundle_type ===
                BundleGroupTypes.UPGRADE_PRODUCT
            );
        });

        if (upgradeBundles.length <= 0) {
            return baseVariant;
        }

        const selectedVariant = upgradeBundles.reduce((memo, bundle) => {
            const upgradeVariant = bundle.suggested_products.find((product) => {
                const upgradeID = getUpgradeID(product);
                return upgradeID === selectedUpgradeID;
            });
            return upgradeVariant ? upgradeVariant : memo;
        }, baseVariant);

        return selectedVariant;
    },
);

/**
 * Return the root product for the currently selected upgraded variant
 */
export const upgradedRootProductSelector = createSelector(
    rootProductsSelector,
    upgradedVariantSelector,
    getRootForVariant,
);

/**
 * Return the price for the selected upgraded variant
 */
export const upgradedVariantPriceSelector = createSelector(
    upgradedVariantSelector,
    loadedPriceSelector,
    quantitySelector,
    (upgradedVariant, price, quantity) => {
        if (!upgradedVariant) {
            return null;
        }
        if (quantity === 1) {
            return {
                product: upgradedVariant.url,
                quantity: 1,
                unit: upgradedVariant.price,
                total: upgradedVariant.price,
            };
        }
        if (!price) {
            return null;
        }
        const priceProductID = getProductID(price.product);
        if (
            upgradedVariant.id !== priceProductID ||
            quantity !== price.quantity
        ) {
            return null;
        }
        return price;
    },
);

/**
 * Return the selected add-on products
 */
export const addonVariantsSelector = createSelector(
    concreteBundlesSelector,
    addonsSelector,
    (bundles, selectedAddons): IAddon[] => {
        if (!selectedAddons || !selectedAddons.length) {
            return [];
        }
        const selectedParentAddons = new Map(
            selectedAddons.map((addon) => [addon.productID, addon.quantity]),
        );
        const addonBundles = bundles.filter((bundle) => {
            return (
                bundle.bundle_group.bundle_type ===
                BundleGroupTypes.IN_CONFIGURATOR_ADD_ON
            );
        });
        if (addonBundles.length <= 0) {
            return [];
        }
        const selectedAddonVariants = addonBundles.reduce<IAddon[]>(
            (memo, bundle) => {
                const addonVariants = bundle.suggested_products
                    .filter((variant) =>
                        selectedParentAddons.has(getUpgradeID(variant)),
                    )
                    .map((addonVariant) => {
                        return {
                            productID: addonVariant.id,
                            quantity:
                                selectedParentAddons.get(
                                    getUpgradeID(addonVariant),
                                ) || 1,
                        };
                    });
                return memo.concat(addonVariants);
            },
            [],
        );
        return selectedAddonVariants;
    },
);

/**
 * Return the total price of the selected add-on products
 */
export const addonVariantPricesSelector = createSelector(
    concreteBundlesSelector,
    addonVariantsSelector,
    (bundles, selectedAddons): IPrice | null => {
        const variantPrices = new Map(
            bundles
                .reduce<
                    IProduct[]
                >((memo, bundle) => memo.concat(bundle.suggested_products), [])
                .map((variant): [IProductID, IPrice] => [
                    variant.id,
                    variant.price,
                ]),
        );
        const priceQuantities = selectedAddons
            .map((addonVariant): [number, IPrice | undefined] => [
                addonVariant.quantity,
                variantPrices.get(addonVariant.productID),
            ])
            .filter((priceQty): priceQty is IPriceQuantity => !!priceQty[1]);
        const total = sumPriceQuantities(priceQuantities);
        return total;
    },
);

export const getVariantPrice = (variant: IProduct): IAPIPrice => {
    const price: IAPIPrice = {
        product: variant.url,
        quantity: 1,
        total: variant.price,
        unit: variant.price,
    };
    return price;
};

export const upgradeBundlesSelector = (
    concreteBundles: IConcreteBundle[],
    baseVariant: IProduct | null,
    bundleType: string,
): IConcreteBundle[] => {
    const upgradeBundles = concreteBundles.filter((bundle) => {
        const matchesProduct =
            baseVariant && bundle.triggering_product === baseVariant.id;
        const isCorrectType = bundle.bundle_group.bundle_type === bundleType;
        return matchesProduct && isCorrectType;
    });
    return upgradeBundles;
};

export const getUpsellInfoModal = (
    state: IReduxState,
    props: {
        bundleID: number | null;
    },
): IModularConfiguratorUpsellInfoModal | null => {
    const modals = state.entities.upsellInfoModals;
    if (!props.bundleID) {
        return null;
    }
    const modal = modals.find((m) => {
        return m.bundle === props.bundleID;
    });
    return modal ?? null;
};

export const productAttributeOptionGroupSelector = (
    state: IReduxState,
    code: IOptionCode,
) => {
    if (!state.entities.productClass) {
        return null;
    }
    const attr = state.entities.productClass.attributes.find((attribute) => {
        return attribute.code === code;
    });
    return attr ? attr.option_group : null;
};

/**
 * Given an array of reviews products and the selected root Oscar product, return an
 * array of histogram entries showing the combined review distribution for the products.
 */
export const reviewHistogramSelector = (
    reviewsProducts: IReviewsProduct[],
    selectedRootProductUUID: IProductUUID | null,
): IHistogramEntry[] => {
    let uuids = new Set<IProductUUID>();
    const products = reviewsProducts
        .sort((x, y) => {
            if (selectedRootProductUUID) {
                if (x.uuid === selectedRootProductUUID) {
                    return -1;
                } else if (y.uuid === selectedRootProductUUID) {
                    return 1;
                }
                return 0;
            }
            return 0;
        })
        // De-dupe reviews products on UUID
        .filter((p, idx) => {
            // Get an array of component_of_uuids array of the products except the indexed one
            // to check if it contains the exact same array of component_of_uuids of this product
            const otherReviewsProducts = reviewsProducts.slice();
            otherReviewsProducts.splice(idx, 1);
            const pCompOfUUIDs = otherReviewsProducts.map(
                (product) => product.component_of_uuids,
            );
            // If this product or one of it's componentOf products has already been
            // included, skip this instance of it.
            const pUUIDs = union(
                new Set(p.uuid ? [p.uuid] : []),
                new Set(p.component_of_uuids || []),
            );
            if (
                (intersection(uuids, pUUIDs).size > 0 &&
                    intersection(
                        new Set(
                            pCompOfUUIDs.reduce((arr, el) => {
                                return el ? arr?.concat(el) : arr;
                            }),
                        ),
                        new Set(p.component_of_uuids),
                    ).size <= 0) ||
                // If this product is one of others componentOf products, skip this instance of it.
                (p.uuid !== selectedRootProductUUID &&
                    pCompOfUUIDs.some((pCompOfUUID) =>
                        p.uuid ? pCompOfUUID?.includes(p.uuid) : false,
                    ))
            ) {
                return false;
            }

            // Include this product, but log its top-level UUID and all of it's
            // componentOf UUID to make sure we don't include any of those
            // again in the future.
            uuids = union(uuids, pUUIDs);
            return true;
        });
    const rawHistogram = products.reduce<IHistogramEntry[]>(
        (memo, p) => memo.concat(p.histogram),
        [],
    );
    const combinedHistogram = range(5)
        .reverse()
        .map((rating): IHistogramEntry => {
            const entries = rawHistogram.filter(
                (entry) => entry.rating === rating,
            );
            return {
                rating: rating,
                count: entries.reduce((memo, entry) => memo + entry.count, 0),
            };
        });
    return combinedHistogram;
};
