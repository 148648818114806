import { TupleOf } from "../models/utils";
import { mapTuple } from "./functional";

interface AbstractVectorSpaceDimension<Entity> {
    name: string;
    showInDiff: boolean;
    getCoordinateFromEntity: (entity: Entity) => number;
    getDescriptionFromEntity: (entity: Entity) => string;
}

export class VectorSpaceDimension<Entity, Option>
    implements AbstractVectorSpaceDimension<Entity>
{
    readonly name: string;
    readonly sortedOptions: Option[];
    readonly _getCoordinateFromEntity: (
        entity: Entity,
        options: Option[],
    ) => number;
    readonly _getCoordinateDescription: (option: Option) => string;
    readonly showInDiff: boolean;

    constructor(
        name: string,
        sortedOptions: Option[],
        getCoordinateFromEntity: (entity: Entity, options: Option[]) => number,
        getCoordinateDescription: (option: Option) => string,
        showInDiff = true,
    ) {
        this.name = name;
        this.sortedOptions = sortedOptions;
        this._getCoordinateFromEntity = getCoordinateFromEntity;
        this._getCoordinateDescription = getCoordinateDescription;
        this.showInDiff = showInDiff;
    }

    public getCoordinateFromEntity(entity: Entity): number {
        return this._getCoordinateFromEntity(entity, this.sortedOptions);
    }

    public getDescriptionFromEntity(entity: Entity): string {
        const coord = this.getCoordinateFromEntity(entity);
        const option = this.sortedOptions[coord];
        return this._getCoordinateDescription(option);
    }
}

export class VectorSpacePoint<Entity, NumDimensions extends number> {
    readonly entity: Entity;
    readonly dimensions: TupleOf<
        AbstractVectorSpaceDimension<Entity>,
        NumDimensions
    >;

    constructor(
        entity: Entity,
        dimensions: TupleOf<
            AbstractVectorSpaceDimension<Entity>,
            NumDimensions
        >,
    ) {
        this.entity = entity;
        this.dimensions = dimensions;
    }

    get coordinates(): TupleOf<number, NumDimensions> {
        const point = mapTuple(this.dimensions, (dimension) => {
            return dimension.getCoordinateFromEntity(this.entity);
        });
        return point;
    }
}

export type PointDifferenceRow = {
    dimensionName: string;
    showInDiff: boolean;
    aDescr: string;
    bDescr: string;
};

export class VectorSpace<Entity, NumDimensions extends number> {
    readonly dimensions: TupleOf<
        AbstractVectorSpaceDimension<Entity>,
        NumDimensions
    >;

    constructor(
        dimensions: TupleOf<
            AbstractVectorSpaceDimension<Entity>,
            NumDimensions
        >,
    ) {
        this.dimensions = dimensions;
    }

    public getDistance<T extends Entity, U extends Entity>(
        pointA: VectorSpacePoint<T, NumDimensions>,
        pointB: VectorSpacePoint<U, NumDimensions>,
    ): number {
        const coordsA = pointA.coordinates;
        const coordsB = pointB.coordinates;
        if (coordsA.length !== coordsB.length) {
            return Infinity;
        }
        const dist = Math.sqrt(
            coordsA
                .map((coordA, i) => (coordsB[i] - coordA) ** 2)
                .reduce((memo, coord) => memo + coord, 0),
        );
        return dist;
    }

    public sortEntityList<T extends Entity>(
        center: Entity,
        entitiesIn: T[],
    ): [number, T][] {
        const centerPoint = new VectorSpacePoint(center, this.dimensions);
        const entities = entitiesIn
            .map((entity): [number, T] => {
                const point = new VectorSpacePoint(entity, this.dimensions);
                const distance = this.getDistance(centerPoint, point);
                return [distance, entity];
            })
            .sort(([distA], [distB]) => {
                if (distA === distB) {
                    return 0;
                }
                return distA > distB ? 1 : -1;
            });
        return entities;
    }

    public describeDifference<T extends Entity, U extends Entity>(
        a: T,
        b: U,
    ): PointDifferenceRow[] {
        const diff: PointDifferenceRow[] = [];
        for (const dimension of this.dimensions) {
            const aDescr = dimension.getDescriptionFromEntity(a);
            const bDescr = dimension.getDescriptionFromEntity(b);
            if (aDescr !== bDescr) {
                diff.push({
                    dimensionName: dimension.name,
                    showInDiff: dimension.showInDiff,
                    aDescr: aDescr,
                    bDescr: bDescr,
                });
            }
        }
        return diff;
    }
}
