import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IWebPageURL, isoWebPageURL } from "../../../models/nominals";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { ITileID, isoTileID } from "../../../models/product-compare";
import { RatingGraphic } from "../../../common/RatingGraphic";
import { ConfiguratorPrice } from "../../../common/ConfiguratorPrice";
import { RichText } from "../../../common/RichText";
import { Link } from "../../../common/Link";
import { DiscountDisplayMode } from "../../configurator/constants";
import { getVariantPrice } from "../selectors";
import iconCircleClose from "../../../../img/icons/circle-close.svg";

interface IProps {
    data: ICompareTile;
    selectedVariant: IProduct | null;
    priceSelectValue: string;
    onRemoveButtonClick: (tileID: ITileID) => void;
}

interface IState {}

export class SelectedProduct extends React.Component<IProps, IState> {
    private readonly onRemoveButtonClick = () => {
        this.props.onRemoveButtonClick(this.props.data.product_compare_tile.id);
        this.removeFromURLParams(this.props.data.product_compare_tile.id);
    };

    private removeFromURLParams(tileID: ITileID) {
        const urlParams = new URLSearchParams(window.location.search);
        const selectedTileIDs = decodeURIComponent(urlParams.get("tiles") || "")
            .split(",")
            .map(Number)
            .map(isoTileID.wrap);
        const newTileIDs = selectedTileIDs.filter((id) => id !== tileID);

        if (newTileIDs.length > 0) {
            urlParams.set("tiles", newTileIDs.join(","));
            const newURL = `${window.location.origin}${
                window.location.pathname
            }?${urlParams.toString()}`;
            window.history.replaceState(history.state, "", newURL);
        } else {
            urlParams.delete("tiles");
            const newURL = `${window.location.origin}${
                window.location.pathname
            }?${urlParams.toString()}`;
            window.history.replaceState(history.state, "", newURL);
        }
    }

    private buildPrice() {
        if (this.props.selectedVariant) {
            return (
                <ConfiguratorPrice
                    price={getVariantPrice(this.props.selectedVariant)}
                    variant={this.props.selectedVariant}
                    selectedAddonPrice={null}
                    financingLink={isoWebPageURL.wrap("")}
                    strikeThroughMSRP={false}
                    discountDisplayMode={DiscountDisplayMode.DISABLED}
                    actualPriceStyle={""}
                />
            );
        }
        return (
            <div className="configurator__price">
                <p>Unavailable in size {this.props.priceSelectValue}</p>
            </div>
        );
    }

    private buildRating(variantURL: IWebPageURL | null) {
        if (!this.props.selectedVariant) {
            return null;
        }
        return (
            <a href={`${variantURL}#reviews__body`} title={t`Read Reviews`}>
                <RatingGraphic
                    cardClass="tile"
                    cardSize="large"
                    numReviews={this.props.selectedVariant.num_reviews}
                    rating={this.props.selectedVariant.rating}
                />
            </a>
        );
    }

    render() {
        const variantURL = this.props.selectedVariant?.link || null;
        return (
            <div role="group" aria-label="selected product for comparison">
                <button
                    type="button"
                    className="al-comparetray-xoutproduct"
                    onClick={this.onRemoveButtonClick}
                >
                    <SVG
                        className="basket-savings__title-icon"
                        aria-hidden="true"
                        src={iconCircleClose}
                    />
                    <span className="ada-screenreader-only">
                        {t`Remove product from compare`}
                    </span>
                </button>
                <Link href={variantURL}>
                    <RichText
                        html={this.props.data.product_compare_tile.title}
                    />
                </Link>
                {this.buildRating(variantURL)}
                {this.buildPrice()}
            </div>
        );
    }
}
