import React from "react";
import {
    getProducts,
    searchReviews,
    getSearchFacets,
} from "../../../api/reviews";
import {
    IReviewsBrandID,
    IReviewsProductTypeID,
} from "../../../models/nominals";
import {
    ReviewSortOption,
    IReviewQuery,
    ISearchReviewArgs,
    ISearchFacet,
    IReview,
    IReviewsProduct,
} from "../../../models/reviews.interfaces";
import { LoadedReviewsBehavior } from "../constants";
import { Dispatchers } from "../dispatchers";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { serializeFacetValues } from "../../../utils/reviews";

export interface IReviewsAppOwnProps {
    brandID: IReviewsBrandID;
    defaultSortOption?: ReviewSortOption;
    pageSize?: number;
    displayedProductTypeIDWhitelist?: IReviewsProductTypeID[];
    sourceFilterWhitelist?: string[];
    starHasStroke?: boolean;
    reviewsComponentType?: string;
}

export interface IReviewsAppReduxProps {
    products: IReviewsProduct[];
    productTypeIDs: IReviewsProductTypeID[];
    reviews: IReview[];
    facets: ISearchFacet[];
    facetValues: IReviewQuery;
    selectedSortOption: ReviewSortOption;
    page: number;
    hasMore: boolean;
    loadedInitialProducts: boolean;
    loadedInitialReviews: boolean;
}

export interface IReviewsAppDispatchProps {
    dispatchers: Dispatchers;
}

interface IReviewsAppProps
    extends IReviewsAppOwnProps,
        IReviewsAppReduxProps,
        IReviewsAppDispatchProps {}

interface IState {
    // eslint-disable-next-line
    isLoadingMore: boolean;
}

export abstract class ReviewsAppContainer<
    IProps extends IReviewsAppProps,
> extends React.Component<IProps, IState> {
    public state: IState = {
        isLoadingMore: false,
    };

    protected listReviewsTimer: number | undefined;

    protected readonly onLoadMore = async () => {
        this.setState({
            isLoadingMore: true,
        });
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        await this.listReviews(this.props.page + 1, true);
        this.setState({
            isLoadingMore: false,
        });
    };

    componentDidMount() {
        this.initSourceFilter();
        this.listProducts();

        // If the default sort option is set, dispatch a change and let componentDidUpdate query for reviews. Otherwise,
        // just list the initial reviews directly.
        const defaultSortOption =
            this.props.defaultSortOption || ReviewSortOption.HIGHEST_RATED;
        this.props.dispatchers.updateSorting(defaultSortOption);

        // Load reviews
        this.listReviewsDeBounced(1, false);
    }

    componentDidUpdate(prevProps: IProps) {
        const changedReviewSourceFilter =
            prevProps.sourceFilterWhitelist !==
            this.props.sourceFilterWhitelist;
        const changedFacets = prevProps.facets !== this.props.facets;
        if (changedReviewSourceFilter || changedFacets) {
            this.initSourceFilter();
        }
        const changedSort =
            this.props.selectedSortOption !== prevProps.selectedSortOption;
        const changedType =
            this.props.productTypeIDs !== prevProps.productTypeIDs;
        const changedFilters = this.props.facetValues !== prevProps.facetValues;
        if (changedSort || changedType || changedFilters) {
            this.listReviewsDeBounced(1, false);
        }
    }

    protected async listProducts() {
        const products = await getProducts(this.props.brandID);
        this.props.dispatchers.setProducts(products);
    }

    protected buildReviewSearchQuery(page: number): ISearchReviewArgs {
        // Make sure we don't inadvertently query for or display product types disallowed by the whitelist.
        // See #9281
        let productTypeIDs = this.props.productTypeIDs;
        if (
            productTypeIDs.length <= 0 &&
            this.props.displayedProductTypeIDWhitelist &&
            this.props.displayedProductTypeIDWhitelist.length > 0
        ) {
            productTypeIDs = this.props.displayedProductTypeIDWhitelist!;
        }
        const query: ISearchReviewArgs = {
            brand_id: this.props.brandID,
            ordering: this.props.selectedSortOption,
            product_type_id: serializeFacetValues(productTypeIDs),
            page: page,
            page_size: this.props.pageSize,
            ...this.props.facetValues,
        };
        return query;
    }

    private async listReviews(page = 1, appendToExisting = false) {
        const query = this.buildReviewSearchQuery(page);
        const [reviewData, facets] = await Promise.all([
            searchReviews(query),
            getSearchFacets(this.props.brandID, query),
        ]);
        if (!reviewData || !facets) {
            console.error("Failed to load reviews data");
            return;
        }
        this.props.dispatchers.loadedReviews({
            page: page,
            hasMore: reviewData.next !== null,
            loadedFacetValues: query,
            reviews: reviewData.results,
            facets: facets,
            behavior: appendToExisting
                ? LoadedReviewsBehavior.APPEND
                : LoadedReviewsBehavior.REPLACE,
        });
    }

    private initSourceFilter() {
        const sourceFilterWhitelist = this.props.sourceFilterWhitelist;
        if (!sourceFilterWhitelist || sourceFilterWhitelist.length <= 0) {
            return;
        }
        const sourceFacet = this.props.facets.find((facet) => {
            return facet.type === "source_id";
        });
        if (!sourceFacet) {
            return;
        }
        const optionIDs = sourceFacet.options
            .filter((option) => {
                return sourceFilterWhitelist.includes(`${option.name}`);
            })
            .map((option) => {
                return option.option_id;
            });
        const sourceIDs = serializeFacetValues(optionIDs);
        if (this.props.facetValues.source_id !== sourceIDs) {
            this.props.dispatchers.setFacetValues({
                ...this.props.facetValues,
                source_id: sourceIDs,
            });
        }
    }

    private listReviewsDeBounced(
        page = 1,
        appendToExisting = false,
        delayMs = 100,
    ) {
        if (this.listReviewsTimer) {
            window.clearTimeout(this.listReviewsTimer);
        }
        this.listReviewsTimer = window.setTimeout(() => {
            this.listReviews(page, appendToExisting);
        }, delayMs);
    }

    protected abstract buildContent(): JSX.Element;

    render() {
        if (
            !this.props.loadedInitialProducts ||
            !this.props.loadedInitialReviews
        ) {
            return <LoadingSpinner />;
        }
        return this.buildContent();
    }
}
