export const clamp = (num: number, min: number, max: number): number => {
    return num <= min ? min : num >= max ? max : num;
};

export const roundReviewRating = (rating: number): number => {
    return Math.round(rating * 10) / 10;
};

// See https://stackoverflow.com/a/31711034
export const modulo = (val: number, step: number): number => {
    const valDecCount = (val.toString().split(".")[1] || "").length;
    const stepDecCount = (step.toString().split(".")[1] || "").length;
    const decCount = Math.max(valDecCount, stepDecCount);
    const valInt = parseInt(val.toFixed(decCount).replace(".", ""), 10);
    const stepInt = parseInt(step.toFixed(decCount).replace(".", ""), 10);
    return (valInt % stepInt) / Math.pow(10, decCount);
};
