import { combineReducers } from "@reduxjs/toolkit";
import { Action } from "./constants";
import {
    IOptionValues,
    IProduct,
    IAddon,
} from "../../models/catalogue.interfaces";
import { sortProductsByPrice } from "../../utils/sorting";
import { uniqueByKey } from "../../utils/functional";
import { intersection } from "../../utils/sets";
import { guardReducer } from "../../utils/redux";
import { setOptionValue } from "../reducer-utils";
import {
    RootSelectionMode,
    IRootSelectionMode,
    ISelectedCategories,
    IReduxState,
    IAction,
    IActionLoadedProducts,
    IActionSetSelectedRootProduct,
    IActionSetSelectedCategory,
    IActionSetSelectedVariant,
    IActionUpdateSelectedAddons,
} from "./reducers.interfaces";
import { IPLCProductCategorySelector } from "./models.interfaces";
import { defaults } from "./defaults";
import { getInitialVariant } from "./utils";
import { selectedCategoriesSelector } from "./selectors";
import { getMostSimilarProduct } from "./product-similarity";

const updateSelectedAddons = (
    startingAddons: IAddon[],
    action: IActionUpdateSelectedAddons,
): IAddon[] => {
    const addonMap = new Map(
        startingAddons.map((addon) => [addon.productID, addon.quantity]),
    );
    for (const update of action.payload) {
        if (update.quantity > 0) {
            addonMap.set(update.productID, update.quantity);
        } else {
            addonMap.delete(update.productID);
        }
    }
    return Array.from(addonMap.keys()).map((productID) => {
        return {
            productID: productID,
            quantity: addonMap.get(productID) || 1,
        };
    });
};

const buildOptionValuesForVariant = (
    rootProduct: IProduct,
    variant: IProduct,
): IOptionValues => {
    const optionCodes = rootProduct.attributes.product_options?.value || [];
    const optionValues: IOptionValues = {};
    for (const optionCode of optionCodes) {
        optionValues[optionCode] = variant.attributes[optionCode]?.value;
    }
    return optionValues;
};

const buildSelectedCategoriesForVariant = (
    variant: IProduct,
    categorySelectors?: IPLCProductCategorySelector[],
): ISelectedCategories => {
    if (!categorySelectors) {
        return {};
    }
    const selectedCategories: ISelectedCategories = {};
    const variantCatIDs = new Set(variant.category_ids);
    for (const categorySelector of categorySelectors) {
        const selectorCatIDs = categorySelector.value.options.map(
            (opt) => opt.category.id,
        );
        const overlap = Array.from(
            intersection(variantCatIDs, new Set(selectorCatIDs)),
        );
        if (overlap.length > 0) {
            selectedCategories[categorySelector.id] = overlap[0];
        }
    }

    return selectedCategories;
};

const uiLoadedProducts = (
    state: typeof defaults.ui,
    action: IActionLoadedProducts,
): typeof defaults.ui => {
    const { rootProduct, variant } = getInitialVariant(
        action.payload.rootProducts,
        action.payload.initialVariantID,
    );
    const optionValues = buildOptionValuesForVariant(rootProduct, variant);
    const selectedCategories = buildSelectedCategoriesForVariant(
        variant,
        action.payload.categorySelectors,
    );
    const selectedRoot: IRootSelectionMode = action.payload.categorySelectors
        ? {
              type: RootSelectionMode.CATEGORY,
              categories: selectedCategories,
          }
        : {
              type: RootSelectionMode.DIRECT,
              productID: rootProduct.id,
          };
    // Update the state
    return {
        ...state,
        selectedRoot: selectedRoot,
        optionValues: optionValues,
    };
};

const setSelectedRootProduct = (
    state: typeof defaults.ui,
    action: IActionSetSelectedRootProduct,
): typeof defaults.ui => {
    const { rootProduct, variant } = getInitialVariant([action.payload]);
    const optionValues = buildOptionValuesForVariant(rootProduct, variant);
    // Update the state
    return {
        ...state,
        selectedRoot: {
            type: RootSelectionMode.DIRECT,
            productID: rootProduct.id,
        },
        optionValues: optionValues,
    };
};

const setSelectedCategory = (
    state: typeof defaults.ui,
    action: IActionSetSelectedCategory,
): typeof defaults.ui => {
    // Update the state with the new category
    const prevSelection =
        state.selectedRoot?.type === RootSelectionMode.CATEGORY
            ? state.selectedRoot.categories
            : {};
    return {
        ...state,
        selectedRoot: {
            type: RootSelectionMode.CATEGORY,
            categories: {
                ...prevSelection,
                [action.payload.selectorID]: action.payload.categoryID,
            },
        },
    };
};

const setSelectedVariant = (
    state: typeof defaults.ui,
    action: IActionSetSelectedVariant,
): typeof defaults.ui => {
    const optionValues = buildOptionValuesForVariant(
        action.payload.root,
        action.payload.variant,
    );
    const selectedRoot: IRootSelectionMode = action.payload.categorySelectors
        ? {
              type: RootSelectionMode.CATEGORY,
              categories: buildSelectedCategoriesForVariant(
                  action.payload.variant,
                  action.payload.categorySelectors,
              ),
          }
        : {
              type: RootSelectionMode.DIRECT,
              productID: action.payload.root.id,
          };
    // Update the state
    return {
        ...state,
        selectedRoot: selectedRoot,
        optionValues: optionValues,
    };
};

const buildPreviousSelectionState = (
    state: typeof defaults.ui,
): typeof defaults.ui.previousSelectionState => {
    return {
        selectedRoot: state.selectedRoot,
        optionValues: state.optionValues,
        quantity: state.quantity,
    };
};

const trackSelectionHistory = (
    oldState: typeof defaults.ui,
    newState: typeof defaults.ui,
): typeof defaults.ui => {
    // Only update the previousSelectionState if it will be different from the newSelectionState. This prevents
    // "squashing" history when a action duplicates are sent a couple times in a row.
    const newSelectionState = buildPreviousSelectionState(newState);
    const previousSelectionState = buildPreviousSelectionState(oldState);
    if (
        JSON.stringify(newSelectionState) ===
        JSON.stringify(previousSelectionState)
    ) {
        return newState;
    }
    return {
        ...newState,
        previousSelectionState: previousSelectionState,
    };
};

const entityReducer = (
    state = defaults.entities,
    action: IAction,
): IReduxState["entities"] => {
    switch (action.type) {
        case Action.LOADED_PRODUCTS:
            return {
                ...state,
                rootProducts: sortProductsByPrice(action.payload.rootProducts),
                categorySelectors: action.payload.categorySelectors,
                optionPanels: action.payload.optionPanels,
            };

        case Action.LOADED_PRODUCT_CLASS:
            return {
                ...state,
                productClass: action.payload,
            };

        case Action.LOADED_PRICE:
            return {
                ...state,
                price: action.payload,
            };

        case Action.LOADED_FINANCING_PLANS:
            return {
                ...state,
                financingPlans: action.payload,
            };

        case Action.LOADED_CONCRETE_BUNDLES:
            return {
                ...state,
                concreteBundles: action.payload.bundles
                    .slice()
                    .filter((bundle) => {
                        return bundle.suggested_products.length > 0;
                    }),
                upsellInfoModals: uniqueByKey(
                    action.payload.upsellInfoModals.concat(
                        state.upsellInfoModals,
                    ),
                    (modal) => modal.id,
                ),
                promoCards: action.payload.promoCards,
            };

        case Action.SET_VARIANT_PREFILTER:
            return {
                ...state,
                variantPrefilter: action.payload,
            };
    }

    return state;
};

/* eslint-disable complexity */
const uiReducer = (state = defaults.ui, action: IAction): IReduxState["ui"] => {
    switch (action.type) {
        case Action.LOADED_PRODUCTS:
            return uiLoadedProducts(state, action);

        case Action.LOADED_CONCRETE_BUNDLES:
            return {
                ...state,
                selectedUpgrade: action.payload.selectedUpgradeID,
            };

        case Action.SET_SELECTED_ROOT_PRODUCT:
            return setSelectedRootProduct(state, action);

        case Action.SET_SELECTED_CATEGORY:
            return setSelectedCategory(state, action);

        case Action.SET_SELECTED_VARIANT:
            return setSelectedVariant(state, action);

        case Action.SET_OPTION_VALUE:
            return {
                ...state,
                optionValues: setOptionValue(
                    state.optionValues,
                    action.payload,
                ),
            };

        case Action.SET_QUANTITY:
            return {
                ...state,
                quantity: action.payload,
            };

        case Action.UNDO_SELECTION:
            return {
                ...state,
                ...state.previousSelectionState,
            };

        case Action.SET_UPGRADES_VISIBLE:
            return {
                ...state,
                upgradeVisible: action.payload,
            };

        case Action.SET_ADDONS_VISIBLE:
            return {
                ...state,
                addonsVisible: action.payload,
            };

        case Action.SET_SELECTED_UPGRADE:
            return {
                ...state,
                selectedUpgrade: action.payload,
            };

        case Action.UPDATE_SELECTED_ADDONS:
            return {
                ...state,
                selectedAddons: updateSelectedAddons(
                    state.selectedAddons,
                    action,
                ),
            };

        case Action.SHOW_UPSELL_INFO_MODAL:
            return {
                ...state,
                upsellInfoModalActiveBundle: action.payload.bundleID,
            };

        case Action.HIDE_UPSELL_INFO_MODAL:
            return {
                ...state,
                upsellInfoModalActiveBundle: null,
            };

        case Action.SET_ADD_TO_BASKET_BTN_STATE:
            return {
                ...state,
                addToBasketCooldownActive: action.payload.cooldownActive,
                addToBasketButtonText: action.payload.text,
            };

        case Action.SET_ADD_TO_FAVORITES_BTN_STATE:
            return {
                ...state,
                addToFavoritesButtonText: action.payload.text,
            };

        case Action.SET_ADD_TO_BASKET_ERROR_MODAL_STATE:
            return {
                ...state,
                addToBasketErrorOpen: action.payload.isOpen,
                addToBasketErrorReason: action.payload.reason,
            };

        case Action.SET_RATINGS_MODAL_STATE:
            return {
                ...state,
                ratingsModalOpen: action.payload.isOpen,
            };

        case Action.SET_OPTION_SELECTION_ERROR_STATE:
            return {
                ...state,
                optionSelectionErrorReason: action.payload.reason,
            };

        case Action.SET_PANEL_TRIGGER_STATE:
            return {
                ...state,
                panelTriggerClicked: action.payload.isClicked,
            };
    }
    return state;
};
/* eslint-enable complexity */

const shippingMethodReducer = (
    state = defaults.shippingMethod,
    action: IAction,
): IReduxState["shippingMethod"] => {
    switch (action.type) {
        case Action.LOADED_SHIPPING_METHOD:
            return action.payload;
    }

    return state;
};

const _innerReducers = combineReducers({
    entities: entityReducer,
    ui: uiReducer,
    shippingMethod: shippingMethodReducer,
});

export const reducers = guardReducer(
    Action,
    defaults,
    (oldState, action: IAction) => {
        let newState = _innerReducers(oldState, action);

        // Automatically update the option values to match the most similar product in the new category
        if (action.type === Action.SET_SELECTED_CATEGORY) {
            const match = getMostSimilarProduct({
                categorySelectors: action.payload.categorySelectors,
                optionPanels: action.payload.optionPanels,
                rootProducts: newState.entities.rootProducts,
                selectedCategories: selectedCategoriesSelector(newState),
                optionValues: newState.ui.optionValues,
                previouslySelectedCategories: oldState
                    ? selectedCategoriesSelector(oldState)
                    : {},
                previouslySelectedOptionValues:
                    oldState?.ui?.optionValues || {},
            });
            if (match && match.distance > 0) {
                newState = {
                    ...newState,
                    ui: setSelectedVariant(newState.ui, {
                        type: Action.SET_SELECTED_VARIANT,
                        payload: {
                            root: match.bestProduct.parent || match.bestProduct,
                            variant: match.bestProduct,
                            categorySelectors: action.payload.categorySelectors,
                        },
                    }),
                };
            }
        }

        // Track the previously selected state
        if (oldState) {
            newState = {
                ...newState,
                ui: trackSelectionHistory(oldState.ui, newState.ui),
            };
        }

        return newState;
    },
);
