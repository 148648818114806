import React from "react";
import classNames from "classnames";
import { t } from "ttag";
import { UGCListSubBlock } from "../models/ugc";
import Flickity from "flickity-imagesloaded";
import UGCGallery from "./UGCGallery";
import { Image } from "./Image";
import { SocialIcon } from "./SocialIcon";
import "lazysizes";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "lazysizes/plugins/attrchange/ls.attrchange";
import {
    detectTileVisibility,
    setAriaHiddenAttribute,
    removeTabindex,
} from "../utils/detectFlickityTileVisibility";
import playButtonCircle from "../../img/misc/play-button-circle.png";

interface IProps {
    tiles?: UGCListSubBlock[];
}

interface IState {
    isMobile: boolean;
    showGallery: boolean;
    selectedSlide: number;
    selectedTilesGroupRange: number;
    selectedTileIndex: number;
}

export class UGCCarousel extends React.PureComponent<IProps, IState> {
    private ugcCarousel: HTMLDivElement | null = null;
    private carouselFlkty: Flickity | null = null;
    private readonly MOBILE_WIDTH_THRESHOLD = 501;
    private readonly LARGE_SCREEN_THRESHOLD = 960;

    public state: IState = {
        isMobile: false,
        showGallery: false,
        selectedSlide: 1,
        selectedTilesGroupRange: 0,
        selectedTileIndex: 0,
    };

    private readonly onGalleryClose = () => {
        this.setState({
            showGallery: false,
        });
    };

    private readonly onGalleryOpen = (
        showGallery: boolean,
        cellIndex: number,
    ) => {
        this.setState({
            showGallery: showGallery,
            selectedSlide: cellIndex,
        });
    };

    private readonly openSelectedSlide = (
        cellIndex: number,
        event: React.KeyboardEvent<HTMLButtonElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        this.onGalleryOpen(true, cellIndex);
    };

    private readonly checkMobileState = () => {
        const mobile: boolean = this.isMobileWidth() ? true : false;

        this.setState(
            {
                isMobile: mobile,
            },
            () => {
                if (this.props.tiles) {
                    this.initializeBaseCarousel();
                }
            },
        );
    };

    componentDidMount() {
        this.checkMobileState();
        window.addEventListener("resize", this.checkMobileState);
    }

    componentDidUpdate() {
        setAriaHiddenAttribute(
            "ugc-carousel-tile-container",
            "ugc-carousel-tile-container--hidden",
        );
        // ADA - Remove tabindex attribute which is added by flickity to avoid problems
        // for screen reader and keyboard users by disrupting the natural tab order
        removeTabindex("ugc-carousel__container");
    }

    private checkDisplayTilesNumber() {
        return !(window.innerWidth < this.LARGE_SCREEN_THRESHOLD) ? 3 : 2;
    }

    private isMobileWidth() {
        return window.innerWidth < this.MOBILE_WIDTH_THRESHOLD;
    }

    private initializeBaseCarousel() {
        if (this.carouselFlkty) {
            return;
        }

        const cellAlignment = this.state.isMobile ? "center" : "left";
        const initialIndex = this.state.isMobile ? 1 : 0;

        if (this.ugcCarousel) {
            this.carouselFlkty = new Flickity(this.ugcCarousel, {
                cellAlign: cellAlignment,
                contain: true,
                groupCells: true,
                pageDots: false,
                imagesLoaded: true,
                initialIndex: initialIndex,
                arrowShape: {
                    x0: 25,
                    x1: 60,
                    y1: 50,
                    x2: 65,
                    y2: 50,
                    x3: 30,
                },
            });

            this.setState({
                selectedTilesGroupRange:
                    Number(this.carouselFlkty.selectedIndex) +
                    Number(this.checkDisplayTilesNumber() - 1),
                selectedTileIndex: this.carouselFlkty.selectedIndex,
            });

            this.carouselFlkty.on("select", () => {
                if (this.carouselFlkty) {
                    this.setState({
                        selectedTilesGroupRange:
                            Number(this.carouselFlkty.selectedIndex) +
                            Number(this.checkDisplayTilesNumber() - 1),
                        selectedTileIndex: this.carouselFlkty.selectedIndex,
                    });
                }
            });

            this.carouselFlkty.on("staticClick", (_, __, ___, cellIndex) => {
                this.onGalleryOpen(true, cellIndex);
            });
        }
    }

    private isTilePhotoOrVideo(tile: UGCListSubBlock) {
        return (
            tile.post &&
            (tile.post.post_type === "image" ||
                tile.post.post_type === "video") &&
            tile.post.photo
        );
    }

    private isTextOnly(tile: UGCListSubBlock) {
        return tile.post?.post_type === "text-only";
    }

    private buildCarouselTiles() {
        if (!this.props.tiles) {
            return null;
        }
        const tilesNumber = this.props.tiles.length;
        let isTileInViewport = true;

        return this.props.tiles.map((tile, key) => {
            if (!tile.post) {
                return null;
            }
            if (this.carouselFlkty) {
                isTileInViewport = detectTileVisibility(
                    tilesNumber,
                    key,
                    this.state.selectedTilesGroupRange,
                    this.state.selectedTileIndex,
                );
            }
            const tileContainerClasses = classNames({
                "ugc-carousel-tile-container": true,
                "ugc-carousel-tile-container--hidden":
                    !this.isMobileWidth() && !isTileInViewport,
            });
            const tileBaseClasses = classNames({
                "ugc-carousel-tile": true,
                "ugc-carousel-tile--text":
                    this.isTextOnly(tile) && !tile.post.avatar,
                "ugc-carousel-tile--avatar":
                    this.isTextOnly(tile) && tile.post.avatar,
                "ugc-carousel-tile--photo": tile.post.post_type === "image",
                "ugc-carousel-tile--video": tile.post.post_type === "video",
            });
            const tileAttributionMobile = classNames({
                "ugc-carousel-tile__attribution": true,
                "ugc-carousel-tile__attribution--text-only":
                    this.isTextOnly(tile),
            });
            const tilePhotoClasses = classNames({
                "lazyload": true,
                "ugc-carousel-tile__photo": true,
                "ugc-carousel-tile__photo--video":
                    tile.post.post_type === "video",
            });
            const tileIconContainerClasses = classNames({
                "ugc-carousel-tile__icon-container": true,
                "ugc-carousel-tile__icon-container--empty":
                    tile.post.social_media === "none",
            });

            const playButtonImage = playButtonCircle;
            const tilePhoto = this.isTilePhotoOrVideo(tile) ? (
                <div className={tilePhotoClasses} data-bg={tile.post.photo}>
                    {tile.post.post_type === "video" && (
                        <Image
                            src={playButtonImage}
                            className="ugc-carousel-tile__video-icon"
                            alt="play video"
                        />
                    )}
                </div>
            ) : null;
            const mobileInfo = this.state.isMobile ? (
                <div className="ugc-carousel-tile__info">
                    <div className="ugc-carousel-tile__info--inner-mobile">
                        {!this.isTextOnly(tile) && (
                            <div className="ugc-carousel-tile__copy">
                                {tile.post.pull_quote}
                                <span className="ugc-carousel-tile__copy--blue">
                                    {t`View More`}
                                </span>
                            </div>
                        )}
                        <div className={tileAttributionMobile}>
                            <span className={tileIconContainerClasses}>
                                <SocialIcon
                                    type={tile.post.social_media}
                                    className={"ugc-carousel-tile__icon"}
                                />
                            </span>
                            <span className="ugc-carousel-tile__username">
                                {tile.post.username}
                            </span>
                        </div>
                    </div>
                </div>
            ) : null;
            const mobileTextOnly =
                this.state.isMobile && this.isTextOnly(tile) ? (
                    <div className="ugc-carousel-tile__copy--mobile-text-only">
                        {tile.post.copy}
                    </div>
                ) : null;
            const nonMobileInfo = !this.state.isMobile ? (
                <div className="ugc-carousel-tile__info">
                    <div className="ugc-carousel-tile__copy">
                        {tile.post.pull_quote}
                        {tile.post.view_post && (
                            <span className="ugc-carousel-tile__copy--blue al-ugc-carousel__view-more">
                                {t`View More`}
                            </span>
                        )}
                    </div>
                </div>
            ) : null;
            const nonMobileAttribution = !this.state.isMobile ? (
                <div className="ugc-carousel-tile__attribution">
                    <span className={tileIconContainerClasses}>
                        <SocialIcon
                            type={tile.post.social_media}
                            className={"ugc-carousel-tile__icon"}
                        />
                    </span>
                    <span className="ugc-carousel-tile__username">
                        {tile.post.username}
                    </span>
                </div>
            ) : null;
            return (
                <button
                    tabIndex={!isTileInViewport ? -1 : 0}
                    className={tileContainerClasses}
                    onKeyPress={this.openSelectedSlide.bind(this, key)}
                    key={key}
                >
                    <div className={tileBaseClasses}>
                        {mobileInfo}
                        {tilePhoto}
                        {mobileTextOnly}
                        {nonMobileInfo}
                        {nonMobileAttribution}
                    </div>
                </button>
            );
        });
    }

    render() {
        return (
            <span>
                <div
                    className="ugc-carousel__container"
                    ref={(ref) => {
                        this.ugcCarousel = ref;
                    }}
                >
                    {this.buildCarouselTiles()}
                </div>
                {this.state.showGallery && this.props.tiles && (
                    <UGCGallery
                        tiles={this.props.tiles}
                        initialSlide={this.state.selectedSlide}
                        onCloseGallery={this.onGalleryClose}
                    />
                )}
            </span>
        );
    }
}
