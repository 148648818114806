import contains from "validator/lib/contains";
import isCreditCard from "validator/lib/isCreditCard";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";
import isMobilePhone from "validator/lib/isMobilePhone";
import matches from "validator/lib/matches";
import toFloat from "validator/lib/toFloat";
import { parse as parseDate, isValid as isDateValid } from "date-fns";
import {
    VALIDATION_TYPES,
    ValidationTypes,
} from "../models/formFields.interfaces";
import { strings } from "../localization";

const PATTERN_ZIP_CODE = /^\d{5}([ -]\d{4})?$/;
const PATTERN_SSN = /^\d{3}-?\d{2}-?\d{4}$/;
const PATTERN_SSN_LAST_FOUR = /^\d{4}$/;
const PATTERN_DATE = /^\d{2}\/\d{2}\/\d{4}$/;
const PATTERN_CREDIT_CARD_EXP_DATE = /^([0-9][0-9])\/([0-9][0-9])$/;
const PATTERN_CREDIT_CARD_CVC = /^[0-9]{3,4}$/;
const PATTERN_CREDIT_CARD_CVC3 = /^[0-9]{3}$/; // use if card type is known
const PATTERN_CREDIT_CARD_CVC4 = /^[0-9]{4}$/; // use if card type is known
const PATTERN_FINANCING_ACCOUNT_REAL_WELLSFARGO =
    /^(5774)\s?([0-9]{4})\s?([0-9]{4})\s?([0-9]{4})$/;
const PATTERN_FINANCING_ACCOUNT_REAL_FORTIVA =
    /^(7656)\s?([0-9]{4})\s?([0-9]{4})\s?([0-9]{4})$/;
const PATTERN_FINANCING_ACCOUNT_TEST =
    /^(9999|2000)\s?([0-9]{4})\s?([0-9]{4})\s?([0-9]{4})$/;
const PATTERN_AMOUNT = /^\d*(\.\d{0,2})?$/;
const PATTERN_MI = /^[A-Za-z]+$/;
const PATTERN_NAME = /^[^\d]/;

type ErrorMsg = string;
type ValidationResult = [true, null] | [false, ErrorMsg];
type Validator = (value: string) => ValidationResult;

type Validators = Readonly<{
    [K in (typeof VALIDATION_TYPES)[number]]: Validator;
}>;

const VALID: ValidationResult = [true, null];

const makeError = (msg: string): ValidationResult => [false, msg];

const validators: Validators = {
    "required": (value) => {
        if (isEmpty(value)) {
            return makeError("This field is required.");
        }
        return VALID;
    },

    "firstname-empty": (value) => {
        if (isEmpty(value)) {
            return makeError("Please enter a valid first name.");
        }
        if (!matches(value, PATTERN_NAME)) {
            return makeError(
                "Please start the first name with a letter, not a number.",
            );
        }
        return VALID;
    },

    "lastname-empty": (value) => {
        if (isEmpty(value)) {
            return makeError("Please enter a valid last name.");
        }
        if (!matches(value, PATTERN_NAME)) {
            return makeError(
                "Please start the last name with a letter, not a number.",
            );
        }
        return VALID;
    },

    "shipping-address-empty": (value) => {
        if (isEmpty(value)) {
            return makeError("Please enter a valid shipping address.");
        }
        return VALID;
    },

    "street-address-empty": (value) => {
        if (isEmpty(value)) {
            return makeError("Please enter a valid street address.");
        }
        return VALID;
    },

    "annual-income-empty": (value) => {
        if (isEmpty(value)) {
            return makeError("Please enter a net annual income.");
        }
        return VALID;
    },

    "city-empty": (value) => {
        if (isEmpty(value)) {
            return makeError("Please enter a valid city.");
        }
        return VALID;
    },

    "state-empty": (value) => {
        if (isEmpty(value)) {
            return makeError("Please enter a valid state.");
        }
        return VALID;
    },

    "lastname": (value) => {
        // Wells Fargo doesn't support last names with hyphens in it. See https://thelabnyc.plan.io/issues/1869
        if (contains(value, "-")) {
            return makeError("No hyphens permitted in last name.");
        }
        return VALID;
    },

    "middle-initial": (value) => {
        if (!matches(value, PATTERN_MI)) {
            return makeError("Please enter alphabet characters only.");
        }
        return VALID;
    },

    "email": (value) => {
        if (!isEmail(value)) {
            return makeError("Please enter a valid email address.");
        }
        return VALID;
    },

    "zipcode": (value) => {
        if (!matches(value, PATTERN_ZIP_CODE)) {
            return makeError("Please enter a valid US zip code.");
        }
        return VALID;
    },

    "phone": (value) => {
        value = value.replace(/\D/g, "");
        if (!isMobilePhone(value, "en-US")) {
            return makeError(
                "Please enter a valid telephone number. e.g. +1 (000) 000-0000",
            );
        }
        return VALID;
    },

    "ssn": (value) => {
        if (!matches(value, PATTERN_SSN)) {
            return makeError("Please enter a valid US Social Security Number.");
        }
        return VALID;
    },

    "ssn_last_four": (value) => {
        if (!matches(value, PATTERN_SSN_LAST_FOUR)) {
            return makeError(
                "Please enter the last 4 digits of your Social Security Number.",
            );
        }
        return VALID;
    },

    "date": (value) => {
        if (!matches(value, PATTERN_DATE)) {
            return makeError("Please enter a date in the MM/DD/YYYY format.");
        }
        if (!isDateValid(parseDate(value, "MM/dd/yyyy", new Date()))) {
            return makeError("Please enter a valid date.");
        }
        return VALID;
    },

    "credit-card": (value) => {
        if (!isCreditCard(value)) {
            return makeError("Please enter a valid credit card number.");
        }
        return VALID;
    },

    "expiration-date": (value) => {
        if (!matches(value, PATTERN_CREDIT_CARD_EXP_DATE)) {
            return makeError(
                "Please enter your card’s expiration date in the MM/YY format.",
            );
        }
        const splitValue = value.split("/");
        const month = parseInt(splitValue[0], 10);
        const year = parseInt(splitValue[1], 10);

        if (month < 1 || month > 12) {
            return makeError("Please enter a valid month (01 to 12).");
        }

        const currentTime = new Date();
        const currentYear = parseInt(
            currentTime.getFullYear().toString().substr(2, 2),
            10,
        );
        const currentMonth = currentTime.getMonth() + 1;

        if (
            year < currentYear ||
            (year === currentYear && month < currentMonth)
        ) {
            return makeError(
                "This date indicates an expired card. Please enter a current date.",
            );
        }

        return VALID;
    },

    "cvc": (value) => {
        if (!matches(value, PATTERN_CREDIT_CARD_CVC)) {
            return makeError("Please enter a valid security code.");
        }
        return VALID;
    },

    "cvc3": (value) => {
        if (!matches(value, PATTERN_CREDIT_CARD_CVC3)) {
            return makeError("Please enter a valid security code.");
        }
        return VALID;
    },

    "cvc4": (value) => {
        if (!matches(value, PATTERN_CREDIT_CARD_CVC4)) {
            return makeError("Please enter a valid security code.");
        }
        return VALID;
    },

    "amount": (value) => {
        if (!matches(value, PATTERN_AMOUNT)) {
            return makeError("Please enter a valid amount.");
        }
        return VALID;
    },

    "nonzero": (value) => {
        if (toFloat(value) === 0) {
            return makeError("This field cannot be zero.");
        }
        return VALID;
    },

    "financing-account": (value) => {
        if (
            !matches(value, PATTERN_FINANCING_ACCOUNT_REAL_WELLSFARGO) &&
            !matches(value, PATTERN_FINANCING_ACCOUNT_REAL_FORTIVA) &&
            !matches(value, PATTERN_FINANCING_ACCOUNT_TEST)
        ) {
            const msg = strings.get("FINANCING_FINANCING_ERROR");
            if (msg) {
                return makeError(msg);
            }
        }
        return VALID;
    },
};

/**
 * Run the given validator on the given value and return an error message string or null
 */
export const runValidator = (
    validationType: ValidationTypes,
    value: string,
) => {
    // Run custom validators
    if (typeof validationType == "function") {
        return validationType(value);
    }
    // Run built-in validators
    const validatorFn = validators[validationType];
    const [isValid, msg] = validatorFn(value);
    return isValid ? null : msg;
};

/**
 * Run the given validator on the given value and return an error message string or null
 */
export const runBooleanValidator = (
    validationType: ValidationTypes,
    value: boolean,
) => {
    let errorMessage: string | null = null;
    if (typeof validationType == "function") {
        const msg = validationType(value);
        if (msg) {
            errorMessage = msg;
        }
    }
    return errorMessage ? errorMessage : null;
};
