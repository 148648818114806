import { IAddress } from "../models/address.interfaces";
import { IShippingMethod } from "../models/shipping.interfaces";
import { ShippingMethods } from "../models/shipping";
import { check } from "../models/utils";
import { ajax, CSRF_HEADER, getCSRFToken } from "../utils/ajax";
import { PromiseMutex } from "../utils/mutex";

export const loadMethods = async (shipping_address: Partial<IAddress>) => {
    const data = {
        line1: shipping_address.line1,
        line2: shipping_address.line2,
        line4: shipping_address.line4,
        state: shipping_address.state,
        postcode: shipping_address.postcode,
        country: shipping_address.country,
    };
    const mutex = new PromiseMutex<IShippingMethod[]>(
        `load-shipping-methods-${shipping_address.country}-${shipping_address.state}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/checkout/shipping-methods/")
            .set("Accept", "application/json")
            .query(data)
            .then((resp) => {
                return check(ShippingMethods.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const saveShippingMethod = async (
    shipping_address: Partial<IAddress>,
    methodCode: string,
): Promise<IShippingMethod[]> => {
    const data = {
        line1: shipping_address.line1 || "",
        line2: shipping_address.line2 || "",
        line4: shipping_address.line4 || "",
        state: shipping_address.state || "",
        postcode: shipping_address.postcode || "",
        country: shipping_address.country || "",
        shipping_method_code: methodCode,
    };
    return ajax
        .post("/api/checkout/shipping-methods/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data)
        .then((resp) => {
            return check(ShippingMethods.decode(resp.body));
        });
};
