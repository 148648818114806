/**
 * Thread-global named promise store.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mutexStore: { [s: string]: Promise<any> | undefined } = {};

/**
 * Mutex locking system to prevent duplication of ajax calls.
 */
export class PromiseMutex<T> {
    readonly name: string;
    readonly cache: boolean;

    constructor(name: string, cache = false) {
        this.name = name;
        this.cache = cache;
    }

    getPromise() {
        return mutexStore[this.name] as Promise<T>;
    }

    setPromise(newPromise: Promise<T>) {
        const cache = this.cache;
        const name = this.name;
        const oldPromise = mutexStore[name];
        if (oldPromise) {
            throw new Error(
                `Can not call Mutex[${name}].setPromise since a pending promise still exists.`,
            );
        }

        newPromise.then(
            (v) => {
                if (!cache) {
                    delete mutexStore[name];
                }
                return v;
            },
            (e) => {
                delete mutexStore[name];
                throw e;
            },
        );

        mutexStore[name] = newPromise;
        return mutexStore[name] as Promise<T>;
    }
}
