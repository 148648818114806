export const isSuperset = <T>(set: Set<T>, subset: Set<T>) => {
    for (const elem of subset) {
        if (!set.has(elem)) {
            return false;
        }
    }
    return true;
};

export const union = <T>(setA: Set<T>, setB: Set<T>) => {
    const _union = new Set(setA);
    for (const elem of setB) {
        _union.add(elem);
    }
    return _union;
};

export const intersection = <T>(setA: Set<T>, setB: Set<T>) => {
    const _intersection = new Set<T>();
    for (const elem of setB) {
        if (setA.has(elem)) {
            _intersection.add(elem);
        }
    }
    return _intersection;
};

export const difference = <T>(setA: Set<T>, setB: Set<T>) => {
    const _difference = new Set(setA);
    for (const elem of setB) {
        _difference.delete(elem);
    }
    return _difference;
};

export const isEqualSet = <T>(setA: Set<T>, setB: Set<T>) => {
    return (
        setA.size === setB.size &&
        [...setA].every((value) => {
            return setB.has(value);
        })
    );
};
