import React from "react";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Actions } from "../actions";
import { FormView } from "./abstract/FormView";

interface IOwnProps {
    basketID: number;
    email: string;
}

interface IReduxProps {}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const BasketAssignComponent = (props: IProps) => {
    return (
        <FormView
            submitLabel="Assign Basket"
            actions={props.actions}
            fields={[
                {
                    label: "Email Address",
                    name: "email",
                    type: "email",
                },
            ]}
            initialValues={props}
            onSubmit={async (event, data) => {
                event.preventDefault();
                const email = data.email as string;
                await props.actions.assignBasket(props.basketID, email);
                await Promise.all([
                    props.actions.loadCurrentlyAssistedCustomer(),
                    props.actions.loadCurrentlyAssistedBasket(),
                ]);
                props.actions.popViewStack();
            }}
        />
    );
};

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    _rootState,
    ownProps,
) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const BasketAssign = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BasketAssignComponent);
