import React from "react";
import { IImageURL } from "../../../models/nominals";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { BreakPoint } from "../../../models/screen.interfaces";
import { ConfiguratorFreeDelivery } from "./ConfiguratorFreeDelivery";
import { ConfiguratorTypes } from "../../../constants";
import { FreeDeliveryButtonTypes } from "../constants";
import { IModularConfiguratorShippingMessage } from "../models.interfaces";

import styles from "./ConfiguratorShippingCallout.module.scss";

interface IOwnProps {
    content: string;
    configuratorType: ConfiguratorTypes;
    shippingMessages?: IModularConfiguratorShippingMessage | null;
    phoneNumber?: string;
    iconURL?: IImageURL | null | undefined;
}

interface IReduxProps {}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

export class ConfiguratorShippingCallout extends React.PureComponent<
    IProps,
    IState
> {
    private buildDescription() {
        let content = this.props.content;
        if (getViewportBreakpoint() > BreakPoint.SMALL) {
            content = content.replace("<br>", "");
        }
        return (
            <div className={styles.content}>
                {this.props.iconURL && (
                    <img
                        src={`${this.props.iconURL}`}
                        aria-hidden="true"
                        alt=""
                    />
                )}
                <span>
                    {content}
                    {!!this.props.shippingMessages && (
                        <ConfiguratorFreeDelivery
                            freeDeliveryButtonTypes={
                                FreeDeliveryButtonTypes.ICON
                            }
                            configuratorType={this.props.configuratorType}
                            phoneNumber={this.props.phoneNumber}
                            shippingMessages={this.props.shippingMessages}
                        />
                    )}
                </span>
            </div>
        );
    }
    render() {
        return (
            <>
                <div className={styles.container}>
                    {this.buildDescription()}
                </div>
            </>
        );
    }
}
