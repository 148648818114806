import { createAsyncThunk } from "@reduxjs/toolkit";
import { submitMattressMatchPreferences } from "../../../api/products";
import {
    IMattressMatchPreferenceSet,
    IMattressMatchResults,
} from "../../../models/catalogue.interfaces";

export const getResultsFromPreferences = createAsyncThunk(
    "mattressmatch/loadResults",
    async (
        data: IMattressMatchPreferenceSet,
    ): Promise<IMattressMatchResults | null> => {
        const results = await submitMattressMatchPreferences(data);
        return results;
    },
);
