import React from "react";
import { t, ngettext, msgid } from "ttag";
import SVG from "react-inlinesvg";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { MapDirectionsLink } from "tsi-common-react/src/common/MapDirectionsLink";
import { Link } from "tsi-common-react/src/common/Link";
import { urls } from "tsi-common-react/src/utils/urls";
import { formatNumber } from "tsi-common-react/src/utils/format";
import {
    formatDistanceWithSuffix,
    getAddress,
} from "tsi-common-react/src/apps/retail/helpers";
import { Image } from "tsi-common-react/src/common/Image";
import { RatingGraphic } from "tsi-common-react/src/common/RatingGraphic";
import styles from "./StoreInfo.module.scss";
import { RetailStoreOpenStatus } from "tsi-common-react/src/common/RetailStoreOpenStatus";
import { StoreHours } from "./StoreHours";
import iconPin from "../../svg/pin.svg";
import iconCarouselArrowRight from "../../svg/carousel-arrow-right.svg";
import pin from "../../img/book-appointment/pin.png";
import mobileDevice from "../../img/book-appointment/mobile-device.png";
import clipboard from "../../img/book-appointment/clipboard.png";
import iconPinLarge from "../../svg/pin-large.svg";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

interface IProps {
    minimal?: boolean | null;
    store: IRetailStoreWithDistance | null;
    showReviews: boolean;
    showBookApptLink: boolean;
}

export const StoreInfo = (props: IProps) => {
    const numReviews = props?.store?.num_reviews || 0;

    if (!props.store) {
        return null;
    }
    const reviewCountLabel = ngettext(msgid`Review`, `Reviews`, numReviews);

    const onViewAllReviews = () => {
        const reviewsBody = document.querySelector<HTMLElement>(
            ".customer-reviews-block",
        );
        reviewsBody?.scrollIntoView(true);
        reviewsBody?.focus();
    };

    if (props.minimal) {
        return (
            <>
                <strong>
                    {" "}
                    <SVG
                        aria-hidden="true"
                        src={iconPin}
                        title={t`Map Icon`}
                    />{" "}
                    {props.store.name}
                </strong>

                <RetailStoreOpenStatus store={props.store} />

                <SVG
                    aria-hidden="true"
                    src={iconCarouselArrowRight}
                    title={t`Book Appointment`}
                    className="arrow"
                />
            </>
        );
    }

    return (
        <>
            <div className={styles.meta}>
                <p className={styles.name}>{props.store.name}</p>
                {props.showReviews && (
                    <div className={styles.reviews}>
                        <RatingGraphic
                            cardClass="reviews"
                            cardSize="large"
                            onlyStars={false}
                            starHasStroke={true}
                            rating={null}
                        />
                        <span onClick={onViewAllReviews}>
                            {formatNumber(numReviews)} {reviewCountLabel}
                        </span>
                    </div>
                )}
                <div className={styles.twoColumnRow}>
                    <Image aria-hidden="true" src={pin} alt={t`Pin`} />
                    <MapDirectionsLink
                        addressParts={[
                            props.store.address,
                            props.store.address2,
                            props.store.city,
                            props.store.state,
                            props.store.postal,
                        ]}
                        displayText={getAddress(props.store)}
                        className="adress-text-link"
                    />
                </div>
                <div className={styles.twoColumnRow}>
                    <Image
                        aria-hidden="true"
                        src={mobileDevice}
                        alt={t`Mobile`}
                    />
                    <a href={"tel:" + props.store.phone}>{props.store.phone}</a>
                </div>
                {getPageSetting("app-slug") !== "sleepoutfittersoutlet" && (
                    <div className={styles.twoColumnRow}>
                        <Image
                            aria-hidden="true"
                            src={clipboard}
                            alt={t`Clipboard`}
                        />
                        <a href={"sms:688348&Body=Outfit"}>
                            {t`Text Outfit to 688348`}
                        </a>
                    </div>
                )}
                {props.showBookApptLink && (
                    <div className={styles.twoColumnRow}>
                        <Image
                            aria-hidden="true"
                            src={clipboard}
                            alt={t`Clipboard`}
                        />
                        <Link
                            href={urls.pageURL("book-appointment")}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t`Book An Appointment`}
                        </Link>
                    </div>
                )}
                <StoreHours
                    hoursSummary={props.store.hours_summary}
                    holidayHours={props.store.holiday_hours}
                />
            </div>
            <div className={styles.cta} id="book-appointment-block-cta">
                <button className={`button ${styles.button}`}>
                    <MapDirectionsLink
                        addressParts={[
                            props.store.address,
                            props.store.address2,
                            props.store.city,
                            props.store.state,
                            props.store.postal,
                        ]}
                    />
                </button>
                <div className={styles.distance}>
                    <SVG
                        aria-hidden="true"
                        src={iconPinLarge}
                        title={t`Map Icon`}
                    />
                    {formatDistanceWithSuffix(props.store.distance)}
                </div>
            </div>
        </>
    );
};
