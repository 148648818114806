import React from "react";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";

import styles from "./ConfiguratorPanelTrigger.module.scss";
import iconArrowRightBlue from "../../../../img/icons/arrow_right_blue.svg";

interface IOwnProps {
    prefixNameCapped: string;
    onOpenPanel: () => void;
    children: React.ReactNode;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IDispatchProps {}

const ConfiguratorPanelTriggerComponent = (props: IProps) => {
    return (
        <button
            className={styles.button}
            onClick={() => {
                props.onOpenPanel();
                hideFinanceBlock();
                props.dispatchers.setPanelTriggerState(true);
            }}
        >
            <h6>{props.prefixNameCapped}</h6>
            <span>{props.children}</span>
            <SVG aria-hidden="true" src={iconArrowRightBlue} />
        </button>
    );
};
const footer = document.querySelector<HTMLElement>(".site-footer");
const PDPHero = document.querySelector<HTMLElement>(".pdp-hero");
export const showFinanceBlock = () => {
    if (footer) {
        footer.classList.remove("u-unset-position");
    }
    if (PDPHero) {
        PDPHero.classList.remove("u-increase-z-index");
    }
};
export const hideFinanceBlock = () => {
    if (footer) {
        footer.classList.add("u-unset-position");
    }
    if (PDPHero) {
        PDPHero.classList.add("u-increase-z-index");
    }
};

export const disableMainConfiguratorScrolling = () => {
    const container = document.querySelector<HTMLElement>(
        ".pdp-hero .configurator",
    );
    if (container) {
        container.classList.add("overflow-hidden");
    }
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers,
    };
};

export const ConfiguratorPanelTrigger = connect(
    null,
    mapDispatchToProps,
)(ConfiguratorPanelTriggerComponent);
