import React from "react";
import { t } from "ttag";
import * as userAPI from "../../../api/user";
import { Form } from "../../../forms/Form";
import { NonFieldErrors } from "../../../forms/NonFieldErrors";
import { FormInputWithoutFormatting as FormInput } from "../../../forms/FormInputWithoutFormatting";
import { FormHelpLink } from "../../../forms/FormHelpLink";
import { FormButtonLink } from "../../../forms/FormButtonLink";
import { FormSubmit } from "../../../forms/FormSubmit";
import { updateGTMUserHash } from "../../../utils/analytics";
import { IWebPageURL } from "../../../models/nominals";
import { urls } from "../../../utils/urls";
import { LoadingOverlay } from "../../../common/Loading/index";

interface IProps {
    nextURL?: IWebPageURL;
    onAfterLoad?: () => void;
    onAfterSave?: () => void;
}

interface IState {
    isSubmitting: boolean;
    server: {
        username: string;
        email: string;
    };
    client: {
        email: string;
        password: string;
    };
    errors: {
        non_field_errors: string[];
        email: string[];
        password: string[];
    };
    helptext: {
        email?: string;
    };
}

export class LoginForm extends React.Component<IProps, IState> {
    public state: IState = {
        isSubmitting: false,
        server: {
            username: "",
            email: "",
        },
        client: {
            email: "",
            password: "",
        },
        errors: {
            non_field_errors: [],
            email: [],
            password: [],
        },
        helptext: {},
    };

    private readonly onEmailChange = async (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        const input = event.currentTarget;
        const email = input.value;
        this.setState({
            client: {
                ...this.state.client,
                email: email,
            },
        });
    };

    private readonly onPasswordChange = async (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        const password = event.currentTarget.value;
        this.setState({
            client: {
                ...this.state.client,
                password: password,
            },
        });
    };

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        event.preventDefault();
        this.setState({
            isSubmitting: true,
        });
        try {
            const data = await userAPI.login(
                this.state.client.email,
                this.state.client.password,
                this.props.nextURL,
            );
            // Reload current user on success; render error message on failure
            if (data.redirect) {
                urls.navigateToURL(data.redirect);
                return;
            }
            this.setState({
                isSubmitting: false,
                errors: {
                    non_field_errors: [],
                    email: [],
                    password: [],
                },
                helptext: {
                    email: data.message,
                },
            });
        } catch (error) {
            const data = error.response.body;
            this.setState({
                ...this.state,
                isSubmitting: false,
                errors: {
                    non_field_errors: data.non_field_errors || [],
                    email: data.email || [],
                    password: data.password || [],
                },
                helptext: {
                    email: "",
                },
            });
        }
        updateGTMUserHash(this.state.client.email);
        if (this.props.onAfterSave) {
            this.props.onAfterSave();
        }
    };

    componentDidMount() {
        this.loadCurrentUser();
    }

    private async loadCurrentUser() {
        const user = await userAPI.load();
        this.setState(
            {
                ...this.state,
                server: {
                    ...this.state.server,
                    username: user.username,
                    email: user.email,
                },
                client: {
                    ...this.state.client,
                    email: user.email,
                },
            },
            () => {
                if (this.props.onAfterLoad) {
                    this.props.onAfterLoad();
                }
            },
        );
    }

    private buildLoginForm() {
        return (
            <Form className="js-kuid-form" onSubmit={this.onSubmit}>
                <NonFieldErrors errors={this.state.errors.non_field_errors} />
                <FormInput
                    id="account_email"
                    name="account_email"
                    type="email"
                    errors={this.state.errors.email}
                    placeholder={t`Email Address`}
                    value={this.state.client.email}
                    helpText={this.state.helptext.email}
                    onChange={this.onEmailChange}
                    required={true}
                    disabled={this.state.isSubmitting}
                    autoComplete="email"
                />
                <FormInput
                    id="account_password"
                    name="account_password"
                    type="password"
                    errors={this.state.errors.password}
                    placeholder={t`Password`}
                    value={this.state.client.password}
                    onChange={this.onPasswordChange}
                    disabled={this.state.isSubmitting}
                    autoComplete="current-password"
                />
                <FormHelpLink
                    href={urls.pageURL("password-reset")}
                    helpText={t`Forgot password?`}
                />
                <FormSubmit
                    value={t`Log in`}
                    className="button button--full-width"
                    disabled={this.state.isSubmitting}
                />
                <FormButtonLink
                    href={urls.pageURL("customer-register")}
                    text={t`Or, create account`}
                />
            </Form>
        );
    }

    render() {
        if (!this.state.server.username) {
            return <LoadingOverlay visuallyHiddenText={t`Loading…`} />;
        }
        return this.buildLoginForm();
    }
}
