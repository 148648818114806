import React from "react";
import { t } from "ttag";
import SVG from "react-inlinesvg";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { strings } from "../../../localization";
import { IWebPageURL } from "../../../models/nominals";
import { Form } from "../../../forms/Form";
import { FormInput } from "../../../forms/FormInput";
import { FormPasswordInput } from "../../../forms/FormPasswordInput";
import { FormSubmit } from "../../../forms/FormSubmit";
import { Trans } from "../../../common/Trans";
import { Link } from "../../../common/Link";
import * as userAPI from "../../../api/user";
import iconReviewsYes from "../../../../img/icons/reviews-yes.svg";
import iconCollapse from "../../../../img/icons/collapse.svg";
import iconExpand from "../../../../img/icons/expand.svg";

interface IProps {
    firstName: string;
    email: string;
    isAuthenticatedAsOrderOwner: boolean;
    orderOwnerHasPassword: boolean;
    orderHistoryURL: IWebPageURL;
}

interface IState {
    disableSubmit: boolean;
    formSubmitted: boolean;
    passwordField: string;
    passwordErrors: string[];
    formIsExpanded: boolean;
}

const BASE_CLASS = "thank-you-create-account";

export class CreateAccount extends React.Component<IProps, IState> {
    public state: IState = {
        disableSubmit: false,
        formSubmitted: false,
        passwordField: "",
        passwordErrors: [],
        formIsExpanded: true,
    };

    private readonly onSubmitSetNewPassword = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
        // Validate inputs
        if (this.state.disableSubmit) {
            return;
        }
        if (this.state.passwordField.length <= 0) {
            this.setState({
                passwordErrors: [
                    t`You must enter a password to create an account`,
                ],
            });
            return;
        }
        // Submit form
        this.setState({
            disableSubmit: true,
        });
        try {
            await userAPI.changePassword(this.state.passwordField);
            this.setState({
                formSubmitted: true,
                disableSubmit: true,
                passwordErrors: [],
            });
        } catch (e) {
            console.error(e);
            this.setState({
                formSubmitted: false,
                disableSubmit: false,
                passwordErrors: [
                    t`An unexpected error occurred. Please try again.`,
                ],
            });
        }
    };

    private readonly onSubmitValidateEmail = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();
        // Validate inputs
        if (this.state.disableSubmit) {
            return;
        }
        // Submit form
        this.setState({
            disableSubmit: true,
        });
        try {
            await userAPI.login(
                this.props.email,
                "",
                this.props.orderHistoryURL,
            );
            this.setState({
                formSubmitted: true,
                disableSubmit: true,
            });
        } catch (e) {
            console.error(e);
            this.setState({
                formSubmitted: false,
                disableSubmit: false,
            });
        }
    };

    private readonly onPasswordChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.setState({
            passwordField: event.currentTarget.value,
            passwordErrors: [],
        });
    };

    private readonly onToggleFormAccordion = (
        e: React.MouseEvent<HTMLElement>,
    ) => {
        e.preventDefault();
        this.setState((state) => {
            return {
                ...state,
                formIsExpanded: !state.formIsExpanded,
            };
        });
    };

    private buildContent__AnonOrderForNewUser(): JSX.Element {
        // Reqs Branch 1: An anonymous user placed an order using an email address that was entirely new to Oscar.
        if (this.state.formSubmitted) {
            return (
                <>
                    <SVG
                        aria-hidden="true"
                        className={`${BASE_CLASS}__form-success-icon`}
                        src={iconReviewsYes}
                    />
                    <h2 className={`${BASE_CLASS}__title`} role="alert">
                        {t`Success! Your account has been created.`}
                    </h2>
                    <p className={`${BASE_CLASS}__copy`}>
                        <Trans
                            fmtString={strings.get(
                                "ORDER_THANK_YOU_B1_CREATE_ACCT_SUCCESS",
                            )}
                            data={{
                                MyOrdersLink: (content) => (
                                    <Link
                                        key="orders-link"
                                        className={`${BASE_CLASS}__link`}
                                        href={this.props.orderHistoryURL}
                                        target="_top"
                                    >
                                        {content}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </>
            );
        }
        // Build expand/collapse body
        let accordionBody: JSX.Element | null = null;
        if (this.state.formIsExpanded) {
            accordionBody = (
                <CSSTransition
                    key="form"
                    classNames="transition--accordion"
                    timeout={{ exit: 2000, enter: 2000 }}
                >
                    <div
                        id="create-expandable-account-form"
                        className={`${BASE_CLASS}__accordion-body`}
                    >
                        <p className={`${BASE_CLASS}__copy`}>
                            {strings.get("ORDER_THANK_YOU_B1_CREATE_ACCT_COPY")}
                        </p>
                        <Form
                            className={`form ${BASE_CLASS}__set-password-form`}
                            onSubmit={this.onSubmitSetNewPassword}
                        >
                            <FormInput
                                id="create_account_email"
                                name="create_account_email"
                                type="email"
                                label={t`Email Address:`}
                                labelPlacement="outside"
                                value={this.props.email}
                                readOnly={true}
                                disabled={true}
                            />
                            <FormPasswordInput
                                id="create_account_password"
                                name="create_account_password"
                                label={t`Password:`}
                                labelPlacement="outside"
                                value={this.state.passwordField}
                                errors={this.state.passwordErrors}
                                disabled={this.state.disableSubmit}
                                onChange={this.onPasswordChange}
                            />
                            <FormSubmit
                                className={`button button--full-width ${BASE_CLASS}__submit`}
                                value={t`Create Account`}
                                disabled={this.state.disableSubmit}
                            />
                        </Form>
                    </div>
                </CSSTransition>
            );
        }
        const icon = this.state.formIsExpanded ? iconCollapse : iconExpand;
        return (
            <>
                <h2 className={`${BASE_CLASS}__title`}>
                    <button
                        aria-controls="create-expandable-account-form"
                        aria-expanded={this.state.formIsExpanded}
                        onClick={this.onToggleFormAccordion}
                    >
                        {t`Let's create an account`}
                        <SVG
                            aria-hidden="true"
                            className={`${BASE_CLASS}__accordion-handle`}
                            src={icon}
                        />
                    </button>
                </h2>
                <TransitionGroup>{accordionBody}</TransitionGroup>
            </>
        );
    }

    private buildContent__AnonOrderForNonRegisteredUser(): JSX.Element {
        // Reqs branch 2: An anonymous user placed an order using an email address that was already associated with a
        // user account in the Non-Registered status (i.e. order user does not have a password).
        if (this.state.formSubmitted) {
            return (
                <>
                    <h2 className={`${BASE_CLASS}__title`}>
                        {t`Welcome back, ${this.props.firstName}`}
                    </h2>
                    <p className={`${BASE_CLASS}__copy`}>
                        <Trans
                            fmtString={t`Your account has been successfully created. For security purposes, we have sent an email to <UserEmail></UserEmail> to allow you to set your password.`}
                            data={{
                                UserEmail: () => (
                                    <strong key="user-email">
                                        {this.props.email}
                                    </strong>
                                ),
                            }}
                        />
                    </p>
                </>
            );
        }
        return (
            <Form
                className={`form ${BASE_CLASS}__validate-email-form`}
                onSubmit={this.onSubmitValidateEmail}
            >
                <input type="hidden" value={this.props.email} />
                <FormSubmit
                    className={`button ${BASE_CLASS}__submit`}
                    value={t`Create Account`}
                    disabled={this.state.disableSubmit}
                />
            </Form>
        );
    }

    private buildContent__AnonOrderForRegisteredUser(): JSX.Element {
        // Reqs Branch 3: An anonymous user placed an order using an email address that was already associated with a
        // user account in the Registered status (i.e. order user does have a password).
        return (
            <>
                <h2 className={`${BASE_CLASS}__title`}>
                    {t`Welcome back, ${this.props.firstName}`}
                </h2>
                <p className={`${BASE_CLASS}__copy`}>
                    <Trans
                        fmtString={strings.get(
                            "ORDER_THANK_YOU_B3_CREATE_ACCT_COPY",
                        )}
                        data={{
                            UserEmail: () => (
                                <strong key="user-email">
                                    {this.props.email}
                                </strong>
                            ),
                            LoginLink: (content) => (
                                <Link
                                    key="login-link"
                                    className={`${BASE_CLASS}__link`}
                                    href={this.props.orderHistoryURL}
                                    target="_top"
                                >
                                    {content}
                                </Link>
                            ),
                        }}
                    />
                </p>
            </>
        );
    }

    private buildContent__AuthenticatedUserOrder(): JSX.Element {
        // Reqs Branch 4: An authenticated user placed an order.
        return (
            <>
                <h2 className={`${BASE_CLASS}__title`}>
                    {t`Thanks for ordering from us again.`}
                </h2>
                <p className={`${BASE_CLASS}__copy`}>
                    <Trans
                        fmtString={strings.get(
                            "ORDER_THANK_YOU_B4_CREATE_ACCT_COPY",
                        )}
                        data={{
                            MyOrdersLink: (content) => (
                                <Link
                                    key="orders-link"
                                    className={`${BASE_CLASS}__link`}
                                    href={this.props.orderHistoryURL}
                                    target="_top"
                                >
                                    {content}
                                </Link>
                            ),
                        }}
                    />
                </p>
            </>
        );
    }

    private buildContent(): JSX.Element {
        // See requirements in
        // https://gitlab.com/thelabnyc/tsi-sites/tsi-requirements/blob/feature_9446/tsi-tempurpedic/checkout/order-thank-you.md#create-account-form
        if (this.props.orderOwnerHasPassword) {
            if (this.props.isAuthenticatedAsOrderOwner) {
                return this.buildContent__AuthenticatedUserOrder();
            }
            return this.buildContent__AnonOrderForRegisteredUser();
        }
        if (this.props.isAuthenticatedAsOrderOwner) {
            return this.buildContent__AnonOrderForNewUser();
        }
        return this.buildContent__AnonOrderForNonRegisteredUser();
    }

    render() {
        return <div className={BASE_CLASS}>{this.buildContent()}</div>;
    }
}
