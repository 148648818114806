import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { FinancingPlan } from "../../../models/financing";
import format from "../../../utils/format";
import { IPayment } from "../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import {
    PRIMARY_PAYMENT_METHOD_KEY,
    SECONDARY_PAYMENT_METHOD_KEY,
} from "../constants";
import checkBadgeGreenCircle from "../../../../img/icons/check-badge-green-circle.png";
import circleEmpty from "../../../../img/icons/circle-empty.png";

const NAMES: { [c in IPayment["method_type"]]: string } = {
    "default": "",
    "cash": t`Cash`,
    "pay-later": t`Pay Later`,
    "cybersource": t`Credit Card`,
    "bluefin": t`Secure Credit Card`,
    "financing": t`Financing`,
    "synchrony": t`Financing`,
};

interface IProps {
    primary: IPayment["method_type"];
    secondary: IPayment["method_type"]; // what secondary method is available
    selected: boolean;
    methodKey: string;
    grandTotal: string | null;
    plans: FinancingPlan[];
    addPaymentMethod: Dispatchers["addPaymentMethod"];
    removePaymentMethod: Dispatchers["removePaymentMethod"];
    onClick: (
        event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    ) => void;
}

interface IState {}

export class PaymentEnabledField extends React.Component<IProps, IState> {
    private readonly onClick = (event: React.MouseEvent<HTMLElement>) => {
        if (this.props.methodKey === SECONDARY_PAYMENT_METHOD_KEY) {
            // adding secondary, so split pay
            const grandTotal: number = this.props.grandTotal
                ? parseFloat(this.props.grandTotal)
                : 0;
            let amount: number = Math.round((100 * grandTotal) / 2) / 100; // Round to hundreds, 50% split
            if (this.props.primary === "financing") {
                const plansAboveZero = this.props.plans
                    .map((plan) => parseFloat(plan.product_price_threshold))
                    .filter((a) => a > 0);
                const minFinancingAmount = plansAboveZero.length
                    ? Math.min(...plansAboveZero)
                    : 0;
                amount = Math.max(amount, minFinancingAmount);
            }
            const secondaryAmount: number = Math.max(0, grandTotal - amount);
            // set primary method based on what was clicked
            this.props.addPaymentMethod(
                PRIMARY_PAYMENT_METHOD_KEY,
                this.props.primary,
                format.decimal(amount),
            );
            // add the the secondary method
            this.props.addPaymentMethod(
                SECONDARY_PAYMENT_METHOD_KEY,
                this.props.secondary,
                format.decimal(secondaryAmount),
            );
        } else {
            // regular, non-split payment
            const amount = this.props.grandTotal;
            this.props.addPaymentMethod(
                PRIMARY_PAYMENT_METHOD_KEY,
                this.props.primary,
                format.decimal(amount),
            );
            // remove secondary method
            this.props.removePaymentMethod(SECONDARY_PAYMENT_METHOD_KEY);
        }
        this.props.onClick(event);
    };

    private readonly isPrimary = () => {
        return this.props.methodKey === PRIMARY_PAYMENT_METHOD_KEY
            ? true
            : false;
    };

    render() {
        const classes = classNames({
            "checkout-step__payment-type": true,
            "checkout-step__payment-type--active": this.props.selected,
            "checkout-step__payment-type-secondary": this.isPrimary()
                ? false
                : true,
            [`checkout-step__payment-type--${this.props.primary}`]: true,
            "al-checkout-step__payment-type": true,
            [`al-checkout-step__payment-type--${this.props.primary}`]: true,
        });
        const inputID = this.isPrimary()
            ? `payment-enabled-field-${this.props.primary}`
            : `payment-enabled-field-${this.props.primary}-${this.props.secondary}`;
        return (
            <li onClick={this.onClick} className={classes}>
                <label htmlFor={inputID}>
                    <input
                        id={inputID}
                        type="radio"
                        checked={this.props.selected}
                        readOnly={true}
                        name="payment-method"
                        aria-describedby="payment-method-instructions"
                    />
                    {this.props.selected ? (
                        <img alt="" src={checkBadgeGreenCircle} />
                    ) : (
                        <img alt="" src={circleEmpty} />
                    )}
                    {this.props.methodKey === PRIMARY_PAYMENT_METHOD_KEY
                        ? NAMES[this.props.primary]
                        : "Split Pay"}
                    {this.props.methodKey === SECONDARY_PAYMENT_METHOD_KEY &&
                        this.props.primary === "cybersource" &&
                        " Two Credit Cards"}
                    {this.props.methodKey === SECONDARY_PAYMENT_METHOD_KEY &&
                        this.props.primary === "bluefin" &&
                        " Two Credit Cards"}
                    {this.props.methodKey === SECONDARY_PAYMENT_METHOD_KEY &&
                        this.props.primary === "financing" &&
                        " Financing and Credit Card"}
                </label>
            </li>
        );
    }
}
