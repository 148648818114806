import React from "react";
import { FormFieldErrors } from "./FormFieldErrors";

interface IProps {
    errors?: string[];
    label?: string;
    isRequired?: boolean;
    children?: React.ReactNode;
}

export const FormField = (props: IProps) => {
    return (
        <label className={props.isRequired ? "required" : ""}>
            <span>{props.label}</span>
            {props.children}
            <FormFieldErrors errors={props.errors} />
        </label>
    );
};
