import { Response } from "../../utils/ajax";
import { guardUnhandledAction } from "../../utils/never";
import * as basketAPI from "../../api/basket";
import * as cybersourceAPI from "../../api/cybersource";
import * as checkoutAPI from "../../api/checkout";
import * as shippingAPI from "../../api/shipping";
import * as wishlistsAPI from "../../api/wishlists";
import { strings } from "../../localization";
import { IProductAPIURL, IBasketLineAPIURL } from "../../models/nominals";
import { urls } from "../../utils/urls";
import { IHTTPError } from "../../models/api.interfaces";
import {
    IPaymentState,
    IPaymentRejectMsgs,
    ICybersourceTemplateContext,
} from "../../models/checkout.interfaces";
import { IUser } from "../../models/user.interfaces";
import { PaymentState, OrderStatus } from "../../constants";
import { PRIMARY_PAYMENT_METHOD_KEY } from "./constants";
import { IReduxState, IPayment } from "./reducers.interfaces";
import { getShippingAddress, getBillingAddress, getCountryCode } from "./utils";
import { updateGTMUserHash } from "../../utils/analytics";
import { objectKeys } from "../../utils/functional";
import { getCaptchaToken } from "../../forms/Captcha";
import { config } from "../../config";
import { Loaders } from "./loaders";
import { Dispatchers } from "./dispatchers";

const PAYMENT_REJECT_MSGS: IPaymentRejectMsgs = {
    "cybersource": strings.get("REJECT_MSG_CYBERSOURCE") || "",
    "bluefin": strings.get("REJECT_MSG_CYBERSOURCE") || "",
    "financing": strings.get("REJECT_MSG_FINANCING") || "",
    "synchrony": strings.get("REJECT_MSG_SYF") || "",
    "cash": strings.get("REJECT_MSG_CASH") || "",
    "pay-later": strings.get("REJECT_MSG_CASH") || "",
};

export interface ICheckoutErrorBody {
    global?: string[];
    basket?: string[];
    shipping_address?: string[];
    shipping_method_code?: string[];
    payment?: string[];
    billing_address?: string[];
}

export type ICheckoutHTTPError = IHTTPError<ICheckoutErrorBody>;

export { Response };

export class Actions {
    private readonly loaders: Loaders;
    private readonly dispatchers: Dispatchers;

    constructor(loaders: Loaders, dispatchers: Dispatchers) {
        this.loaders = loaders;
        this.dispatchers = dispatchers;
    }

    public readonly addBasketLine = async (
        productURL: IProductAPIURL,
        qty?: number,
    ) => {
        await basketAPI.addProduct(productURL, qty);
        return this.loaders.loadBasket();
    };

    public readonly addBasketLines = async (
        lines: Array<{ productURL: IProductAPIURL; qty?: number }>,
    ) => {
        for (const { productURL, qty } of lines) {
            await basketAPI.addProduct(productURL, qty);
        }
        return this.loaders.loadBasket();
    };

    public readonly updateBasketLineQuantity = async (
        lineURL: IBasketLineAPIURL,
        newQty: number,
    ) => {
        await basketAPI.updateLineQuantity(lineURL, newQty);
        return this.loaders.loadBasket();
    };

    public readonly removeBasketLine = async (lineURL: IBasketLineAPIURL) => {
        await basketAPI.removeLine(lineURL);
        return this.loaders.loadBasket();
    };

    public readonly removeWishlistLine = async (lineURL: string) => {
        await wishlistsAPI.removeLine(lineURL);
        return this.loaders.loadBasket();
    };

    public readonly addVoucherCode = async (code: string) => {
        await basketAPI.addVoucherCode(code);
        await this.loaders.loadBasket();
    };

    public readonly removeVoucherCode = async (code: string) => {
        await basketAPI.removeVoucherCode(code);
        await this.loaders.loadBasket();
    };

    public readonly saveEmailAddress = async (state: IReduxState) => {
        updateGTMUserHash(state.form.guest_email);
        return checkoutAPI.saveEmailAddress(state.form.guest_email);
    };

    public readonly saveShippingAddress = async (
        state: IReduxState,
        bypassUSPSValidation?: boolean,
    ) => {
        const shippingAddr = getShippingAddress(state);
        const addr = await checkoutAPI.saveShippingAddress(
            shippingAddr,
            bypassUSPSValidation,
        );
        // Reload basket to get update taxes and basket offers after shipping address change
        await this.loaders.loadBasket();
        return addr;
    };

    public readonly saveBillingAddress = async (
        state: IReduxState,
        bypassUSPSValidation?: boolean,
    ) => {
        const billingAddr = getBillingAddress(state);
        return checkoutAPI.saveBillingAddress(
            billingAddr,
            bypassUSPSValidation,
        );
    };

    public readonly saveShippingMethod = async (state: IReduxState) => {
        const shippingAddress = getShippingAddress(state);
        shippingAddress.country = getCountryCode(shippingAddress.country);
        const methodCode = state.form.shipping_method;
        await shippingAPI.saveShippingMethod(shippingAddress, methodCode);
        // Reload basket to get update basket offers after shipping method changes
        return this.loaders.loadBasket();
    };

    public readonly savePreferredDeliveryDate = async (
        date: Date | null,
        state: IReduxState,
    ) => {
        const shippingAddress = getShippingAddress(state);
        await basketAPI.setPreferredDeliveryDate(
            // make sure we're using the short zip code
            shippingAddress.postcode.slice(0, 5),
            date,
        );
        return this.dispatchers.setPreferredDeliveryDate(date);
    };

    public readonly getOrderPayment = (state: IReduxState) => {
        type IBasePaymentMethod = {
            enabled: boolean;
            amount?: string;
            pay_balance?: boolean;
        };
        type ICash = IBasePaymentMethod & {
            method_type: "cash";
        };
        type IPayLater = IBasePaymentMethod & {
            method_type: "pay-later";
        };
        type ICybersource = IBasePaymentMethod & {
            method_type: "cybersource";
        };
        type IBluefin = IBasePaymentMethod & {
            method_type: "bluefin";
            payment_data: string;
        };
        type IFinancing = IBasePaymentMethod & {
            method_type: "financing";
            account_number: string;
            financing_plan: number | null;
        };
        type ISynchrony = IBasePaymentMethod & {
            method_type: "synchrony";
            dpos_token: string;
        };
        type IAPIPaymentMethod =
            | ICash
            | IPayLater
            | ICybersource
            | IBluefin
            | IFinancing
            | ISynchrony;
        type IAPIPaymentMethods = { [method_key: string]: IAPIPaymentMethod };
        const methods: IAPIPaymentMethods = {};
        const methodState = state.form.payment_methods;
        Object.keys(methodState).forEach((methodKey) => {
            const data = methodState[methodKey];
            let method: IAPIPaymentMethod;
            switch (data.method_type) {
                case "default":
                    throw new Error("Method type should never be default.");

                case "cash":
                    method = {
                        method_type: "cash",
                        enabled: true,
                    };
                    break;

                case "pay-later":
                    method = {
                        method_type: "pay-later",
                        enabled: true,
                    };
                    break;

                case "cybersource":
                    method = {
                        method_type: "cybersource",
                        enabled: true,
                    };
                    break;

                case "bluefin":
                    method = {
                        method_type: "bluefin",
                        enabled: true,
                        payment_data: data.payment_data,
                    };
                    break;

                case "financing":
                    method = {
                        method_type: "financing",
                        enabled: true,
                        account_number: data.financing_account,
                        financing_plan: data.financing_plan,
                    };
                    break;

                case "synchrony":
                    method = {
                        method_type: "synchrony",
                        enabled: true,
                        dpos_token: data.dpos_token || "",
                    };
                    break;

                default:
                    guardUnhandledAction(data);
                    return;
            }
            if (data.pay_balance) {
                method.pay_balance = true;
            } else {
                method.pay_balance = false;
                method.amount = data.amount || "0.00";
            }
            methods[methodKey] = method;
        });
        return methods;
    };

    public readonly placeOrder = async (
        user: IUser | null,
        state: IReduxState,
    ) => {
        if (!state.data.basket) {
            throw new Error("Can not checkout without a basket");
        }
        const basket = state.data.basket.url;
        const shippingAddr = getShippingAddress(state);
        const shippingMethod = state.form.shipping_method;
        const billingAddr = getBillingAddress(state);
        const guestEmail = state.form.guest_email;
        const payment = this.getOrderPayment(state);
        const recaptcha = config.get("ENABLE_CHECKOUT_CAPTCHA")
            ? await getCaptchaToken("checkout")
            : null;
        const order = await checkoutAPI.placeOrder(
            basket,
            shippingAddr,
            shippingMethod,
            billingAddr,
            payment,
            guestEmail,
            recaptcha || undefined,
        );
        this.dispatchers.updateOrder(order);
        return this.completeOrderPayment(user, state);
    };

    public readonly completeDeferredPayment = async (
        user: IUser | null,
        basketToken: string,
        orderToken: string,
        state: IReduxState,
    ) => {
        const payment = this.getOrderPayment(state);
        const order = await checkoutAPI.completeDeferredPayment(
            basketToken,
            orderToken,
            payment,
        );
        this.dispatchers.updateOrder(order);
        return this.completeOrderPayment(user, state);
    };

    public readonly completeOrderPayment = async (
        user: IUser | null,
        state: IReduxState,
    ) => {
        const handlers = this.getPaymentHandlers();
        const paymentMethods = Object.keys(state.form.payment_methods).map(
            (key) => {
                return state.form.payment_methods[key].method_type;
            },
        );
        const states = await checkoutAPI.checkPaymentStates();
        if (
            states.order_status === OrderStatus.AUTHORIZED ||
            states.order_status === OrderStatus.MANUAL_REVIEW ||
            states.payment_method_states[PRIMARY_PAYMENT_METHOD_KEY]?.status ===
                PaymentState.DEFERRED
        ) {
            this.forwardToThankYouPage();
            return;
        }
        this.dispatchers.updatePaymentStates(states);
        let highestAmount: number | null = null;
        let nextMethodKeyToCharge: string | null = null;
        // Check for decline
        objectKeys(states.payment_method_states).forEach((key) => {
            const paymentMethodState = states.payment_method_states[key];
            const paymentMethodType =
                state.form.payment_methods[key].method_type;
            // Match the current payment method to the corresponding paymentMethodState
            if (
                paymentMethods.includes(paymentMethodType) &&
                paymentMethodState.status === PaymentState.DECLINED
            ) {
                throw this.buildCheckoutError(
                    "global",
                    PAYMENT_REJECT_MSGS[`${paymentMethodType}`],
                );
            }
            // While we're looping, track highest pending amount
            if (
                paymentMethodState.status === PaymentState.PENDING &&
                (highestAmount === null ||
                    Number(paymentMethodState.amount) > highestAmount)
            ) {
                nextMethodKeyToCharge = key;
                highestAmount = Number(paymentMethodState.amount);
            }
        });
        // Try highest pending amount
        if (nextMethodKeyToCharge) {
            const paymentMethodState =
                states.payment_method_states[nextMethodKeyToCharge];
            const paymentMethodType =
                state.form.payment_methods[nextMethodKeyToCharge].method_type;
            const handler = handlers[paymentMethodType];
            handler(nextMethodKeyToCharge, user, state, paymentMethodState);
        }
        return;
    };

    private buildCheckoutError(
        errorKey: keyof ICheckoutErrorBody,
        msg: string,
    ) {
        const error: ICheckoutHTTPError = new Error(msg);
        error.response = {
            body: {
                [errorKey]: [msg],
            },
        };
        return error;
    }

    private getPaymentHandlers() {
        type IHandlers = {
            [t in IPayment["method_type"]]: (
                methodKey: string,
                user: IUser | null,
                storeState: IReduxState,
                paymentState: IPaymentState,
            ) => void;
        };
        const handlers: IHandlers = {
            "default": () => {
                throw this.buildCheckoutError(
                    "payment",
                    "Default method should never be pending",
                );
            },
            "cash": () => {
                throw this.buildCheckoutError(
                    "payment",
                    "Cash method should never be pending",
                );
            },
            "pay-later": () => {
                throw this.buildCheckoutError(
                    "payment",
                    "Pay Later method should never be pending",
                );
            },
            "financing": () => {
                throw this.buildCheckoutError(
                    "payment",
                    "Financing should never be pending",
                );
            },
            "synchrony": () => {
                throw this.buildCheckoutError(
                    "payment",
                    "Synchrony should never be pending",
                );
            },
            "cybersource": (methodKey, user, storeState, paymentState) => {
                const method = storeState.form.payment_methods[methodKey];
                if (!paymentState.required_action) {
                    return;
                }
                if (method.method_type !== "cybersource") {
                    throw new Error("Payment error occurred");
                }
                const context: ICybersourceTemplateContext = {
                    card_type: method.card_type,
                    card_number: method.card_number,
                    card_expiration: method.card_expiration,
                    card_cvc: method.card_cvc,
                    email: user ? user.email : storeState.form.guest_email,
                    billing_first_name: storeState.form.billing_first_name,
                    billing_last_name: storeState.form.billing_last_name,
                    billing_phone_number: storeState.form.billing_phone_number,
                    billing_line1: storeState.form.billing_line1,
                    billing_line2: storeState.form.billing_line2,
                    billing_line4: storeState.form.billing_line4,
                    billing_postcode: storeState.form.billing_postcode,
                    billing_state: storeState.form.billing_state,
                    billing_country: storeState.form.billing_country,
                };
                const request = cybersourceAPI.renderRequestTemplate(
                    context,
                    paymentState.required_action,
                );
                cybersourceAPI.submitRequest(request);
            },
            "bluefin": () => {
                throw this.buildCheckoutError(
                    "payment",
                    "Bluefin should never be pending",
                );
            },
        };
        return handlers;
    }

    private forwardToThankYouPage() {
        urls.navigateTo("checkout-thank-you");
    }
}
