import React from "react";
import memoize from "memoize-one";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { loadWistiaPlayer } from "../../../utils/wistia";
import { check } from "../../../models/utils";
import { IImageURL } from "../../../models/nominals";
import { IProduct, IOptionValues } from "../../../models/catalogue.interfaces";
import { IViewport } from "../../../models/screen.interfaces";
import {
    rootProductSelector,
    baseVariantSelector,
} from "../../configurator/selectors";
import {
    GalleryBlock as GalleryBlockCodec,
    IGallery,
    IAttributePair,
} from "../models";
import { Gallery } from "./Gallery";

interface IOwnProps {
    galleriesJSON: string;
    defaultThumbnail: IImageURL;
}

interface IReduxProps {
    viewport: IViewport;
    selectedRootProduct: IProduct | null;
    optionValues: IOptionValues;
    baseVariant: IProduct | null;
}

type IProps = IOwnProps & IReduxProps;

interface IState {
    gallery: IGallery | null;
}

class GalleryBlockComponent extends React.PureComponent<IProps, IState> {
    state = {
        gallery: null,
    };

    private readonly decodeGalleryJSON = memoize((json: string) => {
        return check(GalleryBlockCodec.decode(JSON.parse(json)));
    });

    componentDidMount() {
        loadWistiaPlayer();

        this.loadGallery();
    }

    componentDidUpdate() {
        this.loadGallery();
    }

    attributesInclude(attributes: IOptionValues, attributeVal: IAttributePair) {
        return Object.entries(attributes).find(([c, v]) => {
            return c === attributeVal.code && v === attributeVal.value;
        });
    }

    attributesMatch(
        attributes: IOptionValues,
        galleryAttributes: IAttributePair[],
    ) {
        return galleryAttributes.every((v) =>
            this.attributesInclude(attributes, v),
        );
    }

    private loadGallery() {
        const block = this.decodeGalleryJSON(this.props.galleriesJSON);

        // default galleries have no product or attribute set
        const defaultGallery = block.galleries.find((gallery) => {
            if (!gallery.product && !gallery.attributes?.length) {
                return true;
            }
            return false;
        });
        const matchtAttributesGallery = block.galleries.find((gallery) => {
            // only match on galleries with attributes selection and no product selection
            if (
                gallery.product || // don't match if product also selected
                !gallery.attributes?.length ||
                !this.props.selectedRootProduct?.id ||
                !this.props.baseVariant?.id
            ) {
                return false;
            }
            // all selected gallery variants match
            const variantMatch = this.attributesMatch(
                this.props.optionValues,
                gallery.attributes,
            );
            return variantMatch;
        });
        const productMatchGallery = block.galleries.find((gallery) => {
            // only match on product selection but no attributes selection
            if (
                gallery.attributes?.length || // don't match if attributes also selected
                !gallery.product ||
                !this.props.selectedRootProduct?.id ||
                !this.props.baseVariant?.id
            ) {
                return false;
            }
            const isRootOrVariant =
                gallery.product === this.props.selectedRootProduct?.id ||
                gallery.product === this.props.baseVariant?.id;
            return isRootOrVariant;
        });
        const matchProductAndAttributesGallery = block.galleries.find(
            (gallery) => {
                if (
                    !gallery.attributes?.length || // attributes must be selected
                    !gallery.product || // gallery must be selected
                    !this.props.selectedRootProduct?.id ||
                    !this.props.baseVariant?.id
                ) {
                    return false;
                }
                const isRootOrVariant =
                    gallery.product === this.props.selectedRootProduct?.id ||
                    gallery.product === this.props.baseVariant?.id;
                // all selected gallery variants match
                const variantMatch = this.attributesMatch(
                    this.props.optionValues,
                    gallery.attributes,
                );
                return isRootOrVariant && variantMatch;
            },
        );
        if (matchProductAndAttributesGallery) {
            this.setState({
                gallery: matchProductAndAttributesGallery,
            });
        } else if (productMatchGallery) {
            this.setState({
                gallery: productMatchGallery,
            });
        } else if (matchtAttributesGallery) {
            this.setState({
                gallery: matchtAttributesGallery,
            });
        } else if (defaultGallery) {
            this.setState({
                gallery: defaultGallery,
            });
            // reset to no gallery
        } else {
            this.setState({
                gallery: null,
            });
        }
    }

    render() {
        if (!this.state.gallery) {
            return null;
        }
        return (
            <Gallery
                gallery={this.state.gallery}
                defaultThumbnail={this.props.defaultThumbnail}
                viewport={this.props.viewport}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        viewport: state.common.viewport,
        optionValues: state.configurator.ui.optionValues,
        selectedRootProduct: rootProductSelector(state.configurator),
        baseVariant: baseVariantSelector(state.configurator),
        ...ownProps,
    };
};

export const GalleryBlock = connect(mapStateToProps)(GalleryBlockComponent);
