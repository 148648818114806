import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Dispatchers } from "../apps/configurator/dispatchers";
import { TStateMapper, TDispatchMapper } from "../apps/reducers.interfaces";
import { ConfiguratorTypes, OptionTypes } from "../constants";
import { defaults } from "../apps/configurator/defaults";
import {
    IOptionCode,
    IOptionSingleValue,
    IOptionValues,
    IProduct,
    IProductAttributeOptionGroup,
} from "../models/catalogue.interfaces";
import {
    productAttributeOptionGroupSelector,
    rootProductSelector,
} from "../apps/configurator/selectors";
import { Image } from "./Image";

import styles from "./ProductOptionRadioSelector.module.scss";
import {
    buildRelevantLevelVariants,
    findLevelVariants,
    getProductVariantsForOptionCodes,
} from "../apps/selectors";
import { InternalProduct } from "../models/catalogue";
import { getProductPrice, PriceType } from "../utils/catalogue";
import { roundedMoney } from "../utils/format";
import { IImageURL } from "../models/nominals";
import { listPossibleOptionValues } from "../utils/sorting";

interface IOwnProps {
    configuratorType?: ConfiguratorTypes;
    selectorID: string;
    rootProduct: IProduct;
    code: IOptionCode;
    value: IOptionSingleValue;
    options: IOptionSingleValue[];
    prefixNameCapped: string;
    configuratorClass: string;
    prefixClass: string;
    onChange: (event: React.FormEvent<HTMLInputElement>) => void;
    onKeyboardDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onClick: () => void;
    isIcon?: boolean;
    showPricing?: boolean;
    isPlpPanelStyle?: boolean;
}

interface IReduxProps {
    namespace: string | null;
    optionValues: IOptionValues;
    productAttributeOptionGroup: IProductAttributeOptionGroup | null;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

export class ProductOptionRadioSelectorComponent extends React.Component<
    IProps,
    IState
> {
    private buildPricingOverlay(variant: IProduct) {
        if (
            !this.props.showPricing ||
            !variant ||
            !variant.availability.is_available_to_buy
        ) {
            return null;
        }
        const retailPrice = getProductPrice(variant.price, {
            priceType: PriceType.RETAIL,
            includePostDiscountAddons: true,
            quantity: 1,
        });
        const cosmeticPrice = getProductPrice(variant.price, {
            priceType: PriceType.COSMETIC_EXCL_TAX,
            includePostDiscountAddons: true,
            quantity: 1,
        });
        if (cosmeticPrice.lessThan(retailPrice)) {
            return (
                <div className={styles.pricingOverlay}>
                    <span className={styles.actualPrice}>
                        {roundedMoney(cosmeticPrice)}
                    </span>
                    <span className={styles.retailPrice}>
                        {roundedMoney(retailPrice)}
                    </span>
                </div>
            );
        }
        return (
            <div className={styles.pricingOverlay}>
                <span>{roundedMoney(retailPrice)}</span>
            </div>
        );
    }

    private buildLabelContent(
        iconImageURL: IImageURL | null,
        isPanel: boolean,
        option: string,
    ) {
        const content = (
            <>
                {iconImageURL && isPanel && (
                    <Image className={styles.icon} src={iconImageURL} alt="" />
                )}
                {option}
            </>
        );
        return this.props.isPlpPanelStyle ? <div>{content}</div> : content;
    }

    // Refs #21452 - zlebar
    // Overriding `this.props.onChange` to accommodate
    // tsi-sealy's Desired "Level" behavior
    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();

        if ("option_level" in this.props.optionValues) {
            // Build a more updated optionValues object
            const updatedOptionValues = this.props.optionValues;
            updatedOptionValues[this.props.code] = e.currentTarget.value;

            // Build our Level options
            const levelOptions = listPossibleOptionValues(
                "option_level",
                this.props.rootProduct,
            );
            const levelVariants = buildRelevantLevelVariants(
                levelOptions,
                updatedOptionValues,
                this.props.rootProduct,
                "option_level",
            );
            const levelColumns =
                levelVariants.length > 2
                    ? findLevelVariants(levelVariants, updatedOptionValues)
                    : levelVariants;

            // If the newly selected options doesn't come in the currently selected Level
            if (
                this.props.optionValues.option_level !== levelColumns[0].level
            ) {
                if (this.props.namespace) {
                    // Change the Level
                    this.props.dispatchers.setOptionValue(
                        this.props.namespace,
                        "option_level",
                        0,
                        1,
                        levelColumns[0].level,
                    );
                }
            }
        }

        this.props.onChange(e);
    };

    render() {
        const codeVariants = this.props.options.reduce(
            (
                variants: Record<IOptionSingleValue, InternalProduct[]>,
                option,
            ) => {
                variants[option] = getProductVariantsForOptionCodes(
                    [this.props.rootProduct],
                    [this.props.code],
                    { [this.props.code]: option },
                );
                return variants;
            },
            {},
        );
        // Figure out how to group the radio options (based on the product_option_labels attribute)
        const labelConfig =
            this.props.rootProduct.attributes.product_option_labels?.value[
                this.props.code
            ] || [];
        if (labelConfig.length <= 0) {
            labelConfig.push({
                label: this.props.prefixNameCapped,
                values: this.props.options,
            });
        }
        // Render each label group
        const groups = labelConfig.map((labelGroup, groupIndex) => {
            // Are we rendering within a Panel?
            const isPanel =
                this.props.configuratorType === ConfiguratorTypes.PANEL;

            let isThreeColumnDesign: boolean;
            switch (this.props.code) {
                case OptionTypes.SIZE:
                case OptionTypes.COLOR:
                case OptionTypes.PROFILE:
                case OptionTypes.HEIGHT:
                    isThreeColumnDesign = true;
                    break;
                default:
                    isThreeColumnDesign = false;
            }

            const optionBtns = labelGroup.values.map((option, index) => {
                // Make sure option is valid
                if (this.props.options.indexOf(option) === -1) {
                    return null;
                }
                const optionContainerClass = classNames({
                    "configurator__radio-option-container": true,
                    [`configurator__radio-option-container--${this.props.selectorID}`]:
                        true,
                    [styles.twoColumnContainer]:
                        this.props.isPlpPanelStyle ||
                        (isPanel && !isThreeColumnDesign),
                    [styles.threeColumnContainer]:
                        !this.props.isPlpPanelStyle &&
                        isPanel &&
                        isThreeColumnDesign,
                });
                const optionClass = classNames({
                    "configurator__radio-option": true,
                    [`configurator__radio-option--${this.props.selectorID}`]:
                        true,
                    [styles.vertical]:
                        isPanel &&
                        (this.props.code === OptionTypes.SIZE ||
                            this.props.code === OptionTypes.COLOR ||
                            this.props.code === OptionTypes.HEIGHT ||
                            this.props.code === OptionTypes.PROFILE),
                    [styles.activeOption]: option === this.props.value,
                    [styles.panelRadioOption]: isPanel,
                    [styles.alternativePanelRadioOption]:
                        this.props.isPlpPanelStyle,
                });
                const optionNameSuffix = `${this.props.rootProduct.id}-${this.props.selectorID}-${this.props.code}-${groupIndex}-${index}`;
                const optionName = `configurator__radio-option--${optionNameSuffix}`;
                const optionsGroupName = `${this.props.code}-${this.props.selectorID}`;
                const optionSelected = option === this.props.value;

                let iconImageURL = null;
                this.props.productAttributeOptionGroup?.options.map((o) => {
                    if (o.option === option) {
                        iconImageURL = o.icon_image_url;
                    }
                });

                return (
                    <li key={option} className={optionContainerClass}>
                        <input
                            type="radio"
                            id={optionName}
                            name={optionsGroupName}
                            onChange={this.onChange}
                            onClick={this.props.onClick}
                            onKeyDown={this.props.onKeyboardDown}
                            value={option}
                            checked={optionSelected}
                        />
                        <label
                            htmlFor={optionName}
                            className={optionClass}
                            aria-selected={optionSelected}
                        >
                            {this.buildLabelContent(
                                iconImageURL,
                                isPanel,
                                option,
                            )}
                            {codeVariants?.[option] &&
                                this.buildPricingOverlay(
                                    codeVariants[option][0],
                                )}
                        </label>
                    </li>
                );
            });

            const prefixClass = classNames({
                [this.props.prefixClass]: true,
                "ada-screenreader-only": labelConfig.length <= 1 && isPanel,
            });

            return (
                <fieldset key={labelGroup.label}>
                    <legend className={prefixClass}>{labelGroup.label}</legend>
                    <ul className={this.props.configuratorClass}>
                        {optionBtns}
                    </ul>
                </fieldset>
            );
        });
        return (
            <div
                key={this.props.code}
                className="configurator__radio-container"
            >
                {groups}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    const rootProduct = rootProductSelector(state);
    return {
        productAttributeOptionGroup: productAttributeOptionGroupSelector(
            state,
            ownProps.code,
        ),
        namespace: rootProduct ? rootProduct.product_class_slug : null,
        optionValues: state.ui.optionValues,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const ProductOptionRadioSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductOptionRadioSelectorComponent);
