import { parseFacetValues, serializeFacetValues } from "../../utils/reviews";
import {
    IReviewsProductTypeID,
    IWebPageURL,
    isoWebPageURL,
} from "../../models/nominals";
import {
    IReviewQueryFacets,
    IReviewQuery,
} from "../../models/reviews.interfaces";

interface IParams {
    [name: string]: string | number | number[] | undefined | null;
}

type IFacetParams = {
    [T in IReviewQueryFacets]: string | number | null;
};

export const getParamsFromFacetValues = (
    facetValues: IReviewQuery,
    productTypeIDs: IReviewsProductTypeID[],
): IFacetParams => {
    const params: IFacetParams = {
        text: null,
        rating: null,
        product_uuid: null,
        product_id: null,
        source_id: null,
        product_variant_id: null,
        ...facetValues,
        product_type_id: serializeFacetValues(productTypeIDs),
    };
    return params;
};

export const buildReviewsURL = (baseURL: IWebPageURL, params: IParams) => {
    const url = new URL(isoWebPageURL.unwrap(baseURL));
    // Build the new, combined query params object
    Object.keys(params).forEach((key) => {
        const value = params[key];
        if (value === undefined || value === null) {
            url.searchParams.delete(key);
        } else {
            url.searchParams.set(key, `${value}`);
        }
    });
    return url.toString();
};

export const updateQueryParams = (params: IParams) => {
    // Build the URL and replace the current history state with it
    const url = buildReviewsURL(
        isoWebPageURL.wrap(window.location.href),
        params,
    );
    window.history.replaceState(null, "", url.toString());
};

export const readQueryParams = () => {
    const urlObj = new URL(window.location.href);
    const query: IParams = {};
    urlObj.searchParams.forEach((value, name) => {
        query[name] = `${value}`;
    });
    if (query.product_type_id) {
        const typeIDs = parseFacetValues(`${query.product_type_id || ""}`).map(
            (id: string) => parseInt(id, 10),
        );
        query.product_type_id = typeIDs;
    }
    return query;
};
