import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Key } from "ts-key-enum";
import {
    IOptionCode,
    IOptionValue,
    IOptionSingleValue,
} from "../../../models/catalogue.interfaces";
import { Dispatchers } from "../dispatchers";
import { rootProductSelector } from "../selectors";
import { notEmpty, unique } from "../../../utils/functional";
import { sortProductOptions } from "../../../utils/sorting";
import { getOptionValueHash } from "../../../utils/catalogue";
import { slugify } from "../../../utils/text";
import {
    TStateMapper,
    TDispatchMapper,
    IRootState as IReduxState,
} from "../../reducers.interfaces";
import { ProductOptionDropdownSelector } from "../../../common/ProductOptionDropdownSelector";
import { VariantOptionBlockInputTypes } from "../../../constants";
import { getOptionValueSet, getOptionValueByIdx } from "../utils";

interface IOwnProps {
    attributeCode: IOptionCode;
    inputType?: string;
}

interface IReduxProps {
    namespace: string | null;
    selectedValues: IOptionValue | undefined;
    optionValues: IOptionSingleValue[];
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class PDPChangeVariantOptionBlockComponent extends React.Component<
    IProps,
    IState
> {
    private readonly optionRefs: (HTMLButtonElement | null)[] = [];

    private readonly onChange = (
        e: React.FormEvent<HTMLSelectElement | HTMLInputElement>,
    ) => {
        if (!this.props.namespace) {
            return;
        }
        this.props.dispatchers.setOptionValue(
            this.props.namespace,
            this.props.attributeCode,
            0,
            1,
            e.currentTarget.value,
        );
    };

    private onOptionSelectorClick(
        event: React.MouseEvent<HTMLElement>,
        value: IOptionSingleValue,
    ) {
        event.preventDefault();
        if (!this.props.namespace) {
            return;
        }
        this.props.dispatchers.setOptionValue(
            this.props.namespace,
            this.props.attributeCode,
            0,
            1,
            value,
        );
    }

    private onOptionSelectorKeyDown(
        event: React.KeyboardEvent<Element>,
        index: number,
    ) {
        if (!this.props.namespace) {
            return;
        }

        const prevIndex = index - 1;
        const nextIndex = index + 1;

        const prevValue = this.props.optionValues[prevIndex];
        const nextValue = this.props.optionValues[nextIndex];

        const prevRef = this.optionRefs[prevIndex];
        const nextRef = this.optionRefs[nextIndex];

        // Left arrow
        if (event.key === Key.ArrowLeft && prevValue && prevRef) {
            this.props.dispatchers.setOptionValue(
                this.props.namespace,
                this.props.attributeCode,
                0,
                1,
                prevValue,
            );
            prevRef.focus();
        }

        // Right arrow
        if (event.key === Key.ArrowRight && nextValue && nextRef) {
            this.props.dispatchers.setOptionValue(
                this.props.namespace,
                this.props.attributeCode,
                0,
                1,
                nextValue,
            );
            nextRef.focus();
        }
    }

    private buildOptionSelector(index: number) {
        const selectedValues = getOptionValueSet(this.props.selectedValues);
        const value = this.props.optionValues[index];
        const isSelected = selectedValues.has(value);

        const classes = classNames({
            // 'button': true,
            // 'button--primary-congress-blue': true,
            "pdp-change-variant-option-block__option": true,
            "pdp-change-variant-option-block__option--selected": isSelected,
        });
        // Build IDs for relationship to tab controls. These IDs must match the IDs used by PDPSelectedVariantConditionalBlock.
        const tabControlID = slugify(
            `change-variant-block-${this.props.attributeCode}-${value}`,
        );
        const tabPanelID = slugify(
            `variant-conditional-block-${this.props.attributeCode}-${value}`,
        );
        return (
            <button
                key={getOptionValueHash(value)}
                ref={(ref) => {
                    this.optionRefs[index] = ref;
                }}
                id={tabControlID}
                role="tab"
                aria-selected={isSelected}
                aria-controls={tabPanelID}
                className={classes}
                onClick={(e) => {
                    this.onOptionSelectorClick(e, value);
                }}
                onKeyDown={(e) => {
                    this.onOptionSelectorKeyDown(e, index);
                }}
                tabIndex={isSelected ? 0 : -1}
            >
                {value}
            </button>
        );
    }

    render() {
        if (this.props.inputType === VariantOptionBlockInputTypes.DROPDOWN) {
            const htmlID = "productOption";
            const prefixName = this.props.attributeCode.substr(
                this.props.attributeCode.indexOf("_") + 1,
                this.props.attributeCode.length,
            );
            const prefixNameCapped =
                prefixName[0].toUpperCase() + prefixName.substr(1);
            return (
                <ProductOptionDropdownSelector
                    selectorID={htmlID}
                    code={this.props.attributeCode}
                    value={
                        getOptionValueByIdx(this.props.selectedValues, 0) || ""
                    }
                    options={this.props.optionValues}
                    prefixNameCapped={prefixNameCapped}
                    configuratorClass={"product-option-dropdown"}
                    prefixClass={""}
                    selectClass={""}
                    onChange={this.onChange}
                />
            );
        }

        return (
            <nav role="tablist">
                {this.props.optionValues.map((_, index) => {
                    return this.buildOptionSelector(index);
                })}
            </nav>
        );
    }
}

const getOptionValues = (
    state: Pick<IReduxState, "configurator">,
    attributeCode: IOptionCode,
): IOptionSingleValue[] => {
    const rootProduct = rootProductSelector(state.configurator);
    if (!rootProduct) {
        return [];
    }
    const children = rootProduct.children;
    if (!children || children.length <= 0) {
        const attr = rootProduct.attributes[attributeCode];
        if (!attr) {
            return [];
        }
        return Array.isArray(attr.value) ? attr.value : [attr.value];
    }
    const values = children
        .reduce<Array<IOptionSingleValue | null>>((memo, child) => {
            const attr = child.attributes[attributeCode];
            return memo.concat(attr ? attr.value : null);
        }, [])
        .filter(notEmpty)
        .filter(unique);
    return sortProductOptions(attributeCode, values);
};

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    const values = getOptionValues(state, ownProps.attributeCode);
    const rootProduct = rootProductSelector(state.configurator);
    return {
        ...ownProps,
        namespace: rootProduct ? rootProduct.product_class_slug : null,
        selectedValues:
            state.configurator.ui.optionValues[ownProps.attributeCode],
        optionValues: values,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const PDPChangeVariantOptionBlock = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PDPChangeVariantOptionBlockComponent);
