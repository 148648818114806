import {
    StoreLocationProvider,
    buildBaseQuery,
} from "tsi-common-react/src/apps/retail/loaders";
import { searchStores, convertStorePageShape } from "../api/retail";

/**
 * Drop in replacement for tsi-common-react/src/apps/retail/loaders:Loaders.loadClosestStores,
 * which queries the Wagtail API for store locations rather than the retail
 * locator API.
 */
export const loadClosestStores: StoreLocationProvider = async (
    location,
    extraFilters,
) => {
    const baseQuery = buildBaseQuery(location);
    if (!baseQuery) {
        return [];
    }
    const resp = await searchStores({
        ...baseQuery,
        ...extraFilters,
    });
    return resp.items.map(convertStorePageShape);
};
