import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { IUser } from "../../../models/user.interfaces";
import { Step } from "../constants";
import { IReduxFormState } from "../reducers.interfaces";

interface IOwnProps {
    onEdit?: (event?: React.MouseEvent<HTMLElement>) => void;
}

interface IReduxProps {
    user: IUser | null;
    form: IReduxFormState;
    showSummary: boolean;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class ShippingAddressSummaryComponent extends React.Component<IProps, IState> {
    render() {
        const summaryClasses = classNames({
            "checkout-step__summary": true,
            "checkout-step__summary--collapsed": !this.props.showSummary,
        });
        return (
            <div className={summaryClasses}>
                <h3 className="checkout-step__label">{t`Ship to:`}</h3>
                <div className="checkout-step__summary-address">
                    {this.props.form.shipping_first_name}{" "}
                    {this.props.form.shipping_last_name}
                    <br />
                    {this.props.form.shipping_line1}
                    <br />
                    {this.props.form.shipping_line2 ? (
                        <div>{this.props.form.shipping_line2}</div>
                    ) : null}
                    {this.props.form.shipping_line4},{" "}
                    {this.props.form.shipping_state}{" "}
                    {this.props.form.shipping_postcode}
                    <br />
                    {this.props.form.shipping_phone_number}
                    <br />
                    {this.props.user &&
                    this.props.user.email &&
                    !this.props.user.is_csr
                        ? this.props.user.email
                        : this.props.form.guest_email}
                    <br />
                </div>
                {this.props.onEdit && (
                    <a
                        className="checkout-step__back"
                        href="#"
                        onClick={this.props.onEdit}
                    >
                        {t`Edit`}
                    </a>
                )}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        user: rootState.common.user,
        form: state.form,
        showSummary: state.form.current_step > Step.SHIPPING_ADDRESS,
        ...ownProps,
    };
};

export const ShippingAddressSummary = connect(mapStateToProps)(
    ShippingAddressSummaryComponent,
);
