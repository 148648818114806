import React from "react";
import { t } from "ttag";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { IProductAPIURL } from "../../../models/nominals";
import { IBasket, IWishListLine } from "../../../models/catalogue.interfaces";
import { WishListLine } from "./WishListLine";

interface IProps {
    lines: IWishListLine[];
    addBasketLine?: (
        productURL: IProductAPIURL,
        qty?: number,
    ) => Promise<IBasket>;
    removeWishlistLine: (lineURL: string) => Promise<IBasket>;
}

interface IState {}

export class WishListLines extends React.Component<IProps, IState> {
    private renderLineList() {
        return this.props.lines.map((line) => {
            return (
                <CSSTransition
                    key={line.url}
                    classNames="transition--fade"
                    timeout={{ exit: 500, enter: 500 }}
                >
                    <WishListLine {...this.props} line={line} />
                </CSSTransition>
            );
        });
    }

    private renderEmptyBasket() {
        return <p>{t`This list is empty.`}</p>;
    }

    render() {
        const isEmpty = this.props.lines.length <= 0;
        const classes = classNames({
            "wishlist-lines__content": true,
            "wishlist-lines__content--empty": isEmpty,
        });
        return (
            <div className={classes}>
                {isEmpty && this.renderEmptyBasket()}
                <TransitionGroup>{this.renderLineList()}</TransitionGroup>
            </div>
        );
    }
}
