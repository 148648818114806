import React from "react";
import { t } from "ttag";
import { money } from "../../../utils/format";
import { getBasketTotal } from "../utils";
import { IBasket } from "../models.interfaces";

interface IProps extends IBasket {}

interface IState {}

export class CustomerDetailsBasket extends React.Component<IProps, IState> {
    state: IState = {};

    private buildLines() {
        if (this.props.lines.length <= 0) {
            return (
                <tr>
                    <td colSpan={7}>
                        <em>Basket is empty</em>
                    </td>
                </tr>
            );
        }
        return this.props.lines.map((line) => {
            return (
                <tr key={line.id}>
                    <td>{line.id}</td>
                    <td>{line.product.title}</td>
                    <td>{line.product.attribute_summary || "-"}</td>
                    <td>{line.quantity}</td>
                    <td>{money(line.price_excl_tax)}</td>
                    <td></td>
                </tr>
            );
        });
    }

    render() {
        const created = this.props.date_created
            ? new Date(this.props.date_created).toLocaleString()
            : "-";
        const merged = this.props.date_merged
            ? new Date(this.props.date_merged).toLocaleString()
            : "-";
        const submitted = this.props.date_submitted
            ? new Date(this.props.date_submitted).toLocaleString()
            : "-";
        const totalExclTax = getBasketTotal(this.props);
        return (
            <div className="csr-customer-basket">
                <div className="csr-customer-basket__basic">
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>{t`Basic Details`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{t`Status`}</th>
                                <td>{this.props.status}</td>
                            </tr>
                            <tr>
                                <th>{t`Total (excl. Tax)`}</th>
                                <td>{money(totalExclTax)}</td>
                            </tr>
                            <tr>
                                <th>{t`Date Created`}</th>
                                <td>{created}</td>
                            </tr>
                            <tr>
                                <th>{t`Date Merged`}</th>
                                <td>{merged}</td>
                            </tr>
                            <tr>
                                <th>{t`Date Submitted`}</th>
                                <td>{submitted}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="csr-customer-basket__lines">
                    <h2>Lines</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>{t`Line ID`}</th>
                                <th>{t`Product`}</th>
                                <th>{t`Options`}</th>
                                <th>{t`Quantity`}</th>
                                <th>{t`Line Price (excl. Tax)`}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{this.buildLines()}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}
