import { default as superagent, Request, Response } from "superagent";
import cookies from "js-cookie";

/**
 * Export common interfaces
 */
export { Request, Response };

/**
 * Make superagent work with promises
 */
export const ajax = superagent;

/**
 * Load a CSRF token using the API. Sometimes necessary since CSRf tokens aren't included in cached page responses.
 */
export const loadCSRFToken = async () => {
    return ajax
        .get("/api/csrf-token/")
        .set("Accept", "application/json")
        .then((resp) => {
            return resp.body.csrf_token as string;
        });
};

/**
 * Name of CSRF Token Header
 */
export const CSRF_HEADER = "X-CSRFToken";

/**
 * Get the value of the CSRF Token Cookie
 */
export const getCSRFToken = async () => {
    let token: string | undefined = cookies.get("csrftoken");
    if (!token) {
        token = await loadCSRFToken();
    }
    return token;
};
