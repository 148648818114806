import React from "react";
import { _RichNavigation__ImageLink as Value } from "@reactivated";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import styles from "./index.module.scss";

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const RichNavigationImageLink = (props: Props) => {
    return (
        <a href={props.value.destination?.url}>
            {props.value.promo_period?.is_promo_active && <p>Promo Active</p>}
            {props.value.text && <RichTextBlock value={props.value.text} />}
            {props.value.image && (
                <ImageChooserBlock
                    attrs={{ className: styles.img }}
                    value={props.value.image}
                />
            )}
        </a>
    );
};
