import { useEffect } from "react";
import { config } from "../../config";

// interface IProps {}

const loadUnifyScript = ({
    libraryURL,
    syfPartnerId,
}: {
    libraryURL: string;
    syfPartnerId: string;
}) => {
    const scriptID = "syfMPPScript";
    // Use the script's ID attr to make sure this doesn't happen more than once
    // per page load.
    if (document.getElementById(scriptID)) {
        console.warn(
            `Aborting loading ${libraryURL} because it's already been loaded once.`,
        );
        return;
    }

    // Setup Unify config
    window.syfWidgetObject = {
        syfPartnerId: syfPartnerId,
        flowType: "PDP",
    };

    // Load Unify library script
    const script = document.createElement("script");
    script.onload = () => {
        console.debug(`Loaded script: ${libraryURL}`);
    };
    script.onerror = () => {
        console.warn(`Failed to load script: ${libraryURL}`);
    };
    script.id = scriptID;
    script.src = libraryURL;
    document.body.appendChild(script);
};

export const UnifyMerchantLibrary = () => {
    useEffect(() => {
        const libraryURL = config.get("SYF_UNIFY_LIBRARY_URL");
        const syfPartnerId = config.get("SYF_UNIFY_PARTNER_ID");
        if (libraryURL && syfPartnerId) {
            loadUnifyScript({
                libraryURL,
                syfPartnerId,
            });
        }
    }, []);
    return null;
};
