export const initAccordion = async (elem: HTMLElement) => {
    elem.addEventListener("click", (event) => {
        event.preventDefault();
        elem.classList.toggle("--is-active");
        if (!elem.parentNode) {
            return;
        }

        const ariaExpanded = elem.getAttribute("aria-expanded");
        if (
            ariaExpanded !== null &&
            (ariaExpanded === "true" || ariaExpanded === "false")
        ) {
            elem.setAttribute(
                "aria-expanded",
                ariaExpanded === "true" ? "false" : "true",
            );
        }

        let accordionTriggerParent = null;
        // A360 issue 77,78 exception: trigger elem is not a sibling of target elem
        if (
            elem.parentElement &&
            elem.tagName === "BUTTON" &&
            elem.parentElement.tagName === "H5"
        ) {
            accordionTriggerParent = elem.parentElement.parentElement;
        } else {
            // default: trigger elem is a sibling of target elem
            accordionTriggerParent = elem.parentElement;
        }

        if (!accordionTriggerParent) {
            window.console.error("accordion trigger has no parent element");
            return;
        }
        Array.from(accordionTriggerParent.children)
            .filter((e) => {
                return e.classList.contains("accordion-target");
            })
            .forEach((e) => {
                // e.classList.toggle('accordion-target--is-active');
                if (e.getAttribute("aria-hidden") === "true") {
                    e.classList.add("accordion-target--is-active");
                    e.setAttribute("aria-hidden", "false");
                } else {
                    e.classList.remove("accordion-target--is-active");
                    e.setAttribute("aria-hidden", "true");
                }
            });
    });
};
