import React from "react";
import { t } from "ttag";
import {
    SynchronyModalData,
    UnifyModalCloseEvent,
    SYFTransactionCode,
} from "../../../models/synchrony";
import { getDPOSToken, lookupTransaction } from "../../../api/synchrony";
import { UnifyMerchantLibrary } from "../../synchrony/UnifyMerchantLibrary";
import { config } from "../../../config";
import { Dispatchers } from "../dispatchers";
import { ISynchronyPayment } from "../reducers.interfaces";
import { IPaymentMethodFormProps } from "./PaymentMethod.interfaces";

interface IProps extends IPaymentMethodFormProps {
    setPaymentMethodFields: Dispatchers["setPaymentMethodFields"];
}

interface IState {}

export class Synchrony extends React.Component<IProps, IState> {
    private readonly onOpenModal = async (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
        if (!window.syfMPP) {
            console.error("Synchrony library is not loaded.");
            return;
        }
        const data = await this.buildModalData();
        console.log("Attempting to open SYF model with data:", data);
        window.syfMPP.calldBuyProcess(null, data);
    };

    private readonly onWindowMessage = (event: MessageEvent<unknown>) => {
        // Check if this message is a unify modal close event, or just some
        // other window message. Ignore anything other than Unify messages.
        if (!UnifyModalCloseEvent.is(event)) {
            return;
        }
        this.lookupTransaction();
    };

    private async buildModalData(): Promise<SynchronyModalData> {
        const token = await this.loadDPOSToken();
        const syfPartnerId = config.get("SYF_UNIFY_PARTNER_ID") || "";
        const methodData = this.getMethodData();
        const data: SynchronyModalData = {
            // REQUIRED FIELDS
            tokenId: token,
            syfPartnerId: syfPartnerId,
            merchantID: "",
            processInd: "3",
            clientTransId: "",
            custAddress1: this.props.form.billing_line1,
            custAddress2: this.props.form.billing_line2,
            custCity: this.props.form.billing_line4,
            custState: this.props.form.billing_state,
            custZipCode: this.props.form.billing_postcode,
            transAmount1: methodData.amount || "",
            transPromo1: "",
            // OPTIONAL FIELDS
            custFirstName: this.props.form.billing_first_name,
            custLastName: this.props.form.billing_last_name,
            phoneNumber: this.props.form.billing_phone_number,
        };
        return data;
    }

    private async loadDPOSToken(): Promise<string> {
        const tokenResp = await getDPOSToken();
        this.props.setPaymentMethodFields(this.props.methodKey, {
            dpos_token: tokenResp.token,
        });
        return tokenResp.token;
    }

    private async lookupTransaction() {
        const methodData = this.getMethodData();
        if (!methodData.dpos_token) {
            throw new Error("Missing DPOS token");
        }
        const data = await lookupTransaction(methodData.dpos_token);
        // Log the results to aid in debugging.
        const status = data.creditAuthorizationInfo.transactionCode;
        // Update the component state
        this.props.setPaymentMethodFields(this.props.methodKey, {
            transaction_code: status,
        });
    }

    private getMethodData(): ISynchronyPayment {
        const data = this.props.form.payment_methods[this.props.methodKey];
        let syfData: ISynchronyPayment | null = null;
        if (data.method_type === "synchrony") {
            syfData = data;
        }
        const baseFields: ISynchronyPayment = {
            method_type: "synchrony",
            amount: "",
            pay_balance: false,
            dpos_token: null,
            transaction_code: null,
        };
        return {
            ...baseFields,
            ...syfData,
        };
    }

    componentDidMount(): void {
        window.addEventListener("message", this.onWindowMessage);
    }

    componentWillUnmount(): void {
        window.removeEventListener("message", this.onWindowMessage);
    }

    render() {
        const methodData = this.getMethodData();
        const isSuccess =
            methodData.transaction_code === SYFTransactionCode.SUCCESS;
        const isErrorOrNeverOpened = !isSuccess;
        const isError = !isSuccess && methodData.transaction_code !== null;
        return (
            <div className="checkout-step__payment-form">
                <UnifyMerchantLibrary />
                {isErrorOrNeverOpened && (
                    <button
                        type="button"
                        onClick={this.onOpenModal}
                        className="button button--full-width"
                    >
                        {t`Pay with Synchrony Financing`}
                    </button>
                )}
                {isError && (
                    <p className="form__error">
                        {t`Synchrony payment was not completed. Please try again or select a different payment method.`}
                    </p>
                )}
                {isSuccess && (
                    <p>
                        {t`Synchrony payment confirmed. Click “Place Order” to continue.`}
                    </p>
                )}
            </div>
        );
    }
}
