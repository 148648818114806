import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { t } from "ttag";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import format from "../utils/format";
import { urls } from "../utils/urls";
import { focusElement } from "../utils/keyboardFocus";
import { Dinero, getDinero, getMonthlyPrice } from "../utils/money";
import { Link } from "./Link";
import iconXClose from "../../img/icons/x-close.svg";

interface IProps {
    price?: Dinero;
    title: string;
    linkText: string;
}

interface IState {
    modalHeight: number;
    modalIsOpen: boolean;
}

export class CardFinancingModal extends React.Component<IProps, IState> {
    private modalContent: HTMLDivElement | null = null;

    public state: IState = {
        modalHeight: 600,
        modalIsOpen: false,
    };

    private readonly onOpenModal = (event: React.MouseEvent<HTMLElement>) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            modalIsOpen: true,
        });
    };

    private readonly onCloseModal = (
        event: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        this.setState({
            modalIsOpen: false,
        });
    };

    private readonly onAfterModalOpen = () => {
        if (!this.modalContent) {
            return;
        }
        this.setState({
            modalHeight: this.modalContent.getBoundingClientRect().height,
        });

        // close button is automatically focused when the modal first opens
        focusElement(".address-suggestion-model__close");
    };

    private renderPlanTable() {
        const plansHTML = Array.from(
            document.querySelectorAll<HTMLElement>(
                ".financing-plan-table__plan",
            ),
        );

        // Build table rows
        const planRows = plansHTML.map((elem, index) => {
            const moPrice = format.money(
                getMonthlyPrice(
                    getDinero(elem.dataset.threshold),
                    parseInt(elem.dataset.length || "", 10),
                ),
            );
            const rowClasses = classNames({
                "card-financing-modal-table__row": true,
                "card-financing-modal-table__row--odd":
                    Math.abs((index + 1) % 2) === 1,
                "card-financing-modal-table__row--even": (index + 1) % 2 === 0,
            });
            return (
                <tr key={index} className={rowClasses}>
                    <th
                        scope="row"
                        className="card-financing-modal-table__cell"
                    >
                        ${elem.dataset.threshold}
                    </th>
                    <td className="card-financing-modal-table__cell">
                        <Link href={urls.pageURL("finance-link")}>
                            {t`${elem.dataset.length} months`}
                            <sup>1</sup>
                        </Link>
                    </td>
                    <td className="card-financing-modal-table__cell">
                        <Link href={urls.pageURL("finance-link")}>
                            {t`${moPrice}/mo`}
                            <sup>2</sup>
                        </Link>
                    </td>
                </tr>
            );
        });

        return (
            <table className="card-financing-modal-table">
                <thead>
                    <tr className="card-financing-modal-table__row--title">
                        <th
                            scope="col"
                            className="card-financing-modal-table__cell card-financing-modal-table__cell--title"
                        >
                            {t`With a minimum purchase of...`}
                        </th>
                        <th
                            scope="col"
                            className="card-financing-modal-table__cell card-financing-modal-table__cell--title"
                        >
                            {t`Get 0% APR for...`}
                        </th>
                        <th
                            scope="col"
                            className="card-financing-modal-table__cell card-financing-modal-table__cell--title"
                        >
                            {t`With a monthly payment of...`}
                        </th>
                    </tr>
                </thead>
                <tbody>{planRows}</tbody>
            </table>
        );
    }

    render() {
        const padding = 30; // adjust this to your needs
        const height = this.state.modalHeight + padding * 2;
        const heightPx = `${height}px`;
        const heightOffset = height / 2;
        const offsetPx = `${heightOffset}px`;

        const modalStyle: Modal.Styles = {
            content: {
                top: "50%",
                bottom: "auto",
                left: "50%",
                right: "auto",
                position: "fixed",
                transform: "translate(-50%,-" + offsetPx + ")",
                height: heightPx,
                padding: padding,
            },
        };

        return (
            <span>
                <button
                    className="product-card__installment product-card__installment--modal-link"
                    onClick={this.onOpenModal}
                >
                    {this.props.linkText}
                </button>
                <Modal
                    aria={{ modal: true }}
                    contentLabel={t`Special Financing Available`}
                    className="card-financing-modal"
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.onAfterModalOpen}
                    onRequestClose={this.onCloseModal}
                    role="dialog"
                    style={modalStyle}
                >
                    <div
                        className="card-financing-modal__all-content"
                        ref={(ref) => {
                            this.modalContent = ref;
                        }}
                    >
                        <button
                            aria-label={t`Close`}
                            className="card-financing-modal__close"
                            onClick={this.onCloseModal}
                        >
                            <SVG
                                aria-hidden="true"
                                className="card-financing-modal__close-icon"
                                src={iconXClose}
                            />
                        </button>
                        <div className="card-financing-modal__content">
                            <h1 className="card-financing-modal__title">
                                {t`Special Financing Available`}
                            </h1>
                            <div className="card-financing-modal__copy-container">
                                <p
                                    className="card-financing-modal__copy"
                                    dangerouslySetInnerHTML={{
                                        __html: interpolate(
                                            t`Finance the %s (%s) when part of a qualifying order:`,
                                            [
                                                this.props.title,
                                                format.money(this.props.price),
                                            ],
                                        ),
                                    }}
                                ></p>
                                {this.renderPlanTable()}
                            </div>
                            <div className="card-financing-modal__cta-container">
                                <div className="card-financing-modal__cta-copy">
                                    {t`Financing with approved credit`}
                                </div>
                                <button
                                    className="button card-financing-modal__cta"
                                    onClick={this.onCloseModal}
                                >
                                    {t`Continue shopping`}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </span>
        );
    }
}
