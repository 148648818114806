import { Store } from "@reduxjs/toolkit";
import { IRootState } from "../reducers.interfaces";
import { Dispatchers } from "./dispatchers";

type IRootCommonState = Pick<IRootState, "common">;

export const registerCascades = (store: Store<IRootCommonState>) => {
    const dispatchers = new Dispatchers(store.dispatch);

    // Dispatch a Redux action when the viewport is resized
    window.addEventListener("resize", () => {
        dispatchers.setViewport({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    });
};
