import * as userAPI from "../../api/user";
import { Dispatchers } from "./dispatchers";

export class Loaders {
    private readonly dispatchers: Dispatchers;

    constructor(dispatchers: Dispatchers) {
        this.dispatchers = dispatchers;
    }

    public readonly loadCurrentUser = async () => {
        const user = await userAPI.load();
        this.dispatchers.setCurrentUser(user);
        return user;
    };
}
