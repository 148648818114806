import React from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { connect } from "react-redux";
import { t } from "ttag";
import { IReviewsProductTypeID } from "../../../models/nominals";
import {
    IReviewQuery,
    ISearchFacet,
    IReviewsProduct,
} from "../../../models/reviews.interfaces";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { defaultState } from "../defaults";
import { AppliedFilters } from "./AppliedFilters";
import { IProps as IFilterProps, Filter } from "./Filter";
import styles from "./SidebarFilterSection.module.scss";
import iconXClose from "../../../../img/icons/x-close.svg";

interface IOwnProps extends IFilterProps {
    isMobile: boolean;
    showAppliedFilters: boolean;
    writeReviewURL: string;
    onToggleMobileSideNav: () => void;
    mobileSideNavOpen: boolean;
    isCondensedView?: boolean;
    isCollapsedView?: boolean;
    showProducts: boolean;
}

interface IReduxProps {
    products: IReviewsProduct[];
    facets: ISearchFacet[];
    productTypeIDs: IReviewsProductTypeID[];
    facetValues: IReviewQuery;
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class SidebarFilterSectionComponent extends React.PureComponent<
    IProps,
    IState
> {
    private readonly buildCloseButton = () => {
        return (
            <div className={styles.topbar}>
                <button
                    className={styles.closeButton}
                    onClick={this.props.onToggleMobileSideNav}
                    aria-label={t`close`}
                >
                    <SVG
                        className={styles.closeButtonSvg}
                        src={iconXClose}
                        role="img"
                        aria-hidden="true"
                    />
                </button>
            </div>
        );
    };

    private readonly buildWriteReviewButton = () => {
        if (this.props.writeReviewURL === "") {
            return null;
        }
        return (
            <a className={styles.link} href={this.props.writeReviewURL}>
                <span className={styles.writeAReviewButton}>
                    <span className={styles.writeAReviewButtonIcon}></span>
                    <h3>{t`Write a Review`}</h3>
                </span>
            </a>
        );
    };

    private readonly buildFilterButton = () => {
        return (
            <button
                className={styles.filterToggleButton}
                onClick={this.props.onToggleMobileSideNav}
            >
                <h3>
                    {t`Filter Reviews`}
                    <span aria-hidden="true">
                        <sup>§</sup>
                    </span>
                </h3>
            </button>
        );
    };

    private readonly buildSideNav = () => {
        const textFacets = this.props.facets
            .filter((facet) => facet.type === "text")
            .map((facet) => (
                <Filter
                    {...this.props}
                    key={facet.type}
                    facet={facet}
                    isCollapsedView={this.props.isCollapsedView}
                    isCondensedView={this.props.isCondensedView}
                />
            ));
        const otherFacets = this.props.facets
            .filter((facet) => facet.type !== "text")
            .map((facet) => (
                <Filter
                    {...this.props}
                    key={facet.type}
                    facet={facet}
                    isCollapsedView={this.props.isCollapsedView}
                    isCondensedView={this.props.isCondensedView}
                />
            ));

        const filterButtonClasses = classNames({
            [styles.filterButtons]: true,
            [styles.filterButtonsMobile]: this.props.isMobile,
        });

        if (this.props.isMobile) {
            // hide reviews and show filter options
            if (this.props.mobileSideNavOpen) {
                return (
                    <>
                        {this.buildCloseButton()}
                        {otherFacets}
                    </>
                );
            }
            // show search box and filter button
            return (
                <>
                    {textFacets}
                    <div className={filterButtonClasses}>
                        {this.buildWriteReviewButton()}
                        {this.buildFilterButton()}
                    </div>
                    {this.props.showAppliedFilters && <AppliedFilters />}
                </>
            );
        }
        // show search box, navbutton and filters
        return (
            <>
                {textFacets}
                {this.buildWriteReviewButton()}
                {otherFacets}
            </>
        );
    };

    render() {
        const filterClasses = classNames({
            [styles.sideFilters]: true,
            [styles.sideFiltersMobile]: this.props.isMobile,
            [styles.sideFiltersDesktop]: !this.props.isMobile,
            "customer-review__side-filters": true,
            "customer-review__side-filters--desktop": !this.props.isMobile,
            "customer-review__side-filters--mobile": this.props.isMobile,
        });
        return <div className={filterClasses}>{this.buildSideNav()}</div>;
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        products: state.data.products,
        facets: state.data.facets,
        productTypeIDs: state.ui.productTypeIDs,
        facetValues: state.ui.facetValues,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateFilterOptionValue: dispatchers.updateFilterOptionValue,
    };
};

export const SidebarFilterSection = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SidebarFilterSectionComponent);
