import React from "react";
import { t } from "ttag";

interface IProps {
    formTitle: string;
}

interface IState {}

export class WriteReviewFormHeader extends React.PureComponent<IProps, IState> {
    render() {
        return (
            <div className="write-review-form__header">
                <h2 className="write-review-form__title">
                    {this.props.formTitle}
                </h2>
                <p className="write-review-form__sub-title">
                    (
                    <span className="write-review-form__sub-title--red">*</span>
                    ){" "}
                    <span className="write-review-form__sub-title--italic">
                        {t`indicates a required field`}
                    </span>
                </p>
            </div>
        );
    }
}
