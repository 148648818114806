import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { IProduct } from "../../../models/catalogue.interfaces";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { ProductCompareGrid__ColumnCard_Component } from "../../product-compare/components/ProductCompareGrid__ColumnCard";
import styles from "./RootProductCompareModalContent.module.scss";
import { ISafeHTML, isoSafeHTML } from "../../../models/nominals";
import { IViewport } from "../../../models/screen.interfaces";

interface IOwnProps {
    optionSet: IModularConfiguratorOptionSet;
}

interface IReduxProps {
    rootProducts: IProduct[];
    viewport: IViewport;
    priceSelectValue: string;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class RootProductCompareModalContentComponent extends React.PureComponent<
    IProps,
    IState
> {
    private getTiles(): ICompareTile[] {
        if (!this.props.optionSet.root_product_compare_modal) {
            return [];
        }
        const baseTiles =
            this.props.optionSet.root_product_compare_modal.products;
        // Annotate each tile with the related product data
        const tiles = baseTiles
            .map((baseTile): ICompareTile => {
                const rootProduct = this.props.rootProducts.find((rp) => {
                    return rp.id === baseTile.product_compare_tile.product_id;
                });
                return {
                    ...baseTile,
                    rootProducts: rootProduct ? [rootProduct] : [],
                };
            })
            .filter((tile) => tile.rootProducts.length > 0);
        return tiles;
    }

    private buildHeader(): ISafeHTML {
        return isoSafeHTML.wrap(
            `<h3 id="heading">${t`What's the Difference?`}</h3>`,
        );
    }

    render() {
        const tiles = this.getTiles();
        return (
            <div className={styles.root}>
                {/* wrapping with the same class as the Wagtail Block Variant to ensure proper styling */}
                <div
                    className={`${styles.grid} product-compare-grid-column-card`}
                >
                    <ProductCompareGrid__ColumnCard_Component
                        header={this.buildHeader()}
                        tiles={tiles}
                        priceSelectValue={this.props.priceSelectValue}
                        viewport={this.props.viewport}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<
    "configurator" | "productcompare",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    return {
        viewport: rootState.common.viewport,
        priceSelectValue: rootState.productcompare.priceSelectValue,
        rootProducts: rootState.configurator.entities.rootProducts,
        ...ownProps,
    };
};

export const RootProductCompareModalContent = connect(mapStateToProps)(
    RootProductCompareModalContentComponent,
);
