import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { FinancingModalTriggerTheme } from "tsi-common-react/src/constants";
import { UnifiModalTrigger } from "./UnifiModalTrigger";
import { UnifiModalTriggerBlockCopy } from "./UnifiModalTriggerBlockCopy";

interface IProps {
    theme?: FinancingModalTriggerTheme;
    termAPR: string;
    termMonths: number;
    termThreshold: string;
    aprInfoID: string;
    promoPeriod: string;
}

export const UnifiModalTriggerBlockContent = (props: IProps) => {
    if (props.theme === FinancingModalTriggerTheme.SPECIAL) {
        return (
            <>
                <UnifiModalTriggerBlockCopy {...props} />
                <a href="/pre-qualification-and-financing" className="button">
                    {t`Learn More`}
                </a>
            </>
        );
    }

    const checkNowClassesDesktop = classNames({
        "button": true,
        "pre-approval-ad__button": true,
        "pre-approval-ad__button--block": true,
        "pre-approval-ad__button--check": true,
        "al-homepage__pre-approval-ad--check-now": true,
        "al-homepage__pre-approval-ad--check-now-desktop": true,
    });
    const buttonCopy =
        props.theme === FinancingModalTriggerTheme.MINI_FINANCING
            ? t`Pre-qualify Now`
            : props.theme === FinancingModalTriggerTheme.CARD_OVERLAP
              ? t`See if You Pre-qualify`
              : t`Check Now`;

    return (
        <>
            <UnifiModalTriggerBlockCopy {...props} />
            {
                <UnifiModalTrigger
                    className={checkNowClassesDesktop}
                    termThreshold={props.termThreshold}
                >
                    {buttonCopy}
                </UnifiModalTrigger>
            }
        </>
    );
};
