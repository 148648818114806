import React from "react";

interface IProps {
    forwardedRef: React.RefObject<HTMLDivElement>;
    primaryLinkRef: React.RefObject<HTMLAnchorElement>;
    tileUID: string | number;
    children?: React.ReactNode;
}

interface IState {}

export class GridTile extends React.Component<IProps, IState> {
    private readonly clickPrimaryLink = () => {
        if (this.props.primaryLinkRef.current) {
            this.props.primaryLinkRef.current.click();
        }
    };

    render() {
        return (
            <article
                ref={this.props.forwardedRef}
                className={`post al-tlovegrid-tile-${this.props.tileUID}`}
                role="group"
                aria-label="item"
            >
                <section
                    className="post__content"
                    onClick={this.clickPrimaryLink}
                >
                    {this.props.children}
                </section>
            </article>
        );
    }
}
