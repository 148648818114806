import { decorateElements } from "./react";
import { getPageSetting } from "./settings";

export const toggleAttributeValue = (elem: HTMLElement, attr: string) => {
    if (elem.hasAttribute(attr)) {
        const val = JSON.parse(elem.getAttribute(attr) || "");
        if (val) {
            elem.setAttribute(attr, "false");
        } else {
            elem.setAttribute(attr, "true");
        }
    }
};

export const initPromoCalloutMessages = async () => {
    await decorateElements(".promo_callout_RTF", (elems: HTMLElement[]) => {
        const promoCalloutMessage = getPageSetting(
            "primary-promo-callout-message",
        );

        for (const elem of elems) {
            const replaceText = (el: Element) => {
                if (el.childElementCount > 0) {
                    replaceText(el.children[0]);
                } else {
                    el.innerHTML = promoCalloutMessage;
                }
            };

            if (promoCalloutMessage) {
                replaceText(elem);
            }
            // This workaround, utilizing setTimeout, helps reduce the visibility of the abrupt transition in the copy
            setTimeout(() => {
                elem.classList.add("visible");
            }, 200);
        }
    });
};

export const openStoreLocatorPanel = () => {
    const storeLocatorPanel = document.querySelector<HTMLElement>(
        "#store-locator-panel",
    );

    storeLocatorPanel?.classList.add("is-open");
    hideStoreLocatorPanel("false");
    document.body.classList.add("modal-open");
    storeLocatorPanel?.addEventListener("click", (event) => {
        event.stopPropagation();
    });
    storeLocatorPanel?.addEventListener("transitionend", () => {
        const bodyWithModal =
            document.querySelector<HTMLElement>(".modal-open");
        if (bodyWithModal) {
            bodyWithModal.addEventListener(
                "click",
                closeStoreLocatorPanelOnBodyClick,
            );
        }
    });
};

export const closeStoreLocatorPanel = () => {
    document.getElementById("store-locator-panel")?.classList.remove("is-open");
    hideStoreLocatorPanel("true");
    document.body.classList.remove("modal-open");
    document.body.removeEventListener(
        "click",
        closeStoreLocatorPanelOnBodyClick,
    );
};

export const hideStoreLocatorPanel = (hide: string) => {
    const panelElem = document.getElementById("store-locator-panel");
    if (panelElem) {
        panelElem.setAttribute("aria-hidden", hide);
    }
};

export const isStoreLocatorPanelOpen = () => {
    const panel = document.getElementById("store-locator-panel");
    return panel?.classList.contains("is-open") || false;
};

const closeStoreLocatorPanelOnBodyClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.className.includes("mobile-toggle")) {
        return false;
    }
    event.preventDefault();
    if (isStoreLocatorPanelOpen()) {
        closeStoreLocatorPanel();
    }
    return true;
};
