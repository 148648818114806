import type { PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import classNames from "classnames";
import update from "immutability-helper";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import {
    IAddress,
    IAddressCountry,
    IAddressState,
    IBillingAddress,
} from "../../../models/address.interfaces";
import { IUser } from "../../../models/user.interfaces";
import { IReduxAPIData } from "../reducers.interfaces";
import {
    PreQualStatus,
    FinancingPreQualRequest,
    FinancingPreQualResponse,
    FinancingAccountInquiry,
    FinancingPreQualRequestResponse,
    PreQualCustomerResponse,
} from "../../../models/financing";
import { strings } from "../../../localization";
import * as financingAPI from "../../../api/financing";
import {
    checkPreScreenStatus,
    clearPreScreenOffer,
} from "../../financing/reducers";
import { focusElement } from "../../../utils/keyboardFocus";
import { Form } from "../../../forms/Form";
import { NonFieldErrors } from "../../../forms/NonFieldErrors";
import { objectKeys } from "../../../utils/functional";
import { FormSubmit } from "../../../forms/FormSubmit";
import { ErrorBoundary } from "../../../common/ErrorBoundary";
import { BillingAddressForm } from "../payment/BillingAddressForm";
import { PRIMARY_PAYMENT_METHOD_KEY, Step } from "../constants";
import { Dispatchers } from "../dispatchers";
import { Loaders } from "../loaders";
import { Actions } from "../actions";
import { IReduxFormState } from "../reducers.interfaces";
import { AddressSuggestionModal } from "../components/AddressSuggestionModal";
import { AddressWarningModal } from "../components/AddressWarningModal";
import { CheckoutStepContainer } from "./CheckoutStepContainer";
import { BillingAddressSummary } from "./BillingAddressSummary";

export interface IPreScreenModalProps {
    isOpen: boolean;
    preApprovalLimit: string;
    preApprovalOfferID: string;
    onRequestClose: (custResp: PreQualCustomerResponse) => void;
    onNewAccount: (account: FinancingAccountInquiry) => void;
}

const enum ModalState {
    CLOSED,
    PRESCREEN_OPEN,
}

interface IOwnProps {
    heading: string;
    errors: string[];
    showFinancingPreQual?: boolean;
    isCurrentStep: boolean;
    buildPreScreenModal?: (props: IPreScreenModalProps) => JSX.Element | null;
    saveBillingAddress: (bypassUSPSValidation: boolean) => Promise<IAddress>;
    onContinue: (event?: React.MouseEvent<HTMLElement>) => void;
    onEdit: (event?: React.MouseEvent<HTMLElement>) => void;
    formRef?: React.RefObject<Form>;
}

interface IReduxProps {
    user: IUser | null;
    form: IReduxFormState;
    countries: IAddressCountry[];
    states: IAddressState[];
    savedAddresses: IAddress[];
    billingMethodErrors: string[];
    showForm: boolean;
    paymentMethods: IReduxAPIData["payment_methods"];
}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    modalState: ModalState;
    isSaving: boolean;
    showErrorMessages: boolean;
    preApprovalLimit: string | null;
    preApprovalOfferID: string | null;
    showAddressWarningModal: boolean;
    showAddressSuggestionModal: boolean;
    suggestedAddress: IAddress | null;
    errors: {
        non_field_errors?: string[];
        billing_first_name?: string[];
        billing_last_name?: string[];
        billing_line1?: string[];
        billing_line2?: string[];
        billing_line4?: string[];
        billing_state?: string[];
        billing_postcode?: string[];
        billing_country?: string[];
        billing_phone_number?: string[];
    };
}

class BillingAddressComponent extends React.Component<IProps, IState> {
    private _stateCache: IState | null = null;
    private readonly submitButton = React.createRef<HTMLInputElement>();

    public state: IState = {
        modalState: ModalState.CLOSED,
        isSaving: false,
        showErrorMessages: false,
        preApprovalLimit: null,
        preApprovalOfferID: null,
        showAddressWarningModal: false,
        showAddressSuggestionModal: false,
        suggestedAddress: null,
        errors: {},
    };

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        event.preventDefault();
        if (this.getErrorCount() > 0) {
            this.showErrorMessages();
            return;
        }
        this.setState({
            isSaving: true,
            errors: {},
        });
        return this.saveAddress(false);
    };

    private readonly onValidStateChange = (
        fieldName: string,
        errorMessages: string[],
    ) => {
        const oldState = this._stateCache || this.state;
        const newState: IState = update(oldState, {
            errors: {
                $merge: {
                    [fieldName]: errorMessages,
                },
            },
        });
        this._stateCache = newState;
        this.setState(newState);
    };

    private readonly onBillingAddressChange = (
        fields: Partial<IBillingAddress>,
    ) => {
        this.props.dispatchers.changeFormField(fields);
    };

    private readonly continue = () => {
        this.setState({
            isSaving: false,
        });
        this.props.onContinue();
    };

    private readonly onPreApprovalError = () => {
        if (this.state.modalState !== ModalState.CLOSED) {
            this.closeModal();
            this.continue();
        }
    };

    componentDidUpdate(_: IProps, prevState: IState) {
        this._stateCache = null;

        // When the pre-screen modal first opens, hit the tracking beacon URL
        const prescreenWasOpen =
            prevState.modalState === ModalState.PRESCREEN_OPEN;
        const prescreenIsOpen =
            this.state.modalState === ModalState.PRESCREEN_OPEN;
        if (
            this.state.preApprovalOfferID &&
            prescreenIsOpen &&
            !prescreenWasOpen
        ) {
            financingAPI.sendPreScreenOfferDisplayConfirmation(
                this.state.preApprovalOfferID,
            );
        }
    }

    private addressesIsSame(addr: IAddress) {
        const line1 = addr.line1 === this.props.form.billing_line1;
        const line2 = addr.line2 === this.props.form.billing_line2;
        const line4 = addr.line4 === this.props.form.billing_line4;
        const state = addr.state === this.props.form.billing_state;
        const postcode = addr.postcode === this.props.form.billing_postcode;
        return line1 && line2 && line4 && state && postcode;
    }

    private abortSave() {
        this.setState({
            isSaving: false,
            showErrorMessages: true,
        });
    }

    private showSuggestionModal(addr: IAddress) {
        this.setState({
            showAddressSuggestionModal: true,
            suggestedAddress: addr,
        });
    }

    private showWarningModal() {
        this.setState({
            showAddressWarningModal: true,
        });
    }

    private displayInlineErrors(errors: { [s: string]: string }) {
        if (errors.phone_number) {
            // map phone number error to appropriate field
            errors.billing_phone_number = errors.phone_number;
            delete errors.phone_number;
        }
        this.setState({
            isSaving: false,
            errors: errors,
            showErrorMessages: true,
        });
    }

    private async saveAddress(bypassUSPSValidation: boolean) {
        if (this.props.billingMethodErrors.length > 0) {
            return;
        }
        this.setState({
            isSaving: true,
            errors: {},
            showAddressWarningModal: false,
            showAddressSuggestionModal: false,
        });
        let addr: IAddress;
        try {
            addr = await this.props.saveBillingAddress(bypassUSPSValidation);
        } catch (err) {
            if (this.props.billingMethodErrors.length > 0) {
                this.abortSave();
                return;
            }
            if (Object.keys(err.response.body).length === 1) {
                if (err.response.body.billing_state) {
                    this.displayInlineErrors(err.response.body);
                    return;
                } else if (err.response.body.non_field_errors) {
                    this.showWarningModal();
                    return;
                }
            }
            this.displayInlineErrors(err.response.body);
            return;
        }
        if (this.props.billingMethodErrors.length > 0) {
            this.abortSave();
            return;
        }
        if (bypassUSPSValidation || this.addressesIsSame(addr)) {
            this.checkPreQualificationStatus();
            return;
        }
        this.showSuggestionModal(addr);
    }

    private showErrorMessages() {
        this.setState({
            showErrorMessages: true,
        });
    }

    private getErrorCount() {
        return objectKeys(this.state.errors).reduce((memo, field) => {
            const errors = this.state.errors[field];
            const numErrors = errors ? errors.length : 0;
            return memo + numErrors;
        }, 0);
    }

    private async checkPreQualificationStatus() {
        // If the user a CSR? If so, skip all of the PreQual/PreScreen stuff.
        if (this.props.user && this.props.user.is_csr) {
            this.continue();
            return;
        }
        // Check that pre approvals is enabled from the CMS
        if (!this.props.showFinancingPreQual) {
            // If not enabled continue onto the payment step
            this.continue();
            return;
        }
        // Check the user's prescreen status using data from the billing address form
        const prequalFormData: FinancingPreQualRequest = {
            first_name: this.props.form.billing_first_name,
            last_name: this.props.form.billing_last_name,
            line1: this.props.form.billing_line1,
            city: this.props.form.billing_line4,
            state: this.props.form.billing_state,
            postcode: this.props.form.billing_postcode.slice(0, 5),
            email: this.props.form.guest_email,
            phone: this.props.form.billing_phone_number,
        };
        let prescreenReqResp: PayloadAction<
            FinancingPreQualRequestResponse | undefined
        >;
        try {
            prescreenReqResp = await this.props.dispatchers.dispatch(
                /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                checkPreScreenStatus(prequalFormData) as any,
            );
        } catch (err) {
            // Log the server error
            const serverErrors = err.response.body || {
                detail: "An unknown error occurred",
            };
            console.error(serverErrors);
            // Continue on with checkout
            this.continue();
            return;
        }
        // If the user wasn't pre-qualified, continue onto the payment step
        const approvedResponse = prescreenReqResp?.payload?.responses.find(
            (resp) => resp.status === PreQualStatus.APPROVED,
        );
        if (!approvedResponse) {
            this.continue();
            return;
        }
        // If the user was pre-qualified, show them the pre-qual offer modal
        if (this.props.buildPreScreenModal) {
            this.openPreScreenModal(approvedResponse);
        }
    }

    private openPreScreenModal(preQualResp: FinancingPreQualResponse) {
        document.body.setAttribute("style", "overflow-y: hidden");
        this.setState({
            preApprovalLimit: preQualResp.credit_limit,
            preApprovalOfferID: preQualResp.response_id,
            modalState: ModalState.PRESCREEN_OPEN,
        });
    }

    private closeModal() {
        document.body.setAttribute("style", "overflow: initial");
        this.setState({
            modalState: ModalState.CLOSED,
        });
    }

    private onNewAccount(account: FinancingAccountInquiry) {
        // Select the new account
        this.props.dispatchers.resetPaymentMethods(this.props.paymentMethods);
        this.props.dispatchers.addPaymentMethod(
            PRIMARY_PAYMENT_METHOD_KEY,
            "financing",
        );
        this.props.dispatchers.setPaymentMethodFields(
            PRIMARY_PAYMENT_METHOD_KEY,
            {
                new_financing_account: account,
                financing_account: account.account_number,
            },
        );
        this.closeModal();
        this.continue();
    }

    private buildAddressSuggestionModal() {
        const onContinue = (shouldImport: boolean) => {
            if (shouldImport && this.state.suggestedAddress) {
                this.props.loaders.importBillingAddr(
                    this.state.suggestedAddress,
                );
            }
            this.saveAddress(true);
        };
        const closeSuggestionModal = () => {
            this.setState({
                showAddressSuggestionModal: false,
                isSaving: false,
            });
        };
        const focusOnSubmitButton = () => {
            if (this.submitButton.current) {
                focusElement(this.submitButton.current);
            }
        };
        return (
            <AddressSuggestionModal
                form={this.props.form}
                suggestedAddress={this.state.suggestedAddress}
                onContinue={onContinue}
                onRequestClose={closeSuggestionModal}
                onAfterClose={focusOnSubmitButton}
                showAddressSuggestionModal={
                    this.state.showAddressSuggestionModal
                }
                addressPrefix={"billing"}
            />
        );
    }

    private buildAddressWarningModal() {
        const onContinue = () => {
            this.saveAddress(true);
        };
        const onEdit = () => {
            this.setState({
                isSaving: false,
                showAddressWarningModal: false,
            });
        };
        return (
            <AddressWarningModal
                form={this.props.form}
                onContinue={onContinue}
                onEdit={onEdit}
                showAddressWarningModal={this.state.showAddressWarningModal}
                addressPrefix={"billing"}
            />
        );
    }

    render() {
        const bodyClasses = classNames({
            "checkout-step__body": true,
            "checkout-step__body--collapsed": !this.props.showForm,
        });

        let preScreenModal: JSX.Element | null = null;
        if (this.props.showFinancingPreQual && this.props.buildPreScreenModal) {
            preScreenModal = this.props.buildPreScreenModal({
                isOpen: this.state.modalState === ModalState.PRESCREEN_OPEN,
                preApprovalLimit: this.state.preApprovalLimit || "",
                preApprovalOfferID: this.state.preApprovalOfferID || "",
                onRequestClose: (custResp) => {
                    // Record their response for reporting to Wells Fargo
                    const prequalresponse =
                        financingAPI.setPreQualCustomerResponse(custResp);
                    prequalresponse.then((response) => {
                        // Send an update to Wells Fargo Retail Service
                        financingAPI.updatePreScreenChoiceValue(
                            custResp,
                            response.response_id,
                        );
                    });
                    // If not accepted, wipe the prescreen data
                    if (custResp !== PreQualCustomerResponse.ACCEPT) {
                        this.props.dispatchers.dispatch(
                            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                            clearPreScreenOffer() as any,
                        );
                    }
                    this.closeModal();
                    this.continue();
                },
                onNewAccount: (a) => {
                    this.onNewAccount(a);
                },
            });
        }

        return (
            <CheckoutStepContainer
                className="checkout-step--billing-address form"
                heading={this.props.heading}
                isCurrentStep={this.props.isCurrentStep}
            >
                <div className={bodyClasses}>
                    <Form onSubmit={this.onSubmit} ref={this.props.formRef}>
                        <NonFieldErrors
                            errors={this.state.errors.non_field_errors}
                            showErrorMessages={true}
                        />
                        <NonFieldErrors
                            errors={this.props.errors.concat(
                                this.props.billingMethodErrors,
                            )}
                            showErrorMessages={true}
                        />
                        <BillingAddressForm
                            countries={this.props.countries}
                            states={this.props.states}
                            savedAddresses={this.props.savedAddresses}
                            form={this.props.form}
                            errors={this.state.errors}
                            setBillingAddressIsSameAsShipping={
                                this.props.dispatchers
                                    .setBillingAddressIsSameAsShipping
                            }
                            selectSavedAddress={
                                this.props.dispatchers.selectSavedAddress
                            }
                            onChange={this.onBillingAddressChange}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                            disabled={this.state.isSaving}
                        />
                        {this.props.form.billing_addr_is_shipping_addr && (
                            <div className="checkout-step__summary-address">
                                {this.props.form.shipping_first_name}{" "}
                                {this.props.form.shipping_last_name}
                                <br />
                                {this.props.form.shipping_line1}
                                <br />
                                {this.props.form.shipping_line2 ? (
                                    <div>{this.props.form.shipping_line2}</div>
                                ) : null}
                                {this.props.form.shipping_line4},{" "}
                                {this.props.form.shipping_state}{" "}
                                {this.props.form.shipping_postcode}
                                <br />
                                {this.props.form.shipping_phone_number}
                                <br />
                            </div>
                        )}
                        <FormSubmit
                            className="button button--full-width button--continue-to-payment al-checkout__continue-button--to-payment"
                            inputRef={this.submitButton}
                            value={
                                strings.get(
                                    "CONTINUE_BTN_LABEL_BILLING_ADDRESS",
                                ) || "Continue"
                            }
                            disabled={this.state.isSaving}
                        />
                    </Form>
                </div>
                <BillingAddressSummary onEdit={this.props.onEdit} />
                {this.buildAddressWarningModal()}
                {this.buildAddressSuggestionModal()}
                <ErrorBoundary onError={this.onPreApprovalError}>
                    {preScreenModal}
                </ErrorBoundary>
            </CheckoutStepContainer>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        user: rootState.common.user,
        form: state.form,
        countries: state.data.countries,
        states: state.data.billing_states,
        savedAddresses: state.data.addresses,
        billingMethodErrors: state.data.billing_method_errors,
        paymentMethods: state.data.payment_methods,
        showForm: state.form.current_step === Step.BILLING_ADDRESS,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    const actions = new Actions(loaders, dispatchers);
    return {
        dispatchers: dispatchers,
        loaders: loaders,
        actions: actions,
    };
};

export const BillingAddress = connect(
    mapStateToProps,
    mapDispatchToProps,
)(BillingAddressComponent);
