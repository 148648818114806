import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { IAddressCountry } from "../../../models/address.interfaces";
import { Actions } from "../actions";
import { ICustomerSummary, ICustomerSearchResults } from "../models.interfaces";
import { CustomerDetails } from "./CustomerDetails";

interface IProps {}

interface IOwnProps {}

interface IReduxProps {
    countries: IAddressCountry[];
    customerSearch: ICustomerSearchResults;
}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class CustomerSearchComponent extends React.Component<IProps, IState> {
    search: HTMLInputElement | null | undefined;

    componentDidMount() {
        this.props.actions.searchCustomers(this.props.customerSearch.term, 1);
    }

    componentDidUpdate() {
        if (this.search) {
            this.search.focus();
        }
    }

    private readonly onSearchTermChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.actions.setCustomerSearchTerm(event.currentTarget.value);
    };

    private readonly onDoSearch = (
        page: number,
        event: React.FormEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        if (this.search) {
            this.props.actions.searchCustomers(this.search.value, page);
        }
    };

    private readonly onPrevPage = (event: React.MouseEvent<HTMLElement>) => {
        this.onDoSearch(this.props.customerSearch.page - 1, event);
    };

    private readonly onNextPage = (event: React.MouseEvent<HTMLElement>) => {
        this.onDoSearch(this.props.customerSearch.page + 1, event);
    };

    private renderCustomerResultRow(customer: ICustomerSummary, index: number) {
        const viewDetails = (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            const component = <CustomerDetails url={customer.url} />;
            this.props.actions.pushViewStack("Customer Details", component);
        };
        return (
            <tr key={index} className="csr-customer-search__result">
                <td>
                    {customer.first_name} {customer.last_name}
                </td>
                <td>{customer.email}</td>
                <td>
                    {customer.groups.map((name) => {
                        return (
                            <span className="csr-customer-group" key={name}>
                                {name}
                            </span>
                        );
                    })}
                </td>
                <td className="button-group">
                    <button
                        className="csr-customer-search__result-link"
                        onClick={viewDetails}
                    >
                        {t`Details`}
                    </button>
                </td>
            </tr>
        );
    }

    private renderCustomerResults() {
        if (
            !this.props.customerSearch.results ||
            this.props.customerSearch.results.length <= 0
        ) {
            return (
                <tr>
                    <td colSpan={5}>
                        <em>{t`No Results Found`}</em>
                    </td>
                </tr>
            );
        }
        return this.props.customerSearch.results.map((result, i) => {
            return this.renderCustomerResultRow(result, i);
        });
    }

    render() {
        return (
            <div className="csr-customer-search">
                <form onSubmit={this.onDoSearch.bind(this, 1)}>
                    <p className="csr-customer-search__help">
                        {t`To search a specific field, prefix the search term with the name of the field. For example: email:sarah@example.com.`}
                    </p>
                    <div className="csr-customer-search__input">
                        <input
                            type="search"
                            placeholder={t`Search`}
                            ref={(r) => {
                                this.search = r;
                            }}
                            value={this.props.customerSearch.term}
                            onChange={this.onSearchTermChange}
                        />
                    </div>
                    <div className="csr-customer-search__button">
                        <button type="submit">{t`Search`}</button>
                    </div>
                </form>
                <div className="csr-customer-search__results">
                    <p>
                        <em>
                            {t`Viewing page ${this.props.customerSearch.page}. Found ${this.props.customerSearch.total} total results.`}
                        </em>
                    </p>
                    <p className="button-group">
                        <button
                            disabled={!this.props.customerSearch.hasPrev}
                            onClick={this.onPrevPage}
                            className="csr-customer-search__page-link"
                        >
                            {t`Previous Page`}
                        </button>
                        <button
                            disabled={!this.props.customerSearch.hasNext}
                            onClick={this.onNextPage}
                            className="csr-customer-search__page-link"
                        >
                            {t`Next Page`}
                        </button>
                    </p>
                    <table>
                        <thead>
                            <tr>
                                <th>{t`Name`}</th>
                                <th>{t`Email`}</th>
                                <th>{t`Groups`}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{this.renderCustomerResults()}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        countries: rootState.csr.countries,
        customerSearch: rootState.csr.customerSearch,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const CustomerSearch = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerSearchComponent);
