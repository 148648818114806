import * as cleave from "cleave-zen";
import { FormatOptions } from "../models/formFields.interfaces";
import { assertNever } from "../utils/never";

/**
 * Wrapper class around cleave-zen functions
 */
export class Formatter<
    T extends FormatOptions,
    U extends (value: string, options: Omit<T, "type">) => string,
> {
    private readonly _options: T;
    private readonly _format: U;
    private readonly _unformat: U | undefined;
    private readonly cursorTrackingDelim: cleave.DelimiterType;

    public constructor({
        options,
        format,
        unformat,
        cursorTrackingDelim,
    }: {
        options: T;
        format: U;
        unformat?: U;
        cursorTrackingDelim: cleave.DelimiterType;
    }) {
        this._options = options;
        this._format = format;
        this._unformat = unformat;
        this.cursorTrackingDelim = cursorTrackingDelim;
    }

    public format(value: string): string {
        return this._format(value, this._options);
    }

    public unformat(value: string): string {
        return this._unformat ? this._unformat(value, this._options) : value;
    }

    public registerCursorTracker(
        input: HTMLInputElement,
    ): ReturnType<typeof cleave.registerCursorTracker> {
        return cleave.registerCursorTracker({
            input: input,
            delimiter: this.cursorTrackingDelim,
        });
    }
}

export const getFormatter = (formatOptions: FormatOptions) => {
    switch (formatOptions.type) {
        case "credit-card": {
            return new Formatter({
                options: formatOptions,
                format: cleave.formatCreditCard,
                unformat: cleave.unformatCreditCard,
                cursorTrackingDelim: cleave.DefaultCreditCardDelimiter,
            });
        }
        case "date": {
            return new Formatter({
                options: formatOptions,
                format: cleave.formatDate,
                unformat: undefined,
                cursorTrackingDelim: cleave.DefaultDateDelimiter,
            });
        }
        case "numeral": {
            return new Formatter({
                options: formatOptions,
                format: cleave.formatNumeral,
                unformat: cleave.unformatNumeral,
                cursorTrackingDelim: cleave.DefaultNumeralDelimiter,
            });
        }
        case "general": {
            return new Formatter({
                options: formatOptions,
                format: cleave.formatGeneral,
                unformat: cleave.unformatGeneral,
                cursorTrackingDelim: " ",
            });
        }
        case "time": {
            return new Formatter({
                options: formatOptions,
                format: cleave.formatTime,
                unformat: undefined,
                cursorTrackingDelim: cleave.DefaultTimeDelimiter,
            });
        }
        default:
            throw assertNever(formatOptions);
    }
};
