import { IWebPageURL, isoWebPageURL } from "../models/nominals";

const enum Context {
    CURRENT,
    TOP,
}

/**
 * Get a Page URL defined in the #common-page-urls div in the DOM.
 */
const pageURL = (name: string) => {
    const elem = document.getElementById("common-page-urls");
    if (!elem) {
        console.warn("Missing #common-page-urls element");
        return isoWebPageURL.wrap("/");
    }
    const link = elem.querySelector(`.${name}`) as HTMLAnchorElement;
    if (!link) {
        console.warn(`Missing #common-page-urls.${name} element`);
        return isoWebPageURL.wrap("/");
    }
    return isoWebPageURL.wrap(link.href);
};

const navigateTo = (name: string) => {
    const url = pageURL(name);
    navigateToURL(url);
};

const navigateToHome = () => {
    const homeURL = isoWebPageURL.wrap(window.location.origin);
    navigateToURL(homeURL);
};

const navigateToURL = (url: IWebPageURL, context = Context.TOP) => {
    const unwrappedURL = isoWebPageURL.unwrap(url);
    if (context === Context.TOP && window.top) {
        window.top.location.href = unwrappedURL;
        return;
    }
    window.location.href = unwrappedURL;
};

const getHash = (elem: string) => {
    const hasPDPHash =
        window.location.hash.length > 0 && window.location.hash.includes(elem);
    if (hasPDPHash) {
        return true;
    }
    return false;
};

// Export Public API
export const urls = {
    pageURL,
    navigateTo,
    navigateToHome,
    navigateToURL,
    getHash,
};
export default urls;
