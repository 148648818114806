import React from "react";
import { connect } from "react-redux";
import { PreferredDeliveryDateDisplayType } from "../constants";
import { IDeliveryDatePredictionState } from "../reducers.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { PredictedDeliveryDateStatus } from "../constants";
import styles from "./PreferredDeliveryDateHelpText.module.scss";

interface IOwnProps {
    type: PreferredDeliveryDateDisplayType;
}
interface IReduxProps {
    prediction: IDeliveryDatePredictionState;
}
interface IProps extends IOwnProps, IReduxProps {}
interface IState {}

class PreferredDeliveryDateHelpTextComponent extends React.Component<
    IProps,
    IState
> {
    render() {
        if (this.props.type === PreferredDeliveryDateDisplayType.DISABLED) {
            return null;
        }
        // Hide this help text when the user's location is "unpredictable"
        // I.e. Convey zones 3 or higher, or when the convey API is down.
        if (
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.ERROR &&
            (this.props.prediction.data.status_code ===
                "unpredictable-location-error" ||
                this.props.prediction.data.status_code ===
                    "deliverydates-api-offline-error")
        ) {
            return null;
        }
        return (
            <div className={styles.root}>
                <em>
                    You can select your preferred delivery date during checkout.
                </em>
            </div>
        );
    }
}
const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        prediction: rootState.checkout.data.predicted_delivery_date,
        ...ownProps,
    };
};

export const PreferredDeliveryDateHelpText = connect(mapStateToProps)(
    PreferredDeliveryDateHelpTextComponent,
);
