import { captureException } from "@sentry/browser";
import {
    ILatitude,
    ILongitude,
    ISyncStoreID,
    IProductUUID,
} from "../models/nominals";
import { IRetailStoreWithDistance } from "../models/location.interfaces";
import { RetailStoresWithDistance } from "../models/location";
import { check } from "../models/utils";
import { ajax } from "../utils/ajax";
import { trackEvent } from "../utils/analytics";
import config from "../config";

type ITextQuery = {
    // Search location by string
    q: string;
};

type IGeoQuery = {
    // Search location by latitude and longitude
    origLat: ILatitude;
    origLng: ILongitude;
};

type IOtherFilters = {
    // Max distance from search location
    distance?: number;

    // Return store distance to center
    show_distance?: "true" | "false";

    // Filter by store ID
    external_id?: ISyncStoreID;

    // Filter by store branch
    brand?: string;

    // Filter by store category
    category?: string | null;

    // Filter by retailer name
    retailer?: string[];

    // Filter by product availability
    product?: IProductUUID;

    // Max results. Default sot 500.
    limit?: number;

    // Limit by country.
    country?: string;

    // Slot filters
    has_slot_stearns_foster?: string;
    has_slot_tempur?: string;
    has_slot_sealy?: string;
};

export type IQueryParams = (ITextQuery | IGeoQuery) & IOtherFilters;

export enum StoreCategory {
    FLAGSHIP = "Tempur-Pedic Flagship Store",
    ELITE = "Elite",
    NOT_ELITE = "Not Elite",
}

export const searchStores = async (
    query: IQueryParams,
): Promise<IRetailStoreWithDistance[]> => {
    const apiURL = config.get("RETAIL_LOCATOR_API");
    const country = config.get("RETAIL_LOCATOR_COUNTRY");
    query = {
        brand: config.get("RETAIL_LOCATOR_BRAND"),
        show_distance: "true",
        distance: 3000,
        country: country,
        ...query,
    };
    return ajax
        .get(`${apiURL}/api/locations/`)
        .set("Accept", "application/json")
        .query(query)
        .then((resp) => {
            const stores = resp.body;
            // Log stores result
            if (stores.length === 0) {
                trackEvent(
                    "RetailLocator",
                    "storesNotFound",
                    "Stores Not Found at Distance",
                    {
                        nonInteraction: true,
                        distance: query.distance,
                    },
                );
            } else if (stores.length >= 500) {
                trackEvent(
                    "RetailLocator",
                    "tooManyStoresFound",
                    "Too Many Stores at Distance",
                    {
                        nonInteraction: true,
                        distance: query.distance,
                    },
                );
            }
            trackEvent(
                "RetailLocator",
                "numStoresFound",
                `Distance: ${query.distance}mi`,
                {
                    nonInteraction: true,
                    numStores: stores.length,
                },
            );
            // Decode types
            return check(RetailStoresWithDistance.decode(stores));
        })
        .catch((err) => {
            console.log(err);
            captureException(err);
            return [];
        });
};
