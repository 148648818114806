import { isoMiles } from "../../models/nominals";
import { IReduxState } from "./reducers.interfaces";

export const defaults: IReduxState = {
    storesLoadedKey: "",
    stores: [],
    storeFilters: {
        limit: 500,
        distance: isoMiles.wrap(10),
        category: null,
        retailerName: [],
    },
    focusedStoreID: null,
};
