import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { t } from "ttag";
import { strings } from "../../localization";
import { FinancingPreQualResponse } from "../../models/financing";
import { BackendType } from "../../models/financing";
import { trackEvent } from "../../utils/analytics";
import { FinancingAppType, ModalStateType } from "./constants";
import {
    StepIndex,
    TStepIndex,
    PrefillData,
    FullApplicationValues,
    ModalFullFormState,
    ModalPreQualApproved,
    ModalPreScreenApproved,
} from "./models";
import {
    checkPreQualStatus,
    checkPreScreenStatus,
    submitFullApplication,
    refreshPendingAppStatus,
} from "./thunks";
import { financingDefaults, getInitialFullAppValues } from "./defaults";

export const financingSlice = createSlice({
    name: "financing",
    initialState: financingDefaults,
    reducers: {
        openPreQualModal: (
            state,
            action: PayloadAction<{
                prefillData?: PrefillData;
            }>,
        ) => {
            state.modal = { _tag: ModalStateType.PREQUAL_INITIAL };
            state.prefillData = action.payload.prefillData || {};
            trackEvent("Financing", "openPreQualModal");
        },

        clearPreScreenOffer: (state) => {
            state.modal = { _tag: ModalStateType.CLOSED };
            state.preQualApproval = null;
        },

        openApplyModal: (
            state,
            action: PayloadAction<{
                backendType?: BackendType;
                purchasePrice?: number;
                applicationSource?: string;
                prefillData?: PrefillData;
            }>,
        ) => {
            state.purchasePrice = action.payload.purchasePrice || null;
            state.applicationSource = action.payload.applicationSource || null;
            state.prefillData = action.payload.prefillData || {};

            const backendType =
                (state.preQualApproval
                    ? state.preQualApproval.backend_type
                    : action.payload.backendType) || BackendType.WELLSFARGO;

            const preQualData =
                ModalPreQualApproved.is(state.modal) ||
                ModalPreScreenApproved.is(state.modal)
                    ? state.modal.data
                    : null;

            state.modal = {
                _tag: ModalStateType.APPLY_INITIAL,
                backendType: backendType,
                appType: FinancingAppType.INDIVIDUAL,
                values: getInitialFullAppValues({
                    prefillData: state.prefillData,
                    purchasePrice: state.purchasePrice,
                    preQualData: preQualData,
                }),
                // If prequal data exists here, skip the MAIN_CONTACT step and
                // "lock" it, to prevent editing.
                currStepIndex: preQualData
                    ? StepIndex.MAIN_INCOME
                    : StepIndex.MAIN_CONTACT,
                disabledSteps: preQualData ? [StepIndex.MAIN_CONTACT] : [],
            };

            trackEvent("Financing", "openCustomApplyFormModal");
        },

        closeModal: (state) => {
            // Only warn users once. This catches issues with disabled alerts.
            if (state.closeAttempts <= 0) {
                const msg =
                    state.modal._tag === ModalStateType.APPLY_APPROVED
                        ? t`Please make sure you have recorded your account number before closing this window. You will not be able to access it again.`
                        : t`Would you like to cancel your application? The form will close and your progress will be lost.`;
                if (!window.confirm(msg)) {
                    state.closeAttempts++;
                    return;
                }
            }
            state.closeAttempts = 0;
            state.modal = {
                _tag: ModalStateType.CLOSED,
            };
            // Wipe prequal data when closing the modal.
            // See https://thelabnyc.plan.io/issues/26302
            state.preQualApproval = null;
        },

        updateFullAppFormState: (
            state,
            action: PayloadAction<{
                backendType?: BackendType;
                appType?: FinancingAppType;
                values?: Partial<FullApplicationValues>;
                currStepIndex?: StepIndex;
            }>,
        ) => {
            if (!ModalFullFormState.is(state.modal)) {
                return;
            }
            const { values, ...kwargs } = action.payload;
            state.modal = {
                ...state.modal,
                ...kwargs,
                values: {
                    ...state.modal.values,
                    ...values,
                },
            };
        },

        fullAppNextStep: (
            state,
            action: PayloadAction<{
                totalStepCount: number;
            }>,
        ) => {
            if (!ModalFullFormState.is(state.modal)) {
                return;
            }
            const newStepIdx = Math.min(
                action.payload.totalStepCount - 1,
                state.modal.currStepIndex + 1,
            );
            state.modal = {
                ...state.modal,
                currStepIndex: TStepIndex.is(newStepIdx)
                    ? newStepIdx
                    : state.modal.currStepIndex,
            };
        },

        fullAppPrevStep: (state) => {
            if (!ModalFullFormState.is(state.modal)) {
                return;
            }
            const newStepIdx = Math.max(0, state.modal.currStepIndex - 1);
            state.modal = {
                ...state.modal,
                currStepIndex: TStepIndex.is(newStepIdx)
                    ? newStepIdx
                    : state.modal.currStepIndex,
            };
        },

        nextBackendAcceptOffer: (
            state,
            action: PayloadAction<{
                offer: FinancingPreQualResponse;
                values: FullApplicationValues;
            }>,
        ) => {
            state.preQualApproval = action.payload.offer;
            state.modal = {
                _tag: ModalStateType.APPLY_INITIAL,
                backendType: action.payload.offer.backend_type,
                appType: FinancingAppType.INDIVIDUAL,
                values: {
                    ...action.payload.values,
                    // Require re-checking the terms boxes
                    agree_disclosure: false,
                    esign: false,
                    agree_disclosure_joint: false,
                    esign_joint: false,
                },
                currStepIndex: StepIndex.MAIN_TERMS,
                disabledSteps: [
                    StepIndex.MAIN_CONTACT,
                    StepIndex.MAIN_INCOME,
                    StepIndex.MAIN_IDENT,
                ],
            };
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(checkPreQualStatus.pending, (state) => {
                state.modal = { _tag: ModalStateType.PREQUAL_SUBMITTING };
                state.preQualApproval = null;
            })
            .addCase(checkPreQualStatus.fulfilled, (state, action) => {
                state.modal = action.payload;
                state.preQualApproval = ModalPreQualApproved.is(state.modal)
                    ? state.modal.approvedResponse
                    : null;
            })
            .addCase(checkPreQualStatus.rejected, (state) => {
                state.modal = {
                    _tag: ModalStateType.PREQUAL_ERROR,
                    errors: {
                        detail: t`An unknown error occurred`,
                    },
                };
                state.preQualApproval = null;
            });

        builder
            .addCase(checkPreScreenStatus.pending, () => {})
            .addCase(checkPreScreenStatus.fulfilled, (state, action) => {
                const approvedResponse = action.payload.responses.find(
                    (r) => r.is_approved,
                );
                if (approvedResponse) {
                    state.modal = {
                        _tag: ModalStateType.PRESCREEN_APPROVED,
                        data: action.payload,
                        approvedResponse: approvedResponse,
                    };
                    state.preQualApproval = approvedResponse;
                }
            })
            .addCase(checkPreScreenStatus.rejected, () => {});

        builder
            .addCase(submitFullApplication.pending, (state, action) => {
                state.modal = {
                    _tag: ModalStateType.APPLY_SUBMITTING,
                    ...action.meta.arg,
                };
            })
            .addCase(submitFullApplication.fulfilled, (state, action) => {
                state.modal = action.payload;
            })
            .addCase(submitFullApplication.rejected, (state, action) => {
                state.modal = {
                    _tag: ModalStateType.APPLY_ERROR,
                    ...action.meta.arg,
                    errors: {
                        detail:
                            strings.get("FINANCING_APP_SUBMIT_ERROR") ||
                            t`An unknown error occurred`,
                    },
                };
            });

        builder
            .addCase(refreshPendingAppStatus.pending, (state, action) => {
                state.modal = {
                    ...action.meta.arg,
                    isRefreshing: true,
                };
            })
            .addCase(refreshPendingAppStatus.fulfilled, (state, action) => {
                state.modal = action.payload;
            })
            .addCase(refreshPendingAppStatus.rejected, (state, action) => {
                state.modal = {
                    _tag: ModalStateType.APPLY_DENIED,
                    appType: action.meta.arg.appType,
                    values: action.meta.arg.values,
                    result: null,
                };
            });
    },
});

export const {
    openPreQualModal,
    clearPreScreenOffer,
    openApplyModal,
    closeModal,
    updateFullAppFormState,
    fullAppNextStep,
    fullAppPrevStep,
    nextBackendAcceptOffer,
} = financingSlice.actions;

export {
    checkPreQualStatus,
    checkPreScreenStatus,
    submitFullApplication,
    refreshPendingAppStatus,
};

export default financingSlice.reducer;
