import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

onDOMContentLoaded.on(() => {
    if (!document.getElementById("utility-page")) {
        return;
    }
    const promotionBlock = document.querySelector(".promotion-block");

    if (promotionBlock) {
        //Opens chat from promotion block
        const promo_callout =
            document.querySelector<HTMLElement>(".promo_callout_RTF");
        if (promo_callout) {
            promo_callout.addEventListener("click", function (e) {
                e.preventDefault();
                const salesIQ: HTMLElement = document.querySelector(
                    "[data-id=zsalesiq]",
                ) as HTMLElement;
                if (salesIQ) {
                    salesIQ.click();
                }
            });
        }
    }
});
