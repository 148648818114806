import React from "react";
import { t } from "ttag";
import { GridItem, SortType } from "../interfaces";
import { SearchField } from "./SearchField";
import { SortDropdown } from "./SortDropdown";
import { Grid } from "./Grid";

interface Props {
    filterBy?: number | null;
    baseApiUrl: string;
}

interface State {
    gridData: GridItem[];
    sortType: SortType;
    nextUrl: string | null;
    appendItems: boolean;
}

export class TempurLoveGrid extends React.Component<Props, State> {
    state: State = {
        gridData: [],
        sortType: "-date",
        nextUrl: null,
        appendItems: false,
    };

    componentDidMount() {
        this.updateGrid();
    }

    componentDidUpdate(_prevProps: Props, prevState: State) {
        if (prevState.sortType !== this.state.sortType) {
            this.updateGrid();
        }
    }

    private readonly updateGrid = async (searchString?: string) => {
        const apiURL = new URL(this.props.baseApiUrl);
        apiURL.searchParams.set("ordering", this.state.sortType);

        if (this.props.filterBy) {
            apiURL.searchParams.set("category", `${this.props.filterBy}`);
        }
        if (searchString) {
            apiURL.searchParams.set("search", searchString);
        }

        const response = await fetch(apiURL.toString());
        const data = await response.json();
        this.setState({
            gridData: data.results,
            nextUrl: data.next,
            appendItems: false,
        });
    };

    private readonly loadMore = async () => {
        // The button that calls this should never be shown unless this exists, so this if statement is here mostly to satisfy typescript
        if (this.state.nextUrl) {
            const response = await fetch(this.state.nextUrl);
            const data = await response.json();
            this.setState((state) => ({
                gridData: [...state.gridData, ...data.results],
                nextUrl: data.next,
                appendItems: true,
            }));
        }
    };

    render() {
        return (
            <>
                <div className="l-full-width">
                    <div className="l-capped-width">
                        <article className="search">
                            <SearchField onSearch={this.updateGrid} />
                            <SortDropdown
                                onChange={(e) => {
                                    this.setState({
                                        sortType: e.target.value as SortType,
                                    });
                                }}
                                value={this.state.sortType}
                            />
                        </article>
                    </div>
                </div>
                {this.state.gridData.length > 0 && (
                    <Grid
                        data={this.state.gridData}
                        appendItems={this.state.appendItems}
                    />
                )}
                {this.state.nextUrl && (
                    <button
                        className="button button--full-width button--alabaster load-more-posts al-tempur-love--load-more-posts"
                        onClick={this.loadMore}
                    >
                        {t`Load More Posts`}
                    </button>
                )}
            </>
        );
    }
}
