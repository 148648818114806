import React from "react";
import { t } from "ttag";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import { IHistogramEntry } from "../../../models/reviews.interfaces";
import { formatNumber, formatPercentage } from "../../../utils/format";

import styles from "./RatingsModalRow.module.scss";

interface IProps {
    totalReviewCount: number;
    entry: IHistogramEntry;
    onClickRating: () => void;
}

export const RatingsModalRow = (props: IProps) => {
    const percentage = Math.round(
        (props.entry.count / props.totalReviewCount) * 100,
    );
    const barStyle = {
        width: `${percentage}%`,
    };
    const formattedPercentage = formatPercentage(percentage);
    return (
        <button
            role="button"
            key={props.entry.rating}
            className={styles.root}
            onClick={props.onClickRating}
            tabIndex={0}
        >
            <span className={styles.numberStar}>
                {interpolate(t`%(rating)s star`, {
                    rating: formatNumber(props.entry.rating),
                })}
            </span>
            <figure className={styles.graphBar}>
                <div
                    className={styles.innerGraphBar}
                    aria-label={interpolate(t`%(percentage)s width graph bar`, {
                        percentage: formattedPercentage,
                    })}
                    style={barStyle}
                />
            </figure>
            <span className={styles.numberReviews}>{formattedPercentage}</span>
        </button>
    );
};
