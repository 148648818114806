import React from "react";
import { IStringField } from "../models/formFields.interfaces";
import { isMobile } from "../utils/detectMobile";
import { FormInput } from "./FormInput";

export const FormCreditCardNumberInput = <T extends string>(
    props: IStringField<T>,
) => {
    const newProps: IStringField<T> = {
        type: "text",
        validation: ["required", "credit-card"],
        autoComplete: "cc-number",
        formatOptions: {
            type: "credit-card",
        },
        maxLength: 24,
        ...props,
    };
    // Due to issues with Cleave.js on Android, disable number grouping there.
    if (isMobile.Android()) {
        delete newProps.formatOptions;
        if (newProps.value) {
            newProps.value = newProps.value.replace(/\s+/g, "");
        }
    }
    return <FormInput {...newProps} />;
};
