import React from "react";
import { strings } from "../../../localization";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { BreakPoint } from "../../../models/screen.interfaces";
import { getPageSetting } from "tsi-common-react/src/utils/settings";

interface FinePrintProps {
    showFinePrint?: boolean;
}

export const FinePrint = ({ showFinePrint }: FinePrintProps) => {
    const content = strings.get("FINANCING_CHECKOUT_FINE_PRINT") || "";
    const mobileContent =
        strings.get("FINANCING_CHECKOUT_FINE_PRINT_MOBILE") || "";

    const isMobileWidth = () => {
        const currentBreakpoint = getViewportBreakpoint();
        return currentBreakpoint < BreakPoint.MEDIUM;
    };

    // This ensures that internal links within the terms & conditions legal language
    // in checkout open in new tabs, as per the TSI's request.
    const addTargetBlankToLinks = (htmlContent: string) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlContent;

        const links = tempDiv.querySelectorAll("a");
        links.forEach((link) => {
            link.setAttribute("target", "_blank");
            link.setAttribute("rel", "noopener noreferrer");
        });

        return tempDiv.innerHTML;
    };

    const rawFinancingHtml =
        mobileContent.length > 0
            ? isMobileWidth()
                ? mobileContent
                : content
            : content;
    const processedFinancingHtml = addTargetBlankToLinks(rawFinancingHtml);

    const rawGenericHtml = getPageSetting("checkout-generic-fine-print") || "";
    const processedGenericHtml = addTargetBlankToLinks(rawGenericHtml);

    return (
        <div className="financing-form__fine-print">
            <div dangerouslySetInnerHTML={{ __html: processedGenericHtml }} />
            {showFinePrint && (
                <div
                    dangerouslySetInnerHTML={{ __html: processedFinancingHtml }}
                />
            )}
        </div>
    );
};
