import React from "react";
import { t } from "ttag";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";

interface IProps {
    isMultiReview?: boolean;
    brand?: string;
}

interface IState {}

export class WriteReviewFormDescriptionCopy extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        return (
            <div className="wrf-rating__description-copy">
                {interpolate(
                    t`Your opinion is important to us and helps other shoppers make the best choice for their needs. Thank you for sharing your %s experience.`,
                    [this.props.brand || "Tempur-Pedic"],
                )}
                {this.props.isMultiReview && (
                    <p>
                        {t`Uncheck any products you don't wish to review at this time.`}
                    </p>
                )}
            </div>
        );
    }
}
