import React from "react";
import { Key } from "ts-key-enum";
import { GridSorterConfig } from "../sorters";
import styles from "./GridSort.module.scss";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { BreakPoint } from "../../../models/screen.interfaces";

export enum GridFilterVariants {
    ACCORDION = "accordion",
    DROPDOWN = "dropdown",
}

interface IProps {
    sorters: GridSorterConfig[];
    sortMethod: string | null;
    onChangeSortMethod: (sortMethod: string) => void;
}

interface IState {
    isOpen: boolean;
}

export class GridSort extends React.PureComponent<IProps, IState> {
    state: IState = {
        isOpen: true,
    };

    componentDidMount(): void {
        const currentBreakpoint = getViewportBreakpoint();
        this.setState({
            isOpen: currentBreakpoint > BreakPoint.MEDIUM,
        });
    }

    private readonly onTitleEvent = (
        event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        this.setState((state) => ({
            isOpen: !state.isOpen,
        }));
    };

    private readonly onKeyPressHandler = (
        event: React.KeyboardEvent<HTMLElement>,
    ) => {
        if (event.key === Key.Enter) {
            this.onTitleEvent(event);
        }
    };

    render() {
        return (
            <fieldset className={styles.sortContainer}>
                <legend
                    onClick={this.onTitleEvent}
                    role="button"
                    tabIndex={0}
                    onKeyPress={this.onKeyPressHandler}
                    aria-expanded={this.state.isOpen}
                    className={styles.header}
                >
                    Sort By
                </legend>
                {this.props.sorters && this.state.isOpen && (
                    <select
                        id="sorter"
                        value={this.props.sortMethod || ""}
                        onChange={(e) => {
                            this.props.onChangeSortMethod(
                                e.currentTarget.value,
                            );
                        }}
                        className={styles.sortBody}
                    >
                        {this.props.sorters.map((sorter) => (
                            <option key={sorter.id} value={sorter.id}>
                                {sorter.label}
                            </option>
                        ))}
                    </select>
                )}
            </fieldset>
        );
    }
}
