import React from "react";
import { t } from "ttag";
import { IUser } from "../../../models/user.interfaces";

interface IProps {
    user: IUser | null;
}

export const CSRHeader = (props: IProps) => {
    if (!props.user || !props.user.is_csr) {
        return null;
    }
    const name = props.user.first_name || props.user.email;
    return (
        <p>
            {t`Welcome, ${name}. Please complete the checkout form with the information of the customer you are assisting.`}
        </p>
    );
};
