export const detectTileVisibility = (
    tilesNumber: number,
    index: number,
    selectedTilesGroupRange: number,
    selectedTileIndex: number,
) => {
    let isVisible: boolean;
    if (selectedTilesGroupRange > tilesNumber - 1) {
        const range = selectedTilesGroupRange - tilesNumber;
        if (index >= range) {
            isVisible = index >= selectedTileIndex ? true : index <= range;
        } else {
            isVisible = true;
        }
    } else {
        isVisible =
            index >= selectedTileIndex && index <= selectedTilesGroupRange;
    }
    return isVisible;
};

export const setAriaHiddenAttribute = (
    tileClass: string,
    hiddenTileClass: string,
) => {
    const thumbSlide = document.querySelectorAll(`.${tileClass}`);
    if (thumbSlide) {
        Array.from(thumbSlide).forEach((title) => {
            if (title.classList.contains(hiddenTileClass)) {
                title.setAttribute("aria-hidden", "true");
            } else {
                title.removeAttribute("aria-hidden");
            }
        });
    }
};

export const removeTabindex = (container: string) => {
    const thumbCarousel = document.querySelector(`.${container}`);
    if (thumbCarousel) {
        if (thumbCarousel.hasAttribute("tabindex")) {
            thumbCarousel.removeAttribute("tabindex");
        }
    }
};
