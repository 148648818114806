import React from "react";
import { ICountdownTimer } from "../../../models/countdown-timer.interfaces";
import { CountdownTimer as Component } from "../component/CountdownTimer";

interface IProps {
    timerData: ICountdownTimer[];
    timersExpiredPromo: string;
}

interface IState {
    timers: ICountdownTimer[];
    currentTimer: ICountdownTimer | null;
}

export class CountdownTimer extends React.Component<IProps, IState> {
    state: IState = {
        timers: [],
        currentTimer: null,
    };

    componentDidMount() {
        this.setState(
            {
                timers: this.props.timerData,
            },
            () => {
                this.getCurrentTimer();
            },
        );
    }

    private getCurrentTimer() {
        if (this.state.timers.length === 0) {
            this.setState({
                currentTimer: null,
            });
            return;
        }

        const now = new Date().getTime();
        const testStart = Date.parse(this.state.timers[0].start_date);
        const testEnd = Date.parse(this.state.timers[0].end_date);
        if (testStart <= now && testEnd >= now) {
            this.setState({
                currentTimer: this.state.timers[0],
            });
        } else {
            this.setState(
                {
                    timers: this.state.timers.slice(1),
                },
                () => {
                    this.getCurrentTimer();
                },
            );
        }
    }

    private renderCountdownTimer() {
        if (!this.state.currentTimer) {
            if (this.props.timersExpiredPromo === "") {
                return null;
            }

            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: this.props.timersExpiredPromo,
                    }}
                ></div>
            );
        }

        return (
            <Component
                currentTimer={this.state.currentTimer}
                onTimerExpired={this.getCurrentTimer.bind(this)}
            />
        );
    }

    render() {
        return <div>{this.renderCountdownTimer()}</div>;
    }
}
