import React from "react";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { renderErrorMessageTemplate } from "../utils";

interface IOwnProps {
    tags: string;
    message: string;
    encodedBasketID?: string;
    orderID?: string;
}

interface IReduxProps {}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class MessageBannerComponent extends React.Component<IProps, IState> {
    private buildFormattedMessage(tags: string, message: string) {
        tags = tags || "error";
        return (
            <li className={`messages__item ${tags}`}>
                <div className="messages__item__content l-capped-width">
                    <p>
                        <span
                            dangerouslySetInnerHTML={{ __html: message }}
                        ></span>
                    </p>
                </div>
            </li>
        );
    }

    render() {
        const msg = renderErrorMessageTemplate(this.props.message, {
            basketID: this.props.encodedBasketID,
            orderID: this.props.orderID,
        });
        return (
            <aside
                className="messages l-full-width"
                style={msg ? {} : { display: "none" }}
            >
                <ul className="messages__list">
                    {this.buildFormattedMessage(this.props.tags, msg)}
                </ul>
            </aside>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout || defaults;
    const payment_error = state.data.payment_error;
    const message = ownProps.message ? ownProps.message : payment_error;
    const encodedBasketID = state.data.basket
        ? state.data.basket.encoded_basket_id
        : "";
    const orderID = state.data.order ? state.data.order.number : "";
    return {
        ...ownProps,
        message: message,
        encodedBasketID: encodedBasketID,
        orderID: orderID,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = () => {
    return {};
};

export const MessageBanner = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MessageBannerComponent);
