import React from "react";
import { connect } from "react-redux";
import {
    getProductsByUUIDs,
    getWriteReviewTemplate,
} from "../../../api/reviews";
import { UGCListSubBlock } from "../../../models/ugc";
import { IProductUUID, IFormUUID } from "../../../models/nominals";
import {
    ISearchReviewArgs,
    IReviewsProductVariant,
    IReviewsProduct,
} from "../../../models/reviews.interfaces";
import { notEmpty, unique } from "../../../utils/functional";
import { serializeFacetValues } from "../../../utils/reviews";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { defaultState } from "../defaults";
import { WriteReviewFormMode } from "../constants";
import { getWriteReviewFormSelectedProduct } from "../selectors";
import { ProductReviews as ProductReviewsComponent } from "../components/ProductReviews";
import {
    IReviewsAppOwnProps,
    IReviewsAppReduxProps,
    IReviewsAppDispatchProps,
    ReviewsAppContainer,
} from "./_base";
import { ReviewsComponentTypes } from "../constants";

interface IOwnProps extends IReviewsAppOwnProps {
    title: string;
    productUUIDs: IProductUUID[];
    numReviews: number;
    averageRating: number;
    showWriteReviewButton: boolean;
    writeReviewFormUUID: IFormUUID;
    ugcCarouselDesc?: string;
    ugcCarouselPosts?: UGCListSubBlock[];
    brand?: string;
    showProductsFilter?: boolean;
    showSourcesFilter?: boolean;
    reviewsComponentType?: ReviewsComponentTypes;
    isCollapsedView?: boolean;
}

interface IReduxProps extends IReviewsAppReduxProps {
    writeReviewSelectedProduct: IReviewsProduct | null;
}

interface IDispatchProps extends IReviewsAppDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

class ProductReviewsContainer extends ReviewsAppContainer<IProps> {
    static defaultProps: Partial<IProps> = {
        showWriteReviewButton: true,
        pageSize: 6,
    };

    componentDidMount() {
        super.componentDidMount();
        this.props.dispatchers.registerNewWriteReviewForm(
            this.props.writeReviewFormUUID,
            WriteReviewFormMode.CLOSED,
        );
    }

    protected buildReviewSearchQuery(page: number): ISearchReviewArgs {
        const query = super.buildReviewSearchQuery(page);
        const { productTypeIDs, productIDs, variantIDs } =
            this.getDefaultFilterOptions(this.props.products);
        if (!query.product_type_id) {
            query.product_type_id = serializeFacetValues(productTypeIDs);
        }
        if (!query.product_id && !query.product_variant_id) {
            query.product_id = serializeFacetValues(productIDs);
            query.product_variant_id = serializeFacetValues(variantIDs);
        }
        return query;
    }

    protected async listProducts() {
        // Load product for the given UUID
        const products = await getProductsByUUIDs(this.props.productUUIDs);
        this.props.dispatchers.setProducts(products);
        // Load Review Template for first product
        if (products.length > 0) {
            const template = await getWriteReviewTemplate(products[0].id);
            this.props.dispatchers.setReviewTemplate(
                this.props.writeReviewFormUUID,
                products[0].id,
                template,
            );
        }
        // Set default product type selections
        const { productTypeIDs, productIDs, variantIDs } =
            this.getDefaultFilterOptions(products);
        this.props.dispatchers.setProductTypes(productTypeIDs);
        // Set default filter selections
        this.props.dispatchers.setFacetValues({
            ...this.props.facetValues,
            product_id: serializeFacetValues(productIDs),
            product_variant_id: serializeFacetValues(variantIDs),
        });
    }

    private getDefaultFilterOptions(products: IReviewsProduct[]) {
        // Select all the products types
        const productTypeIDs = products
            .map((p) => {
                return p.product_type;
            })
            .filter(notEmpty)
            .filter(unique);
        // Select all the products
        const productIDs = products
            .map((p) => p.id)
            .filter(notEmpty)
            .filter(unique);
        // Select all the product variants
        const variantIDs = products
            .reduce<IReviewsProductVariant[]>(
                (memo, p) => memo.concat(p.variants),
                [],
            )
            .map((v) => v.id)
            .filter(notEmpty)
            .filter(unique);
        return {
            productTypeIDs,
            productIDs,
            variantIDs,
        };
    }

    protected buildContent() {
        const writeReviewFormTitle = this.props.writeReviewSelectedProduct
            ? `Write a Review for ${this.props.writeReviewSelectedProduct.name}`
            : `Write a Review`;
        return (
            <ProductReviewsComponent
                title={this.props.title}
                numReviews={this.props.numReviews}
                averageRating={this.props.averageRating}
                showWriteReviewButton={!!this.props.showWriteReviewButton}
                displayedProductTypeIDWhitelist={[]}
                onLoadMore={this.onLoadMore}
                ugcCarouselDesc={this.props.ugcCarouselDesc || ""}
                ugcCarouselPosts={this.props.ugcCarouselPosts || []}
                brand={this.props.brand || "Tempur-Pedic"}
                starHasStroke={this.props.starHasStroke}
                showProductsFilter={!!this.props.showProductsFilter}
                showSourcesFilter={!!this.props.showSourcesFilter}
                writeReviewFormUUID={this.props.writeReviewFormUUID}
                writeReviewFormTitle={writeReviewFormTitle}
                reviewsComponentType={this.props.reviewsComponentType}
                isCollapsedView={this.props.isCollapsedView}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        // Direct Props
        ...ownProps,

        // Custom Redux Data
        writeReviewSelectedProduct: getWriteReviewFormSelectedProduct(state, {
            formUUID: ownProps.writeReviewFormUUID,
        }),

        // Base Redux Data
        loadedInitialProducts: state.data.loadedInitialProducts,
        loadedInitialReviews: state.data.loadedInitialReviews,
        products: state.data.products,
        productTypeIDs: state.ui.productTypeIDs,
        selectedSortOption: state.ui.selectedSortOption,
        reviews: state.data.reviews,
        facets: state.data.facets,
        facetValues: state.ui.facetValues,
        page: state.ui.page,
        hasMore: state.ui.hasMore,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const ProductReviews = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductReviewsContainer);
