import { Dispatch, Action as AnyAction } from "@reduxjs/toolkit";
import { IRetailStoreWithDistance } from "../../models/location.interfaces";
import { ISyncStoreID } from "../../models/nominals";
import { IStoreSearchFilters } from "./models.interfaces";
import {
    Action,
    IActionClearStores,
    IActionAddStores,
    IActionSetStores,
    IActionUpdateStoreFilters,
    IActionSetFocusedStore,
} from "./reducers.interfaces";

export class Dispatchers {
    dispatch: Dispatch<AnyAction>;

    constructor(dispatch: Dispatch<AnyAction>) {
        this.dispatch = dispatch;
    }

    public readonly clearStores = () => {
        this.dispatch<IActionClearStores>({
            type: Action.CLEAR_STORES,
        });
    };

    public readonly addStores = (stores: IRetailStoreWithDistance[]) => {
        this.dispatch<IActionAddStores>({
            type: Action.ADD_STORES,
            payload: stores,
        });
    };

    public readonly setStores = (
        loadKey: string,
        stores: IRetailStoreWithDistance[],
    ) => {
        this.dispatch<IActionSetStores>({
            type: Action.SET_STORES,
            payload: {
                loadKey: loadKey,
                stores: stores,
            },
        });
    };

    public readonly updateStoreFilters = (
        filters: Partial<IStoreSearchFilters>,
    ) => {
        this.dispatch<IActionUpdateStoreFilters>({
            type: Action.UPDATE_STORE_FILTERS,
            payload: filters,
        });
    };

    public readonly setFocusedStore = (externalID: ISyncStoreID | null) => {
        this.dispatch<IActionSetFocusedStore>({
            type: Action.SET_FOCUSED_STORE,
            payload: {
                externalID: externalID,
            },
        });
    };
}
