import { IOptionCode } from "../../models/catalogue.interfaces";
import { guardReducer } from "../../utils/redux";
import { setOptionValue } from "../reducer-utils";
import {
    Action,
    IFilterState,
    IReduxState,
    IAction,
} from "./reducers.interfaces";
import { defaults } from "./defaults";
import { pushFilterStateChange } from "./history";
import { getInitialOptionValues } from "./utils";

const getFilterState = (
    filters: IReduxState["filters"],
    filterID: string,
): IFilterState => {
    return (
        filters[filterID] || {
            selectedValues: [],
            isOpen: true,
        }
    );
};

const mutateFilterState = (
    filters: IReduxState["filters"],
    filterID: string,
    mutateFn: (filter: IFilterState) => IFilterState,
) => {
    const clonedFilters = {
        ...filters,
    };
    const filterState = getFilterState(clonedFilters, filterID);
    clonedFilters[filterID] = mutateFn(filterState);
    return clonedFilters;
};

export const reducers = guardReducer(
    Action,
    defaults,
    (state = defaults, action: IAction): IReduxState => {
        const optionValues = {
            ...state.optionValues,
        };

        switch (action.type) {
            case Action.PRODUCTS_LOADING_STARTED:
                return {
                    ...state,
                    isLoading: true,
                    products: [],
                };

            case Action.PRODUCTS_LOADING_DONE:
                return {
                    ...state,
                    isLoading: false,
                    products: action.payload.filter((p) => {
                        // Make sure at least one variant is still in stock
                        const availableParent =
                            p.availability.is_available_to_buy;
                        const availableChild = p.children.find(
                            (c) => c.availability.is_available_to_buy,
                        );
                        return availableParent || availableChild;
                    }),
                };

            case Action.APPLY_HISTORY_UPDATE:
                return {
                    ...state,
                    filters: action.payload,
                };

            case Action.UPDATE_SELECT_ONE_FILTER_VALUE:
                if (action.payload.optionID) {
                    optionValues[action.payload.filterID as IOptionCode] =
                        action.payload.optionID;
                } else {
                    delete optionValues[action.payload.filterID as IOptionCode];
                }
                state = {
                    ...state,
                    filters: mutateFilterState(
                        state.filters,
                        action.payload.filterID,
                        (filter) => {
                            return {
                                ...filter,
                                selectedValues: action.payload.optionID
                                    ? [action.payload.optionID]
                                    : [],
                            };
                        },
                    ),
                    optionValues: optionValues,
                };
                pushFilterStateChange(state.filters);
                return state;

            case Action.UPDATE_SELECT_MANY_FILTER_VALUE:
                if (action.payload.isSelected) {
                    optionValues[action.payload.filterID as IOptionCode] =
                        action.payload.optionID;
                }
                state = {
                    ...state,
                    filters: mutateFilterState(
                        state.filters,
                        action.payload.filterID,
                        (filter) => {
                            const selectedValues = new Set(
                                filter.selectedValues,
                            );
                            if (action.payload.isSelected) {
                                selectedValues.add(action.payload.optionID);
                            } else {
                                selectedValues.delete(action.payload.optionID);
                            }
                            return {
                                ...filter,
                                selectedValues: [...selectedValues].sort(),
                            };
                        },
                    ),
                    optionValues: optionValues,
                };
                pushFilterStateChange(state.filters);
                return state;

            case Action.UPDATE_FILTER_VISIBILITY:
                return {
                    ...state,
                    filters: mutateFilterState(
                        state.filters,
                        action.payload.filterID,
                        (filter) => {
                            return {
                                ...filter,
                                isOpen: action.payload.isOpen,
                            };
                        },
                    ),
                };

            case Action.UPDATE_SORT_METHOD:
                return {
                    ...state,
                    sortMethod: action.payload,
                };

            case Action.FOCUS_PRODUCT:
                const rootProduct = state.products.find(
                    (p) => p.id === action.payload,
                );
                return {
                    ...state,
                    focusedProductID: action.payload,
                    optionValues: rootProduct
                        ? getInitialOptionValues(rootProduct)
                        : state.optionValues,
                };

            case Action.BLUR_PRODUCT:
                return {
                    ...state,
                    focusedProductID:
                        action.payload === state.focusedProductID
                            ? null
                            : state.focusedProductID,
                };

            case Action.SET_OPTION_VALUE:
                return {
                    ...state,
                    optionValues: setOptionValue(
                        state.optionValues,
                        action.payload,
                    ),
                };

            case Action.SET_QUANTITY:
                return {
                    ...state,
                    quantity: action.payload,
                };
        }
        return state;
    },
);
