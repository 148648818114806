export enum Action {
    LOADED_PRODUCTS = "Configurator/LOADED_PRODUCTS",
    LOADED_PRODUCT_CLASS = "Configurator/LOADED_PRODUCT_CLASS",
    LOADED_PRICE = "Configurator/LOADED_PRICE",
    LOADED_FINANCING_PLANS = "Configurator/LOADED_FINANCING_PLANS",
    LOADED_CONCRETE_BUNDLES = "Configurator/LOADED_CONCRETE_BUNDLES",
    LOADED_SHIPPING_METHOD = "Configurator/LOADED_SHIPPING_METHOD",

    SET_VARIANT_PREFILTER = "Configurator/SET_VARIANT_PREFILTER",
    SET_SELECTED_ROOT_PRODUCT = "Configurator/SET_SELECTED_ROOT_PRODUCT",
    SET_SELECTED_CATEGORY = "Configurator/SET_SELECTED_CATEGORY",
    SET_SELECTED_VARIANT = "Configurator/SET_SELECTED_VARIANT",
    SET_OPTION_VALUE = "Configurator/SET_OPTION_VALUE",
    SET_QUANTITY = "Configurator/SET_QUANTITY",

    UNDO_SELECTION = "Configurator/UNDO_SELECTION",

    SET_UPGRADES_VISIBLE = "Configurator/SET_UPGRADES_VISIBLE",
    SET_ADDONS_VISIBLE = "Configurator/SET_ADDONS_VISIBLE",
    SET_SELECTED_UPGRADE = "Configurator/SET_SELECTED_UPGRADE",
    UPDATE_SELECTED_ADDONS = "Configurator/UPDATE_SELECTED_ADDONS",

    SHOW_UPSELL_INFO_MODAL = "Configurator/SHOW_UPSELL_INFO_MODAL",
    HIDE_UPSELL_INFO_MODAL = "Configurator/HIDE_UPSELL_INFO_MODAL",

    SET_ADD_TO_BASKET_BTN_STATE = "Configurator/SET_ADD_TO_BASKET_BTN_STATE",

    SET_ADD_TO_FAVORITES_BTN_STATE = "Configurator/SET_ADD_TO_FAVORITES_BTN_STATE",

    SET_ADD_TO_BASKET_ERROR_MODAL_STATE = "Configurator/SET_ADD_TO_BASKET_ERROR_MODAL_STATE",

    SET_RATINGS_MODAL_STATE = "Configurator/SET_RATINGS_MODAL_STATE",

    SET_OPTION_SELECTION_ERROR_STATE = "Configurator/SET_OPTION_SELECTION_ERROR_STATE",

    SET_PANEL_TRIGGER_STATE = "Configurator/SET_PANEL_TRIGGER_STATE",
}

export const LIST_FAVORITES = "favorites";
export const VIEW_FAVORITES = "View favorites";
export const SAVED_FAVORITES = "Saved!";

export enum FreeDeliveryButtonTypes {
    TEXT = "text",
    ICON = "icon",
}

export enum DiscountDisplayMode {
    DISABLED = "disabled",
    AUTOMATIC = "automatic",
    SHOW_PERCENTAGE = "show-percentage",
    SHOW_ABSOLUTE = "show-absolute",
}

export enum RootProductSelectorInputType {
    DROPDOWN = "dropdown",
    RADIO = "radio",
}

export const SIZES = [
    "size-ca-king",
    "size-double",
    "size-full",
    "size-king",
    "size-queen",
    "size-split-ca-king",
    "size-split-king",
    "size-twin",
    "size-twin-long",
];

export const FEELS = [
    "feel-soft",
    "feel-medium",
    "feel-medium-hybrid",
    "feel-firm",
];

export const MOBILE_WIDTH_THRESHOLD_ALTERNATIVE = 1200;
export const MOBILE_WIDTH_BODY_SCROLL = 768;
/**
 * Corresponds to the _Discontinued_ out of stock reason which can be configured
 * for product stock records. This is returned as the product availability code
 * in the product API.
 */
export const DISCONTINUED = "discontinued";
