import React from "react";
import { t } from "ttag";
import { IPaymentMethodFormProps } from "./PaymentMethod.interfaces";

interface IProps extends IPaymentMethodFormProps {}

interface IState {}

export class PayLater extends React.Component<IProps, IState> {
    render() {
        return (
            <div className="form__error">
                <p>
                    <strong>
                        {t`Our payment processor is currently experiencing technical issues. You may submit your order now and our team will contact you to confirm payment as soon as our credit card payment system is operational.`}
                    </strong>
                    <br />
                    <br />
                    <strong>
                        {t`Our team will call you at the phone number provided in checkout and use your order number to verify your information.`}
                    </strong>
                    <br />
                    <br />
                    <strong>
                        {t`Alternatively you may try placing your order again later. We sincerely apologize for any inconvenience.`}
                    </strong>
                </p>
            </div>
        );
    }
}
