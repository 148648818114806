import * as t from "io-ts";
import { nullable } from "tsi-common-react/src/models/utils";
import { ProductID } from "tsi-common-react/src/models/nominals";
import {
    BooleanBlock,
    BundleGroupChooserSubBlock,
    CharBlock,
    ImageChooserBlock,
    ListBlock,
    SnippetChooserBlock,
    StructBlock,
} from "tsi-common-react/src/models/streamfield-blocks";
import { Link as LinkSnippet } from "tsi-common-react/src/models/streamfield-snippets";

/**
 * See streamfield block: sleepoutfitters/cms.pages.pdp.PDPUpsellBlock
 */
export const PDPUpsellBlockSlide = StructBlock(
    {
        image: ImageChooserBlock,
    },
    {
        slide_bundle: BundleGroupChooserSubBlock,
        slide_cta: CharBlock,
        slide_link: nullable(SnippetChooserBlock(LinkSnippet)),
        initial_mobile_slide: BooleanBlock,
    },
);
export interface IPDPUpsellBlockSlide
    extends t.TypeOf<typeof PDPUpsellBlockSlide> {}

/**
 * See streamfield block: sleepoutfitters/cms.pages.pdp.PDPUpsellBlock
 */
export const PDPUpsellBlock = StructBlock(
    {},
    {
        header_title: CharBlock,
        header_copy: CharBlock,
        header_title_mobile: CharBlock,
        header_copy_mobile: CharBlock,
        slides: ListBlock(PDPUpsellBlockSlide),
        cta_copy: CharBlock,
    },
);
export interface IPDPUpsellBlock extends t.TypeOf<typeof PDPUpsellBlock> {}

/**
 * See Wagtail Page: sleepoutfitters/cms.pages.pdp.AbstractProductPage
 */
export const ProductPage = t.interface({
    products: t.array(ProductID),
    title: t.string,
    description: nullable(t.string),
    modules: t.string,
    bundle_modal_serialized: t.array(PDPUpsellBlock),
});
export interface IProductPage extends t.TypeOf<typeof ProductPage> {}
