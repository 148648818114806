import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import {
    Action,
    IReduxState,
    IActionUpdateSortMethod,
    IActionUpdateSelectManyFilterValue,
} from "../reducers.interfaces";
import { defaults } from "../defaults";
import { IProduct } from "../../../models/catalogue.interfaces";
import { GridFilterConfig } from "../filters";
import { GridSorterConfig } from "../sorters";
import { SideNav } from "../elements/SideNav";
import { BreakPoint } from "../../../models/screen.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";

import styles from "./TopNav.module.scss";

interface IOwnProps {
    gridVariant: string;
    filters: GridFilterConfig[];
    sorters?: GridSorterConfig[];
    hideSort?: boolean;
}

interface IReduxProps {
    products: IProduct[];
    filterState: IReduxState["filters"];
    sortMethod: string | null;
}

interface IDispatchProps {
    onButtonClick: (
        optionNamespace: string,
        filterID: string,
        optionID: string,
    ) => void;
    onChangeSortMethod: (sortMethod: string) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

export class TopNavComponent extends React.PureComponent<IProps, IState> {
    private readonly isMobileOrTabletWidth = () => {
        const currentBreakpoint = getViewportBreakpoint();
        return currentBreakpoint < BreakPoint.LARGE;
    };

    private readonly onButtonClick = (
        filterID: string,
        optionID: string,
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
        const optionNamespace =
            this.props.products[0]?.product_class_slug || "";
        this.props.onButtonClick(optionNamespace, filterID, optionID);
    };

    private readonly onButtonClear = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
        const optionNamespace =
            this.props.products[0]?.product_class_slug || "";
        for (const filterConfig of this.props.filters) {
            const options = filterConfig.listFilterOptions(this.props.products);
            const filterState = this.props.filterState[filterConfig.filterID];
            const selectedValues = new Set(
                filterState ? filterState.selectedValues : [],
            );
            for (const option of options) {
                if (selectedValues.has(option.id)) {
                    this.props.onButtonClick(
                        optionNamespace,
                        filterConfig.filterID,
                        option.id,
                    );
                }
            }
        }
    };

    // TODO: Need figure out how to handle the default sort
    // this following code is commented out so that any sort method is not applied on page load.
    // componentDidMount () {
    //     if (this.props.sorters && !this.props.sortMethod) {
    //         this.props.onChangeSortMethod(this.props.sorters[0].id);
    //     }
    // }

    private *buildButtons() {
        for (const filterConfig of this.props.filters) {
            const options = filterConfig.listFilterOptions(this.props.products);
            const filterState = this.props.filterState[filterConfig.filterID];
            const selectedValues = new Set(
                filterState ? filterState.selectedValues : [],
            );
            for (const option of options) {
                if (selectedValues.has(option.id)) {
                    yield (
                        <button
                            key={`${filterConfig.filterID}-${option.id}`}
                            className={styles.filterTag}
                            onClick={this.onButtonClick.bind(
                                this,
                                filterConfig.filterID,
                                option.id,
                            )}
                        >
                            <span className="ada-screenreader-only">
                                Remove filter
                            </span>
                            {option.label}
                        </button>
                    );
                }
            }
        }
    }

    private buildFilters() {
        const buttons = Array.from(this.buildButtons());
        return (
            <div className={styles.tagContainer}>
                {buttons}
                {buttons.length > 1 && (
                    <button
                        className={styles.clearButton}
                        onClick={this.onButtonClear.bind(this)}
                    >
                        Clear All
                    </button>
                )}
            </div>
        );
    }

    private buildFilterMenu() {
        return (
            <div className={styles.filterContainer}>
                <SideNav
                    gridVariant={this.props.gridVariant}
                    filters={this.props.filters}
                />
            </div>
        );
    }

    private buildSortMenu() {
        if (!this.props.sorters || this.props.hideSort) {
            return null;
        }

        return (
            <div className={styles.sortContainer}>
                <label htmlFor="sorter" className={styles.sortLabel}>
                    {t`Sort by:`}
                </label>
                <select
                    id="sorter"
                    className={styles.sortSelect}
                    value={this.props.sortMethod || this.props.sorters[0].id}
                    onChange={(e) => {
                        this.props.onChangeSortMethod(e.currentTarget.value);
                    }}
                >
                    {this.props.sorters.map((sorter) => (
                        <option key={sorter.id} value={sorter.id}>
                            {sorter.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }

    render() {
        if (this.isMobileOrTabletWidth()) {
            return (
                <div className={styles.container}>
                    <div className={styles.mobileWrapper}>
                        <div className={styles.mobileFilterWrapper}>
                            {this.buildFilterMenu()}
                        </div>
                        <div className={styles.mobileSortWrapper}>
                            {this.buildSortMenu()}
                        </div>
                    </div>
                    {this.buildFilters()}
                </div>
            );
        }

        return (
            <div className={styles.container}>
                {this.buildFilters()}
                {this.buildSortMenu()}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.productgrid2 || defaults;
    return {
        ...ownProps,
        products: state.products,
        filterState: state.filters,
        sortMethod: state.sortMethod,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    return {
        onButtonClick: (optionNamespace, filterID, optionID) => {
            dispatch<IActionUpdateSelectManyFilterValue>({
                type: Action.UPDATE_SELECT_MANY_FILTER_VALUE,
                payload: {
                    optionNamespace: optionNamespace,
                    filterID: filterID,
                    optionID: optionID,
                    isSelected: false,
                },
            });
        },
        onChangeSortMethod: (sortMethod: string) => {
            dispatch<IActionUpdateSortMethod>({
                type: Action.UPDATE_SORT_METHOD,
                payload: sortMethod,
            });
        },
    };
};

export const TopNav = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TopNavComponent);
