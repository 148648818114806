import React from "react";
import { Provider } from "react-redux";
import * as checkoutAPI from "tsi-common-react/src/api/checkout";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { isoWebPageURL } from "tsi-common-react/src/models/nominals";
import { Dispatchers as CheckoutDispatchers } from "tsi-common-react/src/apps/checkout/dispatchers";
import { Loaders as CheckoutLoaders } from "tsi-common-react/src/apps/checkout/loaders";
import { onReadyStateComplete } from "tsi-common-react/src/utils/events";
import { registerCommonCascades } from "tsi-common-react/src/apps/checkout/cascades-main";
import { registerCheckoutCascades } from "tsi-common-react/src/apps/checkout/cascades-checkout";
import { BasketLineVariant } from "tsi-common-react/src/apps/checkout/constants";
import { BasketMenuIcon } from "tsi-common-react/src/apps/checkout/containers/BasketMenuIcon";
import * as signals from "tsi-common-react/src/apps/signals";

import { rehydratingStore, persistor, store } from "../store";
import {
    getPageSetting,
    isPageInsideIFrame as isPageInsideIFrameCheck,
} from "tsi-common-react/src/utils/settings";
import { urls } from "tsi-common-react/src/utils/urls";
import { FinancingModalTriggerTheme } from "tsi-common-react/src/constants";
import { PreferredDeliveryDateChatLink } from "./components/PreferredDeliveryDateChatLink";
import { RichText } from "tsi-common-react/src/common/RichText";
import iconCart from "../svg/cart.svg";

// ============================================================================
// Setup Redux Store / Redux Event Dispatchers
// ============================================================================
export const dispatchers = new CheckoutDispatchers(store.dispatch);
export const loaders = new CheckoutLoaders(dispatchers);

// ============================================================================
// Render checkout app components
// ============================================================================
/* eslint-disable-next-line no-async-promise-executor */
const componentsRendering = new Promise<void>(async (resolve) => {
    // Wait for Redux store to finish loading
    await rehydratingStore;

    // Register store cascades
    registerCommonCascades(store);

    // Render basket menu icons
    dynamicPlaceComponent(
        '[data-place-react="basket-menu-icon"]',
        async (elem) => {
            const appSlugName = getPageSetting("app-slug");
            if (appSlugName === "sleepoutfittersoutlet") {
                return null;
            }
            const className = elem.dataset.extraClasses;
            const basketURL = urls.pageURL("basket-summary");
            const checkoutURL = urls.pageURL("checkout-index");
            const isPageInsideIFrame = isPageInsideIFrameCheck();
            return (
                <Provider store={store}>
                    <BasketMenuIcon
                        className={className}
                        basketURL={basketURL}
                        checkoutURL={checkoutURL}
                        isPageInsideIFrame={isPageInsideIFrame}
                        basketLineVariant={BasketLineVariant.MINIMAL_ENHANCED}
                        customIcon={iconCart}
                        showLabel={true}
                    />
                </Provider>
            );
        },
    );

    // Render Basket Application
    dynamicPlaceComponent("#basket-app", async () => {
        const { Basket } = await import(
            "tsi-common-react/src/apps/checkout/containers/Basket"
        );
        const { BasketFinePrint } = await import(
            "./components/BasketFinePrint"
        );
        return (
            <Provider store={store}>
                <Basket
                    getLineProductUpsell={() => null}
                    showValueProps={true}
                    getExtraSummaryContent={() => null}
                    getExtraSidebarContent={() => {
                        return (
                            <div className="basket-summary__sidebar-content">
                                <RichText
                                    html={getPageSetting(
                                        "basket-sidebar-extra-content",
                                    )}
                                />
                            </div>
                        );
                    }}
                    getExtraMainColumnContent={(data, isMobile) => {
                        if (isMobile) {
                            return null;
                        }
                        return <BasketFinePrint plans={data.financing_plans} />;
                    }}
                    predictedDeliveryDates={getPageSetting(
                        "predicted-delivery-date-display-type",
                    )}
                    preferredDeliveryDates={getPageSetting(
                        "preferred-delivery-date-display-type",
                    )}
                    deliveryIsFree={false}
                />
            </Provider>
        );
    });

    // Render Checkout Application
    dynamicPlaceComponent("#checkout-app", async () => {
        registerCheckoutCascades(store);
        const { Checkout } = await import(
            "tsi-common-react/src/apps/checkout/containers/Checkout"
        );
        const { CheckoutSidebarContent } = await import(
            "./components/CheckoutSidebarContent"
        );

        return (
            <Provider store={store}>
                <Checkout
                    showFinancingPreQual={false}
                    enableSplitPay={false}
                    buildPreScreenModal={() => null}
                    getExtraSummaryContent={() => null}
                    getExtraSidebarContent={() => {
                        return <CheckoutSidebarContent />;
                    }}
                    buildFinancingUpsell={() => null}
                    predictedDeliveryDates={getPageSetting(
                        "predicted-delivery-date-display-type",
                    )}
                    preferredDeliveryDates={getPageSetting(
                        "preferred-delivery-date-display-type",
                    )}
                    preferredDeliveryContent={<PreferredDeliveryDateChatLink />}
                />
            </Provider>
        );
    });

    // Render Complete Deferred Payment Application
    dynamicPlaceComponent("#complete-deferred-payment-app", async (elem) => {
        registerCheckoutCascades(store);
        const { CompleteDeferredPayment } = await import(
            "tsi-common-react/src/apps/checkout/containers/CompleteDeferredPayment"
        );
        return (
            <Provider store={store}>
                <CompleteDeferredPayment
                    initDataJSON={elem.dataset.initData || ""}
                    enableSplitPay={false}
                    buildFinancingUpsell={() => null}
                />
            </Provider>
        );
    });

    // Render Message Banner
    dynamicPlaceComponent(
        '[data-place-react="message-banner"]',
        async (elem) => {
            const { MessageBanner } = await import(
                "tsi-common-react/src/apps/checkout/containers/MessageBanner"
            );
            const tags = elem.dataset.tags || "";
            const message = elem.dataset.message || "";
            return (
                <Provider store={store}>
                    <MessageBanner tags={tags} message={message} />
                </Provider>
            );
        },
    );

    // Render Create Account Widgets
    dynamicPlaceComponent(
        '[data-place-react="create-account"]',
        async (elem) => {
            const { CreateAccount } = await import(
                "tsi-common-react/src/apps/checkout/containers/CreateAccount"
            );
            const firstName = elem.dataset.firstName || "";
            const email = elem.dataset.email || "";
            const currentUserID = elem.dataset.currentUserId;
            const orderUserID = elem.dataset.orderUserId;
            const orderUserHasPassword =
                (elem.dataset.orderUserHasPassword || "").toLowerCase() ===
                "true";
            const orderHistoryUrl = isoWebPageURL.wrap(
                elem.dataset.orderHistoryUrl || "",
            );
            const isAuthenticatedAsOrderOwner = currentUserID === orderUserID;
            return (
                <CreateAccount
                    firstName={firstName}
                    email={email}
                    isAuthenticatedAsOrderOwner={isAuthenticatedAsOrderOwner}
                    orderOwnerHasPassword={orderUserHasPassword}
                    orderHistoryURL={orderHistoryUrl}
                />
            );
        },
    );

    // Render Pre Approval Triggers
    dynamicPlaceComponent(
        '[data-place-react="financing-modal-trigger--block"]',
        async (elem) => {
            const { UnifiModalTriggerBlock } = await import(
                "./components/UnifiModalTriggerBlock"
            );
            return (
                <Provider store={store}>
                    <UnifiModalTriggerBlock
                        theme={
                            (elem.dataset
                                .theme as FinancingModalTriggerTheme) ||
                            FinancingModalTriggerTheme.DEFAULT
                        }
                        termAPR={"0"}
                        termMonths={Number(elem.dataset.termMonths)}
                        termThreshold={elem.dataset.termThreshold || ""}
                        promoPeriod={elem.dataset.promoPeriod || ""}
                        aprInfoID="#footer-legal-text"
                    />
                </Provider>
            );
        },
    );

    // Render Pre Approval Trigger
    dynamicPlaceComponent(
        '[data-place-react="financing-modal-trigger--pre-qual"]',
        async (elem) => {
            const { UnifiPreQualAd } = await import(
                "./components/UnifiPreQualAd"
            );
            return (
                <Provider store={store}>
                    <UnifiPreQualAd
                        termAPR={"0"}
                        termMonths={Number(elem.dataset.termMonths)}
                        termThreshold={elem.dataset.termThreshold || ""}
                        promoPeriod={elem.dataset.promoPeriod || ""}
                        aprInfoID="#footer-legal-text"
                    />
                </Provider>
            );
        },
    );

    // Render Cart Reference Number on Checkout Page
    dynamicPlaceComponent(
        '[data-place-react="cart-reference-number"]',
        async () => {
            const { CartReferenceNumber } = await import(
                "./components/CartReferenceNumber"
            );
            return (
                <Provider store={store}>
                    <CartReferenceNumber />
                </Provider>
            );
        },
    );

    // Render Offer images
    dynamicPlaceComponent('[data-place-react="order-offer"]', async (elem) => {
        const desktop_image = elem.dataset.desktop_image || "";
        const mobile_image = elem.dataset.mobile_image || "";
        const offer_freq = elem.dataset.offer_freq || "";
        const benefit_value = elem.dataset.benefit_value || "";
        const offer_name = elem.dataset.offer_name || "";
        const appSlugName = getPageSetting("app-slug");
        if (appSlugName !== "sleepoutfitters") {
            return null;
        }

        const { OrderOfferImage } = await import(
            "./components/OrderOfferImage"
        );
        const { OrderOffer } = await import("./components/OrderOffer");
        if (!!offer_freq && !!benefit_value) {
            return (
                <Provider store={store}>
                    <OrderOffer
                        offer_freq={offer_freq}
                        benefit_value={benefit_value}
                        desktop_image={desktop_image}
                        mobile_image={mobile_image}
                        offer_name={offer_name}
                    />
                </Provider>
            );
        }
        if (!!desktop_image && !!mobile_image) {
            return (
                <Provider store={store}>
                    <OrderOfferImage
                        desktop_image={desktop_image}
                        mobile_image={mobile_image}
                    />
                </Provider>
            );
        }
        return null;
    });

    // Resolve promise to denote that all components are now rendered
    resolve();
});

// ============================================================================
// Special On-Page-Load Functionality
// ============================================================================
const wipeCheckoutData = () => {
    console.debug("Erasing transient checkout data...");
    persistor.purge();
    checkoutAPI.clearShippingAddress();
    checkoutAPI.clearBillingAddress();
    loaders.resetFormData();
};

onReadyStateComplete.on(async () => {
    // Wait for redux store to finish loading
    await rehydratingStore;
    await componentsRendering;

    // Redirect from basket page to homepage after 60 min delay
    const basketApp = document.getElementById("basket-app");
    if (basketApp) {
        setTimeout(() => {
            urls.navigateToHome();
        }, 3600_000);
    }

    // Wipe checkout data?
    if (document.body.classList.contains("js-wipe-checkout-data")) {
        wipeCheckoutData();
    }
});

// ============================================================================
// CSR Toolbar Integration
// ============================================================================
const reloadCheckoutData = () => {
    wipeCheckoutData();
    console.log("Reloading basket data");
    loaders.loadBasket();
    console.log("Reloading shipping and billing address");
    loaders.loadShippingAndBillingAddresses();
};

signals.csr.onBeforeStartNewBasket.on(wipeCheckoutData);
signals.csr.onAfterStartAssistingCustomer.on(reloadCheckoutData);
signals.csr.onAfterStopAssistingCustomer.on(reloadCheckoutData);
