import React from "react";
import { t } from "ttag";
import { format } from "tsi-common-react/src/utils/format";

interface IProps {
    termAPR: string;
    termMonths: number;
    termThreshold: string;
    promoPeriod: string;
    aprInfoID: string;
}

export const UnifiPreQualAdHeader = (props: IProps) => {
    const termThreshold = format.money(props.termThreshold);
    const termYears = props.termMonths / 12;
    return (
        <>
            <h2 className="pre-qual-block__header">
                {t`${termYears} Years — ${props.termAPR}% Interest`}
                <sup>
                    <a href={props.aprInfoID}>1</a>
                </sup>
            </h2>
            <p>
                {t`On all purchases of ${termThreshold} or more made with your Sleep Outfitters credit card from ${props.promoPeriod}. Equal monthly payments required for ${props.termMonths} months. Promo fee of 2% of amount financed will be included in required monthly payments.¹`}
            </p>
        </>
    );
};
