import { IUser } from "../../models/user.interfaces";
import { IViewport } from "../../models/screen.interfaces";
import {
    ILocation,
    ILocationGuessResponse,
} from "../../models/location.interfaces";

export enum Action {
    UPDATE_DETECTED_LOCATION = "Common/UPDATE_DETECTED_LOCATION",
    UPDATE_ENTERED_LOCATION = "Common/UPDATE_ENTERED_LOCATION",
    SET_USER = "Common/SET_USER",
    SET_VIEWPORT = "Common/SET_VIEWPORT",
}

export interface IReduxState {
    detectedLocation: ILocationGuessResponse | null;
    enteredLocation: ILocation | null;

    user: IUser | null;

    viewport: IViewport;
}

export interface IBaseAction {
    type: Action;
}

export interface IActionUpdateDetectedLocation extends IBaseAction {
    type: Action.UPDATE_DETECTED_LOCATION;
    payload: ILocationGuessResponse;
}

export interface IActionUpdateEnteredLocation extends IBaseAction {
    type: Action.UPDATE_ENTERED_LOCATION;
    payload: ILocation;
}

export interface IActionSetUser extends IBaseAction {
    type: Action.SET_USER;
    payload: IUser;
}

export interface IActionSetViewport extends IBaseAction {
    type: Action.SET_VIEWPORT;
    payload: IViewport;
}

export type IAction =
    | IActionUpdateDetectedLocation
    | IActionUpdateEnteredLocation
    | IActionSetUser
    | IActionSetViewport;
