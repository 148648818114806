import React from "react";

interface IProps {
    errors?: string[];
}

export const FormFieldErrors = (props: IProps) => {
    if (!props.errors || props.errors.length <= 0) {
        return null;
    }
    return (
        <ul className="error-list">
            {props.errors.map((err, i) => (
                <li key={i}>{err}</li>
            ))}
        </ul>
    );
};
