import { createListenerMiddleware } from "@reduxjs/toolkit";
import { IRootState } from "../reducers.interfaces";
import { trackUserLocation } from "../../utils/analytics";
import { hasKeys } from "../../utils/guards";

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    predicate: (action, currentState, previousState) => {
        if (
            hasKeys<IRootState>(currentState, ["common"]) &&
            hasKeys<IRootState>(previousState, ["common"])
        ) {
            const previousZip = previousState.common?.detectedLocation?.zip;
            const currentZip = currentState.common?.detectedLocation?.zip;
            // Return true if the zip has changed
            return previousZip !== currentZip;
        }
        return false;
    },
    effect: (action, listenerApi) => {
        const state = listenerApi.getState();
        if (hasKeys<IRootState>(state, ["common"])) {
            const newLocation = state.common?.detectedLocation;
            // Push into dataLayer
            if (newLocation && newLocation.zip) {
                trackUserLocation(newLocation.zip);
            }
        }
    },
});
