import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { t } from "ttag";
import { formatDateString } from "../../../utils/format";
import { IReview } from "../../../models/reviews.interfaces";
import { IWebPageURL } from "../../../models/nominals";
import { getProductByUUID } from "../../../api/products";
import { RatingGraphic } from "../../../common/RatingGraphic";
import { Link } from "../../../common/Link";
import iconMinus from "../../../../img/icons/minus.svg";
import iconPlus from "../../../../img/icons/plus.svg";

interface IProps {
    review: IReview;
    showSideDate: boolean;
    enablePDPLinks: boolean;
    isMobile: boolean;
    closeAllReviewColumns: boolean;
    starHasStroke?: boolean;
    expandReviewColumn: (isExpanded: boolean) => void;
    currentReviewElement: React.RefObject<HTMLButtonElement>;
    expandContentThreshold: number;
}

interface IState {
    productLink: IWebPageURL | null;
    isExpanded: boolean;
    reviewCopyHeight: number;
}

export class CarouselReview extends React.PureComponent<IProps, IState> {
    private readonly reviewContent = React.createRef<HTMLDivElement>();
    private readonly reviewCopy = React.createRef<HTMLDivElement>();
    public state: IState = {
        productLink: null,
        isExpanded: false,
        reviewCopyHeight: 0,
    };

    private readonly clickToExpand = () => {
        this.props.expandReviewColumn(this.state.isExpanded);
        this.setState({
            isExpanded: !this.state.isExpanded,
        });
    };

    private readonly checkReviewCopyHeight = () => {
        if (this.reviewCopy.current) {
            this.setState({
                reviewCopyHeight: this.reviewCopy.current.offsetHeight,
            });
        }
    };

    async componentDidMount() {
        if (this.props.isMobile) {
            this.checkReviewCopyHeight();
            window.addEventListener("resize", this.checkReviewCopyHeight);
        }
        if (!this.props.review.product_uuid) {
            return;
        }
        const product = await getProductByUUID(this.props.review.product_uuid);
        if (product && product.link && product.show_in_site_search) {
            this.setState({
                productLink: product.link,
            });
        }
    }

    componentDidUpdate() {
        this.setState({
            isExpanded: this.props.closeAllReviewColumns
                ? false
                : this.state.isExpanded,
        });

        if (this.props.isMobile) {
            this.checkReviewCopyHeight();
        }
    }

    private buildProductTitle() {
        const title = this.props.review.product_variant_display_name
            ? `${this.props.review.product_name} – ${this.props.review.product_variant_display_name}`
            : this.props.review.product_name;
        if (this.props.enablePDPLinks && this.state.productLink) {
            return (
                <Link
                    className="customer-review__review-item__product-link"
                    href={this.state.productLink}
                >
                    {title}
                </Link>
            );
        }
        return <>{title}</>;
    }

    private buildToggleButton() {
        return (
            <button
                ref={this.props.currentReviewElement}
                onClick={this.clickToExpand}
                aria-controls="toggle-review"
                aria-expanded={this.state.isExpanded}
            >
                {this.state.isExpanded ? (
                    <span>
                        <SVG aria-hidden="true" src={iconMinus} />
                        {t`View Less`}
                    </span>
                ) : (
                    <span>
                        <SVG aria-hidden="true" src={iconPlus} />
                        {t`View More`}
                    </span>
                )}
            </button>
        );
    }

    render() {
        const displayTogglebutton =
            this.props.isMobile &&
            this.state.reviewCopyHeight >= this.props.expandContentThreshold;
        const formattedDate = formatDateString(
            this.props.review.created_datetime,
            "MMM d, yyyy",
        );
        const formattedReviewDate = this.props.showSideDate
            ? formattedDate
            : null;

        const content = this.props.review.highlighted
            ? this.props.review.highlighted
            : this.props.review.text;

        const contentClasses = classNames({
            "customer-review__review-content": true,
            "customer-review__review-content--expanded": this.state.isExpanded,
            "customer-review__review-content--no-expand-button":
                !displayTogglebutton,
        });
        const isSelected =
            this.reviewContent &&
            this.reviewContent.current?.parentElement?.parentElement?.classList.contains(
                "is-selected",
            );
        const tabIndex =
            isSelected &&
            !this.props.isMobile &&
            this.reviewCopy.current &&
            this.reviewCopy.current.offsetHeight >=
                this.props.expandContentThreshold
                ? 0
                : -1;
        return (
            <div
                className="customer-review__review-item"
                role="group"
                aria-label={t`Product Review`}
            >
                <div className="customer-review__review-content-wrapper">
                    {this.buildProductTitle()}
                    <RatingGraphic
                        cardClass="product-reviews-filter"
                        cardSize="standard"
                        rating={this.props.review.rating}
                        starHasStroke={this.props.starHasStroke}
                    />
                    <div
                        ref={this.reviewContent}
                        className={contentClasses}
                        tabIndex={tabIndex}
                    >
                        <strong>{this.props.review.headline}</strong>
                        <p
                            ref={this.reviewCopy}
                            id={displayTogglebutton ? "toggle-review" : ""}
                            dangerouslySetInnerHTML={{ __html: content }}
                        ></p>
                    </div>
                    {displayTogglebutton && this.buildToggleButton()}
                    <div className="customer-review__reivew-user-info">
                        <strong title={this.props.review.name}>
                            {this.props.review.name}
                        </strong>
                        <div
                            title={`${this.props.review.location}, ${formattedReviewDate}`}
                        >
                            <span>{this.props.review.location}</span>
                            <span>{formattedReviewDate}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
