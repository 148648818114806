import { IReduxState } from "./reducers.interfaces";
import { getFilterState } from "./history";

export const defaults: IReduxState = {
    isLoading: true,
    products: [],
    filters: getFilterState(),
    sortMethod: null,
    focusedProductID: null,
    optionValues: {},
    quantity: 1,
};
