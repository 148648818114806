import React from "react";
import classNames from "classnames";
import Modal from "react-modal";
import { t } from "ttag";
import { focusElement } from "../utils/keyboardFocus";

interface IProps {
    name: string;
    isOpen: boolean;
    onRequestClose?: (
        e: React.MouseEvent<Element> | React.KeyboardEvent<Element>,
    ) => void;
    closeButtonText?: string;
    children?: React.ReactNode;
}

interface IState {}

export class ErrorModal extends React.PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        onRequestClose: () => {},
        closeButtonText: "Close",
    };

    render() {
        const modalStyle = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                padding: "30px 30px 30px 30px",
            },
        };

        const containerClassNames = classNames({
            "error-modal__container": true,
            [`error-modal__container--${this.props.name}`]: true,
        });

        return (
            <Modal
                aria={{ modal: true }}
                contentLabel={t`Error`}
                className={containerClassNames}
                style={modalStyle}
                isOpen={this.props.isOpen}
                onAfterOpen={() => {
                    focusElement(".error-modal__button");
                }}
                onRequestClose={this.props.onRequestClose}
                role="dialog"
            >
                {this.props.children}

                <div className="error-modal__button-container">
                    <button
                        className="button button--primary-congress-blue error-modal__button"
                        onClick={this.props.onRequestClose}
                    >
                        {this.props.closeButtonText}
                    </button>
                </div>
            </Modal>
        );
    }
}

export default ErrorModal;
