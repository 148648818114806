import { IReduxState } from "./reducers.interfaces";

export const defaults: IReduxState = {
    tiles: [],
    selectedTileIDs: [],
    trayIsExpanded: true,
    trayLabel: "collapse",
    priceSelectValue: "Queen",
    showCompareModal: false,
    rootProducts: [],
};
