import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import styles from "./RootProductCompareDrawer.module.scss";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { Key } from "ts-key-enum";
import { focusElement, trapFocus } from "../../../utils/keyboardFocus";
import { MOBILE_WIDTH_THRESHOLD_ALTERNATIVE } from "../constants";
import { showFinanceBlock } from "./ConfiguratorPanelTrigger";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { ISafeHTML, isoSafeHTML } from "../../../models/nominals";
import { IProduct } from "../../../models/catalogue.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { connect } from "react-redux";
import { ProductCompareGrid__ColumnCard_Component } from "../../product-compare/components/ProductCompareGrid__ColumnCard";
import { IViewport } from "../../../models/screen.interfaces";
import { CSSTransition } from "react-transition-group";
import iconXClose from "../../../../img/icons/x-close.svg";

interface IOwnProps {
    optionSet: IModularConfiguratorOptionSet;
    prefixNameCapped: string;
    panelClass: string;
    isPanelOpen: boolean;
    onCloseButtonClick: (
        removeStaticFromBody: () => void,
        removePanelStateToBody: () => void,
        showFinanceBlock: () => void,
    ) => void;
}

interface IReduxProps {
    rootProducts: IProduct[];
    viewport: IViewport;
    priceSelectValue: string;
}

interface IState {}

interface IProps extends IOwnProps, IReduxProps {}

class RootProductCompareDrawerComponent extends React.PureComponent<
    IProps,
    IState
> {
    private optionPanel: HTMLDivElement | null = null;
    private closeButton: HTMLButtonElement | null = null;

    private readonly onEscKeyDown = (
        e: React.KeyboardEvent<HTMLDivElement>,
    ) => {
        if (e.key === Key.Escape) {
            this.props.onCloseButtonClick(
                this.removeStaticFromBody,
                this.removePanelStateToBody,
                showFinanceBlock,
            );
        }
    };

    private readonly isMobileWidth = () => {
        return window.innerWidth < MOBILE_WIDTH_THRESHOLD_ALTERNATIVE;
    };

    private readonly addStaticToBody = () => {
        document.body.classList.add("static");
    };

    private readonly removeStaticFromBody = () => {
        document.body.classList.remove("static");
    };

    private readonly addPanelStateToBody = () => {
        document.body.classList.add("configurator-panel-open");
    };

    private readonly removePanelStateToBody = () => {
        document.body.classList.remove("configurator-panel-open");
    };

    private readonly onMouseEnterPanel = () => {
        if (this.optionPanel) {
            this.addStaticToBody();
        }
    };

    private readonly onMouseLeavePanel = () => {
        if (this.optionPanel) {
            this.removeStaticFromBody();
        }
    };

    private getTiles(): ICompareTile[] {
        if (!this.props.optionSet.root_product_compare_modal) {
            return [];
        }
        const baseTiles =
            this.props.optionSet.root_product_compare_modal.products;
        // Annotate each tile with the related product data
        const tiles = baseTiles
            .map((baseTile): ICompareTile => {
                const rootProduct = this.props.rootProducts.find((rp) => {
                    return rp.id === baseTile.product_compare_tile.product_id;
                });
                return {
                    ...baseTile,
                    rootProducts: rootProduct ? [rootProduct] : [],
                };
            })
            .filter((tile) => tile.rootProducts.length > 0);
        return tiles;
    }

    private buildHeader(): ISafeHTML {
        if (!this.props.optionSet.root_product_compare_modal_title) {
            return isoSafeHTML.wrap("");
        }

        return isoSafeHTML.wrap(
            `<h3>${this.props.optionSet.root_product_compare_modal_title}</h3>`,
        );
    }

    componentDidMount() {
        // Wait for animation completed
        setTimeout(() => {
            if (this.closeButton) {
                focusElement(this.closeButton);
            }
            if (this.optionPanel) {
                trapFocus(this.optionPanel);
            }
        }, 500);

        if (this.isMobileWidth()) {
            this.addStaticToBody();
        }

        this.addPanelStateToBody();
    }

    componentWillUnmount() {
        this.removeStaticFromBody();
    }

    render() {
        if (!this.props.optionSet.root_product_compare_modal) {
            return null;
        }
        const panelClasses = classNames({
            configurator__panel: true,
            [styles.panel]: true,
            [`configurator__panel--${this.props.panelClass}`]: true,
            [`configurator__panel--compare-drawer`]: true,
        });
        const tiles = this.getTiles();
        return (
            <>
                <CSSTransition
                    in={this.props.isPanelOpen}
                    timeout={500}
                    classNames="configurator__panel-"
                    unmountOnExit={true}
                >
                    <div
                        aria-modal="true"
                        aria-label={t`${this.props.prefixNameCapped} option selection panel`}
                        className={panelClasses}
                        ref={(elem) => {
                            this.optionPanel = elem;
                        }}
                        role="dialog"
                        onKeyDown={this.onEscKeyDown}
                        onMouseEnter={this.onMouseEnterPanel}
                        onMouseLeave={this.onMouseLeavePanel}
                    >
                        <div className={styles.root}>
                            <div
                                className={`${styles.head} configurator__panel--head`}
                            >
                                {" "}
                                <h5>Compare</h5>
                                <button
                                    ref={(elem) => {
                                        this.closeButton = elem;
                                    }}
                                    aria-label={t`Close`}
                                    onClick={() =>
                                        this.props.onCloseButtonClick(
                                            this.removePanelStateToBody,
                                            this.removePanelStateToBody,
                                            showFinanceBlock,
                                        )
                                    }
                                >
                                    <SVG aria-hidden="true" src={iconXClose} />
                                </button>
                            </div>
                            {/* wrapping with the same class as the Wagtail Block Variant to ensure proper styling */}
                            <div
                                className={`${styles.grid} product-compare-grid-column-card`}
                            >
                                <ProductCompareGrid__ColumnCard_Component
                                    header={this.buildHeader()}
                                    tiles={tiles}
                                    priceSelectValue={
                                        this.props.priceSelectValue
                                    }
                                    viewport={this.props.viewport}
                                />
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </>
        );
    }
}

const mapStateToProps: TStateMapper<
    "configurator" | "productcompare",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    return {
        viewport: rootState.common.viewport,
        priceSelectValue: rootState.productcompare.priceSelectValue,
        rootProducts: rootState.configurator.entities.rootProducts,
        ...ownProps,
    };
};

export const RootProductCompareDrawer = connect(mapStateToProps)(
    RootProductCompareDrawerComponent,
);
