export const initCustomerReviewCarousel = async (elem: HTMLElement) => {
    const Flickity = (await import("flickity-imagesloaded")).default;
    const flickity = new Flickity(elem, {
        watchCSS: true,
        initialIndex: 1,
    });
    flickity.on("select", () => {
        return null;
    });
};
