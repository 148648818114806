import {
    IBasket,
    IConcreteBundle,
    IWishListLine,
    IBasketLinkShippingMethod,
} from "../../../models/catalogue.interfaces";
import { IOrderResponse } from "../../../models/checkout.interfaces";
import { IShippingMethod } from "../../../models/shipping.interfaces";
import { isoProductAPIURL } from "../../../models/nominals";
import { guardUnhandledAction } from "../../../utils/never";
import { guardReducer } from "../../../utils/redux";
import { defaults } from "../defaults";
import { Action, PredictedDeliveryDateStatus } from "../constants";
import { IReduxAPIData, IDataLoadActions } from "../reducers.interfaces";

const updateBasket = (state: IReduxAPIData, basket: IBasket) => {
    basket = { ...basket };

    const bundles: { [lineURL: string]: IConcreteBundle[] | undefined } = {};
    const shippingMethods: {
        [lineURL: string]: IBasketLinkShippingMethod[] | undefined;
    } = {};

    // Extract bundle and shipping method data from the current basket
    if (state.basket) {
        state.basket.lines.forEach((line) => {
            bundles[isoProductAPIURL.unwrap(line.product.url)] = line.bundles;
            shippingMethods[isoProductAPIURL.unwrap(line.product.url)] =
                line.shipping_methods;
        });
    }

    // Inject bundle and shipping method data back into the new basket
    basket.lines.forEach((line) => {
        line.bundles = bundles[isoProductAPIURL.unwrap(line.product.url)] || [];
        line.shipping_methods =
            shippingMethods[isoProductAPIURL.unwrap(line.product.url)] || [];
    });

    return {
        ...state,
        basket: basket,
    };
};

const updateOrder = (state: IReduxAPIData, order: IOrderResponse) => {
    order = { ...order };

    return {
        ...state,
        order: order,
    };
};

const injectBundle = (state: IReduxAPIData, bundle: IConcreteBundle) => {
    if (!state.basket) {
        return state;
    }
    return {
        ...state,
        basket: {
            ...state.basket,
            lines: state.basket.lines.map((line) => {
                if (bundle.triggering_product !== line.product.id) {
                    return line;
                }
                return {
                    ...line,
                    bundles: line.bundles
                        .filter((b) => {
                            return b.id !== bundle.id;
                        })
                        .concat([bundle]),
                };
            }),
        },
    };
};

const injectShippingMethods = (
    state: IReduxAPIData,
    methods: IShippingMethod[],
) => {
    let basket = state.basket;
    if (state.basket) {
        basket = { ...state.basket };
        basket.lines = basket.lines.map((line) => {
            return {
                ...line,
                shipping_methods: methods.map((method) => {
                    const submethod = method.lines.find((sub) => {
                        return sub.line_url === line.url;
                    });
                    return {
                        code: method.code,
                        price: method.price.incl_tax || "0.00",
                        name: submethod ? submethod.method.name : method.name,
                        description: submethod
                            ? submethod.method.description
                            : "",
                    };
                }),
            };
        });
    }
    return {
        ...state,
        shipping_methods: methods,
        shipping_method_errors: [] as string[],
        basket: basket,
    };
};

const injectShippingMethodErrors = (state: IReduxAPIData, errors: string[]) => {
    return {
        ...state,
        shipping_methods: [] as IShippingMethod[],
        shipping_method_errors: errors,
    };
};

const updateAPIDataFields = (
    state: IReduxAPIData,
    updatedFields: Partial<IReduxAPIData>,
) => {
    return {
        ...state,
        ...updatedFields,
    };
};

const updateWishlist = (
    state: IReduxAPIData,
    code: string,
    lines: IWishListLine[],
) => {
    state = {
        ...state,
        wishlists: {
            ...state.wishlists,
            [code]: lines,
        },
    };
    return state;
};

const resetPaymentStates = (state: IReduxAPIData) => {
    state = {
        ...state,
        payment_states: defaults.data.payment_states,
    };
    return state;
};

/* eslint-disable complexity */
export const reduceDataLoadEvents = guardReducer(
    Action,
    defaults.data,
    (state = defaults.data, action: IDataLoadActions) => {
        switch (action.type) {
            case Action.UPDATE_BASKET:
                return updateBasket(state, action.basket);

            case Action.UPDATE_ORDER:
                return updateOrder(state, action.order);

            case Action.UPDATE_BUNDLE:
                return injectBundle({ ...state }, action.bundle);

            case Action.UPDATE_COUNTRIES:
                return updateAPIDataFields(state, {
                    countries: action.countries,
                });

            case Action.UPDATE_SHIPPING_STATES:
                return updateAPIDataFields(state, {
                    shipping_states: action.states,
                });

            case Action.UPDATE_BILLING_STATES:
                return updateAPIDataFields(state, {
                    billing_states: action.states,
                });

            case Action.UPDATE_SHIPPING_METHODS:
                if (action.errors.length > 0) {
                    return injectShippingMethodErrors(state, action.errors);
                }
                return injectShippingMethods(state, action.methods);

            case Action.UPDATE_PAYMENT_METHODS:
                return updateAPIDataFields(state, {
                    payment_methods: action.methods,
                });

            case Action.UPDATE_PAYMENT_STATES:
                return updateAPIDataFields(state, {
                    payment_states: action.states,
                });

            case Action.RESET_PAYMENT_STATES:
                return resetPaymentStates(state);

            case Action.UPDATE_PAYMENT_ERROR:
                return updateAPIDataFields(state, {
                    payment_error: action.error,
                });

            case Action.UPDATE_FINANCING_PLANS:
                return updateAPIDataFields(state, {
                    financing_plans: action.plans,
                });

            case Action.UPDATE_WISHLIST:
                return updateWishlist(state, action.code, action.lines);

            case Action.UPDATE_ASSISTED_USER:
                return updateAPIDataFields(state, {
                    assisted_user: action.user,
                });

            case Action.UPDATE_USER_ADDRESSES:
                return updateAPIDataFields(state, {
                    addresses: action.addresses,
                });

            case Action.PREDICTED_DELIVERY_DATE_LOADING:
                return updateAPIDataFields(state, {
                    predicted_delivery_date: {
                        status: PredictedDeliveryDateStatus.LOADING,
                    },
                });

            case Action.PREDICTED_DELIVERY_DATE_LOADED:
                return updateAPIDataFields(
                    state,
                    action.error
                        ? {
                              predicted_delivery_date: {
                                  status: PredictedDeliveryDateStatus.ERROR,
                                  data: action.payload,
                              },
                          }
                        : {
                              predicted_delivery_date: {
                                  status: PredictedDeliveryDateStatus.LOADED,
                                  data: action.payload,
                              },
                          },
                );

            case Action.BEGIN_COMPLETE_DEFERRED_PAYMENT:
                return updateAPIDataFields(state, {
                    deferredPaymentOrder: action.payload.order,
                });

            case Action.SET_PREFERRED_DELIVERY_DATE:
                return {
                    ...state,
                    preferred_date: action.payload.preferred_date
                        ? action.payload.preferred_date.toISOString()
                        : null,
                };

            default:
                guardUnhandledAction(action);
        }
        return state;
    },
);
/* eslint-enable complexity */
