import { createAsyncThunk } from "@reduxjs/toolkit";
import * as financingAPI from "../../../api/financing";
import { ModalStateType } from "../constants";
import { ModalFullApplyPending, ModalFullApplyApproved } from "../models";

export const refreshPendingAppStatus = createAsyncThunk(
    "financing/refreshPendingAppStatus",
    async (
        modalState: ModalFullApplyPending,
    ): Promise<ModalFullApplyApproved | ModalFullApplyPending> => {
        const account = await financingAPI.updatePendingCreditAppStatus();
        if (account && parseFloat(account.credit_limit) > 0) {
            return {
                _tag: ModalStateType.APPLY_APPROVED,
                account: account,
                documents: [],
            };
        }
        return {
            ...modalState,
            isRefreshing: false,
        };
    },
);
