import React from "react";

import { templates } from "@reactivated";
import Flair from "../common/Flair";

export const Template = (props: templates.FlairSubBlockTemplate) => {
    if (!props.content) {
        return null;
    }
    const flair = {
        style: props.style,
        content: props.content,
    };
    return <Flair flair={flair} />;
};
