import { Reducer, UnknownAction } from "@reduxjs/toolkit";
import { objectValues } from "./functional";

/**
 * Type guard for narrowing an UnknownAction down to a known action interface.
 */
export const isKnownActionGroup = <
    KnownActionTypes extends string,
    KnownActions extends { type: KnownActionTypes },
>(
    knownActions: Record<string, KnownActionTypes>,
    action: unknown,
): action is KnownActions => {
    const type = (action as KnownActions).type;
    const knownTypes = objectValues(knownActions);
    return knownTypes.includes(type);
};

/**
 * Type guard for narrowing an UnknownAction down to a known action interface.
 */
export const isKnownAction = <T extends { type: string }>(
    expectedType: T["type"],
    action: unknown,
): action is T => {
    return (action as T).type === expectedType;
};

export const guardReducer = <
    KnownActionTypes extends string,
    KnownActions extends { type: KnownActionTypes },
    DataType,
>(
    knownActions: Record<string, KnownActionTypes>,
    defaultValue: DataType,
    inner: Reducer<DataType, KnownActions>,
): Reducer<DataType, UnknownAction> => {
    const outer: Reducer<DataType, UnknownAction> = (
        state = defaultValue,
        action,
    ) => {
        if (
            isKnownActionGroup<KnownActionTypes, KnownActions>(
                knownActions,
                action,
            )
        ) {
            return inner(state, action);
        }
        return state;
    };
    return outer;
};
