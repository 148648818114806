import { guardUnhandledAction } from "../../../utils/never";
import { guardReducer } from "../../../utils/redux";
import { defaultMethodOrder, defaults } from "../defaults";
import {
    Action,
    DEFAULT_PHONE_NUMBER,
    DEFAULT_COUNTRY,
    PRIMARY_PAYMENT_METHOD_KEY,
    SECONDARY_PAYMENT_METHOD_KEY,
} from "../constants";
import {
    IPayment,
    IPaymentPartial,
    IReduxFormState,
    IUserInputAction,
} from "../reducers.interfaces";

const cloneShippingAddressToBillingAddress = (state: IReduxFormState) => {
    const newState: IReduxFormState = {
        ...state,
        billing_address_url: state.shipping_address_url,
        billing_first_name: state.shipping_first_name,
        billing_last_name: state.shipping_last_name,
        billing_line1: state.shipping_line1,
        billing_line2: state.shipping_line2,
        billing_postcode: state.shipping_postcode,
        billing_line4: state.shipping_line4,
        billing_state: state.shipping_state,
        billing_phone_number: state.shipping_phone_number,
        billing_country: state.shipping_country,
    };
    return newState;
};

const clearBillingAddress = (state: IReduxFormState) => {
    const newState: IReduxFormState = {
        ...state,
        billing_address_url: "",
        billing_first_name: "",
        billing_last_name: "",
        billing_line1: "",
        billing_line2: "",
        billing_postcode: "",
        billing_line4: "",
        billing_state: "",
        billing_phone_number: DEFAULT_PHONE_NUMBER,
        billing_country: DEFAULT_COUNTRY,
    };
    return newState;
};

const updateFormFields = (
    state: IReduxFormState,
    updatedFields: Partial<IReduxFormState>,
) => {
    state = { ...state, ...updatedFields };

    if (state.billing_addr_is_shipping_addr) {
        state = cloneShippingAddressToBillingAddress(state);
    }

    return state;
};

const setUseShippingAddrAsBilling = (
    state: IReduxFormState,
    billingIsShipping: boolean,
) => {
    const newState: IReduxFormState = {
        ...state,
        billing_addr_is_shipping_addr: billingIsShipping,
    };
    if (newState.billing_addr_is_shipping_addr) {
        return cloneShippingAddressToBillingAddress(newState);
    }
    return clearBillingAddress(newState);
};

const calculatePaymentMethodAmounts = (state: IReduxFormState) => {
    if (SECONDARY_PAYMENT_METHOD_KEY in state.payment_methods) {
        state.payment_methods[PRIMARY_PAYMENT_METHOD_KEY].pay_balance = false;
        state.payment_methods[SECONDARY_PAYMENT_METHOD_KEY].pay_balance = true;
    } else {
        state.payment_methods[PRIMARY_PAYMENT_METHOD_KEY].pay_balance = true;
    }
    return state;
};

const resetPaymentMethods = (
    state: IReduxFormState,
    methods: IPayment["method_type"][],
) => {
    const default_methods = { ...defaults.form.payment_methods };
    // if form primary method is 'default', set to first available default
    if (default_methods[PRIMARY_PAYMENT_METHOD_KEY].method_type === "default") {
        for (const method_type of defaultMethodOrder) {
            if (methods.includes(method_type)) {
                switch (method_type) {
                    case "bluefin": {
                        default_methods[PRIMARY_PAYMENT_METHOD_KEY] = {
                            payment_data: "",
                            ...default_methods[PRIMARY_PAYMENT_METHOD_KEY],
                            method_type: method_type,
                        };
                        break;
                    }
                    case "cybersource": {
                        default_methods[PRIMARY_PAYMENT_METHOD_KEY] = {
                            card_type: "",
                            card_number: "",
                            card_expiration: "",
                            card_cvc: "",
                            ...default_methods[PRIMARY_PAYMENT_METHOD_KEY],
                            method_type: method_type,
                        };
                        break;
                    }
                    case "financing": {
                        default_methods[PRIMARY_PAYMENT_METHOD_KEY] = {
                            new_financing_account: null,
                            financing_account: "",
                            financing_plan: null,
                            has_agreed: false,
                            has_esigned: false,
                            ...default_methods[PRIMARY_PAYMENT_METHOD_KEY],
                            method_type: method_type,
                        };
                        break;
                    }
                    case "cash": {
                        default_methods[PRIMARY_PAYMENT_METHOD_KEY] = {
                            ...default_methods[PRIMARY_PAYMENT_METHOD_KEY],
                            method_type: method_type,
                        };
                        break;
                    }
                    case "pay-later": {
                        default_methods[PRIMARY_PAYMENT_METHOD_KEY] = {
                            ...default_methods[PRIMARY_PAYMENT_METHOD_KEY],
                            method_type: method_type,
                        };
                        break;
                    }
                }
                break;
            }
        }
    }
    state = {
        ...state,
        payment_methods: default_methods,
    };
    return calculatePaymentMethodAmounts(state);
};

const addPaymentMethod = (
    state: IReduxFormState,
    methodKey: string,
    method: IPayment,
) => {
    state = {
        ...state,
        payment_methods: {
            ...state.payment_methods,
            [methodKey]: method,
        },
    };
    return calculatePaymentMethodAmounts(state);
};

const removePaymentMethod = (state: IReduxFormState, methodKey: string) => {
    const methods = { ...state.payment_methods };
    delete methods[methodKey];
    state = {
        ...state,
        payment_methods: methods,
    };
    return calculatePaymentMethodAmounts(state);
};

const updatePaymentMethodFields = (
    state: IReduxFormState,
    methodKey: string,
    fields: IPaymentPartial,
) => {
    const method = state.payment_methods[methodKey];
    if (!method) {
        throw new Error(`Payment method key ${methodKey} does not exist.`);
    }
    const newFields = fields as Partial<typeof method>;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const mergedData: any = { ...method, ...newFields };
    state = {
        ...state,
        payment_methods: {
            ...state.payment_methods,
            [methodKey]: mergedData,
        },
    };
    return calculatePaymentMethodAmounts(state);
};

export const reduceUserInputEvents = guardReducer(
    Action,
    defaults.form,
    (state = defaults.form, action: IUserInputAction) => {
        switch (action.type) {
            case Action.SET_FIELDS:
                return updateFormFields(state, action.fields);

            case Action.USE_SHIPPING_ADDR_AS_BILLING_ADDR:
                return setUseShippingAddrAsBilling(
                    state,
                    action.billing_addr_is_shipping_addr,
                );

            case Action.RESET_PAYMENT_METHODS:
                return resetPaymentMethods(state, action.payload.methods);

            case Action.UPDATE_PAYMENT_METHODS:
                return resetPaymentMethods(state, action.methods);

            case Action.ADD_PAYMENT_METHOD:
                return addPaymentMethod(
                    state,
                    action.method_key,
                    action.method,
                );

            case Action.REMOVE_PAYMENT_METHOD:
                return removePaymentMethod(state, action.method_key);

            case Action.SET_PAYMENT_METHOD_FIELDS:
                return updatePaymentMethodFields(
                    state,
                    action.method_key,
                    action.fields,
                );

            default:
                guardUnhandledAction(action);
        }

        return state;
    },
);
