import React from "react";

interface IProps {
    desktop_image?: string;
    mobile_image?: string;
}

interface IState {
    isMobile: boolean;
}

export class OrderOfferImage extends React.Component<IProps, IState> {
    public state: IState = {
        isMobile: false,
    };

    componentDidMount() {
        window.addEventListener("resize", () => {
            this.isWindowResizing();
        });
    }

    private isWindowResizing() {
        if (window.innerWidth <= 500) {
            this.setState({
                isMobile: true,
            });
        } else {
            this.setState({
                isMobile: false,
            });
        }
    }

    render() {
        const backgroundImage = this.state.isMobile
            ? this.props.mobile_image
            : this.props.desktop_image;
        return (
            <div
                style={{ backgroundImage: `url('${backgroundImage}')` }}
                className="thank-you-order-offer__container"
            ></div>
        );
    }
}
