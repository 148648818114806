import React from "react";
import { IStringField } from "../models/formFields.interfaces";
import { Image } from "../common/Image";
import { IFormFieldState } from "./AbstractFormField";
import { AbstractInput } from "./AbstractInput";

// This is a temporary measure to allow for SSR of FormInputs, @zlebar
export class FormInputWithoutFormatting<T extends string> extends AbstractInput<
    T,
    IStringField<T>,
    IFormFieldState
> {
    public static defaultProps = {
        type: "text",
    };

    public state: IFormFieldState = {
        focused: false,
    };

    public onChange(event: React.FormEvent<HTMLInputElement>) {
        if (!event.currentTarget) {
            return;
        }

        // If maxBytes property is provided, check the current value of the input and truncate it to stay within the size limit.
        if (this.props.maxBytes) {
            const original = event.currentTarget.value;
            const truncated = this.truncateStringByBytes(
                original,
                this.props.maxBytes,
            );
            if (original !== truncated) {
                event.currentTarget.value = truncated;
            }
        }

        super.onChange(event);
    }

    public componentDidMount() {
        super.componentDidMount();
    }

    protected getInputProps() {
        return Object.assign(super.getInputProps(), {
            minLength: this.props.minLength,
            maxLength: this.props.maxLength,
            pattern: this.props.pattern,
        });
    }

    private truncateStringByBytes(original: string, maxBytes: number) {
        let truncated: string;
        let byteLength: number;
        for (let i = original.length; i >= 0; i--) {
            truncated = original.slice(0, i);
            byteLength = encodeURIComponent(
                truncated.replace(/\d/g, "X"),
            ).replace(/%[A-F\d]{2,6}/g, "U").length;
            if (byteLength <= maxBytes) {
                return truncated;
            }
        }
        throw Error(
            `This should never happen! Somehow an empty string is longer than ${maxBytes} bytes.`,
        );
    }

    protected buildControl() {
        const props = this.getInputProps();
        props.className ??= "form__field__input";
        let image = null;
        if (
            this.props.secureImage &&
            this.props.secureImage.image &&
            this.props.secureImage.image.length > 0 &&
            this.props.secureImage.alt
        ) {
            image = (
                <Image
                    src={this.props.secureImage.image}
                    alt={this.props.secureImage.alt}
                    lazy={false}
                />
            );
        }
        return (
            <>
                <input
                    ref={(ref) => {
                        this.inputElem = ref;
                    }}
                    {...props}
                />
                {image}
            </>
        );
    }
}
