import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { IAddress } from "../../../models/address.interfaces";
import { FormSelect } from "../../../forms/FormSelect";
import { Dispatchers } from "../dispatchers";
import { IReduxFormState } from "../reducers.interfaces";

interface IOwnProps {
    prefix: "shipping" | "billing";
    isDisabled?: boolean;
}

interface IReduxProps {
    form: Partial<IReduxFormState>;
    savedAddresses: IAddress[];
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class SavedAddressSelectorComponent extends React.Component<IProps, IState> {
    private readonly onSavedAddressChange = (
        event: React.FormEvent<HTMLSelectElement>,
    ) => {
        this.props.dispatchers.selectSavedAddress(
            this.props.prefix,
            this.props.savedAddresses,
            event.currentTarget.value,
        );
    };

    private getSavedAddressOptions() {
        const savedAddresses = this.props.savedAddresses
            .map((addr) => {
                const descr = [
                    `${addr.title} ${addr.first_name} ${addr.last_name}`,
                    addr.line1,
                    addr.line2,
                    addr.line4,
                    addr.state,
                    addr.postcode,
                ]
                    .filter((item) => {
                        return !!item;
                    })
                    .join(", ")
                    .replace(/\s+/g, " ")
                    .trim();
                return {
                    value: addr.url,
                    label: descr,
                };
            })
            .sort((a, b) => {
                if (a.label === b.label) {
                    return 0;
                }
                return a.label > b.label ? 1 : -1;
            });
        // Add a blank option to the top
        savedAddresses.unshift({
            value: "",
            label: "",
        });
        return savedAddresses;
    }

    render() {
        if (this.props.savedAddresses.length <= 0) {
            return null;
        }
        const elemID = `${this.props.prefix}_address_url`;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const addrURL = `${this.props.prefix}_address_url` as
            | "shipping_address_url"
            | "billing_address_url";
        const value = this.props.form[addrURL];
        return (
            <FormSelect
                label={t`Saved Address`}
                labelPlacement="dynamic"
                name={elemID}
                id={elemID}
                value={value}
                onChange={this.onSavedAddressChange}
                choices={this.getSavedAddressOptions()}
                disabled={this.props.isDisabled}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        form: state.form,
        savedAddresses: state.data.addresses,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const SavedAddressSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SavedAddressSelectorComponent);
