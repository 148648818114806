import React from "react";
import {
    ICountdownTimer,
    ICountdownTime,
    CountdownTimerTheme,
} from "../../../models/countdown-timer.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";
import { BreakPoint } from "../../../models/screen.interfaces";
import styles from "./CountdownTimer.module.scss";
import classNames from "classnames";

interface IProps {
    currentTimer: ICountdownTimer | null;
    onTimerExpired: () => void;
}

interface IState {
    timeLeft: ICountdownTime | null;
}

export class CountdownTimer extends React.Component<IProps, IState> {
    timerID: number | undefined = undefined;

    state: IState = {
        timeLeft: {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        },
    };

    componentDidMount() {
        this.timerID = window.setInterval(() => {
            this.tick();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    private formatNumber(n: number | null) {
        if (n === null) {
            return "";
        }

        return n.toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false,
        });
    }

    private tick() {
        this.setState({
            timeLeft: this.calcTimeDiff(),
        });
    }

    private calcTimeDiff() {
        const now = new Date().getTime();
        let end = 0;
        if (this.props.currentTimer) {
            end = Date.parse(this.props.currentTimer.end_date);
        }
        const difference = end - now;

        const timeLeft: ICountdownTime = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
        if (difference > 0) {
            timeLeft.days = Math.floor(difference / (1000 * 60 * 60 * 24));
            timeLeft.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
            timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60);
            timeLeft.seconds = Math.floor((difference / 1000) % 60);
            return timeLeft;
        } else {
            this.props.onTimerExpired();
            return null;
        }
    }
    private getBodyCopy() {
        if (this.props.currentTimer) {
            let body = this.props.currentTimer.body;
            if (
                getViewportBreakpoint() < BreakPoint.SMALL &&
                this.props.currentTimer.mobile_body
            ) {
                body = this.props.currentTimer.mobile_body;
            }
            return body;
        }
        return "";
    }

    render() {
        if (!this.state.timeLeft) {
            return <></>;
        }

        const formattedDays = this.formatNumber(this.state.timeLeft?.days);
        const formattedHours = this.formatNumber(this.state.timeLeft?.hours);
        const formattedMin = this.formatNumber(this.state.timeLeft?.minutes);
        const formattedSec = this.formatNumber(this.state.timeLeft?.seconds);
        const hasTime =
            formattedDays !== "00" ||
            formattedHours !== "00" ||
            formattedMin !== "00" ||
            formattedSec !== "00";

        const timerTheme = this.props.currentTimer?.theme;
        let themeStyle = "";
        switch (timerTheme) {
            case CountdownTimerTheme.RED:
                themeStyle = styles.red;
                break;
            case CountdownTimerTheme.YELLOW:
                themeStyle = styles.yellow;
                break;
            case CountdownTimerTheme.CARDS:
                themeStyle = styles.cards;
                break;
            default:
                themeStyle = "";
        }

        const timerClasses = classNames({
            [styles.countdownTimer]: true,
            [themeStyle]: true,
        });

        // TODO : Use better semnatic markup for date layout below. Table?
        return (
            <>
                {/* Added condition "hasTime" so that this promo copy is rendered at the same time as the countdown timer. */}
                {hasTime && this.props.currentTimer && (
                    <div
                        className={styles.content}
                        dangerouslySetInnerHTML={{
                            __html: this.getBodyCopy(),
                        }}
                    ></div>
                )}
                {hasTime && (
                    <div className={timerClasses}>
                        <div>
                            <h2>
                                <span className="countdown-timer__copy--trim">
                                    Sale <br />
                                </span>
                                Ends In
                            </h2>
                        </div>
                        <div>
                            <div>
                                <div>
                                    <span>{formattedDays}</span>
                                    <span>days</span>
                                </div>
                                <div className={`${styles.colon} colon`}>:</div>
                            </div>
                            <div>
                                <div>
                                    <span>{formattedHours}</span>
                                    <span>
                                        h
                                        <span className="countdown-timer__copy--trim">
                                            ou
                                        </span>
                                        rs
                                    </span>
                                </div>
                                <div className={`${styles.colon} colon`}>:</div>
                            </div>
                            <div>
                                <div>
                                    <span>{formattedMin}</span>
                                    <span>min</span>
                                </div>
                                <div className={`${styles.colon} colon`}>:</div>
                            </div>
                            <div>
                                <div>
                                    <span>{formattedSec}</span>
                                    <span>sec</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}
