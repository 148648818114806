import React from "react";
import { connect } from "react-redux";
import memoize from "memoize-one";
import { t } from "ttag";
import {
    getProductsByUUIDs,
    getWriteReviewTemplate,
} from "../../../api/reviews";
import {
    IReviewsProductID,
    IFormUUID,
    isoFormUUID,
    isoReviewsProductID,
    isoProductUUID,
    IReviewsProductVariantID,
} from "../../../models/nominals";
import { check } from "../../../models/utils";
import { UUIDReviewsInfoMap } from "../../../models/reviews";
import {
    IReviewsProduct,
    IWriteReviewTemplate,
    IUUIDReviewsInfoMap,
} from "../../../models/reviews.interfaces";
import {
    IWriteReviewFormState,
    IReviewFormProductTemplatePair,
} from "../reducers.interfaces";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Actions } from "../actions";
import { Dispatchers } from "../dispatchers";
import { defaultState, defaultReviewTemplate } from "../defaults";
import { WriteReviewFormMode, SideNames } from "../constants";
import { parseFacetValues } from "../../../utils/reviews";
import { Form } from "../../../forms/Form";
import { FormSubmit } from "../../../forms/FormSubmit";
import { MultiProductWriteReviewFormSection } from "../elements/MultiProductWriteReviewFormSection";
import { MultiProductWriteReviewFormGlobalInputSection } from "../elements/MultiProductWriteReviewFormGlobalInputSection";
import { WriteReviewFormDisclaimers } from "../elements/WriteReviewFormDisclaimers";
import { WriteReviewFormDescriptionCopy } from "../elements/WriteReviewFormDescriptionCopy";

interface IOwnProps {
    reviewsInfoMapJSON: string;
    email?: string;
}

interface IReduxProps {
    loadedInitialProducts: boolean;
    products: IReviewsProduct[];
    writeReviewTemplates: IReviewFormProductTemplatePair[];
    forms: { [formUUID: string]: IWriteReviewFormState };
}

interface IDispatchProps {
    dispatchers: Dispatchers;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}
interface IParams {
    [name: string]: string | number | number[] | undefined | null;
}

const getWriteReviewTemplateForForm = (
    templates: IReviewFormProductTemplatePair[],
    formState: IWriteReviewFormState,
): IWriteReviewTemplate => {
    const formTemplate = templates.find(
        ({ productID }) => productID === formState.selectedProductID,
    );
    return formTemplate ? formTemplate.template : defaultReviewTemplate;
};

class MultiProductWriteReviewFormContainer extends React.Component<
    IProps,
    IState
> {
    private readonly parseReviewsInfoMap = memoize(
        (infoMapJSON: string): IUUIDReviewsInfoMap => {
            const data = check(
                UUIDReviewsInfoMap.decode(JSON.parse(infoMapJSON)),
            );
            // TODO: is there a way to do this with io-ts decoder?
            // convert JSON object into Map, converting UUIDstring to UUID
            const infoMap: IUUIDReviewsInfoMap = Object.entries(data)
                .map(([k, v]) => [isoProductUUID.wrap(k), v])
                .reduce((m, [k, v]) => m.set(k, v), new Map());
            return infoMap;
        },
    );

    private readonly onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isMultiReview = true;
        for (const [uuid, formState] of Object.entries(this.props.forms)) {
            const formTemplate = getWriteReviewTemplateForForm(
                this.props.writeReviewTemplates,
                formState,
            );
            if (formState.mode === WriteReviewFormMode.FORM_OPEN) {
                this.props.actions.submitReview(
                    isoFormUUID.wrap(uuid),
                    formTemplate,
                    formState,
                    isMultiReview,
                );
            }
        }
    };

    componentDidMount() {
        this.listProducts();
    }

    componentDidUpdate(_prevProps: IProps, _prevState: IState) {
        if (this.someReviewsSubmitted()) {
            this.scrollToThankYou();
        }
    }

    private readSelectedProductParam(): IReviewsProductID[] {
        const urlObj = new URL(window.location.href);
        const query: IParams = {};
        urlObj.searchParams.forEach((value, name) => {
            query[name] = `${value}`;
        });
        if (query.selected_products) {
            const prodIDs: IReviewsProductID[] = parseFacetValues(
                `${query.selected_products || ""}`,
            ).map((id: string) => isoReviewsProductID.wrap(parseInt(id, 10)));
            return prodIDs;
        }
        return [];
    }

    private async listProducts() {
        // Load the reviews product for each given UUID
        const infoMap = this.parseReviewsInfoMap(this.props.reviewsInfoMapJSON);
        const prodUUIDs = [...infoMap.keys()];
        const products = await getProductsByUUIDs(prodUUIDs);
        this.props.dispatchers.setProducts(products);
        // Load the reviews template for product
        await Promise.all(products.map(this.loadTemplate.bind(this)));
        if (this.props.email) {
            this.props.dispatchers.setCustomerInformationEmail(
                this.props.email,
            );
        }
        this.setActiveProducts();
    }

    private async loadTemplate(product: IReviewsProduct) {
        const infoMap = this.parseReviewsInfoMap(this.props.reviewsInfoMapJSON);
        if (!product.uuid) {
            return;
        }
        const formUUID = isoFormUUID.wrap(String(product.uuid));
        const reviewsMap = infoMap.get(product.uuid);
        const template = await getWriteReviewTemplate(product.id);
        // If multiple variants, render separate forms for each variant on product
        if (reviewsMap && reviewsMap.reviewsProductVariantIDs.length > 0) {
            for (const variantID of reviewsMap.reviewsProductVariantIDs) {
                const variantFormUUID = isoFormUUID.wrap(
                    `${product.uuid}__${variantID}`,
                );
                this.registerNewReviewsForm(
                    variantFormUUID,
                    product,
                    template,
                    variantID,
                );
            }
        } else {
            // No variant info provided
            this.registerNewReviewsForm(formUUID, product, template);
        }
    }

    private registerNewReviewsForm(
        formUUID: IFormUUID,
        product: IReviewsProduct,
        template: IWriteReviewTemplate,
        variantID: IReviewsProductVariantID | null = null,
    ) {
        this.props.dispatchers.registerNewWriteReviewForm(
            formUUID,
            WriteReviewFormMode.FORM_OPEN,
        );
        this.props.dispatchers.setReviewTemplate(
            formUUID,
            product.id,
            template,
        );
        // Find variant info from review product, update selection of form
        const reviewVariantInfo = product.variants.find(
            (v) => v.id === variantID,
        );
        if (reviewVariantInfo) {
            this.props.dispatchers.onReviewFormVariantChange({
                formUUID: formUUID,
                selectedVariant: reviewVariantInfo.slug,
            });
        }
    }

    private async setActiveProducts() {
        const prodIDs = this.readSelectedProductParam();
        if (!prodIDs.length) {
            return;
        }
        Object.entries(this.props.forms).forEach(([uuidstr, formState]) => {
            // make sure selected products are active
            const uuid = isoFormUUID.wrap(uuidstr);
            if (
                formState.selectedProductID &&
                prodIDs.includes(formState.selectedProductID)
            ) {
                this.props.dispatchers.setWriteReviewFormMode(
                    uuid,
                    WriteReviewFormMode.FORM_OPEN,
                );
                // deactive products not in selected_products param
            } else {
                this.props.dispatchers.setWriteReviewFormMode(
                    uuid,
                    WriteReviewFormMode.CLOSED,
                );
            }
        });
    }

    private scrollToThankYou() {
        const thankYouSelectDiv = document.querySelector(".review-thank-you");
        thankYouSelectDiv?.scrollIntoView({ behavior: "smooth" });
    }

    private allReviewsSubmitted() {
        // if no product UUIDs on dom, treat as completed
        const infoMap = this.parseReviewsInfoMap(this.props.reviewsInfoMapJSON);
        if (!infoMap.size) {
            return true;
        }
        const unSubmittedReviews = Object.entries(this.props.forms).filter(
            ([_, reviewTemplate]) =>
                reviewTemplate.mode !== WriteReviewFormMode.THANK_YOU_OPEN,
        );
        if (!unSubmittedReviews.length) {
            return true;
        }
        return false;
    }

    private someReviewsSubmitted() {
        // if at least one review is submitted and no other review forms open
        const submittedReviews = Object.entries(this.props.forms).filter(
            ([_, reviewTemplate]) =>
                reviewTemplate.mode === WriteReviewFormMode.THANK_YOU_OPEN,
        );
        const openReviews = Object.entries(this.props.forms).filter(
            ([_, reviewTemplate]) =>
                reviewTemplate.mode === WriteReviewFormMode.FORM_OPEN,
        );
        if (submittedReviews.length && !openReviews.length) {
            return true;
        }
        return false;
    }

    render() {
        const infoMap = this.parseReviewsInfoMap(this.props.reviewsInfoMapJSON);
        if (!this.props.loadedInitialProducts) {
            return null;
        }
        // if all forms submitted or closed, show thank you
        if (this.allReviewsSubmitted()) {
            return (
                <div className="review-thank-you" role="alert">
                    <h2 className="review-thank-you__title">
                        {t`No Products Left to Review`}
                    </h2>
                    <p>
                        {t`We appreciate your feedback and comments, and always enjoy hearing from you.`}
                    </p>
                    <p>
                        {t`Return to the `}
                        <a
                            title={t`Home`}
                            href="/"
                            className="review-thank-you__home"
                            target="_self"
                        >
                            {t`homepage`}
                        </a>
                        <span>.</span>
                    </p>
                </div>
            );
        }
        return (
            <>
                {this.someReviewsSubmitted() && (
                    <div className="review-thank-you" role="alert">
                        <h2 className="review-thank-you__title">
                            {t`Thank You For Your Feedback`}
                        </h2>
                        <p>
                            {t`We appreciate your comments. Please review more items below or return to the `}
                            <a
                                title={t`Home`}
                                href="/"
                                className="review-thank-you__home"
                                target="_self"
                            >
                                {t`homepage`}
                            </a>
                            <span>.</span>
                        </p>
                    </div>
                )}
                <div className="multi-product-write-review-form">
                    <p className="write-review-form__sub-title">
                        (
                        <span className="write-review-form__sub-title--red">
                            *
                        </span>
                        ){" "}
                        <span className="write-review-form__sub-title--italic">
                            {t`indicates a required field`}
                        </span>
                    </p>
                    <WriteReviewFormDescriptionCopy isMultiReview={true} />

                    <Form onSubmit={this.onSubmit} noValidate={true}>
                        {this.props.products.map((product) => {
                            if (!product.uuid) {
                                return null;
                            }
                            const reviewsMap = infoMap.get(product.uuid);
                            // Is Product Heterogeneous?
                            if (
                                reviewsMap &&
                                reviewsMap.reviewsProductVariantIDs.length > 1
                            ) {
                                return reviewsMap.reviewsProductVariantIDs.map(
                                    (
                                        variantID: IReviewsProductVariantID,
                                        idx: number,
                                    ) => {
                                        const formUUID = isoFormUUID.wrap(
                                            `${product.uuid}__${variantID}`,
                                        );
                                        const side = SideNames[idx];
                                        return (
                                            <MultiProductWriteReviewFormSection
                                                key={String(formUUID)}
                                                formUUID={formUUID}
                                                product={product}
                                                isMultiReview={true}
                                                side={side}
                                            />
                                        );
                                    },
                                );
                                // Products with one variant not considered htereogenous, have no side info
                            } else if (
                                reviewsMap &&
                                reviewsMap.reviewsProductVariantIDs.length === 1
                            ) {
                                return reviewsMap.reviewsProductVariantIDs.map(
                                    (variantID: IReviewsProductVariantID) => {
                                        const formUUID = isoFormUUID.wrap(
                                            `${product.uuid}__${variantID}`,
                                        );
                                        return (
                                            <MultiProductWriteReviewFormSection
                                                key={String(formUUID)}
                                                formUUID={formUUID}
                                                product={product}
                                                isMultiReview={true}
                                            />
                                        );
                                    },
                                );
                            } else {
                                const formUUID = isoFormUUID.wrap(
                                    String(product.uuid),
                                );
                                return (
                                    <MultiProductWriteReviewFormSection
                                        key={String(product.uuid)}
                                        formUUID={formUUID}
                                        product={product}
                                        isMultiReview={true}
                                    />
                                );
                            }
                        })}
                        <MultiProductWriteReviewFormGlobalInputSection
                            email={this.props.email}
                        />
                        <WriteReviewFormDisclaimers />
                        <FormSubmit
                            className="button button--rock-blue write-review-form__cta"
                            type="submit"
                            value={t`Submit your review`}
                        />
                    </Form>
                </div>
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        loadedInitialProducts: state.data.loadedInitialProducts,
        products: state.data.products,
        writeReviewTemplates: state.data.writeReviewTemplates,
        forms: state.ui.writeReviewForms,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const actions = new Actions(dispatchers);
    return {
        dispatchers: dispatchers,
        actions: actions,
    };
};

export const MultiProductWriteReviewForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MultiProductWriteReviewFormContainer);
