import React from "react";
import { RichNavigationImagePromo as RichNavigationImagePromoValue } from "@reactivated";
import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";

import styles from "./index.module.scss";

export interface Props {
    value: RichNavigationImagePromoValue;
    className?: string;
    children?: React.ReactNode;
}

const Wrapper = (props: Props) => {
    if (!props.value.link) {
        return <div className={props.className}>{props.children}</div>;
    }
    return (
        <a className={props.className} href={props.value.link.url}>
            {props.children}
        </a>
    );
};

export const RichNavigationImagePromo = (props: Props) => {
    const isActive =
        !props.value.promo_period || props.value.promo_period.is_promo_active;
    if (!isActive) {
        return null;
    }
    return (
        <Wrapper {...props}>
            {props.value.image && (
                <ImageChooserBlock
                    attrs={{ className: styles.imgContainer }}
                    value={props.value.image}
                />
            )}
        </Wrapper>
    );
};
