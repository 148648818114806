import React from "react";
import { IReviewsProductTypeID } from "../../../models/nominals";
import { ReviewList } from "../elements/ReviewList";
import { LoadMoreButton } from "../elements/LoadMoreButton";
import { TopBar } from "../elements/TopBar";
import { SidebarFilterSection } from "../elements/SidebarFilterSection";

interface IProps {
    displayedProductTypeIDWhitelist: IReviewsProductTypeID[];
    showSources: boolean;
    enablePDPLinks: boolean;
    isCondensedView?: boolean;
    mobileSideNavOpen: boolean;
    showAppliedFilters: boolean;
    writeReviewURL: string;
    starHasStroke?: boolean;
    onLoadMore: () => void;
    onToggleMobileSideNav: () => void;
}

interface IState {}

export class ReviewsViewer extends React.PureComponent<IProps, IState> {
    private buildSideNav(mobileStatus: boolean) {
        return (
            <SidebarFilterSection
                isMobile={mobileStatus}
                displayedProductTypeIDWhitelist={
                    this.props.displayedProductTypeIDWhitelist
                }
                isCondensedView={this.props.isCondensedView}
                showProducts={true}
                showSources={this.props.showSources}
                starHasStroke={this.props.starHasStroke}
                writeReviewURL={this.props.writeReviewURL}
                onToggleMobileSideNav={this.props.onToggleMobileSideNav}
                mobileSideNavOpen={this.props.mobileSideNavOpen}
                showAppliedFilters={this.props.showAppliedFilters}
            />
        );
    }

    private buildReviews() {
        return (
            <ReviewList
                showSideDate={false}
                enablePDPLinks={this.props.enablePDPLinks}
                showRecommended={true}
                showVerboseUserInfo={true}
                starHasStroke={this.props.starHasStroke}
            />
        );
    }

    private buildLoadMoreButton() {
        return <LoadMoreButton onLoadMore={this.props.onLoadMore} />;
    }

    private buildReviewModule() {
        // Hide reviews if filter for mobile is visible
        if (this.props.mobileSideNavOpen) {
            return null;
        }

        return (
            <div className="customer-review__content">
                <TopBar />
                <div className="customer-review__review-list">
                    {this.buildReviews()}
                    {this.buildLoadMoreButton()}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div>
                {this.buildSideNav(true)}
                <div className="customer-review__body" tabIndex={-1}>
                    {this.buildSideNav(false)}
                    {this.buildReviewModule()}
                </div>
            </div>
        );
    }
}
