export enum Action {
    SET_COMPARE_TILES = "ProductCompare/SET_COMPARE_TILES",

    ADD_TO_SELECTED = "ProductCompare/ADD_TO_SELECTED",
    REMOVE_FROM_SELECTED = "ProductCompare/REMOVE_FROM_SELECTED",

    UPDATE_PRICE_SELECT_VALUE = "ProductCompare/UPDATE_PRICE_SELECT_VALUE",

    COLLAPSE_TRAY = "ProductCompare/COLLAPSE_TRAY",
    EXPAND_TRAY = "ProductCompare/EXPAND_TRAY",

    SHOW_MODAL = "ProductCompare/SHOW_MODAL",
    HIDE_MODAL = "ProductCompare/HIDE_MODAL",

    SET_ROOT_PRODUCTS = "ProductCompare/SET_ROOT_PRODUCS",
}
