import { onDOMContentLoaded } from "../utils/events";
import { loadWistiaPlayer } from "../utils/wistia";

onDOMContentLoaded.on(async () => {
    const bg_videos = document.querySelectorAll<HTMLElement>(
        ".wistia-background-video",
    );
    if (bg_videos.length) {
        await loadWistiaPlayer();
        bg_videos.forEach(async (container) => {
            const videoID = container.dataset.videoId;

            const videoOptions = {
                plugin: {
                    cropFill: {
                        src: "https://fast.wistia.com/labs/crop-fill/plugin.js",
                    },
                },
            };

            // Embed video in the background
            window.Wistia.embed(videoID, videoOptions);
        });
    }
});
