import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Actions } from "../actions";
import {
    IVoucher,
    IVoucherSearchResults,
    IVoucherSearchErrors,
} from "../models.interfaces";

interface IOwnProps {}

interface IReduxProps {
    vouchers: IVoucherSearchResults | IVoucherSearchErrors;
}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isLoading: boolean;
}

const isSearchErrors = (
    obj: IVoucherSearchResults | IVoucherSearchErrors,
): obj is IVoucherSearchErrors => {
    return (obj as IVoucherSearchErrors).detail !== undefined;
};

class VouchersComponent extends React.Component<IProps, IState> {
    public state: Readonly<IState> = {
        isLoading: false,
    };

    private code: HTMLInputElement | null | undefined;

    private readonly onSearch = async (event: React.FormEvent<HTMLElement>) => {
        event.preventDefault();
        if (this.code) {
            this.setState({ isLoading: true });
            await this.props.actions.searchVoucherCode(this.code.value);
            this.setState({ isLoading: false });
        }
    };

    private readonly onDelete = async (
        event: React.FormEvent<HTMLElement>,
        voucher: IVoucher,
    ) => {
        event.preventDefault();
        this.setState({ isLoading: true });
        await this.props.actions.deleteVoucherCode(voucher);
        this.setState({ isLoading: false });
    };

    render() {
        const [searchResults, error] = isSearchErrors(this.props.vouchers)
            ? [null, this.props.vouchers]
            : [this.props.vouchers, null];
        const voucher =
            searchResults && searchResults.results.length > 0
                ? searchResults.results[0]
                : null;

        return (
            <div className="csr-vouchers">
                <form onSubmit={this.onSearch}>
                    <div className="csr-vouchers__input">
                        <input
                            type="text"
                            ref={(r) => {
                                this.code = r;
                            }}
                            placeholder={t`Enter a voucher code to lookup.`}
                            required={true}
                        />
                    </div>
                    <div className="csr-vouchers__button">
                        <button type="submit" disabled={this.state.isLoading}>
                            {t`Search`}
                        </button>
                    </div>
                </form>

                {error && <p className="csr-vouchers__error">{error.detail}</p>}
                {voucher && (
                    <div className="csr-vouchers__results">
                        <h3>{t`Voucher Details`}</h3>
                        <table>
                            <tbody>
                                <tr>
                                    <th scope="row">{t`Name`}</th>
                                    <td>{voucher.name}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{t`Code`}</th>
                                    <td>{voucher.code}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{t`Valid Dates`}</th>
                                    <td>
                                        {new Date(
                                            voucher.start_datetime,
                                        ).toLocaleString()}
                                        &nbsp;&ndash;&nbsp;
                                        {new Date(
                                            voucher.end_datetime,
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">{t`Num. Baskets`}</th>
                                    <td>{voucher.num_basket_additions}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{t`Num. Orders`}</th>
                                    <td>{voucher.num_orders}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{t`Num. Children`}</th>
                                    <td>{voucher.num_children}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="button-group">
                            <button
                                className="danger"
                                type="button"
                                disabled={
                                    this.state.isLoading ||
                                    voucher.num_children > 0
                                }
                                onClick={(event) => {
                                    this.onDelete(event, voucher);
                                }}
                            >
                                {t`Delete Voucher`}
                            </button>
                            <p className="button-group__help">
                                {voucher.num_children > 0
                                    ? t`This voucher can not be deleted since it has child codes.`
                                    : t`Delete this voucher code. This can not be undone.`}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    _rootState,
    ownProps,
) => {
    return {
        vouchers: _rootState.csr.voucherSearch,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const Vouchers = connect(
    mapStateToProps,
    mapDispatchToProps,
)(VouchersComponent);
