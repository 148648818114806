import * as t from "io-ts";
import { check, codecFromEnum, RegExpFromString } from "../models/utils";
import { decorateElements } from "../utils/react";
import { getPageSetting } from "../utils/settings";

enum LinkRewriteAction {
    DO_NOTHING = "DO_NOTHING", // Do not change the link
    REMOVE_LINK = "REMOVE_LINK", // Remove the link entirely (but preserve it's content).
    TARGET_BLANK = "TARGET_BLANK", // Set the default link target to open in a new window
    TARGET_TOP = "TARGET_TOP", // Set the default link target the topmost browsing context
    TARGET_SELF = "TARGET_SELF", // Set the default link target the current browsing context
}

const LinkRewriteRule = t.interface({
    pattern: RegExpFromString,
    action: codecFromEnum("LinkRewriteAction", LinkRewriteAction),
});

const LinkRewriteRuleset = t.interface({
    rules: t.array(LinkRewriteRule),
});

type LinkRewriteRule = t.TypeOf<typeof LinkRewriteRule>;
type LinkRewriteRuleset = t.TypeOf<typeof LinkRewriteRuleset>;

export const initLinks = async () => {
    const ruleset = getRewriteRuleset();

    // Add a rule to the beginning of the ruleset to make sure we always ignore
    // page anchor links.
    ruleset.rules.unshift({
        pattern: /^#/,
        action: LinkRewriteAction.DO_NOTHING,
    });

    // Add a fallback rule to the end of the ruleset, so that if a link doesn't
    // match any previous rules, we set its target to _top.
    ruleset.rules.push({
        pattern: /.*/,
        action: LinkRewriteAction.TARGET_TOP,
    });

    // Run our tweak code on all present and future links
    await decorateElements("a[href]", (elems: HTMLElement[]) => {
        for (const elem of elems) {
            if (elem instanceof HTMLAnchorElement) {
                configureLink(ruleset, elem);
            }
        }
    });
};

const getRewriteRuleset = (): LinkRewriteRuleset => {
    // Get link config from the page
    const rawRules = getPageSetting(
        "link-rewrite-rules",
        JSON.stringify({ rules: [] }),
    );
    try {
        return check(LinkRewriteRuleset.decode(rawRules));
    } catch (e) {
        return {
            rules: [],
        };
    }
};

const configureLink = (
    ruleset: LinkRewriteRuleset,
    link: HTMLAnchorElement,
): HTMLElement => {
    const href = link.href;
    for (const rule of ruleset.rules) {
        // Check if this rule applies to this link. If not, continue on to
        // the next rule.
        if (!href.match(rule.pattern)) {
            continue;
        }
        // Apply the rule and stop processing.
        return applyRule(rule, link);
    }
    return link;
};

const applyRule = (
    rule: LinkRewriteRule,
    link: HTMLAnchorElement,
): HTMLElement => {
    switch (rule.action) {
        // Deactivate the link by replacing it with a span.
        case LinkRewriteAction.REMOVE_LINK:
            // Create a span
            const span = document.createElement("span");
            span.id = link.id;
            span.className = link.className;
            // Move all the link's children from the link to the span
            for (const child of Array.from(link.childNodes)) {
                span.appendChild(child);
            }
            // Replace the link with the span
            link.replaceWith(span);
            return span;

        // If the link target isn't already set, set it to _blank
        case LinkRewriteAction.TARGET_BLANK:
            if (!link.target) {
                link.target = "_blank";
            }
            return link;

        // If the link target isn't already set, set it to _top
        case LinkRewriteAction.TARGET_TOP:
            if (!link.target) {
                link.target = "_top";
            }
            return link;

        // If the link target isn't already set, set it to _self
        case LinkRewriteAction.TARGET_SELF:
            if (!link.target) {
                link.target = "_self";
            }
            return link;

        // Don't change the link at all
        case LinkRewriteAction.DO_NOTHING:
        default:
            return link;
    }
};
