import React from "react";
import { connect } from "react-redux";
import { ILocation } from "../../../models/location.interfaces";
import { isZipLocation } from "../../../utils/guards";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { IDeliveryDatePredictionState } from "../reducers.interfaces";
import { preferredLocationSelector } from "../../common/selectors";
import { Dispatchers } from "../dispatchers";
import { Loaders } from "../loaders";
import { PredictedDeliveryDateStatus } from "../constants";

const PREDICTION_TTL = 3600 * 1000; // 1 hour

interface IOwnProps {}

interface IReduxProps {
    location: ILocation | null;
    prediction: IDeliveryDatePredictionState;
}

interface IDispatchProps {
    loadPredictedDeliveryDate: Loaders["loadPredictedDeliveryDate"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class PredictedDeliveryLoaderComponent extends React.Component<IProps, IState> {
    componentDidMount() {
        this.loadPredictedDeliveryDate();
    }

    componentDidUpdate() {
        this.loadPredictedDeliveryDate();
    }

    private loadPredictedDeliveryDate() {
        // If we don't have a ZIP code, we can't load a prediction.
        if (!this.props.location || !isZipLocation(this.props.location)) {
            console.log(
                "Location is not suitable for delivery date prediction",
            );
            return;
        }
        // If the prediction is already loading, just wait for it.
        if (
            this.props.prediction.status === PredictedDeliveryDateStatus.LOADING
        ) {
            console.log("Delivery date prediction is already loading");
            return;
        }
        // If we've already loaded data for this ZIP code and got back an
        // error, don't bother trying to load the data again.
        if (
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.ERROR &&
            this.props.location.zip === this.props.prediction.data.postcode
        ) {
            console.log("Delivery date prediction is already up to date");
            return;
        }
        // If we have a valid prediction, and it's not too old (1 hour TTL), don't
        // bother loading the data again.
        if (
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.LOADED &&
            this.props.location.zip === this.props.prediction.data.postcode &&
            this.props.prediction.data.created_datetime
        ) {
            const createdTimestamp = new Date(
                this.props.prediction.data.created_datetime,
            ).getTime();
            const expiresOnTimestamp = createdTimestamp + PREDICTION_TTL;
            const nowTimestamp = new Date().getTime();
            if (expiresOnTimestamp > nowTimestamp) {
                console.log("Delivery date prediction is already up to date");
                return;
            }
        }
        // We've passed all the gates, so fetch the prediction now.
        this.props.loadPredictedDeliveryDate(this.props.location.zip);
    }

    render() {
        return null;
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        location: preferredLocationSelector(rootState),
        prediction: rootState.checkout.data.predicted_delivery_date,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    return {
        loadPredictedDeliveryDate: loaders.loadPredictedDeliveryDate,
    };
};

export const PredictedDeliveryLoader = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PredictedDeliveryLoaderComponent);
