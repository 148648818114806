import { IProduct } from "../../models/catalogue.interfaces";
import { IAPIPrice } from "../../models/prices.interfaces";
import { IWebPageURL } from "../../models/nominals";
import { notEmpty } from "../../utils/functional";
import { sortProductsByPrice } from "../../utils/sorting";
import {
    ICompareTile,
    IChildFilter,
} from "../../models/product-compare.interfaces";

const getFlattenedVariants = (rootProducts: IProduct[]): IProduct[] => {
    const variants = rootProducts.reduce<IProduct[]>((memo, rp) => {
        if (rp.children.length <= 0) {
            return memo.concat([rp]);
        }
        return memo.concat(rp.children);
    }, []);
    return sortProductsByPrice(variants);
};

const isAvailableToBuy = (p: IProduct) => p.availability.is_available_to_buy;

export const getSelectedVariant = (
    tile: ICompareTile,
    selectedSize: string | null,
): IProduct | null => {
    // If the tile is set to only use a subset of variants, start with those. Otherwise,
    // use a flattened list of all of the variants for all of the root products.
    let possibleVariants =
        tile.childFilterProducts || getFlattenedVariants(tile.rootProducts);
    // Filter the possible variant list by the selected size.
    if (selectedSize !== null) {
        possibleVariants = possibleVariants.filter((child) => {
            const attr = child.attributes.option_size;
            return !!attr && attr.value === selectedSize;
        });
    }
    // If a both a categoryID and a productID are set, the productID is the “featured”
    // product. This is the product that must be used for pricing data. So, limit the
    // variant options to its children. BUT, if the “featured” isn't available to buy,
    // use the cheapest variant instead.
    if (
        tile.product_compare_tile.category_id &&
        tile.product_compare_tile.product_id
    ) {
        const featuredRoot = tile.rootProducts.find(
            (p) => p.id === tile.product_compare_tile.product_id,
        );
        if (featuredRoot) {
            const featuredRootAvailableChildren =
                featuredRoot.children.filter(isAvailableToBuy);
            const featuredRootAvailableChildrenIDs = new Set(
                featuredRootAvailableChildren.map((p) => p.id),
            );
            const possibleVariantsOfFeaturedRoot = possibleVariants.filter(
                (v) => featuredRootAvailableChildrenIDs.has(v.id),
            );
            if (possibleVariantsOfFeaturedRoot.length > 0) {
                possibleVariants = possibleVariantsOfFeaturedRoot;
            }
        }
    }
    // Filter the possible variant list down to only what's available to buy
    const availablePossibleVariants = possibleVariants.filter(isAvailableToBuy);
    // Return the cheapest available-to-buy variant if one exists, else the cheapest unavailable-to-buy product.
    return availablePossibleVariants[0] || possibleVariants[0] || null;
};

export const getShopNowURL = (
    tile: ICompareTile,
    selectedVariant: IProduct | null,
): IWebPageURL | null => {
    // If this is a category tile, try to specifically use a category page, even if a PDP exists.
    const categoryPage = (tile.rootProducts[0].alternate_links || []).find(
        (link) =>
            link.type === "ProductLinePage" ||
            link.type === "ProductLineCollectionPage" ||
            link.type === "GridPage",
    );
    const url =
        (tile.product_compare_tile.category_id !== null && categoryPage
            ? categoryPage.link
            : null) ||
        selectedVariant?.link ||
        tile.rootProducts[0].link ||
        null;
    return url;
};

export const getChildFilterVariants = (
    tile: ICompareTile,
    childFilter: IChildFilter,
) => {
    if (childFilter.behavior === "inclusive") {
        const variants = childFilter.variants
            .map((variant) => {
                return getFlattenedVariants(tile.rootProducts).filter(
                    (child) => {
                        const option_group = childFilter.option_group.code;
                        const attr = child.attributes[option_group];
                        if (childFilter.behavior === "inclusive") {
                            if (attr && attr.value === variant.name) {
                                return child;
                            }
                        }
                        return null;
                    },
                );
            })
            .filter(notEmpty);

        // flatten variants array
        return variants.reduce((array, el) => {
            return array.concat(el);
        }, []);
    }
    if (childFilter.behavior === "exclusive") {
        return getFlattenedVariants(tile.rootProducts).filter((child) => {
            const option_group = childFilter.option_group.code;
            const attr = child.attributes[option_group];

            let isExcluded = false;
            childFilter.variants.map((variant) => {
                if (attr && attr.value === variant.name) {
                    isExcluded = true;
                }
            });

            if (isExcluded) {
                return null;
            }

            return child;
        });
    }
    return null;
};

export const getVariantPrice = (variant: IProduct): IAPIPrice => {
    const price: IAPIPrice = {
        product: variant.url,
        quantity: 1,
        total: variant.price,
        unit: variant.price,
    };
    return price;
};
