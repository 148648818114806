import React from "react";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { ajax, CSRF_HEADER, getCSRFToken } from "../../../utils/ajax";
import { money } from "../../../utils/format";
import { IAddressCountry } from "../../../models/address.interfaces";
import { Actions } from "../actions";
import { getBasketTotal, isProtectedUser } from "../utils";
import { Address } from "./blocks/Address";
import { CustomerDetailsAddress } from "./CustomerDetailsAddress";
import { CustomerDetailsPhone } from "./CustomerDetailsPhone";
import { CustomerDetailsOrder } from "./CustomerDetailsOrder";
import { CustomerDetailsBasket } from "./CustomerDetailsBasket";
import { CustomerDetailsEdit } from "./CustomerDetailsEdit";
import {
    ICustomerDetails,
    IPhone,
    IBasket,
    IOrder,
    IAddress,
} from "../models.interfaces";

const getIDFromURL = (url: string) => {
    const matches = url.match(/[a-z]\/([0-9]+)\/$/);
    const id = matches ? matches[1] : "";
    return parseInt(id, 10);
};

interface IOwnProps {
    url: string;
}

interface IReduxProps {
    countries: IAddressCountry[];
}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    details: ICustomerDetails | null;
    addresses: IAddress[];
    phones: IPhone[];
    orders: IOrder[];
    baskets: IBasket[];
}

class CustomerDetailsComponent extends React.Component<IProps, IState> {
    state: IState = {
        details: null,
        addresses: [],
        phones: [],
        orders: [],
        baskets: [],
    };

    componentDidMount() {
        this.loadUserData();
    }

    private async loadUserData() {
        await Promise.all([
            this.loadDetails(),
            this.loadAddresses(),
            this.loadPhones(),
            this.loadOrders(),
            this.loadBaskets(),
        ]);
    }

    private async loadDetails() {
        const resp = await ajax.get(this.props.url);
        this.setState({
            ...this.state,
            details: resp.body as ICustomerDetails,
        });
    }

    private async loadAddresses() {
        const userID = getIDFromURL(this.props.url);
        const resp = await ajax.get("/api/csr/user-addresses/").query({
            user: userID,
        });
        this.setState({
            addresses: resp.body as IAddress[],
        });
        return null;
    }

    private async loadPhones() {
        const userID = getIDFromURL(this.props.url);
        const resp = await ajax.get("/api/csr/user-phones/").query({
            user: userID,
        });
        this.setState({
            phones: resp.body as IPhone[],
        });
    }

    private async loadOrders() {
        const userID = getIDFromURL(this.props.url);
        const resp = await ajax.get("/api/csr/user-orders/").query({
            user: userID,
        });
        this.setState({
            orders: resp.body as IOrder[],
        });
    }

    private async loadBaskets() {
        const userID = getIDFromURL(this.props.url);
        const resp = await ajax.get("/api/csr/user-baskets/").query({
            owner: userID,
        });
        this.setState({
            baskets: resp.body as IBasket[],
        });
    }

    private readonly onAddNewAddress = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        const component = (
            <CustomerDetailsAddress
                address={{
                    user: this.props.url,
                }}
            />
        );
        this.props.actions.pushViewStack("Add New Address", component);
    };

    private readonly onAddNewPhone = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        const component = (
            <CustomerDetailsPhone
                phone={{
                    user: this.props.url,
                }}
            />
        );
        this.props.actions.pushViewStack("Add New Phone", component);
    };

    private readonly onResetPassword = async (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        if (
            !confirm(
                `Are you sure you want to send a password reset email to ${
                    this.state.details ? this.state.details.email : ""
                }?`,
            )
        ) {
            return;
        }
        try {
            await ajax
                .post(`${this.props.url}reset_password/`)
                .set(CSRF_HEADER, await getCSRFToken());
        } catch (err) {
            console.error(err);
            alert(
                `Error! Could not send password reset email to ${
                    this.state.details ? this.state.details.email : ""
                }.`,
            );
        }
    };

    private readonly onStartAssisting = async (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        try {
            await this.props.actions.startAssistingCustomer(this.props.url);
            this.loadUserData();
        } catch (err) {
            console.error(err);
            alert("Error! Could not start assisting customer.");
        }
    };

    private readonly onStopAssisting = async (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        try {
            await this.props.actions.stopAssistingCustomer(this.props.url);
            this.loadUserData();
        } catch (err) {
            console.error(err);
            alert("Error! Could not stop assisting customer.");
        }
    };

    private readonly onEditDetails = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (!this.state.details) {
            return;
        }
        const component = (
            <CustomerDetailsEdit
                customer={{
                    ...this.state.details,
                    url: this.props.url,
                }}
            />
        );
        this.props.actions.pushViewStack("Edit Customer", component);
    };

    private readonly onEditPhone = (
        phone: IPhone,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        const component = <CustomerDetailsPhone phone={phone} />;
        this.props.actions.pushViewStack("Edit Phone", component);
    };

    private readonly onEditAddress = (
        address: IAddress,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        const component = <CustomerDetailsAddress address={address} />;
        this.props.actions.pushViewStack("Edit Address", component);
    };

    private readonly onDeleteAddress = async (
        address: IAddress,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        if (!confirm("Are you sure you want to delete this address?")) {
            return;
        }
        await ajax.del(address.url).set(CSRF_HEADER, await getCSRFToken());
        await this.loadUserData();
    };

    private readonly onDeletePhone = async (
        phone: IPhone,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        if (!confirm("Are you sure you want to delete this phone number?")) {
            return;
        }
        await ajax.del(phone.url).set(CSRF_HEADER, await getCSRFToken());
        await this.loadUserData();
    };

    private readonly onViewOrder = async (
        order: IOrder,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        const onReloadRequest = async () => {
            const resp = await ajax.get(order.url);
            this.props.actions.replaceTopView(
                "Order Overview",
                <CustomerDetailsOrder
                    requestReload={onReloadRequest}
                    {...(resp.body as IOrder)}
                />,
            );
        };
        this.props.actions.pushViewStack(
            "Order Overview",
            <CustomerDetailsOrder requestReload={onReloadRequest} {...order} />,
        );
    };

    private readonly onViewBasket = (
        basket: IBasket,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        const component = <CustomerDetailsBasket {...basket} />;
        this.props.actions.pushViewStack("Basket Details", component);
    };

    private renderPhones() {
        if (this.state.phones.length <= 0) {
            return (
                <tr>
                    <td colSpan={2}>
                        <em>No phones found.</em>
                    </td>
                </tr>
            );
        }
        return this.state.phones.map((phone) => {
            const isProtected =
                !this.state.details || isProtectedUser(this.state.details);
            return (
                <tr key={phone.url}>
                    <td>{phone.phone_number}</td>
                    <td className="button-group">
                        <button
                            onClick={this.onEditPhone.bind(this, phone)}
                            disabled={isProtected}
                        >
                            Edit
                        </button>
                        <button
                            className="danger"
                            onClick={this.onDeletePhone.bind(this, phone)}
                            disabled={isProtected}
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        });
    }

    private renderAddresses() {
        if (this.state.addresses.length <= 0) {
            return (
                <tr>
                    <td colSpan={4}>
                        <em>No addresses found.</em>
                    </td>
                </tr>
            );
        }
        return this.state.addresses.map((addr) => {
            const isProtected =
                !this.state.details || isProtectedUser(this.state.details);
            return (
                <tr key={addr.url}>
                    <td>
                        <Address {...addr} />
                    </td>
                    <td>{addr.is_default_for_billing ? "Yes" : "No"}</td>
                    <td>{addr.is_default_for_shipping ? "Yes" : "No"}</td>
                    <td className="button-group">
                        <button
                            onClick={this.onEditAddress.bind(this, addr)}
                            disabled={isProtected}
                        >
                            Edit
                        </button>
                        <button
                            className="danger"
                            onClick={this.onDeleteAddress.bind(this, addr)}
                            disabled={isProtected}
                        >
                            Delete
                        </button>
                    </td>
                </tr>
            );
        });
    }

    private renderOrders() {
        if (this.state.orders.length <= 0) {
            return (
                <tr>
                    <td colSpan={5}>
                        <em>No orders found.</em>
                    </td>
                </tr>
            );
        }
        return this.state.orders.map((order) => {
            const placed = new Date(order.date_placed).toLocaleString();
            return (
                <tr key={order.number}>
                    <td>{order.number}</td>
                    <td>{order.status}</td>
                    <td>{money(order.total_excl_tax)}</td>
                    <td>{money(order.total_incl_tax)}</td>
                    <td>{placed}</td>
                    <td className="button-group">
                        <a onClick={this.onViewOrder.bind(this, order)}>View</a>
                    </td>
                </tr>
            );
        });
    }

    private renderBaskets() {
        if (this.state.baskets.length <= 0) {
            return (
                <tr>
                    <td colSpan={5}>
                        <em>No baskets found.</em>
                    </td>
                </tr>
            );
        }
        return this.state.baskets.map((basket) => {
            const created = basket.date_created
                ? new Date(basket.date_created).toLocaleString()
                : "-";
            const merged = basket.date_merged
                ? new Date(basket.date_merged).toLocaleString()
                : "-";
            const submitted = basket.date_submitted
                ? new Date(basket.date_submitted).toLocaleString()
                : "-";
            const totalExclTax = getBasketTotal(basket);
            return (
                <tr key={basket.id}>
                    <td>{basket.encoded_basket_id}</td>
                    <td>{basket.status}</td>
                    <td>{money(totalExclTax)}</td>
                    <td>{created}</td>
                    <td>{merged}</td>
                    <td>{submitted}</td>
                    <td className="button-group">
                        <a onClick={this.onViewBasket.bind(this, basket)}>
                            View
                        </a>
                    </td>
                </tr>
            );
        });
    }

    render() {
        if (!this.state.details) {
            return null;
        }
        const phones = this.renderPhones();
        const addresses = this.renderAddresses();
        const orders = this.renderOrders();
        const baskets = this.renderBaskets();
        const groups = this.state.details.groups || [];
        const assistor = this.state.details.currently_assisted_by;
        const currentAssistor = assistor
            ? `${assistor.first_name} ${assistor.last_name} <${assistor.email}>`
            : null;
        const isProtected = isProtectedUser(this.state.details);
        return (
            <div className="csr-customer-details">
                <div className="csr-customer-details__basic">
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>Basic Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>First Name</th>
                                <td>{this.state.details.first_name || "-"}</td>
                            </tr>
                            <tr>
                                <th>Last Name</th>
                                <td>{this.state.details.last_name || "-"}</td>
                            </tr>
                            <tr>
                                <th>Username</th>
                                <td>{this.state.details.username || "-"}</td>
                            </tr>
                            <tr>
                                <th>Email Address</th>
                                <td>{this.state.details.email || "-"}</td>
                            </tr>
                            <tr>
                                <th>Groups</th>
                                <td>
                                    {groups.length <= 0
                                        ? "-"
                                        : groups.map((name) => {
                                              return (
                                                  <span
                                                      className="csr-customer-group"
                                                      key={name}
                                                  >
                                                      {name}
                                                  </span>
                                              );
                                          })}
                                </td>
                            </tr>
                            <tr>
                                <th>Currently being assisted by</th>
                                <td>{currentAssistor || "-"}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="button-group">
                        <div>
                            <button
                                onClick={() => {
                                    this.loadUserData();
                                }}
                            >
                                Refresh&nbsp;Data
                            </button>
                            <a href={this.state.details.dashboard_link}>
                                View&nbsp;Customer&nbsp;in&nbsp;Dashboard
                            </a>
                        </div>
                        <div>
                            <button
                                onClick={this.onResetPassword}
                                disabled={
                                    !this.state.details.email || isProtected
                                }
                            >
                                Send&nbsp;Password&nbsp;Reset&nbsp;Email
                            </button>
                            <button
                                onClick={this.onEditDetails}
                                disabled={isProtected}
                            >
                                Edit&nbsp;Customer
                            </button>
                        </div>
                    </div>
                </div>
                <div className="csr-customer-details__phones">
                    <table>
                        <thead>
                            <tr>
                                <th>Phone Number</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{phones}</tbody>
                    </table>
                    <div className="button-group">
                        <button
                            onClick={this.onAddNewPhone}
                            disabled={isProtected}
                        >
                            Add&nbsp;New&nbsp;Phone
                        </button>
                    </div>
                </div>
                <div className="csr-customer-details__assistance">
                    <h2>Assistance Log</h2>
                    <p>Only the latest 10 log entries are shown.</p>
                    <table>
                        <thead>
                            <tr>
                                <th>Customer Service Representative</th>
                                <th>Description</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.details.assistance_log.length === 0 ? (
                                <tr>
                                    <td colSpan={3}>
                                        <em>
                                            No log entries found for this
                                            customer.
                                        </em>
                                    </td>
                                </tr>
                            ) : null}
                            {this.state.details.assistance_log.map((entry) => {
                                return (
                                    <tr key={entry.id}>
                                        <td>
                                            {entry.csr.first_name}{" "}
                                            {entry.csr.last_name} &lt;
                                            {entry.csr.email}&gt;
                                        </td>
                                        <td>{entry.message}</td>
                                        <td>
                                            {new Date(
                                                entry.action_time,
                                            ).toLocaleString()}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                    <div className="button-group">
                        <div>
                            <button
                                onClick={this.onStartAssisting}
                                disabled={
                                    this.state.details.is_assisting ||
                                    isProtected
                                }
                            >
                                Start&nbsp;Assisting&nbsp;Customer
                            </button>
                            <button
                                onClick={this.onStopAssisting}
                                disabled={!this.state.details.is_assisting}
                            >
                                Stop&nbsp;Assisting&nbsp;Customer
                            </button>
                        </div>
                    </div>
                </div>
                <div className="csr-customer-details__addresses">
                    <h2>Addresses</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Address</th>
                                <th>Default for billing</th>
                                <th>Default for shipping</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{addresses}</tbody>
                    </table>
                    <div className="button-group">
                        <button
                            onClick={this.onAddNewAddress}
                            disabled={isProtected}
                        >
                            Add&nbsp;New&nbsp;Address
                        </button>
                    </div>
                </div>
                <div className="csr-customer-details__orders">
                    <h2>Orders</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Order Number</th>
                                <th>Status</th>
                                <th>Total (excl. tax)</th>
                                <th>Total (incl. tax)</th>
                                <th>Date Placed</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{orders}</tbody>
                    </table>
                </div>
                <div className="csr-customer-details__baskets">
                    <h2>Baskets</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Basket ID</th>
                                <th>Status</th>
                                <th>Total (excl. tax)</th>
                                <th>Date Created</th>
                                <th>Date Merged</th>
                                <th>Date Submitted</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{baskets}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        countries: rootState.csr.countries,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const CustomerDetails = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerDetailsComponent);
