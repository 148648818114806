/* eslint-disable complexity */
import React from "react";
import classNames from "classnames";
import {
    IBooleanField,
    IChoiceField,
    IRadioChoiceField,
    IPhoneNumberField,
    IFuzzyDurationField,
    INumberField,
    ITextBoxField,
    IStringField,
    IFieldSet,
    IValueSet,
} from "../models/formFields.interfaces";
import { GenericErrorSet, ErrorFieldValue } from "../models/formFields";
import { assertNever } from "../utils/never";
import { notEmpty } from "../utils/functional";
import { FormCheckbox } from "./FormCheckbox";
import { FormSelect } from "./FormSelect";
import { FormRadioSelect } from "./FormRadioSelect";
import { FormPhoneNumber } from "./FormPhoneNumber";
import { FormFuzzyDurationSelect } from "./FormFuzzyDurationSelect";
import { FormNumber } from "./FormNumber";
import { FormTextbox } from "./FormTextbox";
import { FormInput } from "./FormInput";

import styles from "./AutoFields.module.scss";

export type InputElements =
    | HTMLInputElement
    | HTMLSelectElement
    | HTMLTextAreaElement;

interface IProps<T extends string> {
    heading: string;

    fieldOrder: readonly T[];
    fields: IFieldSet<T>;

    disabled?: boolean;

    values?: Partial<IValueSet<T>>;

    errors?: GenericErrorSet<T>;
    showErrorMessages?: boolean;

    onChange: (name: T, value: string) => void;
    onValidStateChange: (fieldName: T, errorMessages: string[]) => void;
}

interface IState {}

export class AutoFields<T extends string> extends React.Component<
    IProps<T>,
    IState
> {
    private readonly onChange = <ElementType extends InputElements>(
        event: React.FormEvent<ElementType>,
    ) => {
        const name = event.currentTarget.name as T;
        this.props.onChange(name, event.currentTarget.value);
    };

    private getBasicFieldProps(name: T, errors: ErrorFieldValue) {
        return {
            id: `id_${name}`,
            name: name,
            key: name,
            errors: [errors].filter(notEmpty).flat(),
            disabled: this.props.disabled,
            onChange: this.onChange,
            onValidStateChange: this.props.onValidStateChange,
            showErrorMessages: this.props.showErrorMessages,
        };
    }

    private buildCheckboxField(
        name: T,
        field: IBooleanField<T>,
        value: boolean,
        errors: ErrorFieldValue,
    ) {
        return (
            <FormCheckbox
                {...field}
                {...this.getBasicFieldProps(name, errors)}
                checked={value}
            />
        );
    }

    private buildChoiceField(
        name: T,
        field: IChoiceField<T>,
        value: string,
        errors: ErrorFieldValue,
    ) {
        // Normalize any falsy (undefined, null, 0, etc) given value to be an empty string
        value = value || "";

        const choices = [...(field.choices || [])];
        const values = choices.map((opt) => {
            return opt.value;
        });

        const prependOption = (optionLabel: string, optionValue: string) => {
            values.unshift(optionValue);
            choices.unshift({
                value: optionValue || "",
                label: optionLabel || "",
            });
        };

        // Make sure there is a blank option if the field is not required.
        if (!field.required && values.indexOf("") === -1) {
            prependOption("", "");
        }

        // If the current value isn't an option (maybe the current value is blank on initial load), add the value until the change it.
        if (values.indexOf(value) === -1) {
            prependOption("", value);
        }

        return (
            <FormSelect
                className={styles.formSelect}
                labelCSSClass={styles.formLabel}
                wrapperCSSClass={styles.formSelectWrapper}
                {...field}
                {...this.getBasicFieldProps(name, errors)}
                choices={choices}
                value={value}
            />
        );
    }

    private buildRadioChoiceField(
        name: T,
        field: IRadioChoiceField<T>,
        value: string,
        errors: ErrorFieldValue,
    ) {
        return (
            <FormRadioSelect
                {...field}
                {...this.getBasicFieldProps(name, errors)}
                value={value}
            />
        );
    }

    private buildNumberField(
        name: T,
        field: INumberField<T>,
        value: string,
        errors: ErrorFieldValue,
    ) {
        const labelClasses = classNames({
            [styles.formLabel]: field.required,
            [styles.optionalFormLabel]: !field.required,
        });
        return (
            <FormNumber
                className={styles.formField}
                labelCSSClass={labelClasses}
                wrapperCSSClass={styles.formWrapper}
                {...field}
                {...this.getBasicFieldProps(name, errors)}
                value={value}
            />
        );
    }

    private buildTextBox(
        name: T,
        field: ITextBoxField<T>,
        value: string,
        errors: ErrorFieldValue,
    ) {
        return (
            <FormTextbox
                {...field}
                {...this.getBasicFieldProps(name, errors)}
                value={value}
            />
        );
    }

    private buildFuzzyDurationField(
        name: T,
        field: IFuzzyDurationField<T>,
        value: string,
        errors: ErrorFieldValue,
    ) {
        return (
            <FormFuzzyDurationSelect
                {...field}
                {...this.getBasicFieldProps(name, errors)}
                value={value}
            />
        );
    }

    private buildPhoneField(
        name: T,
        field: IPhoneNumberField<T>,
        value: string,
        errors: ErrorFieldValue,
    ) {
        return (
            <FormPhoneNumber
                className={styles.formField}
                labelCSSClass={styles.formLabel}
                wrapperCSSClass={styles.formWrapper}
                {...field}
                {...this.getBasicFieldProps(name, errors)}
                value={value}
            />
        );
    }

    private buildInput(
        name: T,
        field: IStringField<T>,
        value: string,
        errors: ErrorFieldValue,
    ) {
        const labelClasses = classNames({
            [styles.formLabel]: field.required,
            [styles.optionalFormLabel]: !field.required,
        });
        return (
            <FormInput
                className={styles.formField}
                labelCSSClass={labelClasses}
                wrapperCSSClass={styles.formWrapper}
                {...field}
                {...this.getBasicFieldProps(name, errors)}
                value={value}
            />
        );
    }

    private buildField(name: T) {
        const config = { ...this.props.fields[name] };
        const value = this.props.values ? this.props.values[name] : null;
        const errors = this.props.errors ? this.props.errors[name] : undefined;

        // Add required field validation
        if (config.required) {
            if (!config.validation) {
                config.validation = [];
            }
            const validation = [config.validation].flat();
            if (!validation.includes("required")) {
                config.validation = [...validation, "required"];
            }
        }

        switch (config.type) {
            case undefined:
            case null:
                return null;

            case "boolean":
            case "checkbox":
            case "radio":
                return this.buildCheckboxField(
                    name,
                    config,
                    value as boolean,
                    errors,
                );

            case "choice":
                return this.buildChoiceField(
                    name,
                    config,
                    value as string,
                    errors,
                );

            case "radio-choice":
                return this.buildRadioChoiceField(
                    name,
                    config,
                    value as string,
                    errors,
                );

            case "integer":
            case "number":
                return this.buildNumberField(
                    name,
                    config,
                    value as string,
                    errors,
                );

            case "textbox":
                return this.buildTextBox(name, config, value as string, errors);

            case "fuzzy-duration":
                return this.buildFuzzyDurationField(
                    name,
                    config,
                    value as string,
                    errors,
                );

            case "phone":
                return this.buildPhoneField(
                    name,
                    config,
                    value as string,
                    errors,
                );

            case "email":
            case "date":
            case "datetime":
            case "string":
            case "text":
            case "tel":
            case "password":
                return this.buildInput(name, config, value as string, errors);

            default:
                assertNever(config);
        }

        return null;
    }

    public render() {
        const fields = this.props.fieldOrder
            .filter((name) => {
                return !!this.props.fields[name];
            })
            .map((name) => {
                return this.buildField(name);
            });
        if (!fields.length) {
            return null;
        }
        return (
            <>
                <h3 className={styles.heading}>{this.props.heading}</h3>
                {fields}
            </>
        );
    }
}
