import React from "react";
import { captureException } from "@sentry/browser";

interface IErrorInfo {
    componentStack: string;
}

interface IProps {
    onError?: (error: Error, info: IErrorInfo) => void;
    getErrorMessage?: (error: Error, info: IErrorInfo) => JSX.Element | string;
    children: React.ReactNode;
}

interface IState {
    details: null | {
        error: Error;
        info: IErrorInfo;
    };
}

export class ErrorBoundary extends React.PureComponent<IProps, IState> {
    public state: IState = {
        details: null,
    };

    componentDidCatch(error: Error, info: IErrorInfo) {
        this.setState({
            details: {
                error: error,
                info: info,
            },
        });
        captureException(error);
        if (this.props.onError) {
            this.props.onError(error, info);
        }
    }

    render() {
        if (this.state.details) {
            let details: JSX.Element | string =
                "An error occurred while attempting to display this content.";
            if (this.props.getErrorMessage) {
                try {
                    details = this.props.getErrorMessage(
                        this.state.details.error,
                        this.state.details.info,
                    );
                } catch (e) {
                    console.error(e);
                }
            }
            return <div className="react-error-boundary">{details}</div>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
