import React from "react";
import { connect } from "react-redux";
import { IUser } from "../../../models/user.interfaces";
import { urls } from "../../../utils/urls";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { IReduxState } from "../reducers.interfaces";
import { Loaders } from "../loaders";
import { Dispatchers } from "../dispatchers";
import { ICheckoutHTTPError, Actions } from "../actions";
import { defaults } from "../defaults";
import * as signals from "../../signals";

interface IOwnProps {
    onCheckoutError: (err: ICheckoutHTTPError) => void;
    hasPaymentMethodError: boolean;
}

interface IReduxProps extends IReduxState {
    user: IUser | null;
}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class PaymentChildFrameListenerComponent extends React.Component<
    IProps,
    IState
> {
    private readonly messageHandler: (this: Window, ev: MessageEvent) => void;

    constructor(props: IProps) {
        super(props);
        this.messageHandler = (event) => {
            this.onChildFrameMessageReceived(event);
        };
    }

    componentDidMount() {
        // Attach message event listener
        window.addEventListener("message", this.messageHandler, false);
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.messageHandler);
    }

    componentDidUpdate(prevProps: IProps) {
        const prevDeclined =
            prevProps.data.payment_states &&
            prevProps.data.payment_states.order_status === "Payment Declined";
        const currDeclined =
            this.props.data.payment_states &&
            this.props.data.payment_states.order_status === "Payment Declined";
        // Trigger onPaymentDecline, but not if we already did trigger it.
        if (currDeclined && !prevDeclined) {
            signals.checkout.onPaymentDecline.emit();
        }
    }

    private onChildFrameMessageReceived(event: MessageEvent) {
        // Message is sent from a hidden iframe used to submit Cybersource data
        // See tsi-common/src/tsicommon/templates/oscar/checkout/payment-redirect.html
        const messageType: string = event.data.type;
        switch (messageType) {
            case "TSI_CHECKOUT_PAYMENT_FRAME_LOADED":
                this.props.actions
                    .completeOrderPayment(this.props.user, this.props)
                    .catch((err) => {
                        // In case of an error, reload the page to display it.
                        this.props.onCheckoutError(err);
                        window.scrollTo({
                            top: 0,
                        });
                        // turning off page reload so we don't lose cc error info
                        // window.location.reload();
                        // TODO should this be conditional?
                    });
                break;
            case "TSI_CHECKOUT_PAYMENT_TIMEOUT":
                // if payment is pending and no error messages shown
                if (
                    this.props.data.payment_states &&
                    this.props.data.payment_states.order_status === "Pending" &&
                    !this.props.hasPaymentMethodError
                ) {
                    console.log(
                        "checkout is stuck in pending, redirecting to order-pending page",
                    );
                    urls.navigateTo("checkout-order-pending");
                }
                break;
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout || defaults;
    return {
        user: rootState.common.user,
        ...state,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    const actions = new Actions(loaders, dispatchers);
    return {
        actions: actions,
    };
};

export const PaymentChildFrameListener = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentChildFrameListenerComponent);
