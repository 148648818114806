import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React, { useState } from "react";
import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";
import { templates, RichNavigationBannerContentAssembly } from "@reactivated";
import { BannerBlock } from "../../../blocks/BannerBlock";
import { RichNavigationLinkCollection } from "../RichNavigationLinkCollection";
import { RichNavigationLink } from "../RichNavigationLink";
import { useCurrentBreakpoint } from "../../../utils/hooks";

import styles from "./index.module.scss";

export const bannerContentAssemblyComponents: StreamFieldBlockMap<RichNavigationBannerContentAssembly> =
    {
        banner: BannerBlock,
    };

export const RichNavigation = (
    props: templates.RichNavigationTemplate["rich_navigation"],
) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const viewport = useCurrentBreakpoint();
    if (!props) {
        return null;
    }
    // For now, the React-powered RichNavigation only appears on Desktop -@zlebar
    if (viewport.belowMobile) {
        return null;
    }
    return (
        <NavigationMenu.Root
            className={styles.root}
            onValueChange={(val: string) =>
                val ? setIsOpen(true) : setIsOpen(false)
            }
        >
            <NavigationMenu.List className={styles.list}>
                {props.content &&
                    props.content.value.map((block, i) => {
                        switch (block.type) {
                            case "rich_nav_link_collection":
                                return (
                                    <NavigationMenu.Item key={i}>
                                        <RichNavigationLinkCollection
                                            collection={block}
                                            banner_content={
                                                props.banner_content &&
                                                !viewport.belowMobile
                                                    ? props.banner_content.value
                                                    : null
                                            }
                                        />
                                    </NavigationMenu.Item>
                                );
                            case "link":
                                return (
                                    <NavigationMenu.Item key={i}>
                                        <RichNavigationLink {...block.value} />
                                    </NavigationMenu.Item>
                                );
                            default:
                                return <></>;
                        }
                    })}
            </NavigationMenu.List>
            {props.banner_content && viewport.belowMobile && (
                <StreamField
                    components={bannerContentAssemblyComponents}
                    value={props.banner_content.value}
                />
            )}
            {isOpen && <div className={styles.overlay}></div>}
        </NavigationMenu.Root>
    );
};
