import React from "react";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";

dynamicPlaceComponent(
    '[data-place-react="tempur-love-grid"]',
    async (containerElem) => {
        const { TempurLoveGrid } = await import(
            "tsi-common-react/src/apps/tempur-love-grid/components/TempurLoveGrid"
        );
        const maybeFiltered = containerElem.dataset.filterBy;
        const baseUrl = containerElem.dataset.apiUrl;
        if (!baseUrl) {
            throw new Error("No base API url provided!");
        }
        return (
            <TempurLoveGrid
                baseApiUrl={baseUrl}
                filterBy={maybeFiltered ? parseInt(maybeFiltered, 10) : null}
            />
        );
    },
);
