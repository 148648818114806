import React from "react";
import classNames from "classnames";
import { IFormHelpLink } from "../models/formFields.interfaces";
import { Link } from "../common/Link";
import { AbstractFormComponent } from "./AbstractFormComponent";

export class FormHelpLink extends AbstractFormComponent<
    IFormHelpLink,
    Record<string, never>
> {
    protected buildHelpText() {
        const helpTextClasses = classNames({
            "form__help-text": true,
            [this.props.helpTextClass || ""]: !!this.props.helpTextClass,
        });

        return (
            <p className={helpTextClasses}>
                <Link
                    href={this.props.href}
                    target={this.props.target || "_top"}
                >
                    {this.props.helpText}
                </Link>
            </p>
        );
    }
}
