import React from "react";
import { t } from "ttag";

interface IProps {}

interface IState {}

export class WriteReviewFormDisclaimers extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        return (
            <div className="write-review-form__disclaimer">
                <h4>{t`By submitting, I agree with the following:`}</h4>
                <ul className="write-review-form__disclaimer-list">
                    <li>
                        <a
                            className="write-review-form__disclaimer-link"
                            href="http://www.powerreviews.com/legal/privacy_policy_en_US.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t`Privacy Policy`}
                        </a>
                    </li>
                    <li>{t`I am age 13 or older`}</li>
                </ul>
            </div>
        );
    }
}
