import { createSelector } from "@reduxjs/toolkit";
import {
    IProductUUID,
    IReviewsProductID,
    IReviewsProductTypeID,
    IFormUUID,
} from "../../models/nominals";
import {
    IReviewsProduct,
    IReviewsProductVariantChoices,
} from "../../models/reviews.interfaces";
import {
    IWriteReviewFormState,
    IReviewFormProductTemplatePair,
    ICustomerInformationState,
    IReduxState,
} from "./reducers.interfaces";
import { WriteReviewFormMode, NOT_SURE_VARIANT_CHOICE_SLUG } from "./constants";
import {
    defaultReviewTemplate,
    getDefaultWriteReviewFormState,
} from "./defaults";

export const getWriteReviewFormHTMLID = (formUUID: IFormUUID): string => {
    return `write-review-form-${formUUID}`;
};

export const getProductByUUID = (
    state: IReduxState,
    props: {
        productUUID: IProductUUID;
    },
): IReviewsProduct | null => {
    return (
        state.data.products.find((p) => p.uuid === props.productUUID) ?? null
    );
};

export const getProductByID = (
    state: IReduxState,
    props: {
        productID: IReviewsProductID;
    },
): IReviewsProduct | null => {
    return state.data.products.find((p) => p.id === props.productID) ?? null;
};

export const getWriteReviewFormState = (
    state: IReduxState,
    props: {
        formUUID: IFormUUID;
    },
): IWriteReviewFormState => {
    return (
        state.ui.writeReviewForms[String(props.formUUID)] ??
        getDefaultWriteReviewFormState()
    );
};

export const getWriteReviewFormStateMulti = (
    state: IReduxState,
    props: {
        parentUUID: IProductUUID;
    },
) => {
    // find all review formstates for given UUID prefix
    const formsForUUID = Object.entries(state.ui.writeReviewForms).filter(
        ([u, _]) => u.includes(String(props.parentUUID)),
    );
    return Object.fromEntries(formsForUUID);
};

export const getAllFormSubmittedForParentUUID = createSelector(
    [getWriteReviewFormStateMulti],
    (formStates): boolean => {
        return Object.entries(formStates).every(
            ([_, f]) => f.mode === WriteReviewFormMode.THANK_YOU_OPEN,
        );
    },
);

export const getCustomerInformationState = (
    state: IReduxState,
): ICustomerInformationState => {
    return state.ui.customerInformation;
};

export const getWriteReviewTemplates = (
    state: IReduxState,
): IReviewFormProductTemplatePair[] => {
    return state.data.writeReviewTemplates;
};

export const getAllRegisteredWriteReviewForms = (
    state: IReduxState,
): { [formUUID: string]: IWriteReviewFormState } => {
    return state.ui.writeReviewForms;
};

export const getWriteReviewFormMode = createSelector(
    [getWriteReviewFormState],
    (formState): WriteReviewFormMode => {
        return formState.mode;
    },
);

export const getWriteReviewFormSelectedProductID = createSelector(
    [getWriteReviewFormState],
    (formState): IReviewsProductID | null => {
        return formState.selectedProductID;
    },
);

export const getWriteReviewFormProducts = (
    state: IReduxState,
    props: {
        displayedProductTypeIDWhitelist?: IReviewsProductTypeID[];
    },
): IReviewsProduct[] => {
    if (!props.displayedProductTypeIDWhitelist) {
        return state.data.products;
    }
    return state.data.products.filter((p) => {
        return (
            p.product_type &&
            props.displayedProductTypeIDWhitelist!.includes(p.product_type)
        );
    });
};

export const getWriteReviewFormSelectedProduct = createSelector(
    [getWriteReviewFormProducts, getWriteReviewFormSelectedProductID],
    (products, selectedProductID): IReviewsProduct | null => {
        const selectedProduct = products.find((p) => {
            return p.id === selectedProductID;
        });
        return selectedProduct ?? null;
    },
);

export const getWriteReviewFormSelectedProductTypeID = createSelector(
    [getWriteReviewFormSelectedProduct],
    (product): IReviewsProductTypeID | null => {
        return product ? product.product_type : null;
    },
);

export const getWriteReviewFormVariantChoices = createSelector(
    [getWriteReviewFormSelectedProduct],
    (product): IReviewsProductVariantChoices[] => {
        if (product && product.variants.length) {
            return [null, NOT_SURE_VARIANT_CHOICE_SLUG, ...product.variants];
        } else {
            return [];
        }
    },
);

export const getWriteReviewFormSelectedVariantSlug = createSelector(
    [getWriteReviewFormVariantChoices, getWriteReviewFormState],
    (variants, formState): string => {
        if (variants.length <= 0) {
            return "";
        }
        const slugs = variants.map((v: IReviewsProductVariantChoices) => {
            if (v === NOT_SURE_VARIANT_CHOICE_SLUG) {
                return NOT_SURE_VARIANT_CHOICE_SLUG;
            } else if (v !== null) {
                return v.slug;
            }
            return "";
        });
        return slugs.includes(formState.selectedVariant)
            ? formState.selectedVariant
            : slugs[0];
    },
);

export const getWriteReviewFormSelectedVariant = createSelector(
    [getWriteReviewFormVariantChoices, getWriteReviewFormSelectedVariantSlug],
    (variants, slug): IReviewsProductVariantChoices => {
        return (
            variants.find(
                (v: IReviewsProductVariantChoices) =>
                    v && v !== NOT_SURE_VARIANT_CHOICE_SLUG && v.slug === slug,
            ) ?? null
        );
    },
);

export const getWriteReviewTemplateForForm = createSelector(
    [getWriteReviewTemplates, getWriteReviewFormSelectedProductID],
    (templatePairs, selectedProductID): IReviewFormProductTemplatePair => {
        const formTemplate = templatePairs.find(
            ({ productID }) => productID === selectedProductID,
        );
        return (
            formTemplate ?? {
                productID: null,
                template: defaultReviewTemplate,
            }
        );
    },
);
