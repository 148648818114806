import React from "react";
import Flickity from "flickity-imagesloaded";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { t } from "ttag";
import { IReview } from "../../../models/reviews.interfaces";
import { parseFacetValues } from "../../../utils/reviews";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { defaultState } from "../defaults";
import { CarouselReview } from "./CarouselReview";
import { ReviewsPageLink } from "./ReviewsPageLink";
import iconGalleryArrowLeft from "../../../../img/icons/gallery-arrow-left.svg";
import iconGalleryArrowRight from "../../../../img/icons/gallery-arrow-right.svg";

interface IOwnProps {
    showSideDate: boolean;
    enablePDPLinks: boolean;
    onLoadMore: () => void;
    maxKeywordsNumber: number;
    starHasStroke?: boolean;
}

interface IReduxProps {
    reviews: IReview[];
    hasMore: boolean;
    keywords: string;
}

interface IDispatchProps {
    clearFilters: Dispatchers["clearFilters"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    displayTilesNumber: number;
    currentIndex: number;
    closeAllReviewColumns: boolean;
}

class CarouselReviewListComponent extends React.PureComponent<IProps, IState> {
    state: IState = {
        displayTilesNumber: 3,
        currentIndex: 0,
        closeAllReviewColumns: true,
    };

    private readonly SMALL_SCREEN_THRESHOLD = 501;
    private readonly MEDIUM_SCREEN_THRESHOLD = 769;
    private readonly SMALL_SCREEN_TILES_LENGTH = 1;
    private readonly MEDIUM_SCREEN_TILES_LENGTH = 2;
    private readonly LARGE_SCREEN_TILES_LENGTH = 3;
    private readonly INITIAL_REVIEW_LIST_LENGTH = 6;
    private readonly ELEMENT_SCROLLABLE_THRESHOLD = 160;
    private readonly previousButton = React.createRef<HTMLButtonElement>();
    private readonly nextButton = React.createRef<HTMLButtonElement>();
    private readonly currentReviewElement =
        React.createRef<HTMLButtonElement>();

    private reviewsCarousel: HTMLDivElement | null = null;
    private carouselFlkty: Flickity | null = null;

    private readonly checkScreenSize = () => {
        const displayTilesNumber = !(
            window.innerWidth < this.MEDIUM_SCREEN_THRESHOLD
        )
            ? this.LARGE_SCREEN_TILES_LENGTH
            : !(window.innerWidth < this.SMALL_SCREEN_THRESHOLD)
              ? this.MEDIUM_SCREEN_TILES_LENGTH
              : this.SMALL_SCREEN_TILES_LENGTH;

        this.setState(
            {
                displayTilesNumber: displayTilesNumber,
            },
            () => {
                if (this.props.reviews) {
                    this.initializeBaseCarousel();
                }
            },
        );
    };

    private readonly expandReviewColumn = (isExpanded: boolean) => {
        this.setState({
            closeAllReviewColumns: isExpanded,
        });
    };

    private readonly onNavigationClick = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        const direction = event.currentTarget.dataset.direction;
        if (this.carouselFlkty) {
            switch (direction) {
                case "previous":
                    this.carouselFlkty.previous();
                    break;
                case "next":
                    this.carouselFlkty.next();
                    break;
                default:
                    break;
            }
        }
    };

    componentDidMount() {
        this.checkScreenSize();
        window.addEventListener("resize", this.checkScreenSize);
    }

    getSnapshotBeforeUpdate(prevProps: IProps) {
        if (prevProps.reviews !== this.props.reviews) {
            if (this.carouselFlkty) {
                this.carouselFlkty.destroy();
            }
        }
        return null;
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        if (prevProps.reviews !== this.props.reviews) {
            const reviewList = document.querySelectorAll(
                ".customer-review__review-item",
            );
            if (reviewList.length === this.INITIAL_REVIEW_LIST_LENGTH) {
                this.setState({
                    currentIndex: 0,
                });
            }
        }
        if (prevState.displayTilesNumber !== this.state.displayTilesNumber) {
            this.setState({
                currentIndex: 0,
            });
        }
        this.checkScreenSize();
    }

    private initializeBaseCarousel() {
        if (this.carouselFlkty) {
            this.carouselFlkty.destroy();
        }
        if (!this.reviewsCarousel) {
            return;
        }
        this.carouselFlkty = new Flickity(this.reviewsCarousel, {
            cellAlign: "left",
            groupCells: this.state.displayTilesNumber,
            pageDots: false,
            prevNextButtons: false,
            initialIndex: this.state.currentIndex,
            selectedAttraction: 0.4,
            friction: 0.9,
            on: {
                ready: () => {
                    this.reviewsCarousel?.removeAttribute("tabindex");
                },
            },
        });
        const disableButton = (target: HTMLButtonElement) => {
            target.setAttribute("disabled", "disabled");
            target.classList.add("disabled");
        };
        const enableButton = (target: HTMLButtonElement) => {
            target.removeAttribute("disabled");
            target.classList.remove("disabled");
        };
        if (this.state.currentIndex === 0) {
            if (this.previousButton) {
                disableButton(this.previousButton.current as HTMLButtonElement);
            }
        }
        this.carouselFlkty.on("select", () => {
            if (this.carouselFlkty) {
                if (
                    !this.carouselFlkty.cells[
                        this.carouselFlkty.selectedIndex - 1
                    ]
                ) {
                    if (this.previousButton) {
                        disableButton(
                            this.previousButton.current as HTMLButtonElement,
                        );
                    }
                } else if (
                    !this.carouselFlkty.cells[
                        this.carouselFlkty.selectedIndex + 1
                    ]
                ) {
                    if (this.nextButton) {
                        disableButton(
                            this.nextButton.current as HTMLButtonElement,
                        );
                    }
                } else {
                    if (this.previousButton) {
                        enableButton(
                            this.previousButton.current as HTMLButtonElement,
                        );
                    }
                    if (this.nextButton) {
                        enableButton(
                            this.nextButton.current as HTMLButtonElement,
                        );
                    }
                }
            }
        });
        this.carouselFlkty.on("settle", (index) => {
            const idx = Number(index);
            if (this.props.hasMore && idx > this.state.currentIndex) {
                if (
                    (idx + 1) %
                        (this.INITIAL_REVIEW_LIST_LENGTH /
                            this.state.displayTilesNumber) ===
                    0
                ) {
                    this.props.onLoadMore();
                }
            }
            this.setState({
                currentIndex: idx,
                closeAllReviewColumns: true,
            });

            const flickitySlider = document.querySelector(".flickity-slider");
            if (flickitySlider) {
                const items = flickitySlider.children;
                Array.from(items, (item) => {
                    const reviewCopyContainer = item.children[0].children[1];
                    const reviewCopy = reviewCopyContainer
                        .children[1] as HTMLElement;
                    if (item.classList.contains("is-selected")) {
                        if (
                            !(
                                this.state.displayTilesNumber <
                                this.LARGE_SCREEN_TILES_LENGTH
                            ) &&
                            reviewCopy.offsetHeight >=
                                this.ELEMENT_SCROLLABLE_THRESHOLD
                        ) {
                            reviewCopyContainer.setAttribute("tabIndex", "0");
                        }
                    } else {
                        reviewCopyContainer.setAttribute("tabIndex", "-1");
                    }
                });
            }
        });
    }

    render() {
        if (this.props.reviews.length === 0) {
            return (
                <div className="customer-review__no-reviews">
                    <p>
                        {t`Sorry, we could not find any reviews within your criteria.`}
                    </p>
                    <button onClick={this.props.clearFilters}>
                        {t`Clear filters and try again?`}
                    </button>
                </div>
            );
        }
        const keywords =
            this.props.keywords && this.props.keywords.length > 0
                ? parseFacetValues(this.props.keywords)
                : [];
        if (keywords.length > this.props.maxKeywordsNumber) {
            return (
                <div className="customer-review__no-reviews">
                    <p>{t`Error: Too Many Filters Applied.`}</p>
                    <button onClick={this.props.clearFilters}>
                        {t`Clear filters and try again`}
                    </button>
                </div>
            );
        }
        return (
            <>
                <div className="customer-review__carousel-container">
                    <div className="customer-review__carousel-buttons">
                        <button
                            ref={this.previousButton}
                            aria-label={t`Previous`}
                            className="disabled"
                            data-direction="previous"
                            onClick={this.onNavigationClick}
                        >
                            <SVG
                                aria-hidden="true"
                                src={iconGalleryArrowLeft}
                            />
                        </button>
                    </div>
                    <div
                        className="customer-review__carousel-review-list"
                        role="region"
                        aria-label={t`Customer Reviews Carousel`}
                        ref={(ref) => {
                            this.reviewsCarousel = ref;
                        }}
                    >
                        {this.props.reviews.map((data) => (
                            <CarouselReview
                                key={`${data.product_id}-${data.id}`}
                                review={data}
                                showSideDate={this.props.showSideDate}
                                enablePDPLinks={this.props.enablePDPLinks}
                                isMobile={
                                    this.state.displayTilesNumber <
                                    this.LARGE_SCREEN_TILES_LENGTH
                                }
                                starHasStroke={this.props.starHasStroke}
                                closeAllReviewColumns={
                                    this.state.closeAllReviewColumns
                                }
                                expandReviewColumn={this.expandReviewColumn}
                                currentReviewElement={this.currentReviewElement}
                                expandContentThreshold={
                                    this.ELEMENT_SCROLLABLE_THRESHOLD
                                }
                            />
                        ))}
                    </div>
                    <div className="customer-review__carousel-buttons">
                        <button
                            ref={this.nextButton}
                            aria-label={t`Next`}
                            data-direction="next"
                            onClick={this.onNavigationClick}
                        >
                            <SVG
                                aria-hidden="true"
                                src={iconGalleryArrowRight}
                            />
                        </button>
                    </div>
                </div>
                <ReviewsPageLink className="button">
                    {t`READ ALL REVIEWS`}
                </ReviewsPageLink>
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        reviews: state.data.reviews,
        hasMore: state.ui.hasMore,
        keywords: `${state.ui.loadedFacetValues.text}`,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        clearFilters: dispatchers.clearFilters,
    };
};

export const CarouselReviewList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CarouselReviewListComponent);
