import React from "react";
import classNames from "classnames";
import {
    sortProductsByPrice,
    getCheapestProductVariant,
} from "../../../utils/sorting";
import { money } from "../../../utils/format";
import { IProduct } from "../../../models/catalogue.interfaces";
import {
    isProductPriceDiscounted,
    PriceType,
    getProductPrice,
} from "../../../utils/catalogue";

interface IProps {
    products: IProduct[];
    isSelected: boolean;
    onProductSelect: () => void;
}

interface IState {}

export class ProductCompareSelectionTile extends React.Component<
    IProps,
    IState
> {
    private buildPrice() {
        if (this.props.products.length <= 0) {
            return null;
        }

        const cheapestParent = sortProductsByPrice(this.props.products)[0];
        const cheapestProduct = getCheapestProductVariant(cheapestParent);
        const isDiscounted = isProductPriceDiscounted(cheapestProduct.price);
        let priceElem: JSX.Element;
        if (isDiscounted) {
            priceElem = (
                <>
                    <div className="strikethru">
                        {money(
                            getProductPrice(cheapestProduct.price, {
                                priceType: PriceType.RETAIL,
                                includePostDiscountAddons: true,
                                quantity: 1,
                            }),
                        )}
                    </div>
                    <h3 className="discounted">
                        {money(
                            getProductPrice(cheapestProduct.price, {
                                priceType: PriceType.COSMETIC_EXCL_TAX,
                                includePostDiscountAddons: true,
                                quantity: 1,
                            }),
                        )}
                    </h3>
                </>
            );
        } else {
            priceElem = (
                <h3>
                    {money(
                        getProductPrice(cheapestProduct.price, {
                            priceType: PriceType.COSMETIC_EXCL_TAX,
                            includePostDiscountAddons: true,
                            quantity: 1,
                        }),
                    )}
                </h3>
            );
        }
        return (
            <div className="price">
                <p>Starting At</p>
                {priceElem}
            </div>
        );
    }

    private buildSelectButton() {
        const buttonText = this.props.isSelected ? "Selected" : "Select";
        const btnClasses = classNames({
            "button": true,
            "button--secondary": this.props.isSelected,
        });
        return (
            <button className={btnClasses} onClick={this.props.onProductSelect}>
                {buttonText}
            </button>
        );
    }

    render() {
        return (
            <>
                {this.buildPrice()}
                {this.buildSelectButton()}
            </>
        );
    }
}
