import { t } from "ttag";
import { IProductID } from "../../models/nominals";
import {
    IProduct,
    IOptionValue,
    IOptionSingleValue,
} from "../../models/catalogue.interfaces";
import {
    SortType,
    sortProductVariants,
    sortProductsByPrice,
} from "../../utils/sorting";
import { notEmpty } from "../../utils/functional";
import { baseVariantSelector } from "./selectors";
import { getPreferredOptionSelections } from "./persistence";
import { ConfiguratorTypes } from "../../constants";
import {
    RootProductSelectorInputType,
    DiscountDisplayMode,
    DISCONTINUED,
} from "./constants";
import { IModularConfiguratorOptionSet } from "./models.interfaces";

/**
 * Rules for selecting a default root product from a list of root products:
 *
 * 1. Sort the models on the PLP by ascending mean variant-price.
 * 2. Default select the middle model.
 *     1. If an even number of models, then choose the higher of the middle 2.
 *
 * See https://thelabnyc.plan.io/issues/15518
 */
export const getDefaultRootProduct = <T = IProduct>(rootProducts: T[]): T => {
    const count = rootProducts.length;
    const defaultIdx = Math.ceil((count + 1) / 2) - 1;
    return rootProducts[defaultIdx];
};

/**
 * Given a list of root products and, optionally, an initial variant ID, return the variant which should be initially selected.
 * This is based on the initialVariantID if provided, and otherwise on the last known state of the option selectors and
 * the ordering of the selector options.
 */
export const getInitialVariant = (
    rootProducts: IProduct[],
    initialVariantID?: IProductID | null,
): { rootProduct: IProduct; variant: IProduct } => {
    if (rootProducts.length <= 0) {
        throw new Error("List of rootProducts is empty!");
    }

    // Try to find the exact variant (as given by the ID)
    const getVariantExact = (rp: IProduct) => {
        if (rp.id === initialVariantID) {
            return rp;
        }
        return rp.children.find((p) => {
            return p.id === initialVariantID;
        });
    };
    for (const _rootProduct of rootProducts) {
        const variant = getVariantExact(_rootProduct);
        if (variant) {
            return { rootProduct: _rootProduct, variant: variant };
        }
    }

    // Default select the middle product. See https://thelabnyc.plan.io/issues/15518
    rootProducts = sortProductsByPrice(rootProducts);
    const rootProduct = getDefaultRootProduct(rootProducts);

    // If there's no children, the parent is selected
    if (!rootProduct.children || rootProduct.children.length <= 0) {
        return { rootProduct: rootProduct, variant: rootProduct };
    }

    // Try and select the child which matches the most possible number of previously selected option values
    const namespace = rootProduct.product_class_slug;
    const codes = [...(rootProduct.attributes.product_options?.value || [])];
    const initialVariant = codes.reduce(
        (memo: IProduct | null | undefined, _, i) => {
            if (memo) {
                return memo;
            }
            const codeSubset = i === 0 ? codes : codes.slice(0, -i);
            const optionSet = getPreferredOptionSelections(
                namespace,
                codeSubset,
            );
            return baseVariantSelector.resultFunc([rootProduct], optionSet);
        },
        null,
    );
    if (initialVariant) {
        return { rootProduct: rootProduct, variant: initialVariant };
    }

    // Sort the children using the order of the select options.
    const sortedChildren = sortProductVariants(rootProduct, SortType.PRESELECT);
    const availableChildren = sortedChildren.filter((p) => {
        return p.availability.is_available_to_buy;
    });

    // Try to select the first available child.
    if (availableChildren.length > 0) {
        return { rootProduct: rootProduct, variant: availableChildren[0] };
    }

    // Finally, just select the first child.
    return { rootProduct: rootProduct, variant: sortedChildren[0] };
};

/**
 * Return true if the product is a pre-order item
 */
export const isPreorderProduct = (product: IProduct) => {
    return (
        product.attributes &&
        product.attributes.preorder &&
        product.attributes.preorder.value
    );
};

export const getOptionValueByIdx = (
    value: IOptionValue | undefined,
    i: number,
): IOptionSingleValue | undefined => {
    if (Array.isArray(value)) {
        const nonEmptyVals = value.filter(notEmpty);
        return value[i] || nonEmptyVals[0];
    }
    return value;
};

export const getOptionValueSet = (
    value: IOptionValue | undefined,
): Set<IOptionSingleValue> => {
    let vals: IOptionSingleValue[] = [];
    if (Array.isArray(value)) {
        vals = value.filter(notEmpty);
    } else if (value !== undefined) {
        vals.push(value);
    }
    return new Set(vals);
};

export const getDefaultConfiguratorOptionSet =
    (): IModularConfiguratorOptionSet => {
        return {
            product_ids: [],
            initial_variant_id: null,
            variant_prefilter: null,
            root_product_selector: {
                input_type: RootProductSelectorInputType.DROPDOWN,
                label: t`Model:`,
                enable_ratings: true,
            },
            layout: {
                design: ConfiguratorTypes.UPDATED,
                enable_star_rating: false,
                enable_quantity_selector: true,
                enable_prequal: false,
                enable_chat: false,
                enable_predicted_date: true,
                shipping_callout_icon: null,
                shipping_callout_content: "",
                dynamic_shipping_time_messaging: null,
                shipping_message: null,
                review_aggregation: null,
            },
            discounts: {
                discount_mode: DiscountDisplayMode.DISABLED,
                percentage_discount_format: "",
                absolute_discount_format: "",
            },
            root_product_compare_modal_title: "",
            root_product_compare_modal: null,
        };
    };

/**
 * Filter out the variants that are out of stock and discontinued
 */
export const removeDiscontinuedProducts = (products: IProduct[]) => {
    return products.map((product: IProduct) => {
        product.children = product.children.filter((child: IProduct) => {
            if (!child.availability.is_available_to_buy) {
                return child.availability.code !== DISCONTINUED;
            }
            return true;
        });
        return product;
    });
};
