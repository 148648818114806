import React from "react";
import { t } from "ttag";
import { StoryTile } from "../interfaces";
import { CategoriesFooter } from "./CategoriesFooter";

interface Props {
    // eslint-disable-next-line
    data: StoryTile;
}

export const TempurLoveStoryPageTile = React.forwardRef(
    (props: Props, primaryLinkRef: React.Ref<HTMLAnchorElement>) => {
        let avatar: JSX.Element | null = null;
        if (props.data.value.avatar_url) {
            avatar = (
                <>
                    <figure className="avatar">
                        <img
                            className="avatar__image"
                            src={props.data.value.avatar_url}
                            alt={props.data.value.author}
                        />
                    </figure>
                    <span>{props.data.value.author}</span>
                </>
            );
        }

        return (
            <>
                <span className="post__banner">
                    {props.data.value.tile_banner}
                </span>
                <div className="post__main-image-container">
                    <img
                        className="post__main-image responsive-img"
                        src={props.data.value.tile_image_url}
                        alt={props.data.value.tile_title}
                    />
                </div>
                <div className="post__avatar-container">{avatar}</div>
                <section className="post-copy">
                    <h2 className="post-copy__title">
                        {props.data.value.tile_title}
                    </h2>
                    <div
                        className="post-copy__body"
                        dangerouslySetInnerHTML={{
                            __html: props.data.value.tile_body,
                        }}
                    />
                    <a
                        href={props.data.value.url}
                        ref={primaryLinkRef}
                        className="post-link"
                    >
                        {t`Read more`}
                    </a>

                    <CategoriesFooter
                        categories={props.data.value.categories}
                        city={props.data.value.city}
                        state={props.data.value.state}
                    />
                </section>
            </>
        );
    },
);
