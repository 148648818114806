import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { isoProductID } from "../../../models/nominals";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { rootProductSelector } from "../selectors";
import { getDefaultRootProduct } from "../utils";
import { RootProductCompareModal } from "./RootProductCompareModal";
import styles from "./RadioRootProductSelector.module.scss";
import { RootProductCompareDrawer } from "./RootProductCompareDrawer";
import { InternalProduct } from "../../../models/catalogue";

interface IOwnProps {
    optionSet: IModularConfiguratorOptionSet;
    isPlpPanelStyle?: boolean;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedRootProduct: IProduct | null;
}

interface IDispatchProps {
    onSelectRootProduct: (product: IProduct) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isPanelOpen: boolean;
}

class RadioRootProductSelectorComponent extends React.PureComponent<
    IProps,
    IState
> {
    public state: IState = {
        isPanelOpen: false,
    };

    private getRootProductTitle(rootProduct: InternalProduct) {
        if (this.props.isPlpPanelStyle) {
            return rootProduct.subtitle || rootProduct.title;
        } else {
            if (rootProduct.attributes.model_title) {
                return rootProduct.attributes.model_title.value;
            }
            return rootProduct.title;
        }
    }
    private readonly onOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        this.setState({
            isPanelOpen: true,
        });
    };
    private readonly onCloseButtonClick = (
        removeStaticFromBody: () => void,
        removePanelStateToBody: () => void,
        showFinanceBlock: () => void,
    ) => {
        this.setState(
            {
                isPanelOpen: false,
            },
            () => {
                removeStaticFromBody();
                removePanelStateToBody();
            },
        );
        showFinanceBlock();
    };

    private readonly showCompareLink = () => {
        if (
            !this.props.isPlpPanelStyle ||
            !this.props.optionSet.root_product_compare_modal ||
            this.props.optionSet.root_product_compare_modal.products.length ===
                0
        ) {
            return false;
        }

        return true;
    };

    render() {
        if (this.props.rootProducts.length <= 1) {
            return null;
        }
        const selectedProductID = this.props.selectedRootProduct?.id
            ? this.props.selectedRootProduct.id
            : getDefaultRootProduct(this.props.rootProducts).id;
        return (
            <>
                <div
                    className={`${styles.root} configurator__option-container`}
                >
                    <fieldset>
                        <legend>
                            <h6>
                                {
                                    this.props.optionSet.root_product_selector
                                        .label
                                }
                            </h6>
                        </legend>
                        {this.showCompareLink() && (
                            <>
                                <button
                                    className={styles.openBtn}
                                    onClick={this.onOpen}
                                >
                                    {t`Compare`}
                                </button>
                            </>
                        )}
                        {!this.props.isPlpPanelStyle && (
                            <RootProductCompareModal
                                optionSet={this.props.optionSet}
                            />
                        )}
                        {this.props.rootProducts.map((rootProduct) => (
                            <label
                                key={`${rootProduct.id}`}
                                aria-selected={
                                    rootProduct.id === selectedProductID
                                }
                            >
                                <input
                                    type="radio"
                                    name="root-product"
                                    value={isoProductID.unwrap(rootProduct.id)}
                                    className={`ada-screenreader-only`}
                                    checked={
                                        rootProduct.id === selectedProductID
                                    }
                                    onChange={(e) => {
                                        if (!e.currentTarget.checked) {
                                            return;
                                        }
                                        this.props.onSelectRootProduct(
                                            rootProduct,
                                        );
                                    }}
                                />
                                {rootProduct.attributes
                                    .configurator_root_product_selector_icon
                                    ?.value && (
                                    <img
                                        src={`${rootProduct.attributes.configurator_root_product_selector_icon.value}`}
                                        alt=""
                                    />
                                )}
                                <span>
                                    <>
                                        {this.getRootProductTitle(rootProduct)}
                                        {rootProduct.attributes
                                            .model_subtitle && (
                                            <span
                                                className={styles.subTitle}
                                            >{`${rootProduct.attributes.model_subtitle.value}`}</span>
                                        )}
                                    </>
                                </span>
                            </label>
                        ))}
                    </fieldset>
                </div>
                {this.state.isPanelOpen && (
                    <RootProductCompareDrawer
                        prefixNameCapped={
                            this.props.optionSet.root_product_selector.label
                        }
                        panelClass={"model"}
                        optionSet={this.props.optionSet}
                        isPanelOpen={this.state.isPanelOpen}
                        onCloseButtonClick={this.onCloseButtonClick}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProducts: state.entities.rootProducts,
        selectedRootProduct: rootProductSelector(state),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onSelectRootProduct: (product) => {
            dispatchers.setSelectedRootProduct(product);
        },
    };
};

export const RadioRootProductSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RadioRootProductSelectorComponent);
