import React from "react";
import { connect } from "react-redux";
import { Dispatchers } from "../../apps/configurator/dispatchers";
import {
    rootProductSelector,
    categoriesSelector,
    selectedCategoriesSelector,
    optionPanelsSelector,
    optionValueSelector,
} from "../../apps/configurator/selectors";
import {
    IPLCOptionPanel,
    IPLCProductCategorySelector,
} from "../../apps/configurator/models.interfaces";
import { TStateMapper, TDispatchMapper } from "../../apps/reducers.interfaces";
import {
    IProduct,
    IOptionCode,
    IOptionSingleValue,
    IOptionValues,
} from "../../models/catalogue.interfaces";
import { IViewport } from "../../models/screen.interfaces";
import { TabCardsBlock as TabCardsBlockComponent } from "../components/TabCardsBlock";
import { ITabCardsBlock } from "../../models/streamfield-blocks";
import { defaults } from "../../apps/configurator/defaults";
import { ISelectedCategories } from "../../apps/configurator/reducers.interfaces";

interface IOwnProps {
    tabCards: ITabCardsBlock;
    theme?: string;
}

interface IReduxProps {
    allRootProducts: IProduct[] | null;
    rootProduct: IProduct | null;
    pageCategorySelectors: IPLCProductCategorySelector[];
    pageOptionPanels: IPLCOptionPanel[];
    selectedCategories: ISelectedCategories;
    selectedOptionValues: IOptionValues;
    viewport: IViewport;
}

interface IDispatchProps {
    onSelectRootProduct: (product: IProduct) => void;
    onSelectCategory: Dispatchers["setSelectedCategory"];
    onSelectAttribute: (
        namespace: string,
        code: IOptionCode,
        index: number,
        totalNumValues: number,
        value: IOptionSingleValue,
    ) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class TabCardsBlockContainer extends React.PureComponent<IProps, IState> {
    render() {
        return (
            <TabCardsBlockComponent
                tabCards={this.props.tabCards}
                pageRootProducts={this.props.allRootProducts}
                pageRootProductID={
                    this.props.rootProduct ? this.props.rootProduct.id : null
                }
                pageProductNamespace={
                    this.props.rootProduct
                        ? this.props.rootProduct.product_class_slug
                        : null
                }
                pageCategorySelectors={this.props.pageCategorySelectors}
                pageOptionPanels={this.props.pageOptionPanels}
                pageSelectedCategories={this.props.selectedCategories}
                pageSelectedOptionValues={this.props.selectedOptionValues}
                viewport={this.props.viewport}
                theme={this.props.theme}
                // onGetOptionValues={this.getOptionValues}
                onCardProductSelect={this.props.onSelectRootProduct}
                onCardCategorySelect={this.props.onSelectCategory}
                onCardAttributeSelect={this.props.onSelectAttribute}
            />
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;

    const rootProduct = rootProductSelector(state);
    const rootProducts = state.entities.rootProducts;
    const categories = categoriesSelector(state);
    const optionPanels = optionPanelsSelector(state);
    const selectedCategories = selectedCategoriesSelector(state);
    const selectedOptionValues = optionValueSelector(state);

    return {
        rootProduct: rootProduct,
        allRootProducts: rootProducts,
        pageCategorySelectors: categories,
        pageOptionPanels: optionPanels,
        selectedCategories: selectedCategories,
        selectedOptionValues: selectedOptionValues,
        viewport: rootState.common.viewport,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);

    return {
        onSelectRootProduct: (product) => {
            dispatchers.setSelectedRootProduct(product);
        },
        onSelectCategory: (...args) => {
            dispatchers.setSelectedCategory(...args);
        },
        onSelectAttribute: (namespace, code, index, totalNumValues, value) => {
            dispatchers.setOptionValue(
                namespace,
                code,
                index,
                totalNumValues,
                value,
            );
        },
    };
};

export const TabCardsBlock = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TabCardsBlockContainer);
