import React from "react";
import classNames from "classnames";
import { t } from "ttag";
import { GridFilterConfig } from "../filters";
import { GridFilter, GridFilterVariants } from "./GridFilter";
import { BreakPoint } from "../../../models/screen.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";

import styles from "./SideNav.module.scss";

interface IOwnProps {
    gridVariant: string;
    filters: GridFilterConfig[];
}

interface IReduxProps {}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    showFilters: boolean;
}

export class SideNav extends React.PureComponent<IProps, IState> {
    public state: IState = {
        showFilters: true,
    };

    componentDidMount() {
        if (this.isMobileOrTabletWidth()) {
            this.setState({
                showFilters: false,
            });
        }
    }

    private readonly isMobileOrTabletWidth = () => {
        const currentBreakpoint = getViewportBreakpoint();
        return currentBreakpoint < BreakPoint.LARGE;
    };

    private readonly toggleFilterView = (
        event: React.MouseEvent<HTMLDivElement>,
    ) => {
        event.preventDefault();
        this.setState({
            showFilters: !this.state.showFilters,
        });
    };

    renderTitle() {
        const titleText = t`Filters`;
        const filterTitleToggleClass = classNames({
            [styles.title]: true,
            [styles.open]: this.state.showFilters,
            [styles.close]: !this.state.showFilters,
        });

        if (this.isMobileOrTabletWidth()) {
            return (
                <div
                    className={filterTitleToggleClass}
                    onClick={this.toggleFilterView.bind(this)}
                >
                    {titleText}
                </div>
            );
        }

        return <h2 className={styles.title}>{titleText}</h2>;
    }

    render() {
        return (
            <div className={styles.container}>
                {this.renderTitle()}
                {this.state.showFilters && (
                    <div>
                        {this.props.filters.map((config) => (
                            <GridFilter
                                key={config.filterID}
                                config={config}
                                variant={GridFilterVariants.ACCORDION}
                                showSelectedList={false}
                            />
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
