import React from "react";
import { t } from "ttag";
import { Form, FormInput, FormSubmit } from "tsi-common-react/src/forms";
import { runValidator } from "tsi-common-react/src/forms/validation";

interface IFormState {
    isSubmitting: boolean;
    formSubmitted: boolean;
    phoneValue: string;
    errorMessage: string | null;
}

export const PhoneNumberCapture = () => {
    const [formState, setFormState] = React.useState<IFormState>({
        isSubmitting: false,
        formSubmitted: false,
        phoneValue: "",
        errorMessage: null,
    });

    /**
     * SMS form submission differs from email capture:
     * - Unlike email, SMS phone numbers are not required to be sent to backend services,
     * as they are not associated with the current Django session user.
     * - The primary task is only to validate the input as a phone number before Listrak scripts handle the collection.
     */
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setFormState((prevState) => ({
            ...prevState,
            isSubmitting: true,
        }));
        const validationMessage = runValidator("phone", formState.phoneValue);
        setFormState((prevState) => ({
            ...prevState,
            isSubmitting: false,
            errorMessage: validationMessage,
            formSubmitted: validationMessage === null,
        }));
    };

    const handlePhoneNumberChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        const phoneNumber = event.currentTarget.value;
        const validationMessage = runValidator("phone", phoneNumber);
        setFormState((prevState) => ({
            ...prevState,
            phoneValue: phoneNumber,
            errorMessage: validationMessage,
        }));
    };

    const { isSubmitting, formSubmitted, phoneValue } = formState;

    return (
        <section className="phone-number-capture">
            <h3>{t`Stay connected with text alerts`}</h3>
            {formSubmitted ? (
                <p>{t`Thank you for subscribing!`}</p>
            ) : (
                <div>
                    <Form onSubmit={handleSubmit}>
                        <FormInput
                            id="listrak_phone_number"
                            name="listrak_phone_number"
                            type="tel"
                            placeholder={t`Phone number`}
                            required={true}
                            autoComplete="tel"
                            value={phoneValue}
                            onChange={handlePhoneNumberChange}
                            disabled={isSubmitting}
                        />
                        <FormSubmit
                            id="listrak_phone_number_submit"
                            name="listrak_phone_number_submit"
                            type="submit"
                            value={t`Submit`}
                            disabled={formState.errorMessage !== null}
                        />
                    </Form>
                    {formState.errorMessage && (
                        <p className="phone-number-capture__error">
                            {formState.errorMessage}
                        </p>
                    )}
                    <p className="phone-number-capture__conditions">
                        By submitting this form, you agree to receive recurring
                        marketing text messages (e.g. cart reminders), which may
                        be automated, to the mobile number provided at opt-in
                        from Sleep Outfitters. Consent is not a condition of
                        purchase. Msg frequency may vary. Msg & data rates may
                        apply. Reply HELP for help and STOP to cancel. See{" "}
                        <a href="/terms-of-use">Terms and Conditions</a> &{" "}
                        <a href="/general-privacy-policy">Privacy Policy</a>.
                    </p>
                </div>
            )}
        </section>
    );
};
