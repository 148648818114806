import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { strings } from "../../../localization";
import * as userAPI from "../../../api/user";
import { IUser } from "../../../models/user.interfaces";
import { Form } from "../../../forms/Form";
import { NonFieldErrors } from "../../../forms/NonFieldErrors";
import { FormInput } from "../../../forms/FormInput";
import { FormSubmit } from "../../../forms/FormSubmit";
import { updateGTMUserHash } from "../../../utils/analytics";
import { Dispatchers as CommonDispatchers } from "../../common/dispatchers";
import { Loaders as CommonLoaders } from "../../common/loaders";
import { Step } from "../constants";
import { Dispatchers } from "../dispatchers";
import { Loaders } from "../loaders";
import { CheckoutStepContainer } from "./CheckoutStepContainer";
import { EmailAddressSummary } from "./EmailAddressSummary";

interface IOwnProps {
    user: IUser;
    onContinue: (event?: React.MouseEvent<HTMLElement>) => void;
    onEdit: (event?: React.MouseEvent<HTMLElement>) => void;
}

interface IReduxProps {
    showForm: boolean;
}

interface IDispatchProps {
    commonLoaders: CommonLoaders;
    loaders: Loaders;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isSaving: boolean;
    email: string;
    errors: {
        non_field_errors?: string[];
        email?: string[];
    };
}

class EmailAddressComponent extends React.Component<IProps, IState> {
    private emailInput: FormInput<"email"> | null = null;

    public state: IState = {
        isSaving: false,
        email: "",
        errors: {},
    };

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.showForm !== prevProps.showForm) {
            this.setState({ email: this.props.user.email });
        }
    }

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        event.preventDefault();
        if (this.state.errors.email && this.state.errors.email.length > 0) {
            return;
        }
        this.setState({
            isSaving: true,
            errors: {},
        });
        updateGTMUserHash(this.state.email);
        try {
            await userAPI.updateEmailAddress(this.state.email);
            await this.props.commonLoaders.loadCurrentUser();
            this.setState({
                isSaving: false,
                errors: {},
            });
            this.props.onContinue();
        } catch (error) {
            this.setState({
                isSaving: false,
                errors: error.response.body,
            });
        }
    };

    private readonly onValidStateChange = (
        _: string,
        errorMessages: string[],
    ) => {
        const errors = this.state.errors;
        errors.email = errorMessages;
        this.setState({ errors: errors });
    };

    private readonly onEmailChange = () => {
        this.setState({
            email: (this.emailInput ? this.emailInput.value : null) || "",
        });
    };

    render() {
        const bodyClasses = classNames({
            "checkout-step__body": true,
            "checkout-step__body--collapsed": !this.props.showForm,
        });
        return (
            <CheckoutStepContainer
                className="checkout-step--email checkout-step--first"
                heading={t`Email Address`}
            >
                <div className={bodyClasses}>
                    <Form onSubmit={this.onSubmit}>
                        <NonFieldErrors
                            errors={this.state.errors.non_field_errors}
                            showErrorMessages={true}
                        />
                        <FormInput
                            ref={(elem) => {
                                this.emailInput = elem;
                            }}
                            label={t`Email Address`}
                            labelPlacement="dynamic"
                            name="email"
                            id="email"
                            validation={["required", "email"]}
                            value={this.state.email}
                            onChange={this.onEmailChange}
                            errors={this.state.errors.email}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={true}
                        />
                        <FormSubmit
                            value={
                                strings.get("CONTINUE_BTN_LABEL_LOGIN") ||
                                "CONTINUE"
                            }
                            disabled={this.state.isSaving}
                        />
                    </Form>
                    <p>
                        <a
                            className="checkout-step__cancel"
                            href="#"
                            onClick={this.props.onContinue}
                        >
                            {t`Cancel`}
                        </a>
                    </p>
                </div>
                <EmailAddressSummary
                    user={this.props.user}
                    onEdit={this.props.onEdit}
                />
            </CheckoutStepContainer>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        showForm: state.form.current_step === Step.LOGIN,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const commonDispatchers = new CommonDispatchers(dispatch);
    const commonLoaders = new CommonLoaders(commonDispatchers);
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    return {
        commonLoaders: commonLoaders,
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const EmailAddress = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmailAddressComponent);
