import { IRetailStoreWithDistance } from "../../models/location.interfaces";
import { IMeters, isoMiles, ISyncStoreID } from "../../models/nominals";
import { metersToMiles } from "../../utils/maps";

export const formatDistance = (
    distance: IMeters,
    decimalPoint?: number,
): string => {
    const miles = metersToMiles(distance);
    return isoMiles.unwrap(miles).toFixed(decimalPoint);
};

export const formatDistanceWithSuffix = (
    distance: IMeters,
    decimalPoint?: number,
): string => {
    const formattedDistance = formatDistance(distance, decimalPoint);
    const label = formattedDistance === "1" ? " mile" : " miles";
    return formattedDistance + label;
};

export const formatAddress = (store: IRetailStoreWithDistance) => {
    const parts = [store.address];
    if (store.address2) {
        parts.push(store.address2);
    }
    parts.push(store.city);
    parts.push(store.state);
    return parts.join(", ");
};

export const getAddress = (store: IRetailStoreWithDistance): string => {
    const parts = [store.address];
    if (store.address2) {
        parts.push(store.address2);
    }
    parts.push(store.city);
    parts.push(store.state);
    return parts.join(", ");
};

export const getSelectedStore = (
    closestStore: IRetailStoreWithDistance | null,
    selectedStoreID: ISyncStoreID | null,
    stores: IRetailStoreWithDistance[],
) => {
    const selectedStore = selectedStoreID
        ? stores.find((store) => store.external_id === selectedStoreID)
        : closestStore;

    if (!selectedStore) {
        return null;
    }
    return selectedStore;
};
