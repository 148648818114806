import React from "react";
import { FormField } from "./FormField";

interface IProps<T> {
    type: "hidden" | "select" | "phone" | "text" | "email";
    name: T;
    onChange: (name: T, value: string) => void;
    value?: string;
    errors?: string[];
    label?: string;
    placeholder?: string;
    isRequired?: boolean;
    forwardRef?: (elem: HTMLInputElement | null) => void;
}

export const TextInput = <T extends string>(props: IProps<T>) => {
    if (props.type === "hidden") {
        return null;
    }
    return (
        <FormField {...props}>
            <input
                type={props.type}
                name={props.name}
                value={`${props.value}`}
                placeholder={props.placeholder}
                onChange={(event) => {
                    event.preventDefault();
                    props.onChange(props.name, event.currentTarget.value);
                }}
                required={props.isRequired}
                ref={props.forwardRef}
            />
        </FormField>
    );
};
