import * as t from "io-ts";
import { constants } from "@reactivated/constants";
import { codecFromConstMap } from "../../models/utils";

export const RichNavigationVariantOptions =
    constants[
        "tsicommon.cms.models.rich_navigation.RichNavigationVariantOptions"
    ];

const RichNavigationVariantOptionsMap = codecFromConstMap(
    "RichNavigationVariantOptions",
    RichNavigationVariantOptions,
);

export type TRichNavigationVariantOptions = t.TypeOf<
    typeof RichNavigationVariantOptionsMap
>;
