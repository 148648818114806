import { onReadyStateComplete } from "../utils/events";

onReadyStateComplete.on(async () => {
    const columsCarouselBlocks = document.querySelectorAll<HTMLElement>(
        ".columns-carousel-block",
    );
    if (!columsCarouselBlocks) {
        return;
    }
    const Flickity = (await import("flickity-imagesloaded")).default;
    columsCarouselBlocks.forEach((block) => {
        const columns = block.querySelector<HTMLElement>(
            ".columns-carousel-block__container",
        );
        const noCarousel = JSON.parse(block.dataset.noCarousel || "false");
        if (!columns) {
            return;
        }
        if (noCarousel) {
            return;
        }

        const isContain = true;
        const isPrevNext = false;

        const flickity = new Flickity(columns, {
            watchCSS: true,
            autoPlay: false,
            imagesLoaded: true,
            pageDots: false,
            prevNextButtons: isPrevNext,
            contain: isContain,
        });

        flickity.on("ready", () => {
            if (!isPrevNext) {
                return;
            }

            const container = block.querySelector<HTMLElement>(
                ".columns-carousel-block__container",
            );
            if (!container) {
                return;
            }

            container.classList.add("is-prev-next");
            const previousButton = Array.from(container.children).find(
                (element) => element.classList.contains("previous"),
            );
            if (!previousButton) {
                return;
            }
            container.prepend(previousButton);
        });

        const buttonGroup = block.querySelector<HTMLElement>(
            ".columns-carousel-block .buttons",
        );
        if (!buttonGroup) {
            return;
        }

        if (isContain) {
            buttonGroup.classList.add("is-contain");
        }

        buttonGroup.addEventListener("click", (event) => {
            const target = event.target as HTMLButtonElement;
            const parent = target.parentNode as HTMLDivElement;
            const index = Array.prototype.indexOf.call(
                parent.children,
                event.target,
            );
            flickity.select(index);
        });

        const childrenLength = buttonGroup.children.length;

        if (childrenLength === 3 && isContain) {
            buttonGroup.classList.add("no-buttons");
        }

        flickity.on("select", (index) => {
            const selectedButton = buttonGroup.children[Number(index)];
            Array.from(buttonGroup.children, (button) => {
                if (button !== selectedButton) {
                    button.classList.remove("is-selected");
                    button.setAttribute("aria-current", "false");
                } else {
                    button.classList.add("is-selected");
                    button.setAttribute("aria-current", "true");
                }
            });
        });
    });
});
