import React from "react";
import { t } from "ttag";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { Key } from "ts-key-enum";
import { focusElement, trapFocus } from "../../../utils/keyboardFocus";
import { RichText } from "../../../common/RichText";
import { showFinanceBlock } from "./ConfiguratorPanelTrigger";
import { MOBILE_WIDTH_THRESHOLD_ALTERNATIVE } from "../constants";

import styles from "./ConfiguratorPanel.module.scss";
import { ConfiguratorPanelHeadAlternative } from "./ConfiguratorPanelHeadAlternative";
import iconXClose from "../../../../img/icons/x-close.svg";
import iconLiveChatDark from "../../../../img/icons/live-chat-dark.svg";

interface IProps {
    prefixNameCapped: string;
    panelClass: string;
    onClosePanel: () => void;
    radioSelector: JSX.Element | null;
    chatLinkComponent?: JSX.Element | null;
    liveChatHeader?: string;
    enableChat?: boolean;
    isPlpPanelStyle?: boolean;
}

interface IState {
    closePanel: boolean;
}

export class ConfiguratorPanel extends React.PureComponent<IProps, IState> {
    private optionPanel: HTMLDivElement | null = null;
    private closeButton: HTMLButtonElement | null = null;

    public state: IState = {
        closePanel: false,
    };

    private readonly onEscKeyDown = (
        e: React.KeyboardEvent<HTMLDivElement>,
    ) => {
        if (e.key === Key.Escape) {
            this.props.onClosePanel();
        }
    };

    private readonly isMobileWidth = () => {
        return window.innerWidth < MOBILE_WIDTH_THRESHOLD_ALTERNATIVE;
    };

    private readonly updateSelectorContainerHeight = () => {
        if (this.isMobileWidth()) {
            return;
        }
        // Subtract the scrollTop from the height of the header
        // to get the correct vertical viewport at the position where the mouse scroll is
        // when the configurator is not sticky
        const headerOffset =
            parseInt(
                getComputedStyle(document.documentElement).getPropertyValue(
                    "--configurator--header-offset",
                ),
                10,
            ) || 0;
        const footerOffset =
            parseInt(
                getComputedStyle(document.documentElement).getPropertyValue(
                    "--configurator--footer-offset",
                ),
                10,
            ) || 0;
        const isAboveHeaderOffset =
            document.documentElement.scrollTop <= headerOffset;
        if (this.optionPanel) {
            if (footerOffset) {
                if (isAboveHeaderOffset) {
                    this.optionPanel.style.height = `calc(calc(100vh - ${footerOffset}px) - ${
                        headerOffset - document.documentElement.scrollTop
                    }px`;
                }
            } else if (isAboveHeaderOffset) {
                this.optionPanel.style.height = `calc(100vh - ${
                    headerOffset - document.documentElement.scrollTop
                }px`;
            }
        }
    };

    private readonly addStaticToBody = () => {
        document.body.classList.add("static");
    };

    private readonly removeStaticFromBody = () => {
        document.body.classList.remove("static");
    };

    private readonly addPanelStateToBody = () => {
        document.body.classList.add("configurator-panel-open");
    };

    private readonly removePanelStateToBody = () => {
        // Wait a few seconds to remove inline style `position: fixed` before removing `.configurator-panel-open` from `<body>`.
        setTimeout(() => {
            document.body.classList.remove("configurator-panel-open");
        }, 500);
    };

    private readonly onCloseButtonClick = () => {
        this.setState(
            {
                closePanel: true,
            },
            () => {
                this.removeStaticFromBody();
                this.removePanelStateToBody();
            },
        );
        showFinanceBlock();
        this.props.onClosePanel();

        // after closing, return focus to element which opened this panel
        if (
            this.closeButton &&
            this.closeButton.parentElement?.parentElement?.parentElement
                ?.nextElementSibling
        ) {
            const triggerButtonElem = this.closeButton.parentElement
                .parentElement.parentElement.nextElementSibling as HTMLElement;
            if (triggerButtonElem) {
                focusElement(triggerButtonElem);
            }
        }
    };

    private readonly onMouseEnterPanel = () => {
        if (this.optionPanel) {
            this.addStaticToBody();
        }
    };

    private readonly onMouseLeavePanel = () => {
        if (this.optionPanel) {
            this.removeStaticFromBody();
        }
    };

    componentDidMount() {
        // Wait for animation completed
        setTimeout(() => {
            if (this.closeButton) {
                focusElement(this.closeButton);
            }
            if (this.optionPanel) {
                trapFocus(this.optionPanel);
            }
        }, 500);

        if (this.isMobileWidth()) {
            this.addStaticToBody();
        }

        const isAltConfig = document.querySelector(
            ".alternative-design .product-hero__configurator",
        );
        if (!isAltConfig) {
            if (!this.isMobileWidth) {
                this.updateSelectorContainerHeight();
            }

            window.addEventListener(
                "scroll",
                this.updateSelectorContainerHeight,
            );
        }

        this.addPanelStateToBody();
    }

    componentWillUnmount() {
        this.removeStaticFromBody();
    }

    render() {
        const panelClasses = classNames({
            configurator__panel: true,
            [styles.panel]: true,
            [`configurator__panel--${this.props.panelClass}`]: true,
        });
        return (
            <div
                aria-modal="true"
                aria-label={t`${this.props.prefixNameCapped} option selection panel`}
                className={panelClasses}
                ref={(elem) => {
                    this.optionPanel = elem;
                }}
                role="dialog"
                onKeyDown={this.onEscKeyDown}
                onMouseEnter={this.onMouseEnterPanel}
                onMouseLeave={this.onMouseLeavePanel}
            >
                {this.props.isPlpPanelStyle ? (
                    <ConfiguratorPanelHeadAlternative
                        prefixNameCapped={this.props.prefixNameCapped}
                        onCloseButtonClick={this.onCloseButtonClick}
                        closeButtonRef={(elem) => (this.closeButton = elem)}
                    />
                ) : (
                    <div>
                        <h5>{t`Select ${this.props.prefixNameCapped}`}</h5>
                        <button
                            ref={(elem) => {
                                this.closeButton = elem;
                            }}
                            aria-label={t`Close`}
                            onClick={this.onCloseButtonClick}
                        >
                            <SVG aria-hidden="true" src={iconXClose} />
                        </button>
                    </div>
                )}

                <div>
                    <div
                        className={styles.panelSelector}
                        data-body-scroll-lock-ignore="true"
                    >
                        {this.props.radioSelector}
                    </div>
                    {this.props.enableChat && (
                        <div className={styles.chatLink}>
                            <SVG aria-hidden="true" src={iconLiveChatDark} />
                            {this.props.liveChatHeader && (
                                <RichText
                                    html={this.props.liveChatHeader}
                                    tagName="h4"
                                />
                            )}
                            <small>{t`Contact a sleep expert`}</small>
                            <span onClick={this.onCloseButtonClick}>
                                {this.props.chatLinkComponent}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
