import { captureException } from "@sentry/browser";
import { ajax } from "../../utils/ajax";
import * as basketAPI from "../../api/basket";
import * as productsAPI from "../../api/products";
import * as configuratorAPI from "../../api/configurator";
import * as financingAPI from "../../api/financing";
import { guess as guessLocation } from "../../api/location";
import { IProductID, isoWebPageURL } from "../../models/nominals";
import { IProduct } from "../../models/catalogue.interfaces";
import { markPerfTiming } from "../../utils/performance";
import {
    IPLCOptionPanel,
    IPLCProductCategorySelector,
} from "./models.interfaces";
import { Dispatchers } from "./dispatchers";
import { removeDiscontinuedProducts } from "./utils";
import { filterFinancingPlans, sortFinancingPlans } from "../checkout/utils";

export class Loaders {
    dispatchers: Dispatchers;

    constructor(dispatchers: Dispatchers) {
        this.dispatchers = dispatchers;
    }

    public readonly loadProducts = async (
        productIDs: IProductID[],
        initialVariantID?: IProductID | null,
        categorySelectors?: IPLCProductCategorySelector[],
        optionPanels?: IPLCOptionPanel[],
    ) => {
        markPerfTiming("configurator", "loadProducts:start");
        const loading = productIDs.map((productID) => {
            const productURL = productsAPI.getProductURL(productID);
            return productsAPI.getProduct(productURL);
        });
        let products = await Promise.all(loading);
        // if a variant is out of stock and discontinued, do not load in the drop-down menu on the configurators.
        products = removeDiscontinuedProducts(products);
        this.dispatchers.loadedProducts(
            products,
            initialVariantID,
            categorySelectors,
            optionPanels,
        );
        if (products.length > 0) {
            const productClass = await productsAPI.getProductClass(
                products[0].product_class_slug,
            );
            if (productClass) {
                this.dispatchers.loadedProductClass(productClass);
            }
        }
        markPerfTiming("configurator", "loadProducts:done");
    };

    public readonly loadPrice = async (
        productID: IProductID,
        quantity: number,
    ) => {
        const productURL = productsAPI.getProductURL(productID);
        const price = await productsAPI.getProductPrice(productURL, quantity);
        this.dispatchers.loadedPrice(price);
    };

    public readonly loadFinancingPlans = async (productID: IProductID) => {
        // Load Financing plans depending on the product, but allow this to fail gracefully
        const financingPlans = await financingAPI
            .listFinancingPlansForProduct(productID)
            .catch((err) => {
                captureException(err);
                return [];
            });
        const sortedPlans = sortFinancingPlans(
            filterFinancingPlans(financingPlans),
        );
        this.dispatchers.loadedFinancingPlans(sortedPlans);
    };

    public readonly loadProductShippingMethods = async (
        productID: IProductID,
        postCode: string,
    ) => {
        const resp = await productsAPI.getProductShippingMethods(
            productID,
            postCode,
        );
        const defaultMethod = resp.length > 0 ? resp[0] : null;
        this.dispatchers.loadedProductShippingMethod(defaultMethod);
    };

    public readonly loadLocation = async () => {
        const location = await guessLocation();
        if (location) {
            this.dispatchers.loadedLocation(location);
        }
    };

    public readonly loadConcreteBundles = async (
        variantID: IProductID,
        selectedUpgradeID: IProductID | null,
    ) => {
        const productURL = productsAPI.getProductURL(variantID);
        const [bundles, upsellInfoModals, promoCards] = await Promise.all([
            productsAPI.listConcreteBundles(productURL),
            configuratorAPI.getUpsellInfoModalContent(variantID),
            configuratorAPI.getPromoCards(variantID),
        ]);
        this.dispatchers.loadedConcreteBundles(
            bundles,
            upsellInfoModals,
            promoCards,
            selectedUpgradeID,
        );
    };

    public readonly loadUserConfigurableBundles = async () => {
        // Load the basket
        const basket = await basketAPI.load();
        // Load configurable bundles for the given basket
        return basketAPI.listUserConfigurableBundles(basket);
    };

    public readonly loadProductPDP = async (product: IProduct) => {
        if (!product.link) {
            return null;
        }
        const resp = await ajax.get(isoWebPageURL.unwrap(product.link));
        const productPDP = document.createElement("html");
        productPDP.innerHTML = resp.text;
        return productPDP;
    };

    public readonly loadUpgradeTooltip = async (product: IProduct) => {
        const pdp = await this.loadProductPDP(product);
        if (!pdp) {
            throw new Error("Product has no PDP");
        }
        let modalContent = pdp.querySelector(".pdp-features");
        if (!modalContent) {
            modalContent = pdp.querySelector(".pdp-blocks");
        }
        if (!modalContent) {
            throw new Error("Couldn't find any PDP content for product");
        }
        return modalContent.outerHTML;
    };
}
