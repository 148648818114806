import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";
import { buildMobileNavToggleClickHandler } from "tsi-common-react/src/widgets/rich-navigation";

const registerEventListeners_Accordion = () => {
    setAccordionListener();
};

// Accordion logic exported to enable usage for ajax loaded accordions (like the offers page modal)
export const setAccordionListener = () => {
    // Accordion
    Array.from(document.querySelectorAll<HTMLElement>(".accordion")).forEach(
        (elem) => {
            if (elem.classList.contains("accordion--loaded")) {
                return;
            }
            elem.classList.add("accordion--loaded");
            elem.addEventListener("click", function (event) {
                if (
                    event.target instanceof HTMLElement &&
                    !event.target.hasAttribute("href")
                ) {
                    event.preventDefault();
                }
                const ariaExpanded = this.getAttribute("aria-expanded");
                if (ariaExpanded === "true" || ariaExpanded === "false") {
                    this.setAttribute(
                        "aria-expanded",
                        ariaExpanded === "true" ? "false" : "true",
                    );
                }

                this.classList.toggle("accordion--is-active");
                const parentLi = this.closest("li");
                if (!parentLi) {
                    return;
                }

                Array.from(parentLi.children)
                    .filter((e) => {
                        return e.classList.contains("accordion-target");
                    })
                    .forEach((e) => {
                        e.classList.toggle("accordion-target--is-active");
                    });
            });
        },
    );
};

// Mobile Toggles
const registerEventListeners_MobileToggle = () => {
    Array.from(
        document.querySelectorAll<HTMLElement>(".mobile-toggle"),
    ).forEach((elem) => {
        elem.addEventListener(
            "click",
            buildMobileNavToggleClickHandler(elem, true),
        );
    });
};

// Disable the accordion functionality of
// Rich Navigation Link Collections
const rich_navigations = document.querySelectorAll<HTMLElement>(
    "nav.rich-navigation",
);
if (rich_navigations) {
    Array.from(rich_navigations).forEach((rich_navigation) => {
        Array.from(
            rich_navigation.querySelectorAll(
                ".rich-navigation--link-collection",
            ),
        ).forEach((linkCollection) => {
            linkCollection
                .querySelector<HTMLElement>("button")
                ?.removeAttribute("aria-expanded");
            linkCollection
                .querySelector<HTMLElement>(".collection")
                ?.removeAttribute("aria-hidden");
        });
    });
}

const registerEventListeners_Search = () => {
    // Search bar functionality
    Array.from(
        document.querySelectorAll<HTMLElement>(".search-results__clear-icon"),
    ).forEach((elem) => {
        elem.addEventListener("click", () => {
            const input = document.querySelector<HTMLInputElement>(
                ".search-results__search-input",
            );
            if (input) {
                input.value = "";
            }
        });
    });
};

// Register functions after body loads
onDOMContentLoaded.on(() => {
    registerEventListeners_Accordion();
    registerEventListeners_MobileToggle();
    registerEventListeners_Search();
});
