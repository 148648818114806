import * as t from "io-ts";
import { IReviewsBrandID, IProductUUID } from "./nominals";
import {
    SearchFacetSubOption,
    SearchFacetOption,
    SearchFacet,
    ReviewsBrand,
    Review,
    ReviewResultsPage,
    WriteReviewTemplateField,
    WriteReviewTemplate,
    HistogramEntry,
    ReviewsProductVariant,
    ReviewsProduct,
    ReviewsInfoMap,
    WriteReviewBlackBox,
} from "./reviews";

import { NOT_SURE_VARIANT_CHOICE_SLUG } from "../apps/reviews/constants";

export enum ReviewSortOption {
    HIGHEST_RATED = "-rating,-created_datetime",
    LOWEST_RATED = "rating,-created_datetime",
    NEWEST = "-created_datetime",
    OLDEST = "created_datetime",
}

export type IReviewParams = {
    brand_id: IReviewsBrandID;
    ordering?: ReviewSortOption;
    product_type_id?: string;
    page?: number;
    page_size?: number;
};

export type IReviewQueryFacets =
    | "text"
    | "rating"
    | "product_uuid"
    | "product_id"
    | "source_id"
    | "product_variant_id"
    | "product_type_id";

export type IParsedReviewQuery = {
    [T in IReviewQueryFacets]?: Array<string | number>;
};

export type IReviewQuery = {
    [T in IReviewQueryFacets]?: string | number;
};

export type ISearchReviewArgs = IReviewParams & IReviewQuery;

export type ISearchFacetSubOption = t.TypeOf<typeof SearchFacetSubOption>;

export type ISearchFacetOption = t.TypeOf<typeof SearchFacetOption>;

export type ISearchFacet = t.TypeOf<typeof SearchFacet>;

export type IReviewsBrand = t.TypeOf<typeof ReviewsBrand>;

export type IReview = t.TypeOf<typeof Review>;

export type IReviewResultsPage = t.TypeOf<typeof ReviewResultsPage>;

export type IWriteReviewTemplateField = t.TypeOf<
    typeof WriteReviewTemplateField
>;

export type IWriteReviewTemplate = t.TypeOf<typeof WriteReviewTemplate>;

export type IWriteReviewBlackBox = t.TypeOf<typeof WriteReviewBlackBox>;

export interface IWriteReviewData {
    id: string;
    merchant_user_email?: string;
    product_id?: string;
    variant?: string;
    blackbox: {
        io: string;
        igloo: string;
    };
    started: string;
    created: string;
    [fieldName: string]: string | IWriteReviewBlackBox | undefined;
}

export type IHistogramEntry = t.TypeOf<typeof HistogramEntry>;

export type IReviewsProduct = t.TypeOf<typeof ReviewsProduct>;

export type IReviewsProductVariant = t.TypeOf<typeof ReviewsProductVariant>;

export type IReviewsProductVariantChoices =
    | IReviewsProductVariant
    | null
    | typeof NOT_SURE_VARIANT_CHOICE_SLUG;

export interface IMultiFacetValueParam {
    facet: IReviewQueryFacets;
    value: string;
}

export interface IReviewsDisclosureSettings {
    [s: string]: string;
}

export type IUUIDReviewsInfoMap = Map<
    IProductUUID,
    t.TypeOf<typeof ReviewsInfoMap>
>;
