/* utility for rendering google product schema JSON blobs to improve SEO availability
 * https://developers.google.com/search/docs/data-types/product
 * */
import React from "react";
import {
    IImageURL,
    IProductUPC,
    IProductSKU,
    ISafeHTML,
} from "../models/nominals";

interface IProps {
    name: string;
    imageUrl?: IImageURL;
    description: ISafeHTML;
    sku: IProductUPC | IProductSKU;
    brandName: string;
    ratingValue: number | null;
    reviewCount: number;
}

interface IState {}

export class ProductSchemaTag extends React.Component<IProps, IState> {
    render() {
        const schema = JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": this.props.name,
            "image": [this.props.imageUrl],
            "description": this.props.description,
            "sku": this.props.sku,
            "brand": {
                name: this.props.brandName,
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": this.props.ratingValue,
                "reviewCount": this.props.reviewCount,
            },
        });

        return <script type="application/ld+json">{schema}</script>;
    }
}
export default ProductSchemaTag;
