import React from "react";
import { connect } from "react-redux";
import { IProductCategoryID } from "../../../models/nominals";
import { IProduct } from "../../../models/catalogue.interfaces";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { loadCategory } from "../../../api/products";
import {
    Action,
    IActionProductsLoadingStarted,
    IActionProductsLoadingDone,
} from "../reducers.interfaces";
import { GridFilterConfig } from "../filters";
import { GridSorterConfig } from "../sorters";
import { startHistoryListener } from "../history";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { TopNav } from "../elements/TopNav";
import { SideNav } from "../elements/SideNav";
import { GridContent } from "../elements/GridContent";
import { BreakPoint } from "../../../models/screen.interfaces";
import { getViewportBreakpoint } from "../../../utils/detectMobile";

import styles from "./ProductGrid.module.scss";

interface IOwnProps {
    gridVariant: string;
    productCategoryID: IProductCategoryID;
    filters: GridFilterConfig[];
    sorters?: GridSorterConfig[];
    hideSort?: boolean;
    disableHoverOverlay?: boolean;
    buildExternalCallout?: (rootProduct: IProduct) => React.ReactNode;
}

interface IReduxProps {
    isLoading: boolean;
}

interface IDispatchProps {
    onDidMount: () => void;
    onProductsLoadingStarted: () => void;
    onProductsLoadingDone: (products: IProduct[]) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

export class ProductGridComponent extends React.PureComponent<IProps, IState> {
    componentDidMount() {
        this.props.onDidMount();
        this.loadProducts(this.props.productCategoryID);
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.productCategoryID !== this.props.productCategoryID) {
            this.loadProducts(this.props.productCategoryID);
        }
    }

    private async loadProducts(productCategoryID: IProductCategoryID) {
        this.props.onProductsLoadingStarted();
        const products = await loadCategory(productCategoryID);
        this.props.onProductsLoadingDone(products);
    }

    private readonly isMobileOrTabletWidth = () => {
        const currentBreakpoint = getViewportBreakpoint();
        return currentBreakpoint < BreakPoint.LARGE;
    };

    render() {
        let content: React.ReactNode | null = null;
        if (this.props.isLoading) {
            content = <LoadingSpinner />;
        } else {
            content = (
                <>
                    <TopNav
                        gridVariant={this.props.gridVariant}
                        filters={this.props.filters}
                        sorters={this.props.sorters}
                        hideSort={this.props.hideSort}
                    />
                    <div className={styles.contentContainer}>
                        {!this.isMobileOrTabletWidth() && (
                            <SideNav
                                gridVariant={this.props.gridVariant}
                                filters={this.props.filters}
                            />
                        )}
                        <GridContent
                            gridVariant={this.props.gridVariant}
                            cardType="grid-card"
                            filters={this.props.filters}
                            sorters={this.props.sorters}
                            disableHoverOverlay={this.props.disableHoverOverlay}
                            buildExternalCallout={
                                this.props.buildExternalCallout
                            }
                        />
                    </div>
                </>
            );
        }
        return <div className={styles.container}>{content}</div>;
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        ...ownProps,
        isLoading: rootState.productgrid2?.isLoading,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    return {
        onDidMount: () => {
            startHistoryListener(dispatch);
        },
        onProductsLoadingStarted: () => {
            dispatch<IActionProductsLoadingStarted>({
                type: Action.PRODUCTS_LOADING_STARTED,
            });
        },
        onProductsLoadingDone: (products) => {
            dispatch<IActionProductsLoadingDone>({
                type: Action.PRODUCTS_LOADING_DONE,
                payload: products,
            });
        },
    };
};

export const ProductGrid = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductGridComponent);
