import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";
import { IViewport, BreakPoint } from "../../../models/screen.interfaces";
import {
    IProductID,
    ISafeHTML,
    isoSafeHTML,
    IWebPageURL,
} from "../../../models/nominals";
import { RichText } from "../../../common/RichText";
import { ProductCompareTileHeader } from "../elements/ProductCompareTileHeader";
import { getSelectedVariant } from "../selectors";
import {
    IAttributeOptionGroup,
    ICompareTile,
    IProductTileAttributeRow,
} from "../../../models/product-compare.interfaces";
import { ITileID, isoTileID } from "../../../models/product-compare";
import { ProductCompareTileAttributeRow } from "../elements/ProductCompareTileAttributeRow";
import iconCarouselArrowLeft from "../../../../img/icons/carousel-arrow-left.svg";
import iconCarouselArrowRight from "../../../../img/icons/carousel-arrow-right.svg";

interface IProps {
    header: ISafeHTML;
    tiles: ICompareTile[];
    basketLink?: IWebPageURL;
    financingLink?: IWebPageURL;
    preselectedSize?: string | null;
    sizeAttributeOptionGroup?: IAttributeOptionGroup | null;
    priceSelectValue: string;
    pageRootProduct?: IProductID | null;
    urlParams?: URLSearchParams;
    viewport: IViewport;
}

interface IState {
    currentPosition: number;
    selectedTileIDs: ITileID[];
    selectedTiles: ICompareTile[];
    tilesPerSlide: number;
    hideProductHeaderRow: boolean;
    accordionRows: {
        [id: number]: boolean;
    };
}

export class ProductCompareGrid__ColumnCard_Component extends React.Component<
    IProps,
    IState
> {
    public state: IState = {
        currentPosition: 0,
        selectedTileIDs: [],
        selectedTiles: this.props.tiles,
        tilesPerSlide: 5,
        hideProductHeaderRow: true,
        accordionRows: {},
    };

    private readonly compareTiles = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.props.urlParams && this.props.urlParams.get("tiles")) {
            this.buildSelectedTiles(this.props.urlParams);
        } else {
            const tileIDs = this.props.tiles.map(
                (tile: ICompareTile) => tile.product_compare_tile.id,
            );
            this.setState({
                selectedTileIDs: tileIDs,
            });
        }

        this.setAccordionRows();

        this.calculateTilesPerSlide();
        window.addEventListener("resize", this.calculateTilesPerSlide);
        window.addEventListener("scroll", this.handleProductHeaderVisibility);
    }

    private readonly handleProductHeaderVisibility = () => {
        const targetLine = this.compareTiles.current;
        if (targetLine) {
            this.setState({
                hideProductHeaderRow: targetLine.getClientRects()[0].bottom > 0,
            });
        }
    };

    private readonly onPrevSlideClick = () => {
        this.setState({
            currentPosition: this.state.currentPosition - 1,
        });
    };

    private readonly onNextSlideClick = () => {
        this.setState({
            currentPosition: this.state.currentPosition + 1,
        });
    };

    private readonly calculateTilesPerSlide = () => {
        if (this.props.viewport.width > BreakPoint.LARGE) {
            this.setState({
                tilesPerSlide: 5,
            });
        }

        if (this.props.viewport.width <= BreakPoint.LARGE) {
            this.setState({
                tilesPerSlide: 2,
            });
        }
    };

    private buildSelectedTiles(urlParams: URLSearchParams) {
        const selectedTileIDs = decodeURIComponent(urlParams.get("tiles") || "")
            .split(",")
            .map(Number)
            .map(isoTileID.wrap);
        const selectedTiles: ICompareTile[] = [];

        this.props.tiles.map((tile: ICompareTile) => {
            if (selectedTileIDs.includes(tile.product_compare_tile.id)) {
                selectedTiles.push(tile);
            }
        });

        this.setState({
            selectedTileIDs: selectedTileIDs,
            selectedTiles: selectedTiles,
        });
    }

    private setAccordionRows() {
        const accordionRows = this.state.accordionRows;
        this.state.selectedTiles[0].rows.map(
            (row: IProductTileAttributeRow) => {
                accordionRows[row.row_type.id] = true;
            },
        );
        this.setState({
            accordionRows: accordionRows,
        });
    }

    private buildViewableTiles(): ICompareTile[] {
        const increment = this.state.currentPosition + this.state.tilesPerSlide;

        if (
            this.state.selectedTiles.length - this.state.currentPosition ===
            1
        ) {
            return this.state.selectedTiles.slice(
                this.state.currentPosition - 1,
                increment,
            );
        }
        return this.state.selectedTiles.slice(
            this.state.currentPosition,
            increment,
        );
    }

    private buildCompareTiles() {
        const viewableTiles = this.buildViewableTiles();

        return viewableTiles.map((tile: ICompareTile) => {
            const variant = getSelectedVariant(
                tile,
                this.props.priceSelectValue,
            );
            return (
                <div className="tile" key={`${tile.product_compare_tile.id}`}>
                    <ProductCompareTileHeader
                        data={tile}
                        selectedVariant={variant}
                        pageRootProduct={this.props.pageRootProduct}
                        showRating={false}
                    />
                    {this.buildAttributeRows(tile)}
                </div>
            );
        });
    }

    private buildAttributeRows(tile: ICompareTile) {
        return tile.rows.map((row: IProductTileAttributeRow) => {
            return (
                <div
                    key={row.row_type.id}
                    className={`product-attribute-row ${row.row_type.row_type}`}
                >
                    <h4>{row.row_type.title}</h4>
                    <div>
                        {this.buildAttributeRow(tile, row)}
                        {this.buildChildRows(tile, row.children)}
                    </div>
                </div>
            );
        });
    }

    private buildChildRows(
        tile: ICompareTile,
        rows: IProductTileAttributeRow[],
    ) {
        return rows.map((row: IProductTileAttributeRow) => {
            return (
                <div
                    className={`child-row product-attribute-row ${row.row_type.row_type}`}
                    key={`${row.row_type.id}-child-row`}
                >
                    {this.buildAttributeRow(tile, row)}
                </div>
            );
        });
    }

    private buildAttributeRow(
        tile: ICompareTile,
        row: IProductTileAttributeRow,
    ) {
        return (
            <ProductCompareTileAttributeRow
                key={`${tile.product_compare_tile.id}-${row.row_type.id}`}
                row={row}
                data={tile}
                basketLink={this.props.basketLink}
                financingLink={this.props.financingLink}
                priceSelectValue={this.props.priceSelectValue}
                sizeAttributeOptionGroup={
                    this.props.sizeAttributeOptionGroup
                        ? this.props.sizeAttributeOptionGroup
                        : null
                }
            />
        );
    }

    private buildHeaderContent(): ISafeHTML {
        if (this.props.urlParams) {
            let href = decodeURIComponent(
                this.props.urlParams.get("return_url") || "",
            );
            if (href) {
                if (this.props.urlParams.get("tiles")) {
                    href = `${href}?tiles=${encodeURIComponent(
                        this.state.selectedTileIDs.join(","),
                    )}`;
                }
                const link = `<a class="al-compareresults-changeselection" href="${href}">${t`Add/Change Selection`}</a>`;
                return isoSafeHTML.wrap(`${this.props.header}${link}`);
            }
        }
        return this.props.header;
    }

    private renderSlideControls() {
        if (this.props.viewport.width > BreakPoint.LARGE) {
            return null;
        }
        if (this.state.selectedTiles.length <= 2) {
            return null;
        }
        return (
            <nav>
                <button
                    aria-label={t`Previous`}
                    disabled={this.state.currentPosition === 0}
                    onClick={this.onPrevSlideClick}
                >
                    <SVG aria-hidden="true" src={iconCarouselArrowLeft} />
                </button>
                <button
                    aria-label={t`next`}
                    disabled={
                        this.state.currentPosition + this.state.tilesPerSlide >=
                        this.state.selectedTiles.length
                    }
                    onClick={this.onNextSlideClick}
                >
                    <SVG aria-hidden="true" src={iconCarouselArrowRight} />
                </button>
            </nav>
        );
    }

    render() {
        return (
            <>
                <header className="product-compare-grid-block-header">
                    <RichText html={this.buildHeaderContent()} />
                </header>
                {this.renderSlideControls()}
                <div className="product-compare-tiles" ref={this.compareTiles}>
                    {this.buildCompareTiles()}
                </div>
            </>
        );
    }
}
