import React from "react";
import { strings } from "../../../localization";
import { ILineOptionGroupingData } from "../selectors";

interface IProps {
    groups: ILineOptionGroupingData[];
}

interface IState {}

export class ExpeditedShippingDisclosure extends React.Component<
    IProps,
    IState
> {
    render() {
        // Detect if UPS shipping is an option
        const hasUPSOption = this.props.groups.reduce((memoA, group) => {
            return group.methods.reduce((memoB, method) => {
                return memoB || method.name.indexOf("UPS") !== -1;
            }, memoA);
        }, false);

        // Detect if UPS 2 Day or Next Day shipping is an option
        // If the name of either shipping method ever changes in oscar, this will break
        const hasFastUPSOption = this.props.groups.reduce((memoA, group) => {
            return group.methods.reduce((memoB, method) => {
                return memoB || method.name.indexOf("DAY") !== -1;
            }, memoA);
        }, false);

        // If UPS shipping isn't available or is the only option, don't show this disclosure
        if (!hasUPSOption || !hasFastUPSOption) {
            return null;
        }

        return (
            <div>
                <h4>
                    <strong>Get it faster</strong>
                </h4>
                <p>{strings.get("EXPEDITED_SHIPPING_EXCEPTIONS")}</p>
            </div>
        );
    }
}
