import React from "react";
import { Provider } from "react-redux";
import {
    isoProductID,
    isoImageURL,
    isoWebPageURL,
} from "tsi-common-react/src/models/nominals";
import {
    IOptionCode,
    IBasketLine,
} from "tsi-common-react/src/models/catalogue.interfaces";
import { dynamicPlaceComponent } from "tsi-common-react/src/utils/react";
import { check } from "tsi-common-react/src/models/utils";
import { readyStateComplete } from "tsi-common-react/src/utils/events";
import { isZipLocation } from "tsi-common-react/src/utils/guards";
import { store, rehydratingStore } from "../store";
import * as pdpModels from "./models";
import * as streamfieldModels from "tsi-common-react/src/models/streamfield-blocks";
import * as basketAPI from "tsi-common-react/src/api/basket";
import { getProductShippingMethodsByLocation } from "tsi-common-react/src/api/products";

/**
 * Load CMS data and the initially selected product variant
 */
const detailContainerElem = document.querySelector<HTMLDivElement>(
    "#product-detail-container[data-cms]",
);
let cmsData: pdpModels.IProductPage | null = null;
cmsData = detailContainerElem
    ? check(
          pdpModels.ProductPage.decode(
              JSON.parse(detailContainerElem.dataset.cms || ""),
          ),
      )
    : null;

const shouldShowUpsellModal = async () => {
    if (
        cmsData &&
        cmsData.bundle_modal_serialized &&
        cmsData.bundle_modal_serialized.length >= 0
    ) {
        for (const bundle of cmsData.bundle_modal_serialized) {
            if (bundle.slides) {
                for (const slide of bundle.slides) {
                    if (
                        slide.slide_bundle &&
                        slide.slide_bundle.suggested_products_info
                    ) {
                        for (const suggestedProduct of slide.slide_bundle
                            .suggested_products_info) {
                            // A special condition to display an upsell modal for Mattress Removal Add-On Fee: https://thelabnyc.plan.io/issues/24558
                            // The goal is to display the modal only when the customer is inside Sleep Outfitters Footprint. To check this, all the settings are
                            // configurable via CMS ("Bundles" section in product pages in Wagtail) and Oscar (adding/removing postcodes to the delivery footprint)
                            // except that the product "Mattress Removal Fee" definitely needs to be a member of "Mattress Removal" product shipping class.
                            if (
                                suggestedProduct.shipping_class_name ===
                                "Mattress Removal"
                            ) {
                                const basket = await basketAPI.load();
                                // If mattress removal fee is already included in the basket, don't show the upsell model for it.
                                if (
                                    basket.lines.some(
                                        (line: IBasketLine) =>
                                            line.product.id ===
                                            suggestedProduct.product_id,
                                    )
                                ) {
                                    return false;
                                }
                                const state = store.getState();
                                // First attempt to get the postcode entered by the user if available. If not, try to fetch the detected postcode. If still not, no truthy value
                                // will be sent and the backend will try to use the postcode in the shipping address cache of the basket to return the shipping methods by postcode
                                const postCode =
                                    state.common.enteredLocation &&
                                    isZipLocation(state.common.enteredLocation)
                                        ? state.common.enteredLocation.zip
                                        : state.common.detectedLocation?.zip;
                                const resp =
                                    await getProductShippingMethodsByLocation(
                                        suggestedProduct.product_id,
                                        postCode,
                                    );
                                return resp.length > 0;
                            }
                        }
                    }
                }
            }
        }
        return true;
    }
    return false;
};

/**
 * Render Configurators
 */
dynamicPlaceComponent(
    '[data-place-react="split-channel-configurator"]',
    async (elem) => {
        const { SplitChannelConfigurator } = await import(
            "tsi-common-react/src/apps/configurator/containers/SplitChannelConfigurator"
        );
        const { UnifyPDPWidget } = await import(
            "tsi-common-react/src/apps/synchrony/UnifyPDPWidget"
        );
        // This should be refactored into Common - https://thelabnyc.plan.io/issues/24870
        const { UpsellModal } = await import("./UpsellModal");
        // Wait for the document to load, this needs to render multi product options properly
        await readyStateComplete;
        await rehydratingStore;
        const displayUpsellModal = await (async () =>
            await shouldShowUpsellModal())();
        return (
            <Provider store={store}>
                <SplitChannelConfigurator
                    optionSetJSON={elem.dataset.configuratorInitData || ""}
                    title={cmsData ? cmsData.title : ""}
                    starRatingURL={isoWebPageURL.wrap("#reviews")}
                    promoComponents={<UnifyPDPWidget />}
                    deliveryIsFree={false}
                    showUpsellModal={() => displayUpsellModal}
                    getUpsellModalComponentClass={() => {
                        return UpsellModal;
                    }}
                />
            </Provider>
        );
    },
);

/**
 * Render PDPChangeRootProductBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-change-root-product-block"]',
    async () => {
        const { PDPChangeRootProductBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPChangeRootProductBlock"
        );
        return (
            <Provider store={store}>
                <PDPChangeRootProductBlock />
            </Provider>
        );
    },
);

/**
 * Render ChangeVariantOptionBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-change-variant-option-block"]',
    async (elem) => {
        const { PDPChangeVariantOptionBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPChangeVariantOptionBlock"
        );
        const attributeCode = (elem.dataset.attributeCode ||
            "option_feel") as IOptionCode;
        return (
            <Provider store={store}>
                <PDPChangeVariantOptionBlock attributeCode={attributeCode} />
            </Provider>
        );
    },
);

/**
 * Render SelectedRootProductConditionalBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-selected-root-product-conditional-block"]',
    async (elem) => {
        const { PDPSelectedRootProductConditionalBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPSelectedRootProductConditionalBlock"
        );
        const productID = isoProductID.wrap(
            parseInt(elem.dataset.product || "0", 10),
        );
        const htmlContent = elem.innerHTML || "";
        // Show the root element
        elem.style.display = "block";
        return (
            <Provider store={store}>
                <PDPSelectedRootProductConditionalBlock
                    blockProductID={productID}
                    content={htmlContent}
                />
            </Provider>
        );
    },
);

/**
 * Render SelectedVariantConditionalBlock's from the PDP StreamField
 */
dynamicPlaceComponent(
    '[data-place-react="pdp-selected-variant-conditional-block"]',
    async (elem) => {
        const { PDPSelectedVariantConditionalBlock } = await import(
            "tsi-common-react/src/apps/configurator/containers/PDPSelectedVariantConditionalBlock"
        );
        const attributeCode = (elem.dataset.attributeCode ||
            "option_feel") as IOptionCode;
        const attributeValue = elem.dataset.attributeValue || "";
        const htmlContent = elem.innerHTML || "";
        // Show the root element
        elem.style.display = "block";
        return (
            <Provider store={store}>
                <PDPSelectedVariantConditionalBlock
                    attributeCode={attributeCode}
                    attributeValue={attributeValue}
                    content={htmlContent}
                />
            </Provider>
        );
    },
);

/**
 * Render TabCardsBlock's from the PDP StreamField
 */
dynamicPlaceComponent('[data-place-react="tab-cards-block"]', async (elem) => {
    const { TabCardsBlock } = await import(
        "tsi-common-react/src/blocks/containers/TabCardsBlock"
    );
    const tabCards = check(
        streamfieldModels.TabCardsBlock.decode(
            JSON.parse(elem.dataset.cms || ""),
        ),
    );
    return (
        <Provider store={store}>
            <TabCardsBlock
                tabCards={tabCards}
                theme={tabCards.theme.theme || ""}
            />
        </Provider>
    );
});

/**
 * Render GalleryBlock
 */
dynamicPlaceComponent('[data-place-react="gallery-block"]', async (elem) => {
    const { GalleryBlock } = await import(
        "tsi-common-react/src/apps/gallery/components/GalleryBlock"
    );
    return (
        <Provider store={store}>
            <GalleryBlock
                galleriesJSON={elem.dataset.galleries || "{}"}
                defaultThumbnail={isoImageURL.wrap(
                    elem.dataset.videoThumbnail || "",
                )}
            />
        </Provider>
    );
});
