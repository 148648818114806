import * as t from "io-ts";
import { RecordKeyType, PartialRecord } from "./utils";

const BaseErrorSet = t.partial({
    code: t.string,
    detail: t.string,
    non_field_errors: t.array(t.string),
});
type BaseErrorSet = t.TypeOf<typeof BaseErrorSet>;

export const ErrorFieldValue = t.union([
    t.string,
    t.array(t.string),
    t.undefined,
]);
export type ErrorFieldValue = t.TypeOf<typeof ErrorFieldValue>;

export const ErrorSet = t.intersection([
    BaseErrorSet,
    t.record(t.string, ErrorFieldValue),
]);
export type ErrorSet = t.TypeOf<typeof ErrorSet>;

export const GenericErrorSet = <T extends RecordKeyType>(fieldNames: T) => {
    return t.intersection([
        BaseErrorSet,
        PartialRecord(fieldNames, ErrorFieldValue),
    ]);
};
export type GenericErrorSet<T extends string> = BaseErrorSet & {
    [K in T]?: ErrorFieldValue;
};
