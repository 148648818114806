import React from "react";
import { isFuture, min, isBefore, format as formatDT } from "date-fns";
import { t } from "ttag";
import { IRetailStoreWithDistance } from "../models/location.interfaces";
import { Trans } from "./Trans";
import { toDateWithoutTimezone } from "../utils/time";

const OpenUntil = (props: { date: Date }) => (
    <Trans
        fmtString={t`Open until <Time></Time>`}
        data={{
            Time: () => (
                <time key="1" dateTime={props.date.toISOString()}>
                    {formatDT(props.date, "h:mmaaa")}
                </time>
            ),
        }}
    />
);

const OpensAt = (props: { date: Date }) => (
    <Trans
        fmtString={t`Opens at <Time></Time> on <Day></Day>`}
        data={{
            Time: () => (
                <time key="1" dateTime={props.date.toISOString()}>
                    {formatDT(props.date, "h:mmaaa")}
                </time>
            ),
            Day: () => (
                <time key="2" dateTime={props.date.toISOString()}>
                    {formatDT(props.date, "eeee")}
                </time>
            ),
        }}
    />
);

export const RetailStoreOpenStatus = (props: {
    store: IRetailStoreWithDistance | null;
}) => {
    if (
        !props.store?.upcoming_hours ||
        props.store.upcoming_hours.length <= 0
    ) {
        return null;
    }
    const periods = props.store.upcoming_hours.map((p) => {
        return {
            opens: toDateWithoutTimezone(p.opens),
            closes: toDateWithoutTimezone(p.closes),
        };
    });
    const futureOpens = periods.map((p) => p.opens).filter(isFuture);
    const futureCloses = periods.map((p) => p.closes).filter(isFuture);
    const nextOpens = min(futureOpens);
    const nextCloses = min(futureCloses);
    if (isNaN(nextOpens.getTime())) {
        return null;
    }
    if (isBefore(nextCloses, nextOpens)) {
        return (
            <p className="u-store-indicator u-store-indicator--open">
                <OpenUntil date={nextCloses} />
            </p>
        );
    }
    return (
        <p className="u-store-indicator u-store-indicator--opening">
            <OpensAt date={nextOpens} />
        </p>
    );
};
