import React from "react";
import { IFuzzyDurationField } from "../models/formFields.interfaces";
import { AbstractFormField, IFormFieldState } from "./AbstractFormField";

const pad = (num: number) => {
    return `0${num}`.slice(-2);
};

export class FormFuzzyDurationSelect<
    T extends string,
> extends AbstractFormField<
    T,
    HTMLInputElement | HTMLSelectElement,
    IFuzzyDurationField<T>,
    IFormFieldState
> {
    private readonly NUM_YEARS = 100;
    private readonly NUM_MONTHS = 12;

    private monthSelectElem: HTMLSelectElement | undefined | null;
    private yearSelectElem: HTMLSelectElement | undefined | null;

    public static defaultProps = {
        type: "fuzzy-duration",
    };

    public state: IFormFieldState = {
        focused: false,
    };

    protected updateValueAttribute() {
        this.value = (this.getInputElem() as HTMLInputElement).value;
    }

    private buildYearOptions() {
        const yearOptions: JSX.Element[] = [];
        for (let years = 0; years < this.NUM_YEARS; years++) {
            yearOptions.push(
                <option key={years} value={pad(years)}>
                    {years === 1 ? `${years} year` : `${years} years`}
                </option>,
            );
        }
        return yearOptions;
    }

    private buildMonthOptions() {
        const monthOptions: JSX.Element[] = [];
        for (let months = 0; months < this.NUM_MONTHS; months++) {
            monthOptions.push(
                <option key={months} value={pad(months)}>
                    {months === 1 ? `${months} month` : `${months} months`}
                </option>,
            );
        }
        return monthOptions;
    }

    private getRawInputElem() {
        return this.getInputElem() as HTMLInputElement;
    }

    private buildChangeEvent() {
        const target = this.getRawInputElem();
        if (!target) {
            return;
        }

        const event: React.FormEvent<HTMLInputElement> = {
            bubbles: true,
            currentTarget: target,
            cancelable: false,
            defaultPrevented: true,
            eventPhase: 0,
            isTrusted: true,
            nativeEvent: new Event("InputEvent"),
            preventDefault: () => {},
            isDefaultPrevented: () => {
                return true;
            },
            stopPropagation: () => {},
            isPropagationStopped: () => {
                return true;
            },
            persist: () => {},
            target: target,
            timeStamp: new Date().getTime(),
            type: "onChange",
        };
        return event;
    }

    private computeInputValue() {
        if (!this.monthSelectElem || !this.yearSelectElem) {
            return "0000";
        }
        return `${this.monthSelectElem.value}${this.yearSelectElem.value}`;
    }

    protected buildControl() {
        // Get props for the hidden input. Never set a value because we need it to be an unmanaged input.
        const inputProps = {
            ...this.getInputProps(),
            type: "hidden",
            onChange: undefined,
            onBlur: undefined,
            onFocus: undefined,
            value: undefined,
        };

        // Make changing the select elements update the value of the hidden input and then
        // use the input to send a synthetic change event.
        const selectProps = {
            ...this.getInputProps(),
            onChange: () => {
                // Update hidden input's value
                const input = this.getRawInputElem();
                if (!input) {
                    return;
                }
                // Compute and set the input value from the values of the two selects
                input.value = this.computeInputValue();
                this.updateValueAttribute();
                // Trigger a change event
                const event = this.buildChangeEvent();
                if (!event) {
                    return;
                }
                this.onChange(event);
            },
        };

        // Make sure value is a string, not undefined or null
        selectProps.value = selectProps.value || "";

        return (
            <div>
                <select
                    ref={(ref) => {
                        this.yearSelectElem = ref;
                    }}
                    {...selectProps}
                    name={`${selectProps.name}_years`}
                    data-testid={`${selectProps.name}_years`}
                    value={selectProps.value.slice(-2)}
                >
                    {this.buildYearOptions()}
                </select>

                <select
                    ref={(ref) => {
                        this.monthSelectElem = ref;
                    }}
                    {...selectProps}
                    name={`${selectProps.name}_months`}
                    data-testid={`${selectProps.name}_months`}
                    value={selectProps.value.slice(0, 2)}
                >
                    {this.buildMonthOptions()}
                </select>

                <input
                    ref={(ref) => {
                        this.inputElem = ref;
                    }}
                    {...inputProps}
                />
            </div>
        );
    }
}
