/**
 * Activate close buttons for global messages (from the Django messages framework)
 */
export const initMessageCloseButton = async (button: HTMLElement) => {
    button.addEventListener("click", (event) => {
        event.preventDefault();
        // Find the root message element
        let messageElem = button.parentElement;
        while (
            messageElem &&
            !messageElem.classList.contains("messages__item") &&
            messageElem.parentElement
        ) {
            messageElem = messageElem.parentElement;
        }
        if (!messageElem) {
            return;
        }
        // Hide the message
        messageElem.setAttribute("aria-hidden", "true");
        messageElem.classList.add("messages__item--hidden");
        // Remove empty space after opacity animation is completed and visibility is set to hidden.
        setTimeout(() => {
            if (messageElem) {
                messageElem.classList.add("messages__item--closed");
            }
        }, 330);
    });
};
