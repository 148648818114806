import * as t from "io-ts";
import { nullable, codecFromEnum } from "../../models/utils";
import { OptionCode, OptionSingleValue } from "../../models/catalogue";
import {
    StreamValue,
    ImageChooserBlock,
    StructBlock,
    CharBlock,
    ProductChooserSubBlock,
    FlairSubBlock,
    RichTextBlock,
    ProductOptionValueChoiceBlock,
    ListBlock,
    SpacingSubBlock,
} from "../../models/streamfield-blocks";
import {
    ProductID,
    ProductIDs,
    ProductCategoryID,
    SafeHTML,
    ProductAPIURL,
    ImageURL,
} from "../../models/nominals";
import { ValuePropBarBlock } from "../../models/value-props";
import {
    BaseCompareTile,
    ProductCompareRowType,
} from "../../models/product-compare";
import { ConfiguratorTypes } from "../../constants";
import { RootProductSelectorInputType, DiscountDisplayMode } from "./constants";

export const RootProductCompareModalContent = t.interface({
    products: t.array(BaseCompareTile),
    rows: t.array(
        t.interface({
            row_type: ProductCompareRowType,
        }),
    ),
});

export const VariantPrefilter = t.interface({
    attr: OptionCode,
    value: OptionSingleValue,
});

/**
 * See model: tsicommon.cms.modular_configurator.ModularConfiguratorOptionSet
 */
export const ModularConfiguratorOptionSet = t.interface({
    product_ids: ProductIDs,
    initial_variant_id: nullable(ProductID),
    variant_prefilter: nullable(VariantPrefilter),
    root_product_selector: t.interface({
        input_type: codecFromEnum(
            "RootProductSelectorInputType",
            RootProductSelectorInputType,
        ),
        label: t.string,
        enable_ratings: t.boolean,
    }),
    layout: t.interface({
        design: codecFromEnum("ConfiguratorTypes", ConfiguratorTypes),
        enable_star_rating: t.boolean,
        enable_quantity_selector: t.boolean,
        enable_prequal: t.boolean,
        enable_chat: t.boolean,
        enable_predicted_date: t.boolean,
        shipping_callout_icon: nullable(ImageChooserBlock),
        shipping_callout_content: nullable(t.string),
        dynamic_shipping_time_messaging: nullable(
            t.interface({
                title: t.string,
                monday_time: t.string,
                monday_before_message: t.string,
                monday_after_message: t.string,
                tuesday_time: t.string,
                tuesday_before_message: t.string,
                tuesday_after_message: t.string,
                wednesday_time: t.string,
                wednesday_before_message: t.string,
                wednesday_after_message: t.string,
                thursday_time: t.string,
                thursday_before_message: t.string,
                thursday_after_message: t.string,
                friday_time: t.string,
                friday_before_message: t.string,
                friday_after_message: t.string,
                saturday_time: t.string,
                saturday_before_message: t.string,
                saturday_after_message: t.string,
                sunday_time: t.string,
                sunday_before_message: t.string,
                sunday_after_message: t.string,
            }),
        ),
        shipping_message: nullable(
            t.interface({
                header: nullable(t.string),
                footer: nullable(t.string),
                messages: nullable(t.array(t.string)),
            }),
        ),
        review_aggregation: nullable(
            t.interface({
                review_aggregation_copy: t.string,
                checkmark_icon: nullable(ImageChooserBlock),
                modal_content: nullable(t.string),
                modal_image: nullable(ImageChooserBlock),
            }),
        ),
    }),
    discounts: t.interface({
        discount_mode: codecFromEnum(
            "DiscountDisplayMode",
            DiscountDisplayMode,
        ),
        percentage_discount_format: t.string,
        absolute_discount_format: t.string,
    }),
    root_product_compare_modal_title: nullable(t.string),
    root_product_compare_modal: nullable(RootProductCompareModalContent),
});

/**
 * See block: tsicommon.cms.blocks.modular_configurator._UpsellInfoModalImageSlide
 */
export const UpsellInfoModalImageSlide = StructBlock(
    {},
    {
        image: nullable(ImageChooserBlock),
    },
);

/**
 * See block: tsicommon.cms.blocks.modular_configurator._UpsellInfoModalImageGallery
 */
export const UpsellInfoModalImageGallery = StructBlock(
    {
        slides: ListBlock(UpsellInfoModalImageSlide),
    },
    {},
);

/**
 * See block: tsicommon.cms.blocks.modular_configurator._UpsellInfoModalFeature
 */
export const UpsellInfoModalFeature = StructBlock(
    {
        content: CharBlock,
    },
    {},
);

/**
 * See block: tsicommon.cms.blocks.modular_configurator._UpsellInfoModalFeaturesBlock
 */
export const UpsellInfoModalFeaturesBlock = StructBlock(
    {
        heading: CharBlock,
        list_icon: nullable(ImageChooserBlock),
        features: ListBlock(UpsellInfoModalFeature),
    },
    {},
);

/**
 * See block: tsicommon.cms.blocks.modular_configurator._UpsellInfoModalSegmentedRichTextBlock
 */
export const UpsellInfoModalSegmentedRichTextBlock = StructBlock(
    {
        segments: ListBlock(RichTextBlock),
    },
    {},
);

/**
 * See block: tsicommon.cms.blocks.modular_configurator._UpsellInfoModalRichTextBlock
 */
export const UpsellInfoModalRichTextBlock = StructBlock(
    {
        heading: nullable(CharBlock),
        content: nullable(RichTextBlock),
    },
    {},
);

/**
 * See block: tsicommon.cms.blocks.modular_configurator.ModularConfigurator_UpsellInfoModalContent
 */
export const ModularConfigurator_UpsellInfoModalContent = t.union([
    StreamValue("features", UpsellInfoModalFeaturesBlock),
    StreamValue("image_gallery", UpsellInfoModalImageGallery),
    StreamValue("rich_text", UpsellInfoModalRichTextBlock),
    StreamValue("segmented_rich_text", UpsellInfoModalSegmentedRichTextBlock),
    StreamValue("value_props", ValuePropBarBlock),
]);

/**
 * See block: tsicommon.cms.modular_configurator.ModularConfigurator_UpsellInfoModal
 */
export const ModularConfigurator_UpsellInfoModalProduct = t.interface({
    product: ProductAPIURL,
    flair: nullable(ListBlock(StreamValue("flair", FlairSubBlock))),
    content: t.array(ModularConfigurator_UpsellInfoModalContent),
});

/**
 * See block: tsicommon.cms.modular_configurator.ModularConfigurator_UpsellInfoModal
 */
export const ModularConfiguratorUpsellInfoModal = t.interface({
    id: t.number,
    bundle: t.number,
    bundle_type: t.string,
    bundle_description: t.string,
    card_flair: nullable(ListBlock(StreamValue("flair", FlairSubBlock))),
    upsell_info_products: t.array(ModularConfigurator_UpsellInfoModalProduct),
});

export const ModularConfiguratorUpsellInfoModals = t.array(
    ModularConfiguratorUpsellInfoModal,
);

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.InfoPanel
 */
export const PLCInfoPanel = t.interface({
    body: SafeHTML,
});

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.CategorySelectorOption
 */
export const PLCProductCategorySelectorOption = t.interface({
    category: t.interface({
        id: ProductCategoryID,
        name: t.string,
    }),
    image: nullable(ImageChooserBlock),
});

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.CategorySelector
 */
export const PLCProductCategorySelector = StreamValue(
    "selector",
    t.interface({
        name: t.string,
        helptext: SafeHTML,
        learn_more: PLCInfoPanel,
        options: t.array(PLCProductCategorySelectorOption),
    }),
);
export const PLCProductCategorySelectors = t.array(PLCProductCategorySelector);

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.BooleanOptionSelectorOption
 */
export const PLCBooleanOptionSelectorOption = t.interface({
    name: t.string,
    attribute: OptionCode,
    show_in_match_model: t.boolean,
    description: t.string,
    image: nullable(ImageChooserBlock),
    help_content: PLCInfoPanel,
});

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.OtherOptionSelectorOption
 */
const PLCOtherOptionSelectorOption = t.interface({
    name: t.string,
    value: t.string,
    description: t.string,
    image: nullable(ImageChooserBlock),
    help_content: PLCInfoPanel,
});

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.MattressSizeOptionSelector
 */
export const PLCMattressSizeOptionSelector = StreamValue(
    "mattress_size",
    t.interface({
        attribute: OptionCode,
        show_in_match_model: t.boolean,
        help_content: PLCInfoPanel,
    }),
);

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.MattressFeelOptionSelector
 */
export const PLCMattressFeelOptionSelector = StreamValue(
    "mattress_feel",
    t.interface({
        attribute: OptionCode,
        show_in_match_model: t.boolean,
        help_content: PLCInfoPanel,
    }),
);

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.BooleanOptionSelectorGroup
 */
export const PLCBooleanOptionSelectorGroup = StreamValue(
    "boolean_option_group",
    t.interface({
        selectors: t.array(PLCBooleanOptionSelectorOption),
    }),
);

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.OtherOptionSelector
 */
export const PLCOtherOptionSelector = StreamValue(
    "other",
    t.interface({
        name: t.string,
        attribute: OptionCode,
        show_in_match_model: t.boolean,
        options: t.array(PLCOtherOptionSelectorOption),
    }),
);

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.ProductOptionPanelSelectors
 */
export const PLCProductOptionSelector = t.union([
    PLCMattressSizeOptionSelector,
    PLCMattressFeelOptionSelector,
    PLCBooleanOptionSelectorGroup,
    PLCOtherOptionSelector,
]);

/**
 * See streamfield block: tsicommon.cms.blocks.product_line_collection.ProductOptionPanel
 */
export const PLCOptionPanel = StreamValue(
    "panel",
    t.interface({
        short_name: t.string,
        long_name: t.string,
        selectors: t.array(PLCProductOptionSelector),
    }),
);
export const PLCOptionPanels = t.array(PLCOptionPanel);

/**
 * See streamfield block: tsicommon.cms.blocks.tab_feature_attribute_block._FeatureAttributeModel
 */
export const TabFeatureAttributeBlock_ModelTab = StructBlock(
    {
        model: ProductChooserSubBlock,
        model_name: t.string,
        image: ImageChooserBlock,
        content: RichTextBlock,
    },
    {
        image_mobile: ImageChooserBlock,
        flair: FlairSubBlock,
    },
);

/**
 * See streamfield block: tsicommon.cms.blocks.tab_feature_attribute_block._FeatureAttribute
 */
export const TabFeatureAttributeBlock_AttributeTab = StructBlock(
    {
        attribute_value: ProductOptionValueChoiceBlock,
        icon: ImageChooserBlock,
        tabs: ListBlock(TabFeatureAttributeBlock_ModelTab),
    },
    {},
);

/**
 * See streamfield block: tsicommon.cms.blocks.tab_feature_attribute_block.TabFeatureAttributeBlock
 */
export const TabFeatureAttributeBlock = StructBlock(
    {
        spacing: SpacingSubBlock,
        header_content: RichTextBlock,
        attribute: OptionCode,
        tabs: ListBlock(TabFeatureAttributeBlock_AttributeTab),
    },
    {},
);

export const ModularConfiguratorPromoCardInfoModal = t.interface({
    flair: nullable(ListBlock(StreamValue("flair", FlairSubBlock))),
    image: nullable(ImageURL),
    title: nullable(t.string),
    content: t.array(ModularConfigurator_UpsellInfoModalContent),
});

export const ModularConfiguratorPromoCard = t.interface({
    id: t.number,
    section_title: nullable(t.string),
    product: nullable(ProductID),
    card_title: nullable(t.string),
    product_name: nullable(t.string),
    description: nullable(t.string),
    card_image_url: nullable(ImageURL),
    flair: nullable(ListBlock(StreamValue("flair", FlairSubBlock))),
    info_modal: nullable(ModularConfiguratorPromoCardInfoModal),
});

export const ModularConfiguratorPromoCards = t.array(
    ModularConfiguratorPromoCard,
);
