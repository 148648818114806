import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import React from "react";
import {
    RichNavigationLinkTypes,
    RichNavigationLinkCollection as RichNavigationLinkCollectionValue,
    RichNavigationBannerContentAssembly,
} from "@reactivated";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";
import { RichNavigationGeneralLink } from "../RichNavigationGeneralLink";
import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";
import { RichNavigationButtonLink } from "../RichNavigationButtonLink";
import { RichNavigationOfferLink } from "../RichNavigationOfferLink";
import { RichNavigationProductLink } from "../RichNavigationProductLink";
import { RichNavigationDynamicProductLink } from "../RichNavigationDynamicProductLink";
import { RichNavigationImageLink } from "../RichNavigationImageLink";
import { RichNavigationBannerLink } from "../RichNavigationBannerLink";
import {
    classNames,
    concatClassNames,
} from "@thelabnyc/thelabui/src/utils/styles";
import { useCurrentBreakpoint } from "../../../utils/hooks";
import { RichNavigationVariantOptions } from "../constants";
import { bannerContentAssemblyComponents } from "../RichNavigation";

import styles from "./index.module.scss";
import sharedStyles from "../shared.module.scss";
import { RichNavigationImagePromo } from "../RichNavigationPromoFeatures";

const richNavigationLinkTypeComponents: StreamFieldBlockMap<RichNavigationLinkTypes> =
    {
        rich_nav_general_link: RichNavigationGeneralLink,
        rich_nav_offer_link: RichNavigationOfferLink,
        rich_nav_product_link: RichNavigationProductLink,
        rich_nav_dynamic_product_link: RichNavigationDynamicProductLink,
        rich_nav_image_link: RichNavigationImageLink,
        rich_nav_banner_link: RichNavigationBannerLink,
        rich_nav_button_link: RichNavigationButtonLink,
    };

export interface RichNavigationLinkCollectionContentProps {
    value: RichNavigationLinkCollectionValue;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export interface WrapperProps {
    collection: RichNavigationLinkCollectionContentProps;
    banner_content: RichNavigationBannerContentAssembly | null;
}

const MenuTrigger = (props: { children: React.ReactNode }) => {
    const viewport = useCurrentBreakpoint();
    if (viewport.belowMobile) {
        return <>{props.children}</>;
    }
    return (
        <NavigationMenu.Trigger
            className={concatClassNames([
                sharedStyles.hoverEffect,
                styles.trigger,
            ])}
        >
            {props.children}
        </NavigationMenu.Trigger>
    );
};

const MenuContent = (props: {
    children: React.ReactNode;
    hasBannerContent: boolean;
}) => {
    const viewport = useCurrentBreakpoint();
    if (viewport.belowMobile) {
        return <>{props.children}</>;
    }
    return (
        <NavigationMenu.Content
            className={classNames([
                [styles.content, true],
                [styles.hasBannerContent, props.hasBannerContent],
            ])}
        >
            {props.children}
        </NavigationMenu.Content>
    );
};

export const RichNavigationLinkCollection = (props: WrapperProps) => {
    return (
        <section
            className={concatClassNames([
                "rich-nav-link-collection",
                props.collection.value.variant ??
                    RichNavigationVariantOptions.DEFAULT,
            ])}
        >
            <MenuTrigger>
                <h2 className={styles.link}>{props.collection.value.name}</h2>
            </MenuTrigger>
            <MenuContent
                hasBannerContent={
                    props.banner_content
                        ? props.banner_content.length > 0
                        : false
                }
            >
                <RichNavigationLinkCollectionContent {...props.collection} />
                {props.banner_content && (
                    <div className={styles.bannerWrapper}>
                        <StreamField
                            tagName={React.Fragment}
                            components={bannerContentAssemblyComponents}
                            value={props.banner_content}
                        />
                    </div>
                )}
            </MenuContent>
        </section>
    );
};

export const RichNavigationLinkCollectionContent = (
    props: RichNavigationLinkCollectionContentProps,
) => {
    const viewport = useCurrentBreakpoint();
    if (!props.value.links) {
        return null;
    }
    let wrapperClass = styles.defaultWrapper;
    if (props.value.promo_features && props.value.promo_features.length > 0) {
        props.value.promo_features.map((feature) => {
            if (
                !feature.value.promo_period ||
                feature.value.promo_period.is_promo_active
            ) {
                wrapperClass = styles.promoFeatureWrapper;
            }
        });
    }
    return (
        <div className={wrapperClass}>
            <div className={styles.linkWrapper}>
                {props.value.links.map((link, i) => (
                    <StreamField
                        key={i}
                        tagName={React.Fragment}
                        components={richNavigationLinkTypeComponents}
                        value={link.value}
                    />
                ))}
            </div>
            {viewport.aboveSmall &&
                props.value.promo_features &&
                props.value.promo_features.map((block) => {
                    switch (block.type) {
                        case "image_promo":
                            return (
                                <RichNavigationImagePromo
                                    className={styles.imagePromo}
                                    value={block.value}
                                />
                            );
                        default:
                            return <></>;
                    }
                })}
        </div>
    );
};
