import { TupleOf } from "../models/utils";

/**
 * Return an array of the given length
 */
export const range = (x: number) => {
    const arr: unknown[] = Array(...Array(x));
    const rng = arr.map((_, i) => {
        return i + 1;
    });
    return rng;
};

/**
 * Array filter predicate for removing null and undefined values from a list
 */
export const notEmpty = <T>(value: T | null | undefined): value is T => {
    return value !== null && value !== undefined;
};

/**
 * Array filter predicate for removing 0 from a list
 */
export const notZero = (value: number) => {
    return value !== 0;
};

/**
 * Array filter predicate used to make the array unique
 */
export const unique = <T>(value: T, index: number, arr: T[]) => {
    return arr.indexOf(value) === index;
};

/**
 * Make an array unique by an arbitrary property (the return value of by the getKey parameter)
 */
export const uniqueByKey = <T, U>(
    arr: T[],
    getKey: (value: T, i: number, arr: T[]) => U,
): T[] => {
    const result: T[] = [];
    const seen: Set<U> = new Set();

    for (let i = 0, length = arr.length; i < length; i++) {
        const value = arr[i];
        const computed = getKey(value, i, arr);

        if (!seen.has(computed)) {
            seen.add(computed);
            result.push(value);
        }
    }
    return result;
};

/**
 * Sort array of numbers
 */
export const numericSort = (a: number, b: number) => {
    if (a === b) {
        return 0;
    }
    return a > b ? 1 : -1;
};

/**
 * Extract an Object's keys and return them as an array
 *
 * Same as Object.keys, but with a slightly tighter type definition.
 * See https://github.com/Microsoft/TypeScript/pull/12253#issuecomment-393954723
 */
export const objectKeys = Object.keys as <T>(
    o: T,
) => Extract<keyof T, string>[];

/**
 * Extract an Object's values and return them as an array
 *
 * Same as Object.values, but with a slightly tighter type definition.
 */
export const objectValues = Object.values as <T>(o: T) => T[keyof T][];

/**
 * Extract an Object's keys and values and return them as an array of tuples.
 *
 * Same as Object.entries, but with a slightly tighter type definition.
 */
export const objectEntries = Object.entries as <T>(o: T) => {
    [K in keyof T]: [K, T[K]];
}[keyof T][];

/**
 * Same as Array.map, but works on tuples
 */
export const mapTuple = <T, N extends number, U>(
    tupleIn: TupleOf<T, N>,
    func: (obj: T, i: number) => U,
): TupleOf<U, N> => {
    const tupleOut = tupleIn.map(func);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    return tupleOut as any as TupleOf<U, N>;
};

/**
 * Type-safe partial function application.
 *
 * Example:
 *
 * > const logMessage = (level: string, message: string) => {
 * >     console.log(`${level}: ${message}`);
 * > };
 * >
 * > logMessage('INFO', 'Hello, World.');  // Output: 'INFO: Hello, World.'
 * >
 * > const logInfo = papply(logMessage, 'INFO');
 * >
 * > logInfo('Hello, World.');  // Output: 'INFO: Hello, World.'
 *
 */
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const papply = <T, U extends any[], V>(
    fn: (x: T, ...args: U) => V,
    x: T,
): ((...args: U) => V) => {
    return (...args: U) => {
        return fn(x, ...args);
    };
};

/**
 * Group an array of objects by the value of an object key
 */
export const groupBy = function* <
    T extends Record<symbol, unknown>,
    K extends keyof T,
>(arr: T[], key: K): Generator<Array<T>, void, unknown> {
    if (arr.length <= 0) {
        return;
    }
    type KeyType = T[K];
    let lastKey: KeyType = arr[0][key];
    let currentGroup: T[] = [];
    for (const obj of arr) {
        const currKey = obj[key];
        if (currKey !== lastKey) {
            yield currentGroup;
            currentGroup = [];
        }
        currentGroup.push(obj);
        lastKey = currKey;
    }
    if (currentGroup.length > 0) {
        yield currentGroup;
    }
};

/**
 * Sort an array of objects by the value of an object key
 */
export const sortBy = <
    K extends string | symbol | number,
    T extends Record<K, unknown>,
>(
    arr: T[],
    key: K,
): T[] => {
    return arr.sort((a, b) => {
        const vA = a[key];
        const vB = b[key];
        if (vA === vB) {
            return 0;
        }
        return vA < vB ? -1 : 1;
    });
};

/**
 * Sort an array of objects by the value of an object key
 */
export const localeSortBy = <
    T extends Record<symbol, unknown>,
    K extends keyof T,
>(
    arr: T[],
    key: K,
    collator?: Intl.Collator,
): T[] => {
    const _collator: Intl.Collator =
        collator ||
        new Intl.Collator(["en", "es"], {
            ignorePunctuation: true,
            numeric: true,
        });
    return arr.sort((a, b) => {
        const vA = `${a[key]}`;
        const vB = `${b[key]}`;
        return _collator.compare(vA, vB);
    });
};

export const anyTruthy = <T>(arr: T[]): boolean => {
    return arr.reduce<boolean>((memo, n) => memo || !!n, false);
};
