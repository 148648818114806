import { IReduxState } from "./reducers.interfaces";

export const defaults: IReduxState = {
    // Product Data
    entities: {
        rootProducts: [],
        variantPrefilter: null,
        price: null,
        concreteBundles: [],
        upsellInfoModals: [],
        promoCards: [],
        productClass: null,
    },

    // UI State Data
    ui: {
        // Product option selector state
        selectedRoot: null,
        optionValues: {},
        quantity: 1,

        // History
        previousSelectionState: null,

        // Product Upgrades
        upgradeVisible: true,
        selectedUpgrade: null,
        upsellInfoModalActiveBundle: null,

        // Product Add-Ons
        addonsVisible: true,
        selectedAddons: [],

        // Add to basket button
        addToBasketCooldownActive: false,
        addToBasketButtonText: "Add to cart",

        // Add to favorites button
        addToFavoritesButtonText: "Save to favorites",

        // Add to basket error modal
        addToBasketErrorOpen: false,
        addToBasketErrorReason: "",

        ratingsModalOpen: false,

        // Options pannel
        optionSelectionErrorReason: "",
        panelTriggerClicked: false,
    },

    shippingMethod: null,
};
