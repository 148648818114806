import { getDinero } from "../../utils/money";
import { IBasket, ICustomerDetails } from "./models.interfaces";

export const getBasketTotal = (basket: IBasket) => {
    return basket.lines.reduce((memo, line) => {
        const linePrice = getDinero(line.price_excl_tax).multiply(
            line.quantity,
        );
        return memo.add(linePrice);
    }, getDinero(0));
};

export const isProtectedUser = (user: ICustomerDetails) => {
    return user.groups.includes("Customer Service Representatives");
};
