import React from "react";
import { FormField } from "./FormField";

interface IProps<T> {
    name: T;
    onChange: (name: T, value: boolean) => void;
    value?: boolean;
    errors?: string[];
    label?: string;
    isRequired?: boolean;
    forwardRef?: (elem: HTMLSelectElement | null) => void;
}

const TRUE = "true";
const FALSE = "false";

const options: [string, string][] = [
    [FALSE, "No"],
    [TRUE, "Yes"],
];

export const BooleanInput = <T extends string>(props: IProps<T>) => {
    return (
        <FormField {...props}>
            <select
                name={props.name}
                value={props.value ? TRUE : FALSE}
                onChange={(event) => {
                    event.preventDefault();
                    props.onChange(
                        props.name,
                        event.currentTarget.value === TRUE,
                    );
                }}
                required={props.isRequired}
                ref={props.forwardRef}
            >
                {options.map(([value, label]) => (
                    <option key={value} value={value}>
                        {label}
                    </option>
                ))}
            </select>
        </FormField>
    );
};
