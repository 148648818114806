import React from "react";
import { Link } from "tsi-common-react/src/common/Link";
import { IMenuPromo } from "../../menu.interface";

interface IProps {
    promoArrayEl: IMenuPromo;
    promoID: number;
}

interface IState {}

export class PromoAd extends React.Component<IProps, IState> {
    private getLink() {
        if (this.props.promoArrayEl.value.page) {
            return this.props.promoArrayEl.value.page.url;
        }
        if (this.props.promoArrayEl.value.external_link) {
            return this.props.promoArrayEl.value.external_link;
        }
        return null;
    }

    private getContent() {
        if (this.props.promoArrayEl.value.html) {
            return (
                <div
                    className="main-dropdown-menu-ad__content"
                    dangerouslySetInnerHTML={{
                        __html: this.props.promoArrayEl.value.html,
                    }}
                ></div>
            );
        }
        return null;
    }

    render() {
        const link = this.getLink();
        const content = this.getContent();
        if (link) {
            return (
                <Link
                    key={this.props.promoID}
                    href={link}
                    target={"_top"}
                    className="main-dropdown-menu-ad"
                    tabIndex={0}
                >
                    {content}
                </Link>
            );
        }
        return (
            <div
                key={this.props.promoID}
                className="main-dropdown-menu-ad"
                tabIndex={0}
            >
                {content}
            </div>
        );
    }
}
