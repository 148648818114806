import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { IFormUUID } from "../../../models/nominals";
import { IStringField } from "../../../models/formFields.interfaces";
import { IWriteReviewTemplateField } from "../../../models/reviews.interfaces";
import { FormInput } from "../../../forms/FormInput";
import { Dispatchers } from "../dispatchers";
import { getWriteReviewFormState } from "../selectors";

interface IOwnProps {
    formUUID: IFormUUID;
    name: string;
    field: IWriteReviewTemplateField;
}

interface IReduxProps {
    value: string;
    errors: string[];
    showErrors: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class WriteReviewFormTextFieldComponent extends React.PureComponent<
    IProps,
    IState
> {
    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.props.dispatchers.onReviewFormTextChange({
            formUUID: this.props.formUUID,
            fieldName: this.props.name,
            fieldValue: e.currentTarget.value,
        });
    };

    render() {
        let postFieldDisclaimer: JSX.Element | null = null;
        if (this.props.name === "email_collection") {
            postFieldDisclaimer = (
                <div className="write-review-form__email-disclaimer">
                    Your email will not be displayed on the website. We will
                    only use this if we have questions about your review.
                </div>
            );
        }
        const placeholders: { [name: string]: string | undefined } = {
            headline: t`e.g., Quality and comfortable product`,
            name: t`e.g., Happy Sleeper`,
            location: t`e.g., Boston, MA`,
            email_collection: t`e.g., jdoe@example.com`,
        };
        const placeholderText = placeholders[this.props.name] || "";
        const autocompletes: { [name: string]: string | undefined } = {
            name: "nickname",
            location: "address-level2",
            email_collection: "email",
        };
        const autocompleteText = autocompletes[this.props.name] || "";
        let inputType: Exclude<IStringField<string>["type"], undefined>;
        switch (this.props.field.type) {
            case "email":
                inputType = "email";
                break;
            default:
                inputType = "text";
                break;
        }
        return (
            <span>
                <FormInput
                    autoComplete={autocompleteText}
                    name={this.props.name}
                    id={this.props.name}
                    type={inputType}
                    required={this.props.field.is_required}
                    label={this.props.field.name}
                    labelPlacement={"static"}
                    placeholder={placeholderText}
                    maxLength={this.props.field.max_length || undefined}
                    value={this.props.value}
                    errors={this.props.errors}
                    showErrorMessages={this.props.showErrors}
                    onChange={this.onChange}
                />
                {postFieldDisclaimer}
            </span>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const formState = getWriteReviewFormState(rootState.reviews, ownProps);
    return {
        value: formState?.valuesText[ownProps.name] ?? "",
        errors: formState.errors[ownProps.name] ?? [],
        showErrors: formState.showErrors,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const WriteReviewFormTextField = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WriteReviewFormTextFieldComponent);
