import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";
import {
    IReviewQueryFacets,
    ISearchFacet,
} from "../../../models/reviews.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { defaultState } from "../defaults";
import { RatingFilterOption } from "./RatingFilterOption";

const FACET_TYPE: IReviewQueryFacets = "rating";

interface IOwnProps {
    starHasStroke?: boolean;
}

interface IReduxProps {
    ratingFacet: ISearchFacet | null;
}

interface IProps extends IOwnProps, IReduxProps {}

const RatingFilterComponent = (props: IProps) => {
    const options = props.ratingFacet ? props.ratingFacet.options : [];
    return (
        <div className="customer-review__side-filters__menu">
            <div className="customer-review__side-filters__title">
                <h3>
                    Filter Reviews
                    <span aria-hidden="true">
                        <sup className="customer-review__side-filters__title--superscript">
                            §
                        </sup>
                    </span>
                </h3>
            </div>
            <div className="customer-review__side-filters__menu-item">
                <span className="ada-screenreader-only">
                    {t`Activating this element will cause content on the page to be updated`}
                </span>
                <fieldset>
                    <legend>
                        <div className="customer-review__side-filters__menu-title">
                            Rating
                        </div>
                    </legend>
                    <div className="customer-review__side-filters__menu-content">
                        {options.map((option) => (
                            <RatingFilterOption
                                key={option.option_id}
                                facetType={FACET_TYPE}
                                option={option}
                                starHasStroke={props.starHasStroke}
                            />
                        ))}
                    </div>
                </fieldset>
            </div>
        </div>
    );
};

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        ratingFacet:
            state.data.facets.find((facet) => facet.type === FACET_TYPE) ||
            null,
        ...ownProps,
    };
};

export const RatingFilter = connect(mapStateToProps)(RatingFilterComponent);
