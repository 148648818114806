import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import update from "immutability-helper";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { NonFieldErrors } from "../../../forms/NonFieldErrors";
import { focusElement } from "../../../utils/keyboardFocus";
import {
    IReduxFormState,
    IReduxAPIData,
    IReduxDerivedData,
    IReduxGiftData,
    IPayment,
    IFinancingPayment,
} from "../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { PaymentMethod } from "../payment/PaymentMethod";
import { PaymentEnabledField } from "../payment/PaymentEnabledField";
import {
    PRIMARY_PAYMENT_METHOD_KEY,
    SECONDARY_PAYMENT_METHOD_KEY,
    Step,
} from "../constants";
import { IFormErrors } from "../payment/PaymentMethod.interfaces";
import format from "../../../utils/format";
import { defaultMethodOrder } from "../defaults";
import { CheckoutStepContainer } from "./CheckoutStepContainer";
import { Form } from "../../../forms";

const NAMES: { [t in IPayment["method_type"]]: string } = {
    "default": "",
    "cash": t`Cash`,
    "pay-later": t`Pay Later`,
    "cybersource": t`Credit Card`,
    "bluefin": t`Secure Credit Card`,
    "financing": t`Financing`,
    "synchrony": t`Financing`,
};

interface IOwnProps {
    heading: string;
    errors: string[];
    buildFinancingUpsell: (grandTotal: string) => JSX.Element | null;
    enableSplitPay: boolean;
    onContinue: (event?: React.MouseEvent<HTMLElement>) => void;
    isCSR?: boolean;
    isCurrentStep?: boolean;
    formRef?: React.RefObject<Form>;
}

interface IReduxProps {
    form: IReduxFormState;
    data: IReduxAPIData;
    derived: IReduxDerivedData;
    gifts: IReduxGiftData;
    showForm: boolean;
    showSummary: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    showErrorMessages: boolean;
    showMorePaymentOptions: boolean;
    errors: {
        [method_key: string]: IFormErrors;
    };
}

class PaymentMethodsComponent extends React.Component<IProps, IState> {
    private _stateCache: IState | null = null;
    private readonly TABLET_WIDTH_THRESHOLD = 810;

    public state: IState = {
        showErrorMessages: false,
        showMorePaymentOptions: false,
        errors: {
            [PRIMARY_PAYMENT_METHOD_KEY]: {},
            [SECONDARY_PAYMENT_METHOD_KEY]: {},
        },
    };

    private readonly onApplyDenial = () => {
        this.hideErrorMessages();
        this.props.dispatchers.resetPaymentMethods(
            this.props.data.payment_methods,
        );
    };

    private readonly hideErrorMessages = () => {
        this.setState({
            showErrorMessages: false,
        });
    };

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        event.preventDefault();
        if (this.getErrorCount() > 0) {
            this.showErrorMessages();
            return;
        }
        this.props.onContinue();
    };

    private readonly onValidStateChange = (
        fieldName: string,
        errorMessages: string[],
        methodKey: string,
    ) => {
        const oldState = this._stateCache || this.state;
        const newState: IState = update(oldState, {
            errors: {
                [methodKey]: {
                    $merge: {
                        [fieldName]: errorMessages,
                    },
                },
            },
        });
        this._stateCache = newState;
        this.setState(newState);
    };

    componentDidMount = async () => {
        const cardForm = document.getElementById("card_number");
        if (cardForm && this.isTabletOrSmallerWidth()) {
            document.addEventListener("scroll", function () {
                if ((document.activeElement as HTMLElement) === cardForm) {
                    cardForm.blur();
                    (document.activeElement as HTMLElement).focus();
                }
            });
        }
    };

    componentDidUpdate() {
        this._stateCache = null;
    }

    private isTabletOrSmallerWidth() {
        return window.innerWidth <= this.TABLET_WIDTH_THRESHOLD;
    }
    private showErrorMessages() {
        this.setState({
            showErrorMessages: true,
        });
    }

    private readonly showMorePaymentOptions = () => {
        this.setState({
            showMorePaymentOptions: true,
        });

        // Make the active input not to lose the focus when more payment options are revealed
        focusElement(".checkout-step__payment-type--active input");
    };

    private readonly addSplitPayToFinancing = () => {
        const payment = this.props.form.payment_methods[
            PRIMARY_PAYMENT_METHOD_KEY
        ] as IFinancingPayment;
        if (payment.new_financing_account) {
            const financingAmount =
                payment.new_financing_account.available_credit;
            // adding secondary, so split pay
            const creditAmount = this.props.derived.grand_total
                ? Number(this.props.derived.grand_total) -
                  Number(financingAmount)
                : 0;
            // set primary method with max available amount
            this.props.dispatchers.addPaymentMethod(
                PRIMARY_PAYMENT_METHOD_KEY,
                "financing",
                format.decimal(financingAmount),
                false,
                payment.new_financing_account.account_number,
            );
            // always using cybersource/bluefin as the secondary method
            this.props.dispatchers.addPaymentMethod(
                SECONDARY_PAYMENT_METHOD_KEY,
                this.getCreditCardPaymentType(),
                format.decimal(creditAmount),
            );

            this.showMorePaymentOptions();
            this.hideErrorMessages();
        }
    };

    private getErrorCount() {
        let sum = 0;
        for (const methodKey in this.state.errors) {
            for (const field in this.state.errors[methodKey]) {
                sum += this.state.errors[methodKey][field]!.length;
            }
        }
        return sum;
    }

    private getCreditCardPaymentType() {
        return this.props.data.payment_methods.includes("bluefin")
            ? "bluefin"
            : "cybersource";
    }

    private buildMethodSelectionFields(methodKey: string) {
        const selectedPrimaryMethod =
            this.props.form.payment_methods[PRIMARY_PAYMENT_METHOD_KEY];
        const hasSecondaryMethod =
            SECONDARY_PAYMENT_METHOD_KEY in this.props.form.payment_methods;
        return this.props.data.payment_methods
            .sort((a, b) => {
                if (
                    defaultMethodOrder.indexOf(a) <
                    defaultMethodOrder.indexOf(b)
                ) {
                    return -1;
                }
                if (
                    defaultMethodOrder.indexOf(a) >
                    defaultMethodOrder.indexOf(b)
                ) {
                    return 1;
                }
                return 0;
            })
            .map((methodType) => {
                // cannot use cash as a secondary method
                if (
                    methodKey === SECONDARY_PAYMENT_METHOD_KEY &&
                    (methodType === "cash" || methodType === "pay-later")
                ) {
                    return null;
                }
                // determine if this method is selected
                let isSelected = false;
                if (methodKey === PRIMARY_PAYMENT_METHOD_KEY) {
                    if (
                        !hasSecondaryMethod &&
                        selectedPrimaryMethod.method_type === methodType
                    ) {
                        isSelected = true;
                    }
                } else {
                    if (
                        hasSecondaryMethod &&
                        selectedPrimaryMethod.method_type === methodType
                    ) {
                        isSelected = true;
                    }
                }
                return (
                    <PaymentEnabledField
                        key={`${methodKey}-${methodType}`}
                        primary={methodType}
                        secondary={this.getCreditCardPaymentType()}
                        selected={isSelected}
                        methodKey={methodKey}
                        grandTotal={this.props.derived.grand_total}
                        plans={this.props.data.financing_plans}
                        addPaymentMethod={
                            this.props.dispatchers.addPaymentMethod
                        }
                        removePaymentMethod={
                            this.props.dispatchers.removePaymentMethod
                        }
                        onClick={this.hideErrorMessages}
                    />
                );
            });
    }

    private buildSelectedMethodSummary(methodKey: string) {
        const method = this.props.form.payment_methods[methodKey];
        if (!method) {
            return null;
        }
        const summary = this.props.data.payment_methods.map((methodType) => {
            if (methodType !== method.method_type) {
                return null;
            }
            return (
                <p key={methodType}>
                    {t`Paying via`} {NAMES[methodType]}
                </p>
            );
        });
        return summary;
    }

    private buildSelectedMethodForm() {
        return (
            <PaymentMethod
                form={this.props.form}
                data={this.props.data}
                derived={this.props.derived}
                gifts={this.props.gifts}
                errors={this.state.errors}
                onValidStateChange={this.onValidStateChange}
                showErrorMessages={this.state.showErrorMessages}
                onApplyDenial={this.onApplyDenial}
                onSubmit={this.onSubmit}
                buildFinancingUpsell={this.props.buildFinancingUpsell}
                changeFormField={this.props.dispatchers.changeFormField}
                changeCreditCardNumber={
                    this.props.dispatchers.changeCreditCardNumber
                }
                changeCreditCardExpDate={
                    this.props.dispatchers.changeCreditCardExpDate
                }
                changeAmount={this.props.dispatchers.changeAmount}
                changeFinancingAccountNumber={
                    this.props.dispatchers.changeFinancingAccountNumber
                }
                setPaymentMethodFields={
                    this.props.dispatchers.setPaymentMethodFields
                }
                resetPaymentMethods={this.props.dispatchers.resetPaymentMethods}
                addSplitPayToFinancing={this.addSplitPayToFinancing}
                enableSplitPay={this.props.enableSplitPay}
                isCSR={!!this.props.isCSR}
                formRef={this.props.formRef}
            />
        );
    }

    render() {
        const bodyClasses = classNames({
            "checkout-step__body": true,
            "checkout-step__body--collapsed": !this.props.showForm,
        });
        const summaryClasses = classNames({
            "checkout-step__summary": true,
            "checkout-step__summary--collapsed": !this.props.showSummary,
        });
        const showMoreClasses = classNames({
            "checkout-step__summary--collapsed":
                this.state.showMorePaymentOptions,
        });
        const splitOptionsClasses = classNames({
            "checkout-step__summary--collapsed":
                !this.state.showMorePaymentOptions,
        });
        // See https://thelabnyc.plan.io/issues/26533
        // Show warning about Fortiva being disallowed in split-pay
        const selectedMethodCodes = new Set(
            Object.values(this.props.form.payment_methods).map(
                (methodData) => methodData.method_type,
            ),
        );
        const showFortivaWarning =
            selectedMethodCodes.size > 1 &&
            selectedMethodCodes.has("financing");
        return (
            <CheckoutStepContainer
                className="checkout-step--payment-method form"
                heading={this.props.heading}
                isCurrentStep={this.props.isCurrentStep}
            >
                <div className={bodyClasses}>
                    <NonFieldErrors
                        errors={this.props.errors}
                        showErrorMessages={true}
                    />
                    <fieldset>
                        <legend className="ada-screenreader-only">
                            {this.props.heading}
                        </legend>
                        <div className="checkout-step__payment-enabled-fields">
                            <ul>
                                {this.buildMethodSelectionFields(
                                    PRIMARY_PAYMENT_METHOD_KEY,
                                )}
                            </ul>
                        </div>
                        {this.props.enableSplitPay && (
                            <div>
                                <button
                                    className={
                                        showMoreClasses ||
                                        "checkout-step__split-pay--toggle"
                                    }
                                    onClick={this.showMorePaymentOptions}
                                    dangerouslySetInnerHTML={{
                                        __html: t`Show more payment options <span>&#x25bc;</span>`,
                                    }}
                                ></button>
                                <div className={splitOptionsClasses}>
                                    <div className="checkout-step__payment-enabled-fields">
                                        <ul>
                                            {this.buildMethodSelectionFields(
                                                SECONDARY_PAYMENT_METHOD_KEY,
                                            )}
                                        </ul>
                                    </div>
                                    <div>
                                        <p>
                                            <strong>{t`Please Note:`}</strong>{" "}
                                            {t`The billing address you provided during checkout must match the address on file with both payment methods.`}
                                        </p>
                                        {/* See https://thelabnyc.plan.io/issues/26533 */}
                                        {/* Show warning about Fortiva being disallowed in split-pay */}
                                        {showFortivaWarning && (
                                            <p>
                                                {t`Split Pay with Financing is only available with our primary lender. If you were approved by Fortiva® Retail Credit, your account is not valid for placing a split pay order at this time.`}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </fieldset>

                    {this.buildSelectedMethodForm()}
                </div>
                <div className={summaryClasses}>
                    {this.buildSelectedMethodSummary(
                        PRIMARY_PAYMENT_METHOD_KEY,
                    )}
                    {this.buildSelectedMethodSummary(
                        SECONDARY_PAYMENT_METHOD_KEY,
                    )}
                    <LoadingSpinner />
                </div>
            </CheckoutStepContainer>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        form: state.form,
        data: state.data,
        derived: state.derived,
        gifts: state.gifts,
        showForm: state.form.current_step === Step.PAYMENT_METHODS,
        showSummary: state.form.current_step > Step.PAYMENT_METHODS,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const PaymentMethods = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PaymentMethodsComponent);
