import React from "react";
import Modal from "react-modal";
import SVG from "react-inlinesvg";
import { t } from "ttag";
import { ajax } from "../../utils/ajax";
import LoadingSpinner from "../../common/LoadingSpinner";
import { focusElement } from "../../utils/keyboardFocus";
import iconArrowRight from "../../../img/icons/arrow_right.svg";
import iconCircleClose from "../../../img/icons/circle-close.svg";

Modal.defaultStyles = {
    overlay: {},
    content: {},
};

interface IProps {
    offerDetailURL: string;
    ctaText?: string;
    ctaArrow?: boolean;
}

interface IState {
    modalIsOpen: boolean;
    windowWidth: number;
    modalContent: string;
    anchorName: string;
}

export class OfferModal extends React.Component<IProps, IState> {
    private modalContentElem: HTMLDivElement | null = null;
    private modalTriggerElem: HTMLElement | null = null;

    public state: IState = {
        modalIsOpen: false,
        windowWidth: window.innerWidth,
        modalContent: "",
        anchorName: "",
    };

    private readonly onOpenModal = (event: React.MouseEvent<HTMLElement>) => {
        let anchorName = "";
        this.modalTriggerElem = event.currentTarget as HTMLElement;
        const eventTarget = event.target as HTMLElement;
        const sup = this.modalTriggerElem.getElementsByTagName("sup");
        if (sup.length > 0) {
            if (eventTarget.tagName === "SUP") {
                anchorName = sup[0].innerText;
            }
        }

        setTimeout(() => {
            this.setState({
                modalIsOpen: true,
                anchorName: anchorName,
            });
        }, 100);
    };

    private readonly onCloseModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    };

    private readonly checkForAnchor = () => {
        if (this.state.anchorName.trim() !== "" && this.modalContentElem) {
            const anchorLink = this.modalContentElem.querySelector(
                '[id="' + this.state.anchorName + '"]',
            );
            if (anchorLink) {
                anchorLink.scrollIntoView({ behavior: "smooth" });
            }

            // close button is automatically focused when the modal first opens
            focusElement(".coffer-modal__close");
        }
    };

    componentDidMount() {
        this.loadModalContent();
        window.addEventListener("resize", () => {
            this.setState({
                windowWidth: window.innerWidth,
            });
        });

        this.initAccordions();
    }

    componentDidUpdate() {
        // Full content of the modal does not always display and sometimes appears blank on iPad
        // This issue occurs on only Safari in iPad.
        // This hack fixes the issue - Wait 0.2 sec for the content of the modal rendered and then set overflow-y: scroll
        setTimeout(() => {
            const offerDetails = document.querySelector(
                ".offer-modal__content > *",
            );
            const activeClass = "offer-details-page--active";
            if (offerDetails) {
                if (offerDetails.classList.contains(activeClass)) {
                    offerDetails.classList.remove(activeClass);
                }
                offerDetails.classList.add(activeClass);
            }

            this.initAccordions();
        }, 200);
    }

    private initAccordions() {
        if (this.modalContentElem) {
            Array.from(
                this.modalContentElem.querySelectorAll<HTMLElement>(
                    ".accordion",
                ),
            ).forEach((elem) => {
                if (elem.classList.contains("accordion--loaded")) {
                    return;
                }
                elem.classList.add("accordion--loaded");
                elem.addEventListener("click", function (event) {
                    event.preventDefault();
                    this.classList.toggle("accordion--is-active");
                    if (!this.parentNode) {
                        return;
                    }
                    Array.from(this.parentNode.children)
                        .filter((e) => {
                            return e.classList.contains("accordion-target");
                        })
                        .forEach((e) => {
                            e.classList.toggle("accordion-target--is-active");
                        });
                });
            });
        }
    }

    private loadModalContent() {
        ajax.get(this.props.offerDetailURL)
            .then((resp) => {
                const offerDetailHTML = document.createElement("html");
                offerDetailHTML.innerHTML = resp.text;
                const offerDetailContent =
                    offerDetailHTML.querySelectorAll("#main-content > *");
                if (offerDetailContent.length <= 0) {
                    throw new Error(
                        "Couldn't find any Offer Detail Content at this link",
                    );
                }
                return offerDetailContent[0].outerHTML;
            })
            .then((result) => {
                this.setState(
                    {
                        modalContent: result,
                    },
                    () => {
                        this.checkForAnchor();
                    },
                );
            });
    }

    private renderModalContent() {
        if (!this.state.modalContent) {
            return <LoadingSpinner />;
        }

        return (
            <div
                className="offer-modal__content"
                ref={(ref) => {
                    this.modalContentElem = ref;
                }}
                dangerouslySetInnerHTML={{ __html: this.state.modalContent }}
            ></div>
        );
    }

    private renderModalTrigger() {
        if (this.props.ctaArrow) {
            return (
                <button
                    className="limited-time-offer-tile-block__arrow"
                    onClick={this.onOpenModal}
                >
                    <SVG
                        className="limited-time-offer-tile-block__arrow-icon"
                        src={iconArrowRight}
                        title={t`Right Arrow Icon`}
                    />
                </button>
            );
        }

        const triggerText = !this.props.ctaText
            ? "See Details <span>></span>"
            : this.props.ctaText;

        return (
            <button
                dangerouslySetInnerHTML={{ __html: triggerText }}
                className="offer-modal__trigger"
                onClick={this.onOpenModal}
            ></button>
        );
    }

    render() {
        let modalWidth = "";
        let modalHeight = "";
        let modalPadding = "";
        let borderRadius = "20px";
        let closeTimeout = 0;

        if (this.state.windowWidth < 768) {
            modalWidth = "100%";
            modalHeight = "100%";
            modalPadding = "32px 16px";
            borderRadius = "0";
        } else {
            modalWidth = "70%";
            modalHeight = "85vh";
            modalPadding = "32px";
        }

        if (this.state.windowWidth < 500) {
            closeTimeout = 500;
        }

        const modalStyle = {
            content: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
                width: modalWidth,
                height: modalHeight,
                padding: modalPadding,
                boxShadow: "none",
                borderRadius: borderRadius,
                overflow: "hidden",
            },
        };

        return (
            <div>
                {this.renderModalTrigger()}
                <Modal
                    aria={{ modal: true }}
                    className={{
                        base: "offer-modal",
                        afterOpen: "offer-modal--after-open",
                        beforeClose: "offer-modal--before-close",
                    }}
                    overlayClassName={{
                        base: "offer-modal__overlay",
                        afterOpen: "offer-modal__overlay--after-open",
                        beforeClose: "offer-modal__overlay--before-close",
                    }}
                    contentLabel={t`Offers`}
                    closeTimeoutMS={closeTimeout}
                    style={modalStyle}
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.checkForAnchor}
                    onRequestClose={this.onCloseModal}
                    role="dialog"
                >
                    <button
                        aria-label={t`close`}
                        className="offer-modal__close"
                        onClick={this.onCloseModal}
                    >
                        <SVG
                            aria-hidden="true"
                            className="offer-modal__close-icon"
                            src={iconCircleClose}
                            title={t`Close Icon`}
                        />
                    </button>
                    {this.renderModalContent()}
                </Modal>
            </div>
        );
    }
}
