import { UAParser } from "ua-parser-js";
import { BreakPoint } from "../models/screen.interfaces";

export const getUA = () => {
    const parser = new UAParser();
    return parser.getResult();
};

export const isMobile = {
    Android: () => {
        const ua = getUA();
        return ua.os.name === "Android";
    },
    iOS: () => {
        const ua = getUA();
        return ua.os.name === "iOS";
    },
    any: () => {
        const ua = getUA();
        return ua.device.type === "mobile" || ua.device.type === "tablet";
    },
};

export const getViewportBreakpoint = (): BreakPoint => {
    const width = window.innerWidth;
    if (width > BreakPoint.LARGE) {
        return BreakPoint.LARGE;
    }
    if (width > BreakPoint.MEDIUM) {
        return BreakPoint.MEDIUM;
    }
    if (width > BreakPoint.SMALL) {
        return BreakPoint.SMALL;
    }
    return BreakPoint.MIN;
};
