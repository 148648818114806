import * as basketAPI from "../../api/basket";
import * as wishlistsAPI from "../../api/wishlists";
import { IProductAPIURL } from "../../models/nominals";

export const addProductToBasket = async (
    productURL: IProductAPIURL,
    qty?: number,
) => {
    console.info(`[Configurator] Adding ${productURL} to basket`);
    await basketAPI.addProduct(productURL, qty);
};

export const addProductToWishList = async (
    wishListCode: string,
    productURL: IProductAPIURL,
) => {
    console.info(`[Configurator] Adding ${productURL} to ${wishListCode}`);
    await wishlistsAPI.addLine(wishListCode, productURL);
};
