import React from "react";
import classNames from "classnames";
import { t } from "ttag";

interface IProps {
    addressParts: string[];
    className?: string;
    displayText?: string | null;
}

export const MapDirectionsLink = (props: IProps) => {
    const address = props.addressParts.filter((part) => !!part).join(", ");
    const url = new URL("https://www.google.com/maps/dir/?api=1");
    url.searchParams.append("destination", address);
    const cssClasses = classNames({
        button: !props.className,
        [`${props.className}`]: props.className,
    });
    return (
        <a
            href={url.toString()}
            className={cssClasses}
            target="_blank"
            rel="noreferrer"
        >
            {props.displayText || t`Directions`}
        </a>
    );
};
