import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { default as Spinner, SpinnerProps } from "react-spinkit";
import { IProductAPIURL } from "../../../models/nominals";
import { IBasket, IWishListLine } from "../../../models/catalogue.interfaces";
import { PriceType, getProductPrice } from "../../../utils/catalogue";
import {
    IBasketLineBaseProps,
    BasketLineImage,
    BasketLinePrice,
    BasketLineTitle,
    BasketLineOptions,
} from "./BasketLineBase";
import { trackAddToBasketEvent } from "../../../utils/analytics";

interface IProps extends IBasketLineBaseProps<IWishListLine> {
    addBasketLine?: (
        productURL: IProductAPIURL,
        qty?: number,
    ) => Promise<IBasket>;
    removeWishlistLine: (lineURL: string) => Promise<IBasket>;
}

interface IState {
    isOperationPending: boolean;
}

export class WishListLine extends React.Component<IProps, IState> {
    state: IState = {
        isOperationPending: false,
    };

    private readonly onAddToBasket = async (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        if (!this.props.addBasketLine) {
            return;
        }
        event.preventDefault();
        this.showLoadingIndicator();
        await this.props.addBasketLine(this.props.line.product.url);
        trackAddToBasketEvent(this.props.line.product);
        this.hideLoadingIndicator();
    };

    private readonly onRemoveLine = async () => {
        this.showLoadingIndicator();
        await this.props.removeWishlistLine(this.props.line.url);
        this.hideLoadingIndicator();
    };

    private showLoadingIndicator() {
        this.setState({
            isOperationPending: true,
        });
    }

    private hideLoadingIndicator() {
        this.setState({
            isOperationPending: false,
        });
    }

    private buildRemoveButton() {
        return (
            <button
                onClick={this.onRemoveLine}
                className="basket-line__details__button basket-line__details__button--remove al-basket-line__button--remove"
            >
                [{t`Remove from List`}]
            </button>
        );
    }

    private buildAddToBasketButton() {
        if (!this.props.addBasketLine) {
            return null;
        }
        return (
            <button
                onClick={this.onAddToBasket}
                className="basket-line__details__button al-checkout__add-back-button"
            >
                {t`Move to Cart`}
            </button>
        );
    }

    render() {
        const product = this.props.line.product;
        const loadingIndicatorClasses = classNames({
            "basket-line__details__controls-loading": true,
            "basket-line__details__controls-loading--active":
                this.state.isOperationPending,
        });
        const _Spinner = Spinner as React.ComponentClass<SpinnerProps>;
        return (
            <div
                className="basket-line basket-line--wishlist"
                role="group"
                aria-label={t`product`}
            >
                <div className="basket-line__graphic">
                    <BasketLineImage product={product} />
                </div>
                <div className="basket-line__details">
                    <div className="basket-line__details__row">
                        <BasketLineTitle product={product} />
                        {product.price && (
                            <BasketLinePrice
                                unitPrice={product.price}
                                linePriceInclDiscounts={getProductPrice(
                                    product.price,
                                    {
                                        priceType: PriceType.COSMETIC_EXCL_TAX,
                                        includePostDiscountAddons: true,
                                        quantity: 1,
                                    },
                                )}
                                postDiscountAddons={
                                    product.price.post_discount_addons
                                }
                                quantity={1}
                            />
                        )}
                    </div>
                    <div className="basket-line__details__row">
                        <BasketLineOptions product={product} />
                    </div>
                    <div className="basket-line__details__row">
                        <div className="basket-line__details__controls">
                            <div className={loadingIndicatorClasses}>
                                <_Spinner name="three-bounce" fadeIn="none" />
                            </div>
                            {this.buildAddToBasketButton()}
                            {this.buildRemoveButton()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
