import { IReduxState, IPayment } from "./reducers.interfaces";
import {
    PRIMARY_PAYMENT_METHOD_KEY,
    DEFAULT_PHONE_NUMBER,
    DEFAULT_COUNTRY,
    Step,
    ConfigureGiftStatus,
    PredictedDeliveryDateStatus,
} from "./constants";

export const defaultPaymentMethod: IPayment = {
    method_type: "default",
    amount: "0.00",
    pay_balance: true,
};

// order of precedence for the default payment method
export const defaultMethodOrder: IPayment["method_type"][] = [
    "bluefin",
    "cybersource",
    "financing",
    "synchrony",
    "cash",
    "pay-later",
];

export const defaults: IReduxState = {
    // Data loaded from the API
    data: {
        addresses: [],
        basket: null,
        order: null,
        deferredPaymentOrder: null,
        countries: [],
        shipping_states: [],
        billing_states: [],
        shipping_methods: [],
        shipping_method_errors: [],
        billing_method_errors: [],
        payment_methods: [defaultPaymentMethod.method_type],
        payment_states: null,
        payment_error: "",
        financing_plans: [],
        wishlists: {},
        assisted_user: null,
        predicted_delivery_date: {
            status: PredictedDeliveryDateStatus.INITIAL,
        },
        preferred_date: null,
    },

    // Form data entered by the customer
    form: {
        current_step: Step.SHIPPING_ADDRESS,
        guest_email: "",

        shipping_address_url: "",
        billing_address_url: "",

        shipping_first_name: "",
        shipping_last_name: "",
        shipping_line1: "",
        shipping_line2: "",
        shipping_line4: "",
        shipping_state: "",
        shipping_postcode: "",
        shipping_phone_number: DEFAULT_PHONE_NUMBER,
        shipping_country: DEFAULT_COUNTRY,
        shipping_address_join_rewards: false,
        shipping_notes: "",
        shipping_method: "",

        billing_addr_is_shipping_addr: true,

        billing_first_name: "",
        billing_last_name: "",
        billing_line1: "",
        billing_line2: "",
        billing_postcode: "",
        billing_line4: "",
        billing_state: "",
        billing_phone_number: "",
        billing_country: DEFAULT_COUNTRY,

        payment_methods: {
            [PRIMARY_PAYMENT_METHOD_KEY]: defaultPaymentMethod,
        },
    },

    // "Derived" data calculated from some combination of API and form data
    derived: {
        shipping_method_cost_excl_tax: null,
        shipping_method_cost_incl_tax: null,
        shipping_method_cost_before_discount_excl_tax: null,
        shipping_method_cost_before_discount_incl_tax: null,
        total_discount: null,
        grand_total: null,
        acknowledged_merged_baskets: [],
    },

    gifts: {
        status: ConfigureGiftStatus.LOADING,
        userConfigurableBundles: [],
        addToBasketError: {
            addToBasketErrorOpen: false,
            addToBasketErrorReason: "",
        },
        gifts: {},
    },
};
