import React from "react";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { IProduct } from "../../../models/catalogue.interfaces";
import { ProductSchemaTag } from "../../../common/ProductSchemaTag";
import { IProductCardType } from "../models.interfaces";
import { getProductVariant } from "../../selectors";
import { RatingGraphic } from "../../../common/RatingGraphic";
import {
    CardProductLink,
    CardImage,
    CardCallout,
    CardTitle,
    CardDefaultPrice,
} from "./ProductCard";

import styles from "./SOProductCard.module.scss";
import classNames from "classnames";
import { TryInStoreCalloutContainer } from "../../product-compare/elements/TryInStoreCallout";

interface IOwnProps {
    gridVariant: string;
    cardType: IProductCardType;
    rootProduct: IProduct;
    disableHoverOverlay?: boolean;
    buildExternalCallout?: (rootProduct: IProduct) => React.ReactNode;
    forwardedRef?: React.RefObject<HTMLDivElement>;
}

interface IReduxProps {
    variant: IProduct | null;
    quantity: number;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

export class SOProductCardComponent extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        const cardClasses = classNames({
            ["product-grid-card"]: true, // used for Masonry Grid Layout
            [styles.card]: true,
        });
        return (
            <div
                role="group"
                aria-label="product"
                ref={this.props.forwardedRef}
                className={cardClasses}
            >
                <CardProductLink
                    {...this.props}
                    customStyle={styles.imgContainer}
                >
                    <CardImage {...this.props} />
                    <CardCallout {...this.props} />
                </CardProductLink>
                <div className={styles.cardContent}>
                    <CardProductLink {...this.props}>
                        <CardTitle {...this.props} />
                    </CardProductLink>
                    {this.props.rootProduct.rating && (
                        <CardProductLink
                            {...this.props}
                            hashFragment={"reviews"}
                        >
                            <RatingGraphic
                                rating={this.props.rootProduct.rating}
                                numReviews={this.props.rootProduct.num_reviews}
                                onlyReviewsNum={false}
                                cardClass={this.props.cardType}
                                cardSize={"small"}
                                reviewNumberClass={styles.reviewNumber}
                                starRatingClass={styles.rating}
                            />
                        </CardProductLink>
                    )}
                    <CardDefaultPrice {...this.props} />
                    <TryInStoreCalloutContainer
                        cssClass={styles.inStoreCallout}
                    />
                </div>
                <ProductSchemaTag
                    name={this.props.rootProduct.title}
                    imageUrl={
                        this.props.rootProduct.images[0] &&
                        this.props.rootProduct.images[0].original
                    }
                    description={this.props.rootProduct.description}
                    sku={this.props.rootProduct.upc}
                    brandName="Tempur-Pedic"
                    ratingValue={this.props.rootProduct.rating}
                    reviewCount={this.props.rootProduct.num_reviews}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.productgrid2 || defaults;
    return {
        ...ownProps,
        variant: getProductVariant([ownProps.rootProduct], state.optionValues),
        quantity: state.quantity,
    };
};

export const SOProductCard = connect(mapStateToProps)(SOProductCardComponent);
