import React from "react";
import classNames from "classnames";
import { Link } from "tsi-common-react/src/common/Link";
import { IMenuPageLink } from "../../menu.interface";

interface IProps {
    linkObject: IMenuPageLink;
    isMobile: boolean;
    linkID: number;
}

export const PageLink = (props: IProps) => {
    const linkClass = classNames({
        "site-nav__main-menu-item": !props.isMobile,
        "main-dropdown-menu__mobile": props.isMobile,
        "main-dropdown-menu__mobile-link": props.isMobile,
        [`main-dropdown-menu__mobile--${props.linkObject.value.page.slug}`]:
            props.isMobile,
    });
    return (
        <Link
            key={props.linkID}
            title={props.linkObject.value.override_title}
            className={linkClass}
            href={props.linkObject.value.page.url}
            target={props.linkObject.value.open_in_new_tab ? "_blank" : "_top"}
            rel="noreferrer"
        >
            {props.linkObject.value.override_title}
        </Link>
    );
};
