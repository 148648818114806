export enum CountdownTimerTheme {
    RED = "red",
    YELLOW = "yellow",
    CARDS = "cards",
}

export interface ICountdownTimer {
    body: string;
    mobile_body?: string | null;
    start_date: string;
    end_date: string;
    theme: CountdownTimerTheme;
}

export interface ICountdownTime {
    days: number | null;
    hours: number | null;
    minutes: number | null;
    seconds: number | null;
}
