import { trackEvent } from "./analytics";

export interface Listener<T> {
    (event: T): unknown;
}

export interface Disposable {
    dispose: () => void;
}

export class TypedEvent<T = void> {
    private readonly eventCategory: string | undefined;
    private readonly eventAction: string | undefined;
    private readonly listeners: Listener<T>[] = [];
    private listenersOncer: Listener<T>[] = [];

    constructor(eventCategory?: string, eventAction?: string) {
        this.eventCategory = eventCategory;
        this.eventAction = eventAction;
    }

    public readonly on = (listener: Listener<T>): Disposable => {
        this.listeners.push(listener);
        return {
            dispose: () => {
                this.off(listener);
            },
        };
    };

    public readonly once = (listener: Listener<T>): void => {
        this.listenersOncer.push(listener);
    };

    public readonly off = (listener: Listener<T>): void => {
        const callbackIndex = this.listeners.indexOf(listener);
        if (callbackIndex > -1) {
            this.listeners.splice(callbackIndex, 1);
        }
    };

    public readonly emit = (event: T): void => {
        // Update any general listeners
        this.listeners.forEach((listener) => {
            listener(event);
        });
        // Clear the `once` queue
        if (this.listenersOncer.length > 0) {
            const toCall = this.listenersOncer;
            this.listenersOncer = [];
            toCall.forEach((listener) => {
                listener(event);
            });
        }
        // Send a tracking event
        if (this.eventCategory && this.eventAction) {
            trackEvent(this.eventCategory, this.eventAction);
        }
    };

    public readonly pipe = (te: TypedEvent<T>): Disposable => {
        return this.on((e) => {
            te.emit(e);
        });
    };
}

export const onDOMContentLoaded = new TypedEvent();
export const onReadyStateComplete = new TypedEvent();
export const onPageVisible = new TypedEvent();

export const domContentLoaded = new Promise((resolve) => {
    if (typeof document === "undefined") {
        return;
    }
    document.addEventListener("DOMContentLoaded", resolve);
});

export const readyStateComplete = new Promise<void>((resolve) => {
    if (typeof document === "undefined") {
        return;
    }
    document.addEventListener("readystatechange", () => {
        if (document.readyState !== "complete") {
            return;
        }
        resolve();
    });
});

export const pageVisible = new Promise<void>((resolve) => {
    if (typeof document === "undefined") {
        return;
    }
    if (document.visibilityState === "visible") {
        resolve();
    }
    document.addEventListener("visibilitychange", () => {
        if (document.visibilityState !== "visible") {
            return;
        }
        resolve();
    });
});

domContentLoaded.then(() => {
    onDOMContentLoaded.emit();
});

readyStateComplete.then(() => {
    onReadyStateComplete.emit();
});

pageVisible.then(() => {
    onPageVisible.emit();
});
