import React from "react";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "../../../models/location.interfaces";
import { ISyncStoreID } from "../../../models/nominals";
import { loadGMaps, newMap, getGoogleLatLng } from "../../../utils/maps";
import { isLatLngLocation } from "../../../utils/guards";

interface IProps {
    stores: IRetailStoreWithDistance[];

    defaultCenter?: ILocation | null;

    elemProps?: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    >;
    mapOptions?: () => google.maps.MapOptions;
    markerOptions?: (
        store: IRetailStoreWithDistance,
    ) => Partial<google.maps.MarkerOptions>;

    onBeforeInfoWindowOpen?: (
        info: google.maps.InfoWindow,
        store: IRetailStoreWithDistance,
    ) => void;
    onInfoWindowClose?: () => void;
}

interface IState {}

export class MarkerMap extends React.Component<IProps, IState> {
    private readonly mapElem = React.createRef<HTMLDivElement>();
    private readonly defaultMapCenter: google.maps.LatLngLiteral = {
        lat: 37.0902,
        lng: -95.7129,
    };
    private readonly defaultZoom = 13;

    private readonly markers: Map<ISyncStoreID, google.maps.Marker> = new Map();

    private map: google.maps.Map | undefined;
    private info: google.maps.InfoWindow | undefined;

    async componentDidMount() {
        await loadGMaps();
        await this.renderMap();
        this.initInfoWindow();
        this.renderMarkers();
        // Autoselect the marker of the closest store
        if (this.props.stores.length > 0) {
            this.onActivateMarker(this.props.stores[0].external_id);
        } else {
            this.panToDefaultCenter();
        }
    }

    async componentDidUpdate(prevProps: IProps) {
        if (this.props.stores !== prevProps.stores) {
            await loadGMaps();
            this.renderMarkers();
            // Autoselect the marker of the closest store
            if (this.props.stores.length > 0) {
                this.onActivateMarker(this.props.stores[0].external_id);
            } else {
                this.panToDefaultCenter();
            }
        }
    }

    public onActivateMarker(storeID: ISyncStoreID) {
        const store = this.props.stores.find((s) => s.external_id === storeID);
        if (store && this.map) {
            this.map.panTo(getGoogleLatLng(store));
        }
        const marker = this.markers.get(storeID);
        if (marker) {
            google.maps.event.trigger(marker, "click");
        }
    }

    private panToDefaultCenter() {
        if (
            this.map &&
            this.props.defaultCenter &&
            isLatLngLocation(this.props.defaultCenter)
        ) {
            this.map.panTo(getGoogleLatLng(this.props.defaultCenter));
        }
    }

    private async initInfoWindow() {
        this.info = new google.maps.InfoWindow({
            content: "",
        });
        this.info.addListener("closeclick", () => {
            if (this.props.onInfoWindowClose) {
                this.props.onInfoWindowClose();
            }
        });
    }

    private async renderMap() {
        const mapElem = this.mapElem.current;
        if (!mapElem) {
            return;
        }
        this.map = await newMap(mapElem, {
            center: this.defaultMapCenter,
            zoom: this.defaultZoom,
            // Add in custom map options
            ...(this.props.mapOptions ? this.props.mapOptions() : {}),
        });
        this.map.addListener("bounds_changed", this.renderMarkers.bind(this));
    }

    private buildMarker(store: IRetailStoreWithDistance): google.maps.Marker {
        let marker = this.markers.get(store.external_id);
        if (marker) {
            return marker;
        }
        marker = new google.maps.Marker({
            position: getGoogleLatLng(store),
            title: store.name,
            // Add in custom marker options
            ...(this.props.markerOptions
                ? this.props.markerOptions(store)
                : {}),
        });
        marker.addListener("click", () => {
            if (!this.info) {
                return;
            }
            if (this.props.onBeforeInfoWindowOpen) {
                this.props.onBeforeInfoWindowOpen(this.info, store);
            }
            this.info.open(this.map, marker);
        });
        this.markers.set(store.external_id, marker);
        return marker;
    }

    private renderMarkers() {
        // Get map boundaries
        const mapBounds = this.map ? this.map.getBounds() : null;
        this.props.stores.forEach((store) => {
            // See if there's a cached marker already built
            const cachedMarker = this.markers.get(store.external_id);
            // Get store location
            const storeLocation = getGoogleLatLng(store);
            // If marker is in map boundaries, add it to the map
            // Else, if theres a cached marker, remove it from the map
            if (this.map && mapBounds && mapBounds.contains(storeLocation)) {
                const marker = this.buildMarker(store);
                marker.setMap(this.map);
            } else if (cachedMarker) {
                cachedMarker.setMap(null);
            }
        });
    }

    render() {
        return <div ref={this.mapElem} {...this.props.elemProps} />;
    }
}
