import React from "react";
import { t } from "ttag";
import { IOfferDiscount } from "../../../models/catalogue.interfaces";
import { Image } from "../../../common/Image";
import styles from "./VirtualLineMinimalEnhanced.module.scss";

interface IProps {
    offerData: IOfferDiscount;
}

export const OfferLineMinimal = (props: IProps) => {
    return (
        <section className={styles.virtualLine}>
            {props.offerData.desktop_image && (
                <Image
                    alt={props.offerData.short_name}
                    src={props.offerData.desktop_image}
                />
            )}
            <div className={styles.titleContainer}>
                <h2 className={styles.productTitle}>{props.offerData.name}</h2>
                <span>
                    {t`Qty:`} &nbsp; {props.offerData.freq}
                </span>
            </div>
        </section>
    );
};
