import React from "react";
import { t } from "ttag";
import { ajax, CSRF_HEADER, getCSRFToken } from "../../../utils/ajax";
import { money } from "../../../utils/format";
import { SelectInput } from "./blocks/SelectInput";
import { IOrder, ICustomerDetails } from "../models.interfaces";

interface IProps extends IOrder {
    requestReload: () => void;
}

interface IState {
    csrs: ICustomerDetails[];
    credit_assign_csr: string;
    credit_assign_errors: {
        [field: string]: string[];
    };
}

export class CustomerDetailsOrder extends React.Component<IProps, IState> {
    state: IState = {
        csrs: [],
        credit_assign_csr: "",
        credit_assign_errors: {},
    };

    componentDidMount() {
        this.loadCSRs();
    }

    private async loadCSRs() {
        const resp = await ajax.get("/api/csr/users/csrs/");
        this.setState({
            csrs: resp.body,
            credit_assign_csr: `${resp.body[0].id}`,
        });
    }

    private readonly onAssignCredit = async (
        event: React.FormEvent<HTMLElement>,
    ) => {
        event.preventDefault();
        const data = { csr: this.state.credit_assign_csr };
        try {
            await ajax
                .post(`${this.props.url}give_credit/`)
                .set(CSRF_HEADER, await getCSRFToken())
                .send(data);
            this.props.requestReload();
        } catch (err) {
            this.setState({
                credit_assign_errors: err.response.body,
            });
        }
    };

    private buildLines() {
        return this.props.lines.map((line) => {
            return (
                <tr key={line.id}>
                    <td>{line.id}</td>
                    <td>{line.title}</td>
                    <td>{line.partner_sku || "-"}</td>
                    <td>{line.quantity}</td>
                    <td>{line.status}</td>
                    <td>{money(line.line_price_excl_tax)}</td>
                    <td>{money(line.line_price_incl_tax)}</td>
                    <td></td>
                </tr>
            );
        });
    }

    render() {
        const placed = new Date(this.props.date_placed).toLocaleString();
        const creditErrors = (
            this.state.credit_assign_errors.non_field_errors || []
        ).map((err, i) => {
            return <li key={i}>{err}</li>;
        });
        const csrOptions = this.state.csrs.map((csr): [string, string] => {
            return [
                `${csr.id}`,
                `${csr.first_name} ${csr.last_name} <${csr.email}>`,
            ];
        });
        const selectCSR = (_: string, value: string) => {
            this.setState({ ...this.state, credit_assign_csr: value });
        };
        return (
            <div className="csr-customer-order">
                <div className="csr-customer-order__basic">
                    <table>
                        <thead>
                            <tr>
                                <th colSpan={2}>{t`Basic Details`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>{t`Order Number`}</th>
                                <td>{this.props.number}</td>
                            </tr>
                            <tr>
                                <th>{t`Status`}</th>
                                <td>{this.props.status}</td>
                            </tr>
                            <tr>
                                <th>{t`Shipping`}</th>
                                <td>{this.props.shipping_incl_tax}</td>
                            </tr>
                            <tr>
                                <th>{t`Total (excl. Tax)`}</th>
                                <td>{money(this.props.total_excl_tax)}</td>
                            </tr>
                            <tr>
                                <th>{t`Total (incl. Tax)`}</th>
                                <td>{money(this.props.total_incl_tax)}</td>
                            </tr>
                            <tr>
                                <th>{t`Date Placed`}</th>
                                <td>{placed}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="button-group">
                        <a href={this.props.dashboard_link}>
                            {t`View Order in Dashboard`}
                        </a>
                    </div>
                </div>
                <div className="csr-customer-order__credit">
                    <h2>{t`Order Placement Credit`}</h2>
                    <form onSubmit={this.onAssignCredit}>
                        <p>
                            {t`Use the form below to assign credit for this order to another customer service representative.`}
                        </p>
                        <ul className="error-list">{creditErrors}</ul>
                        <SelectInput
                            name="order_credit_select"
                            label={t`Select a customer service representative.`}
                            errors={this.state.credit_assign_errors.csr}
                            options={csrOptions}
                            value={this.state.credit_assign_csr}
                            onChange={selectCSR}
                            isRequired={true}
                        />
                        <button type="submit">
                            Assign&nbsp;Order&nbsp;Credit
                        </button>
                    </form>
                </div>
                <div className="csr-customer-order__assistance">
                    <h2>{t`Assistance Log`}</h2>
                    <p>{t`Only the latest 10 log entries are shown.`}</p>
                    <table>
                        <thead>
                            <tr>
                                <th>{t`Customer Service Representative`}</th>
                                <th>{t`Description`}</th>
                                <th>{t`Date`}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.assistance_log.length === 0 ? (
                                <tr>
                                    <td colSpan={3}>
                                        <em>
                                            {t`No log entries found for this customer.`}
                                        </em>
                                    </td>
                                </tr>
                            ) : null}
                            {this.props.assistance_log.map((entry) => (
                                <tr key={entry.id}>
                                    <td>
                                        {entry.csr.first_name}{" "}
                                        {entry.csr.last_name} &lt;
                                        {entry.csr.email}&gt;
                                    </td>
                                    <td>{entry.message}</td>
                                    <td>
                                        {new Date(
                                            entry.action_time,
                                        ).toLocaleString()}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="csr-customer-order__lines">
                    <h2>Lines</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>{t`Line ID`}</th>
                                <th>{t`Product`}</th>
                                <th>{t`SKU`}</th>
                                <th>{t`Quantity`}</th>
                                <th>{t`Status`}</th>
                                <th>{t`Line Price (excl. Tax)`}</th>
                                <th>{t`Line Price (incl. Tax)`}</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{this.buildLines()}</tbody>
                    </table>
                </div>
            </div>
        );
    }
}
