import { guardUnhandledAction } from "../../../utils/never";
import { guardReducer } from "../../../utils/redux";
import { defaults } from "../defaults";
import { Action, ConfigureGiftStatus } from "../constants";
import { setOptionValue } from "../../reducer-utils";
import {
    IReduxGiftData,
    IGiftActions,
    IGiftSelection,
} from "../reducers.interfaces";

export const reduceGiftsEvents = guardReducer(
    Action,
    defaults.gifts,
    (state = defaults.gifts, action: IGiftActions): IReduxGiftData => {
        switch (action.type) {
            case Action.SET_CONFIGURE_GIFT_STATUS:
                return {
                    ...state,
                    status: action.payload,
                };

            case Action.SET_CONFIGURABLE_BUNDLES:
                return {
                    ...state,
                    userConfigurableBundles: action.payload,
                    status: ConfigureGiftStatus.LOADED,
                };

            case Action.SET_ADD_TO_BASKET_ERROR_MODAL_STATE:
                return {
                    ...state,
                    addToBasketError: {
                        addToBasketErrorOpen: action.payload.isOpen,
                        addToBasketErrorReason: action.payload.reason,
                    },
                };

            case Action.SET_GIFT_ROOT_PRODUCT:
                // Find default variant
                const defaultVariant =
                    action.payload.rootProduct.children.length > 0
                        ? action.payload.rootProduct.children[0]
                        : action.payload.rootProduct;
                // Set default variant
                const options =
                    defaultVariant.attributes.product_options?.value || [];
                const optionValues: IGiftSelection["optionValues"] = {};
                options.forEach((code) => {
                    const attr = defaultVariant.attributes[code];
                    if (attr) {
                        optionValues[code] = attr.value;
                    }
                });
                return {
                    ...state,
                    gifts: {
                        ...state.gifts,
                        [action.payload.giftID]: {
                            bundleID: action.payload.bundleID,
                            selectedRootProduct: action.payload.rootProduct.id,
                            optionValues: optionValues,
                        },
                    },
                };

            case Action.SET_GIFT_OPTION_VALUE:
                return {
                    ...state,
                    gifts: {
                        ...state.gifts,
                        [action.payload.giftID]: {
                            ...state.gifts[action.payload.giftID],
                            optionValues: setOptionValue(
                                (state.gifts[action.payload.giftID] || {})
                                    .optionValues,
                                action.payload,
                            ),
                        },
                    },
                };

            default:
                guardUnhandledAction(action);
        }
        return state;
    },
);
