import React from "react";
import { createPopper } from "@popperjs/core";
import { BreakPoint } from "../models/screen.interfaces";
import { focusElement } from "../utils/keyboardFocus";
import { getViewportBreakpoint } from "../utils/detectMobile";
import { Modal, IProps as IModalProps } from "./Modal";

export type PopperOptions = Parameters<typeof createPopper>[2];

interface IBaseProps {
    triggerContent: React.ReactNode;
    triggerBtnProps?: React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
    >;
    popperOptions?: PopperOptions;
    modalProps?: Partial<IModalProps>;
    showArrow?: boolean;
    arrowCSSClass?: string;
    isStandardModalBelowSize?: BreakPoint;
    onAfterOpen?: () => void;
    onAfterClose?: () => void;
    disablePopper?: boolean;
    onParentSelector?: () => HTMLElement;
    children?: React.ReactNode;
}

interface IControlledProps extends IBaseProps {
    isOpen: boolean;
    onRequestOpen: () => void;
    onRequestClose: () => void;
    disableOpenButton?: boolean;
}

interface IUncontrolledProps extends IBaseProps {}

interface IControlledState {}

interface IUncontrolledState {
    isOpen: boolean;
}

/**
 * Popover is a combination of:
 *
 * - a trigger button
 * - a modal positioned relative to the trigger button
 *
 * Can be used to create components like drop down menus, tooltip-like modals, etc.
 */
export class Popover extends React.Component<
    IControlledProps,
    IControlledState
> {
    private readonly _triggerBtnElem = React.createRef<HTMLButtonElement>();
    private readonly _triggerDivElem = React.createRef<HTMLDivElement>();
    private readonly modalElem = React.createRef<Modal>();
    private readonly arrowElem = React.createRef<HTMLDivElement>();

    private popper: ReturnType<typeof createPopper> | undefined;

    private readonly onAfterOpen = (): void => {
        if (
            !this.triggerElem.current ||
            !this.modalElem.current ||
            !this.modalElem.current.contentElem
        ) {
            return;
        }
        if (this.popper) {
            this.popper.destroy();
        }
        // Should this be a normal non-positioned modal at the current screen size?
        const breakpoint = getViewportBreakpoint();
        if (
            !this.props.disablePopper &&
            (this.props.isStandardModalBelowSize === undefined ||
                breakpoint > this.props.isStandardModalBelowSize)
        ) {
            // Position the popover
            const popperOptions = this.getPopperOptions();
            this.popper = createPopper(
                this.triggerElem.current,
                this.modalElem.current.contentElem,
                popperOptions,
            );
        }

        // set variable for use in iframe
        if (window.parentIFrame) {
            window.parentIFrame.getPageInfo(() => {
                const popoverClientHeight =
                    this.modalElem.current?.contentElem?.clientHeight.toString();
                document.documentElement.style.setProperty(
                    "--basket-popover--height-popover-with-arrow",
                    popoverClientHeight ? popoverClientHeight : null,
                );
            });
        }

        // Bubble callback
        if (this.props.onAfterOpen) {
            this.props.onAfterOpen();
        }
    };

    private readonly onAfterClose = (): void => {
        if (this.triggerElem.current) {
            focusElement(this.triggerElem.current);
        }
        // Bubble callback
        if (this.props.onAfterClose) {
            this.props.onAfterClose();
        }
    };

    private getPopperOptions(): PopperOptions {
        const popperOptions = {
            ...this.props.popperOptions,
            modifiers: [...(this.props.popperOptions?.modifiers || [])],
        };
        if (this.props.showArrow) {
            popperOptions.modifiers.push({
                name: "arrow",
                options: {
                    element: this.arrowElem.current,
                },
            });
        }
        return popperOptions;
    }

    private get triggerElem() {
        return this.props.disableOpenButton
            ? this._triggerDivElem
            : this._triggerBtnElem;
    }

    private buildTrigger() {
        if (this.props.disableOpenButton) {
            return (
                <div {...this.props.triggerBtnProps} ref={this._triggerDivElem}>
                    {this.props.triggerContent}
                </div>
            );
        }
        return (
            <button
                {...this.props.triggerBtnProps}
                onClick={this.props.onRequestOpen}
                ref={this._triggerBtnElem}
            >
                {this.props.triggerContent}
            </button>
        );
    }

    render() {
        return (
            <>
                {this.buildTrigger()}
                <Modal
                    {...this.props.modalProps}
                    ref={this.modalElem}
                    isOpen={this.props.isOpen}
                    onAfterOpen={this.onAfterOpen}
                    onAfterClose={this.onAfterClose}
                    onRequestClose={this.props.onRequestClose}
                    shouldReturnFocusAfterClose={false}
                    preCloseBtnContent={
                        this.props.showArrow && (
                            <div
                                className={`${this.props.arrowCSSClass} popper-arrow arrow`}
                                aria-hidden={true}
                                ref={this.arrowElem}
                            >
                                <div data-popper-arrow={true} />
                            </div>
                        )
                    }
                    parentSelector={this.props.onParentSelector}
                >
                    {this.props.children}
                </Modal>
            </>
        );
    }
}

/**
 * UncontrolledPopover is a wrapper around Popover that manages the
 * open/closed state for you. It's “uncontrolled” in React's “uncontrolled form
 * input” sense. I.E. the component user doesn't control the open state.
 * Rather, the component manages it internally.
 */
export class UncontrolledPopover extends React.Component<
    IUncontrolledProps,
    IUncontrolledState
> {
    state: IUncontrolledState = {
        isOpen: false,
    };

    public requestClose() {
        this.setState({
            isOpen: false,
        });
    }

    render() {
        return (
            <Popover
                {...this.props}
                isOpen={this.state.isOpen}
                onRequestOpen={() => {
                    this.setState({
                        isOpen: true,
                    });
                }}
                onRequestClose={() => {
                    this.setState({
                        isOpen: false,
                    });
                }}
            >
                {this.props.children}
            </Popover>
        );
    }
}
