import React from "react";
import { connect } from "react-redux";
import { Dispatchers } from "../dispatchers";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { IAttributeOptionGroup } from "../../../models/product-compare.interfaces";
import { SizeSelector as SizeSelectorComponent } from "../components/ProductCompareSizeSelector";
import styles from "./ProductCompareSizeSelector.module.scss";
import classNames from "classnames";
import { ProductCompareTheme } from "../../../constants";

interface IOwnProps {
    sizeAttributeOptionGroup: IAttributeOptionGroup;
    preselectedSize: string | null;
    showSizeSelector?: boolean | null;
    theme?: ProductCompareTheme | null;
}

interface IReduxProps {
    priceSelectValue: string;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IDispatchProps, IReduxProps {}

interface IState {}

class SizeSelectorContainer extends React.Component<IProps, IState> {
    private readonly onSelectChange = (
        e: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        // Update size select value
        this.props.dispatchers.updatePriceSelectValue(e.currentTarget.value);
        localStorage.setItem("selectedSize", e.currentTarget.value);
    };

    componentDidMount() {
        if (this.props.preselectedSize) {
            this.props.dispatchers.updatePriceSelectValue(
                this.props.preselectedSize,
            );
        } else {
            // fetch the size attribute from localStorage
            // and set it only if preselectedSize is not defined
            const selectedSize: string | null =
                localStorage.getItem("selectedSize");
            if (selectedSize) {
                this.props.dispatchers.updatePriceSelectValue(selectedSize);
            }
        }
    }

    render() {
        if (!this.props.showSizeSelector) {
            return null;
        }

        const rootClassNames = classNames({
            [styles.root]: true,
            [this.props.theme || ""]:
                this.props.theme === ProductCompareTheme.THEME_ONE,
        });

        return (
            <div className={rootClassNames}>
                <SizeSelectorComponent
                    sizeAttributeOptionGroupName={
                        this.props.sizeAttributeOptionGroup.name
                    }
                    sizeAttributeOptions={
                        this.props.sizeAttributeOptionGroup.options
                    }
                    priceSelectValue={this.props.priceSelectValue}
                    onSelectChange={this.onSelectChange}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<
    "productcompare",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    const state = rootState.productcompare || defaults;
    return {
        ...ownProps,
        priceSelectValue: state.priceSelectValue,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const SizeSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SizeSelectorContainer);
