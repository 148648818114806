import { readyStateComplete } from "../utils/events";

readyStateComplete.then(async () => {
    const pdpComparison = document.querySelector<HTMLElement>(
        ".comparison-carousel-block__slides",
    );
    if (!pdpComparison) {
        return;
    }
    const Flickity = (await import("flickity")).default;
    const flickity = new Flickity(pdpComparison, {
        watchCSS: true,
        autoPlay: false,
        imagesLoaded: true,
        pageDots: false,
        prevNextButtons: false,
    });

    const buttonGroup = document.querySelector(
        ".comparison-carousel-block__dots",
    );
    if (!buttonGroup) {
        return;
    }

    buttonGroup.addEventListener("click", (event) => {
        const target = event.target as HTMLButtonElement;
        const parent = target.parentNode as HTMLDivElement;
        const index = Array.prototype.indexOf.call(
            parent.children,
            event.target,
        );
        flickity.select(index);
    });

    flickity.on("select", (index) => {
        const selectedButton = buttonGroup.children[Number(index)];
        Array.from(buttonGroup.children, (button) => {
            if (button !== selectedButton) {
                button.classList.remove("is-selected");
                button.setAttribute("aria-current", "false");
            } else {
                button.classList.add("is-selected");
                button.setAttribute("aria-current", "true");
            }
        });
    });

    // previous
    const previousButton = document.querySelector(
        ".comparison-carousel-block__button--prev",
    );
    if (previousButton) {
        previousButton.addEventListener("click", () => {
            flickity.previous();
        });
    }

    // next
    const nextButton = document.querySelector(
        ".comparison-carousel-block__button--next",
    );
    if (nextButton) {
        nextButton.addEventListener("click", () => {
            flickity.next();
        });
    }
});
