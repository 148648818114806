import { IProduct } from "../../models/catalogue.interfaces";
import {
    sortProductsByPrice,
    getCheapestProductVariant,
    productPriceComparator,
} from "../../utils/sorting";
import { SortDirection } from "./models";
import { IGridSorterConfigOptions } from "./models.interfaces";

export abstract class GridSorterConfig {
    public readonly id: string;
    public readonly label: string;
    public readonly direction: SortDirection;

    constructor(opts: IGridSorterConfigOptions) {
        this.id = opts.id;
        this.label = opts.label;
        this.direction = opts.direction;
    }

    public abstract sort(products: IProduct[]): IProduct[];
}

export class AttributeValueGridSorter extends GridSorterConfig {
    public readonly attributeName: string;

    constructor(opts: IGridSorterConfigOptions & { attribute: string }) {
        super(opts);
        this.attributeName = opts.attribute;
    }

    public sort(products: IProduct[]): IProduct[] {
        return products.sort((a, b) => {
            const vA = a.attributes[this.attributeName]?.value || 0;
            const vB = b.attributes[this.attributeName]?.value || 0;
            if (vA === vB) {
                return 0;
            }
            if (this.direction === SortDirection.ASC) {
                return vA > vB ? 1 : -1;
            }
            return vA > vB ? -1 : 1;
        });
    }
}

export class PriceGridSorter extends GridSorterConfig {
    public sort(products: IProduct[]): IProduct[] {
        const sortedProducts = sortProductsByPrice(products);
        return this.direction === SortDirection.ASC
            ? sortedProducts
            : sortedProducts.reverse();
    }
}

/**
 * Same as the normal PriceGridSorter, but pushes anything without a sleep position attribute to the end of the grid.
 * Additionally, adjacent items with the same price are sorted alphabetically
 */
export class PillowsPriceGridSorter extends PriceGridSorter {
    public sort(products: IProduct[]): IProduct[] {
        products = super.sort(products);

        const hasSleepPosition = (p: IProduct): boolean => {
            return !!(
                p.attributes.back_sleeper?.value ||
                p.attributes.side_sleeper?.value ||
                p.attributes.stomach_sleeper?.value
            );
        };

        return products.sort((a, b) => {
            const pAhasSleepPosition = hasSleepPosition(a);
            const pBhasSleepPosition = hasSleepPosition(b);

            if (pAhasSleepPosition && !pBhasSleepPosition) {
                return -1;
            }
            if (!pAhasSleepPosition && pBhasSleepPosition) {
                return 1;
            }

            const vA = getCheapestProductVariant(a);
            const vB = getCheapestProductVariant(b);
            const priceComparison = productPriceComparator(vA, vB);

            if (priceComparison !== 0) {
                return 0;
            }
            return a.title.localeCompare(b.title);
        });
    }
}

export class RatingGridSorter extends GridSorterConfig {
    public sort(products: IProduct[]): IProduct[] {
        return products.sort((a, b) => {
            const rA = a.rating || 0;
            const rB = b.rating || 0;
            if (rA === rB) {
                return 0;
            }
            if (this.direction === SortDirection.ASC) {
                return rA > rB ? 1 : -1;
            }
            return rA > rB ? -1 : 1;
        });
    }
}

export class RatingNumberGridSorter extends GridSorterConfig {
    public sort(products: IProduct[]): IProduct[] {
        return products.sort((a, b) => {
            const rA = a.num_reviews || 0;
            const rB = b.num_reviews || 0;
            if (rA === rB) {
                return 0;
            }
            if (this.direction === SortDirection.ASC) {
                return rA > rB ? 1 : -1;
            }
            return rA > rB ? -1 : 1;
        });
    }
}

export class DateCreatedGridSorter extends GridSorterConfig {
    public sort(products: IProduct[]): IProduct[] {
        return products.sort((a, b) => {
            const rA = a.date_created ? new Date(a.date_created) : new Date();
            const rB = b.date_created ? new Date(b.date_created) : new Date();
            const rATime = rA.getTime();
            const rBTime = rB.getTime();
            if (rATime === rBTime) {
                return 0;
            }
            if (this.direction === SortDirection.ASC) {
                return rATime > rBTime ? 1 : -1;
            }
            return rATime > rBTime ? -1 : 1;
        });
    }
}
