import React from "react";

interface IProps {
    showRecommended: boolean;
    recommended: boolean | null;
}

interface IState {}

export class ReviewRecommended extends React.PureComponent<IProps, IState> {
    render() {
        if (!this.props.showRecommended) {
            return null;
        }
        if (this.props.recommended === null) {
            return null;
        }

        const reviewMsg = this.props.recommended
            ? "YES, I do recommend this product!"
            : "NO, I do not recommend this product";

        return (
            <div>
                <strong>{reviewMsg}</strong>
            </div>
        );
    }
}
