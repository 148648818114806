import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";
import { SortType } from "../interfaces";
import iconArrowDown from "../../../../img/icons/arrow_down.svg";

interface Props extends React.SelectHTMLAttributes<HTMLSelectElement> {
    value: SortType;
}

const sortNames: { [K in SortType]: string } = {
    "-date": "Newest",
    "date": "Oldest",
};

export const SortDropdown = (props: Props) => {
    const sortMapping = Object.entries(sortNames);
    return (
        <section className="search-select">
            <div className="search-select__sort">
                <label className="search-select__label" htmlFor="sort-by">
                    {t`Sort by:`}
                </label>
                <select
                    className="search-select__form-select"
                    {...props}
                    id="sort-by"
                >
                    {sortMapping.map(([value, display]) => (
                        <option key={value} value={value}>
                            {display}
                        </option>
                    ))}
                </select>
                <SVG
                    src={iconArrowDown}
                    className="svg svg--xsmall svg--black search-select__icon"
                    role="presentation"
                />
            </div>
        </section>
    );
};
