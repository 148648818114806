import * as t from "io-ts";
import { ImageURL, SafeHTML } from "./nominals";
import {
    StructBlock,
    SpacingSubBlock,
    BackgroundStylesSubBlock,
    ListBlock,
} from "./streamfield-blocks";

/**
 * See model tsicommon.cms.models.value_props.ValueProp
 */
export const ValueProp = StructBlock(
    {
        title: t.string,
        subtitle: t.string,
        show_tooltip: t.boolean,
        tooltip_contents: SafeHTML,
    },
    {
        icon: t.interface({
            id: t.number,
            title: t.string,
            url: ImageURL,
        }),
    },
);

export const ValuePropBarBlock = StructBlock(
    {
        value_props: ListBlock(ValueProp),
    },
    {
        spacing: SpacingSubBlock,
        background_style: BackgroundStylesSubBlock,
    },
);
