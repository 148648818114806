export interface IViewport {
    width: number;
    height: number;
}

export const enum BreakPoint {
    MIN = 0,
    SMALL = 500,
    MEDIUM = 768,
    LARGE = 960,
}
