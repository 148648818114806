export enum Action {
    SET_PRODUCTS = "ReviewSPA/SET_PRODUCTS",
    SET_PRODUCT_TYPES = "ReviewSPA/SET_PRODUCT_TYPES",
    LOAD_NEXT_PAGE = "ReviewSPA/LOAD_NEXT_PAGE",
    UPDATE_SORTING = "ReviewSPA/UPDATE_SORTING",
    TOGGLE_MOBILE_SIDE_NAV = "ReviewSPA/TOGGLE_MOBILE_SIDE_NAV",
    LOADED_REVIEWS = "ReviewSPA/LOADED_REVIEWS",
    SET_FACET_VALUES = "ReviewSPA/SET_FACET_VALUES",
    UPDATE_PRODUCT_TYPES = "ReviewSPA/UPDATE_PRODUCT_TYPES",
    UPDATE_FILTER_OPTION_VALUE = "ReviewSPA/UPDATE_FILTER_OPTION_VALUE",
    UPDATE_FILTER_OPTION_COLLECTION_VALUE = "ReviewSPA/UPDATE_FILTER_OPTION_COLLECTION_VALUE",
    UPDATE_SOURCE_FILTER_VISIBILITY = "ReviewSPA/UPDATE_SOURCE_FILTER_VISIBILITY",
    CLEAR_FILTERS = "ReviewSPA/CLEAR_FILTERS",

    REGISTER_NEW_WRITE_REVIEW_FORM = "ReviewSPA/REGISTER_NEW_WRITE_REVIEW_FORM",
    SET_WRITE_REVIEW_FORM_MODE = "ReviewSPA/SET_WRITE_REVIEW_FORM_MODE",
    SET_REVIEW_TEMPLATE = "ReviewSPA/SET_REVIEW_TEMPLATE",

    REVIEW_FORM_VARIANT_CHANGE = "ReviewSPA/REVIEW_FORM_VARIANT_CHANGE",
    REVIEW_FORM_TEXT_CHANGE = "ReviewSPA/REVIEW_FORM_TEXT_CHANGE",
    REVIEW_FORM_CHECKBOX_CHANGE = "ReviewSPA/REVIEW_FORM_CHECKBOX_CHANGE",
    REVIEW_FORM_RADIO_CHANGE = "ReviewSPA/REVIEW_FORM_RADIO_CHANGE",
    REVIEW_FORM_SET_ERRORS = "ReviewSPA/REVIEW_FORM_SET_ERRORS",
    REVIEW_SET_CUSTOMER_INFORMATION_EMAIL = "ReviewSPA/REVIEW_SET_CUSTOMER_INFORMATION_EMAIL",
    REVIEW_SET_CUSTOMER_INFORMATION_NAME = "ReviewSPA/REVIEW_SET_CUSTOMER_INFORMATION_NAME",
    REVIEW_SET_CUSTOMER_INFORMATION_LOCATION = "ReviewSPA/REVIEW_SET_CUSTOMER_INFORMATION_LOCATION",
    SUBMIT_REVIEW = "ReviewSPA/SUBMIT_REVIEW",
}

export enum ReviewsComponentTypes {
    CLASSIC = "classic",
    CAROUSEL = "carousel",
}

export enum LoadedReviewsBehavior {
    REPLACE,
    APPEND,
}

export enum WriteReviewFormMode {
    CLOSED = "closed",
    FORM_OPEN = "form-open",
    THANK_YOU_OPEN = "thank-you-open",
}

export enum SubmitReviewStatus {
    SUCCESS,
    FAIL_MISSING_PRODUCT_SELECTION,
    FAIL_MISSING_VARIANT_SELECTION,
    FAIL_INVALID_FIELDS,
    FAIL_API,
}

export enum ReviewDisclosureCode {
    SWEEPSTAKES = "sweepstakes",
    SAMPLING = "sampling",
}

export const SideNames = ["One", "Two"];

export const NOT_SURE_VARIANT_CHOICE_SLUG = "not-sure";
export const REVIEW_VARIANT_NOT_SELECTED = "not-selected";
