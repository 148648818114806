import React from "react";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { baseVariantSelector } from "../selectors";
import { IProductID } from "../../../models/nominals";
import { IAddon, IProduct } from "../../../models/catalogue.interfaces";
import { IModularConfiguratorPromoCard } from "../models.interfaces";
import { ConfiguratorUpsellContainer } from "./ConfiguratorUpsellContainer";
import { ConfiguratorUpsell } from "./ConfiguratorUpsell";
import { BundleGroupTypes, ConfiguratorTypes } from "../../../constants";
import { UpsellInfoModal } from "./ConfiguratorUpsellInfoModal";

export enum OptionPriceType {
    RELATIVE,
    ABSOLUTE,
    FREE_GIFT,
}

interface IOwnProps {
    configuratorType: ConfiguratorTypes;
}

interface IReduxProps {
    baseVariant: IProduct | null;
    selectedUpgrade: IProductID | null;
    selectedAddons: IAddon[];
    promoCards: IModularConfiguratorPromoCard[];
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class ConfiguratorUpsellsComponent extends React.PureComponent<IProps, IState> {
    render() {
        if (!this.props.baseVariant) {
            return null;
        }
        return (
            <>
                {/* Promo Cards */}
                {this.props.promoCards &&
                    this.props.promoCards.map((promo, idx) => {
                        return (
                            <ConfiguratorUpsell
                                key={idx}
                                bundleID={null}
                                bundleType={BundleGroupTypes.PROMO_CARD}
                                sectionTitle={promo.section_title}
                                lineOne={promo.card_title}
                                lineTwo={promo.product_name}
                                lineThree={promo.description}
                                isSelected={false}
                                cardImage={promo.card_image_url}
                                flair={
                                    promo.flair && promo.flair.length > 0
                                        ? promo.flair[0].value
                                        : null
                                }
                                infoModal={promo.info_modal}
                                configuratorType={this.props.configuratorType}
                            />
                        );
                    })}
                {/* Free Gifts */}
                <ConfiguratorUpsellContainer
                    optionPriceType={OptionPriceType.FREE_GIFT}
                    bundleType={BundleGroupTypes.IN_CONFIGURATOR_FREE_GIFT}
                    selectedUpgrades={this.props.selectedAddons}
                    onSelectionChange={
                        this.props.dispatchers.updateSelectedAddons
                    }
                />
                {/* Product Upgrades */}
                <ConfiguratorUpsellContainer
                    optionPriceType={OptionPriceType.RELATIVE}
                    bundleType={BundleGroupTypes.UPGRADE_PRODUCT}
                    selectedUpgrades={
                        this.props.selectedUpgrade
                            ? [
                                  {
                                      productID: this.props.selectedUpgrade,
                                      quantity: 1,
                                  },
                              ]
                            : []
                    }
                    onSelectionChange={(updates) => {
                        if (updates.length > 0) {
                            const update = updates[0];
                            this.props.dispatchers.setSelectedUpgrade(
                                update.quantity > 0 ? update.productID : null,
                            );
                        }
                    }}
                />
                {/* "Change Model" Upgrades */}
                <ConfiguratorUpsellContainer
                    optionPriceType={OptionPriceType.RELATIVE}
                    bundleType={BundleGroupTypes.UPGRADE_MODEL}
                    selectedUpgrades={
                        this.props.selectedUpgrade
                            ? [
                                  {
                                      productID: this.props.selectedUpgrade,
                                      quantity: 1,
                                  },
                              ]
                            : []
                    }
                    onSelectionChange={(updates) => {
                        if (updates.length > 0) {
                            const update = updates[0];
                            this.props.dispatchers.setSelectedUpgrade(
                                update.quantity > 0 ? update.productID : null,
                            );
                        }
                    }}
                />
                {/* Product Add-Ons */}
                <ConfiguratorUpsellContainer
                    optionPriceType={OptionPriceType.ABSOLUTE}
                    bundleType={BundleGroupTypes.IN_CONFIGURATOR_ADD_ON}
                    selectedUpgrades={this.props.selectedAddons}
                    onSelectionChange={
                        this.props.dispatchers.updateSelectedAddons
                    }
                />
                <UpsellInfoModal
                    configuratorType={this.props.configuratorType}
                    selectedUpgrade={this.props.selectedUpgrade}
                    selectedAddons={this.props.selectedAddons}
                />
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        baseVariant: baseVariantSelector(state),
        selectedUpgrade: state.ui.selectedUpgrade,
        selectedAddons: state.ui.selectedAddons,
        promoCards: state.entities.promoCards,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const ConfiguratorUpsells = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfiguratorUpsellsComponent);
