export type MaybeNumber = number | null;

const isNumber = (n: MaybeNumber): n is number => {
    return n !== null;
};

/**
 * Helper function to convert supported types to number
 */
export const getNumber = <T extends MaybeNumber>(
    value: string | number | undefined | null,
    defaultVal: T,
): number | T => {
    if (value === undefined || value === null) {
        return defaultVal;
    }
    if (typeof value === "number") {
        return value;
    }
    // Strip out thousand-separators, dollar signs, etc.
    const cleanedValue = value.replace(/[^\d.]+/g, "");
    return parseFloat(cleanedValue);
};

/**
 * More convenient than parseInt, as it can take string, number, null, or undefined values
 */
export const getInteger = <T extends MaybeNumber>(
    value: string | number | undefined | null,
    defaultVal: T,
): number | T => {
    const num = getNumber(value, defaultVal);
    if (!isNumber(num)) {
        return defaultVal;
    }
    return Math.round(num);
};
