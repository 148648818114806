import { IBasket } from "../models/catalogue.interfaces";
import { FinancingAccountInquiry } from "../models/financing";
import { TypedEvent } from "../utils/events";

export const checkout = {
    onPaymentDecline: new TypedEvent("Checkout", "onPaymentDecline"),
};

export const csr = {
    onBeforeStartAssistingCustomer: new TypedEvent(),
    onAfterStartAssistingCustomer: new TypedEvent(),
    onBeforeStopAssistingCustomer: new TypedEvent(),
    onAfterStopAssistingCustomer: new TypedEvent(),
    onBeforeAssignBasket: new TypedEvent(),
    onAfterAssignBasket: new TypedEvent(),
    onBeforeStartNewBasket: new TypedEvent(),
    onAfterStartNewBasket: new TypedEvent<IBasket>(),
};

export const financing = {
    onApproval: new TypedEvent<FinancingAccountInquiry>(
        "Financing",
        "onCreditAppApproval",
    ),
    onDenial: new TypedEvent("Financing", "onCreditAppDenial"),
};
