export const initWidgets = async (lazy = true) => {
    const loading: Array<Promise<unknown>> = [];

    // Tooltips
    const tooltips = document.querySelectorAll<HTMLElement>("[data-tooltip]");
    if (tooltips) {
        const { initTooltips } = await import("./tooltips");
        initTooltips(lazy);
    }

    // Forms
    const formFields = document.querySelectorAll<HTMLElement>(
        ".form-field-placeholder",
    );
    if (formFields) {
        const { initFormFields } = await import("./forms");
        Array.from(formFields).forEach((formField) => {
            loading.push(initFormFields(formField));
        });
    }

    // Messages
    const messageCloseButtons = document.querySelectorAll<HTMLElement>(
        ".messages__item__close",
    );
    if (messageCloseButtons) {
        const { initMessageCloseButton } = await import("./messages");
        Array.from(messageCloseButtons).forEach((btn) => {
            loading.push(initMessageCloseButton(btn));
        });
    }

    // Accordions
    const accordions =
        document.querySelectorAll<HTMLElement>(".accordion-trigger");
    if (accordions) {
        const { initAccordion } = await import("./accordion");
        Array.from(accordions).forEach((accordion) => {
            loading.push(initAccordion(accordion));
        });
    }

    // Review Carousels
    const reviewCarousels = document.querySelectorAll<HTMLElement>(
        ".customer-review-module__review-list--homepage",
    );
    if (reviewCarousels) {
        const { initCustomerReviewCarousel } = await import(
            "./customer-reviews"
        );
        Array.from(reviewCarousels).forEach((elem) => {
            loading.push(initCustomerReviewCarousel(elem));
        });
    }

    // Wistia Videos
    const videos = document.querySelectorAll<HTMLElement>(".wistia_embed");
    if (videos) {
        const { loadWistiaPlayer } = await import("../utils/wistia");
        if (videos.length > 0) {
            loadWistiaPlayer();
        }
    }

    // Rich Navigation
    const rich_navigations = document.querySelectorAll<HTMLElement>(
        "nav.rich-navigation",
    );
    if (rich_navigations) {
        const { initRichNavigation } = await import("./rich-navigation");
        Array.from(rich_navigations).forEach((rich_navigation) => {
            loading.push(initRichNavigation(rich_navigation));
        });
    }

    // Tweak link behavior
    const links = document.querySelectorAll<HTMLElement>("a[href]");
    if (links) {
        const { initLinks } = await import("./links");
        loading.push(initLinks());
    }

    const { initPromoCalloutMessages } = await import(
        "../utils/domManipulation"
    );
    loading.push(initPromoCalloutMessages());

    // Wait for everything to finish loading
    await Promise.all(loading);

    // Focus first form field with an error
    const fields = document.querySelectorAll<HTMLElement>(
        ".form__field.form__field--has-errors",
    );
    if (fields.length > 0) {
        const { focusElement } = await import("../utils/keyboardFocus");
        const fieldInput = fields[0].querySelector<HTMLElement>(
            "input, select, textarea",
        );
        if (fieldInput) {
            focusElement(fieldInput);
        }
    }
};
