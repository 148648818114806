export enum Action {
    SET_COUNTRIES = "CSR/SET_COUNTRIES",
    SET_CUSTOMER_SEARCH_RESULTS = "CSR/SET_CUSTOMER_SEARCH_RESULTS",
    SET_CUSTOMER_SEARCH_TERM = "CSR/SET_CUSTOMER_SEARCH_TERM",
    SET_BASKET_SEARCH_RESULTS = "CSR/SET_BASKET_SEARCH_RESULTS",
    SET_BASKET_SEARCH_TERM = "CSR/SET_BASKET_SEARCH_TERM",
    SET_BASKET_SEARCH_ERRORS = "CSR/SET_BASKET_SEARCH_ERRORS",
    SET_CURRENTLY_ASSISTED_CUSTOMER = "CSR/SET_CURRENTLY_ASSISTED_CUSTOMER",
    SET_CURRENTLY_ASSISTED_BASKET = "CSR/SET_CURRENTLY_ASSISTED_BASKET",
    SET_VOUCHER_SEARCH_RESULTS = "CSR/SET_VOUCHER_SEARCH_RESULTS",
    SET_VOUCHER_SEARCH_ERRORS = "CSR/SET_VOUCHER_SEARCH_ERRORS",
    CLEAR_VOUCHER_SEARCH = "CSR/CLEAR_VOUCHER_SEARCH",
    VIEW_STACK_CLEAR = "CSR/VIEW_STACK_CLEAR",
    VIEW_STACK_SET_ROOT = "CSR/VIEW_STACK_SET_ROOT",
    VIEW_STACK_PUSH = "CSR/VIEW_STACK_PUSH",
    VIEW_STACK_POP = "CSR/VIEW_STACK_POP",
    VIEW_STACK_REPLACE_TOP = "CSR/VIEW_STACK_REPLACE_TOP",
}
