import React from "react";
import { connect } from "react-redux";
import { getProducts } from "../../../api/reviews";
import {
    IReviewsBrandID,
    IReviewsProductTypeID,
    IFormUUID,
    isoReviewsProductID,
    isoReviewsProductVariantID,
    IWebPageURL,
} from "../../../models/nominals";
import { IReviewQuery } from "../../../models/reviews.interfaces";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { getInteger } from "../../../utils/numbers";
import { Dispatchers } from "../dispatchers";
import { defaultState } from "../defaults";
import { WriteReviewFormMode } from "../constants";
import { WriteReviewThankYou } from "../elements/WriteReviewThankYou";
import { WriteReviewForm } from "../elements/WriteReviewForm";

interface IOwnProps {
    formUUID: IFormUUID;
    brandID: IReviewsBrandID;
    brand?: string;
    returnURL?: IWebPageURL;
}

interface IReduxProps {
    loadedInitialProducts: boolean;
    productTypeIDs: IReviewsProductTypeID[];
    facetValues: IReviewQuery;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class SingleProductWriteReviewFormContainer extends React.Component<
    IProps,
    IState
> {
    private readonly onReviewSubmit = () => {
        const reviewModuleTitle = document.querySelector(
            ".write-review-button__container",
        );
        reviewModuleTitle?.scrollIntoView({ behavior: "smooth" });
    };

    componentDidMount() {
        this.props.dispatchers.registerNewWriteReviewForm(
            this.props.formUUID,
            WriteReviewFormMode.FORM_OPEN,
        );
        this.listProducts();
    }

    private async listProducts() {
        const products = await getProducts(this.props.brandID);
        this.props.dispatchers.setProducts(products);
    }

    render() {
        if (!this.props.loadedInitialProducts) {
            return null;
        }
        const defaultProductTypeID = this.props.productTypeIDs[0];
        const defaultProductID = isoReviewsProductID.wrap(
            getInteger(this.props.facetValues.product_id, 0),
        );
        const defaultProductVariantID = isoReviewsProductVariantID.wrap(
            getInteger(this.props.facetValues.product_variant_id, 0),
        );
        return (
            <div className="write-review-button__container">
                <WriteReviewThankYou formUUID={this.props.formUUID} />
                <WriteReviewForm
                    formUUID={this.props.formUUID}
                    formTitle={"Choose your product"}
                    brand={this.props.brand}
                    onReviewSubmit={this.onReviewSubmit}
                    defaultProductTypeID={defaultProductTypeID}
                    defaultProductID={defaultProductID}
                    defaultProductVariantID={defaultProductVariantID}
                    returnURL={this.props.returnURL}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        loadedInitialProducts: state.data.loadedInitialProducts,
        productTypeIDs: state.ui.productTypeIDs,
        facetValues: state.ui.facetValues,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const SingleProductWriteReviewForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SingleProductWriteReviewFormContainer);
