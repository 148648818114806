import React from "react";
import { RecommendedProductData } from "../models/ugc";
import { RatingGraphic } from "./RatingGraphic";
import { Image } from "./Image";

interface IProps {
    recommendations: RecommendedProductData[];
}

interface IState {}

export class UGCRecommendations extends React.PureComponent<IProps, IState> {
    private buildProducts() {
        return this.props.recommendations.map((product, key) => {
            let productImage: JSX.Element | null = null;
            if (product.image_url) {
                productImage = (
                    <Image
                        className="ugc-recommends-product__image"
                        src={product.image_url}
                        alt=""
                    />
                );
            }
            return (
                <a
                    key={key}
                    className="ugc-recommends-product"
                    href={product.url}
                    target="_blank"
                    title={product.title}
                    rel="noreferrer"
                >
                    {productImage}
                    <div>
                        <div className="ugc-recommends-product__title">
                            {product.title}
                        </div>
                        <RatingGraphic
                            rating={product.rating}
                            numReviews={product.num_reviews}
                            cardClass={"ugc-recommends-product"}
                            cardSize={"small"}
                        />
                    </div>
                </a>
            );
        });
    }

    render() {
        return (
            <section className="ugc-recommends">
                <div className="ugc-recommends__products">
                    {this.buildProducts()}
                </div>
            </section>
        );
    }
}

export default UGCRecommendations;
