import * as t from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";
import { regexp } from "io-ts-types/lib/regexp";
import { Either, isLeft, chain } from "fp-ts/lib/Either";
import { map } from "fp-ts/lib/Record";
import { pipe } from "fp-ts/lib/function";
import dinero from "dinero.js/build/cjs/dinero";
import { STR_YES, STR_NO } from "../constants";
import { isDinero } from "../utils/guards";
import { getDinero } from "../utils/money";
import { decimal } from "../utils/format";

export const check = <T>(result: Either<t.Errors, T>): T => {
    if (isLeft(result)) {
        const msg = PathReporter.report(result).join("\n\n");
        console.log(msg);
        throw new Error(msg);
    }
    return result.right;
};

export const nullable = <RT extends t.Mixed>(type: RT) => {
    return t.union([t.null, type]);
};

export const optional = <RT extends t.Mixed>(type: RT) => {
    return t.union([t.undefined, type]);
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export type RecordKeyType = t.KeyofC<any>;

export const Record = <KS extends RecordKeyType, T extends t.Any>(
    k: KS,
    type: T,
) => {
    return map(() => type)(k.keys) as Record<keyof KS["keys"], T>;
};

export const PartialRecord = <KS extends RecordKeyType, T extends t.Any>(
    k: KS,
    type: T,
) => {
    return t.partial(Record(k, type));
};

/**
 * Generic for making tuples of a defined size:
 *
 * const foo: TupleOf<number, 3> = [1, 2, 3];
 */
export type TupleOf<T, N extends number> = N extends N
    ? number extends N
        ? T[]
        : _TupleOf<T, N, []>
    : never;
export type _TupleOf<
    T,
    N extends number,
    R extends unknown[],
> = R["length"] extends N ? R : _TupleOf<T, N, [T, ...R]>;

/**
 * io-ts Codec to convert string ('true', 'false', '0', '1', ''), into a nullable boolean type.
 * Based on https://github.com/gcanti/io-ts-types/blob/master/src/BooleanFromString.ts
 */
export type NullBooleanFromStringC = t.Type<boolean | null, string, unknown>;
export const NullBooleanFromString: NullBooleanFromStringC = new t.Type<
    boolean | null,
    string,
    unknown
>(
    "BooleanFromString",
    t.boolean.is,
    (u, c) => {
        return pipe(
            t.string.validate(u, c),
            chain((s) => {
                return s === "true" || s === "1" || s === "yes"
                    ? t.success(true)
                    : s === "false" || s === "0" || s === "no"
                      ? t.success(false)
                      : t.success(null);
            }),
        );
    },
    String,
);

export const NullString = nullable(t.string);
export const NullNumber = nullable(t.number);
export const NullBoolean = nullable(t.boolean);

export const codecFromEnum = <EnumType extends string>(
    enumName: string,
    theEnum: Record<string, EnumType>,
): t.Type<EnumType, EnumType, unknown> => {
    const isEnumValue = (input: unknown): input is EnumType => {
        return Object.values<unknown>(theEnum).includes(input);
    };
    return new t.Type<EnumType>(
        enumName,
        isEnumValue,
        (input, context) => {
            return isEnumValue(input)
                ? t.success(input)
                : t.failure(input, context);
        },
        t.identity,
    );
};

type ValueOf<T> = T[keyof T];

export const codecFromConstMap = <
    MapType extends Record<string, string | number>,
>(
    mapName: string,
    theMap: MapType,
): t.Type<ValueOf<MapType>, ValueOf<MapType>, unknown> => {
    const isMapValue = (input: unknown): input is ValueOf<MapType> => {
        return Object.values<unknown>(theMap).includes(input);
    };
    return new t.Type<ValueOf<MapType>>(
        mapName,
        isMapValue,
        (input, context) => {
            return isMapValue(input)
                ? t.success(input)
                : t.failure(input, context);
        },
        t.identity,
    );
};

/**
 * Codec for getting a yes/no string from a boolean
 */
export type StringFromBooleanC = t.Type<string, boolean, unknown>;
export const StringFromBoolean: StringFromBooleanC = new t.Type<
    string,
    boolean,
    unknown
>(
    "StringFromBoolean",
    t.string.is,
    (u, c) => {
        return pipe(
            t.boolean.validate(u, c),
            chain((bool) => {
                return t.success(bool ? STR_YES : STR_NO);
            }),
        );
    },
    (yesno) => yesno === STR_YES,
);

/**
 * Codec for getting a boolean from a yes/no string
 */
export type BooleanFromStringC = t.Type<boolean, string, unknown>;
export const BooleanFromString: BooleanFromStringC = new t.Type<
    boolean,
    string,
    unknown
>(
    "BooleanFromString",
    t.boolean.is,
    (u, c) => {
        return pipe(
            t.string.validate(u, c),
            chain((str) => {
                return t.success(str === STR_YES);
            }),
        );
    },
    (bool) => (bool ? STR_YES : STR_NO),
);

/**
 * Codec for getting a boolean from a yes/no string
 */
export type DineroFromStringC = t.Type<dinero.Dinero, string, unknown>;
export const DineroFromString: DineroFromStringC = new t.Type<
    dinero.Dinero,
    string,
    unknown
>(
    "DineroFromString",
    isDinero,
    (u, c) => {
        return pipe(
            t.string.validate(u, c),
            chain((str) => {
                return t.success(getDinero(str));
            }),
        );
    },
    (money) => decimal(money),
);

/**
 * Codec for getting a Regexp from a string
 */
export type RegExpFromStringC = t.Type<RegExp, string, unknown>;
export const RegExpFromString: RegExpFromStringC = new t.Type<
    RegExp,
    string,
    unknown
>(
    "RegExpFromString",
    regexp.is,
    (u, c) => {
        return pipe(
            t.string.validate(u, c),
            chain((str) => {
                return t.success(new RegExp(str));
            }),
        );
    },
    (pattern) => pattern.toString(),
);

type Enumerate<
    N extends number,
    Acc extends number[] = [],
> = Acc["length"] extends N
    ? Acc[number]
    : Enumerate<N, [...Acc, Acc["length"]]>;

/**
 * Bounded numeric range type
 */
export type Range<F extends number, T extends number> = Exclude<
    Enumerate<T>,
    Enumerate<F>
>;

/**
 * Codec for validating that a number is within a given range
 */
export const Range = <F extends number, T extends number>(
    minIncl: F,
    maxExcl: T,
) => {
    type _Range = Range<F, T>;
    type _RangeC = t.Type<_Range, number, unknown>;
    const isNumInRange = (num: unknown): num is _Range => {
        return typeof num === "number" && num >= minIncl && num < maxExcl;
    };
    const _Range: _RangeC = new t.Type<_Range, number, unknown>(
        "Range",
        isNumInRange,
        (u, c) => {
            return pipe(
                t.number.validate(u, c),
                chain((num) => {
                    return isNumInRange(num)
                        ? t.success(num)
                        : t.failure(num, c);
                }),
            );
        },
        t.identity,
    );
    return _Range;
};
