import { ProductFlair } from "tsi-common-react/src/models/product-compare";
import { ImageChooserBlock } from "tsi-common-react/src/models/streamfield-blocks";
import { nullable } from "tsi-common-react/src/models/utils";
import * as t from "io-ts";

export const InStoreOffer = t.interface({
    image: nullable(ImageChooserBlock),
    text: t.string,
    flair: nullable(ProductFlair),
});

export type IInstoreOffer = t.TypeOf<typeof InStoreOffer>;

export const InStoreOffers = t.array(InStoreOffer);

export type IInStoreOffers = t.TypeOf<typeof InStoreOffers>;

export const InStoreOffersCMSData = t.interface({
    offers: InStoreOffers,
});
