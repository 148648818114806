import { combineReducers } from "@reduxjs/toolkit";
import { isZipLocation } from "../../utils/guards";
import { guardReducer } from "../../utils/redux";
import { getChatConnector } from "../chat/index";
import { Action, IReduxState, IAction } from "./reducers.interfaces";

const defaults: IReduxState = {
    detectedLocation: null,
    enteredLocation: null,
    user: null,
    viewport: {
        width: typeof window == "undefined" ? 1440 : window.innerWidth,
        height: typeof window == "undefined" ? 700 : window.innerHeight,
    },
};

const detectedLocationReducer = guardReducer(
    Action,
    defaults.detectedLocation,
    (
        state = defaults.detectedLocation,
        action: IAction,
    ): IReduxState["detectedLocation"] => {
        switch (action.type) {
            case Action.UPDATE_DETECTED_LOCATION:
                return action.payload;
        }
        return state;
    },
);

const enteredLocationReducer = guardReducer(
    Action,
    defaults.enteredLocation,
    (
        state = defaults.enteredLocation,
        action: IAction,
    ): IReduxState["enteredLocation"] => {
        switch (action.type) {
            case Action.UPDATE_ENTERED_LOCATION:
                if (isZipLocation(action.payload)) {
                    action.payload.zip = action.payload.zip.slice(0, 5);
                }
                return action.payload;
        }
        return state;
    },
);

const userReducer = guardReducer(
    Action,
    defaults.user,
    (state = defaults.user, action: IAction): IReduxState["user"] => {
        switch (action.type) {
            case Action.SET_USER:
                if (action.payload.email) {
                    getChatConnector().setUserData(action.payload);
                }
                return action.payload;
        }
        return state;
    },
);

const viewportReducer = guardReducer(
    Action,
    defaults.viewport,
    (state = defaults.viewport, action: IAction): IReduxState["viewport"] => {
        switch (action.type) {
            case Action.SET_VIEWPORT:
                return action.payload;
        }
        return state;
    },
);

export const reducers = combineReducers({
    detectedLocation: detectedLocationReducer,
    enteredLocation: enteredLocationReducer,
    user: userReducer,
    viewport: viewportReducer,
});
