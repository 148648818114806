import React from "react";
import {
    IAttributeOptionGroup,
    ICompareTile,
    IGroupColumnsHeader,
} from "../../../models/product-compare.interfaces";
import { ITileID } from "../../../models/product-compare";
import { SizeSelector } from "../containers/ProductCompareSizeSelector";
import { RichText } from "../../../common/RichText";
import { ISafeHTML, isoSafeHTML, IWebPageURL } from "../../../models/nominals";
import { ProductSelectionGridColumn } from "../elements/ProductSelectionGridColumn";
import { ProductCompareTheme } from "../../../constants";
import { getHighestVisibleAttributeCount } from "../utility";

import styles from "./ProductSelectionGrid.module.scss";
import LoadingSpinner from "../../../common/LoadingSpinner";
import classNames from "classnames";

interface IProps {
    header: ISafeHTML;
    productComparePageURL: IWebPageURL | null;
    tiles: ICompareTile[];
    showStartingAt?: boolean;
    showRating?: boolean;
    showInStoreCallout?: boolean;
    ctaText?: string;
    selectedTileIDs: ITileID[];
    preselectedSize: string | null;
    priceSelectValue: string;
    financingLink: IWebPageURL;
    sizeAttributeOptionGroup: IAttributeOptionGroup | null;
    theme: ProductCompareTheme;
    footer: ISafeHTML | null;
    groupHeader?: IGroupColumnsHeader | null;
    showSizeSelector?: boolean | null;
    addToSelected: (tileID: ITileID) => void;
    removeFromSelected: (tileID: ITileID) => void;
    showModal: () => void;
}

interface IState {}

export class ProductSelectionGrid extends React.Component<IProps, IState> {
    private readonly ctaText = this.props.ctaText
        ? this.props.ctaText
        : "Compare All Mattresses";

    private buildTileIDString() {
        const tileIDs = this.props.tiles.map((tile) => {
            return tile.product_compare_tile.id;
        });

        return tileIDs.join(",");
    }

    private buildHeaderContent(url: string): ISafeHTML {
        if (this.props.productComparePageURL) {
            const link = `<a href="${url}">${this.ctaText}</a>`;
            return isoSafeHTML.wrap(`${this.props.header}${link}`);
        }
        return isoSafeHTML.wrap(`${this.props.header}`);
    }

    render() {
        const isThemeOne = this.props.theme === ProductCompareTheme.THEME_ONE;
        const url = `${
            this.props.productComparePageURL
        }?tiles=${this.buildTileIDString()}&return_url=${encodeURI(
            window.location.pathname,
        )}`;
        const activeTiles = this.props.tiles.filter(
            (tile) => tile.is_promo_period_active !== false,
        );
        const allTilesLength = activeTiles.length;
        const groupedColumns =
            this.props.groupHeader && isThemeOne ? activeTiles.slice(-2) : null;
        const firstGroupTiles =
            this.props.groupHeader && isThemeOne
                ? activeTiles.slice(0, allTilesLength - 2)
                : activeTiles;

        const headerClassNames = classNames({
            [styles.header]: true,
            [this.props.theme || ""]: isThemeOne,
        });
        const tilesClassNames = classNames({
            [styles.tiles]: true,
            [styles.tilesThemeOne]: isThemeOne,
        });
        const rootClasses = classNames({
            [styles.root]: true,
            [styles.rootThemeOne]: isThemeOne,
        });
        const highestVisibleAttributeCount =
            getHighestVisibleAttributeCount(firstGroupTiles);

        return (
            <div className={rootClasses}>
                <header className={headerClassNames}>
                    <RichText html={this.buildHeaderContent(url)} />
                </header>
                {this.props.sizeAttributeOptionGroup && (
                    <SizeSelector
                        sizeAttributeOptionGroup={
                            this.props.sizeAttributeOptionGroup
                        }
                        preselectedSize={this.props.preselectedSize}
                        showSizeSelector={this.props.showSizeSelector}
                        theme={this.props.theme}
                    />
                )}
                <div className={tilesClassNames}>
                    {activeTiles.length === 0 && <LoadingSpinner />}
                    {firstGroupTiles.map((tile) => (
                        <ProductSelectionGridColumn
                            key={`tile-${tile.product_compare_tile.id}`}
                            tile={tile}
                            highestAttributeCount={highestVisibleAttributeCount}
                            {...this.props}
                        />
                    ))}
                    {this.props.groupHeader && isThemeOne && (
                        <div className={styles.groupTiles}>
                            {this.props.groupHeader?.content && (
                                <RichText
                                    className={`group-columns-header ${
                                        this.props.groupHeader.alignment || ""
                                    }`}
                                    html={this.props.groupHeader.content}
                                />
                            )}
                            <div className={tilesClassNames}>
                                {groupedColumns?.map((tile) => (
                                    <ProductSelectionGridColumn
                                        key={`tile-${tile.product_compare_tile.id}`}
                                        tile={tile}
                                        {...this.props}
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {this.props.productComparePageURL && (
                    <a
                        className="button button--secondary button--compare-all al-compareallmattresses-whitebutton"
                        href={url}
                    >
                        {this.ctaText}
                    </a>
                )}
                {this.props.footer && (
                    <footer>
                        <RichText html={this.props.footer} />
                    </footer>
                )}
            </div>
        );
    }
}
