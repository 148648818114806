import {
    IAttributeOptionGroup,
    ICompareTile,
} from "../../models/product-compare.interfaces";

export const setShowStartingAt = (
    tile: ICompareTile,
    sizeAttributeOptionGroup: IAttributeOptionGroup | null,
) => {
    let showStartingAt = sizeAttributeOptionGroup ? false : true;
    if (showStartingAt) {
        tile.rootProducts.map((rootProduct) => {
            if (rootProduct.children.length === 0) {
                showStartingAt = false;
            }
        });
    }
    return showStartingAt;
};

// Determine the highest count of visible attributes when the row type title is "Construction" across all rows in all columns.
export const getHighestVisibleAttributeCount = (
    firstGroupTiles: ICompareTile[],
) => {
    let highestCount = 0;

    firstGroupTiles.forEach((tile) => {
        tile.rows.forEach((row) => {
            if (row.row_type.title === "Construction") {
                const visibleAttributesCount = row.attributes.filter(
                    (attribute) => attribute.show_attribute,
                ).length;
                if (visibleAttributesCount > highestCount) {
                    highestCount = visibleAttributesCount;
                }
            }
        });
    });

    return highestCount;
};
