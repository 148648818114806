import React from "react";
import SVG from "react-inlinesvg";
import { t } from "ttag";
import iconSearch from "../../../../img/icons/search.svg";
import iconXClose from "../../../../img/icons/x-close.svg";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    onSearch: (searchTerm: string) => void;
}

interface State {
    terms: string[];
}

export class SearchField extends React.Component<Props, State> {
    private readonly fieldRef = React.createRef<HTMLInputElement>();

    readonly state: State = {
        terms: [],
    };

    doSearch() {
        this.props.onSearch(this.state.terms.join(" "));
    }

    render() {
        const { onSearch, ...props } = this.props;

        return (
            <>
                <form
                    className="search-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        const field = this.fieldRef.current;
                        if (field) {
                            this.setState(
                                (state) => ({
                                    terms: [...state.terms, field.value],
                                }),
                                () => {
                                    this.doSearch();
                                    field.value = "";
                                },
                            );
                        }
                    }}
                >
                    <div className="search-form__search">
                        <label
                            className="ada-screenreader-only"
                            htmlFor="search-box"
                        >
                            {t`Search posts`}
                        </label>
                        <input
                            ref={this.fieldRef}
                            className="search-form__input"
                            {...props}
                            id="search-box"
                            placeholder="Search posts"
                        />

                        <button
                            className="search-form__magnifying-glass"
                            type="submit"
                            aria-label="search TempurLove posts"
                        >
                            <SVG
                                src={iconSearch}
                                title={t`Search`}
                                className="svg svg--xsmall svg--black"
                                role="presentation"
                                aria-hidden="true"
                            />
                        </button>
                    </div>
                </form>
                {/* Pills */}
                {this.state.terms.length > 0 && (
                    <section className="search-tags">
                        {this.state.terms.map((term, index) => (
                            <button
                                key={term + index.toString()}
                                onClick={() => {
                                    this.setState(
                                        (state) => ({
                                            terms: state.terms.filter(
                                                (_, otherIndex) =>
                                                    index !== otherIndex,
                                            ),
                                        }),
                                        this.doSearch,
                                    );
                                }}
                                className="search-tag"
                                aria-label={`Remove search term: ${term}`}
                            >
                                <span className="search-tag__copy">{term}</span>

                                <SVG
                                    className="svg svg--white svg--xsmall search-tag__close"
                                    role="presentation"
                                    src={iconXClose}
                                    aria-hidden="true"
                                />
                            </button>
                        ))}
                        <button
                            onClick={() => {
                                this.setState({ terms: [] }, this.doSearch);
                            }}
                            className="search-tag search-tag--clear"
                        >
                            {t`clear all tags`}
                        </button>
                    </section>
                )}
            </>
        );
    }
}
