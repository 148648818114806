import { Dispatch } from "@reduxjs/toolkit";
import { ICompareTile } from "../../models/product-compare.interfaces";
import { ITileID } from "../../models/product-compare";
import {
    IAction,
    IActionSetCompareTiles,
    IActionAddToSelected,
    IActionRemoveFromSelected,
    IActionUpdatePriceSelectValue,
    IActionCollapseTray,
    IActionExpandTray,
    IActionShowModal,
    IActionHideModal,
    IActionSetRootProducts,
} from "./reducers.interfaces";
import { Action } from "./constants";
import { IProduct } from "../../models/catalogue.interfaces";

export class Dispatchers {
    dispatch: Dispatch<IAction>;

    constructor(dispatch: Dispatch<IAction>) {
        this.dispatch = dispatch;
    }

    public readonly setCompareTiles = (tiles: ICompareTile[]) => {
        this.dispatch<IActionSetCompareTiles>({
            type: Action.SET_COMPARE_TILES,
            payload: {
                tiles: tiles,
            },
        });
    };

    public readonly addToSelected = (tileID: ITileID) => {
        this.dispatch<IActionAddToSelected>({
            type: Action.ADD_TO_SELECTED,
            payload: {
                tileID: tileID,
            },
        });
    };

    public readonly removeFromSelected = (tileID: ITileID) => {
        this.dispatch<IActionRemoveFromSelected>({
            type: Action.REMOVE_FROM_SELECTED,
            payload: {
                tileID: tileID,
            },
        });
    };

    public readonly updatePriceSelectValue = (value: string) => {
        this.dispatch<IActionUpdatePriceSelectValue>({
            type: Action.UPDATE_PRICE_SELECT_VALUE,
            payload: {
                value: value,
            },
        });
    };

    public readonly collapseTray = () => {
        this.dispatch<IActionCollapseTray>({
            type: Action.COLLAPSE_TRAY,
        });
    };

    public readonly expandTray = () => {
        this.dispatch<IActionExpandTray>({
            type: Action.EXPAND_TRAY,
        });
    };

    public readonly showModal = () => {
        this.dispatch<IActionShowModal>({
            type: Action.SHOW_MODAL,
        });
    };

    public readonly hideModal = () => {
        this.dispatch<IActionHideModal>({
            type: Action.HIDE_MODAL,
        });
    };

    public readonly setRootProducts = (rootProducts: IProduct[]) => {
        this.dispatch<IActionSetRootProducts>({
            type: Action.SET_ROOT_PRODUCTS,
            payload: {
                rootProducts: rootProducts,
            },
        });
    };
}
