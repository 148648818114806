import React from "react";
import { t } from "ttag";
import { IWebPageURL } from "../../../models/nominals";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IAPIPrice } from "../../../models/prices.interfaces";
import { IUpsellModalComponentClass } from "../models.interfaces";
import { ConfiguratorPrice } from "../../../common/ConfiguratorPrice";
import { ProductUnavailable } from "../../../common/ProductUnavailable";
import { PredictedDeliveryDate } from "../../checkout/components/PredictedDeliveryDate";
import { ConfiguratorPriceLoading } from "../elements/ConfiguratorPriceLoading";
import { ConfiguratorOptions } from "../elements/ConfiguratorOptions";
import { RatingsSummary } from "../elements/RatingsSummary";
import { QuantitySelector } from "../elements/QuantitySelector";
import { AddToBasketButton } from "../elements/AddToBasketButton";
import { RetailChannel } from "../elements/RetailChannel";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import Flair from "../../../common/Flair";
import SVG from "react-inlinesvg";
import styles from "./SplitChannelConfigurator.module.scss";
import {
    PredictedDeliveryDateDisplayType,
    PredictedDeliveryDateStyle,
} from "../../checkout/constants";
import iconStore from "../../../../img/icons/store.svg";
import iconCartSo from "../../../../img/icons/cart--so.svg";

interface IProps {
    optionSet: IModularConfiguratorOptionSet;
    // Basic Data
    title?: string;
    financingLink: IWebPageURL;
    rootProduct: IProduct | null;
    upgradedVariant: IProduct | null;
    price: IAPIPrice | null;
    basketLink: IWebPageURL;
    configureGiftsLink: IWebPageURL;
    // Optional: Up Sell Modal functionality
    showUpsellModal?: (product: IProduct | null) => boolean;
    getUpsellModalComponentClass?: (
        product: IProduct,
    ) => IUpsellModalComponentClass | null;
    buttonColor: string;
    starRatingURL?: IWebPageURL;
    promoComponents?: JSX.Element | null;

    children?: React.ReactNode;
    deliveryIsFree?: boolean;
}

interface IState {}

export class SplitChannelConfigurator extends React.PureComponent<
    IProps,
    IState
> {
    private buildPrice() {
        if (!this.props.price || !this.props.upgradedVariant) {
            return (
                <ConfiguratorPriceLoading
                    configuratorType={this.props.optionSet.layout.design}
                    alternatePriceView={false}
                />
            );
        }
        return (
            <ConfiguratorPrice
                price={this.props.price}
                variant={this.props.upgradedVariant}
                selectedAddonPrice={null}
                configuratorType={this.props.optionSet.layout.design}
                financingLink={this.props.financingLink}
                strikeThroughMSRP={true}
                discountDisplayMode={
                    this.props.optionSet.discounts.discount_mode
                }
                percentageDiscountFormat={
                    this.props.optionSet.discounts.percentage_discount_format
                }
                absoluteDiscountFormat={
                    this.props.optionSet.discounts.absolute_discount_format
                }
                actualPriceStyle={""}
                alternatePriceView={false}
            />
        );
    }

    private buildPromoContent() {
        if (
            !this.props.rootProduct ||
            !this.props.rootProduct.attributes.configurator_promo_content
        ) {
            return null;
        }

        const flair = {
            style: "flair--surf-crest-tag",
            content: `${this.props.rootProduct.attributes.configurator_promo_content.value}`,
        };

        return (
            <div className={styles.promoContent}>
                <Flair flair={flair} inline={true} />
            </div>
        );
    }

    private buildRetailChannel() {
        return (
            <div className={styles.channel}>
                <div className={styles.channelTitle}>
                    <SVG className="mysvg" aria-hidden="true" src={iconStore} />
                    <h3>{t`Call for Availability`}</h3>
                </div>
                <RetailChannel />
            </div>
        );
    }

    private buildEcomChannel() {
        if (!this.props.rootProduct) {
            return null;
        }
        return (
            <div className={styles.channel}>
                <div className={styles.channelTitle}>
                    <SVG
                        className="mysvg"
                        aria-hidden="true"
                        src={iconCartSo}
                    />
                    <h3 className={styles.buyNowTitle}>
                        {t`Ready to Buy Now?`}
                    </h3>
                </div>
                <ConfiguratorOptions
                    optionSet={this.props.optionSet}
                    chatLinkComponent={null}
                    financingLink={this.props.financingLink}
                />
                {this.props.optionSet.layout.enable_predicted_date && (
                    <PredictedDeliveryDate
                        type={PredictedDeliveryDateDisplayType.SHOW_AGGRESSIVE}
                        style={PredictedDeliveryDateStyle.TWO_LINE}
                        deliveryIsFree={this.props.deliveryIsFree}
                    />
                )}
                <div className="configurator__selection">
                    {this.props.optionSet.layout.enable_quantity_selector && (
                        <QuantitySelector
                            configuratorType={
                                this.props.optionSet.layout.design
                            }
                        />
                    )}
                    <AddToBasketButton
                        basketLink={this.props.basketLink}
                        configureGiftsLink={this.props.configureGiftsLink}
                        configuratorType={this.props.optionSet.layout.design}
                        buttonColor={this.props.buttonColor}
                        showUpsellModal={this.props.showUpsellModal}
                        getUpsellModalComponentClass={
                            this.props.getUpsellModalComponentClass
                        }
                    />
                </div>
            </div>
        );
    }

    render() {
        const variantIsAvailableToBuy = !!(
            this.props.rootProduct &&
            this.props.upgradedVariant &&
            this.props.upgradedVariant.availability.is_available_to_buy
        );
        return (
            <div>
                <div>
                    <h2>{this.props.title}</h2>
                    {this.props.rootProduct &&
                        this.props.optionSet.layout.enable_star_rating && (
                            <RatingsSummary
                                isPanel={false}
                                starRatingURL={this.props.starRatingURL}
                                showModal={false}
                            />
                        )}
                    {variantIsAvailableToBuy ? (
                        this.buildPrice()
                    ) : (
                        <ProductUnavailable
                            variant={this.props.upgradedVariant}
                        />
                    )}
                    <div hidden={!variantIsAvailableToBuy}>
                        {/* This has to be here since SYFs widget won't tolerate the
                            price element being removed/re-added as part of the
                            react render process.
                        */}
                        {this.props.promoComponents}
                    </div>
                </div>
                {this.buildPromoContent()}
                <div className={styles.channels}>
                    {this.buildRetailChannel()}
                    <div className={styles.channelSeparator}>
                        <p>or</p>
                        <svg height="46" width="46">
                            <circle
                                cx="23"
                                cy="23"
                                r="23"
                                stroke="white"
                                strokeWidth="10"
                            />
                        </svg>
                        <div className={styles.verticalLine}></div>
                    </div>
                    {this.buildEcomChannel()}
                </div>
            </div>
        );
    }
}
