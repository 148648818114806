import React from "react";
import { t } from "ttag";
import { IOrder } from "../../../models/checkout.interfaces";
import format from "../../../utils/format";

interface IProps {
    order: IOrder;
    disableFreeShippingMessage?: boolean;
}

interface IState {}

export class OrderSummary extends React.Component<IProps, IState> {
    private buildRow(label: string, value: string) {
        return (
            <span className="basket-summary-line">
                <dt className="basket-summary-line__title">{label}</dt>
                <dd className="basket-summary-line__data">{value}</dd>
            </span>
        );
    }

    render() {
        const totalDiscount =
            this.props.order.basket_total_before_discounts_excl_tax.subtract(
                this.props.order.basket_total_excl_tax,
            );
        const totalTax = this.props.order.total_incl_tax.subtract(
            this.props.order.total_excl_tax,
        );
        return (
            <div className="">
                <dl className="basket-summary__prices">
                    {this.buildRow(
                        t`Subtotal`,
                        format.money(
                            this.props.order
                                .basket_total_before_discounts_excl_tax,
                        ),
                    )}
                    {!totalDiscount.isZero() &&
                        this.buildRow(
                            t`Total Savings`,
                            format.money(totalDiscount),
                        )}
                    {this.buildRow(
                        t`Shipping`,
                        format.money(this.props.order.shipping_incl_tax),
                    )}
                    {this.buildRow(t`Tax`, format.money(totalTax))}
                    {this.buildRow(
                        t`Total`,
                        format.money(this.props.order.total_incl_tax),
                    )}
                </dl>
            </div>
        );
    }
}
