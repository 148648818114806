import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { FormTextbox } from "../../../forms/FormTextbox";
import { IReduxFormState } from "../reducers.interfaces";

interface IProps {
    value: string;
    changeFormField: (changedFields: Partial<IReduxFormState>) => void;
    disabled: boolean;
}

interface IState {
    isToggleOn?: boolean;
}

export class ShippingNotes extends React.Component<IProps, IState> {
    public state: IState = {
        isToggleOn: false,
    };

    render() {
        const noteClasses = classNames({
            "checkout-step__notes--collapsed": !this.state.isToggleOn,
        });

        const arrowClasses = classNames({
            "checkout-step__notes__arrow-right": !this.state.isToggleOn,
            "checkout-step__notes__arrow-down": this.state.isToggleOn,
        });

        const toggleNotesVisibility = (
            event: React.MouseEvent<HTMLAnchorElement>,
        ) => {
            event.preventDefault();
            event.stopPropagation();
            this.setState({
                isToggleOn: !this.state.isToggleOn,
            });
        };

        const setShippingNotes = (
            event: React.FormEvent<HTMLTextAreaElement>,
        ) => {
            const value = event.currentTarget.value.replace(
                /[^a-zA-Z0-9.,!'"() ]/g,
                "",
            );
            if (value.length > 70) {
                return;
            }
            this.props.changeFormField({
                shipping_notes: value,
            });
        };

        return (
            <div>
                <p className="checkout-step__notes-show" id="show-notes">
                    <a
                        className="checkout-step__notes-show--link"
                        href="#"
                        onClick={toggleNotesVisibility}
                        aria-controls="shipping_notes"
                        aria-expanded={this.state.isToggleOn}
                    >
                        <span
                            className={arrowClasses}
                            aria-hidden={true}
                        ></span>
                        {t`Add delivery notes (optional)`}
                    </a>
                </p>
                <div className={noteClasses} id="shipping_notes">
                    <FormTextbox
                        name="shipping_notes"
                        id="shipping_notes_input"
                        label={t`Enter delivery notes...`}
                        labelPlacement="dynamic"
                        maxLength={70}
                        rows={1}
                        cols={50}
                        value={this.props.value}
                        onChange={setShippingNotes}
                        disabled={this.props.disabled}
                    />
                </div>
            </div>
        );
    }
}
