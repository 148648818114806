import React from "react";
import { connect } from "react-redux";
import { ILocation } from "../../../models/location.interfaces";
import {
    TStateMapper,
    TDispatchMapper,
} from "../../../apps/reducers.interfaces";
import { runValidator } from "../../../forms/validation";
import { isZipLocation } from "../../../utils/guards";
import { Dispatchers } from "../dispatchers";
import { preferredLocationSelector } from "../selectors";

import styles from "./EditablePostalCode.module.scss";

interface IOwnProps {
    isReadOnly?: boolean;
    className?: string;
}

interface IReduxProps {
    currentLocation: ILocation | null;
}

interface IDispatchProps {
    onUpdateEnteredLocation: (location: ILocation) => void;
}

interface IProps extends IReduxProps, IOwnProps, IDispatchProps {}

interface IState {
    isFocused: boolean;
    value: string;
}

class EditablePostalCodeContainer extends React.Component<IProps, IState> {
    state: IState = {
        isFocused: false,
        value: "",
    };

    private readonly inputElem = React.createRef<HTMLInputElement>();

    private readonly onFocus = () => {
        this.setState({
            isFocused: true,
            value: this.getInitialVal(),
        });
    };

    private readonly onBlur = () => {
        const errMsg = runValidator("zipcode", this.state.value);
        if (!errMsg) {
            this.props.onUpdateEnteredLocation({
                formatted_address: this.state.value,
                zip: this.state.value,
            });
        }
        this.setState({
            isFocused: false,
        });
    };

    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            value: e.currentTarget.value,
        });
    };

    private getInitialVal() {
        const initialVal =
            this.props.currentLocation &&
            isZipLocation(this.props.currentLocation)
                ? this.props.currentLocation.zip
                : this.props.currentLocation?.formatted_address;
        return initialVal || "";
    }

    private get value(): string {
        return this.isEditing ? this.state.value : this.getInitialVal();
    }

    private get isEditing(): boolean {
        return this.state.isFocused && !this.props.isReadOnly;
    }

    render() {
        const classes = this.props.className
            ? this.props.className
            : `${styles.input} al-predicted-delivery-date__zip-code`;

        return (
            <span className={styles.inputWrapper}>
                <label htmlFor={"editable-postal-code"} aria-hidden={true}>
                    {this.value}
                </label>
                <input
                    id={"editable-postal-code"}
                    ref={this.inputElem}
                    className={classes}
                    value={this.value}
                    disabled={this.props.isReadOnly}
                    autoComplete="postal-code"
                    placeholder={"ZIP"}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                />
            </span>
        );
    }
}

const mapStateToProps: TStateMapper<"common", IReduxProps, IOwnProps> = (
    state,
    ownProps,
) => {
    return {
        currentLocation: preferredLocationSelector(state),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onUpdateEnteredLocation: dispatchers.updateEnteredLocation,
    };
};

export const EditablePostalCode = connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditablePostalCodeContainer);
