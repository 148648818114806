import React from "react";
import { IBaseField } from "../models/formFields.interfaces";
import { IFormFieldState, AbstractFormField } from "./AbstractFormField";

export abstract class AbstractSelect<
    FieldName extends string,
    IProps extends IBaseField<HTMLSelectElement, FieldName>,
    IState extends IFormFieldState,
> extends AbstractFormField<FieldName, HTMLSelectElement, IProps, IState> {
    protected updateValueAttribute(event: React.FormEvent<HTMLSelectElement>) {
        this.value = event.currentTarget.value;
    }
}
