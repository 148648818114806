import { IReduxState } from "./reducers.interfaces";
import { MattressSizeOptions, FetchResultsStatusType } from "./constants";

export const defaults: IReduxState = {
    ui: {
        selections: {
            selectedSize: MattressSizeOptions.QUEEN,
            selectedBudget: 0,
            selectedHeight: 0,
            selectedWeight: 0,
            selectedFirmness: 0,
            selectedCoolness: 0,
        },
        loadedResults: null,
        fetchResultsStatus: FetchResultsStatusType.IDLE,
        currentScreen: 0,
    },
};
