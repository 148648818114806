import {
    IOptionCode,
    IOptionValues,
    IOptionValue,
    IOptionSingleValue,
    IOptionMultiValue,
} from "../models/catalogue.interfaces";
import { notEmpty } from "../utils/functional";

export interface ISetOptionValuePayload {
    namespace: string;
    code: IOptionCode;
    index: number;
    totalNumValues: number;
    value: IOptionSingleValue;
}

export const setOptionValue = (
    optionValues: IOptionValues,
    actionPayload: ISetOptionValuePayload,
): IOptionValues => {
    const currentValueRaw = optionValues[actionPayload.code];
    const currentValue: IOptionMultiValue = currentValueRaw
        ? Array.isArray(currentValueRaw)
            ? currentValueRaw
            : [currentValueRaw]
        : [];
    let newValue: IOptionValue = [...currentValue];
    // Make sure newValue array is the correct length for this attribute
    newValue = newValue.filter(notEmpty).slice(0, actionPayload.totalNumValues);
    for (let i = 0; i < actionPayload.totalNumValues; i++) {
        newValue[i] = newValue[i] || newValue[0] || "";
    }
    // Set the new option value
    newValue[actionPayload.index] = actionPayload.value;
    // If the newValue array is still a single item, this must be a single-value attribute, so set it as such (not an array).
    if (newValue.length === 1) {
        newValue = newValue[0];
    }
    return {
        ...optionValues,
        [actionPayload.code]: newValue,
    };
};
