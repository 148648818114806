import {
    IAddressCountry,
    IAddressState,
    IAddress,
} from "../models/address.interfaces";
import { ajax } from "../utils/ajax";
import { PromiseMutex } from "../utils/mutex";

/**
 * List countries.
 */
export const loadCountries = async (): Promise<IAddressCountry[]> => {
    const mutex = new PromiseMutex<IAddressCountry[]>(
        "address-load-countries",
        true,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/countries/")
            .set("Accept", "application/json")
            .then((resp) => {
                return resp.body;
            });
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * List states for the given country code
 */
export const loadStates = async (
    countryCode: string,
): Promise<IAddressState[]> => {
    if (!countryCode) {
        throw new Error(
            "Cannot list shipping methods without a destination state",
        );
    }

    const mutex = new PromiseMutex<IAddressState[]>(
        `address-load-${countryCode}-states`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/countries/${countryCode}/states/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return resp.body;
            });
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * List addresses saved for the user
 */
export const listUserAddresses = async (): Promise<IAddress[]> => {
    const mutex = new PromiseMutex<IAddress[]>("load-user-addresses");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/saved-addresses/`)
            .set("Accept", "application/json")
            .then((resp): IAddress[] => {
                return resp.body;
            });
        mutex.setPromise(loading);
    }
    return loading;
};
