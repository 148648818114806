import React from "react";

interface IProps {
    fmtString: string | null;
    data: {
        [key: string]: (
            content: string | React.ReactNode,
            index: number,
        ) => React.ReactNode;
    };
}

interface IState {}

export class Trans extends React.Component<IProps, IState> {
    private buildStringFragment(str: string): React.ReactNode {
        if (!str) {
            return null;
        }
        return <React.Fragment key={str}>{str}</React.Fragment>;
    }

    render() {
        if (!this.props.fmtString) {
            return null;
        }
        // Render the React components references in the format string, replacing
        // their references with index markers. e.g. Starting with:
        //
        //    This is a translated string <MyLink>with a link</MyLink> in it.
        //
        // And converts it to this:
        //
        //    This is a translated string <0> in it.
        //
        // …while also rendering the React component for <MyLink> and saving it
        // into an array.
        const components: React.ReactNode[] = [];
        const preparedFmtString = Object.keys(this.props.data).reduce<string>(
            (memo, key) => {
                const tag = new RegExp(`<${key}>([^<]*)</${key}>`, "g");
                return memo.replace(tag, (_, content: string) => {
                    const i = components.length;
                    const component = this.props.data[key](content, i);
                    components.push(component);
                    return `<${i}>`;
                });
            },
            this.props.fmtString,
        );

        // Not that we have a prepared format string that look like this:
        //
        //    This is a translated string <0> in it.
        //
        // Convert it into an array of React Fragments, interlaced with the
        // previously rendered React components.
        let remainingFmtString = preparedFmtString;
        const fragments = components.reduce<React.ReactNode[]>(
            (memo, component, index) => {
                const [strBefore, strAfter] = remainingFmtString.split(
                    `<${index}>`,
                    2,
                );
                memo.push(this.buildStringFragment(strBefore));
                memo.push(component);
                remainingFmtString = strAfter;
                return memo;
            },
            [],
        );
        fragments.push(this.buildStringFragment(remainingFmtString));

        // Return the full rendered translated string
        return <>{fragments}</>;
    }
}

export default Trans;
