import React from "react";
import memoize from "memoize-one";
import { connect } from "react-redux";
import { check } from "../../../models/utils";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { SplitChannelConfigurator as Component } from "../components/SplitChannelConfigurator";
import { IWebPageURL, IProductID } from "../../../models/nominals";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IAPIPrice } from "../../../models/prices.interfaces";
import { trackListrackProductBrowse } from "../../../utils/analytics";
import { urls } from "../../../utils/urls";
import { Loaders } from "../loaders";
import { Dispatchers } from "../dispatchers";
import { defaults } from "../defaults";
import {
    rootProductSelector,
    baseVariantSelector,
    upgradedVariantSelector,
    upgradedVariantPriceSelector,
} from "../selectors";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { ModularConfiguratorOptionSet } from "../models";
import {
    startHistoryListener,
    pushPDPVariantURLChange,
    getSelectedUpgradeIDFromURL,
} from "../history";
import { IUpsellModalComponentClass } from "../models.interfaces";

export interface IOwnProps {
    optionSetJSON: string;
    title?: string;
    showUpsellModal?: (product: IProduct | null) => boolean;
    getUpsellModalComponentClass?: (
        product: IProduct,
    ) => IUpsellModalComponentClass | null;
    buttonColor?: string;
    starRatingURL?: IWebPageURL;
    promoComponents?: JSX.Element | null;
    deliveryIsFree?: boolean;
}

interface IReduxProps {
    rootProducts: IProduct[];
    rootProduct: IProduct | null;
    baseVariant: IProduct | null;
    selectedUpgradeID: IProductID | null;
    upgradedVariant: IProduct | null;
    price: IAPIPrice | null;
    quantity: number;
}

interface IDispatchProps {
    onDidMount: (opts: { enableHistoryInteraction: boolean }) => void;
    loaders: Loaders;
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class SplitChannelConfiguratorContainer extends React.Component<
    IProps,
    IState
> {
    private readonly parseOptionSetJSON = memoize(
        (optionSetJSON: string): IModularConfiguratorOptionSet => {
            const data = check(
                ModularConfiguratorOptionSet.decode(JSON.parse(optionSetJSON)),
            );
            return data;
        },
    );

    private get optionSet(): IModularConfiguratorOptionSet {
        return this.parseOptionSetJSON(this.props.optionSetJSON);
    }

    componentDidMount() {
        this.props.onDidMount({
            enableHistoryInteraction: true,
        });

        // Load main product from API
        if (this.optionSet.product_ids.length <= 0) {
            throw new Error("rootProductIDs array must not be empty");
        }
        this.props.dispatchers.setVariantPrefilter(
            this.optionSet.variant_prefilter,
        );
        this.props.loaders.loadProducts(
            this.optionSet.product_ids,
            this.optionSet.initial_variant_id,
        );

        // Load Bundles
        const baseVariantID =
            this.props.baseVariant && this.props.baseVariant.id
                ? this.props.baseVariant.id
                : null;
        if (baseVariantID) {
            const selectedUpgradeID = getSelectedUpgradeIDFromURL();
            this.props.loaders.loadConcreteBundles(
                baseVariantID,
                selectedUpgradeID,
            );
        }
    }

    componentDidUpdate(prevProps: IProps) {
        // Did the base selection change?
        const prevBaseVariantID =
            prevProps.baseVariant && prevProps.baseVariant.id
                ? prevProps.baseVariant.id
                : null;
        const nextBaseVariantID =
            this.props.baseVariant && this.props.baseVariant.id
                ? this.props.baseVariant.id
                : null;
        const baseVariantChanged = prevBaseVariantID !== nextBaseVariantID;

        // Did the upgraded variant change?
        const prevUpgradedVariantID =
            prevProps.upgradedVariant && prevProps.upgradedVariant.id
                ? prevProps.upgradedVariant.id
                : null;
        const nextUpgradedVariantID =
            this.props.upgradedVariant && this.props.upgradedVariant.id
                ? this.props.upgradedVariant.id
                : null;
        const upgradedVariantChanged =
            prevUpgradedVariantID !== nextUpgradedVariantID;

        // Did the quantity change?
        const prevQty = prevProps.quantity;
        const nextQty = this.props.quantity;
        const qtyChanged = prevQty !== nextQty;

        // Update the page URL to include the new selection data
        if (baseVariantChanged || upgradedVariantChanged) {
            pushPDPVariantURLChange(
                this.props.rootProduct,
                this.props.baseVariant,
                this.props.selectedUpgradeID,
            );
            // Refire Listrak AddProductBrowse activity only when the selected variant is changed.
            if (
                prevProps.baseVariant &&
                this.props.baseVariant &&
                this.props.baseVariant.skus.length > 0
            ) {
                trackListrackProductBrowse(this.props.baseVariant.skus[0]);
            }
        }
        if (baseVariantChanged && nextBaseVariantID) {
            const selectedUpgradeID = getSelectedUpgradeIDFromURL();
            this.props.loaders.loadConcreteBundles(
                nextBaseVariantID,
                selectedUpgradeID,
            );
        }

        // Load the price data for quantities > 1
        if (
            nextUpgradedVariantID &&
            (upgradedVariantChanged || qtyChanged) &&
            this.props.quantity > 1
        ) {
            this.props.loaders.loadPrice(
                nextUpgradedVariantID,
                this.props.quantity,
            );
        }
    }

    render() {
        return (
            <Component
                optionSet={this.optionSet}
                title={this.props.title}
                financingLink={urls.pageURL("finance-link")}
                rootProduct={this.props.rootProduct}
                upgradedVariant={this.props.upgradedVariant}
                price={this.props.price}
                basketLink={urls.pageURL("basket-summary")}
                configureGiftsLink={urls.pageURL("configure-gifts")}
                showUpsellModal={this.props.showUpsellModal}
                getUpsellModalComponentClass={
                    this.props.getUpsellModalComponentClass
                }
                buttonColor={this.props.buttonColor || "primary-congress-blue"}
                starRatingURL={this.props.starRatingURL}
                promoComponents={this.props.promoComponents}
                deliveryIsFree={this.props.deliveryIsFree}
            ></Component>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        // Basic Data
        rootProducts: state.entities.rootProducts,

        // Data derived from API data combined with user selected options
        rootProduct: rootProductSelector(state),
        baseVariant: baseVariantSelector(state),
        selectedUpgradeID: state.ui.selectedUpgrade,
        upgradedVariant: upgradedVariantSelector(state),
        price: upgradedVariantPriceSelector(state),

        // Option selector state
        quantity: state.ui.quantity,

        // Direct Props
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    return {
        onDidMount: ({ enableHistoryInteraction }) => {
            if (enableHistoryInteraction) {
                startHistoryListener(dispatch);
            }
        },
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const SplitChannelConfigurator = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SplitChannelConfiguratorContainer);
