import type { IProductAPIURL } from "./models/nominals";
import {
    curryOrderURL,
    loadCustomerOrders,
    loadOrderCancellationInfo,
    cancelOrder,
} from "./api/orders";
import { addProductToBasket } from "./apps/configurator/actions";
import { urls } from "./utils/urls";

/**
 * See these docs for a full description of this API:
 *
 * https://docs.svc.tsiaws.com/tsi-requirements/dev/chat/chat-handler-functions.html
 */
export const publicAPI = {
    /**
     * Add a product to the current customer's basket.
     */
    chatAddToCart: async (
        url: IProductAPIURL,
        quantity: number,
    ): Promise<void> => {
        try {
            await addProductToBasket(url, quantity);
            urls.navigateTo("basket-summary");
            return;
        } catch (e) {
            console.log(e);
            throw e;
        }
    },

    /**
     * Fetch and return an array of the current customer's order history.
     */
    getUserOrders: loadCustomerOrders,

    /**
     * Get cancellation-related metadata for the given order.
     */
    getOrderCancellationInfo: curryOrderURL(loadOrderCancellationInfo),

    /**
     * Attempt to cancel the given order.
     */
    cancelOrder: curryOrderURL(cancelOrder),
} as const;

export type TSIEcomPublicAPI = typeof publicAPI;
