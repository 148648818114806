import React from "react";
import { default as Spinner, SpinnerProps } from "react-spinkit";

interface IProps {
    // accepts values full (the default), half, quarter, and none for one second, a half second, a quarter second, and no fade in, respectively
    fadeIn?: SpinnerProps["fadeIn"];
}

interface IState {}

export class LoadingSpinner extends React.Component<IProps, IState> {
    render() {
        const _Spinner = Spinner as React.ComponentClass<SpinnerProps>;
        return (
            <div className="loading-spinner l-capped-width">
                <div className="loading-spinner__inner">
                    <_Spinner
                        name="three-bounce"
                        fadeIn={this.props.fadeIn ? this.props.fadeIn : "full"}
                    />
                </div>
            </div>
        );
    }
}

export default LoadingSpinner;
