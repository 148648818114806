import React from "react";
import { connect } from "react-redux";
import { IProductID } from "../../../models/nominals";
import { Dispatchers } from "../../configurator/dispatchers";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { rootProductSelector } from "../../configurator/selectors";
import { ProductCompareSelectionTile as ProductCompareSelectionTileComponent } from "../elements/ProductCompareSelectionTile";
import { IProduct } from "../../../models/catalogue.interfaces";
import * as productsAPI from "../../../api/products";

interface IOwnProps {
    tile: ICompareTile;
}

interface IReduxProps {
    pageRootProduct: IProductID | null;
}

interface IDispatchProps {
    onSelectRootProduct: (product: IProduct) => void;
}

interface IProps extends IOwnProps, IDispatchProps, IReduxProps {}

interface IState {
    products: IProduct[];
}

class ProductCompareSelectionTileContainer extends React.Component<
    IProps,
    IState
> {
    public state: IState = {
        products: [],
    };

    componentDidMount() {
        this.loadProduct();
    }

    private readonly onProductSelect = () => {
        if (this.state.products.length > 0) {
            this.props.onSelectRootProduct(this.state.products[0]);
        }
    };

    private async loadProduct() {
        const products = await Promise.all(
            this.props.tile.product_compare_tile.product_ids.map((pid) => {
                const productURL = productsAPI.getProductURL(pid);
                return productsAPI.getProduct(productURL);
            }),
        );
        this.setState({
            products: products,
        });
    }

    render() {
        const isSelected =
            this.props.tile.product_compare_tile.product_id ===
            this.props.pageRootProduct;
        return (
            <div>
                <ProductCompareSelectionTileComponent
                    products={this.state.products}
                    isSelected={isSelected}
                    onProductSelect={this.onProductSelect}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const rootProduct = rootProductSelector(rootState.configurator);

    return {
        ...ownProps,
        pageRootProduct: rootProduct ? rootProduct.id : null,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);

    return {
        onSelectRootProduct: (product) => {
            dispatchers.setSelectedRootProduct(product);
        },
    };
};

export const ProductCompareSelectionTile = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCompareSelectionTileContainer);
