import * as t from "io-ts";

const BaseAddress = t.interface({
    title: t.string,
    first_name: t.string,
    last_name: t.string,
    line1: t.string,
    line2: t.string,
    line4: t.string,
    state: t.string,
    postcode: t.string,
    country: t.string,
    phone_number: t.string,
});

export const Address = t.intersection([
    BaseAddress,
    t.interface({
        url: t.string,
    }),
]);

export const BillingAddress = t.intersection([
    BaseAddress,
    t.interface({
        id: t.number,
        search_text: t.string,
    }),
]);

export const ShippingAddress = t.intersection([
    BillingAddress,
    t.interface({
        notes: t.string,
    }),
]);
