import React from "react";
import { connect } from "react-redux";
import { Loaders } from "../loaders";
import { Dispatchers } from "../dispatchers";
import { ISafeHTML, IWebPageURL } from "../../../models/nominals";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import {
    IAttributeOptionGroup,
    ICompareTile,
    IGroupColumnsHeader,
} from "../../../models/product-compare.interfaces";
import { ITileID, isoTileID } from "../../../models/product-compare";
import { SelectedProductTray } from "./SelectedProductTray";
import { ProductSelectionGrid as ProductSelectionGridComponent } from "../components/ProductSelectionGrid";
import { trackCategoryPageView } from "../../../utils/analytics";
import { notEmpty } from "../../../utils/functional";
import { getSelectedVariant } from "../selectors";
import { ProductCompareTheme } from "../../../constants";

interface IOwnProps {
    header: ISafeHTML;
    financingLink: IWebPageURL;
    ctaText?: string;
    preselectedSize: string | null;
    productComparePageURL: IWebPageURL | null;
    sizeAttributeOptionGroup: IAttributeOptionGroup | null;
    showStartingAt?: boolean;
    showRating?: boolean;
    showInStoreCallout?: boolean;
    theme: ProductCompareTheme;
    footer: ISafeHTML | null;
    groupHeader?: IGroupColumnsHeader | null;
    showSizeSelector?: boolean | null;
}

interface IReduxProps {
    tiles: ICompareTile[];
    selectedTileIDs: ITileID[];
    priceSelectValue: string;
}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IDispatchProps, IReduxProps {}

interface IState {}

class ProductSelectionGridContainer extends React.Component<IProps, IState> {
    private trackCategoryView() {
        if (!this.props.tiles) {
            return;
        }
        // Obtain SKUs for current variants
        const skus = this.props.tiles
            .map((tile) => {
                const variant = getSelectedVariant(
                    tile,
                    this.props.priceSelectValue,
                );
                return variant ? variant.skus[0] : null;
            })
            .filter(notEmpty);
        trackCategoryPageView(skus);
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("tiles")) {
            const selectedTileIDs = decodeURIComponent(
                urlParams.get("tiles") || "",
            )
                .split(",")
                .map(Number)
                .map(isoTileID.wrap);
            if (selectedTileIDs.length < 5) {
                selectedTileIDs.map((tileID) => {
                    this.props.loaders.loadSelectedTileID(tileID);
                });
            } else {
                urlParams.delete("tiles");
                const newURL = `${window.location.origin}${
                    window.location.pathname
                }?${urlParams.toString()}`;
                window.history.replaceState(history.state, "", newURL);
            }
        }
    }

    componentDidUpdate() {
        this.trackCategoryView();
    }

    render() {
        const showCompareFunctionality = [
            ProductCompareTheme.WITHOUT_COMPARE,
            ProductCompareTheme.THEME_ONE,
        ].includes(this.props.theme)
            ? false
            : true;
        return (
            <>
                {showCompareFunctionality && (
                    <SelectedProductTray
                        tiles={this.props.tiles}
                        productComparePageURL={this.props.productComparePageURL}
                        ctaText={this.props.ctaText}
                    />
                )}
                <ProductSelectionGridComponent
                    header={this.props.header}
                    tiles={this.props.tiles}
                    selectedTileIDs={this.props.selectedTileIDs}
                    priceSelectValue={this.props.priceSelectValue}
                    financingLink={this.props.financingLink}
                    addToSelected={this.props.dispatchers.addToSelected}
                    removeFromSelected={
                        this.props.dispatchers.removeFromSelected
                    }
                    showStartingAt={this.props.showStartingAt}
                    showInStoreCallout={this.props.showInStoreCallout}
                    showRating={this.props.showRating}
                    ctaText={this.props.ctaText}
                    showModal={this.props.dispatchers.showModal}
                    productComparePageURL={this.props.productComparePageURL}
                    preselectedSize={this.props.preselectedSize || null}
                    sizeAttributeOptionGroup={
                        this.props.sizeAttributeOptionGroup
                    }
                    theme={this.props.theme}
                    footer={this.props.footer}
                    groupHeader={this.props.groupHeader}
                    showSizeSelector={this.props.showSizeSelector}
                />
            </>
        );
    }
}

const mapStateToProps: TStateMapper<
    "productcompare",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    const state = rootState.productcompare || defaults;
    return {
        ...ownProps,
        tiles: state.tiles,
        selectedTileIDs: state.selectedTileIDs,
        priceSelectValue: state.priceSelectValue,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);

    return {
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const ProductSelectionGrid = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductSelectionGridContainer);
