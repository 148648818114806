import React from "react";
import Flickity from "flickity-imagesloaded";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { assertNever } from "../../../utils/never";
import format from "../../../utils/format";
import { ZERO } from "../../../utils/money";
import { IValuePropBarBlock } from "../../../models/value-props.interfaces";
import {
    IModularConfiguratorUpsellInfoModal,
    IUpsellInfoModalImageSlide,
    IUpsellInfoModalImageGallery,
    IUpsellInfoModalFeaturesBlock,
    IUpsellInfoModalSegmentedRichTextBlock,
    IUpsellInfoModalRichTextBlock,
    IModularConfigurator_UpsellInfoModalContent,
    IModularConfigurator_UpsellInfoModalProduct,
} from "../models.interfaces";
import { InternalProduct } from "../../../models/catalogue";
import { RichText } from "../../../common/RichText";
import { Image } from "../../../common/Image";
import { RatingGraphic } from "../../../common/RatingGraphic";
import { ValuePropsBar } from "../../../common/ValuePropsBar";
import Flair from "../../../common/Flair";
import { IProductID } from "../../../models/nominals";
import { getUpgradeID } from "../selectors";
import {
    IAddon,
    IProduct,
    IConcreteBundle,
} from "../../../models/catalogue.interfaces";
import { PriceType, getProductPrice } from "../../../utils/catalogue";
import { BundleGroupTypes } from "../../../constants";
import * as productsAPI from "../../../api/products";

import styles from "./ConfiguratorUpsellInfoModal.module.scss";
import iconUpsellModalSelectedIcon from "../../../../img/icons/upsell-modal-selected-icon.svg";
import iconUpsellModalSelectIcon from "../../../../img/icons/upsell-modal-select-icon.svg";

const UpsellInfoModalImageSlide = (props: {
    slide: IUpsellInfoModalImageSlide;
}) => {
    if (!props.slide.image) {
        return null;
    }
    const url =
        props.slide.image.renditions?.desktop_img?.url ?? props.slide.image.url;
    return (
        <Image
            className={styles.imageSlide}
            src={url}
            alt={props.slide.image.title}
            lazy={false}
        />
    );
};

class UpsellInfoModalImageGallery extends React.Component<
    { block: IUpsellInfoModalImageGallery },
    never
> {
    private readonly imageListElem = React.createRef<HTMLDivElement>();
    private flkty: Flickity | null = null;

    componentDidMount() {
        this.initFlickity();
    }

    componentDidUpdate() {
        this.initFlickity();
    }

    private initFlickity() {
        if (!this.imageListElem.current) {
            return;
        }
        if (this.flkty) {
            this.flkty.destroy();
        }
        this.flkty = new Flickity(this.imageListElem.current, {
            cellAlign: "left",
            pageDots: true,
            prevNextButtons: true,
            imagesLoaded: true,
        });
    }

    render() {
        if (this.props.block.slides.length === 1) {
            const slide = this.props.block.slides[0];
            return (
                <Image
                    className={styles.imageSlide}
                    src={slide.image?.url}
                    alt={slide.image?.title}
                    lazy={false}
                />
            );
        }
        return (
            <div className={styles.imageGallery}>
                <div ref={this.imageListElem}>
                    {this.props.block.slides.map((slide, idx) => (
                        <UpsellInfoModalImageSlide key={idx} slide={slide} />
                    ))}
                </div>
            </div>
        );
    }
}

const UpsellInfoModalFeaturesBlock = (props: {
    isOneProduct: boolean;
    block: IUpsellInfoModalFeaturesBlock;
}) => {
    return (
        <div>
            <h3 className={styles.featuresHeading}>{props.block.heading}</h3>
            <ul className={styles.featuresList}>
                {props.block.features.map((feature, idx) => (
                    <li key={idx} className={styles.featuresItem}>
                        {props.block.list_icon && (
                            <Image
                                className={styles.featuresIcon}
                                src={props.block.list_icon?.url}
                                alt=""
                            />
                        )}
                        <span>{feature.content}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const UpsellInfoModalSegmentedRichTextBlock = (props: {
    block: IUpsellInfoModalSegmentedRichTextBlock;
}) => {
    return (
        // TODO: Temporary CSS class to allow site-specific styling
        <div className={styles.segmentedRichText}>
            {props.block.segments.map((rich_text, idx) => {
                return <RichText key={idx} html={rich_text} />;
            })}
        </div>
    );
};

const UpsellInfoModalRichTextBlock = (props: {
    block: IUpsellInfoModalRichTextBlock;
}) => {
    return (
        <div className={styles.upsellRichTextBlock}>
            {props.block.heading && (
                <h3 className={styles.featuresHeading}>
                    {props.block.heading}
                </h3>
            )}
            {props.block.content && <RichText html={props.block.content} />}
        </div>
    );
};

const UpsellInfoModalValueProps = (props: { block: IValuePropBarBlock }) => {
    return (
        <div className={styles.valueProps}>
            <ValuePropsBar
                className="upsell-info-modal"
                valueProps={props.block.value_props}
            />
        </div>
    );
};

const UpsellInfoModalBlock = (props: {
    isOneProduct: boolean;
    block: IModularConfigurator_UpsellInfoModalContent;
}) => {
    switch (props.block.type) {
        case "features":
            return (
                <UpsellInfoModalFeaturesBlock
                    isOneProduct={props.isOneProduct}
                    block={props.block.value}
                />
            );
        case "image_gallery":
            return <UpsellInfoModalImageGallery block={props.block.value} />;
        case "rich_text":
            return <UpsellInfoModalRichTextBlock block={props.block.value} />;
        case "segmented_rich_text":
            return (
                <UpsellInfoModalSegmentedRichTextBlock
                    block={props.block.value}
                />
            );
        case "value_props":
            return <UpsellInfoModalValueProps block={props.block.value} />;
    }
    assertNever(props.block);
    return null;
};

export const UpsellInfoColumnProductInfo = (props: {
    product: IProduct;
    baseVariant: IProduct | null;
    selectedRoot: IProduct | null;
    bundleType: string;
    isOneProduct?: boolean | null;
}) => {
    const buildImage = (product: InternalProduct) => {
        const productImage = product.parent
            ? product.parent.images[0]
            : product.images[0];

        if (productImage) {
            return productImage.original;
        }

        return "";
    };

    const buildPrice = (product: InternalProduct) => {
        if (props.bundleType === BundleGroupTypes.IN_CONFIGURATOR_FREE_GIFT) {
            const price = getProductPrice(product.price, {
                priceType: PriceType.RETAIL,
                includePostDiscountAddons: true,
                quantity: 1,
            });
            return `(${format.money(price)} Value)`;
        }

        let basePrice = ZERO;
        if (
            props.baseVariant &&
            (props.bundleType === BundleGroupTypes.UPGRADE_PRODUCT ||
                props.bundleType === BundleGroupTypes.UPGRADE_MODEL)
        ) {
            basePrice = getProductPrice(props.baseVariant.price, {
                priceType: PriceType.COSMETIC_EXCL_TAX,
                includePostDiscountAddons: true,
                quantity: 1,
            });
        }
        const upgradePrice = getProductPrice(product.price, {
            priceType: PriceType.COSMETIC_EXCL_TAX,
            includePostDiscountAddons: true,
            quantity: 1,
        });
        const priceDiff = upgradePrice.subtract(basePrice);

        if (!props.isOneProduct) {
            if (
                priceDiff.isZero() ||
                priceDiff.equalsTo(basePrice.multiply(-1))
            ) {
                return "";
            } else if (priceDiff.isNegative()) {
                return format.money(priceDiff.multiply(-1));
            }
            return `+ ${format.money(priceDiff)}`;
        }
        return `+ ${format.money(priceDiff)}`;
    };

    const buildUpsellProductName = (product: InternalProduct) => {
        if (product.parent) {
            if (product.parent.attributes.upgrade_option_title) {
                return product.parent.attributes.upgrade_option_title.value;
            }
            return product.parent.title;
        }

        if (product.attributes.upgrade_option_title) {
            return product.attributes.upgrade_option_title.value;
        }

        return product.title;
    };

    const ratingClasses = classNames({
        [styles.rating]: true,
        [styles.freeGiftRating]:
            props.bundleType === BundleGroupTypes.IN_CONFIGURATOR_FREE_GIFT,
    });

    const productNameClasses = classNames({
        [styles.productName]: true,
        [styles.freeGiftProductName]:
            props.bundleType === BundleGroupTypes.IN_CONFIGURATOR_FREE_GIFT,
    });

    return (
        <>
            <Image
                className={styles.productImage}
                src={buildImage(props.product)}
                alt=""
            />
            <h2 className={productNameClasses}>
                {buildUpsellProductName(props.product)}
            </h2>
            {props.product.rating && props.product.rating > 0 && (
                <RatingGraphic
                    starRatingClass={ratingClasses}
                    cardClass="tile"
                    cardSize="large"
                    numReviews={props.product.num_reviews}
                    rating={props.product.rating}
                />
            )}
            {props.bundleType !==
                BundleGroupTypes.IN_CONFIGURATOR_FREE_GIFT && (
                <p className={styles.price}>{buildPrice(props.product)}</p>
            )}
        </>
    );
};

export const UpsellInfoColumnContent = (props: {
    isOneProduct: boolean;
    content: IModularConfigurator_UpsellInfoModalContent[];
}) => {
    return (
        <>
            {props.content.map((block) => (
                <UpsellInfoModalBlock
                    key={block.id}
                    isOneProduct={props.isOneProduct}
                    block={block}
                />
            ))}
        </>
    );
};

interface IColumnProps {
    column: IModularConfigurator_UpsellInfoModalProduct;
    isOneProduct: boolean;
    isFreeGift: boolean;
    bundleID: number;
    bundleType: string;
    baseVariant: IProduct | null;
    selectedRoot: IProduct | null;
    selectedAddons: IAddon[];
    selectedUpgrade: IProductID | null;
    upgradeBundles: IConcreteBundle[];
    updateSelectedAddons: (
        updates: Array<{ productID: IProductID; quantity: number }>,
    ) => void;
    setSelectedUpgrade: (selectedUpgrade: IProductID | null) => void;
}

interface IColumnState {
    product: IProduct | null;
    baseVariant: IProduct | null;
    parentProduct: IProduct | null;
    bundle: IConcreteBundle | null;
}

class UpsellInfoColumn extends React.PureComponent<IColumnProps, IColumnState> {
    public state: IColumnState = {
        product: null,
        baseVariant: null,
        parentProduct: null,
        bundle: null,
    };

    async componentDidMount() {
        await this.setProduct();
        this.setBaseVariant();
    }

    private async setProduct() {
        const product = await productsAPI.getProduct(this.props.column.product);

        const thisBundle = this.props.upgradeBundles.find((b) => {
            return b.bundle_group.id === this.props.bundleID;
        });

        if (thisBundle) {
            this.setState({
                bundle: thisBundle,
            });

            const variantProduct = thisBundle.suggested_products.find((p) => {
                return p.parent?.id === product.id;
            });

            if (variantProduct) {
                this.setState({
                    product: variantProduct,
                });
            } else {
                this.setState({
                    product: product,
                });
            }
        }
    }

    private setBaseVariant() {
        if (this.props.baseVariant) {
            this.setState({
                baseVariant: this.props.baseVariant,
            });
        }
    }

    private get analyticsClassName(): { [key: string]: string } {
        return {
            [BundleGroupTypes.IN_CONFIGURATOR_ADD_ON]:
                "al-add-on-card-panel-select-button",
            [BundleGroupTypes.UPGRADE_MODEL]:
                "al-change-model-upgrade-panel-select-button",
            [BundleGroupTypes.UPGRADE_PRODUCT]:
                "al-standard-upgrade-card-panel-select-button",
        };
    }

    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        // If this is an UPGRADE_PRODUCT or UPGRADE_MODEL Bundle, we update things one way...
        if (
            this.props.bundleType === BundleGroupTypes.UPGRADE_PRODUCT ||
            this.props.bundleType === BundleGroupTypes.UPGRADE_MODEL
        ) {
            if (this.state.product) {
                const upgradeID = getUpgradeID(this.state.product);
                this.props.setSelectedUpgrade(
                    e.currentTarget.checked ? upgradeID : null,
                );
            }
            // If it's another kind of Bundle, we update things a different way...
        } else {
            if (this.state.product) {
                const upgradeID = getUpgradeID(this.state.product);
                const updates = [
                    {
                        productID: upgradeID,
                        quantity: e.currentTarget.checked ? 1 : 0,
                    },
                ];
                if (this.state.bundle) {
                    for (const product of this.state.bundle
                        .suggested_products) {
                        const pID = getUpgradeID(product);
                        if (pID !== upgradeID) {
                            updates.push({
                                productID: pID,
                                quantity: 0,
                            });
                        }
                    }
                }
                this.props.updateSelectedAddons(updates);
            }
        }
    };

    private readonly buildUI = () => {
        const upgradeID = this.state.product
            ? getUpgradeID(this.state.product)
            : null;
        if (
            // if the currently selected Root product matches the upgradeID,
            // AND this isn't an UPGRADE_MODEL bundle,
            // then don't show any UI
            this.props.selectedRoot &&
            this.props.bundleType !== BundleGroupTypes.UPGRADE_MODEL &&
            this.props.selectedRoot.id === upgradeID
        ) {
            return false;
        }
        const isSelected = () => {
            // if this is an UPGRADE_MODEL bundle, handle the UI differently
            if (this.props.bundleType === BundleGroupTypes.UPGRADE_MODEL) {
                if (
                    this.props.selectedRoot &&
                    this.props.selectedRoot.id === upgradeID
                ) {
                    return true;
                } else {
                    return false;
                }
            }
            if (this.props.bundleType === BundleGroupTypes.UPGRADE_PRODUCT) {
                // if the currently selectedUpgrade matches the upgradeID
                if (this.props.selectedUpgrade === upgradeID) {
                    return true;
                } else {
                    return false;
                }
            } else {
                const selectedAddon = this.props.selectedAddons.find(
                    (addon) => {
                        return addon.productID === upgradeID;
                    },
                );
                return selectedAddon ? true : false;
            }
            return false;
        };
        const textLabel = isSelected() ? "Selected" : "Select";
        const icon = isSelected() ? (
            <SVG
                className={styles.selectedIcon}
                src={iconUpsellModalSelectedIcon}
            />
        ) : (
            <SVG
                className={styles.selectIcon}
                src={iconUpsellModalSelectIcon}
            />
        );
        const analyticsClassName =
            this.analyticsClassName[this.props.bundleType];
        const buttonClasses = classNames({
            [styles.selectButton]: true,
            [styles.selectedButton]: isSelected(),
            [analyticsClassName]: true,
        });

        return (
            <label className={buttonClasses} tabIndex={0} role="button">
                <input
                    type="checkbox"
                    checked={isSelected()}
                    onChange={this.onChange}
                />
                {icon}
                {textLabel}
            </label>
        );
    };

    render() {
        const columnClasses = classNames({
            [styles.column]: true,
            [styles.multipleColumns]: !this.props.isOneProduct,
        });
        const headerClasses = classNames({
            [styles.columnHeader]: true,
            [styles.headerOneProduct]:
                this.props.isOneProduct && !this.props.isFreeGift,
            [styles.centeredHeader]: this.props.isFreeGift,
        });
        return (
            <div className={columnClasses}>
                <header
                    className={headerClasses}
                    role="group"
                    aria-label="item"
                >
                    {this.props.column.flair &&
                        this.props.column.flair.length > 0 && (
                            <Flair
                                flair={this.props.column.flair[0].value}
                                inline={true}
                            />
                        )}
                    {this.state.product && (
                        <UpsellInfoColumnProductInfo
                            product={this.state.product}
                            baseVariant={this.state.baseVariant}
                            selectedRoot={this.props.selectedRoot}
                            bundleType={this.props.bundleType}
                            isOneProduct={this.props.isOneProduct}
                        />
                    )}
                    <div>
                        {this.state.product &&
                            this.props.bundleType !==
                                BundleGroupTypes.IN_CONFIGURATOR_FREE_GIFT &&
                            this.buildUI()}
                    </div>
                </header>
                <UpsellInfoColumnContent
                    isOneProduct={this.props.isOneProduct}
                    content={this.props.column.content}
                />
            </div>
        );
    }
}

interface IUpsellInfoModalContentProps {
    baseVariant: IProduct | null;
    selectedRoot: IProduct | null;
    modal: IModularConfiguratorUpsellInfoModal;
    selectedAddons: IAddon[];
    selectedUpgrade: IProductID | null;
    upgradeBundles: IConcreteBundle[];
    updateSelectedAddons: (
        updates: Array<{ productID: IProductID; quantity: number }>,
    ) => void;
    setSelectedUpgrade: (selectedUpgrade: IProductID | null) => void;
}
interface IUpsellInfoModalContentState {}

export class UpsellInfoModalContent extends React.PureComponent<
    IUpsellInfoModalContentProps,
    IUpsellInfoModalContentState
> {
    render() {
        return (
            <>
                <h1 className={styles.title}>
                    {this.props.modal.bundle_description}
                </h1>
                <div className={styles.container}>
                    {this.props.modal.upsell_info_products.map((column, i) => {
                        return (
                            <UpsellInfoColumn
                                key={`column-${i}`}
                                column={column}
                                isOneProduct={
                                    this.props.modal.upsell_info_products
                                        .length < 2
                                }
                                isFreeGift={
                                    this.props.modal.bundle_type ===
                                    BundleGroupTypes.IN_CONFIGURATOR_FREE_GIFT
                                }
                                bundleID={this.props.modal.bundle}
                                bundleType={this.props.modal.bundle_type}
                                baseVariant={this.props.baseVariant}
                                selectedRoot={this.props.selectedRoot}
                                selectedAddons={this.props.selectedAddons}
                                selectedUpgrade={this.props.selectedUpgrade}
                                upgradeBundles={this.props.upgradeBundles}
                                updateSelectedAddons={
                                    this.props.updateSelectedAddons
                                }
                                setSelectedUpgrade={
                                    this.props.setSelectedUpgrade
                                }
                            />
                        );
                    })}
                </div>
            </>
        );
    }
}
