// Enum of currently running tests.
// - The name should describe what's being tested
// - The value should be the related Plan.io ticket number
export const enum ABTest {
    WELLS_FARGO_CREDIT_APP = 14795,
    PREDICTED_DELIVERY_DATES = 17966,
}

export const enum ABTestVariant {
    A = "a",
    B = "b",
}

/**
 * For the given test, figure out which variant to show the current user.
 *
 * How does this work? Google Optimize manages the test—it indicates which
 * variant the current user should get by setting a data attribute on the <body>
 * tag for all users in test group B. Attribute format is:
 *
 *     data-exp-${TICKET_NUMBER}="b"
 *
 * Note that no data attribute will be present at all for the A group. Default
 * functionality should load when no testing attribute is found.
 *
 * This function reads the data attribute value and and returns the constant
 * representing the current test variant.
 */
export const getDesiredABTestVariant = (
    test: ABTest,
    defaultVariant = ABTestVariant.A,
): ABTestVariant => {
    const attrName = `exp-${test}`;
    const attrValue = document.body.dataset[attrName];
    if (attrValue === ABTestVariant.A) {
        return ABTestVariant.A;
    }
    if (attrValue === ABTestVariant.B) {
        return ABTestVariant.B;
    }
    return defaultVariant;
};
