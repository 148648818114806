import React from "react";
import classNames from "classnames";
import { IProduct } from "../models/catalogue.interfaces";
import { IAPIPrice, IPrice } from "../models/prices.interfaces";
import { IWebPageURL } from "../models/nominals";
import { ConfiguratorTypes } from "../constants";
import { DiscountDisplayMode } from "../apps/configurator/constants";
import { ErrorBoundary } from "./ErrorBoundary";
import { getTotalPriceWithAddons } from "../utils/money";
import { ConfiguratorProductPrice } from "./ConfiguratorProductPrice";
import { ConfiguratorFinancing } from "./ConfiguratorFinancing";
import { FinancingPlans } from "../models/financing";

interface IProps {
    price: IAPIPrice;
    financingPlans?: FinancingPlans;
    variant: IProduct;
    selectedAddonPrice: IPrice | null;
    configuratorType?: ConfiguratorTypes;
    financingLink?: IWebPageURL | null;
    strikeThroughMSRP: boolean;
    discountDisplayMode: DiscountDisplayMode;
    showFinancingModal?: boolean;
    actualPriceStyle: string;
    overrideFinancingCopy?: string;
    extraClasses?: string;
    supDollarSign?: boolean;
    alternatePriceView?: boolean;
    promoComponents?: JSX.Element | null;
    percentageDiscountFormat?: string;
    absoluteDiscountFormat?: string;
    showStartingAt?: boolean;
    addPriceText?: boolean;
}

interface IState {}

export class ConfiguratorPrice extends React.PureComponent<IProps, IState> {
    private readonly buildStartingAtText = () => {
        if (this.props.showStartingAt) {
            if (this.props.variant.attributes.option_size) {
                return (
                    <span className="configurator__price--starting-at">
                        {this.props.variant.attributes.option_size.value}
                        {this.props.addPriceText && " Price"} starting at
                    </span>
                );
            }

            return <span>Starting at</span>;
        }
        return null;
    };

    render() {
        const totalPriceWithAddons: IPrice = getTotalPriceWithAddons(
            this.props.price.total,
            this.props.selectedAddonPrice,
        );
        const priceClasses = classNames({
            "configurator__price": true,
            [`configurator__price--${this.props.configuratorType}`]: true,
            "configurator__price--alternate": this.props.alternatePriceView,
            "special-financing":
                (!totalPriceWithAddons.per_month ||
                    !totalPriceWithAddons.per_month_term_length) &&
                this.props.showFinancingModal,
        });
        return (
            <div className={priceClasses}>
                {this.buildStartingAtText()}
                <ConfiguratorProductPrice
                    configuratorType={this.props.configuratorType}
                    strikeThroughMSRP={this.props.strikeThroughMSRP}
                    discountDisplayMode={this.props.discountDisplayMode}
                    percentageDiscountFormat={
                        this.props.percentageDiscountFormat
                    }
                    absoluteDiscountFormat={this.props.absoluteDiscountFormat}
                    actualPriceStyle={this.props.actualPriceStyle}
                    price={totalPriceWithAddons}
                    supDollarSign={this.props.supDollarSign}
                    alternatePriceView={this.props.alternatePriceView}
                />
                <ConfiguratorFinancing
                    price={totalPriceWithAddons}
                    financingPlans={this.props.financingPlans}
                    variant={this.props.variant}
                    financingLink={this.props.financingLink}
                    configuratorType={this.props.configuratorType}
                    showFinancingModal={this.props.showFinancingModal}
                    overrideFinancingCopy={this.props.overrideFinancingCopy}
                    extraClasses={this.props.extraClasses}
                    alternatePriceView={this.props.alternatePriceView}
                />
                <ErrorBoundary>{this.props.promoComponents}</ErrorBoundary>
            </div>
        );
    }
}
