import * as t from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { chain } from "fp-ts/lib/Either";
import {
    Latitude,
    LatitudeFromString,
    Longitude,
    LongitudeFromString,
    IMeters,
    Meters,
    isoMeters,
    RetailLocatorStoreID,
    SyncStoreID,
    WebPageURL,
} from "./nominals";
import { NullString, NullBooleanFromString, nullable } from "./utils";

type RetailStoreDistanceC = t.Type<IMeters, number | null, unknown>;

const RetailStoreDistance: RetailStoreDistanceC = new t.Type<
    IMeters,
    number | null,
    unknown
>(
    "RetailStoreDistance",
    Meters.is,
    (u, c) => {
        return pipe(
            nullable(t.number).validate(u, c),
            chain((nn) => {
                if (nn == null) {
                    return t.success(isoMeters.wrap(Infinity));
                }
                return t.success(isoMeters.wrap(nn));
            }),
        );
    },
    Number,
);

export const LocationGuessResponse = t.partial({
    as: NullString,
    city: NullString,
    country: NullString,
    countryCode: NullString,
    isp: NullString,
    lat: nullable(Latitude),
    lon: nullable(Longitude),
    org: NullString,
    query: NullString,
    region: NullString,
    regionName: NullString,
    status: NullString,
    timezone: NullString,
    zip: NullString,
});

const RetailStoreData = t.record(
    t.string,
    t.union([t.string, t.array(t.string)]),
);

export const RetailStoreBusinessHourPeriod = t.interface({
    opens: t.string,
    closes: t.string,
});

export const RetailStoreHolidayClosed = t.interface({
    date: t.string,
    closed: t.boolean,
});

export const RetailStoreHolidayWorking = t.interface({
    date: t.string,
    start_time: t.string,
    end_time: t.string,
});

export const RetailStoreHolidayHours = t.union([
    RetailStoreHolidayClosed,
    RetailStoreHolidayWorking,
]);

export const RetailStore = t.intersection([
    t.interface({
        id: nullable(RetailLocatorStoreID),
        url: t.string,
        name: t.string,
        name_slug: t.string,
        lat: LatitudeFromString,
        lng: LongitudeFromString,
        address: t.string,
        address2: t.string,
        city: t.string,
        city_slug: t.string,
        state: t.string,
        country: t.string,
        postal: t.string,
        external_id: SyncStoreID,
        data: RetailStoreData,
        category: t.string,
        featured: NullBooleanFromString,
        elite: NullBooleanFromString,
        phone: t.string,
        inventory: t.array(t.string),
        web: WebPageURL,
    }),
    t.partial({
        web_sealy: WebPageURL,
        web_stearns: WebPageURL,
        upcoming_hours: t.array(RetailStoreBusinessHourPeriod),
        hours_summary: t.string,
        num_reviews: t.number,
        average_review_rating: nullable(t.number),
        storefront: t.union([
            t.undefined,
            nullable(
                t.interface({
                    id: t.number,
                    meta: t.interface({
                        type: t.string,
                        detail_url: t.string,
                        download_url: t.string,
                    }),
                    title: t.string,
                }),
            ),
        ]),
        storefront_thumbnail: t.union([
            t.undefined,
            nullable(
                t.interface({
                    url: t.string,
                    width: t.number,
                    height: t.number,
                }),
            ),
        ]),
        holiday_hours: t.array(RetailStoreHolidayHours),
    }),
]);

export const RetailStoreWithDistance = t.intersection([
    RetailStore,
    t.type({
        // Distance in Meters
        distance: RetailStoreDistance,
    }),
]);

export const RetailStoresWithDistance = t.array(RetailStoreWithDistance);
