import { IProductID } from "../models/nominals";
import {
    ModularConfiguratorPromoCards,
    ModularConfiguratorUpsellInfoModals,
} from "../apps/configurator/models";
import {
    IModularConfiguratorPromoCard,
    IModularConfiguratorUpsellInfoModal,
} from "../apps/configurator/models.interfaces";
import { check } from "../models/utils";
import { ajax } from "../utils/ajax";
import { PromiseMutex } from "../utils/mutex";

/**
 * Return the UpsellInfoModal content for any bundles that are associated
 * with the given product ID.
 */
export const getUpsellInfoModalContent = async (
    triggeringProductID: IProductID,
) => {
    const mutex = new PromiseMutex<IModularConfiguratorUpsellInfoModal[]>(
        `product-upsell-info-modals-get-${triggeringProductID}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/products/${triggeringProductID}/upsell-info-modals/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(
                    ModularConfiguratorUpsellInfoModals.decode(resp.body),
                );
            });
        mutex.setPromise(loading);
    }
    return loading;
};

/**
 * Return the UpsellInfoModal content for any bundles that are associated
 * with the given product ID.
 */
export const getPromoCards = async (triggeringProductID: IProductID) => {
    const mutex = new PromiseMutex<IModularConfiguratorPromoCard[]>(
        `product-promo-cards-get-${triggeringProductID}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/products/${triggeringProductID}/promo-cards/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(ModularConfiguratorPromoCards.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};
