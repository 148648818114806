import { onDOMContentLoaded } from "tsi-common-react/src/utils/events";

const changeTab = (tab: HTMLElement, tabListElem: HTMLElement | null) => {
    const tabList = tabListElem ? tabListElem : (tab.parentNode as HTMLElement);
    const tabBlock = tabList ? tabList.parentNode : null;

    // capture the edges to detect when they're cut off by the viewport
    const tabRightEdge = tab.getBoundingClientRect().right;
    const tabLeftEdge = tab.getBoundingClientRect().left;

    // if tab is cut off on the right
    if (tabRightEdge > window.innerWidth) {
        const moveAmount =
            tabRightEdge - window.innerWidth + 24 + tabList.scrollLeft;
        tabList.scrollLeft = moveAmount;
    }

    // if tab is cut off on the left
    if (tabLeftEdge < 0) {
        const moveAmount = tabList.scrollLeft + tabLeftEdge - 24;
        tabList.scrollLeft = moveAmount;
    }

    if (tabList) {
        tabList.querySelectorAll('[aria-selected="true"]').forEach((t) => {
            t.setAttribute("aria-selected", "false");
        });
    }

    tab.setAttribute("aria-selected", "true");

    if (tabBlock) {
        tabBlock
            .querySelectorAll<HTMLElement>('[role="tabpanel"]')
            .forEach((panel) => {
                panel.setAttribute("hidden", "true");
            });

        const tabPanel = tabBlock.querySelector<HTMLElement>(
            `#${tab.getAttribute("aria-controls")}`,
        );
        if (tabPanel) {
            tabPanel.removeAttribute("hidden");
        }
    }
};

const activateTabs = (selector: string, tabListClass?: string) => {
    Array.from(document.querySelectorAll<HTMLElement>(selector)).forEach(
        (elem) => {
            const tabs = elem.querySelectorAll<HTMLElement>('[role="tab"]');
            const tabList = tabListClass
                ? elem.querySelector<HTMLElement>(tabListClass)
                : null;

            let tabFocus = 0;
            tabs.forEach((tab) => {
                // enable clickable tabs
                tab.addEventListener("click", (e) => {
                    const target = e.target as HTMLElement;
                    if (target.tagName === "button") {
                        changeTab(target, tabList);
                    } else {
                        changeTab(
                            target.closest("button") as HTMLElement,
                            tabList,
                        );
                    }
                });
                // enable keyboard-navigable tabs
                tab.addEventListener("keydown", (e) => {
                    if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
                        tabs[tabFocus].setAttribute("tabindex", "-1");
                        if (e.key === "ArrowRight") {
                            tabFocus++;
                            // If we're at the end, go to the start
                            if (tabFocus >= tabs.length) {
                                tabFocus = 0;
                            }
                            // Move left
                        } else if (e.key === "ArrowLeft") {
                            tabFocus--;
                            // If we're at the start, move to the end
                            if (tabFocus < 0) {
                                tabFocus = tabs.length - 1;
                            }
                        }

                        tabs[tabFocus].setAttribute("tabindex", "0");
                        tabs[tabFocus].focus();
                        changeTab(tabs[tabFocus], tabList);
                    }
                });
            });

            // enable 'anchor' linking to specific tabs
            if (window.location.hash) {
                const urlTab = document.querySelector<HTMLElement>(
                    window.location.hash,
                );
                if (urlTab) {
                    changeTab(urlTab, tabList);
                }
            }
        },
    );
};

onDOMContentLoaded.on(() => {
    activateTabs(".tabs-block");
    activateTabs(".featured-products-tab-block");
    activateTabs(".tabs");
    activateTabs(".tab-feature-block", ".tab-feature-block__tablist");
});
