import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { IBasket } from "../../../models/catalogue.interfaces";
import { IBasketLineAPIURL } from "../../../models/nominals";
import { LoadingSpinner } from "../../../common/LoadingSpinner";
import { Step, SHIPPING_METHOD_NAMES } from "../constants";
import { IReduxFormState } from "../reducers.interfaces";
import {
    ILineOptionGroupingData,
    getLineOptionGroupingData,
} from "../selectors";
import { BasketLineMinimal } from "../components/BasketLineMinimal";
import { ExpeditedShippingDisclosure } from "../components/ExpeditedShippingDisclosure";

interface IOwnProps {
    onEdit?: (event?: React.MouseEvent<HTMLElement>) => void;
}

interface IReduxProps {
    form: IReduxFormState;
    basket: IBasket | null;
    groups: ILineOptionGroupingData[];
    showSummary: boolean;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class ShippingMethodSummaryComponent extends React.Component<IProps, IState> {
    private buildLine(lineURL: IBasketLineAPIURL) {
        if (!this.props.basket) {
            return null;
        }
        const line = this.props.basket.lines.find((l) => {
            return l.url === lineURL;
        });
        if (!line || !line.quantity) {
            return null;
        }
        return <BasketLineMinimal key={`${lineURL}`} line={line} />;
    }

    private buildSummary() {
        if (!this.props.showSummary) {
            return null;
        }
        return this.props.groups.map((group, i) => {
            const method =
                group.methods.filter((m) => {
                    return this.props.form.shipping_method === m.code;
                })[0] || group.methods[0];

            const lines = group.lines.map(this.buildLine.bind(this));
            return (
                <div key={i} className="checkout-step__shipping-group-summary">
                    <h3 className="checkout-step__label">
                        {SHIPPING_METHOD_NAMES[method.name] || method.name}
                    </h3>
                    <p>{method.description}</p>
                    <ul>{lines}</ul>
                </div>
            );
        });
    }

    render() {
        const summaryClasses = classNames({
            "checkout-step__summary": true,
            "checkout-step__summary--collapsed": !this.props.showSummary,
        });
        if (this.props.groups.length <= 0) {
            return (
                <div className={summaryClasses}>
                    <LoadingSpinner />
                </div>
            );
        }
        return (
            <div className={summaryClasses}>
                {this.buildSummary()}
                <ExpeditedShippingDisclosure groups={this.props.groups} />
                {this.props.onEdit && (
                    <p>
                        <a
                            className="checkout-step__back"
                            href="#"
                            onClick={this.props.onEdit}
                        >
                            {t`Edit`}
                        </a>
                    </p>
                )}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        form: state.form,
        basket: state.data.basket,
        groups: getLineOptionGroupingData(state),
        showSummary: state.form.current_step > Step.SHIPPING_METHOD,
        ...ownProps,
    };
};

export const ShippingMethodSummary = connect(mapStateToProps)(
    ShippingMethodSummaryComponent,
);
