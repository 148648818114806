import { check } from "../models/utils";
import { ajax, CSRF_HEADER, getCSRFToken } from "../utils/ajax";
import { IProductAPIURL } from "../models/nominals";
import { IWishListLine } from "../models/catalogue.interfaces";
import { WishListLines } from "../models/catalogue";
import { PromiseMutex } from "../utils/mutex";

export const listLines = async (wishListCode: string) => {
    const mutex = new PromiseMutex<IWishListLine[]>(
        `wishlist-products-list-${wishListCode}`,
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get(`/api/wishlist-types/${wishListCode}/lines/`)
            .set("Accept", "application/json")
            .then((resp) => {
                return check(WishListLines.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const addLine = async (
    wishListCode: string,
    lineURL: IProductAPIURL,
) => {
    const data = {
        list_type: `${window.location.origin}/api/wishlist-types/${wishListCode}/`,
        product: lineURL,
    };
    return ajax
        .post(`/api/wishlist-types/${wishListCode}/lines/`)
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data)
        .then((resp) => {
            return resp;
        });
};

export const removeLine = async (lineURL: string) => {
    return ajax
        .del(lineURL)
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .then(() => {
            return true;
        });
};
