import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import { OrderAPIURL, OrderNumber } from "./nominals";
import { BillingAddress, ShippingAddress } from "./address";

const OrderCancellationReason = t.interface({
    reason: t.string,
});

export const OrderCancellationInfo = t.interface({
    number: t.string,
    status: t.string,
    date_placed: DateFromISOString,
    can_be_cancelled: t.boolean,
    cancellation_window_ends_on: DateFromISOString,
    cancellation_reasons: t.array(OrderCancellationReason),
});

export interface OrderCancellationInfo
    extends t.TypeOf<typeof OrderCancellationInfo> {}

export const OrderDiscount = t.interface({
    description: t.string,
    name: t.string,
    amount: t.string,
});

export const Order = t.interface({
    url: OrderAPIURL,
    number: OrderNumber,
    basket: t.string,
    lines: t.string,
    owner: t.string,
    billing_address: BillingAddress,
    currency: t.string,
    total_incl_tax: t.string,
    total_excl_tax: t.string,
    shipping_incl_tax: t.string,
    shipping_excl_tax: t.string,
    shipping_address: ShippingAddress,
    shipping_method: t.string,
    shipping_code: t.string,
    status: t.string,
    email: t.string,
    date_placed: t.string,
    payment_url: t.string,
    offer_discounts: t.array(OrderDiscount),
    voucher_discounts: t.array(t.string), // TODO: spec
    surcharges: t.array(t.string), // TODO: spec
});

export interface Order extends t.TypeOf<typeof Order> {}

export const OrderList = t.array(Order);
export type OrderList = t.TypeOf<typeof OrderList>;
