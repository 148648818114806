import React from "react";
import { connect } from "react-redux";
import { IAddressCountry } from "../../../models/address.interfaces";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { IAddress } from "../models.interfaces";
import { Actions } from "../actions";
import { FormView } from "./abstract/FormView";

interface IOwnProps {
    address: Partial<IAddress>;
}

interface IReduxProps {
    countries: IAddressCountry[];
}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

const CustomerDetailsAddressComponent = (props: IProps) => {
    return (
        <FormView
            url={props.address.url}
            baseURL={"/api/csr/user-addresses/"}
            actions={props.actions}
            fields={[
                {
                    name: "user",
                    type: "hidden",
                },
                {
                    label: "Title",
                    name: "title",
                    type: "select",
                    options: [
                        ["", ""],
                        ["Mr", "Mr"],
                        ["Miss", "Miss"],
                        ["Mrs", "Mrs"],
                        ["Ms", "Ms"],
                        ["Dr", "Dr"],
                    ],
                },
                {
                    label: "First Name",
                    name: "first_name",
                    type: "text",
                },
                {
                    label: "Last Name",
                    name: "last_name",
                    type: "text",
                },
                {
                    label: "Line 1",
                    name: "line1",
                    isRequired: true,
                    type: "text",
                },
                {
                    label: "Line 2",
                    name: "line2",
                    type: "text",
                },
                {
                    label: "City",
                    name: "line4",
                    type: "text",
                },
                {
                    label: "State/Province",
                    name: "state",
                    type: "text",
                },
                {
                    label: "ZIP Code",
                    name: "postcode",
                    type: "text",
                },
                {
                    label: "Country",
                    name: "country",
                    type: "select",
                    options: props.countries.map((c): [string, string] => {
                        return [c.url, c.printable_name];
                    }),
                },
                {
                    label: "Is Default Shipping Address",
                    name: "is_default_for_shipping",
                    type: "boolean",
                },
                {
                    label: "Is Default Billing Address",
                    name: "is_default_for_billing",
                    type: "boolean",
                },
            ]}
            initialValues={props.address}
        />
    );
};

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        countries: rootState.csr.countries,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const CustomerDetailsAddress = connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomerDetailsAddressComponent);
