import React from "react";
import ValueProp from "./ValueProp";
import { IValueProp } from "../models/value-props.interfaces";
import styles from "./ValuePropsBar.module.scss";
import classNames from "classnames";

interface IProps {
    valueProps: IValueProp[];
    className?: string;
}

interface IState {}

export class ValuePropsBar extends React.Component<IProps, IState> {
    render() {
        const containerClasses = classNames({
            [styles.container]: true,
            [this.props.className || ""]: true,
        });
        const rootClasses = classNames({
            [styles.root]: true,
            [this.props.className || ""]: true,
        });
        return (
            <div className={containerClasses}>
                <ul className={rootClasses}>
                    {this.props.valueProps.map((valueProp, i) => (
                        <ValueProp
                            key={i}
                            prop={valueProp}
                            className={this.props.className || ""}
                        />
                    ))}
                </ul>
            </div>
        );
    }
}
