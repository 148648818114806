import React from "react";
import { IBasketLine } from "../../../models/catalogue.interfaces";
import {
    BasketLineImage,
    BasketLineTitle,
    BasketLineQuantity,
    BasketLineOptions,
} from "./BasketLineBase";

interface IProps {
    line: IBasketLine;
}

interface IState {}

export class BasketLineMinimal extends React.Component<IProps, IState> {
    render() {
        return (
            <li>
                <div
                    className="basket-line basket-line--minimal"
                    role="group"
                    aria-label="product"
                >
                    <div className="basket-line__graphic">
                        <BasketLineImage product={this.props.line.product} />
                    </div>
                    <div className="basket-line__details">
                        <div className="basket-line__details__row">
                            <BasketLineTitle
                                product={this.props.line.product}
                            />
                            <BasketLineQuantity
                                quantity={this.props.line.quantity}
                            />
                        </div>
                        <div className="basket-line__details__row">
                            <BasketLineOptions
                                product={this.props.line.product}
                            />
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}
