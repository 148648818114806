import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { urls } from "../../../utils/urls";
import { IWebPageURL } from "../../../models/nominals";
import * as signals from "../../signals";
import { Actions } from "../actions";
import { CustomerSearch } from "./CustomerSearch";
import { BasketSearch } from "./BasketSearch";
import { CustomerCreate } from "./CustomerCreate";
import { CustomerDetails } from "./CustomerDetails";
import { Vouchers } from "./Vouchers";
import { IReduxState } from "../reducers.interfaces";

Modal.defaultStyles = {
    overlay: {},
    content: {},
};

// Load Toolbar styles
import "../../../../src/apps/csr/components/Toolbar.scss";

interface IOwnProps {
    dashboard: IWebPageURL;
    basket: IWebPageURL;
}

interface IReduxProps {
    assisting: IReduxState["assisting"];
    views: IReduxState["views"];
}

interface IDispatchProps {
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    activePanel: number | null;
}

class ToolbarComponent extends React.Component<IProps, IState> {
    state = {
        activePanel: null,
    };

    private readonly panels = [
        {
            linkName: "Customers",
            component: CustomerSearch,
        },
        {
            linkName: "New Customer",
            component: CustomerCreate,
        },
        {
            linkName: "Baskets",
            component: BasketSearch,
        },
        {
            linkName: "Vouchers",
            component: Vouchers,
        },
    ];

    private readonly onOpenModal = (
        panelIndex: number,
        event?: React.MouseEvent<HTMLElement>,
    ) => {
        if (event) {
            event.preventDefault();
        }
        const def = this.panels[panelIndex];
        const Panel = def.component;
        const component = <Panel />;
        this.props.actions.setViewStackRoot(def.linkName, component);
        this.setState({
            activePanel: panelIndex,
        });
    };

    private readonly onCloseModal = (
        event:
            | React.MouseEvent<Element>
            | React.KeyboardEvent<Element>
            | MouseEvent
            | KeyboardEvent,
    ) => {
        if (event) {
            event.preventDefault();
        }
        this.props.actions.clearViewStack();
        this.setState({
            activePanel: null,
        });
    };

    private readonly onGotoDashboard = () => {
        urls.navigateToURL(this.props.dashboard);
    };

    private readonly onGotoBasket = () => {
        urls.navigateToURL(this.props.basket);
    };

    private readonly onAfterStartNewBasket = () => {
        window.location.reload();
    };

    componentDidMount() {
        // Attach event handlers
        signals.csr.onAfterStartNewBasket.on(this.onAfterStartNewBasket);
        // Load initial data
        this.props.actions.loadCountries();
        this.props.actions.loadCurrentlyAssistedCustomer();
        this.props.actions.loadCurrentlyAssistedBasket();
    }

    componentWillUnmount() {
        signals.csr.onAfterStartNewBasket.off(this.onAfterStartNewBasket);
    }

    private renderCurrentlyAssistedUser() {
        if (!this.props.assisting.customer) {
            return null;
        }
        const customer = this.props.assisting.customer;
        let name = "Anonymous";
        if (customer.email) {
            name = customer.email;
        }
        if (customer.first_name && customer.last_name) {
            name = `${customer.first_name} ${customer.last_name}`;
        }
        const openDetails = (event: React.MouseEvent<HTMLElement>) => {
            event.preventDefault();
            const component = <CustomerDetails url={customer.url} />;
            this.props.actions.setViewStackRoot("Customer Details", component);
        };
        return (
            <li
                key="assisting-user"
                className="csr-toolbar__item"
                onClick={openDetails}
            >
                Currently Assisting: <em>{name}</em>
            </li>
        );
    }

    private renderCurrentlyAssistedBasket() {
        if (!this.props.assisting.basket) {
            return null;
        }
        const basket = this.props.assisting.basket;
        return (
            <li
                key="assisting-basket"
                className="csr-toolbar__item"
                onClick={this.onGotoBasket}
            >
                Basket: {basket.encoded_basket_id}
            </li>
        );
    }

    private renderPanelLinks() {
        const elems = this.panels.map((def, index) => {
            const classes = classNames({
                "csr-toolbar__item": true,
                "csr-toolbar__item--active": this.state.activePanel === index,
            });
            return (
                <li
                    key={index}
                    className={classes}
                    onClick={this.onOpenModal.bind(this, index)}
                >
                    {def.linkName}
                </li>
            );
        });
        const currentlyAssistedUser = this.renderCurrentlyAssistedUser();
        if (currentlyAssistedUser) {
            elems.push(currentlyAssistedUser);
        }
        const currentlyAssistedBasket = this.renderCurrentlyAssistedBasket();
        if (currentlyAssistedBasket) {
            elems.push(currentlyAssistedBasket);
        }
        return elems;
    }

    private renderActivePanel() {
        const stack = this.props.views.stack;
        const activeIndex = stack.length - 1;
        if (activeIndex <= -1) {
            return null;
        }
        const breadcrumbs = stack
            .map((v) => {
                return v.name;
            })
            .join(" > ");
        const view = stack[activeIndex];
        const content = React.cloneElement(view.component, this.props);
        return (
            <div className="csr-modal">
                <div className="csr-modal__back">
                    <button
                        disabled={activeIndex === 0}
                        onClick={() => {
                            this.props.actions.popViewStack();
                        }}
                    >
                        Back
                    </button>
                    <span>{breadcrumbs}</span>
                </div>
                <div className="csr-modal__close">
                    <button onClick={this.onCloseModal}>Close</button>
                </div>
                <div className="csr-modal__title">
                    <h1>{view.name}</h1>
                </div>
                {content}
            </div>
        );
    }

    render() {
        return (
            <div className="csr-toolbar sticky">
                <div className="csr-toolbar__inner">
                    <ul className="csr-toolbar__items">
                        <li className="csr-toolbar__item csr-toolbar__item--title">
                            CSR Tools
                        </li>
                        <li
                            className="csr-toolbar__item"
                            onClick={this.onGotoDashboard}
                        >
                            Dashboard
                        </li>
                        {this.renderPanelLinks()}
                    </ul>
                </div>
                <Modal
                    isOpen={this.props.views.stack.length > 0}
                    onRequestClose={this.onCloseModal}
                    contentLabel="CSR Toolbar"
                    style={{
                        overlay: {
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.75)",
                            zIndex: 9010,
                        },
                        content: {
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            right: "auto",
                            bottom: "auto",
                            marginRight: "-50%",
                            transform: "translate(-50%, -50%)",
                            border: "1px solid #CCC",
                            background: "#FFF",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            borderRadius: "4px",
                            outline: "none",
                            padding: "0",
                            minWidth: "600px",
                            maxWidth: "1200px",
                            width: "90%",
                            height: "90%",
                        },
                    }}
                >
                    {this.renderActivePanel()}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"csr", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        assisting: rootState.csr.assisting,
        views: rootState.csr.views,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const actions = new Actions(dispatch);
    return {
        actions: actions,
    };
};

export const Toolbar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ToolbarComponent);
