/* eslint-disable complexity */
import React from "react";
import { createRoot } from "react-dom/client";
import { v4 as uuidv4 } from "uuid";
import {
    IBaseField,
    IField,
    IChoiceField,
} from "../models/formFields.interfaces";

interface IError {
    code: string;
    message: string;
}

/**
 * Mount Form Fields
 *
 * Example HTML:
 *
 *     <div class="form-field-placeholder"
 *          data-id="id_email"
 *          data-name="email"
 *          data-label="Email address"
 *          data-type="email"
 *          data-value="foo@foo"
 *          data-required="true"
 *          data-max-length="254"
 *          data-min-length="0"
 *          data-errors="[{&quot;message&quot;: &quot;Enter a valid email address.&quot;, &quot;code&quot;: &quot;invalid&quot;}]"
 *          data-help-text="">
 *      </div>
 */
export const initFormFields = async (container: HTMLElement) => {
    const id = container.dataset.id;
    const name = container.dataset.name || id || uuidv4();
    const label = container.dataset.label;
    const _type =
        container.dataset.type === "select" ? "choice" : container.dataset.type;
    const type = (_type || "text") as IField<string>["type"];
    const value = container.dataset.value || "";
    const required: boolean = JSON.parse(container.dataset.required || "false");
    const placeholder = container.dataset.placeholder;
    const maxLength = container.dataset.maxLength
        ? parseInt(container.dataset.maxLength, 10)
        : undefined;
    const minLength = container.dataset.minLength
        ? parseInt(container.dataset.minLength, 10)
        : undefined;
    const errors: IError[] = JSON.parse(container.dataset.errors || "[]");
    const choices: Array<[string, string]> = JSON.parse(
        container.dataset.choices || "[]",
    );
    const helpText = container.dataset.helpText || "";

    const props: IBaseField<HTMLInputElement, string> &
        IBaseField<HTMLTextAreaElement, string> &
        IChoiceField<string> = {
        id: id,
        name: name,
        label: label,
        labelPlacement: "outside",
        defaultValue: value,
        validation: required ? ["required"] : undefined,
        placeholder: placeholder,
        errors: errors.map((e) => {
            return e.message;
        }),
        choices: choices.map(([v, l]) => {
            return {
                value: v,
                label: l,
            };
        }),
        helpText: helpText,
    };

    let component: JSX.Element;
    switch (type) {
        case "boolean":
        case "checkbox":
        case "radio":
            const { FormCheckbox } = await import("../forms/FormCheckbox");
            component = <FormCheckbox {...props} type={type} />;
            break;

        case "integer":
        case "number":
            const { FormNumber } = await import("../forms/FormNumber");
            component = <FormNumber {...props} type={type} />;
            break;

        case "string":
        case "text":
        case "tel":
        case "date":
        case "datetime":
        case "email":
            const { FormInput } = await import("../forms/FormInput");
            component = (
                <FormInput
                    {...props}
                    type={type}
                    maxLength={maxLength}
                    minLength={minLength}
                    autoComplete={type}
                />
            );
            break;

        case "password":
            const { FormPasswordInput } = await import(
                "../forms/FormPasswordInput"
            );
            component = (
                <FormPasswordInput
                    {...props}
                    type={type}
                    maxLength={maxLength}
                    minLength={minLength}
                />
            );
            break;

        case "textbox":
            const { FormTextbox } = await import("../forms/FormTextbox");
            component = (
                <FormTextbox
                    {...props}
                    type={type}
                    maxLength={maxLength}
                    minLength={minLength}
                />
            );
            break;

        case "phone":
            const { FormPhoneNumber } = await import(
                "../forms/FormPhoneNumber"
            );
            component = <FormPhoneNumber {...props} type={type} />;
            break;

        case "choice":
            const { FormSelect } = await import("../forms/FormSelect");
            component = <FormSelect {...props} type="choice" />;
            break;

        default:
            console.error(
                `Can not render form field with unknown type: ${type}`,
                container,
            );
            component = <></>;
            break;
    }

    const root = createRoot(container);
    root.render(component);
};
