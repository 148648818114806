import { STR_NO, STR_YES } from "../constants";
import {
    IProduct,
    IOptionCode,
    IOptionValue,
    IOptionSingleValue,
} from "../models/catalogue.interfaces";
import { notEmpty, unique } from "./functional";
import {
    getOptionValueHash,
    asMultiValueOption,
    PriceType,
    getProductPrice,
} from "./catalogue";

/**
 * Master option sort orders
 *
 * Used for manually controlling sort order of values that don't follow any other rules
 */
type TMasterSortList = { [attr: string]: string[] | undefined };
const _sorting: TMasterSortList = {
    feel: [
        "Extra-Soft",
        "extra-soft",
        "SOFTER",
        "Soft",
        "soft",
        "Medium-Soft",
        "medium-soft",
        "Medium",
        "medium",
        "Medium-Firm",
        "medium-firm",
        "Firm",
        "firm",
        "Extra-Firm",
        "extra-firm",
    ],
    option_size: [
        "X Soft",
        "Soft",
        "Low",
        "Small",
        "Small 24 x 12",
        "Medium",
        "Medium 20 x 20",
        "High",
        "Large",
        "Large 26 x 26",
        "Standard",
        "One Size",
        "Twin",
        "Twin 13 inch Pocket",
        "Twin Long",
        "Twin XL",
        "Twin Extra Long",
        "Twin Long 13 inch Pocket",
        "Double",
        "Double 13 inch Pocket",
        "Full",
        "Queen",
        "Queen (split 2 pieces)",
        "Queen 13 inch Pocket",
        "Queen 16 inch Pocket",
        "Queen Medium",
        "Queen High",
        "Half Split Queen",
        "Split Queen",
        "King",
        "King (split 2 pieces)",
        "King 13 inch Pocket",
        "King 16 inch Pocket",
        "King Medium",
        "Split King",
        "Split King 13 inch Pocket",
        "Split King 16 inch Pocket",
        "CA King",
        "Cal King",
        "California King",
        "CA King (split 2 pieces)",
        "CA King 13 inch Pocket",
        "CA King 16 inch Pocket",
        "Half Split CA King",
        "Split CA King",
        "Split CA King 13 inch Pocket",
        "Split CA King 16 inch Pocket",
    ],
    option_feel: [
        "Soft",
        "Medium-Soft",
        "Medium",
        "Medium Hybrid",
        "Hybrid",
        "Firm",
        "Extra-Firm",
        "Ultra Firm",
        "Cushion Firm",
        "Plush",
        "Ultra Plush",
        "PT Firm",
        "PT Plush",
        "PT Ultra Plush",
    ],
    option_firmness_cocoon: ["Medium-Soft", "Extra Firm", "Extra-Firm"],
    option_advanced_pressure_relief: [STR_NO, STR_YES],
    option_advanced_support: [STR_NO, STR_YES],
    option_chill: [STR_NO, STR_YES],
    option_top: ["Tight Top", "Euro Top", "Pillow Top"],
    option_height: ["Lo", "Mid", "Hi"],
    filter_size: ["Standard/Queen", "King", "Specialty"],
    configuration: [
        "Mattress Only",
        "Mattress + Ease Power Base",
        "Mattress + Premier Power Base",
    ],
    option_level: ["Level 1", "Level 3", "Level 5"],
};

/**
 * Pre-Selection Master Sort List
 *
 * Manual sort list used to determine which variant of a product to preselect (different from the
 * normal master sort list, which is for dropdown option ordering).
 */
const _preselectSorting: TMasterSortList = {
    ..._sorting,
    feel: [...(_sorting.feel || [])],
    option_size: [
        "Queen",
        "Queen (split 2 pieces)",
        "Queen 13 inch Pocket",
        "Queen 16 inch Pocket",
        "Queen Medium",
        "Queen High",
        "Half Split Queen",
        "Split Queen",
        ...(_sorting.option_size || []),
    ],
    option_feel: [...(_sorting.option_feel || [])],
    option_firmness_cocoon: [...(_sorting.option_firmness_cocoon || [])],
};

export const enum SortType {
    DISPLAY,
    PRESELECT,
}

export const getMasterSortList = (sortType: SortType): TMasterSortList => {
    switch (sortType) {
        case SortType.PRESELECT:
            return _preselectSorting;
        case SortType.DISPLAY:
        default:
            return _sorting;
    }
};

/**
 * Compare two values using the master sort list
 */
const compareByMasterSortList = <T extends IOptionValue>(
    sortType: SortType,
    type: string,
    a: T,
    b: T,
) => {
    const masterSortList = getMasterSortList(sortType);
    const master = masterSortList[type.toLowerCase()] || [];
    const iA = asMultiValueOption(a).reduce<number>(
        (memo, v) => memo + master.indexOf(`${v}`),
        0,
    );
    const iB = asMultiValueOption(b).reduce<number>(
        (memo, v) => memo + master.indexOf(`${v}`),
        0,
    );
    if (iA === iB) {
        return 0;
    }
    return iA > iB ? 1 : -1;
};

/**
 * Compare two values using numeric prefixes, if both values have them.
 */
const compareByNumericPrefix = <T extends IOptionValue>(
    _sortType: SortType,
    _type: string,
    a: T,
    b: T,
) => {
    const numericPrefix = /^([0-9.]+)\b/;
    const groupsA = getOptionValueHash(a).match(numericPrefix);
    const groupsB = getOptionValueHash(b).match(numericPrefix);
    if (!groupsA || !groupsB) {
        return 0;
    }
    const prefixA = parseFloat(groupsA[1]);
    const prefixB = parseFloat(groupsB[1]);
    if (prefixA === prefixB) {
        return 0;
    }
    return prefixA > prefixB ? 1 : -1;
};

/**
 * Compare two values alphanumerically (using localeCompare)
 */
const compareByAlpha = <T extends IOptionValue>(
    _sortType: SortType,
    _: string,
    a: T,
    b: T,
) => {
    return getOptionValueHash(a).localeCompare(getOptionValueHash(b));
};

/**
 * List of comparators to fall-through when sorting a list. This list is iterated over
 * until a comparator returns a non-0 value for the two items.
 */
const comparators = [
    compareByMasterSortList,
    compareByNumericPrefix,
    compareByAlpha,
];

/**
 * Compare two option values an return the correct sort compare integer
 */
const compareOptionValues = <T extends IOptionValue>(
    sortType: SortType,
    type: string,
    a: T,
    b: T,
) => {
    return comparators.reduce((memo, func) => {
        if (memo !== 0) {
            return memo;
        }
        return func(sortType, type, a, b);
    }, 0);
};

/**
 * Sort a list of product attribute values based on the type of attribute it is, for example option_size;
 */
export const sortProductOptions = <T extends IOptionValue>(
    type: string,
    options: T[],
    sortType = SortType.DISPLAY,
): T[] => {
    return (
        options
            // Make option list unique
            .filter((value, index, arr) => {
                return arr.indexOf(value) === index;
            })
            // Sort option list
            .sort((a, b) => {
                return compareOptionValues(sortType, type, a, b);
            })
    );
};

/**
 * Get all possible values for the given option code in the given product.
 */
export const listPossibleOptionValues = (
    code: IOptionCode,
    product: IProduct,
    sortType = SortType.DISPLAY,
): IOptionSingleValue[] => {
    let options: IOptionSingleValue[] = [];
    if (!product) {
        return options;
    }
    // If the product has variant children, use their collected attributes
    if (product.children.length) {
        options = product.children
            // Flatten into list of attribute values
            .reduce<IOptionSingleValue[]>((memo, variant) => {
                const childAttr = variant.attributes[code]?.value || "";
                return memo.concat(childAttr);
            }, [])
            // Filter out falsey values like null
            .filter(notEmpty)
            // Make list unique
            .filter(unique);

        // Return sorted, unique list
        return sortProductOptions(code, options, sortType);
    } else {
        const parentAttr = product.attributes[code];
        if (parentAttr) {
            options = options.concat(parentAttr.value);
        }
    }
    return options;
};

/**
 * Get all possible values for the given option code in the given products.
 */
export const listPossibleOptionValuesforProductSet = (
    code: IOptionCode,
    products: IProduct[],
    sortType = SortType.DISPLAY,
): IOptionSingleValue[] => {
    const allOptions = new Set<IOptionSingleValue>();
    for (const product of products) {
        const options = listPossibleOptionValues(code, product);
        for (const opt of options) {
            allOptions.add(opt);
        }
    }
    return sortProductOptions(code, [...allOptions], sortType);
};

/**
 * Based on the options configured for display on the site, and the relative ordering of each value for each
 * of those options, sort a copy of the given product's children and return it.
 */
export const sortProductVariants = (
    rootProduct: IProduct,
    sortType = SortType.DISPLAY,
): IProduct[] => {
    // Get list of option codes in reverse order
    const codes = [...(rootProduct.attributes.product_options?.value || [])];
    codes.reverse();

    // Codes, as displayed on the site from left to right, are most significant to least significant, respectively.
    const codeWeights: { [code: string]: number } = {};
    const codeWeightFactor = Math.ceil(rootProduct.children.length / 10) * 10;
    let nextCodeWeight = 1;
    codes.forEach((code) => {
        codeWeights[code] = nextCodeWeight;
        nextCodeWeight = nextCodeWeight * codeWeightFactor;
    });

    // Values, as displayed on the site in the option dropdown from top to bottom, are most preferred to least preferred, respectively.
    const valueWeights: { [code: string]: { [value: string]: number } } = {};
    codes.forEach((code) => {
        valueWeights[code] = {};
        const values = listPossibleOptionValues(code, rootProduct, sortType);
        values.forEach((value, i) => {
            valueWeights[code][getOptionValueHash(value)] = i;
        });
    });

    // For a given variant, calculate it's sort value by using the code and value weights calculated above.
    const getSortIntForProduct = (product: IProduct) => {
        return codes.reduce((memo, code) => {
            const attr = product.attributes[code];
            const value = attr ? getOptionValueHash(attr.value) : 0;
            const codeWeight =
                codeWeights[code] !== undefined ? codeWeights[code] : Infinity;
            const valueWeight =
                valueWeights[code][value] !== undefined
                    ? valueWeights[code][value]
                    : Infinity;
            return memo + codeWeight * valueWeight;
        }, 0);
    };

    // Sort and return a copy of the product children.
    const children = [...rootProduct.children];
    children.sort((a, b) => {
        const aWeight = getSortIntForProduct(a);
        const bWeight = getSortIntForProduct(b);
        if (aWeight === bWeight) {
            return 0;
        }
        return aWeight > bWeight ? 1 : -1;
    });
    return children;
};

export const productPriceComparator = (
    a: IProduct | undefined,
    b: IProduct | undefined,
) => {
    const flA = a
        ? getProductPrice(a.price, {
              priceType: PriceType.COSMETIC_EXCL_TAX,
              includePostDiscountAddons: true,
              quantity: 1,
          }).toUnit()
        : Infinity;
    const flB = b
        ? getProductPrice(b.price, {
              priceType: PriceType.COSMETIC_EXCL_TAX,
              includePostDiscountAddons: true,
              quantity: 1,
          }).toUnit()
        : Infinity;
    if (flA === flB) {
        return 0;
    }
    return flA > flB ? 1 : -1;
};

export const getCheapestProductVariant = (p: IProduct): IProduct => {
    if (!p.children || p.children.length <= 0) {
        return p;
    }
    const availableChildren = p.children.filter(
        (c) => c.availability.is_available_to_buy,
    );
    const children = (
        availableChildren.length > 0 ? availableChildren : p.children.concat()
    ).sort(productPriceComparator);
    return children[0];
};

export const sortProductsByPrice = (products: IProduct[]): IProduct[] => {
    return products.sort((a, b) => {
        const vA = getCheapestProductVariant(a);
        const vB = getCheapestProductVariant(b);
        return productPriceComparator(vA, vB);
    });
};
