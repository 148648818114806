import React from "react";
import { connect } from "react-redux";
import { IWebPageURL } from "../../../models/nominals";
import { Loaders } from "../loaders";
import { Dispatchers } from "../dispatchers";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { notEmpty } from "../../../utils/functional";
import { defaults } from "../defaults";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { ITileID } from "../../../models/product-compare";
import { SelectedProductTray as SelectedProductTrayComponent } from "../components/SelectedProductTray";
import { CompareAllModal as CompareAllModalComponent } from "../elements/CompareAllModal";
import styles from "./SelectedProductTrayContainer.module.scss";

interface IOwnProps {
    productComparePageURL: IWebPageURL | null;
    tiles: ICompareTile[];
    ctaText?: string;
}

interface IReduxProps {
    priceSelectValue: string;
    selectedTileIDs: ITileID[];
    isExpanded: boolean;
    label: string;
    showCompareModal: boolean;
}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IDispatchProps, IReduxProps {}

interface IState {}

class SelectedProductTrayContainer extends React.Component<IProps, IState> {
    private readonly ctaText = this.props.ctaText
        ? this.props.ctaText
        : "Compare All Mattresses";

    private readonly onRemoveButtonClick = (tileID: ITileID) => {
        this.props.dispatchers.removeFromSelected(tileID);
    };

    private readonly onCollapseToggle = (
        e: React.MouseEvent<HTMLButtonElement>,
    ) => {
        e.preventDefault();
        if (this.props.isExpanded) {
            this.props.dispatchers.collapseTray();
        } else {
            this.props.dispatchers.expandTray();
        }
    };

    private getSelectedTiles() {
        // Find within map is used so as to preserve the selection order of the tiles.
        return this.props.selectedTileIDs
            .map((tileID) => {
                return this.props.tiles.find((t) => {
                    return t.product_compare_tile.id === tileID;
                });
            })
            .filter(notEmpty);
    }

    private buildCompareAllURI() {
        const tileIDs = this.props.tiles.map((tile) => {
            return tile.product_compare_tile.id;
        });
        const baseURL = this.props.productComparePageURL;
        const ids = encodeURIComponent(tileIDs.join(","));
        const returnURL = encodeURIComponent(window.location.pathname);

        return `${baseURL}?tiles=${ids}&return_url=${returnURL}`;
    }

    render() {
        return (
            <section className={`selected-product-tray ${styles.root}`}>
                <SelectedProductTrayComponent
                    selectedTiles={this.getSelectedTiles()}
                    compareAllURI={this.buildCompareAllURI()}
                    priceSelectValue={this.props.priceSelectValue}
                    productComparePageURL={this.props.productComparePageURL}
                    onRemoveButtonClick={this.onRemoveButtonClick}
                    onCollapseToggle={this.onCollapseToggle}
                    isExpanded={this.props.isExpanded}
                    ariaLabel={this.props.label}
                    numProductsAvailable={this.props.tiles.length}
                    ctaText={this.ctaText}
                />
                <CompareAllModalComponent
                    selectedTiles={this.getSelectedTiles()}
                    isModalVisible={this.props.showCompareModal}
                    compareAllURI={this.buildCompareAllURI()}
                    productComparePageURL={this.props.productComparePageURL}
                    onCloseModalClick={this.props.dispatchers.hideModal}
                />
            </section>
        );
    }
}

const mapStateToProps: TStateMapper<
    "productcompare",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    const state = rootState.productcompare || defaults;
    return {
        ...ownProps,
        // tiles: state.tiles,
        selectedTileIDs: state.selectedTileIDs,
        priceSelectValue: state.priceSelectValue,
        isExpanded: state.trayIsExpanded,
        label: state.trayLabel,
        showCompareModal: state.showCompareModal,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);

    return {
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const SelectedProductTray = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SelectedProductTrayContainer);
