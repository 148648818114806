import React from "react";
import { connect } from "react-redux";
import { IRetailStoreWithDistance } from "tsi-common-react/src/models/location.interfaces";
import { TStateMapper } from "tsi-common-react/src/apps/reducers.interfaces";
import {
    myStoreSelector,
    storeSelector,
    focusedStoreIDSelector,
} from "tsi-common-react/src/apps/retail/selectors";
import { getSelectedStore } from "tsi-common-react/src/apps/retail/helpers";
import { StoreInfo } from "./StoreInfo";

import { ISyncStoreID } from "tsi-common-react/src/models/nominals";

interface IOwnProps {
    minimal?: boolean | null;
}

interface IReduxProps {
    closestStore: IRetailStoreWithDistance | null;
    stores: IRetailStoreWithDistance[];
    selectedStoreID: ISyncStoreID | null;
}

interface IProps extends IOwnProps, IReduxProps {}

const NearestStoreInfoContainer = (props: IProps) => {
    const selectedStore = getSelectedStore(
        props.closestStore,
        props.selectedStoreID,
        props.stores,
    );
    if (!selectedStore) {
        return null;
    }
    return (
        <StoreInfo
            store={selectedStore}
            showReviews={false}
            showBookApptLink={false}
            minimal={props.minimal || false}
        />
    );
};

const mapStateToProps: TStateMapper<"retail", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        closestStore: myStoreSelector(rootState),
        stores: storeSelector(rootState),
        selectedStoreID: focusedStoreIDSelector(rootState),
        // Direct Props
        ...ownProps,
    };
};

export const NearestStoreInfo = connect(mapStateToProps)(
    NearestStoreInfoContainer,
);
