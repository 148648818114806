import React from "react";
import classNames from "classnames";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { ConfiguratorTypes } from "../../../constants";

interface IProps {
    configuratorType: ConfiguratorTypes;
    alternatePriceView?: boolean;
}

interface IState {}

export class ConfiguratorPriceLoading extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        const priceClasses = classNames({
            "configurator__price": true,
            "configurator__price--loading": true,
            [`configurator__price--${this.props.configuratorType}`]: true,
            "configurator__price--alternate": this.props.alternatePriceView,
        });
        return (
            <div className={priceClasses}>
                <LoadingSpinner fadeIn="quarter" />
            </div>
        );
    }
}
