import * as t from "io-ts";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import { Address } from "./address";
import { FinancingPlan } from "./financing";
import { DineroFromString } from "./utils";

export const Order = t.interface({
    token: t.string,
    number: t.string,
    date_placed: DateFromISOString,
    //
    basket_total_before_discounts_excl_tax: DineroFromString,
    basket_total_before_discounts_incl_tax: DineroFromString,
    //
    basket_total_excl_tax: DineroFromString,
    basket_total_incl_tax: DineroFromString,
    //
    shipping_excl_tax: DineroFromString,
    shipping_incl_tax: DineroFromString,
    //
    total_excl_tax: DineroFromString,
    total_incl_tax: DineroFromString,
});

export const CompleteOrderPaymentInitData = t.interface({
    basket: t.interface({
        token: t.string,
    }),
    order: Order,
    financing_plans: t.array(FinancingPlan),
    shipping_address: Address,
    billing_address: Address,
});
