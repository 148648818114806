let _wistiaLoading: Promise<unknown> | null = null;

// Load the Js library for the Wistia video player
export const loadWistiaPlayer = async () => {
    if (!_wistiaLoading) {
        const s = document.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://fast.wistia.com/assets/external/E-v1.js";
        _wistiaLoading = new Promise((resolve, reject) => {
            s.onload = resolve;
            s.onerror = reject;
        });
        document.body.appendChild(s);
    }
    return _wistiaLoading;
};
