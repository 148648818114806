import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import update from "immutability-helper";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Key } from "ts-key-enum";
import { strings } from "../../../localization";
import {
    IAddress,
    IAddressCountry,
    IAddressState,
} from "../../../models/address.interfaces";
import { IUser } from "../../../models/user.interfaces";
import { ILocation } from "../../../models/location.interfaces";
import { Form } from "../../../forms/Form";
import { NonFieldErrors } from "../../../forms/NonFieldErrors";
import { FormInput } from "../../../forms/FormInput";
import { FormPhoneNumber } from "../../../forms/FormPhoneNumber";
import { FormSelect } from "../../../forms/FormSelect";
import { FormSubmit } from "../../../forms/FormSubmit";
import { objectKeys } from "../../../utils/functional";
import { focusElement } from "../../../utils/keyboardFocus";
import { newAutocomplete } from "../../../utils/maps";
import { Step } from "../constants";
import { Dispatchers } from "../dispatchers";
import { Dispatchers as CommonDispatchers } from "../../common/dispatchers";
import { Loaders } from "../loaders";
import { Actions } from "../actions";
import { IReduxFormState } from "../reducers.interfaces";
import { SavedAddressSelector } from "../components/SavedAddressSelector";
import { AddressSuggestionModal } from "../components/AddressSuggestionModal";
import { AddressWarningModal } from "../components/AddressWarningModal";
import { CheckoutStepContainer } from "./CheckoutStepContainer";
import { ShippingAddressSummary } from "./ShippingAddressSummary";

interface IOwnProps {
    heading: string;
    errors: string[];
    isCurrentStep: boolean;
    saveEmailAddress: () => Promise<{ owner_email: string }>;
    saveShippingAddress: (bypassUSPSValidation: boolean) => Promise<IAddress>;
    onContinue: (event?: React.MouseEvent<HTMLElement>) => void;
    onEdit: (event?: React.MouseEvent<HTMLElement>) => void;
    formRef?: React.RefObject<Form>;
}

interface IReduxProps {
    user: IUser | null;
    form: IReduxFormState;
    countries: IAddressCountry[];
    states: IAddressState[];
    savedAddresses: IAddress[];
    shippingMethodErrors: string[];
    showForm: boolean;
}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
    actions: Actions;
    onUpdateEnteredLocation: (location: ILocation) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isSaving: boolean;
    showErrorMessages: boolean;
    showAddressWarningModal: boolean;
    showAddressSuggestionModal: boolean;
    suggestedAddress: IAddress | null;
    errors: {
        non_field_errors?: string[];
        guest_email?: string[];
        first_name?: string[];
        last_name?: string[];
        line1?: string[];
        line2?: string[];
        line4?: string[];
        state?: string[];
        postcode?: string[];
        country?: string[];
        phone_number?: string[];
    };
}

class ShippingAddressComponent extends React.Component<IProps, IState> {
    private _stateCache: IState | null = null;
    private autocomplete: FormInput<"shipping_line1"> | null = null;
    private readonly submitButton = React.createRef<HTMLInputElement>();

    public state: IState = {
        isSaving: false,
        showErrorMessages: false,
        showAddressWarningModal: false,
        showAddressSuggestionModal: false,
        suggestedAddress: null,
        errors: {},
    };

    componentDidMount() {
        const inputElem = this.autocomplete
            ? this.autocomplete.getInputElem()
            : null;
        if (inputElem) {
            const autocomplete = newAutocomplete(inputElem);
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place.address_components) {
                    this.onAutocompleteChange(place.address_components);
                }
            });
        }
    }

    componentDidUpdate() {
        this._stateCache = null;
    }

    private readonly onAutocompleteChange = (
        place: google.maps.GeocoderAddressComponent[],
    ) => {
        const placeData: { [s: string]: string } = {};
        const components: { [s: string]: "long_name" | "short_name" } = {
            street_number: "short_name",
            route: "short_name",
            postal_code: "short_name",
            locality: "long_name",
            administrative_area_level_1: "short_name",
        };

        place.forEach((component) => {
            const type = component.types[0];
            const prop = components[type] || "long_name";
            const value = component[prop];
            placeData[type] = value;
        });

        const line1 =
            (placeData.street_number || "") + " " + (placeData.route || "");
        const postcode = placeData.postal_code;
        const line4 = placeData.sublocality_level_1 || placeData.locality;
        const state = placeData.administrative_area_level_1;
        this.props.dispatchers.changeAddressField(
            this.props.form,
            this.props.savedAddresses,
            {
                shipping_line1: line1 || this.props.form.shipping_line1,
                shipping_postcode:
                    postcode || this.props.form.shipping_postcode,
                shipping_line4: line4 || this.props.form.shipping_line4,
                shipping_state: state || this.props.form.shipping_state,
            },
        );
    };

    private readonly onFieldChange = (
        event:
            | React.FormEvent<HTMLInputElement>
            | React.FormEvent<HTMLSelectElement>,
    ) => {
        const fieldValue =
            event.currentTarget.type === "checkbox"
                ? (event.currentTarget as HTMLInputElement).checked
                : event.currentTarget.value;
        this.props.dispatchers.changeAddressField(
            this.props.form,
            this.props.savedAddresses,
            {
                [event.currentTarget.name]: fieldValue,
            },
        );
    };

    private readonly onFormSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
        event.preventDefault();
        // Figure out if we have any client-side validation errors
        const errorCount = objectKeys(this.state.errors)
            .filter((field) => {
                return field !== "non_field_errors";
            })
            .reduce((memo, field) => {
                const fieldErrors = this.state.errors[field];
                const numErrors = fieldErrors ? fieldErrors.length : 0;
                return memo + numErrors;
            }, 0);
        // Don't bother submitting to the server if client-side validation didn't pass
        if (errorCount > 0) {
            this.setState({
                showErrorMessages: true,
            });
            return;
        }
        // Send to the server for further validation and persistence.
        return this.saveAddress(false);
    };

    private readonly onValidStateChange = (
        fieldName: string,
        errorMessages: string[],
    ) => {
        fieldName = fieldName.replace("shipping_", "");
        const oldState = this._stateCache || this.state;
        const newState: IState = update(oldState, {
            errors: {
                [fieldName]: { $set: errorMessages },
            },
        });
        this._stateCache = newState;
        this.setState(newState);
    };

    private async saveAddress(bypassUSPSValidation: boolean) {
        if (this.props.shippingMethodErrors.length > 0) {
            return;
        }

        const addressesIsSame = (addr: IAddress) => {
            const line1 = addr.line1 === this.props.form.shipping_line1;
            const line2 = addr.line2 === this.props.form.shipping_line2;
            const line4 = addr.line4 === this.props.form.shipping_line4;
            const state = addr.state === this.props.form.shipping_state;
            const postcode =
                addr.postcode === this.props.form.shipping_postcode;
            return line1 && line2 && line4 && state && postcode;
        };

        const gotoNextStep = () => {
            this.setState({
                isSaving: false,
                errors: {},
                showErrorMessages: false,
                showAddressWarningModal: false,
                showAddressSuggestionModal: false,
            });
            this.props.onUpdateEnteredLocation({
                formatted_address: this.props.form.shipping_postcode.slice(
                    0,
                    5,
                ),
                formatted_address_long: `${this.props.form.shipping_line4}, ${this.props.form.shipping_state}`,
                zip: this.props.form.shipping_postcode.slice(0, 5),
            });
            this.props.onContinue();
        };

        const showSuggestionModal = (addr: IAddress) => {
            this.setState({
                showAddressSuggestionModal: true,
                suggestedAddress: addr,
            });
        };

        const showWarningModal = () => {
            this.setState({
                showAddressWarningModal: true,
            });
        };

        const displayInlineErrors = (errors: { [s: string]: string }) => {
            this.setState({
                isSaving: false,
                errors: errors,
                showErrorMessages: true,
            });
        };

        const abortSave = () => {
            this.setState({
                isSaving: false,
                showErrorMessages: true,
            });
        };

        this.setState({
            isSaving: true,
            errors: {},
            showAddressWarningModal: false,
            showAddressSuggestionModal: false,
        });

        try {
            await this.props.saveEmailAddress();
            const addr =
                await this.props.saveShippingAddress(bypassUSPSValidation);
            if (this.props.shippingMethodErrors.length > 0) {
                abortSave();
            } else if (bypassUSPSValidation || addressesIsSame(addr)) {
                gotoNextStep();
            } else {
                showSuggestionModal(addr);
            }
        } catch (err) {
            if (this.props.shippingMethodErrors.length > 0) {
                abortSave();
            } else if (
                Object.keys(err.response.body).length === 1 &&
                err.response.body.non_field_errors
            ) {
                showWarningModal();
            } else {
                displayInlineErrors(err.response.body);
            }
        }
    }

    private blockEnterKeySubmit(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === Key.Enter) {
            event.preventDefault();
        }
    }

    private buildAddressWarningModal() {
        const onContinue = () => {
            this.saveAddress(true);
        };
        const onEdit = () => {
            this.setState({
                isSaving: false,
                showAddressWarningModal: false,
            });
        };
        return (
            <AddressWarningModal
                form={this.props.form}
                onContinue={onContinue}
                onEdit={onEdit}
                showAddressWarningModal={this.state.showAddressWarningModal}
                addressPrefix={"shipping"}
            />
        );
    }

    private buildAddressSuggestionModal() {
        const onContinue = (shouldImport: boolean) => {
            if (shouldImport && this.state.suggestedAddress) {
                this.props.loaders.importShippingAddr(
                    this.state.suggestedAddress,
                );
            }
            this.saveAddress(true);
        };
        const closeSuggestionModal = () => {
            this.setState({
                showAddressSuggestionModal: false,
                isSaving: false,
            });
        };
        const focusOnSubmitButton = () => {
            if (this.submitButton.current) {
                focusElement(this.submitButton.current);
            }
        };
        return (
            <AddressSuggestionModal
                form={this.props.form}
                suggestedAddress={this.state.suggestedAddress}
                onContinue={onContinue}
                onRequestClose={closeSuggestionModal}
                onAfterClose={focusOnSubmitButton}
                showAddressSuggestionModal={
                    this.state.showAddressSuggestionModal
                }
                addressPrefix={"shipping"}
            />
        );
    }

    private getCountryOptions() {
        const countries = this.props.countries.map((country) => {
            return {
                value: country.url,
                label: country.printable_name,
            };
        });
        return countries;
    }

    private getStateOptions() {
        const states = this.props.states.map((state) => {
            return {
                value: state.code,
                label: state.name,
            };
        });
        states.unshift({
            value: "",
            label: "",
        });
        return states;
    }

    render() {
        const bodyClasses = classNames({
            "checkout-step__body": true,
            "checkout-step__body--collapsed": !this.props.showForm,
        });
        let emailField: JSX.Element | null = null;
        if (
            !this.props.user ||
            !this.props.user.email ||
            this.props.user.is_csr
        ) {
            emailField = (
                <div>
                    <FormInput
                        autoComplete="email"
                        name="guest_email"
                        id="guest_email"
                        label={t`Email Address`}
                        labelPlacement="dynamic"
                        value={this.props.form.guest_email}
                        errors={this.state.errors.guest_email}
                        validation={["required", "email"]}
                        onChange={this.onFieldChange}
                        disabled={this.state.isSaving}
                        onValidStateChange={this.onValidStateChange}
                        showErrorMessages={this.state.showErrorMessages}
                    />
                </div>
            );
        }
        return (
            <CheckoutStepContainer
                className="checkout-step--shipping-address form"
                heading={this.props.heading}
                isCurrentStep={this.props.isCurrentStep}
            >
                <div className={bodyClasses}>
                    <p>
                        Please ensure the information you provide is correct,
                        because we will share this information with our trusted
                        shipping agent(s) to deliver your order.
                    </p>
                    <Form onSubmit={this.onFormSubmit} ref={this.props.formRef}>
                        <NonFieldErrors
                            errors={this.state.errors.non_field_errors}
                            showErrorMessages={true}
                        />
                        <NonFieldErrors
                            errors={this.props.errors.concat(
                                this.props.shippingMethodErrors,
                            )}
                            showErrorMessages={true}
                        />
                        <SavedAddressSelector
                            prefix="shipping"
                            isDisabled={this.state.isSaving}
                        />
                        <FormInput
                            type="text"
                            id="shipping_first_name"
                            name="shipping_first_name"
                            label={t`First Name`}
                            labelPlacement="dynamic"
                            value={this.props.form.shipping_first_name}
                            errors={this.state.errors.first_name}
                            validation={["required", "firstname-empty"]}
                            maxLength={25}
                            autoComplete="given-name"
                            onChange={this.onFieldChange}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                        />
                        <FormInput
                            type="text"
                            id="shipping_last_name"
                            name="shipping_last_name"
                            label={t`Last Name`}
                            labelPlacement="dynamic"
                            value={this.props.form.shipping_last_name}
                            errors={this.state.errors.last_name}
                            validation={["required", "lastname-empty"]}
                            maxLength={25}
                            autoComplete="family-name"
                            onChange={this.onFieldChange}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                        />
                        <FormInput
                            ref={(elem) => {
                                this.autocomplete = elem;
                            }}
                            type="text"
                            id="shipping_line1"
                            name="shipping_line1"
                            label={t`Street and number, c/o.`}
                            labelPlacement="dynamic"
                            adaText={t`Begin typing to search, use arrow keys to navigate, Enter to select`}
                            placeholder=""
                            value={this.props.form.shipping_line1}
                            errors={this.state.errors.line1}
                            validation={["required", "shipping-address-empty"]}
                            maxLength={25}
                            maxBytes={240}
                            autoComplete="address-line1"
                            onChange={this.onFieldChange}
                            onKeyPress={this.blockEnterKeySubmit}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                        />
                        <FormInput
                            type="text"
                            id="shipping_line2"
                            name="shipping_line2"
                            label={t`Apartment, suite, unit, etc. (Optional)`}
                            labelPlacement="dynamic"
                            autoComplete="address-line2"
                            value={this.props.form.shipping_line2}
                            maxLength={25}
                            maxBytes={240}
                            errors={this.state.errors.line2}
                            onChange={this.onFieldChange}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                        />
                        <FormInput
                            type="text"
                            id="shipping_line4"
                            name="shipping_line4"
                            label={t`City`}
                            labelPlacement="dynamic"
                            autoComplete="address-level2"
                            value={this.props.form.shipping_line4}
                            errors={this.state.errors.line4}
                            validation={["required", "city-empty"]}
                            maxLength={60}
                            maxBytes={60}
                            onChange={this.onFieldChange}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                        />
                        <FormSelect
                            label={t`State`}
                            labelPlacement="dynamic"
                            id="shipping_state"
                            name="shipping_state"
                            value={this.props.form.shipping_state}
                            errors={this.state.errors.state}
                            validation={["required", "state-empty"]}
                            autoComplete="address-level1"
                            onChange={this.onFieldChange}
                            choices={this.getStateOptions()}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                        />
                        <FormInput
                            type="text"
                            id="shipping_postcode"
                            name="shipping_postcode"
                            label={t`Zip Code`}
                            labelPlacement="dynamic"
                            value={this.props.form.shipping_postcode}
                            errors={this.state.errors.postcode}
                            validation={["required", "zipcode"]}
                            maxLength={10}
                            maxBytes={60}
                            autoComplete="postal-code"
                            onChange={this.onFieldChange}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                        />
                        <FormSelect
                            label={t`Country`}
                            labelPlacement="dynamic"
                            id="shipping_country"
                            name="shipping_country"
                            value={this.props.form.shipping_country}
                            errors={this.state.errors.country}
                            onChange={this.onFieldChange}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                            validation="required"
                            autoComplete="country"
                            choices={this.getCountryOptions()}
                        />
                        <FormPhoneNumber
                            type="phone"
                            id="shipping_phone_number"
                            name="shipping_phone_number"
                            label={t`Phone Number`}
                            labelPlacement="dynamic"
                            value={this.props.form.shipping_phone_number}
                            errors={this.state.errors.phone_number}
                            validation={["required", "phone"]}
                            autoComplete="tel"
                            onChange={this.onFieldChange}
                            disabled={this.state.isSaving}
                            onValidStateChange={this.onValidStateChange}
                            showErrorMessages={this.state.showErrorMessages}
                        />
                        {emailField}
                        <FormSubmit
                            className="button button--full-width button--continue-to-delivery"
                            inputRef={this.submitButton}
                            value={
                                strings.get(
                                    "CONTINUE_BTN_LABEL_SHIPPING_ADDRESS",
                                ) || "Continue"
                            }
                            disabled={
                                this.state.isSaving ||
                                this.props.shippingMethodErrors.length > 0
                            }
                        />
                    </Form>
                </div>
                <ShippingAddressSummary onEdit={this.props.onEdit} />
                {this.buildAddressWarningModal()}
                {this.buildAddressSuggestionModal()}
            </CheckoutStepContainer>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        user: rootState.common.user,
        form: state.form,
        countries: state.data.countries,
        states: state.data.billing_states,
        savedAddresses: state.data.addresses,
        shippingMethodErrors: state.data.shipping_method_errors,
        showForm: state.form.current_step === Step.SHIPPING_ADDRESS,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);
    const actions = new Actions(loaders, dispatchers);
    const commonDispatchers = new CommonDispatchers(dispatch);
    return {
        dispatchers: dispatchers,
        loaders: loaders,
        actions: actions,
        onUpdateEnteredLocation: commonDispatchers.updateEnteredLocation,
    };
};

export const ShippingAddress = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShippingAddressComponent);
