import React from "react";
import { connect } from "react-redux";
import { t } from "ttag";
import { IReview } from "../../../models/reviews.interfaces";
import { trackEvent } from "../../../utils/analytics";
import { focusElement } from "../../../utils/keyboardFocus";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { defaultState } from "../defaults";
import { Review } from "./Review";

interface IOwnProps {
    showSideDate: boolean;
    enablePDPLinks: boolean;
    showRecommended: boolean;
    showVerboseUserInfo: boolean;
    starHasStroke?: boolean;
}

interface IReduxProps {
    reviews: IReview[];
    page: number;
}

interface IDispatchProps {
    clearFilters: Dispatchers["clearFilters"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class ReviewListComponent extends React.Component<IProps, IState> {
    private readonly listElem = React.createRef<HTMLDivElement>();

    componentDidUpdate(prevProps: IProps) {
        // If we just loaded new reviews, focus on the first new one
        if (this.props.page > prevProps.page) {
            if (this.listElem.current) {
                const reviewElems =
                    this.listElem.current.querySelectorAll<HTMLElement>(
                        `.customer-review__review-item`,
                    );
                const firstNewReview = reviewElems[prevProps.reviews.length];
                if (firstNewReview) {
                    focusElement(firstNewReview);
                }
            }
        }
    }

    render() {
        trackEvent("Reviews", "reviewCount", "Review Count", {
            nonInteraction: true,
            numReviews: this.props.reviews.length,
        });
        if (this.props.reviews.length === 0) {
            trackEvent("Reviews", "noReviewsFound", undefined, {
                nonInteraction: true,
            });
            return (
                <div className="customer-review__no-reviews">
                    <div className="customer-review__no-reviews-copy">
                        {t`Sorry, we could not find any reviews within your criteria.`}
                    </div>
                    <div
                        className="customer-review__no-reviews-cta"
                        onClick={this.props.clearFilters}
                    >
                        {t`Clear filters and try again?`}
                    </div>
                </div>
            );
        }
        return (
            <div ref={this.listElem}>
                {this.props.reviews.map((data) => (
                    <Review
                        key={`${data.product_id}-${data.id}`}
                        review={data}
                        showSideDate={this.props.showSideDate}
                        enablePDPLinks={this.props.enablePDPLinks}
                        showRecommended={this.props.showRecommended}
                        showVerboseUserInfo={this.props.showVerboseUserInfo}
                        starHasStroke={this.props.starHasStroke}
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        reviews: state.data.reviews,
        page: state.ui.page,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        clearFilters: dispatchers.clearFilters,
    };
};

export const ReviewList = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReviewListComponent);
