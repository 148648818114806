import React from "react";
import { ISafeHTML } from "../models/nominals";

interface IProps {
    html: string | ISafeHTML;
    inline?: boolean;
    className?: string;
    tagName?: keyof React.ReactHTML;
}

export const RichText = (props: IProps) => {
    const richTextProps = {
        className: props.className
            ? `rich-text ${props.className}`
            : "rich-text",
        dangerouslySetInnerHTML: {
            __html: props.html as string,
        },
    };
    let tagName: keyof React.ReactHTML = "div";
    if (props.inline) {
        tagName = "span";
    }
    if (props.tagName) {
        tagName = props.tagName;
    }
    return React.createElement(tagName, richTextProps);
};

export default RichText;
