import { Middleware } from "@reduxjs/toolkit";
import {
    IOptionCode,
    IOptionValue,
    IOptionValues,
} from "../../models/catalogue.interfaces";
import { OptionValue } from "../../models/catalogue";
import { isKnownAction } from "../../utils/redux";
import { check } from "../../models/utils";
import { IActionSetOptionValue } from "./reducers.interfaces";
import { Action } from "./constants";

const getOptionKey = (namespace: string, code: string) => {
    return `configurator-option-${namespace}-${code}`;
};

export const persistOptionValue = (
    namespace: string,
    code: string,
    value: IOptionValue,
): void => {
    const key = getOptionKey(namespace, code);
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.debug("Cannot write to localStorage");
    }
};

const retrieveOptionValue = (
    namespace: string,
    code: string,
): IOptionValue | null => {
    const key = getOptionKey(namespace, code);
    try {
        const val = localStorage.getItem(key);
        return val ? check(OptionValue.decode(JSON.parse(val))) : null;
    } catch (e) {
        console.debug("Cannot read from localStorage");
        return null;
    }
};

/**
 * Persist option selection values to localStorage so that they can be
 * automatically selected upon the next page load.
 */
export const persistOptionSelections: Middleware = () => {
    return (next) => {
        return (action) => {
            if (
                isKnownAction<IActionSetOptionValue>(
                    Action.SET_OPTION_VALUE,
                    action,
                )
            ) {
                persistOptionValue(
                    action.payload.namespace,
                    action.payload.code,
                    action.payload.value,
                );
            }
            return next(action);
        };
    };
};

/**
 * Retrieve the last selected values for the given namespace and option codes.
 */
export const getPreferredOptionSelections = (
    namespace: string,
    codes: IOptionCode[],
) => {
    const values: IOptionValues = {};
    codes.forEach((code) => {
        const value = retrieveOptionValue(namespace, code);
        if (value) {
            values[code] = value;
        }
    });
    return values;
};
