import React from "react";

interface IProps {
    options: string[];
}

interface IState {}

export class SelectOptions extends React.PureComponent<IProps, IState> {
    render() {
        return (
            <>
                {this.props.options.map((option) => {
                    return (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    );
                })}
            </>
        );
    }
}
