import React from "react";
import { connect } from "react-redux";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { preferredLocationSelector } from "tsi-common-react/src/apps/common/selectors";
import { IReduxState } from "../../reducers.interfaces";
import { storeSelector } from "tsi-common-react/src/apps/retail/selectors";
import { LocationsColumnBlockComponent } from "../components/LocationsColumnBlockComponent";

interface IReduxProps {
    stores: IRetailStoreWithDistance[];
    preferredLocation: ILocation | null;
}

interface IOwnProps {
    withMap: boolean;
}

interface IDispatchProps {}

type IProps = IReduxProps & IOwnProps & IDispatchProps;

interface IState {}

class LocationsColumnBlockContainer extends React.Component<IProps, IState> {
    render() {
        return (
            <LocationsColumnBlockComponent
                withMap={this.props.withMap}
                stores={this.props.stores}
            />
        );
    }
}

const mapStateToProps = (
    state: IReduxState,
    ownProps: IOwnProps,
): IReduxProps & IOwnProps => {
    return {
        ...ownProps,
        preferredLocation: preferredLocationSelector(state),
        stores: storeSelector(state),
    };
};

export const LocationsColumnBlock = connect(mapStateToProps)(
    LocationsColumnBlockContainer,
);
