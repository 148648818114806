import React from "react";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import { t } from "ttag";
import classNames from "classnames";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import {
    IOptionCode,
    IOptionSingleValue,
    IOptionValues,
    IProduct,
    IVerboseProductClass,
} from "../../../models/catalogue.interfaces";
import { IWebPageURL } from "../../../models/nominals";
import { ConfiguratorTypes } from "../../../constants";
import {
    rootProductSelector,
    baseVariantSelector,
    optionCodesSelector,
} from "../selectors";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { RootProductSelector } from "./RootProductSelector";
import { QuantitySelector } from "./QuantitySelector";
import { ProductOptionSelectorSet } from "../../../common/ProductOptionSelectorSet";

interface IOwnProps {
    optionSet: IModularConfiguratorOptionSet;
    disableQuantity?: boolean;
    style?: "full" | "mini";
    chatLinkComponent?: JSX.Element | null;
    liveChatHeader?: string;
    financingLink?: IWebPageURL;
    isPlpPanelStyle?: boolean;
}

interface IReduxProps {
    rootProduct: IProduct | null;
    productClass: IVerboseProductClass | null;
    variant: IProduct | null;
    optionCodes: IOptionCode[];
    optionValues: IOptionValues;
    panelTriggerClicked: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
    onOptionChange: (
        namespace: string,
        code: IOptionCode,
        index: number,
        totalNumValues: number,
        value: IOptionSingleValue,
    ) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class ConfiguratorOptionsComponent extends React.PureComponent<IProps, IState> {
    static defaultProps: Partial<IProps> = {
        disableQuantity: false,
        style: "full",
    };

    componentDidUpdate(prevProps: IReduxProps) {
        if (this.props.optionSet.layout.design !== ConfiguratorTypes.PANEL) {
            return;
        }
        if (!this.props.panelTriggerClicked) {
            return;
        }
        if (!this.props.rootProduct || !prevProps.rootProduct) {
            return;
        }
        const optionCodes =
            this.props.rootProduct.attributes.product_options?.value || [];
        const preOptionCodes =
            prevProps.rootProduct.attributes.product_options?.value || [];
        const optionValues = optionCodes.map((code) => {
            return {
                code: code,
                value: this.props.optionValues[code] || "",
            };
        });
        const preOptionValues = preOptionCodes.map((code) => {
            return {
                code: code,
                value: prevProps.optionValues[code] || "",
            };
        });

        if (
            !this.props.isPlpPanelStyle &&
            this.props.rootProduct.id !== prevProps.rootProduct.id
        ) {
            let optionCodeChanges: IOptionCode[] = [];
            Array.from(preOptionValues, (option, i) => {
                if (option.value !== optionValues[i].value) {
                    optionCodeChanges = [...optionCodeChanges, option.code];
                    if (preOptionValues.length === i + 1) {
                        const options = optionCodeChanges.map((o) => {
                            return o.replace("option_", " ");
                        });
                        const errorMessage = interpolate(
                            t`Please <strong>confirm mattress %s</strong> before adding to cart.`,
                            [options.join(" and")],
                        );
                        this.props.dispatchers.setSetOptionSelectionErrorState(
                            errorMessage,
                        );
                    }
                }
            });
        }
    }

    private buildQuantitySelector() {
        if (
            this.props.optionSet.layout.design === ConfiguratorTypes.PANEL ||
            this.props.optionSet.layout.design === ConfiguratorTypes.UPDATED ||
            this.props.disableQuantity
        ) {
            return null;
        }
        return <QuantitySelector />;
    }

    private buildOptionSelectors() {
        return this.props.optionCodes.map((code) => (
            <ProductOptionSelectorSet
                key={code}
                selectorID={code}
                rootProduct={this.props.rootProduct}
                variant={this.props.variant}
                code={code}
                values={this.props.optionValues}
                onOptionChange={this.props.onOptionChange}
                configuratorType={this.props.optionSet.layout.design}
                style={this.props.style}
                chatLinkComponent={this.props.chatLinkComponent}
                liveChatHeader={this.props.liveChatHeader}
                enableChat={this.props.optionSet.layout.enable_chat}
                isPlpPanelStyle={this.props.isPlpPanelStyle}
            />
        ));
    }

    render() {
        const configuratorBaseClasses = classNames({
            configurator__options: true,
            [`configurator__options--${this.props.optionSet.layout.design}`]:
                this.props.optionSet.layout.design,
        });
        return (
            <div className={configuratorBaseClasses}>
                <RootProductSelector
                    optionSet={this.props.optionSet}
                    chatLinkComponent={this.props.chatLinkComponent}
                    liveChatHeader={this.props.liveChatHeader}
                    financingLink={this.props.financingLink}
                    isPlpPanelStyle={this.props.isPlpPanelStyle}
                />
                {this.buildOptionSelectors()}
                {this.buildQuantitySelector()}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProduct: rootProductSelector(state),
        productClass: state.entities.productClass,
        variant: baseVariantSelector(state),
        optionCodes: optionCodesSelector(state),
        optionValues: state.ui.optionValues,
        panelTriggerClicked: state.ui.panelTriggerClicked,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers,
        onOptionChange: (namespace, code, index, totalNumValues, value) => {
            dispatchers.setOptionValue(
                namespace,
                code,
                index,
                totalNumValues,
                value,
            );
        },
    };
};

export const ConfiguratorOptions = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ConfiguratorOptionsComponent);
