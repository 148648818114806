import { createAsyncThunk } from "@reduxjs/toolkit";
import { isRight } from "fp-ts/lib/Either";
import * as financingAPI from "../../../api/financing";
import {
    FinancingPreQualRequest,
    PreQualStatus,
} from "../../../models/financing";
import { ErrorSet } from "../../../models/formFields";
import { ModalStateType } from "../constants";
import { ModalState } from "../models";

export const checkPreQualStatus = createAsyncThunk(
    "financing/checkPreQualStatus",
    async (reqData: FinancingPreQualRequest): Promise<ModalState> => {
        try {
            const resp =
                await financingAPI.checkFinancingPreQualStatus(reqData);
            const approvedResponse = resp.responses.find((r) => r.is_approved);
            if (approvedResponse) {
                return {
                    _tag: ModalStateType.PREQUAL_APPROVED,
                    data: resp,
                    approvedResponse: approvedResponse,
                };
            }
            const existingCardHolderResponse = resp.responses.find(
                (r) => r.status === PreQualStatus.EXISTING_CARDHOLDER,
            );
            if (existingCardHolderResponse) {
                return {
                    _tag: ModalStateType.PREQUAL_EXISTING_CARDHOLDER,
                    data: resp,
                };
            }
            return {
                _tag: ModalStateType.PREQUAL_DENIED,
                data: resp,
            };
        } catch (err) {
            const maybeErrResp = ErrorSet.decode(err.response.body);
            if (isRight(maybeErrResp)) {
                return {
                    _tag: ModalStateType.PREQUAL_ERROR,
                    errors: maybeErrResp.right,
                };
            }
            console.error(err);
            throw err;
        }
    },
);
