/**
 * Used for making sure that switch statements handle all cases.
 *
 * See https://www.typescriptlang.org/docs/handbook/advanced-types.html
 */
export const assertNever = (x: never): never => {
    throw new Error(`Unexpected object: ${x}`);
};

export const guardUnhandledAction = (action: never) => {
    return action;
};
