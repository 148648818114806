export const WEEK_DAYS = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
] as const;

const findWeekDayIndex = (day: string): number => {
    return WEEK_DAYS.findIndex((weekDay) => weekDay === day.trim());
};

export type MaybeDayRange = [string, string | undefined];

export type DayTimePair = [string, string];
export type ExpandedStoreHours = DayTimePair[];

/**
 *  Refactors hours_summary string of type: "Day - day: hour, day: hour" to an array of [day, hour] pairs.
 *  Example:
 *  Refactors `"Monday – Saturday: 10AM – 8PM, Sunday: 12PM – 6PM"` to:
 *  [
 *    ["Monday", " 10AM – 8PM"],
 *    ["Tuesday", " 10AM – 8PM"],
 *    ["Wednesday", " 10AM – 8PM"],
 *    ["Thursday", " 10AM – 8PM"],
 *    ["Friday", " 10AM – 8PM"],
 *    ["Saturday", " 10AM – 8PM"],
 *    ["Sunday", " 12PM – 6PM"],
 *  ]
 */
export const refactorStoreHours = (storeHours: string) => {
    const PERIOD_SEP = ",";
    const PERIOD_TIME_SEP = ": ";
    const DAY_RANGE_SEP = " – ";
    const periods = storeHours.split(PERIOD_SEP).map((pair): DayTimePair => {
        const [d, t] = pair.split(PERIOD_TIME_SEP, 2);
        return [d.trim(), t.trim()];
    });
    const refactoredTimes = periods.map(([days, times]): ExpandedStoreHours => {
        const [startDay, endDay] = days.split(
            DAY_RANGE_SEP,
            2,
        ) as MaybeDayRange;

        if (!endDay) {
            return [[startDay, times]];
        }

        const [firstDayIndex, lastDayIndex] = [startDay, endDay].map(
            findWeekDayIndex,
        );
        const allStoreDays = WEEK_DAYS.slice(firstDayIndex, lastDayIndex + 1);
        return allStoreDays.map((storeDay): DayTimePair => [storeDay, times]);
    });
    return refactoredTimes.flat();
};
