import {
    IProduct,
    IOptionValue,
    IOptionMultiValue,
} from "../models/catalogue.interfaces";
import { IPrice, IAPIPrice } from "../models/prices.interfaces";
import { APIPrice } from "../models/prices";
import { STR_YES, STR_NO } from "../constants";
import { Dinero, ZERO, getDinero } from "./money";

export const isMattress = (product: IProduct) => {
    return product.product_class_slug === "mattress";
};

export const getOptionValueHash = (value: IOptionValue): string => {
    return value.toString();
};

export const asMultiValueOption = <T>(value: T | T[]): IOptionMultiValue => {
    if (Array.isArray(value)) {
        return value.map((v) => `${v}`);
    }
    return [`${value}`];
};

export const getOptionValueDisplayName = (
    name: string,
    value: IOptionValue,
): string => {
    // Boolean options are a special case
    if (value === STR_YES) {
        return name;
    }
    if (value === STR_NO) {
        return "";
    }
    return asMultiValueOption(value).sort().join(" & ");
};

export const getPostDiscountAddonsTotal = (
    price: IPrice,
    qty: number,
): Dinero => {
    const total = (price.post_discount_addons || [])
        .map((addon) => getDinero(addon.price_excl_tax))
        .reduce((memo, addonPrice) => memo.add(addonPrice), ZERO)
        .multiply(qty);
    return total;
};

export const enum PriceType {
    RETAIL, // MSRP (retail price)
    ACTUAL_EXCL_TAX, // Base price in Oscar
    ACTUAL_INCL_TAX,
    COSMETIC_EXCL_TAX, // Predicted base price after applying offers
    COSMETIC_INCL_TAX,
}

export const getProductPrice = (
    inputPrice: IPrice | IAPIPrice,
    {
        priceType,
        includePostDiscountAddons,
        quantity,
    }: {
        priceType: PriceType;
        includePostDiscountAddons: boolean;
        quantity: number;
    },
): Dinero => {
    // Figure out what kind of price we have to work with.
    // - IPrice is always a unit price
    // - IAPIPrice has both unit and total, but the total might not be the
    //   quantity requested.
    // let unitPrice: IPrice;
    let basePrice: IPrice;
    let basePriceIncludesQuantity: boolean;
    if (APIPrice.is(inputPrice)) {
        if (inputPrice.quantity === quantity) {
            // unitPrice = inputPrice.unit;
            basePrice = inputPrice.total;
            basePriceIncludesQuantity = true;
        } else {
            // unitPrice = inputPrice.unit;
            basePrice = inputPrice.unit;
            basePriceIncludesQuantity = false;
        }
    } else {
        // unitPrice = inputPrice;
        basePrice = inputPrice;
        basePriceIncludesQuantity = false;
    }

    // Get the base price based on the price type options
    let outputPrice: Dinero;
    switch (priceType) {
        case PriceType.RETAIL: {
            outputPrice = getDinero(
                basePrice.retail ??
                    basePrice.cosmetic_excl_tax ??
                    basePrice.excl_tax,
            );
            break;
        }

        case PriceType.ACTUAL_EXCL_TAX: {
            outputPrice = getDinero(basePrice.excl_tax);
            break;
        }

        case PriceType.ACTUAL_INCL_TAX: {
            outputPrice = getDinero(basePrice.incl_tax);
            break;
        }

        case PriceType.COSMETIC_EXCL_TAX: {
            outputPrice = getDinero(
                basePrice.cosmetic_excl_tax ?? basePrice.excl_tax,
            );
            break;
        }

        case PriceType.COSMETIC_INCL_TAX: {
            outputPrice = getDinero(
                basePrice.cosmetic_incl_tax ?? basePrice.incl_tax,
            );
            break;
        }
    }

    // If quantity isn't already accounted for, do that now.
    if (!basePriceIncludesQuantity) {
        outputPrice = outputPrice.multiply(quantity);
    }

    // Add on the post-discount addons
    if (includePostDiscountAddons) {
        const addonsTotal = getPostDiscountAddonsTotal(basePrice, quantity);
        outputPrice = outputPrice.add(addonsTotal);
    }

    return outputPrice;
};

export const isProductPriceDiscounted = (price: IPrice): boolean => {
    const retail = getProductPrice(price, {
        priceType: PriceType.RETAIL,
        includePostDiscountAddons: true,
        quantity: 1,
    });
    const cosmetic = getProductPrice(price, {
        priceType: PriceType.COSMETIC_EXCL_TAX,
        includePostDiscountAddons: true,
        quantity: 1,
    });
    return cosmetic.lessThan(retail);
};
