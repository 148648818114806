import * as t from "io-ts";
import { DeliveryDatePredictionID } from "./nominals";
import { nullable } from "./utils";

export const DeliveryDatePreference = t.interface({
    preferred_date: nullable(t.string),
});
export type IDeliveryDatePreference = t.TypeOf<typeof DeliveryDatePreference>;

export const DeliveryDatePrediction = t.interface({
    status_code: t.literal("success"),
    prediction_id: DeliveryDatePredictionID,
    date_aggressive: t.string,
    date_conservative: t.string,
    slots_aggressive: t.array(t.string),
    slots_conservative: t.array(t.string),
    postcode: t.string,
    created_datetime: t.string,
});
export type IDeliveryDatePrediction = t.TypeOf<typeof DeliveryDatePrediction>;

export const DeliveryDateUnpredictable = t.interface({
    status_code: t.union([
        t.literal("deliverydates-api-offline-error"),
        t.literal("unpredictable-location-error"),
    ]),
});
export type IDeliveryDateUnpredictable = t.TypeOf<
    typeof DeliveryDateUnpredictable
>;

export const DeliveryDatePredictionResponse = t.union([
    DeliveryDatePrediction,
    DeliveryDateUnpredictable,
]);
export type IDeliveryDatePredictionResponse = t.TypeOf<
    typeof DeliveryDatePredictionResponse
>;

export type IPredictionResponseStatusCode =
    | IDeliveryDatePredictionResponse["status_code"]
    | "unknown";
