import React from "react";
import classNames from "classnames";
import { Key } from "ts-key-enum";
import {
    IOptionCode,
    IOptionSingleValue,
    IOptionInputType,
    IProduct,
} from "../models/catalogue.interfaces";
import { listPossibleOptionValues } from "../utils/sorting";
import { getOptionValueHash } from "../utils/catalogue";
import { ConfiguratorTypes } from "../constants";
import { getPageSetting } from "../utils/settings";
import { MOBILE_WIDTH_BODY_SCROLL } from "../apps/configurator/constants";
import { ProductOptionColorSelector as ColorSelector } from "./ProductOptionColorSelector";
import { ProductOptionDropdownSelector as DropdownSelector } from "./ProductOptionDropdownSelector";
import { ProductOptionRadioSelector as RadioSelector } from "./ProductOptionRadioSelector";
import { LevelSelectionPanel } from "./LevelSelectionPanel";
import { ProductOptionClassicSelector as ClassicSelector } from "./ProductOptionClassicSelector";
import { ConfiguratorPanel } from "../apps/configurator/elements/ConfiguratorPanel";
import {
    ConfiguratorPanelTrigger,
    disableMainConfiguratorScrolling,
} from "../apps/configurator/elements/ConfiguratorPanelTrigger";
import { CSSTransition } from "react-transition-group";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";

import styles from "./ProductOptionSelector.module.scss";

interface IProps {
    selectorID: string;
    rootProduct: IProduct | null;
    variant: IProduct | null;
    code: IOptionCode;
    value: IOptionSingleValue;
    onOptionChange: (
        namespace: string,
        code: IOptionCode,
        value: IOptionSingleValue,
    ) => void;
    configuratorType?: ConfiguratorTypes;
    style?: "full" | "mini";
    chatLinkComponent?: JSX.Element | null;
    liveChatHeader?: string;
    enableChat: boolean;
    isPlpPanelStyle?: boolean;
}

interface IState {
    isPanelOpen: boolean;
    isEnterKeyDown: boolean;
    isClicked: boolean;
}

export class ProductOptionSelector extends React.PureComponent<IProps, IState> {
    private readonly panelContainerRef = React.createRef<HTMLDivElement>();
    static defaultProps: Partial<IProps> = {
        style: "full",
    };

    private changeLock = false;
    public state: IState = {
        isPanelOpen: false,
        isEnterKeyDown: false,
        isClicked: false,
    };

    componentDidUpdate(prevProps: IProps) {
        if (this.props.configuratorType !== ConfiguratorTypes.PANEL) {
            return;
        }
        if (this.changeLock) {
            return;
        }

        // Don't close the panel if we're inside the special
        // Sealy "Level Selection Panel"
        if (this.props.code !== "option_level") {
            if (
                prevProps.value !== this.props.value ||
                this.state.isEnterKeyDown ||
                this.state.isClicked
            ) {
                this.onClosePanel();
            }
        }
    }

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    private readonly onOptionChange = (
        e: React.FormEvent<HTMLSelectElement | HTMLInputElement>,
    ) => {
        // Preventing default actions results in weird behavior with keyboard navigation
        const namespace = this.props.rootProduct
            ? this.props.rootProduct.product_class_slug
            : "";
        this.props.onOptionChange(
            namespace,
            this.props.code,
            e.currentTarget.value,
        );
    };

    private getClassesNames(
        inputType: string,
        prefixName: string,
        options: IOptionSingleValue[],
    ) {
        const hasSingleOption = options.length === 1;
        const configuratorClass = classNames({
            "configurator__select-container": true,
            [`configurator__select-container--${this.props.configuratorType}`]:
                true,
            "configurator__select-container--one-option": hasSingleOption,
            [`configurator__select-container--${this.props.style}`]: true,
            [`configurator__select-container--${prefixName}`]: true,
            [`configurator__select-container--${inputType}`]: true,
            [styles.panelContainer]:
                this.props.configuratorType === ConfiguratorTypes.PANEL,
        });
        const prefixClass = classNames({
            "configurator__prefix": true,
            [`configurator__prefix--${this.props.configuratorType}`]: true,
            "configurator__prefix--one-option": hasSingleOption,
            [`configurator__prefix--${prefixName}`]: true,
        });
        const selectClass = classNames({
            "configurator__select": true,
            "configurator__select--one-option": hasSingleOption,
            [`configurator__select--${this.props.configuratorType}`]: true,
            [`configurator__select--${prefixName}`]: true,
        });
        return {
            configuratorClass,
            prefixClass,
            selectClass,
        };
    }

    private getLevelMarketingCopy(option: string) {
        const levelMarketingCopy = getPageSetting("level-marketing-copy");
        if (levelMarketingCopy[option]) {
            return levelMarketingCopy[option].main_copy;
        }
        return getOptionValueHash(this.props.value);
    }

    private readonly onClosePanel = () => {
        const container =
            document.querySelector<HTMLElement>(".pdp-hero .configurator") ??
            document.querySelector<HTMLElement>(".product-hero__configurator");
        if (!container) {
            return;
        }
        container.classList.remove("overflow-hidden");

        document.body.style.overflowX = "hidden";
        setTimeout(() => {
            document.body.classList.remove("configurator-panel-open");
        }, 500);

        // `enableBodyScroll` should run before setState `isPanelOpen: false`,
        // so inline style `position: fixed` from `<body>` will be removed first before removing `.configurator-panel-open` from `<body>`.
        // This way can avoid the issue of the page jumping to the top when panel is opened/closed
        enableBodyScroll(this.panelContainerRef.current as HTMLElement);

        // TOOD: Remove the panel after the close animation is completed. Tie the animation variables into the component here
        this.setState({
            isPanelOpen: false,
            isEnterKeyDown: false,
            isClicked: false,
        });
    };

    private readonly onOpenPanel = () => {
        disableMainConfiguratorScrolling();
        this.setState(
            {
                isPanelOpen: true,
            },
            () => {
                if (window.innerWidth < MOBILE_WIDTH_BODY_SCROLL) {
                    disableBodyScroll(
                        this.panelContainerRef.current as HTMLElement,
                    );
                } else {
                    enableBodyScroll(
                        this.panelContainerRef.current as HTMLElement,
                    );
                }
            },
        );
    };

    private readonly onKeyboardDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (this.props.configuratorType !== ConfiguratorTypes.PANEL) {
            return;
        }
        this.changeLock = true;
        if (e.key === Key.Enter) {
            this.changeLock = false;
            this.setState({
                isEnterKeyDown: true,
            });
        }
    };

    private readonly onClick = () => {
        if (this.props.configuratorType !== ConfiguratorTypes.PANEL) {
            return;
        }

        this.setState({
            isEnterKeyDown: false,
            isClicked: true,
        });
    };

    render() {
        if (!this.props.rootProduct) {
            return null;
        }
        const inputTypes =
            this.props.rootProduct.attributes.product_option_input_types
                ?.value || {};
        const inputType: IOptionInputType =
            inputTypes[this.props.code] || "dropdown";

        const prefixAttr = this.props.variant
            ? this.props.variant.attributes[this.props.code]
            : "";
        const prefixName = prefixAttr
            ? prefixAttr.name.toLowerCase()
            : this.props.code.substr(
                  this.props.code.indexOf("_") + 1,
                  this.props.code.length,
              );
        let prefixNameCapped =
            prefixName[0].toUpperCase() + prefixName.substr(1);

        // Hardcode custom string override for tsi-sealy (Refs #21451)
        // 'Level' becomes 'Sealy® Technology'
        if (prefixName === "level") {
            prefixNameCapped = "Sealy® Technology";
        }

        const options = listPossibleOptionValues(
            this.props.code,
            this.props.rootProduct,
        );

        const { configuratorClass, prefixClass, selectClass } =
            this.getClassesNames(inputType, prefixName, options);
        const isPanel = this.props.configuratorType === ConfiguratorTypes.PANEL;

        const props = {
            configuratorType: this.props.configuratorType,
            selectorID: this.props.selectorID,
            rootProduct: this.props.rootProduct,
            variant: this.props.variant,
            code: this.props.code,
            value: this.props.value,
            options: options,
            prefixNameCapped: prefixNameCapped,
            configuratorClass: configuratorClass,
            prefixClass: prefixClass,
            selectClass: selectClass,
            onChange: this.onOptionChange,
            onKeyboardDown: this.onKeyboardDown,
            onClick: this.onClick,
            onClosePanel: this.onClosePanel,
            isPlpPanelStyle: this.props.isPlpPanelStyle,
        };

        const isClassic =
            this.props.configuratorType === ConfiguratorTypes.CLASSIC;
        const isMini = this.props.style === "mini";
        const showClassicStyle = isClassic || isMini;

        // Show a panel?
        if (isPanel) {
            if (props.code === "option_level") {
                return (
                    <>
                        <div ref={this.panelContainerRef}>
                            <CSSTransition
                                in={this.state.isPanelOpen}
                                timeout={500}
                                classNames="configurator__panel-"
                                unmountOnExit={true}
                            >
                                <ConfiguratorPanel
                                    chatLinkComponent={
                                        this.props.chatLinkComponent
                                    }
                                    liveChatHeader={this.props.liveChatHeader}
                                    panelClass={"option"}
                                    enableChat={this.props.enableChat}
                                    radioSelector={
                                        <LevelSelectionPanel
                                            isIcon={true}
                                            {...props}
                                            showPricing={
                                                inputType ===
                                                "radio-with-pricing"
                                            }
                                        />
                                    }
                                    {...props}
                                />
                            </CSSTransition>
                        </div>
                        <ConfiguratorPanelTrigger
                            prefixNameCapped={prefixNameCapped}
                            onOpenPanel={this.onOpenPanel}
                        >
                            {this.getLevelMarketingCopy(this.props.value)}
                        </ConfiguratorPanelTrigger>
                    </>
                );
            }
            return (
                <>
                    <div ref={this.panelContainerRef}>
                        <CSSTransition
                            in={this.state.isPanelOpen}
                            timeout={500}
                            classNames="configurator__panel-"
                            unmountOnExit={true}
                        >
                            <ConfiguratorPanel
                                chatLinkComponent={this.props.chatLinkComponent}
                                liveChatHeader={this.props.liveChatHeader}
                                panelClass={"option"}
                                enableChat={this.props.enableChat}
                                radioSelector={
                                    <RadioSelector
                                        isIcon={true}
                                        {...props}
                                        showPricing={
                                            inputType === "radio-with-pricing"
                                        }
                                    />
                                }
                                {...props}
                            />
                        </CSSTransition>
                    </div>
                    <ConfiguratorPanelTrigger
                        prefixNameCapped={prefixNameCapped}
                        onOpenPanel={this.onOpenPanel}
                    >
                        {getOptionValueHash(this.props.value)}
                    </ConfiguratorPanelTrigger>
                </>
            );
        }

        // Show the color selector style?
        if (showClassicStyle && prefixName === "color") {
            return <ColorSelector {...props} />;
        }

        // Show the classic selector style?
        if (showClassicStyle) {
            return <ClassicSelector {...props} />;
        }

        // Show the dropdown selector style?
        if (inputType === "dropdown" || inputType === "dropdown-with-pricing") {
            return (
                <DropdownSelector
                    {...props}
                    showPricing={inputType === "dropdown-with-pricing"}
                />
            );
        }

        // Default to the the radio-button selector style
        return <RadioSelector {...props} />;
    }
}
