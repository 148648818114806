import React from "react";
import { IBaseField } from "../models/formFields.interfaces";
import { IFormFieldState, AbstractFormField } from "./AbstractFormField";

export abstract class AbstractInput<
    FieldName extends string,
    IProps extends IBaseField<HTMLInputElement, FieldName>,
    IState extends IFormFieldState,
> extends AbstractFormField<FieldName, HTMLInputElement, IProps, IState> {
    protected updateValueAttribute(event: React.FormEvent<HTMLInputElement>) {
        this.value = event.currentTarget.value;
    }
}
