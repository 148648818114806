import memoize from "memoize-one";
import { t } from "ttag";
import {
    IOptionCode,
    IOptionValues,
    IOptionSingleValue,
    IProduct,
} from "../../models/catalogue.interfaces";
import { IProductCategoryID } from "../../models/nominals";
import { Product } from "../../models/catalogue";
import {
    SortType,
    getMasterSortList,
    listPossibleOptionValuesforProductSet,
} from "../../utils/sorting";
import {
    PointDifferenceRow,
    VectorSpaceDimension,
    VectorSpace,
} from "../../utils/similarity";
import { difference } from "../../utils/sets";
import { objectKeys, notEmpty, unique } from "../../utils/functional";
import { assertNever } from "../../utils/never";
import { STR_YES, STR_NO } from "../../constants";
import {
    IPLCProductCategorySelector,
    IPLCProductOptionSelector,
    IPLCMattressSizeOptionSelector,
    IPLCMattressFeelOptionSelector,
    IPLCOtherOptionSelector,
    IPLCBooleanOptionSelectorOption,
    IPLCOptionPanel,
} from "./models.interfaces";
import { ISelectedCategories } from "./reducers.interfaces";
import {
    PLCMattressSizeOptionSelector,
    PLCMattressFeelOptionSelector,
    PLCOtherOptionSelector,
    PLCBooleanOptionSelectorGroup,
    PLCBooleanOptionSelectorOption,
} from "./models";
import { getOptionValueByIdx } from "./utils";

export type DesiredSelection = {
    selectedCategoryIDs: IProductCategoryID[];
    optionValues: IOptionValues;
};

type DimensionEntity = IProduct | DesiredSelection;

type DimensionConfig = {
    name: string;
    attr: IOptionCode;
    showInDiff: boolean;
    options: IOptionSingleValue[];
};

type OptionSelector =
    | IPLCMattressSizeOptionSelector
    | IPLCMattressFeelOptionSelector
    | IPLCOtherOptionSelector
    | IPLCBooleanOptionSelectorOption;

type CategoryDimension = VectorSpaceDimension<
    DimensionEntity,
    IProductCategoryID
>;
type OptionDimension = VectorSpaceDimension<
    DimensionEntity,
    IOptionSingleValue
>;

type ProductVectorSpace = VectorSpace<DimensionEntity, number>;

interface ISelectorData {
    name: string;
    attr: IOptionCode;
    showInDiff: boolean;
}

/**
 * Get the product attribute code related to the given OptionSelector.
 */
const getSelectorData = (optSelector: OptionSelector): ISelectorData => {
    if (PLCMattressSizeOptionSelector.is(optSelector)) {
        return {
            name: t`Size`,
            attr: optSelector.value.attribute,
            showInDiff: optSelector.value.show_in_match_model,
        };
    }
    if (PLCMattressFeelOptionSelector.is(optSelector)) {
        return {
            name: t`Feel`,
            attr: optSelector.value.attribute,
            showInDiff: optSelector.value.show_in_match_model,
        };
    }
    if (PLCOtherOptionSelector.is(optSelector)) {
        return {
            name: optSelector.value.name,
            attr: optSelector.value.attribute,
            showInDiff: optSelector.value.show_in_match_model,
        };
    }
    if (PLCBooleanOptionSelectorOption.is(optSelector)) {
        return {
            name: optSelector.name,
            attr: optSelector.attribute,
            showInDiff: optSelector.show_in_match_model,
        };
    }
    throw assertNever(optSelector);
};

/**
 * For the given IPLCProductOptionSelector, return an array of config objects, which can be used to
 * construct Vector Space Dimensions for the selector.
 */
const buildDimensionConfigs = (
    optSelector: IPLCProductOptionSelector,
    variants: IProduct[],
): DimensionConfig[] => {
    let name: string, attr: IOptionCode, showInDiff: boolean;
    if (PLCBooleanOptionSelectorGroup.is(optSelector)) {
        const configs = optSelector.value.selectors.map<DimensionConfig>(
            (subSelector) => {
                ({ name, attr, showInDiff } = getSelectorData(subSelector));
                return {
                    name: name,
                    attr: attr,
                    showInDiff: showInDiff,
                    options: [STR_YES, STR_NO],
                };
            },
        );
        return configs;
    }
    ({ name, attr, showInDiff } = getSelectorData(optSelector));
    const masterSortList = getMasterSortList(SortType.DISPLAY);
    const options = (masterSortList[attr] || [])
        .concat(listPossibleOptionValuesforProductSet(attr, variants))
        .filter(notEmpty)
        .filter(unique);
    return [
        {
            name: name,
            attr: attr,
            showInDiff: showInDiff,
            options: options,
        },
    ];
};

/**
 * Construct and return a Vector Space Dimension for the given category selector.
 */
const buildCategoryDimension = (
    categorySelector: IPLCProductCategorySelector,
): CategoryDimension => {
    const options = categorySelector.value.options.map(
        (opt) => opt.category.id,
    );
    const categoryNames = new Map(
        categorySelector.value.options.map((opt) => [
            opt.category.id,
            opt.category.name,
        ]),
    );
    const dimension = new VectorSpaceDimension<
        DimensionEntity,
        IProductCategoryID
    >(
        categorySelector.value.name,
        options,
        (entity, opts) => {
            const categoryIDs = Product.is(entity)
                ? entity.category_ids
                : entity.selectedCategoryIDs;
            const overlappingIndexes = categoryIDs
                .filter((catID) => opts.includes(catID))
                .map((catID) => opts.indexOf(catID));
            if (overlappingIndexes.length <= 0) {
                return Math.floor(opts.length / 2);
            }
            return Math.min(...overlappingIndexes);
        },
        (opt) => {
            return categoryNames.get(opt) || "Unknown Category";
        },
    );
    return dimension;
};

/**
 * Construct and return a Vector Space Dimension for the given product option selector.
 */
const buildOptionDimension = ({
    name,
    attr,
    showInDiff,
    options,
}: DimensionConfig): OptionDimension => {
    const dimension = new VectorSpaceDimension<
        DimensionEntity,
        IOptionSingleValue
    >(
        name,
        options,
        (entity, opts) => {
            const rawValue = Product.is(entity)
                ? entity.attributes[attr]?.value
                : entity.optionValues[attr];
            const value = getOptionValueByIdx(rawValue, 0);
            return value && opts.includes(value) ? opts.indexOf(value) : 0;
        },
        (opt) => {
            if (opt === STR_YES) {
                return t`Yes`;
            }
            if (opt === STR_NO) {
                return t`No`;
            }
            return opt;
        },
        showInDiff,
    );
    return dimension;
};

/**
 * Flatten a list of root products into a list of all the variants.
 */
const listAllVariants = memoize((rootProducts: IProduct[]): IProduct[] => {
    return rootProducts.reduce<IProduct[]>(
        (memo, rootProduct) => memo.concat(rootProduct.children),
        [],
    );
});

/**
 * Construct and return category dimensions for all of the given category selectors.
 */
const buildCategoryDimensions = (
    categorySelectors: IPLCProductCategorySelector[],
): CategoryDimension[] => {
    return categorySelectors.map(buildCategoryDimension);
};

/**
 * Construct and return product attribute dimensions for all of the given option panels.
 */
const buildOptionDimensions = (
    optionPanels: IPLCOptionPanel[],
    rootProducts: IProduct[],
): OptionDimension[] => {
    const variants = listAllVariants(rootProducts);
    const dimensions = optionPanels
        .reduce<IPLCProductOptionSelector[]>((memo, panel) => {
            return memo.concat(panel.value.selectors);
        }, [])
        .reduce<DimensionConfig[]>((memo, optSelector) => {
            const configs = buildDimensionConfigs(optSelector, variants);
            return memo.concat(configs);
        }, [])
        .map(buildOptionDimension);
    return dimensions;
};

/**
 * Given the category and attribute selector configs, construct and return a Vector Space.
 */
const getVectorSpace = memoize(
    (
        categorySelectors: IPLCProductCategorySelector[],
        optionPanels: IPLCOptionPanel[],
        rootProducts: IProduct[],
    ): ProductVectorSpace => {
        const dimensions = [
            ...buildCategoryDimensions(categorySelectors),
            ...buildOptionDimensions(optionPanels, rootProducts),
        ];
        const space = new VectorSpace(dimensions);
        return space;
    },
);

const getForcedMatchSelections = ({
    selectedCategories,
    optionValues,
    previouslySelectedCategories,
    previouslySelectedOptionValues,
}: {
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
    previouslySelectedCategories: ISelectedCategories;
    previouslySelectedOptionValues: IOptionValues;
}): {
    categories: Set<IProductCategoryID>;
    options: IOptionValues;
} | null => {
    // When trying to find the most similar variant, force a perfect match on whichever
    // attribute/category/option was most recently changed.
    if (!previouslySelectedCategories) {
        return null;
    }
    // Find the newly selected categories
    const prevCategories = new Set(Object.values(previouslySelectedCategories));
    const currCategories = new Set(Object.values(selectedCategories));
    const changedCategories = difference(currCategories, prevCategories);
    // Find the newly changed options
    const changedOpts: IOptionValues = {};
    for (const opt of objectKeys(optionValues)) {
        const prevOpt = previouslySelectedOptionValues[opt];
        const currOpt = optionValues[opt];
        if (currOpt !== prevOpt) {
            changedOpts[opt] = currOpt;
        }
    }
    return {
        categories: changedCategories,
        options: changedOpts,
    };
};

const getSelectableVariants = ({
    rootProducts,
    selectedCategories,
    optionValues,
    previouslySelectedCategories,
    previouslySelectedOptionValues,
}: {
    rootProducts: IProduct[];
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
    previouslySelectedCategories: ISelectedCategories;
    previouslySelectedOptionValues: IOptionValues;
}): IProduct[] => {
    // When trying to find the most similar variant, force a perfect match on whichever
    // attribute/category/option was most recently changed.
    const allVariants = listAllVariants(rootProducts);
    let variants = allVariants;
    const forcedMatchFacets = getForcedMatchSelections({
        selectedCategories,
        optionValues,
        previouslySelectedCategories,
        previouslySelectedOptionValues,
    });
    if (!forcedMatchFacets) {
        return variants;
    }
    // Filter by category
    for (const catID of forcedMatchFacets.categories) {
        variants = variants.filter((v) => v.category_ids.includes(catID));
    }
    // Filter by option
    for (const optKey of objectKeys(forcedMatchFacets.options)) {
        const optVal = forcedMatchFacets.options[optKey];
        variants = variants.filter(
            (v) => v.attributes[optKey]?.value === optVal,
        );
    }
    if (variants.length <= 0) {
        return allVariants;
    }
    return variants;
};

const getDesiredSelection = ({
    selectedCategories,
    optionValues,
}: {
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
}): DesiredSelection => {
    return {
        selectedCategoryIDs: Object.values(selectedCategories),
        optionValues: optionValues,
    };
};

export const getMostSimilarProduct = ({
    categorySelectors,
    optionPanels,
    rootProducts,
    selectedCategories,
    optionValues,
    previouslySelectedCategories,
    previouslySelectedOptionValues,
}: {
    categorySelectors: IPLCProductCategorySelector[];
    optionPanels: IPLCOptionPanel[];
    rootProducts: IProduct[];
    selectedCategories: ISelectedCategories;
    optionValues: IOptionValues;
    previouslySelectedCategories: ISelectedCategories;
    previouslySelectedOptionValues: IOptionValues;
}): {
    betterProductExistsButIsUnavailable: boolean;
    distance: number;
    bestProduct: IProduct;
    difference: PointDifferenceRow[];
} | null => {
    const variants = getSelectableVariants({
        rootProducts,
        selectedCategories,
        optionValues,
        previouslySelectedCategories,
        previouslySelectedOptionValues,
    });
    if (variants.length <= 0) {
        return null;
    }
    const desire = getDesiredSelection({
        selectedCategories,
        optionValues,
    });
    const space = getVectorSpace(categorySelectors, optionPanels, rootProducts);
    const sortedVariants = space.sortEntityList(desire, variants);

    const sortedInStockVariants = sortedVariants.filter(
        ([_dist, variant]) => variant.availability.is_available_to_buy,
    );
    const [bestDist] = sortedVariants[0];
    const [bestInStockDist, bestInStockProduct] = sortedInStockVariants[0];
    return {
        betterProductExistsButIsUnavailable: bestDist < bestInStockDist,
        distance: bestInStockDist,
        bestProduct: bestInStockProduct,
        difference: space.describeDifference(desire, sortedVariants[0][1]),
    };
};
