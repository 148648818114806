import React from "react";
import { t } from "ttag";
import { loadOrderCancellationInfo, cancelOrder } from "../../../api/orders";
import { eventuallySendMetric } from "../../../api/metrics";
import { IOrderID } from "../../../models/nominals";
import { OrderCancellationInfo } from "../../../models/orders";
import { CancelModalViewState, OTHER_OPTION } from "../models";
import { CancelModal } from "../elements/CancelModal";

interface IProps {
    orderID: IOrderID;
}

interface IState {
    viewState: CancelModalViewState;
    cancellationInfo: OrderCancellationInfo | null;
    selectedReason: string | null;
    enteredReason: string;
}

export class OrderCancelButton extends React.Component<IProps, IState> {
    public readonly state: IState = {
        viewState: CancelModalViewState.CLOSED,
        cancellationInfo: null,
        selectedReason: null,
        enteredReason: "",
    };

    private readonly onCancelButtonClick = async () => {
        // Set state to loading to disable the open button
        this.setState({
            viewState: CancelModalViewState.LOADING,
        });
        // Record an event that the user has opened the cancel modal.
        eventuallySendMetric({
            name: "OrderCancelButton.open-modal",
            dimensions: [],
            type: "BOOLEAN",
            value: "TRUE",
        });
        // Load the cancellation info and use it to open the reason form.
        try {
            const data = await loadOrderCancellationInfo(this.props.orderID);
            this.setState({
                viewState: data.can_be_cancelled
                    ? CancelModalViewState.SELECT_REASON
                    : CancelModalViewState.ERROR,
                cancellationInfo: data,
            });
        } catch (e) {
            this.setState({
                viewState: CancelModalViewState.ERROR,
            });
        }
    };

    private readonly onRequestClose = () => {
        this.setState({
            viewState: CancelModalViewState.CLOSED,
        });
    };

    private readonly onSelectReason = (reason: string) => {
        this.setState({
            selectedReason: reason,
            enteredReason: "",
        });
    };

    private readonly onEnterReason = (reason: string) => {
        this.setState({
            selectedReason: OTHER_OPTION,
            enteredReason: reason,
        });
    };

    private readonly onSubmit = async () => {
        this.setState({
            viewState: CancelModalViewState.SAVING,
        });
        // Record an event that the user has opened the cancel modal.
        eventuallySendMetric({
            name: "OrderCancelButton.cancel-order",
            dimensions: [],
            type: "BOOLEAN",
            value: "TRUE",
        });
        // Cancel the order
        const reason = this.getReason();
        try {
            await cancelOrder(this.props.orderID, reason);
            this.setState({
                viewState: CancelModalViewState.CLOSED,
            });
            // Reload the page so that the new order status is shown.
            window.location.reload();
        } catch (e) {
            this.setState({
                viewState: CancelModalViewState.ERROR,
            });
        }
    };

    private getReason(): string {
        const reason =
            this.state.selectedReason === OTHER_OPTION
                ? this.state.enteredReason
                : this.state.selectedReason;
        return reason || "";
    }

    render() {
        return (
            <>
                <button
                    onClick={this.onCancelButtonClick}
                    disabled={
                        this.state.viewState !== CancelModalViewState.CLOSED
                    }
                    type="button"
                    className="button"
                >
                    {t`Cancel Order`}
                </button>
                <CancelModal
                    viewState={this.state.viewState}
                    cancellationInfo={this.state.cancellationInfo}
                    selectedReason={this.state.selectedReason}
                    enteredReason={this.state.enteredReason}
                    onSelectReason={this.onSelectReason}
                    onEnterReason={this.onEnterReason}
                    onRequestClose={this.onRequestClose}
                    onSubmit={this.onSubmit}
                />
            </>
        );
    }
}
