import React from "react";
import { connect } from "react-redux";
import { ISafeHTML, IWebPageURL } from "../../../models/nominals";
import { Loaders } from "../loaders";
import { Dispatchers } from "../dispatchers";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import {
    IAttributeOptionGroup,
    ICompareTile,
} from "../../../models/product-compare.interfaces";
import { IViewport } from "../../../models/screen.interfaces";
import { ProductCompareGrid as ProductCompareGridComponent } from "../components/ProductCompareGrid";
import { notEmpty } from "../../../utils/functional";
import { trackCategoryPageView } from "../../../utils/analytics";
import { getSelectedVariant } from "../selectors";
import { ProductCompareTheme } from "../../../constants";
import { rootProductSelector } from "../../configurator/selectors";
import { IProduct } from "../../../models/catalogue.interfaces";

interface IOwnProps {
    header: ISafeHTML;
    basketLink: IWebPageURL;
    financingLink: IWebPageURL;
    preselectedSize: string | null;
    sizeAttributeOptionGroup: IAttributeOptionGroup | null;
    tiles: ICompareTile[];
    theme: ProductCompareTheme;
    showFirstAvailablePrice?: boolean;
    showRating?: boolean;
    showStartingAt?: boolean;
    isMinimal?: boolean;
}

interface IReduxProps {
    priceSelectValue: string;
    viewport: IViewport;
    rootProduct: IProduct | null;
    rootProducts: IProduct[];
}

interface IDispatchProps {
    loaders: Loaders;
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IDispatchProps, IReduxProps {}

interface IState {}

class ProductCompareGridContainer extends React.Component<IProps, IState> {
    private trackCategoryView() {
        if (!this.props.tiles) {
            return;
        }
        // Obtain SKUs for current variants
        const skus = this.props.tiles
            .map((tile) => {
                const variant = getSelectedVariant(
                    tile,
                    this.props.priceSelectValue,
                );
                return variant ? variant.skus[0] : null;
            })
            .filter(notEmpty);
        trackCategoryPageView(skus);
    }

    componentDidUpdate() {
        this.trackCategoryView();
    }

    render() {
        const urlParams = new URLSearchParams(window.location.search);
        const pageRootProduct = this.props.rootProduct
            ? this.props.rootProduct.id
            : null;
        return (
            <ProductCompareGridComponent
                header={this.props.header}
                tiles={this.props.tiles}
                basketLink={this.props.basketLink}
                financingLink={this.props.financingLink}
                sizeAttributeOptionGroup={this.props.sizeAttributeOptionGroup}
                preselectedSize={this.props.preselectedSize || null}
                priceSelectValue={this.props.priceSelectValue}
                pageRootProduct={pageRootProduct}
                theme={this.props.theme}
                urlParams={urlParams}
                viewport={this.props.viewport}
                showFirstAvailablePrice={this.props.showFirstAvailablePrice}
                showRating={this.props.showRating}
                showStartingAt={this.props.showStartingAt}
                setRootProducts={this.props.dispatchers.setRootProducts}
                rootProducts={this.props.rootProducts}
                isMinimal={this.props.isMinimal}
            />
        );
    }
}

const mapStateToProps: TStateMapper<
    "productcompare" | "configurator",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    const state = rootState.productcompare || defaults;
    const rootProduct = rootProductSelector(rootState.configurator);

    return {
        ...ownProps,
        viewport: rootState.common.viewport,
        priceSelectValue: state.priceSelectValue,
        rootProduct: rootProduct,
        rootProducts: state.rootProducts,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const loaders = new Loaders(dispatchers);

    return {
        dispatchers: dispatchers,
        loaders: loaders,
    };
};

export const ProductCompareGrid = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCompareGridContainer);
