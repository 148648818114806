import React from "react";
import { t } from "ttag";
import { connect } from "react-redux";
import { IRetailStoreWithDistance } from "../../../models/location.interfaces";
import { Link } from "../../../common/Link";
import { MapDirectionsLink } from "../../../common/MapDirectionsLink";
import { RetailStoreOpenStatus } from "../../../common/RetailStoreOpenStatus";
import { TStateMapper } from "../../reducers.interfaces";
import {
    myStoreSelector,
    storeSelector,
    focusedStoreIDSelector,
} from "../../retail/selectors";
import { formatDistanceWithSuffix } from "../../retail/helpers";
import { ISyncStoreID } from "../../../models/nominals";
import { getSelectedStore, getAddress } from "../../retail/helpers";
import SVG from "react-inlinesvg";
import styles from "./RetailChannel.module.scss";
import iconPin from "../../../../img/icons/pin.svg";

interface IOwnProps {}

interface IReduxProps {
    closestStore: IRetailStoreWithDistance | null;
    selectedStoreID: ISyncStoreID | null;
    stores: IRetailStoreWithDistance[];
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class RetailChannelComponent extends React.Component<IProps, IState> {
    private readonly formatAddress = (
        selectedStore: IRetailStoreWithDistance,
    ) => {
        if (!selectedStore) {
            return null;
        }
        const parts = getAddress(selectedStore);
        return <address>{parts}</address>;
    };

    render() {
        if (!this.props.closestStore) {
            return null;
        }
        const selectedStore = getSelectedStore(
            this.props.closestStore,
            this.props.selectedStoreID,
            this.props.stores,
        );

        if (!selectedStore) {
            return null;
        }
        return (
            <div className={styles.root}>
                <p className={styles.storeName}>{selectedStore.name}</p>
                <a className={styles.phone} href={`tel:${selectedStore.phone}`}>
                    {selectedStore.phone}
                </a>
                {this.formatAddress(selectedStore)}
                <RetailStoreOpenStatus store={selectedStore} />
                <Link
                    className={styles.storeDetailsLink}
                    href={selectedStore.web}
                    target="_blank"
                >
                    {t`Store Details`}
                </Link>
                <div className={styles.distanceContainer}>
                    <div>
                        <SVG
                            src={iconPin}
                            className={styles.distancePin}
                            title={t`Distance to ${selectedStore.name}`}
                            aria-hidden="false"
                        />
                        <p>
                            {formatDistanceWithSuffix(selectedStore.distance)}
                        </p>
                    </div>
                    <MapDirectionsLink
                        addressParts={[
                            selectedStore.name,
                            selectedStore.address,
                            selectedStore.address2,
                            selectedStore.city,
                            selectedStore.state,
                            selectedStore.postal,
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<
    "configurator" | "retail",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    return {
        closestStore: myStoreSelector(rootState),
        selectedStoreID: focusedStoreIDSelector(rootState),
        stores: storeSelector(rootState),
        // Direct Props
        ...ownProps,
    };
};

export const RetailChannel = connect(mapStateToProps)(RetailChannelComponent);
