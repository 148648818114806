import { Dispatch } from "@reduxjs/toolkit";
import * as addressAPI from "../../api/address";
import * as basketAPI from "../../api/basket";
import { ajax, CSRF_HEADER, getCSRFToken } from "../../utils/ajax";
import * as signals from "../signals";
import { Action } from "./constants";
import {
    IBasket,
    ICustomerSummary,
    ICustomerDetails,
    IVoucher,
} from "./models.interfaces";
import {
    IActionSetCountries,
    IActionSetCustomerSearchResults,
    IActionSetBasketSearchResults,
    IActionSetBasketSearchErrors,
    IActionSetCurrentlyAssistedCustomer,
    IActionSetCurrentlyAssistedBasket,
    IActionSetVoucherSearchResults,
    IActionSetVoucherSearchErrors,
    IActionClearVoucherSearch,
    IActionViewStackClear,
    IActionViewStackSetRoot,
    IActionViewStackPush,
    IActionViewStackPop,
    IActionViewStackReplaceTop,
    IAction,
} from "./reducers.interfaces";

interface ICustomerSearchParams {
    page?: number;
    text?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    username?: string;
}

interface IBasketLookupParams {
    q: string;
}

export class Actions {
    dispatch: Dispatch<IAction>;

    constructor(dispatch: Dispatch<IAction>) {
        this.dispatch = dispatch;
    }

    public readonly loadCountries = async () => {
        const countries = await addressAPI.loadCountries();
        this.dispatch<IActionSetCountries>({
            type: Action.SET_COUNTRIES,
            countries: countries,
        });
    };

    public readonly searchCustomers = async (text: string, page: number) => {
        const query: ICustomerSearchParams = {
            page: page || 1,
        };
        // Try to tokenize the query string into various fields. Then fallback the rest of the query into the generic `text` field.
        const fields = [
            /(first_name):([^\s]+)/,
            /(last_name):([^\s]+)/,
            /(email):([^\s]+)/,
            /(username):([^\s]+)/,
        ];
        fields.forEach((pattern) => {
            const match = text.match(pattern);
            if (!match) {
                return;
            }
            const field = match[1] as
                | "first_name"
                | "last_name"
                | "email"
                | "username";
            const value = match[2];
            query[field] = value;
            text = text.replace(match[0], "");
        });
        if (text) {
            query.text = text.trim();
        }
        const resp = await ajax.get("/api/csr/users/search/").query(query);
        this.dispatch<IActionSetCustomerSearchResults>({
            type: Action.SET_CUSTOMER_SEARCH_RESULTS,
            total: resp.body.count,
            page: query.page || 1,
            hasNext: !!resp.body.next,
            hasPrev: !!resp.body.previous,
            customers: resp.body.results,
        });
    };

    public readonly lookupBasket = async (reference_number: string) => {
        const query: IBasketLookupParams = {
            q: reference_number,
        };
        try {
            const basket = (
                await ajax.get("/api/csr/baskets/lookup/").query(query)
            ).body as IBasket;
            const owner = (await ajax.get(basket.owner))
                .body as ICustomerDetails;
            this.dispatch<IActionSetBasketSearchResults>({
                type: Action.SET_BASKET_SEARCH_RESULTS,
                basket: basket,
                owner: owner,
            });
        } catch (e) {
            this.dispatch<IActionSetBasketSearchErrors>({
                type: Action.SET_BASKET_SEARCH_ERRORS,
                error: e.response && e.response.body ? e.response.body.q : "",
            });
        }
    };

    public readonly loadCurrentlyAssistedCustomer = async () => {
        const resp = await ajax.get("/api/csr/users/currently_assisted_user/");
        this.dispatch<IActionSetCurrentlyAssistedCustomer>({
            type: Action.SET_CURRENTLY_ASSISTED_CUSTOMER,
            user: resp.body as ICustomerSummary,
        });
    };

    public readonly loadCurrentlyAssistedBasket = async () => {
        const resp = await ajax.get(
            "/api/csr/baskets/currently_assisted_basket/",
        );
        this.dispatch<IActionSetCurrentlyAssistedBasket>({
            type: Action.SET_CURRENTLY_ASSISTED_BASKET,
            basket: resp.body as IBasket,
        });
    };

    public readonly startAssistingCustomer = async (userURL: string) => {
        signals.csr.onBeforeStartAssistingCustomer.emit();
        await ajax
            .post(`${userURL}begin_assisting/`)
            .set(CSRF_HEADER, await getCSRFToken());
        await Promise.all([
            this.loadCurrentlyAssistedCustomer(),
            this.loadCurrentlyAssistedBasket(),
        ]);
        signals.csr.onAfterStartAssistingCustomer.emit();
    };

    public readonly stopAssistingCustomer = async (userURL: string) => {
        signals.csr.onBeforeStopAssistingCustomer.emit();
        await ajax
            .post(`${userURL}stop_assisting/`)
            .set(CSRF_HEADER, await getCSRFToken());
        await Promise.all([
            this.loadCurrentlyAssistedCustomer(),
            this.loadCurrentlyAssistedBasket(),
        ]);
        signals.csr.onAfterStopAssistingCustomer.emit();
    };

    public readonly freezeBasket = async (basketID: number) => {
        await ajax
            .post(`/api/csr/baskets/${basketID}/freeze/`)
            .set(CSRF_HEADER, await getCSRFToken());
    };

    public readonly assignBasket = async (basketID: number, email: string) => {
        signals.csr.onBeforeAssignBasket.emit();
        await ajax
            .post(`/api/csr/baskets/${basketID}/assign/`)
            .set(CSRF_HEADER, await getCSRFToken())
            .send({
                email: email,
            });
        signals.csr.onAfterAssignBasket.emit();
    };

    public readonly startNewBasket = async () => {
        signals.csr.onBeforeStartNewBasket.emit();
        const basketOld = await basketAPI.load();
        await this.freezeBasket(basketOld.id);
        const basketNew = await basketAPI.load();
        signals.csr.onAfterStartNewBasket.emit(basketNew);
    };

    public readonly searchVoucherCode = async (code: string) => {
        this.dispatch<IActionClearVoucherSearch>({
            type: Action.CLEAR_VOUCHER_SEARCH,
        });
        try {
            const resp = await ajax.get("/api/csr/vouchers/").query({
                code: code,
            });
            this.dispatch<IActionSetVoucherSearchResults>({
                type: Action.SET_VOUCHER_SEARCH_RESULTS,
                payload: resp.body,
            });
        } catch (e) {
            this.dispatch<IActionSetVoucherSearchErrors>({
                type: Action.SET_VOUCHER_SEARCH_ERRORS,
                payload: e.response.body,
            });
        }
    };

    public readonly deleteVoucherCode = async (voucher: IVoucher) => {
        await ajax.delete(voucher.url).set(CSRF_HEADER, await getCSRFToken());
        await this.searchVoucherCode(voucher.code);
    };

    public readonly clearViewStack = () => {
        this.dispatch<IActionViewStackClear>({
            type: Action.VIEW_STACK_CLEAR,
        });
    };

    public readonly setViewStackRoot = (
        name: string,
        component: JSX.Element,
    ) => {
        this.dispatch<IActionViewStackSetRoot>({
            type: Action.VIEW_STACK_SET_ROOT,
            name: name,
            component: component,
        });
    };

    public readonly pushViewStack = (name: string, component: JSX.Element) => {
        this.dispatch<IActionViewStackPush>({
            type: Action.VIEW_STACK_PUSH,
            name: name,
            component: component,
        });
    };

    public readonly popViewStack = () => {
        this.dispatch<IActionViewStackPop>({
            type: Action.VIEW_STACK_POP,
        });
    };

    public readonly replaceTopView = (name: string, component: JSX.Element) => {
        this.dispatch<IActionViewStackReplaceTop>({
            type: Action.VIEW_STACK_REPLACE_TOP,
            name: name,
            component: component,
        });
    };

    public readonly setCustomerSearchTerm = (term: string) => {
        this.dispatch({
            type: Action.SET_CUSTOMER_SEARCH_TERM,
            term: term,
        });
    };

    public readonly setBasketSearchTerm = (term: string) => {
        this.dispatch({
            type: Action.SET_BASKET_SEARCH_TERM,
            term: term,
        });
    };
}
