import { constants } from "@reactivated/constants";

export const HousingStatus =
    constants["tsicommon.financing.constants.HousingStatus"];

export enum FinancingAppStatus {
    DENIED = "denied",
    PENDING = "pending",
}

export enum FinancingAppType {
    INDIVIDUAL = "I",
    JOINT = "J",
}

export enum ModalStateType {
    CLOSED = "CLOSED",

    PREQUAL_INITIAL = "PREQUAL_INITIAL",
    PREQUAL_SUBMITTING = "PREQUAL_SUBMITTING",
    PREQUAL_ERROR = "PREQUAL_ERROR",
    PREQUAL_APPROVED = "PREQUAL_APPROVED",
    PREQUAL_DENIED = "PREQUAL_DENIED",
    PREQUAL_EXISTING_CARDHOLDER = "PREQUAL_EXISTING_CARDHOLDER",

    PRESCREEN_APPROVED = "PRESCREEN_APPROVED",

    APPLY_INITIAL = "APPLY_INITIAL",
    APPLY_SUBMITTING = "APPLY_SUBMITTING",
    APPLY_ERROR = "APPLY_ERROR",
    APPLY_NEXT_BACKEND = "APPLY_NEXT_BACKEND",
    APPLY_APPROVED = "APPLY_APPROVED",
    APPLY_PENDING = "APPLY_PENDING",
    APPLY_DENIED = "APPLY_DENIED",
    APPLY_EXISTING_CARDHOLDER = "APPLY_EXISTING_CARDHOLDER",
}

export const STATE_CHOICES = [
    {
        label: "Alabama",
        value: "AL",
    },
    {
        label: "Alaska",
        value: "AK",
    },
    {
        label: "American Samoa",
        value: "AS",
    },
    {
        label: "Arizona",
        value: "AZ",
    },
    {
        label: "Arkansas",
        value: "AR",
    },
    {
        label: "Armed Forces Americas",
        value: "AA",
    },
    {
        label: "Armed Forces Europe",
        value: "AE",
    },
    {
        label: "Armed Forces Pacific",
        value: "AP",
    },
    {
        label: "California",
        value: "CA",
    },
    {
        label: "Colorado",
        value: "CO",
    },
    {
        label: "Connecticut",
        value: "CT",
    },
    {
        label: "Delaware",
        value: "DE",
    },
    {
        label: "District of Columbia",
        value: "DC",
    },
    {
        label: "Florida",
        value: "FL",
    },
    {
        label: "Georgia",
        value: "GA",
    },
    {
        label: "Guam",
        value: "GU",
    },
    {
        label: "Hawaii",
        value: "HI",
    },
    {
        label: "Idaho",
        value: "ID",
    },
    {
        label: "Illinois",
        value: "IL",
    },
    {
        label: "Indiana",
        value: "IN",
    },
    {
        label: "Iowa",
        value: "IA",
    },
    {
        label: "Kansas",
        value: "KS",
    },
    {
        label: "Kentucky",
        value: "KY",
    },
    {
        label: "Louisiana",
        value: "LA",
    },
    {
        label: "Maine",
        value: "ME",
    },
    {
        label: "Maryland",
        value: "MD",
    },
    {
        label: "Massachusetts",
        value: "MA",
    },
    {
        label: "Michigan",
        value: "MI",
    },
    {
        label: "Minnesota",
        value: "MN",
    },
    {
        label: "Mississippi",
        value: "MS",
    },
    {
        label: "Missouri",
        value: "MO",
    },
    {
        label: "Montana",
        value: "MT",
    },
    {
        label: "Nebraska",
        value: "NE",
    },
    {
        label: "Nevada",
        value: "NV",
    },
    {
        label: "New Hampshire",
        value: "NH",
    },
    {
        label: "New Jersey",
        value: "NJ",
    },
    {
        label: "New Mexico",
        value: "NM",
    },
    {
        label: "New York",
        value: "NY",
    },
    {
        label: "North Carolina",
        value: "NC",
    },
    {
        label: "North Dakota",
        value: "ND",
    },
    {
        label: "Northern Mariana Islands",
        value: "MP",
    },
    {
        label: "Ohio",
        value: "OH",
    },
    {
        label: "Oklahoma",
        value: "OK",
    },
    {
        label: "Oregon",
        value: "OR",
    },
    {
        label: "Pennsylvania",
        value: "PA",
    },
    {
        label: "Puerto Rico",
        value: "PR",
    },
    {
        label: "Rhode Island",
        value: "RI",
    },
    {
        label: "South Carolina",
        value: "SC",
    },
    {
        label: "South Dakota",
        value: "SD",
    },
    {
        label: "Tennessee",
        value: "TN",
    },
    {
        label: "Texas",
        value: "TX",
    },
    {
        label: "Utah",
        value: "UT",
    },
    {
        label: "Vermont",
        value: "VT",
    },
    {
        label: "Virgin Islands",
        value: "VI",
    },
    {
        label: "Virginia",
        value: "VA",
    },
    {
        label: "Washington",
        value: "WA",
    },
    {
        label: "West Virginia",
        value: "WV",
    },
    {
        label: "Wisconsin",
        value: "WI",
    },
    {
        label: "Wyoming",
        value: "WY",
    },
];

export const STATE_CHOICES_WITH_SELECT_LABEL = [
    {
        label: "-- Select State --",
        value: "",
    },
].concat(STATE_CHOICES);
