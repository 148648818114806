import { createSelector } from "@reduxjs/toolkit";
import { ILocation } from "../../models/location.interfaces";
import { notEmpty } from "../../utils/functional";
import { IRootState } from "../reducers.interfaces";
import { storeSelector } from "../retail/selectors";
import { milesToMeters } from "../../utils/maps";
import { isoMiles } from "../../models/nominals";

/**
 * Return the most preferred search location for the user
 */
export const preferredLocationSelector = (
    state: Pick<IRootState, "common">,
): ILocation | null => {
    if (state.common.enteredLocation) {
        return state.common.enteredLocation;
    }
    if (
        state.common.detectedLocation &&
        state.common.detectedLocation.lat &&
        state.common.detectedLocation.lon
    ) {
        let formattedAddr = "";
        if (
            state.common.detectedLocation.city &&
            state.common.detectedLocation.region
        ) {
            formattedAddr = `${state.common.detectedLocation.city}, ${state.common.detectedLocation.region}`;
        } else if (state.common.detectedLocation.city) {
            formattedAddr = state.common.detectedLocation.city;
        } else if (state.common.detectedLocation.region) {
            formattedAddr = state.common.detectedLocation.region;
        }
        return {
            formatted_address: formattedAddr,
            lat: state.common.detectedLocation.lat,
            lng: state.common.detectedLocation.lon,
            zip: state.common.detectedLocation.zip || undefined,
        };
    }
    return null;
};

const getAddressComponentMap = (
    place: google.maps.places.PlaceResult | google.maps.GeocoderResult,
) => {
    const addrComponents = place.address_components || [];
    const components = new Map<string, google.maps.GeocoderAddressComponent>();
    for (const component of addrComponents) {
        for (const cType of component.types) {
            components.set(cType, component);
        }
    }
    return components;
};

export const getFormattedAddressForPlace = (
    place: google.maps.places.PlaceResult | google.maps.GeocoderResult,
    long = false,
): string => {
    if (!place.address_components || long) {
        let addr = place.formatted_address || "";
        addr = addr.replace(/,\s[A-Z]+$/, ""); // Trim country off the end
        addr = addr.replace(/\b([0-9]{5})-([0-9]{4})\b/, "$1"); // Convert 9-digit ZIP code to 5 digit
        return addr;
    }

    // Build map of address components
    const components = getAddressComponentMap(place);

    // Loop through a few possible patterns to use the best one for the data we have
    const patterns = [
        ["postal_code"], // 12589
        ["sublocality", "administrative_area_level_1"], // Brooklyn, NY
        ["locality", "administrative_area_level_1"], // Wallkill, NY
        ["administrative_area_level_1"], // NY
    ];
    for (const pattern of patterns) {
        const parts = pattern
            .map((cType) => components.get(cType))
            .filter(notEmpty)
            .map((component) => component.short_name);
        // Check if all the required parts were there, if so, use this pattern
        if (parts.length === pattern.length) {
            return parts.join(", ");
        }
    }

    // Fallback to the formatted address google gave us
    return place.formatted_address || "";
};

export const getPostCodeForPlace = (
    place: google.maps.places.PlaceResult | google.maps.GeocoderResult,
): string | null => {
    const components = getAddressComponentMap(place);
    const postCode = components.get("postal_code");
    return postCode?.short_name || null;
};

/**
 * Return the currently loaded stores within 50 miles
 */
export const nearbyStoreSelector = createSelector(storeSelector, (stores) => {
    const MAX_DISTANCE = milesToMeters(isoMiles.wrap(50));
    return stores.filter((store) => {
        return store.distance <= MAX_DISTANCE;
    });
});
