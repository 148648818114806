import { captureException } from "@sentry/browser";
import URL from "url-parse";
import * as addressAPI from "../../api/address";
import * as basketAPI from "../../api/basket";
import * as checkoutAPI from "../../api/checkout";
import * as financingAPI from "../../api/financing";
import * as productAPI from "../../api/products";
import * as wishlistsAPI from "../../api/wishlists";
import * as userAPI from "../../api/user";
import { objectKeys } from "../../utils/functional";
import {
    IAddress,
    IShippingAddress,
    IBillingAddress,
    IBillingAddressField,
} from "../../models/address.interfaces";
import { IProductAPIURL, isoProductAPIURL } from "../../models/nominals";
import { IBasket } from "../../models/catalogue.interfaces";
import { IPayment } from "./reducers.interfaces";
import { filterFinancingPlans, sortFinancingPlans } from "./utils";
import { Dispatchers } from "./dispatchers";
import { Step, LIST_PREVIOUSLY_IN_BASKET, LIST_FAVORITES } from "./constants";
import { defaults } from "./defaults";

export class Loaders {
    private readonly dispatchers: Dispatchers;

    constructor(dispatchers: Dispatchers) {
        this.dispatchers = dispatchers;
    }

    public readonly loadBasket = async () => {
        // Load the basket
        const loadingBasket = basketAPI.load();
        // Load wishlist lines, but allow this to fail gracefully
        const loadingPrevious = wishlistsAPI
            .listLines(LIST_PREVIOUSLY_IN_BASKET)
            .catch((err) => {
                captureException(err);
                return [];
            });
        // Load Financing plans, but allow this to fail gracefully
        const loadingPlans = financingAPI.listFinancingPlans().catch((err) => {
            captureException(err);
            return [];
        });
        console.debug("Loading basket...");
        const [basket, previousItemsList, financingPlans] = await Promise.all([
            loadingBasket,
            loadingPrevious,
            loadingPlans,
        ]);
        this.dispatchers.updateBasket(basket);
        this.dispatchers.updateWishlist(
            LIST_PREVIOUSLY_IN_BASKET,
            previousItemsList,
        );
        const sortedPlans = sortFinancingPlans(
            filterFinancingPlans(financingPlans),
        );
        this.dispatchers.setFinancingPlans(sortedPlans);
        await this.loadFavorites();
        console.debug("Loaded basket.");
        return basket;
    };

    public readonly loadBasketIfOutdated = async (
        currentBasket: IBasket | null,
    ) => {
        if (
            !currentBasket ||
            !currentBasket.id ||
            !currentBasket.last_modified_timestamp
        ) {
            return {
                isOutdated: true,
                basket: await this.loadBasket(),
            };
        }
        const data = await basketAPI.getLastModifiedTimestamp();
        if (!data || !data.last_modified_timestamp) {
            return {
                isOutdated: false,
                basket: currentBasket,
            };
        }
        const currentTimestamp = new Date(
            currentBasket.last_modified_timestamp,
        ).getTime();
        const newTimestamp = new Date(data.last_modified_timestamp).getTime();
        const idsMatch = currentBasket.id === data.id;
        const isOutdated = currentTimestamp < newTimestamp;
        if (currentTimestamp && newTimestamp && idsMatch && !isOutdated) {
            return {
                isOutdated: false,
                basket: currentBasket,
            };
        }
        return {
            isOutdated: true,
            basket: await this.loadBasket(),
        };
    };

    public readonly loadFavorites = async () => {
        const favoritesList = await wishlistsAPI
            .listLines(LIST_FAVORITES)
            .catch((err) => {
                captureException(err);
                return [];
            });
        this.dispatchers.updateWishlist(LIST_FAVORITES, favoritesList);
    };

    public readonly loadConcreteBundles = async (
        productURL: IProductAPIURL,
    ) => {
        // Make sure we're dealing with a relative URL, not a FQDN. Relative URLs will
        // default to the current page domain, thereby avoiding CORS issues when on the
        // admin site and the API returns a URL to the public site. We choose to fix this
        // CORS issue here instead of by adding site domain to the product API cache key
        // since that would significantly decrease the cache density.
        const parsedProductURL = URL(isoProductAPIURL.unwrap(productURL));
        const bundles = await productAPI.listConcreteBundles(
            isoProductAPIURL.wrap(parsedProductURL.pathname),
        );
        bundles.forEach((bundle) => {
            this.dispatchers.updateBundle(bundle);
        });
        return bundles;
    };

    public readonly loadUserConfigurableBundles = async (basket: IBasket) => {
        // Load configurable bundles for the given basket
        const bundles = await basketAPI.listUserConfigurableBundles(basket);
        // Save the remaining bundles in Redux
        this.dispatchers.setConfigurableBundles(bundles);
        return bundles;
    };

    public readonly loadCountries = async () => {
        console.debug("Loading countries...");
        const countries = await addressAPI.loadCountries();
        // Ignore non-shipping countries
        const shippingCountries = countries.filter((country) => {
            return country.is_shipping_country;
        });
        // Update options list
        this.dispatchers.updateCountries(shippingCountries);
        // Set default values
        const initial = shippingCountries.length
            ? shippingCountries[0].url
            : "";
        this.dispatchers.changeFormField({
            shipping_country: initial,
            billing_country: initial,
        });
        console.debug("Loaded countries.");
        return shippingCountries;
    };

    public readonly loadUserAddresses = async () => {
        console.debug("Loading user addresses...");
        const addresses = await addressAPI.listUserAddresses();
        this.dispatchers.updateUserAddresses(addresses);
        return addresses;
    };

    public readonly loadPaymentMethods = async () => {
        console.debug("Loading available payment methods...");
        const methods = await checkoutAPI.listEnabledPaymentMethods();
        this.dispatchers.updatePaymentMethods(
            methods as IPayment["method_type"][],
        );
        // Clear any payment errors
        this.dispatchers.updatePaymentError("");
        console.debug("Loaded available payment methods.");
        return methods;
    };

    public readonly loadAssistedUser = async () => {
        console.debug("Loading CSR's assisted user...");
        const user = await userAPI.getCurrentlyAssistedUser();
        if (!user) {
            console.debug("CSR is not currently assisting a user.");
            return null;
        }
        if (user.email) {
            this.dispatchers.changeFormField({
                guest_email: user.email,
            });
        }
        this.dispatchers.updateAssistedUser(user);
        console.debug(`Loaded CSR's assisted user: ${user.username}.`);
        return user;
    };

    public readonly loadEmailAddress = async () => {
        console.debug("Loading cached basket email address...");
        const data = await checkoutAPI.loadEmailAddress();
        if (data.owner_email) {
            this.dispatchers.changeFormField({
                guest_email: data.owner_email,
            });
            console.debug(
                `Loaded cached basket email address: ${data.owner_email}.`,
            );
        } else {
            console.debug(`No email address found for basket.`);
        }
    };

    public readonly importShippingAddr = (addr: Partial<IAddress>) => {
        if (!objectKeys(addr).length) {
            this.dispatchers.changeFormField({
                current_step: Step.SHIPPING_ADDRESS,
            });
            console.debug(
                "No cached shipping addr returned from API, setting checkout step to SHIPPING_ADDRESS",
            );
        } else {
            const fields: Partial<IShippingAddress> = {};
            objectKeys(addr).forEach((key) => {
                const fieldKey = `shipping_${key}` as keyof IShippingAddress;
                fields[fieldKey] = addr[key];
            });
            this.dispatchers.changeFormField(fields);
            console.debug("Loaded cached shipping address.");
        }
    };

    public readonly importBillingAddr = (addr: Partial<IAddress>) => {
        if (!objectKeys(addr).length) {
            return;
        }
        const fields: Partial<IBillingAddress> = {};
        objectKeys(addr).forEach((key) => {
            const fieldKey = `billing_${key}` as IBillingAddressField;
            fields[fieldKey] = addr[key];
        });
        this.dispatchers.changeFormField(fields);
        console.debug("Loaded cached billing address.");
    };

    public readonly resetFormData = () => {
        this.dispatchers.changeFormField(defaults.form);
    };

    public readonly loadShippingAndBillingAddresses = async () => {
        console.debug("Loading cached shipping and billing addresses...");
        const data = await Promise.all([
            checkoutAPI.loadShippingAddress(),
            checkoutAPI.loadBillingAddress(),
        ]);
        const compareFields: (keyof IAddress)[] = [
            "first_name",
            "last_name",
            "line1",
            "line2",
            "line4",
            "postcode",
            "state",
            "country",
            "phone_number",
        ];
        const shipping = data[0] || {};
        const billing = data[1] || {};
        const billingIsShipping = compareFields.reduce((memo, name) => {
            if (
                shipping[name] &&
                billing[name] &&
                shipping[name] !== billing[name]
            ) {
                memo = false;
            }
            return memo;
        }, true);
        this.importShippingAddr(shipping);
        if (billingIsShipping) {
            this.dispatchers.setBillingAddressIsSameAsShipping(true);
        } else {
            this.dispatchers.setBillingAddressIsSameAsShipping(false);
        }
        this.importBillingAddr(billing);
    };

    public readonly loadPredictedDeliveryDate = async (postcode: string) => {
        this.dispatchers.predictedDeliveryDateLoading();
        try {
            const data = await basketAPI.getPredictedDeliveryDate(postcode);
            if (data.status_code === "success") {
                this.dispatchers.predictedDeliveryDateLoaded(data);
            } else {
                this.dispatchers.predictedDeliveryDateLoadFailed(
                    postcode,
                    data.status_code,
                );
            }
        } catch (e) {
            console.error(e);
            this.dispatchers.predictedDeliveryDateLoadFailed(
                postcode,
                "unknown",
            );
        }
    };
}
