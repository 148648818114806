import { createAsyncThunk } from "@reduxjs/toolkit";
import { isRight } from "fp-ts/lib/Either";
import { parse as parseDate, format as formatDate } from "date-fns";
import * as financingAPI from "../../../api/financing";
import * as signals from "../../signals";
import {
    FinancingApplicationPrecursor,
    FinancingApplicationSubmitData,
    FinancingCreditAppSubmitResult,
    FinancingCreditAppSubmitResult_Approved,
    FinancingCreditAppSubmitResult_Pending,
    FinancingCreditAppSubmitResult_Denied,
    FinancingCreditAppSubmitResult_NextBackend,
    FinancingCreditAppSubmitResult_ExistingCardholder,
} from "../../../models/financing";
import { ErrorSet } from "../../../models/formFields";
import { assertNever } from "../../../utils/never";
import { FinancingAppType, ModalStateType } from "../constants";
import {
    SharedFullFormState,
    ModalState,
    ModalFullApplyNextBackend,
    ModalFullApplyPending,
    ModalFullApplyApproved,
    ModalFullApplyDenied,
    ModalFullApplyExistingCardholder,
} from "../models";

type SubmitFullAppRequest = {
    applicationSource: string | null;
    precursors: FinancingApplicationPrecursor[];
} & SharedFullFormState;

const toInt = (val: string) => {
    return Math.round(parseFloat(val.replace(/[^0-9]/g, "")));
};

const toDate = (
    date: string,
    inputFormat = "MM/dd/yyyy",
    outputFormat = "yyyy-MM-dd",
) => {
    if (!date) {
        return "";
    }
    const parsed = parseDate(date, inputFormat, new Date());
    return formatDate(parsed, outputFormat);
};

const toPhone = (phone: string) => {
    return !phone || phone.length <= 9 ? "" : phone;
};

const _getAppSubmitData = ({
    applicationSource,
    precursors,
    appType,
    values,
}: SubmitFullAppRequest): FinancingApplicationSubmitData => {
    // Munge data into correct format
    const main: FinancingApplicationSubmitData["main_applicant"] = {
        first_name: values.main_applicant__first_name,
        middle_initial: values.main_applicant__middle_initial,
        last_name: values.main_applicant__last_name,
        date_of_birth: toDate(values.main_applicant__date_of_birth),
        ssn: values.main_applicant__ssn,
        annual_income: toInt(values.main_applicant__annual_income),
        email_address: values.main_applicant__email_address,
        home_phone: toPhone(values.main_applicant__home_phone),
        mobile_phone: toPhone(values.main_applicant__mobile_phone),
        work_phone: toPhone(values.main_applicant__work_phone),
        employer_name: values.main_applicant__employer_name,
        housing_status: values.main_applicant__housing_status,
        address: {
            address_line_1: values.main_applicant__address__address_line_1,
            address_line_2: values.main_applicant__address__address_line_2,
            city: values.main_applicant__address__city,
            state_code: values.main_applicant__address__state_code,
            postal_code: values.main_applicant__address__postal_code.replace(
                /[^0-9]/g,
                "",
            ),
        },
    };
    const isJoint = appType === FinancingAppType.JOINT;
    const joint: FinancingApplicationSubmitData["joint_applicant"] = isJoint
        ? {
              first_name: values.joint_applicant__first_name,
              middle_initial: values.joint_applicant__middle_initial,
              last_name: values.joint_applicant__last_name,
              date_of_birth: toDate(values.joint_applicant__date_of_birth),
              ssn: values.joint_applicant__ssn,
              annual_income: toInt(values.joint_applicant__annual_income),
              email_address: values.joint_applicant__email_address,
              home_phone: toPhone(values.joint_applicant__home_phone),
              mobile_phone: toPhone(values.joint_applicant__mobile_phone),
              work_phone: toPhone(values.joint_applicant__work_phone),
              employer_name: values.joint_applicant__employer_name,
              housing_status: values.joint_applicant__housing_status,
              address: {
                  address_line_1:
                      values.joint_applicant__address__address_line_1,
                  address_line_2:
                      values.joint_applicant__address__address_line_2,
                  city: values.joint_applicant__address__city,
                  state_code: values.joint_applicant__address__state_code,
                  postal_code:
                      values.joint_applicant__address__postal_code.replace(
                          /[^0-9]/g,
                          "",
                      ),
              },
          }
        : null;
    const data: FinancingApplicationSubmitData = {
        precursors: precursors || [],
        requested_credit_limit: Math.round(
            parseFloat(values.requested_credit_limit),
        ),
        application_source: applicationSource || "Unknown",
        main_applicant: main,
        joint_applicant: joint,
    };
    return data;
};

export const mapAppSubmitStateToModalState = (
    request: SharedFullFormState,
    result: FinancingCreditAppSubmitResult,
): ModalState => {
    if (FinancingCreditAppSubmitResult_Approved.is(result)) {
        signals.financing.onApproval.emit(result.inquiry);
        const approved: ModalFullApplyApproved = {
            _tag: ModalStateType.APPLY_APPROVED,
            account: result.inquiry,
            documents: result.result.documents,
        };
        return approved;
    }
    if (FinancingCreditAppSubmitResult_Pending.is(result)) {
        const pending: ModalFullApplyPending = {
            _tag: ModalStateType.APPLY_PENDING,
            isRefreshing: false,
            appType: request.appType,
            values: request.values,
            backendType: request.backendType,
        };
        return pending;
    }
    if (FinancingCreditAppSubmitResult_Denied.is(result)) {
        signals.financing.onDenial.emit();
        const denied: ModalFullApplyDenied = {
            _tag: ModalStateType.APPLY_DENIED,
            appType: request.appType,
            values: request.values,
            result: result.result,
        };
        return denied;
    }
    if (FinancingCreditAppSubmitResult_NextBackend.is(result)) {
        const offer = result.prequal.responses[0];
        const nextBackend: ModalFullApplyNextBackend = {
            _tag: ModalStateType.APPLY_NEXT_BACKEND,
            backendType: offer.backend_type,
            appType: request.appType,
            values: request.values,
            currStepIndex: request.currStepIndex,
            disabledSteps: request.disabledSteps,
            prequal: result.prequal,
        };
        return nextBackend;
    }
    if (FinancingCreditAppSubmitResult_ExistingCardholder.is(result)) {
        const existingCardholder: ModalFullApplyExistingCardholder = {
            _tag: ModalStateType.APPLY_EXISTING_CARDHOLDER,
        };
        return existingCardholder;
    }
    throw assertNever(result);
};

const _submitFullApp = async (
    request: SubmitFullAppRequest,
): Promise<ModalState> => {
    const reqData = _getAppSubmitData(request);
    const result = await financingAPI.submitFinancingCreditApp(reqData);
    return mapAppSubmitStateToModalState(request, result);
};

export const submitFullApplication = createAsyncThunk(
    "financing/submitFullApplication",
    async (request: SubmitFullAppRequest): Promise<ModalState> => {
        try {
            return _submitFullApp(request);
        } catch (err) {
            const maybeErrResp = ErrorSet.decode(err.response.body);
            if (isRight(maybeErrResp)) {
                return {
                    _tag: ModalStateType.APPLY_ERROR,
                    backendType: request.backendType,
                    appType: request.appType,
                    values: request.values,
                    currStepIndex: request.currStepIndex,
                    disabledSteps: request.disabledSteps,
                    errors: maybeErrResp.right,
                };
            }
            console.error(err);
            throw err;
        }
    },
);
