import React from "react";
import SVG from "react-inlinesvg";
import { IReview } from "../../../models/reviews.interfaces";
import config from "../../../config";
import { ReviewDisclosureCode } from "../constants";
import styles from "./DisclosureCopy.module.scss";
import iconGiftBox from "../../../../img/icons/gift-box.svg";

interface IProps {
    review: IReview;
}

export const DisclosureCopy = (props: IProps) => {
    const disclosureSettings = config.get("REVIEWS_DISCLOSURE_SETTINGS") || {};
    const disclosureCopy = props.review.disclosure_code
        ? disclosureSettings[props.review.disclosure_code]
        : null;
    if (!disclosureCopy) {
        return null;
    }

    const className =
        props.review.disclosure_code === ReviewDisclosureCode.SAMPLING
            ? styles.rootAlt
            : styles.root;

    return (
        <div className={className}>
            {props.review.disclosure_code === ReviewDisclosureCode.SAMPLING && (
                <SVG aria-hidden="true" src={iconGiftBox} />
            )}
            {disclosureCopy}
        </div>
    );
};
