export const toDateWithoutTimezone = (dateStr: string): Date => {
    /**
     * Converts an ISO 8601 (YYYY-MM-DDTHH:mm:ssZ) date string to a JavaScript Date object,
     * explicitly ignoring any timezone information.
     */
    const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})([+-]\d{2}:?\d{2})?/;
    const match = dateStr.match(regex);
    if (!match) {
        console.error("Invalid date format");
        return new Date(dateStr);
    }
    // Extract and return the date and time parts without the timezone
    return new Date(match[1]);
};
