import { IChatInitReturn, ChatConnector } from "./connector-base";

/* eslint-disable @typescript-eslint/no-empty-function */
export class NullChatConnector extends ChatConnector {
    /**
     * Ensure that the chat connector code is loaded on the page. This is a no-op if it was already loaded / loading.
     * Returns a boolean with the agent-online status.
     */
    public async init(): Promise<IChatInitReturn> {
        return {
            isOnline: false,
        };
    }

    /**
     * Make the user's email and name available to the chat agent.
     */
    public async setUserData(): Promise<void> {}

    /**
     * Open a chat window
     */
    public async openChat(): Promise<void> {}

    /**
     * Open a proactive chat window
     */
    public async openProactiveChat(): Promise<void> {}

    /**
     * Trigger a campaign
     */
    public async triggerCampaign(): Promise<void> {}
}
/* eslint-enable @typescript-eslint/no-empty-function */
