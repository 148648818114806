import {
    ICybersourceTemplate,
    ICybersourceTemplateContext,
    ICybersourceRequest,
    ICybersourceFieldMapping,
} from "../models/checkout.interfaces";

const getCountryCode = (url: string) => {
    // TODO: make this less awful
    const segments = url.split("/");
    return segments[segments.indexOf("countries") + 1];
};

export const renderRequestTemplate = (
    data: ICybersourceTemplateContext,
    template: ICybersourceTemplate,
) => {
    const request: ICybersourceRequest = {
        url: template.url,
        data: {},
    };

    const fieldMapping: ICybersourceFieldMapping = {
        card_type: "card_type",
        card_number: "card_number",
        card_expiry_date: "card_expiration",
        card_cvn: "card_cvc",
        bill_to_forename: "billing_first_name",
        bill_to_surname: "billing_last_name",
        bill_to_phone: "billing_phone_number",
        bill_to_email: "email",
        bill_to_address_line1: "billing_line1",
        bill_to_address_line2: "billing_line2",
        bill_to_address_city: "billing_line4",
        bill_to_address_postal_code: "billing_postcode",
        bill_to_address_state: "billing_state",
        bill_to_address_country: "billing_country",
    };

    template.fields.forEach((field) => {
        const inputKey = fieldMapping[field.key];
        const inputValue = data[inputKey];
        let value = field.value;

        if (field.editable && inputValue) {
            value = inputValue;
        }

        request.data[field.key] = value;
    });

    request.data.card_number = (request.data.card_number || "").replace(
        /\s+/g,
        "",
    );

    // Convert 12/20 => 12-2020
    const century = `${new Date().getFullYear()}`.slice(0, 2);
    request.data.card_expiry_date = (
        request.data.card_expiry_date || ""
    ).replace(/([0-9]{2})\/([0-9]{2})/, `$1-${century}$2`);

    request.data.bill_to_phone = (request.data.bill_to_phone || "").replace(
        /[^0-9]+/g,
        "",
    );
    request.data.bill_to_address_country = getCountryCode(
        request.data.bill_to_address_country || "/api/countries/US/",
    );
    return request;
};

export const submitRequest = (
    request: ICybersourceRequest,
    frameSrc = "/store/checkout/payment-submit/",
) => {
    const frame = document.createElement("iframe");
    frame.src = frameSrc;
    frame.style.display = "none";
    frame.style.border = "none";
    frame.width = "0";
    frame.height = "0";
    // set timeout to catch stalled payment token requests
    const timeoutSeconds = 45;
    const origin = window.location.href;
    const message = { type: "TSI_CHECKOUT_PAYMENT_TIMEOUT" };
    window.setTimeout(function () {
        window.postMessage(message, origin);
    }, 1000 * timeoutSeconds);
    document.body.appendChild(frame);
    console.log(
        `Payment Submit Iframe created, ${timeoutSeconds} seconds until timeout`,
    );
    const frameDoc = frame.contentWindow
        ? frame.contentWindow.document
        : frame.contentDocument;
    if (!frameDoc) {
        throw new Error("iframe is missing document object");
    }

    const form = frameDoc.createElement("form");
    form.method = "POST";
    form.action = request.url;
    const dataKeys = Object.keys(request.data) as Array<
        keyof ICybersourceRequest["data"]
    >;
    for (const key of dataKeys) {
        const elem = frameDoc.createElement("input");
        elem.type = "hidden";
        elem.name = key;
        elem.value = request.data[key] || "";
        form.appendChild(elem);
    }
    const submitForm = () => {
        console.log("trying to submit appended form from iframe");
        frameDoc.body.appendChild(form);
        form.submit();
    };
    if (frameDoc.body) {
        console.log("submitting token request form");
        submitForm();
    } else {
        frame.onload = submitForm;
    }
};
