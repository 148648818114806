import React from "react";
import { t } from "ttag";
import ReactTooltip from "react-tooltip";
import SVG from "react-inlinesvg";
import { differenceInCalendarDays, formatISO } from "date-fns";
import { IRetailStoreHolidayHours } from "tsi-common-react/src/models/location.interfaces";
import { RetailStoreHolidayClosed } from "tsi-common-react/src/models/location";
import {
    WEEK_DAYS,
    ExpandedStoreHours,
    refactorStoreHours,
    DayTimePair,
} from "../../StoreLocatorPanel/components/utils";
import styles from "./StoreHours.module.scss";
import iconInfo from "tsi-common-react/img/icons/info.svg";

interface IProps {
    hoursSummary?: string;
    holidayHours?: IRetailStoreHolidayHours[];
}

interface DayNameHolidayClosed {
    day: (typeof WEEK_DAYS)[number];
    closed: true;
}

interface DayNameHolidayOpen {
    day: (typeof WEEK_DAYS)[number];
    closed?: false;
    start_time: string;
    end_time: string;
}

export type DayNameHolidayHours = DayNameHolidayClosed | DayNameHolidayOpen;

/**
 * Returns holidays that fall on today or in the next week
 */
export const filterDatesForCurrentWeek = (
    holidayHours: IRetailStoreHolidayHours[],
): IRetailStoreHolidayHours[] => {
    const now = new Date();
    const currentTime = formatISO(now).split("T")[1];
    return holidayHours.filter(({ date }) => {
        const normalizedDate = new Date(`${date}T${currentTime}`);
        const dist = differenceInCalendarDays(normalizedDate, now);
        return dist >= 0 && dist < 7;
    });
};

export const getDayOfWeek = (date: string): (typeof WEEK_DAYS)[number] => {
    const _date = new Date(date + "T00:00:00"); // Append time to ensure correct date
    const weekDay = _date.getDay();
    return WEEK_DAYS[weekDay === 0 ? 6 : weekDay - 1]; // Adjust for monday
};

export const formatTime = (inputTime: string): string => {
    const [hours, minutes] = inputTime.split(":");
    // Convert hours to 12-hour format
    const formattedHours = parseInt(hours, 10) % 12 || 12;
    const period = parseInt(hours, 10) < 12 ? "AM" : "PM";
    const formattedTime =
        minutes === "00"
            ? `${formattedHours}${period}`
            : `${formattedHours}:${minutes}${period}`;
    return formattedTime;
};

export const replaceHolidayHours = (
    summary: ExpandedStoreHours,
    holidayHours: DayNameHolidayHours[],
) => {
    if (holidayHours?.length === 0) {
        return { holidays: [], summaryWithHolidays: summary };
    }
    const holidayState: string[] = [];

    const summaryWithHolidays = summary.map(([day, time]): DayTimePair => {
        const holidayDate = holidayHours.find((d) => d.day === day);
        if (!holidayDate) {
            return [day, time];
        }
        holidayState.push(holidayDate.day);
        return holidayDate.closed
            ? [day, "Closed"]
            : [
                  day,
                  `${formatTime(holidayDate.start_time)} – ${formatTime(
                      holidayDate.end_time,
                  )}`,
              ];
    });

    return { holidays: holidayState, summaryWithHolidays: summaryWithHolidays };
};

export const StoreHours = (props: IProps) => {
    if (!props.hoursSummary) {
        return null;
    }
    const formattedHoursSummary = props.hoursSummary
        ? refactorStoreHours(props.hoursSummary)
        : [];

    const holidayHours = (
        props.holidayHours ? filterDatesForCurrentWeek(props.holidayHours) : []
    ).map((hd): DayNameHolidayHours => {
        const day = getDayOfWeek(hd.date);
        if (RetailStoreHolidayClosed.is(hd)) {
            return { day: day, closed: true };
        }
        return {
            ...hd,
            day: getDayOfWeek(hd.date),
        };
    });

    const { holidays, summaryWithHolidays } = replaceHolidayHours(
        formattedHoursSummary,
        holidayHours,
    );
    const tipId = "store-hours-holiday-text__tooltip";

    return (
        <div className={styles.singleColumnRow}>
            <dl>
                {summaryWithHolidays.map((dates, i) => {
                    const [day, time] = dates;
                    return (
                        <div key={i}>
                            <dt>{day}</dt>
                            <dd>{time}</dd>
                            {holidays.includes(day) && (
                                <>
                                    <div
                                        aria-describedby={tipId}
                                        data-for={tipId}
                                        data-tip={true}
                                        tabIndex={0}
                                        className={styles.tooltip}
                                    >
                                        <SVG
                                            aria-hidden="true"
                                            src={iconInfo}
                                            title={t`Questions Icon`}
                                            className={styles.tooltipSvg}
                                        />
                                        <span className="ada-screenreader-only">
                                            {t`Holiday Hours Indication`}
                                        </span>
                                    </div>
                                    <ReactTooltip
                                        aria-label={t`Holiday Hours`}
                                        event={"mouseover focus"}
                                        eventOff="scroll mousewheel mouseleave mouseout blur"
                                        id={tipId}
                                        role="tooltip"
                                    >
                                        <span>Holiday hours</span>
                                    </ReactTooltip>
                                </>
                            )}
                        </div>
                    );
                })}
            </dl>
        </div>
    );
};
