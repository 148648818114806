import React from "react";
import { t } from "ttag";

interface IProps {
    showVerboseUserInfo: boolean;
    user: string;
    location: string;
    formattedDate: string | null;
}

interface IState {}

export class ReviewUserInfo extends React.PureComponent<IProps, IState> {
    private buildLocation() {
        let location = this.props.location;
        if (location && location.toLowerCase() === "undisclosed") {
            location = t`Location Undisclosed`;
        }
        return location;
    }

    render() {
        const location = this.buildLocation();
        if (this.props.showVerboseUserInfo) {
            return (
                <div className="customer-review__review-item__review-author">
                    – by <strong>{`${this.props.user}`}</strong> from
                    {location ? ` ${location}` : null}
                    {` on ${this.props.formattedDate}`}
                </div>
            );
        } else {
            return (
                <div className="customer-review__review-item__review-author">
                    {this.props.user}
                    {location ? `, ${location}` : null}
                </div>
            );
        }
    }
}
