import React from "react";
import { t } from "ttag";
import classNames from "classnames";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { IUser } from "../../../models/user.interfaces";
import { Step } from "../constants";

interface IOwnProps {
    user: IUser;
    onEdit?: (event?: React.MouseEvent<HTMLElement>) => void;
}

interface IReduxProps {
    showSummary: boolean;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class EmailAddressSummaryComponent extends React.Component<IProps, IState> {
    render() {
        const summaryClasses = classNames({
            "checkout-step__summary": true,
            "checkout-step__summary--collapsed": !this.props.showSummary,
        });
        return (
            <div className={summaryClasses}>
                <p>{t`Logged in as ${this.props.user.email}`}</p>
                {this.props.onEdit && (
                    <p>
                        <a
                            className="checkout-step__back"
                            href="#"
                            onClick={this.props.onEdit}
                        >
                            {t`Edit`}
                        </a>
                    </p>
                )}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        showSummary: state.form.current_step > Step.LOGIN,
        ...ownProps,
    };
};

export const EmailAddressSummary = connect(mapStateToProps)(
    EmailAddressSummaryComponent,
);
