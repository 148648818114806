import React from "react";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { IProduct, IOptionValues } from "../../../models/catalogue.interfaces";
import { IWebPageURL } from "../../../models/nominals";
import { RichText } from "../../../common/RichText";
import { ConfiguratorPanel } from "./ConfiguratorPanel";
import { ConfiguratorPanelTrigger } from "./ConfiguratorPanelTrigger";
import { rootProductSelector } from "../selectors";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { PanelRootProductSelectorOption } from "./PanelRootProductSelectorOption";
import { MOBILE_WIDTH_THRESHOLD_ALTERNATIVE } from "../constants";
import { CSSTransition } from "react-transition-group";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";

import styles from "./PanelRootProductSelector.module.scss";

interface IOwnProps {
    optionSet: IModularConfiguratorOptionSet;
    chatLinkComponent?: JSX.Element | null;
    liveChatHeader?: string;
    financingLink?: IWebPageURL;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedRootProduct: IProduct | null;
    optionValues: IOptionValues;
}

interface IDispatchProps {
    onSelectRootProduct: (product: IProduct) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isPanelOpen: boolean;
}

class PanelRootProductSelectorComponent extends React.PureComponent<
    IProps,
    IState
> {
    private readonly panelContainerRef = React.createRef<HTMLDivElement>();
    public state: IState = {
        isPanelOpen: false,
    };

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    private readonly onClosePanel = () => {
        // `enableBodyScroll` should run before setState `isPanelOpen: false`,
        // so inline style `position: fixed` from `<body>` will be removed first before removing `.configurator-panel-open` from `<body>`.
        // This way can avoid the issue of the page jumping to the top when panel is opened/closed
        enableBodyScroll(this.panelContainerRef.current as HTMLElement);
        this.setState(
            {
                isPanelOpen: false,
            },
            () => {
                // Wait a few seconds to remove inline style `position: fixed` before removing `.configurator-panel-open` from `<body>`.
                setTimeout(() => {
                    document.body.classList.remove("configurator-panel-open");
                }, 500);
            },
        );
    };

    private readonly onOpenPanel = () => {
        this.setState(
            {
                isPanelOpen: true,
            },
            () => {
                if (window.innerWidth < MOBILE_WIDTH_THRESHOLD_ALTERNATIVE) {
                    disableBodyScroll(
                        this.panelContainerRef.current as HTMLElement,
                        {
                            allowTouchMove: (el) => {
                                while (el && el !== document.body) {
                                    if (
                                        el.getAttribute(
                                            "data-body-scroll-lock-ignore",
                                        ) !== null
                                    ) {
                                        return true;
                                    }

                                    el = el.parentElement as
                                        | HTMLElement
                                        | Element;
                                }

                                return false;
                            },
                        },
                    );
                } else {
                    enableBodyScroll(
                        this.panelContainerRef.current as HTMLElement,
                    );
                }
            },
        );
    };

    private buildPanelTriggerContent(): React.ReactNode {
        if (!this.props.selectedRootProduct) {
            return null;
        }
        if (
            this.props.selectedRootProduct.attributes
                .configurator_panel_trigger_title
        ) {
            return (
                <RichText
                    inline={true}
                    html={`${this.props.selectedRootProduct.attributes.configurator_panel_trigger_title.value}`}
                />
            );
        }
        if (this.props.selectedRootProduct.description) {
            return (
                <>
                    <RichText
                        inline={true}
                        html={this.props.selectedRootProduct.description}
                    />
                    (
                    {this.props.selectedRootProduct.title.replace(
                        "TEMPUR-",
                        "",
                    )}
                    )
                </>
            );
        }
        return this.props.selectedRootProduct.title;
    }

    render() {
        if (
            this.props.rootProducts.length <= 1 ||
            !this.props.selectedRootProduct
        ) {
            return null;
        }
        return (
            <>
                <div ref={this.panelContainerRef}>
                    <CSSTransition
                        in={this.state.isPanelOpen}
                        timeout={500}
                        classNames="configurator__panel-"
                        unmountOnExit={true}
                    >
                        <ConfiguratorPanel
                            prefixNameCapped={
                                this.props.optionSet.root_product_selector.label
                            }
                            onClosePanel={this.onClosePanel}
                            panelClass={"model"}
                            chatLinkComponent={this.props.chatLinkComponent}
                            liveChatHeader={this.props.liveChatHeader}
                            enableChat={this.props.optionSet.layout.enable_chat}
                            radioSelector={
                                <div className="configurator__radio-container">
                                    <fieldset>
                                        <legend className="ada-screenreader-only">
                                            {
                                                this.props.optionSet
                                                    .root_product_selector.label
                                            }
                                        </legend>
                                        <ul className={styles.container}>
                                            {this.props.rootProducts.map(
                                                (rootProduct) => (
                                                    <PanelRootProductSelectorOption
                                                        key={`${rootProduct.id}`}
                                                        optionSet={
                                                            this.props.optionSet
                                                        }
                                                        rootProduct={
                                                            rootProduct
                                                        }
                                                        selectedRootProduct={
                                                            this.props
                                                                .selectedRootProduct!
                                                        }
                                                        enableRatings={
                                                            this.props.optionSet
                                                                .root_product_selector
                                                                .enable_ratings
                                                        }
                                                        onSelect={() => {
                                                            this.props.onSelectRootProduct(
                                                                rootProduct,
                                                            );
                                                            this.onClosePanel();
                                                        }}
                                                        onClick={
                                                            this.onClosePanel
                                                        }
                                                    />
                                                ),
                                            )}
                                        </ul>
                                    </fieldset>
                                </div>
                            }
                        />
                    </CSSTransition>
                </div>
                <ConfiguratorPanelTrigger
                    prefixNameCapped={
                        this.props.optionSet.root_product_selector.label
                    }
                    onOpenPanel={this.onOpenPanel}
                >
                    {this.buildPanelTriggerContent()}
                </ConfiguratorPanelTrigger>
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProducts: state.entities.rootProducts,
        selectedRootProduct: rootProductSelector(state),
        optionValues: state.ui.optionValues,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onSelectRootProduct: (product) => {
            dispatchers.setSelectedRootProduct(product);
        },
    };
};

export const PanelRootProductSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(PanelRootProductSelectorComponent);
