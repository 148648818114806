import React from "react";
import { connect } from "react-redux";
import { IRootState as IReduxState } from "../../reducers.interfaces";
import { IOptionCode } from "../../../models/catalogue.interfaces";
import { slugify } from "../../../utils/text";
import { getOptionValueSet } from "../utils";

interface IOwnProps {
    attributeCode: IOptionCode;
    attributeValue: string;
    content: string;
}

interface IReduxProps {
    selectedValues: IReduxState["configurator"]["ui"]["optionValues"];
}

type IProps = IOwnProps & IReduxProps;

interface IState {}

class PDPSelectedVariantConditionalBlockComponent extends React.Component<
    IProps,
    IState
> {
    render() {
        // If the currently selected value of the configured attribute doesn't match the desired value, don't render anything
        const selectedValues = getOptionValueSet(
            this.props.selectedValues[this.props.attributeCode],
        );
        const isSelected = selectedValues.has(this.props.attributeValue);
        // Build IDs for relationship to tab controls. These IDs must match the IDs used by PDPChangeVariantOptionBlock.
        const tabControlID = slugify(
            `change-variant-block-${this.props.attributeCode}-${this.props.attributeValue}`,
        );
        const tabPanelID = slugify(
            `variant-conditional-block-${this.props.attributeCode}-${this.props.attributeValue}`,
        );
        return (
            <div
                id={tabPanelID}
                role="tabpanel"
                aria-labelledby={tabControlID}
                aria-hidden={!isSelected}
                style={{ display: isSelected ? "block" : "none" }}
                dangerouslySetInnerHTML={{ __html: this.props.content }}
            ></div>
        );
    }
}

const mapStateToProps = (state: IReduxState, ownProps: IOwnProps): IProps => {
    return {
        selectedValues: state.configurator.ui.optionValues,
        ...ownProps,
    };
};

export const PDPSelectedVariantConditionalBlock = connect(mapStateToProps)(
    PDPSelectedVariantConditionalBlockComponent,
);
