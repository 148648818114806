import React from "react";

import { templates } from "@reactivated";
import { RichNavigation } from "../apps/rich-navigation/RichNavigation";

export const Template = (props: templates.RichNavigationTemplate) => {
    if (!props.rich_navigation) {
        return null;
    }
    return <RichNavigation {...props.rich_navigation} />;
};
