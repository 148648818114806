import React from "react";

export const UnifiModalTrigger = (
    props: React.PropsWithChildren<{
        className: string;
        termThreshold: string;
    }>,
) => (
    <button
        className={props.className}
        onClick={(e) => {
            e.preventDefault();
            // Route directly to Prequalify form
            window.open(
                "https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=DA83D7FE0C9E7E9FDC17CF8BB57067C303888D4BB7D836B2&preQual=Y",
            );
        }}
    >
        {props.children}
    </button>
);
