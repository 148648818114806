import React from "react";
import { t } from "ttag";
import format from "../../../utils/format";
import { ErrorBoundary } from "../../../common/ErrorBoundary";
import { IReduxDerivedData, IReduxAPIData } from "../reducers.interfaces";
import { RECYCLING_FEE_NAME } from "../constants";
import { BasketAllSavings } from "../components/BasketAllSavings";
import { Dinero, ZERO, getDinero } from "../../../utils/money";
import {
    PriceType,
    getProductPrice,
    getPostDiscountAddonsTotal,
} from "../../../utils/catalogue";

import styles from "./BasketSummary.module.scss";

interface IProps {
    disableShipping?: boolean;
    disableFreeShippingMessage?: boolean;
    disableTaxes?: boolean;
    derived: IReduxDerivedData;
    data: IReduxAPIData;
    removeVoucherCode: (code: string) => void;
    getExtraContent: () => JSX.Element | null;
}

interface IState {}

/* eslint-disable  complexity */
export class BasketSummary extends React.Component<IProps, IState> {
    private buildBasketSavingsRow(retailSubtotal: Dinero) {
        if (!this.props.data.basket) {
            return null;
        }
        const effectiveSubtotal = getDinero(
            this.props.data.basket.total_excl_tax_excl_discounts,
        );
        const hasOscarAdjustment =
            retailSubtotal.greaterThan(effectiveSubtotal);
        const totalDiscount = this.props.derived.total_discount
            ? getDinero(this.props.derived.total_discount)
            : ZERO;
        const hasDiscounts =
            totalDiscount.isPositive() && !totalDiscount.isZero();

        // Check if Post Order actions are present and are NOT marked to be hidden
        const postOrderActions = this.props.data.basket.offer_post_order_actions
            .concat(this.props.data.basket.voucher_post_order_actions)
            .filter((action) => {
                return !action.is_hidden;
            });

        if (
            !hasDiscounts &&
            postOrderActions.length <= 0 &&
            !hasOscarAdjustment
        ) {
            return null;
        }
        const totalOscarDiscount = retailSubtotal.subtract(effectiveSubtotal);
        return (
            <div className={styles.line}>
                <BasketAllSavings
                    offerDiscounts={this.props.data.basket.offer_discounts}
                    voucherDiscounts={this.props.data.basket.voucher_discounts}
                    offerPostOrderActions={
                        this.props.data.basket.offer_post_order_actions
                    }
                    voucherPostOrderActions={
                        this.props.data.basket.voucher_post_order_actions
                    }
                    removeVoucherCode={this.props.removeVoucherCode}
                    totalOscarDiscount={totalOscarDiscount}
                    hasOscarAdjustment={hasOscarAdjustment}
                />
            </div>
        );
    }

    private getShippingCost() {
        if (this.props.disableShipping) {
            return (
                <>
                    <span className="ada-screenreader-only">
                        Uncalculated Shipping
                    </span>
                    -
                </>
            );
        }
        if (
            this.props.disableFreeShippingMessage &&
            !Number(
                this.props.derived
                    .shipping_method_cost_before_discount_excl_tax,
            )
        ) {
            return (
                <>
                    <span className="ada-screenreader-only">
                        Uncalculated Shipping
                    </span>
                    -
                </>
            );
        }
        return format.shippingCost(
            this.props.derived.shipping_method_cost_before_discount_excl_tax,
        );
    }

    private getRetailSubtotal() {
        if (!this.props.data.basket) {
            return ZERO;
        }
        const subtotal = this.props.data.basket.lines
            .map((line) =>
                getProductPrice(line.product.price, {
                    priceType: PriceType.RETAIL,
                    includePostDiscountAddons: false,
                    quantity: line.quantity,
                }),
            )
            .reduce((total, retail) => total.add(retail), ZERO);
        return subtotal || ZERO;
    }

    private getPostDiscountAddonsTotal() {
        if (!this.props.data.basket) {
            return ZERO;
        }
        const subtotal = this.props.data.basket.lines
            .map((line) =>
                getPostDiscountAddonsTotal(line.product.price, line.quantity),
            )
            .reduce((total, addons) => total.add(addons), ZERO);
        return subtotal || ZERO;
    }

    private getSalesTax(totalTax: Dinero | null) {
        const tax = format.money(totalTax);
        if (tax === "-") {
            return (
                <>
                    <span className="ada-screenreader-only">
                        Uncalculated Tax
                    </span>
                    {tax}
                </>
            );
        }

        return tax;
    }

    private buildShippingDiscountRow() {
        if (this.props.disableShipping) {
            return null;
        }
        if (
            this.props.disableFreeShippingMessage &&
            !Number(this.props.derived.shipping_method_cost_excl_tax)
        ) {
            return null;
        }
        const preDiscount = getDinero(
            this.props.derived.shipping_method_cost_before_discount_excl_tax,
        );
        const postDiscount = getDinero(
            this.props.derived.shipping_method_cost_excl_tax,
        );
        const discount = postDiscount.subtract(preDiscount);
        if (discount.toUnit() >= 0) {
            return null;
        }
        return (
            <span className={styles.line}>
                <li>{t`Shipping Discount`}</li>
                <li>{format.money(discount)}</li>
            </span>
        );
    }

    render() {
        if (!this.props.data.basket) {
            return null;
        }
        const retailSubtotal = this.getRetailSubtotal();
        const addonsSubtotal = this.getPostDiscountAddonsTotal();
        const retailSubtotalIncludingAddons =
            retailSubtotal.add(addonsSubtotal);
        const showTax =
            this.props.data.basket.is_tax_known && !this.props.disableTaxes;

        const shippingTax = getDinero(
            this.props.derived.shipping_method_cost_incl_tax,
        ).subtract(getDinero(this.props.derived.shipping_method_cost_excl_tax));
        let totalTax = showTax
            ? getDinero(this.props.data.basket.total_tax).add(shippingTax)
            : null;
        const recyclingFee =
            this.props.data.basket.tax_details[RECYCLING_FEE_NAME];

        let recyclingFeeRow: JSX.Element | null = null;
        if (showTax && totalTax && recyclingFee) {
            totalTax = totalTax.subtract(getDinero(recyclingFee));
            recyclingFeeRow = (
                <div className={styles.line}>
                    <div>{t`Recycling Fee`}</div>
                    <div>{format.money(recyclingFee)}</div>
                </div>
            );
        }

        let grandTotal: Dinero.Dinero | null = null;
        if (this.props.disableShipping && this.props.disableTaxes) {
            grandTotal = getDinero(this.props.data.basket.total_excl_tax);
        } else if (this.props.disableShipping) {
            grandTotal = getDinero(this.props.data.basket.total_incl_tax);
        } else if (this.props.disableTaxes) {
            grandTotal = getDinero(this.props.data.basket.total_excl_tax).add(
                getDinero(
                    this.props.derived.shipping_method_cost_excl_tax || "0.00",
                ),
            );
        } else if (this.props.derived.grand_total) {
            grandTotal = getDinero(this.props.derived.grand_total);
        }

        return (
            <div className="">
                <ul className={styles.pricesContainer}>
                    <span className={styles.line}>
                        <li className={styles.regular}>{t`Subtotal`}</li>
                        <li className={styles.regular}>
                            {format.money(retailSubtotalIncludingAddons)}
                        </li>
                    </span>
                    {this.buildBasketSavingsRow(retailSubtotal)}
                    <span className={styles.line}>
                        <li>{t`Shipping`}</li>
                        <li>{this.getShippingCost()}</li>
                    </span>
                    {this.buildShippingDiscountRow()}
                    <span className={styles.line}>
                        <li>{t`Sales Tax`}</li>
                        <li>{this.getSalesTax(totalTax)}</li>
                    </span>
                    {recyclingFeeRow}
                    <span className={styles.line}>
                        <li className={styles.bold}>{t`Total`}</li>
                        <li className={styles.bold}>
                            {format.money(grandTotal)}
                        </li>
                    </span>
                    <ErrorBoundary>
                        {this.props.getExtraContent &&
                            this.props.getExtraContent()}
                    </ErrorBoundary>
                </ul>
            </div>
        );
    }
}
