import React from "react";
import classNames from "classnames";
import { t } from "ttag";
import {
    IAttributeOptionGroup,
    ICompareTile,
    IProductTileAttributeRow,
} from "../../../models/product-compare.interfaces";
import { ITileID, isoTileID } from "../../../models/product-compare";
import { ProductSchemaTag } from "../../../common/ProductSchemaTag";
import { IWebPageURL } from "../../../models/nominals";
import { ProductCompareTileAttributeRow } from "../elements/ProductCompareTileAttributeRow";
import { ProductCompareTileCTA } from "../elements/ProductCompareTileCTA";
import { ProductCompareTileHeader } from "../elements/ProductCompareTileHeader";
import { getSelectedVariant } from "../selectors";
import { ProductCompareTheme } from "../../../constants";
import { setShowStartingAt } from "../utility";
import styles from "./ProductSelectionGridColumn.module.scss";

interface IProps {
    tile: ICompareTile;
    showStartingAt?: boolean;
    showRating?: boolean;
    showInStoreCallout?: boolean;
    productComparePageURL: IWebPageURL | null;
    selectedTileIDs: ITileID[];
    priceSelectValue: string;
    financingLink: IWebPageURL;
    sizeAttributeOptionGroup: IAttributeOptionGroup | null;
    theme: ProductCompareTheme;
    highestAttributeCount?: number;
    addToSelected: (tileID: ITileID) => void;
    removeFromSelected: (tileID: ITileID) => void;
    showModal: () => void;
}

interface IState {}

export class ProductSelectionGridColumn extends React.Component<
    IProps,
    IState
> {
    private readonly onCompareCheckboxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        tile: ICompareTile,
    ) => {
        if (e.currentTarget.checked) {
            if (this.props.selectedTileIDs.length < 4) {
                this.props.addToSelected(tile.product_compare_tile.id);
                this.addToURLParams(tile.product_compare_tile.id);
            } else {
                this.props.showModal();
            }
        } else {
            this.props.removeFromSelected(tile.product_compare_tile.id);
            this.removeFromURLParams(tile.product_compare_tile.id);
        }
    };

    private isChecked(tile: ICompareTile) {
        return this.props.selectedTileIDs.includes(
            tile.product_compare_tile.id,
        );
    }

    private buildCompareCount() {
        if (this.props.selectedTileIDs.length > 0) {
            return `(${this.props.selectedTileIDs.length})`;
        } else {
            return null;
        }
    }

    private buildCompareNowURI(baseURL: IWebPageURL) {
        const returnURL = encodeURIComponent(window.location.pathname);

        const ids = encodeURIComponent(this.props.selectedTileIDs.join(","));
        return `${baseURL}?tiles=${ids}&return_url=${returnURL}`;
    }

    private removeFromURLParams(tileID: ITileID) {
        const urlParams = new URLSearchParams(window.location.search);
        const selectedTileIDs = decodeURIComponent(urlParams.get("tiles") || "")
            .split(",")
            .map(Number)
            .map(isoTileID.wrap);
        const newTileIDs = selectedTileIDs.filter((id) => id !== tileID);

        if (newTileIDs.length > 0) {
            urlParams.set("tiles", newTileIDs.join(","));
            const newURL = `${window.location.origin}${
                window.location.pathname
            }?${urlParams.toString()}`;
            window.history.replaceState(history.state, "", newURL);
        } else {
            urlParams.delete("tiles");
            const newURL = `${window.location.origin}${
                window.location.pathname
            }?${urlParams.toString()}`;
            window.history.replaceState(history.state, "", newURL);
        }
    }

    private addToURLParams(tileID: ITileID) {
        const urlParams = new URLSearchParams(window.location.search);
        let selectedTileIDs: ITileID[] = [];
        if (urlParams.get("tiles")) {
            selectedTileIDs = decodeURIComponent(urlParams.get("tiles") || "")
                .split(",")
                .map(Number)
                .map(isoTileID.wrap);
        }
        const newTileIDs = [...selectedTileIDs, tileID];

        urlParams.set("tiles", newTileIDs.join(","));
        const newURL = `${window.location.origin}${
            window.location.pathname
        }?${urlParams.toString()}`;
        window.history.replaceState(history.state, "", newURL);
    }

    private buildAttributeRow(tile: ICompareTile) {
        return tile.rows.map((row: IProductTileAttributeRow) => (
            <ProductCompareTileAttributeRow
                key={`${tile.product_compare_tile.id}-${row.row_type.id}`}
                row={row}
                data={tile}
                financingLink={this.props.financingLink}
                sizeAttributeOptionGroup={this.props.sizeAttributeOptionGroup}
                highestAttributeCount={this.props.highestAttributeCount}
            />
        ));
    }

    render() {
        const showCompareFunctionality = [
            ProductCompareTheme.WITHOUT_COMPARE,
            ProductCompareTheme.THEME_ONE,
        ].includes(this.props.theme)
            ? false
            : true;
        const variant = getSelectedVariant(
            this.props.tile,
            this.props.sizeAttributeOptionGroup
                ? this.props.priceSelectValue
                : null,
        );
        const tileClasses = classNames({
            ["tile"]: true,
            [styles.tile]: this.props.theme === ProductCompareTheme.DEFAULT,
            [styles.tileThemeWithoutCompare]:
                this.props.theme === ProductCompareTheme.WITHOUT_COMPARE,
            [styles.tileThemeOne]:
                this.props.theme === ProductCompareTheme.THEME_ONE,
            [this.props.theme || ""]: true,
            ["hidden"]: this.props.tile.is_promo_period_active === false,
        });
        const highlightedAttributesClasses = classNames({
            [styles.highlightedAttributes]:
                this.props.theme === ProductCompareTheme.DEFAULT,
            [styles.highlightedAttributesThemeWithoutCompare]:
                this.props.theme === ProductCompareTheme.WITHOUT_COMPARE,
            [styles.highlightedAttributesThemeOne]:
                this.props.theme === ProductCompareTheme.THEME_ONE,
        });
        return (
            <div
                className={tileClasses}
                role="group"
                aria-label={t`Mattress Model`}
            >
                <ProductCompareTileHeader
                    data={this.props.tile}
                    selectedVariant={variant}
                    showDescription={true}
                    showRating={
                        this.props.showRating !== undefined
                            ? this.props.showRating
                            : true
                    }
                    theme={this.props.theme}
                />
                <section className={highlightedAttributesClasses}>
                    {this.buildAttributeRow(this.props.tile)}
                </section>
                <ProductCompareTileCTA
                    data={this.props.tile}
                    selectedVariant={variant}
                    showInStoreCallout={this.props.showInStoreCallout}
                    showStartingAt={
                        this.props.showStartingAt
                            ? this.props.showStartingAt
                            : setShowStartingAt(
                                  this.props.tile,
                                  this.props.sizeAttributeOptionGroup,
                              )
                    }
                    financingLink={this.props.financingLink}
                    theme={this.props.theme}
                />
                {showCompareFunctionality && (
                    <form className={styles.form}>
                        <span className="ada-screenreader-only">
                            {t`Activating these elements will cause content on the page to be updated. Please go to the Compare section of the page to view your results.`}
                        </span>
                        <input
                            id={`product-compare-tile-selected-${this.props.tile.product_compare_tile.id}`}
                            onChange={(e) => {
                                this.onCompareCheckboxChange(
                                    e,
                                    this.props.tile,
                                );
                            }}
                            type="checkbox"
                            className="al-comparisongrid-checked"
                            checked={this.isChecked(this.props.tile)}
                        />
                        <label
                            htmlFor={`product-compare-tile-selected-${this.props.tile.product_compare_tile.id}`}
                        >
                            {this.isChecked(this.props.tile)
                                ? `Selected`
                                : `Compare`}
                        </label>
                    </form>
                )}
                {this.isChecked(this.props.tile) &&
                    showCompareFunctionality &&
                    this.props.productComparePageURL && (
                        <a
                            href={this.buildCompareNowURI(
                                this.props.productComparePageURL,
                            )}
                            className="al-comparsiongrid-compareselected"
                        >
                            Compare Now <span>{this.buildCompareCount()}</span>
                        </a>
                    )}
                {variant && (
                    <ProductSchemaTag
                        name={variant.title}
                        imageUrl={
                            variant.images[0] && variant.images[0].original
                        }
                        description={variant.description}
                        sku={variant.upc}
                        brandName="Tempur-Pedic"
                        ratingValue={variant.rating}
                        reviewCount={variant.num_reviews}
                    />
                )}
            </div>
        );
    }
}
